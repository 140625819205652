import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { CardWrapperDetailsPage } from '../../../../common/CardWrapper';
import { Constants } from '../../../../common/Constants';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DetailsPageHeader } from '../../../../common/DetailsPageHeader/DetailsPageHeader';
import { LoadingSpinnerComp } from '../../../../common/Loader';
import { ProviderDetailTabs } from '../../../../common/ProviderDetailTabs/ProviderDetailTabs';
import { useFacilityDetails } from '../../../../hooks/useFacilityDetails';
import { ProviderType } from '../../../../models/ProviderDetails';
import {
  getLocationId,
  getNetworkStatus,
  getProviderId,
  getSelectedLocationIndex,
} from '../../../../utils/providerDetails.utils';

export const FacilityDetails = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { showDirection, chipValue, coverageType } = tokenData;

  const { avatar = 'clinic' } = tokenData;
  const providerId = getProviderId(tokenData);
  const locationId = getLocationId(tokenData);

  const { data, isLoading } = useFacilityDetails({
    providerId,
    providerType: ProviderType.ORGANIZATION,
    locationId,
  });

  const [navigateToDirections, setNavigateToDirections] = useState(
    Boolean(showDirection)
  );

  const [isMapView, setIsMapView] = useState<boolean>(false);

  const [, setDirectionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  useEffect(() => {
    if (showDirection) {
      setDirectionsTriggeredFrom('');
      setNavigateToDirections(true);
    }
  }, [showDirection]);

  const facilityDetails = data?.facilityDetails?.providerDetails || {};
  const providerLocations = facilityDetails?.providerLocations || [];

  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const selectedLocationIndex = getSelectedLocationIndex(
    providerLocations,
    selectedId
  );
  const selectedLocation = providerLocations?.[selectedLocationIndex];

  const networkStatus = getNetworkStatus(selectedLocation?.network?.status);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingSpinnerComp isLoading={isLoading} />
      ) : (
        <React.Fragment>
          {!(mobileScreen && isMapView) ? (
            <CardWrapperDetailsPage backgroundColor="#FBFCFE">
              <DetailsPageHeader
                avatar={avatar}
                imageLocation={facilityDetails?.imageLocation}
                inNetwork={networkStatus}
                lastUpdatedDate={selectedLocation?.lastUpdatedDate}
                primarySpeciality={facilityDetails?.organizationType}
                professionalDesignation={undefined}
                providerId={providerId}
                providerLocations={providerLocations}
                providerName={facilityDetails?.providerName}
                providerType={ProviderType.ORGANIZATION}
                setNavigateToDirections={setNavigateToDirections}
              />
            </CardWrapperDetailsPage>
          ) : null}
          <CardWrapperDetailsPage>
            <ProviderDetailTabs
              chipValue={chipValue}
              coverageType={coverageType}
              facilityDetails={{
                ...facilityDetails,
                providerType: ProviderType.ORGANIZATION,
              }}
              isMapView={isMapView}
              navigateToDirections={navigateToDirections}
              setIsMapView={setIsMapView}
              setNavigateToDirections={setNavigateToDirections}
            />
          </CardWrapperDetailsPage>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
