import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TierProvider } from '../../../../../../../../common/ProviderDetailTabs/ProviderLocationsDetails/ProviderLocationsDetails.style';
import { PSXIcon } from '../../../../../../../../common/PSXIcon';
import { getStatusText } from '../../../../ShareDrawer/utils';
import { tierOneProviderNullStyle } from '../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../utility/compareDrawerConstants';

type Props = {
  isTieredProvider: boolean;
};

export const TierOneProviderText = ({ isTieredProvider }: Props) => {
  const { t } = useTranslation();
  const tierOneProviderText = getStatusText(
    isTieredProvider,
    ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.TRUE,
    ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.FALSE,
    NULL_ATTRIBUTE_STATE.TIER_ONE_PROVIDER
  );

  return (
    <React.Fragment>
      {isTieredProvider ? (
        <Layout.Group css={{ backgroundColor: '#F0F8E4', width: '166px' }}>
          <TierProvider>
            <Layout.Group css={{ gap: '4px' }}>
              <PSXIcon icon="Group 204976975" size={16} />
              <Text css={{ fontWeight: 'bold' }}>{t(tierOneProviderText)}</Text>
            </Layout.Group>
          </TierProvider>
        </Layout.Group>
      ) : isTieredProvider === false ? (
        <Text css={{ fontWeight: 'bold' }}>{t(tierOneProviderText)}</Text>
      ) : (
        <Text css={tierOneProviderNullStyle}>{t(tierOneProviderText)}</Text>
      )}
    </React.Fragment>
  );
};
