import { styled } from '@abyss/web/tools/styled';

export const unOrderedList = {
  listStyle: 'disc',
  paddingLeft: '20px',
};
const mobilePadding = {
  '@screen < $md': {
    padding: '17px 16px',
  },
};
const nullPagePadding = {
  padding: '24px 0',
  borderBottom: '1px solid #E5E5E6',
  marginBottom: '5px',
  ...mobilePadding,
};

export const TopRecommendedStyled = styled('div', {
  borderTop: '1px solid #E5E5E6',
  backgroundColor: '$white',
  ...nullPagePadding,
});

export const TopRecomendedChipsStyled = styled('div', {
  paddingTop: '16px',
});

export const NullPageContainerStyled = styled('div', {
  backgroundColor: '$white',
});

export const CommonlySearchedStyled = styled('div', {
  backgroundColor: '$white',
  borderBottom: '1px solid #E5E5E6',
  ...mobilePadding,
});
