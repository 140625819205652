import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { useEffect, useMemo, useState } from 'react';

import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { getProviderType } from '../../utils/providerDetails.utils';
import { parseProviderSearchFilters } from '../../utils/providerSearch.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getDependentInfo,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import GET_PROVIDER_FILTER_COUNT from './ProviderFilterCount.graphql';

const specialtyCodes = require('../../common/PSXHeader/specialtyCodes.json');

export const useProviderFilterCount = (options) => {
  const [filterOptions, setFilterOptions] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GET_PROVIDER_FILTER_COUNT, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'providerSearch',
    /* istanbul ignore next */
    onCompleted: (result) => {
      const data = result?.data?.providerFilterCount;
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
      setFilterOptions(data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: filterOptions,
  };

  return [parsedResult, queryAction];
};

export const useParsedProviderFilters = (params) => {
  const locale = getLanguage()?.code || 'en';
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { dependentSeqNbr } = tokenData;
  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();
  const planVariationCodeForMedical = getPlanVariationCode(currentMember, 'M');

  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const {
    sectionType = '',
    search = '',
    providerType = '',
    specialtyCode,
    category,
    searchType,
    pcpIndicator = false,
    includeOrgTypeCodes,
    requestType = '',
    searchRadius,
    selectedFilters = {},
    coverageType,
  } = params;

  const specialityCode =
    (specialtyCode || specialtyCodes[category?.toUpperCase()]) ?? '';

  const includeSpecialityRollupCodes = useMemo(
    () => [specialityCode],
    [specialityCode]
  );

  const memberDemographics = {
    gender: currentMember.demographics.gender,
    dateOfBirth: currentMember.demographics.dateOfBirth,
  };

  const filterParams = parseProviderSearchFilters(selectedFilters);

  const [providerFilters, getProviderFilters] = useProviderFilterCount({});

  useEffect(() => {
    getProviderFilters({
      variables: {
        lob: currentMember?.lineOfBusiness,
        coverages: getCoverageTypes(currentMember),
        planYear: getCurrentPlanYear(),
        planVariationCode: planVariationCodeForMedical,
        policyID: currentMember?.policyNumber,
        reciprocityId: getNetworkIdsForPES(currentMember, coverageType),
        locale,
        latitude,
        longitude,
        stateCode,
        providerType: getProviderType(providerType, sectionType),
        search,
        includeSpecialityRollupCodes,
        requestType,
        searchType,
        pcpIndicator,
        includeOrgTypeCodes: includeOrgTypeCodes
          ? JSON.parse(includeOrgTypeCodes)
          : [],
        searchRadius,
        coverageType,
        ...filterParams,
        memberDemographics,
      },
    });
  }, [
    locale,
    latitude,
    longitude,
    stateCode,
    providerType,
    sectionType,
    search,
    JSON.stringify(includeSpecialityRollupCodes),
    searchType,
    pcpIndicator,
    JSON.stringify(includeOrgTypeCodes),
    searchRadius,
    coverageType,
    JSON.stringify(filterParams),
    JSON.stringify(memberDemographics),
  ]);

  return {
    error: providerFilters?.error,
    isLoading: providerFilters?.isLoading,
    totalCount: providerFilters?.data?.totalCount || 0,
    providerFilterList: providerFilters?.data?.providerFilterList || {},
  };
};
