import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';

export const CardContainer = styled(Card, {
  boxShadow: '0px 2px 6px rgba(25, 25, 26, 0.16)',
  flex: 'none',
  gap: '8px',
  borderRadius: '12px',
  border: '1px solid $neutralGray2',
  marginTop: '0 !important',

  height: '198px',
  '@screen < $sm': {
    height: '178px',
  },
  '&.active': {
    border: '2px solid $tint3',
  },
  '&.abyss-card-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  '.account-icon': {
    width: '48px',
    height: '48px',
  },
  '.abyss-heading-root': {
    fontSize: '$lg',
    fontWeight: '$bold',
    lineHeight: '$lg',
    paddingLeft: '$sm',
    '@screen < $md': {
      fontSize: '$sm',
    },
  },
  '.text': {
    fontSize: '$sm',
    lineHeight: '$md',
  },
  '.arrow_forward': {
    width: '12px',
  },
  '.check_circle, .star-icon ': {
    height: '13.33px',
    width: '13.33px',
  },
  '@screen < $md': {
    '.location-icon': {
      width: '20px',
    },
    '.bottom-section': {
      padding: '$sm $sm',
      '.abyss-text-root': {
        fontSize: '$xs',
      },
    },
    '.text': {
      fontSize: '$extraSmallText',
      fontWeight: '$medium',
    },
  },
});

export const DataContainer = styled('div', {
  display: 'grid',
  width: '100%',
  fontSize: '0px',
  border: 'none !important',
  flexDirection: 'column',
});

export const NetworkWrapper = styled(Flex, {
  marginTop: '3px',
  gap: '5px',
  alignItems: 'center',
  '@screen < $sm': {
    marginTop: '4px',
  },
});

export const AvatarDataCard = styled('div', {
  width: '48px',
  height: '48px',
  minWidth: '48px',
  minHeight: '48px',
  borderRadius: '50%',
  overflow: 'hidden',
});

export const VoiceOnlyText = styled('p', {
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  userSelect: 'none',
  width: '1px',
});
export const RatingTextWrapper = styled('div', {
  display: 'inline',
  position: 'relative',
});

export const BrandIconWrapper = styled(Flex, {
  paddingLeft: '$md',
});

export const DataCardAvatarContainer = styled('div', {
  position: 'relative',
});

export const providerNameToolTipStyles = {
  'abyss-tooltip-trigger': {
    maxWidth: '280px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $md': {
      maxWidth: '200px',
    },
  },
};
export const specialityNameToolTipStyles = {
  'abyss-tooltip-trigger': {
    maxWidth: '175px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $sm': {
      maxWidth: '140px',
    },
  },
};
export const addressTextToolTipStyles = {
  'abyss-tooltip-trigger': {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $sm': {
      height: '20px',
      fontSize: '$smallText',
      maxWidth: '200px',
    },
  },
};

export const providerNameDataCardTextStyles = {
  '&.abyss-text-root': {
    maxWidth: '280px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $md': {
      maxWidth: '200px',
    },
  },
};

export const displayVirtualVisitStyle = {
  'abyss-flex-root': {
    display: 'flex',
    borderRadius: '100px',
    border: '1px solid $white',
    background: '$primary1',
    alignItems: 'flex-start',
    padding: '4px',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '0px',
    left: '50px',
    zindex: 1,
  },
};

export const HeadingWrapper = styled(Layout.Group, {
  cursor: 'pointer',
  paddingLeft: '8px',
});

export const PriceText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '$sm',
  },
  '@screen < $md': {
    '&.abyss-text-root': {
      fontSize: '$xs',
    },
  },
});

export const RatingText = styled(Text, {
  marginLeft: '3px',
});

export const ResponsiveText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '16px',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
    maxWidth: '175px',
    '@screen < $sm': {
      fontSize: '$extraSmallText',
      '-webkit-line-clamp': '1',
      maxWidth: '140px',
      lineHeight: '16px',
    },
  },
  wordBreak: 'break-word',
  variants: {
    ancillarySpeciality: {
      true: {
        '&.abyss-text-root': {
          '-webkit-line-clamp': '1',
          textAlign: 'left',
          maxWidth: '230px',
          '@screen < $sm': {
            maxWidth: '200px',
          },
        },
      },
    },
  },
});

export const MilesButton = styled('button', {
  display: 'flex',
});

export const VirtualVisitLabel = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    verticalAlign: 'middle',
  },
});

export const NoRatingText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '$xs',
    fontStyle: 'italic',
  },
  wordBreak: 'break-word',
});

export const AddressText = styled(Text, {
  fontSize: '$md',
  '@screen < $md': {
    '&.abyss-text-root': {
      fontSize: '$xs',
    },
  },
  '@screen < $sm': {
    '&.abyss-text-root': {
      fontSize: '$smallText',
      maxWidth: '200px',
    },
  },
  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
  display: 'inline-block',
  overflow: 'hidden',
  maxWidth: '250px',
});

export const MilesAndAddressWrapper = styled('div', {
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  padding: '4px 16px',
  '@screen < $sm': {
    padding: '2px 12px',
  },
});
export const AddressWrapper = styled('div', {
  marginTop: '2px',
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  '@screen < $sm': {
    paddingTop: '2px',
  },
});

export const ProviderCardContent = styled('div', {
  '@screen < $md': {
    paddingBottom: '0',
  },
});

export const BottomWrapper = styled(Flex, {
  borderTop: '1px solid #CBCCCD',
  padding: '8px 16px',
  flexWrap: 'nowrap',
  span: {
    padding: '$xs 0',
  },
  '.icon-text-container': {
    alignItems: 'center',
  },
  '@screen < $sm': {
    padding: '8px 12px',
  },
});

export const BottomWrapperChoosePCP = styled(Flex, {
  borderTop: '1px solid #CBCCCD',
  padding: '8px 16px',
  flexWrap: 'nowrap',
  span: {
    padding: '$xs 0',
    whiteSpace: 'nowrap',
  },
  '.abyss-button-root': {
    width: '128px',
    height: '42px',
    border: '2px solid $primary1',
    '@screen < $sm': {
      height: '28px',
    },
  },
  '@screen < $sm': {
    padding: '8px 12pxs',
  },
});

export const Dot = styled('div', {
  height: '4px',
  width: '4px',
  borderRadius: '50px',
  background: '$gray8',
});

export const DirectionWrapper = styled(Flex, {
  gap: '4px',
  alignItems: 'center',
});

export const ChooseAsPCPButton = styled(Button, {
  fontSize: '$md',
  fontWeight: '$semibold',
  lineHeight: '20px',
  padding: '0px !important',
});
