import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Flex } from '@abyss/web/ui/Flex';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';

const buttonStyles: any = {
  'abyss-button-root': {
    height: '42px',
    marginTop: '20px',
    border: 'none',
    borderRadius: '24px',
  },
};

type Radio = {
  label: String;
  value: string | number | boolean;
  count?: number;
};

interface Props {
  groupLabel?: string;
  options: Array<Radio>;
  defaultValue?:
    | string[]
    | number[]
    | boolean[]
    | (string | number | boolean)[];
  disableSubmitBtn?: boolean;
  model: string;
  onApply?: (currentItem: string[]) => void;
  onOptionChange?: (any) => void;
  submitBtnName?: string;
  labelFontSize?: string;
  locationForAnalytics?: string;
}

export const FilterCheckBoxGroup = ({
  options,
  defaultValue,
  groupLabel,
  model,
  onOptionChange = () => {},
  onApply = () => {},
  submitBtnName,
  disableSubmitBtn = false,
  labelFontSize = '16px',
  locationForAnalytics,
}: Props) => {
  const [currentItem, setCurrentItem] = useState(
    defaultValue?.map(String) || []
  );

  useEffect(() => {
    setCurrentItem(defaultValue?.map(String) || []);
  }, [JSON.stringify(defaultValue)]);

  const onSubmit = () => {
    onApply(currentItem);
  };

  const getLabelName = (filter: string) =>
    options.find((option) => option.value.toString() === filter)?.dnt_label;

  function onChangeCheckboxGroupOption(e) {
    let linkName;

    if (!isEqual(currentItem, e)) {
      if (isEmpty(currentItem) && e?.length === 1) {
        linkName = `${getLabelName(e[0])}:check`;
      } else {
        let updatedOption;
        if (e?.length > currentItem?.length) {
          updatedOption = difference(e, currentItem);
          linkName = `${getLabelName(updatedOption[0])}:check`;
        }
        if (e?.length < currentItem?.length) {
          updatedOption = difference(currentItem, e);
          linkName = `${getLabelName(updatedOption[0])}:uncheck`;
        }
      }

      adobeLinkTrackEvent({
        name: linkName,
        location: locationForAnalytics,
        type: 'checkbox',
      });
    }

    setCurrentItem(e);
    onOptionChange({
      [model]: options.filter((option) => e.includes(option.value.toString())),
    });
  }

  return (
    <React.Fragment>
      <Flex
        css={{
          'abyss-flex-root': {
            maxHeight: '314px',
            overflow: 'auto',
          },
        }}
        direction="column"
      >
        <CheckboxGroup
          css={{
            'abyss-checkbox-group-label': {
              margin: '0',
            },
            'abyss-checkbox-group-root': {
              border: 'none',
            },
          }}
          data-auto-testid={`${model}-group`}
          data-testid={`${model}-group`}
          label={groupLabel}
          onChange={(e) => {
            onChangeCheckboxGroupOption(e);
          }}
          validators={{ required: false }}
          value={currentItem}
        >
          {options.map(({ value, label, count }) => (
            <Checkbox
              key={label}
              css={{
                'abyss-checkbox-root': {
                  padding: '12px 0px 12px $xs',
                  margin: '0 !important',
                  alignItems: 'center !important',
                },
                'abyss-checkbox-label': {
                  fontSize: labelFontSize,
                  color: currentItem?.includes(value.toString())
                    ? '$gray8'
                    : '$gray7',

                  fontWeight: currentItem?.includes(value.toString())
                    ? '$bold'
                    : '$medium',
                },
              }}
              data-testid="checkbox-selection"
              isDisabled={count === 0}
              label={count || count === 0 ? `${label} (${count})` : label}
              value={value.toString()}
            />
          ))}
        </CheckboxGroup>
      </Flex>
      {submitBtnName ? (
        <Button
          css={buttonStyles}
          data-auto-testid="apply-button"
          data-testid="apply-button"
          isDisabled={disableSubmitBtn}
          onClick={onSubmit}
        >
          {submitBtnName}
        </Button>
      ) : null}
    </React.Fragment>
  );
};
