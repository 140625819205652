import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';

export const RatingContainer = styled(Flex, {
  variants: {
    decemberFlag: {
      true: {
        height: '14px',
        lineHeight: '16px',
      },
    },
  },

  lineHeight: '20px',
  textAlign: 'center',
  gap: '8px',
});

export const RatingText = styled(Text, {
  fontWeight: 700,
  fontSize: '$extraSmallText !important',
  '@screen < $sm': {
    fontWeight: 500,
  },
});

export const RatingTextLink = styled(Link, {
  variants: {
    decemberFlag: {
      true: {
        '.abyss-link-root': {
          lineHeight: '16px',
        },
      },
    },
  },
  fontSize: '$extraSmallText !important',
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const RatingsStar = styled(Flex, {
  gap: '2px',
});

export const VoiceOnlyText = styled('p', {
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  userSelect: 'none',
  width: '1px',
});
