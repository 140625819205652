import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../hooks/useLagoon';
import { ProviderDetails } from '../../../models/ProviderDetails';
import {
  capitalizeEachWord,
  getSelectedLocationIndex,
  shortenZipCode,
} from '../../../utils/providerDetails.utils';
import { Constants } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { h2SmallerFontForResponsive, mobileOnly } from '../../ConstantsStyles';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
import {
  convertProviderTypeToAdobeType,
  handleLinkAndModalTrack,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  ContentText,
  PopoverContainer,
  PopoverContentContainer,
  PremiumCareSection,
  PremiumTextIconStyle,
  QualityGrid,
  QualityText,
  StackDiv,
  TabContainer,
} from './Quality.styled';

type Props = {
  boardCertifications?: string[];
  providerDetails: ProviderDetails;
  tabTitle: string;
};

export const Quality = ({
  boardCertifications,
  providerDetails,
  tabTitle,
}: Props) => {
  const mobileScreen = useMediaQuery(mobileOnly);
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
  });

  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  useEffect(() => {
    if (providerDetails.providerName) adobePageTrackEvent();
  }, [providerDetails]);

  const index = getSelectedLocationIndex(
    providerDetails?.providerLocations,
    selectedId
  );
  const selectedLocation = providerDetails?.providerLocations?.[index];

  const addressLine = selectedLocation?.address?.line;
  const city = selectedLocation?.address?.city;
  const state = selectedLocation?.address?.state;
  const postalCode = shortenZipCode(selectedLocation?.address?.postalCode);
  const distance = selectedLocation?.distance;

  const { isPremiumCarePhysician } = providerDetails.providerLocations[index];
  const navigationURL =
    'https://prod.member.myuhc.com/content/dam/projects/myuhc-legacy/en/member/prelogin/pdf/geoaccess/UnitedHealth_Premium_Program_Overview.pdf';

  const data = useLagoon('ui-messaging')();
  const premiumCarePhysicianContent = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.PREMIUM_CARE_PHYSICIAN_CONTENT,
  });

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const linkName: string = Constants.PROVIDER_DETAILS.QUALITY_TAB.PREMIUM;
      const linkLocation: string = `body:${convertProviderTypeToAdobeType(
        providerDetails.providerType
      )} details`;
      const modalName: string = Constants.PROVIDER_DETAILS.QUALITY_TAB.PREMIUM;
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  const premiumCarePopoverContent = (
    <PopoverContentContainer>
      <ContentText>
        <Text
          color="#323334"
          fontWeight={mobileScreen ? '500' : '400'}
          size={mobileScreen ? '$md' : '14px'}
        >
          {premiumCarePhysicianContent?.message ?? ''}
        </Text>
      </ContentText>
      <LinkWithTracking
        analyticsInfo={{
          name: 'Get my premium care',
          location: `modal:${Constants.PROVIDER_DETAILS.QUALITY_TAB.PREMIUM}`,
          type: 'download',
          destinationUrl: navigationURL,
        }}
        data-auto-testid="premium-care-physician-popover"
        data-testid="premium-care-physician-popover"
        href={navigationURL}
      >
        <Text
          color="#196ECF"
          css={{
            margin: '4px 0 4px 0',
            '@screen < $sm': {
              margin: '8px 0 8px 0',
            },
          }}
          fontWeight="700"
          size="$md"
        >
          {t('PROVIDER_DETAILS.QUALITY_TAB.POPOVER.POPOVER_LINK_TEXT')}
        </Text>
      </LinkWithTracking>
    </PopoverContentContainer>
  );

  return (
    <TabContainer key={Constants.PROVIDER_DETAILS.QUALITY}>
      <Layout.Stack alignItems="center" grow space="0">
        {isPremiumCarePhysician && (
          <StackDiv>
            <PremiumCareSection space="0">
              <IconMaterial
                color="$primary1"
                icon="favorite"
                size={mobileScreen ? '20' : '24'}
              />
              <IconMaterial
                color="$primary1"
                css={PremiumTextIconStyle}
                icon="favorite"
                size={mobileScreen ? '20' : '24'}
              />
              <Heading css={h2SmallerFontForResponsive} display="h6" offset={1}>
                {t('PROVIDER_DETAILS.QUALITY_TAB.PREMIUM')}
              </Heading>
              <PopoverContainer
                content={premiumCarePopoverContent}
                onOpenChange={() => setIsOpen(!isOpen)}
                open={isOpen}
                title={t('PROVIDER_DETAILS.QUALITY_TAB.PREMIUM')}
                width={363}
              />
            </PremiumCareSection>
            <Layout.Stack alignItems="left" space="0">
              <QualityText>{addressLine?.join(', ')}</QualityText>
              <QualityText>
                {city}, {state} {postalCode} ({distance} {t('miles')})
              </QualityText>
            </Layout.Stack>
          </StackDiv>
        )}

        <StackDiv>
          <QualityGrid space="0">
            <Grid.Col span={{ xs: '100%', sm: '100%', md: '50%' }}>
              <Layout.Stack alignItems="left" space="12">
                <Heading
                  css={h2SmallerFontForResponsive}
                  display="h4"
                  offset={1}
                >
                  {t('PROVIDER_DETAILS.QUALITY_TAB.BOARD_CERTIFICATIONS_TITLE')}
                </Heading>
                {boardCertifications?.length ? (
                  boardCertifications?.map((certification) => (
                    <QualityText key={`certification-${certification}`}>
                      {capitalizeEachWord(certification)}
                    </QualityText>
                  ))
                ) : (
                  <QualityText>
                    {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                  </QualityText>
                )}
              </Layout.Stack>
            </Grid.Col>
          </QualityGrid>
        </StackDiv>
      </Layout.Stack>
    </TabContainer>
  );
};
