import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  CoverageTypesCodes,
  ReverseCoverageTypesCodes,
} from '../../../../../common/Constants';
import { ConstantsRoutes } from '../../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../../../common/ContentWrapper';
import { ImageComponent } from '../../../../../common/ImageComponent';
import { LinkWithTracking } from '../../../../../common/LinkWithTracking/LinkWithTracking';
import { ChoosePCPPhoto } from '../../../../../common/PrimaryCareProvider/PrimaryCareProvider.styled';
import { Breadcrumb } from '../../../../../common/PSXHeader/Breadcrumb';
import { useAdobePageTrackEvent } from '../../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { BreadcrumbSessionStorage } from '../../../../../models/BreadcrumbSessionStorage';
import { shortenZipCode } from '../../../../../utils/providerDetails.utils';
import {
  ChooseLocationCardsSuccessContainer,
  ClinicNameAddress,
  ClinicSubAddressSuccessPage,
  ContentContainer,
  LocationsDropdown,
  PCPHeaderContainer,
  SubSuccessProviderSpeciality,
  SuccessCheckMark,
  SuccessMessageHeading,
  SuccessPageBackButton,
  SuccessPageYourPCPHeader,
  SuccessPageYourPCPLocationHeader,
  SuccessSelectedPCPBox,
  TextContainer,
} from './ChoosePCPSuccess.styled';
import { SuccesssMapViewWrap } from './SuccessMapViewWrap';

export const ChoosePCPSuccess = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { t } = useTranslation();

  const choosePCPSuccess: string = 'choose pcp success';
  const choosePCPSuccessRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const handleBackToFindCare = () => {
    adobeLinkTrackEvent({
      name: 'back to find care',
      location: `body:${choosePCPSuccess}`,
      type: 'internal',
    });

    const selectedCoverageType =
      find(Constants.MOCK_CARE_CATEGORIES, {
        value: Constants.CHIPS_CATEGORIES.PRIMARY_CARE,
      })?.coverageType || CoverageTypesCodes.MEDICAL;
    const updatedToken = tokenizer.create({
      chipValue: Constants.CHIPS_CATEGORIES.PRIMARY_CARE,
      coverageType: ReverseCoverageTypesCodes[selectedCoverageType],
    });

    navigate(`/${updatedToken}`);
  };
  const handleBackToChangePCP = () => navigate(-2);
  const handleBackToChangeLocation = () => navigate(-1);
  const {
    formattedProviderName,
    pcpEffectiveDate,
    selectedLocation,
    providerType,
    primarySpeciality,
    imageLocation,
  } = tokenData;

  const selectedProviderLocation = selectedLocation
    ? JSON.parse(selectedLocation)
    : {};

  const {
    line = '',
    city = '',
    state = '',
    postalCode = '',
  } = selectedLocation ? selectedProviderLocation?.address : {};

  const addressString = `${line}, ${city}, ${state} ${shortenZipCode(
    postalCode
  )}`;

  const [breadcrumbUrls] = useSessionStorage<BreadcrumbSessionStorage>(
    Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
    {}
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: choosePCPSuccess,
    sitesectionLevel1: 'pcp',
  });

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  useEffect(() => {
    choosePCPSuccessRef?.current?.focus();
  }, []);

  const choosePCPSuccessPageDetail = (
    <ChooseLocationCardsSuccessContainer>
      <Flex direction="column">
        <SuccessPageYourPCPHeader>
          <Heading
            css={{
              'abyss-heading-root': {
                fontSize: '$mediumText !important',
                marginBottom: '12px',
                lineHeight: '32px !important',
              },
            }}
            display="h6"
            offset={1}
          >
            <IconMaterial
              color="$success1"
              css={{
                'abyss-icon': {
                  marginRight: '6.5px',
                },
              }}
              icon="check"
              size="15px"
            />
            {t('PCP_LOCATION.YOUR_PCP')}
          </Heading>
          <div style={{ marginLeft: 'auto' }}>
            <LinkWithTracking
              analyticsInfo={{
                location: `body:${choosePCPSuccess}`,
              }}
              aria-label={`${t('Change PCP for')} ${formattedProviderName}`}
              before={<IconMaterial icon="edit" size="$sm" />}
              data-auto-testid="success-page-change-pcp-link"
              data-testid="success-page-change-pcp-link"
              onClick={handleBackToChangePCP}
            >
              <Text
                css={{
                  color: '$info1',
                  fontSize: '$extraSmallText',
                  fontWeight: '$bold',
                  lineHeight: '16px',
                }}
              >
                {t('PCP_LOCATION.CHANGE_PCP')}
              </Text>
            </LinkWithTracking>
          </div>
        </SuccessPageYourPCPHeader>
        <LocationsDropdown>
          <Flex alignItems="center">
            <ChoosePCPPhoto>
              {ImageComponent({ imgRef, providerType, imgSrc: imageLocation })}
            </ChoosePCPPhoto>
            <Text
              css={{
                lineHeight: '24px',
                'abyss-text-root': {
                  marginLeft: '8px',
                  marginTop: '10px',
                  '@screen < $md': {
                    fontSize: '14.22px',
                  },
                },
              }}
            >
              <div
                style={{
                  fontSize: '18px',
                  color: '#323334',
                  fontWeight: '700',
                }}
              >
                {formattedProviderName}
              </div>
              <SubSuccessProviderSpeciality>
                {primarySpeciality}
              </SubSuccessProviderSpeciality>
            </Text>
          </Flex>
        </LocationsDropdown>
        <SuccessPageYourPCPLocationHeader>
          <Flex direction="row">
            <Heading
              css={{
                'abyss-heading-root': {
                  fontSize: '$mediumText !important',
                  marginBottom: '8px',
                  lineHeight: '32px !important',
                },
              }}
              display="h6"
              offset={1}
            >
              <IconMaterial
                color="$success1"
                css={{
                  'abyss-icon': {
                    marginRight: '6.5px',
                  },
                }}
                icon="check"
                size="15px"
              />
              {t('PCP_LOCATION.YOUR_PCP_LOCATION')}
            </Heading>

            <Layout.Group style={{ marginLeft: 'auto' }}>
              <LinkWithTracking
                analyticsInfo={{
                  location: `body:${choosePCPSuccess}`,
                }}
                aria-label={`${t('Change location for')} ${addressString}`}
                before={<IconMaterial icon="edit" size="$sm" />}
                data-auto-testid="success-page-change-location-link"
                data-testid="success-page-change-location-link"
                onClick={handleBackToChangeLocation}
              >
                <Text
                  css={{
                    color: '$info1',
                    fontSize: '$extraSmallText',
                    fontWeight: '$bold',
                    lineHeight: '16px',
                  }}
                >
                  {t('PCP_LOCATION.CHANGE_LOCATION')}
                </Text>
              </LinkWithTracking>
            </Layout.Group>
          </Flex>
        </SuccessPageYourPCPLocationHeader>
        <ClinicNameAddress>
          <Flex alignItems="center" justify="space-between">
            <ContentContainer>
              <TextContainer>
                <ClinicSubAddressSuccessPage>
                  {addressString}
                </ClinicSubAddressSuccessPage>
              </TextContainer>
            </ContentContainer>
          </Flex>
        </ClinicNameAddress>
      </Flex>
      <div
        style={{
          justifyContent: 'center',
          flexDirection: 'row',
          marginTop: '31px',
        }}
      >
        <SuccessPageBackButton
          after={
            <IconMaterial
              color="$white"
              css={{ margin: '15px 0 15px -10px' }}
              icon="chevron_right"
              size="24px"
            />
          }
          aria-describedby="reverify"
          data-auto-testid="choose-pcp-back-home"
          data-testid="choose-pcp-back-home"
          onClick={handleBackToFindCare}
        >
          {t('Back to Find Care')}
        </SuccessPageBackButton>
      </div>
    </ChooseLocationCardsSuccessContainer>
  );
  return (
    <React.Fragment>
      <PCPHeaderContainer>
        <ContentWrapper>
          <SuccessSelectedPCPBox color="#FBFCFE">
            {!mobileScreen ? (
              <Breadcrumb
                breadcrumbs={[
                  {
                    title: t('BC Results'),
                    href: `${
                      breadcrumbUrls[
                        ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
                      ]
                    }`,
                  },
                  {
                    title: t('PCP_LOCATION.PCP Chosen'),
                    href: '',
                  },
                ]}
              />
            ) : null}
            <SuccessCheckMark>
              <div>
                <IconBrand
                  color="#FFFFFF"
                  icon="checkmark"
                  size={!mobileScreen ? '40' : '32'}
                  variant={
                    !mobileScreen ? 'twotonelightcircle' : 'twotonedarkcircle'
                  }
                />
              </div>
              <SuccessMessageHeading
                ref={choosePCPSuccessRef}
                data-auto-testid="success-message-heading"
                data-testid="success-message-heading"
                tabIndex={-1}
              >
                <h1
                  aria-label={`${t(
                    'PCP_LOCATION.SUCCESS_LOCATION'
                  )} ${'for'} ${formattedProviderName}`}
                >
                  {t('PCP_LOCATION.SUCCESS_LOCATION')}
                </h1>
                {pcpEffectiveDate ? (
                  <span>
                    {t('PCP_LOCATION.SUCCESS_LOCATION_INFO5')}{' '}
                    {pcpEffectiveDate}.
                  </span>
                ) : null}
              </SuccessMessageHeading>
            </SuccessCheckMark>
          </SuccessSelectedPCPBox>
        </ContentWrapper>
      </PCPHeaderContainer>
      <ContentWrapper>
        <Box
          color="white"
          css={{ position: 'relative' }}
          height="unset"
          padding={0}
        >
          <SuccesssMapViewWrap
            providerLocations={[selectedProviderLocation]}
            successPageDetails={choosePCPSuccessPageDetail}
          />
        </Box>
      </ContentWrapper>
    </React.Fragment>
  );
};
