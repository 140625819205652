import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIConstants } from '../../../../api/src/services/Constants';
import { useSnackCardProviderResults } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { useTranslationContent } from '../../hooks/useTranslationContent';
import { Specialty, TranslationType } from '../../models/TranslationContent';
import { getSnackCardDisplayValByLength } from '../../utils/providerDetails.utils';
import { getSpecialityDescription } from '../../utils/specialty.utils';
import { Constants } from '../Constants';
import { ConstantsRoutes } from '../ConstantsRoutes';
import {
  cardSubHeaderContainerStyle,
  cardSubHeaderWithNavigatorStyle,
  landingPopOverIcon,
  phoneOnly,
} from '../ConstantsStyles';
import { LinkContainer } from '../LinkContainer/LinkContainer';
import { SnackCardCarousel, SnackCardContainer } from '../SnackCardContainer';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import { mockVisionCareSnackCardProviders } from './mockVisionCareProviderSnackCards';

const CardResultContainer = styled('div', {
  marginTop: '$md',
});

type CardGroup = {
  heading?: string;
  headingNotTranslated?: string;
  specialtyCode?: string;
};

type Props = {
  section: CardGroup;
  cardType: string;
};

export const VisionCare = ({ section, cardType }: Props) => {
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();
  const visionCarePopOverTestId = `vision-care-popover-${section?.headingNotTranslated}`;

  useEffect(() => {
    useTranslationContent(TranslationType.SPECIALTY).then((data) =>
      setSpecialties(data?.default)
    );
  }, []);

  useEffect(() => {
    if (isOpen) {
      const linkName: string = visionCarePopOverTestId;
      const linkLocation: string = `body:${section.headingNotTranslated}`;
      const modalName: string =
        typeof section.headingNotTranslated !== 'string'
          ? String(section.headingNotTranslated)
          : section.headingNotTranslated;
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  const { data, isLoading, headers } = useSnackCardProviderResults({
    includeSpecialityRollupCodes: section.specialtyCode,
    providerType: APIConstants.PROVIDER_TYPES.practitioner,
  });

  const providersData = data?.snackCardList?.length
    ? { ...data }
    : { ...mockVisionCareSnackCardProviders };

  const numberOfCards = providersData?.snackCardList?.length;
  const displayCarousel = getSnackCardDisplayValByLength(numberOfCards);

  const handleViewAllOnClick = () => {
    const updatedToken = tokenizer.update(token, {
      // temporary Dermatologists specialty code for testing
      // should be replaced with specialtyCode once  speciality codes for vision are available
      specialtyCode: '02-012-007,02-012-117,02-012-357',
      search: section.heading,
      sectionType: Constants.RESULT_SECTION.PROVIDER_CAPITAL,
      providerType: APIConstants.PROVIDER_TYPES.practitioner,
      searchType: APIConstants.PES.SPECIALTY,
      searchRadius: data?.searchRadius,
    });

    navigate(
      `${ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.path}/${updatedToken}`
    );
  };

  return (
    <CardResultContainer>
      <Flex
        key={section.heading}
        css={cardSubHeaderContainerStyle}
        justify="start"
      >
        <Heading
          css={cardSubHeaderWithNavigatorStyle}
          data-auto-testid={`vision-care-heading-${section?.heading}`}
          data-testid={`vision-care-heading-${section?.heading}`}
          display="h6"
          offset={2}
        >
          {section.heading}
          <span
            style={{
              marginLeft: '4px',
              display: 'inline-block',
            }}
          >
            <Popover
              aria-label={`${t('INFORMATION_ABOUT')} ${section.heading}`}
              content={getSpecialityDescription(specialties, section?.heading)}
              css={{
                'abyss-popover-content': {
                  zIndex: 9,
                },
              }}
              data-auto-testid={visionCarePopOverTestId}
              data-testid={visionCarePopOverTestId}
              onOpenChange={() => setIsOpen(!isOpen)}
              open={isOpen}
              title={section?.heading}
              width={mobileScreen ? 222 : 180}
            >
              <IconMaterial
                css={landingPopOverIcon}
                data-auto-testid={visionCarePopOverTestId}
                data-testid={visionCarePopOverTestId}
                icon="info"
                onClick={() => setIsOpen(!isOpen)}
                size={16}
                variant="outlined"
              />
            </Popover>
          </span>
        </Heading>
        {isLoading ? null : (
          <LinkContainer
            analyticsInfo={{
              location: `body:${section.headingNotTranslated}`,
            }}
            aria-label={`${t('View all')} ${section.heading}`}
            data-auto-testid={`vision-care-view-all-link-${section?.heading}`}
            data-testid={`vision-care-view-all-link-${section?.heading}`}
            onClick={handleViewAllOnClick}
            text={`${t('View all')} (${providersData?.totalCount})`}
          />
        )}
      </Flex>
      {providersData &&
      cardType === Constants.RESULT_SECTION.PROVIDER_CAPITAL ? (
        <div>
          {displayCarousel ? (
            <SnackCardCarousel
              headers={headers}
              isFixes
              isLoading={isLoading}
              locationForAnalytics={section.headingNotTranslated}
              snackCardProviders={providersData?.snackCardList}
            />
          ) : (
            <SnackCardContainer
              headers={headers}
              isLoading={isLoading}
              locationForAnalytics={section.headingNotTranslated}
              snackCardProviders={providersData?.snackCardList}
            />
          )}
        </div>
      ) : (
        t('No Results Found')
      )}
    </CardResultContainer>
  );
};
