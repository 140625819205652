import { config } from '@abyss/web/tools/config';
import React, { useEffect, useState } from 'react';

import { IChildrenProp } from '../../types';

export const GlobalNav = ({ children }: IChildrenProp) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      // GLOBAL NAV HEADER & FOOTER
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = config('GLOBAL_NAV_ENI_URL');
      document.head.appendChild(script);
      setIsLoaded(true);
    }
  }, []);

  if (!isLoaded) return null;
  return <React.Fragment>{children}</React.Fragment>;
};
