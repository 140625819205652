import { usePrint } from '@abyss/web/hooks/usePrint';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';

const PrintButtonStyled = styled(Button, {
  variants: {
    view: {
      mobile: {
        '&.abyss-button-root': {
          width: '100px',
          color: '$gray7',
          fontWeight: '$medium',
        },
      },
      desktop: {
        '&.abyss-button-root': {
          paddingRight: 0,
        },
      },
    },
  },
});

type Props = {
  locationForAnalytics: string;
  view: string;
};

export const PrintButton = ({ locationForAnalytics, view }: Props) => {
  const print = usePrint();
  const { t } = useTranslation();

  return (
    <PrintButtonStyled
      data-auto-testid="mapview-print-button"
      data-testid="mapview-print-button"
      onClick={() => {
        adobeLinkTrackEvent({
          name: 'print',
          location: locationForAnalytics,
          type: 'internal',
        });
        print.printPage();
      }}
      variant="tertiary"
      view={view}
    >
      <IconMaterial
        css={{
          'abyss-icon': {
            marginRight: '$xs',
          },
        }}
        icon="print"
      />
      {t('SHARE_DRAWER_TEXT.PRINT')}
    </PrintButtonStyled>
  );
};
