import { config } from '@abyss/web/tools/config';
import { storage } from '@abyss/web/tools/storage';

import { Constants } from '../../common/Constants';
import { SESSION_COOKIE, parseSessionCookie } from '../../utils/session.utils';
import { useCustomQuery } from '../useCustomQuery';
import GET_HEART_BEAT_API from './Heartbeat.graphql';

export const useHeartbeat = (options) => {
  const sessionCookie = storage.cookie.get(SESSION_COOKIE) || '';
  const { SUPER_USER } = Constants.STORAGE_KEYS.SESSION;
  // eslint-disable-next-line no-param-reassign
  options.headers = {
    'xsrf-token': parseSessionCookie(sessionCookie)?.sessionId,
    'rp-token-suffix': 'MU',
    ...options.headers,
  };

  const [queryResult, queryAction] = useCustomQuery(GET_HEART_BEAT_API, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    requestPolicy: 'no-cache',
    accessor: 'heartBeat',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
    },
  });
  storage.session.set(SUPER_USER, queryResult?.data?.heartbeat?.isSuperUser);
  return [queryResult, queryAction];
};
