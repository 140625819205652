import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { ResponseHeaders } from '#/models/ResponseHeaders';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import update from 'lodash/update';
import { ChangeEvent, useCallback, useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeviceLocation } from '../../../../hooks/useDeviceLocation';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useLocation } from '../../../../hooks/useLocation';
import { adobeImpressionTrackEvent } from '../../../AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants, NULL_RESULTS } from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { mobileOnly } from '../../../ConstantsStyles';
import { getFeatureFlag } from '../../../Utils';
import {
  CustomAttributesBlock,
  convertTypeaheadProviderIdAndType,
  getTypeAheadCategory,
} from '../../../Utils/adobeTrackUtils/adobeTrackUtils';
import { refactorPlaceName } from '../../../Utils/locationSearch.util';
import { LocationSearchMobile } from '../../LocationSearchMobile/LocationSearchMobile';
import { handleSearchSelected } from '../../SearchInputBox/TypeaheadSearch';
import {
  IconContainer,
  InputDivider,
  LocationInputContainer,
  LocationInputRow,
  LocationSearchLabel,
  LocationSearchPiece,
  LocationSearchPieceContainer,
} from '../SearchBar.styled';
import { getGeoLocationFromStorage, setGeoLocationToStorage } from '../utils';
import {
  ButtonContainer,
  Input,
  LayoutGroupStyle,
} from './LocationInput.styled';

interface IProps {
  isShowingLocationDropdown: boolean;
  onLocationSearchInputFocus: () => void;
  onLocationSearchInputTextChange: (newValue: string) => void;
  setLocationSuggestions: (value: object[]) => void;
  setIsLocationLoading: (value: boolean) => void;
  handleCloseLocationDrawer: () => void;
  isShowingLocationDrawer: boolean;
  handleCloseLocationDropdown: () => void;
  setCursor: any;
  cursor: number;
  memberLocation: string;
  searchTerm: string;
  typeaheadData: any;
  locationValue: string;
  setLocationInputVal: (value: string) => void;
  setIsFocusedOnKeywordSearchInput: (val: boolean) => void;
  llmSearch: any;
  setIsOpenMobileLocation: (value: boolean) => void;
  isOpenMobileLocation: boolean;
  headers: ResponseHeaders;
}

export const LocationInput = ({
  isShowingLocationDropdown,
  onLocationSearchInputFocus,
  onLocationSearchInputTextChange,
  setLocationSuggestions,
  setIsLocationLoading,
  handleCloseLocationDrawer,
  headers,
  isShowingLocationDrawer,
  handleCloseLocationDropdown,
  cursor,
  setCursor,
  memberLocation,
  searchTerm,
  typeaheadData,
  locationValue,
  setLocationInputVal,
  setIsFocusedOnKeywordSearchInput,
  llmSearch,
  isOpenMobileLocation,
  setIsOpenMobileLocation,
}: IProps) => {
  const { t } = useTranslation();
  const locationSearchInputId = useId();
  const featureFlags: [{ key: string; active: boolean }] = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_FLAGS
  )();
  const decemberFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );
  const geoLocation = getGeoLocationFromStorage();
  const { name: locationName } = geoLocation;
  const { getRouteParams, navigate } = useRouter();
  const { token } = getRouteParams();
  let typeAheadLocationTextForAnalytics;

  const [, setDeviceLocation] = useState<string>(
    getGeoLocationFromStorage() ?? ''
  );
  const [locationSearchResults, getLocation] = useLocation({
    onCompleted: (result: {
      data: {
        location: {
          features: [
            { place_name: string; id: string; center: [string, string] }
          ];
        };
      };
    }) => {
      setIsLocationLoading(false);
      const resultSuggestions = cloneDeep(result?.data?.location?.features);

      adobeImpressionTrackEvent({
        searchTerm: typeAheadLocationTextForAnalytics,
        type: 'location search',
        message:
          resultSuggestions.length > 0
            ? 'results found for your search'
            : 'no results found for your search',
      });

      resultSuggestions?.map((loc) =>
        update(loc, 'place_name', () => refactorPlaceName(loc.place_name))
      );
      setLocationSuggestions(resultSuggestions);
    },
  });

  const [, getInitialLocation] = useLocation({
    onCompleted: (result: {
      data: {
        location: {
          features: [
            {
              place_name: string;
              id: string;
              center: [string, string];
              stateCode: string;
            }
          ];
        };
      };
    }) => {
      const targetLocation = result?.data?.location?.features?.[0] || {};
      const [long, lat] = targetLocation.center || [];
      const placeName = targetLocation.place_name;
      // set geo coords
      setGeoLocationToStorage({
        name: placeName,
        longitude: long,
        latitude: lat,
        stateCode: targetLocation.stateCode,
      });
    },
  });

  const { getDeviceLocation } = useDeviceLocation(
    setDeviceLocation,
    setIsOpenMobileLocation
  );
  const mobileScreen = useMediaQuery(mobileOnly);

  useEffect(() => {
    if (isEmpty(geoLocation)) {
      const getCurrentLocation = async () => {
        await getDeviceLocation();
        if (isEmpty(geoLocation)) {
          getInitialLocation({
            variables: {
              address: memberLocation,
              countySearchEnabled: decemberFlag,
            },
          });
        }
      };
      getCurrentLocation();
    }
  }, []);

  useEffect(() => {
    setLocationInputVal(locationName);
  }, [locationName]);

  const handleLocationChange = async (loc: string) => {
    typeAheadLocationTextForAnalytics = loc;
    await getLocation({
      variables: {
        address: loc,
        countySearchEnabled: decemberFlag,
      },
    });
  };

  const handleLocationSearchTextChange = useCallback(
    debounce(async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      onLocationSearchInputTextChange(value);
      if (value?.trim().length) {
        await handleLocationChange(value);
      }
    }, 300),
    [onLocationSearchInputTextChange, handleLocationChange]
  );

  const handleLocationSearchKeyDown = (ev: {
    key: string;
    preventDefault: () => void;
  }) => {
    const minCursor = -1;
    const maxCursor =
      locationSearchResults?.data?.location?.features?.length - 1;

    if (['ArrowUp', 'ArrowDown'].some((key) => key === ev.key)) {
      ev.preventDefault();
    }

    if (ev.key === 'Enter') {
      if (isShowingLocationDrawer) {
        handleCloseLocationDrawer();
      }
      if (isShowingLocationDropdown) {
        handleCloseLocationDropdown();
      }
    } else if (ev.key === 'ArrowUp' && cursor > minCursor) {
      setCursor((previous) => previous - 1);
    } else if (ev.key === 'ArrowDown' && cursor < maxCursor) {
      setCursor((previous) => previous + 1);
    }
  };

  const clickSearchButton = (data: any) => {
    const analyticsLinkName = 'search button';
    let customAttributesBlock;
    if (searchTerm?.length > 1) {
      customAttributesBlock = data
        ? ({
            correlationId: headers?.correlationId,
            ...convertTypeaheadProviderIdAndType(data),
          } as CustomAttributesBlock)
        : undefined;
      setIsFocusedOnKeywordSearchInput(false);
      handleSearchSelected(
        {
          ...data,
          searchTerm,
          linkName: analyticsLinkName,
          searchMethod: 'typed',
          resultType: data ? Constants.RESULT_SECTION.ALL : NULL_RESULTS,
          search: searchTerm,
        },
        token,
        navigate,
        llmSearch
      );
    }

    let analyticsLocationName = 'search-input-option-list';
    let analyticsSearchBlock;
    if (data?.searchType) {
      analyticsLocationName += `:${getTypeAheadCategory(data?.resultType)}`;
      analyticsSearchBlock = {
        linkPosition: '1',
      };
    }
    adobeLinkTrackEvent({
      name: analyticsLinkName,
      location: analyticsLocationName,
      type: 'internal',
      destinationUrl: '',
      ...(analyticsSearchBlock && { searchBlock: analyticsSearchBlock }),
      customAttributesBlock,
    });
  };
  return !mobileScreen ? (
    <LocationInputContainer>
      <LocationInputRow>
        {!isShowingLocationDropdown && <InputDivider />}
        <LocationSearchPieceContainer
          data-testid="location-search-container"
          isShowingLocationDropdown={isShowingLocationDropdown}
        >
          <LocationSearchLabel
            data-testid="location-search-label"
            htmlFor={locationSearchInputId}
          >
            {t('Location')}
          </LocationSearchLabel>
          <LocationSearchPiece>
            <IconContainer>
              <IconMaterial color="$gray8" icon="location_on" size={24} />
            </IconContainer>
            <Input
              aria-describedby="initInstr"
              aria-haspopup="listbox"
              autoComplete="off"
              data-testid="location-search-input"
              id={locationSearchInputId}
              onChange={(e) => {
                setLocationInputVal(e.target.value);
                setIsLocationLoading(true);
                handleLocationSearchTextChange(e);
              }}
              // onClick={() => setLocationInputVal('')}
              onFocus={onLocationSearchInputFocus}
              onKeyDown={handleLocationSearchKeyDown}
              type="text"
              value={locationValue}
            />
          </LocationSearchPiece>
        </LocationSearchPieceContainer>

        {!mobileScreen && (
          <Layout.Group css={LayoutGroupStyle}>
            <ButtonContainer
              data-testid="search-button"
              disabled={searchTerm?.length <= 1}
              onClick={() => {
                clickSearchButton(typeaheadData);
              }}
            >
              {t('Search')}
            </ButtonContainer>
          </Layout.Group>
        )}
      </LocationInputRow>
    </LocationInputContainer>
  ) : (
    <Drawer
      css={{
        'abyss-modal-close-button': {
          marginTop: '$md',
          marginRight: '8px',
        },
        'abyss-modal-header-container': {
          marginLeft: '$sm',
        },
        'abyss-modal-content-container': {
          borderRadius: '20px 20px 0px 0px',
        },
      }}
      isOpen={isOpenMobileLocation}
      onClose={() => setIsOpenMobileLocation(false)}
      position="bottom"
      size="$lg"
      title={
        <Heading color="$gray8" offset={4}>
          {!locationName ? t('My search location') : t('Location Change')}
        </Heading>
      }
    >
      <LocationSearchMobile
        findUserLocation={getDeviceLocation}
        location={locationName}
        setIsOpen={setIsOpenMobileLocation}
        setLocation={(targetLocation) => {
          const [selectedLong, selectedLat] = targetLocation.center || [];
          const placeName = targetLocation.place_name;
          setGeoLocationToStorage({
            name: placeName,
            longitude: selectedLong,
            latitude: selectedLat,
            stateCode: targetLocation.stateCode,
          });
        }}
      />
    </Drawer>
  );
};
