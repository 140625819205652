import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { OPTUM_CORRELATION_ID_HEADER } from '../../common/Constants';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import {
  getAutoIncrementRadius,
  getLagoonConfigValue,
  getSearchRadius,
} from '../../utils/providerSearch.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import GET_SNACK_CARD_PROVIDER_SEARCH_QUERY from './SnackCardProviderSearch.graphql';

export interface SnackCardProvider {
  providerId: string;
  providerName: string;
  providerType: string;
  primaryDegrees?: string[];
  specialities?: string[];
  distance: string;
  locationId: string;
  networkStatus?: string;
  npi: string;
  healthGradeRating?: string;
  organizationType?: string[];
  speciality: string;
  imageLocation: string;
  isTieredProvider?: boolean;
  website?: string;
}

const filterSearchResults = (searchResult) => {
  const providerSearchResult = get(searchResult, 'snackCardProviderSearch', {});
  const filteredResult = providerSearchResult.snackCardList;
  return {
    totalCount: providerSearchResult.totalCount,
    snackCardList: filteredResult,
    searchRadius: providerSearchResult?.searchRadius,
  };
};

export const useSnackCardProviderSearch = (options) => {
  const [filtredResult, setFiltredResultResult] = useState({});
  const [headers, setHeaders] = useState({});
  const [queryResult, queryAction] = useCustomQuery(
    GET_SNACK_CARD_PROVIDER_SEARCH_QUERY,
    {
      ...options,
      url: config('GRAPHQL_API_URL'),
      accessor: 'snackCardProviderSearch',
      onCompleted: async (result) => {
        const data = filterSearchResults(result?.data);
        if (options?.onCompleted) {
          options.onCompleted(data);
        }
        setFiltredResultResult(data);
        setHeaders({
          correlationId: result.headers[OPTUM_CORRELATION_ID_HEADER],
        });
      },
    }
  );

  const parsedResult = {
    ...queryResult,
    data: filtredResult,
    headers,
  };

  return [parsedResult, queryAction];
};

export const useSnackCardProviderResults = (variables) => {
  const locale = getLanguage()?.code || 'en';
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { coverageType } = tokenData;
  const { providerType, includeSpecialityRollupCodes, pcpIndicator } =
    variables;

  const { longitude, latitude, stateCode } = useGeoLocationStorage();
  const allConfigs = useLagoon('config');
  const searchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const autoIncrementRadius = getLagoonConfigValue(
    allConfigs,
    'AUTO_INCREMENT_SEARCH_RADIUS'
  );

  const currentMember = getCurrentMember();
  const planVariationCodeForMedical = getPlanVariationCode(currentMember, 'M');

  const [snackCardProviderResults, getSnackCardProviderResults] =
    useSnackCardProviderSearch({});

  useEffect(() => {
    getSnackCardProviderResults({
      variables: {
        locale,
        longitude,
        latitude,
        stateCode,
        pcpIndicator,
        includeSpecialityRollupCodes: includeSpecialityRollupCodes ?? '',
        providerType: providerType ?? '',
        lob: currentMember?.lineOfBusiness,
        planYear: getCurrentPlanYear(),
        planVariationCode: planVariationCodeForMedical,
        reciprocityId: getNetworkIdsForPES(currentMember, coverageType),
        coverages: getCoverageTypes(currentMember),
        policyNumber: currentMember?.policyNumber,
        searchRadius: getSearchRadius(searchRadius),
        autoIncrementRadius: getAutoIncrementRadius(autoIncrementRadius),
        memberDemographics: {
          gender: currentMember.demographics.gender,
          dateOfBirth: currentMember.demographics.dateOfBirth,
        },
        coverageType,
      },
    });
  }, [
    providerType,
    longitude,
    latitude,
    stateCode,
    pcpIndicator,
    currentMember.demographics.gender,
    currentMember.demographics.dateOfBirth,
    coverageType,
  ]);

  return snackCardProviderResults;
};
