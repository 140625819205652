/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Layout } from '@abyss/web/ui/Layout';

// import { ILocationSearchSuggestion } from '../../CostEstimate/api/useLocationSearch/types';
// import { ITypeAheadSearchSuggestion } from '../SearchBar/types';
import { LocationItem } from './LocationItem';

export const LocationSuggestionStack = ({
  suggestions,
  onSuggestionClick,
  activeSuggestion,
}) => (
  <Layout.Stack
    alignItems="left"
    data-testid="location-suggestions-container-option-list"
  >
    {suggestions?.map((location, index) => (
      <LocationItem
        key={index}
        activeSuggestion={activeSuggestion}
        data-testid="LocationSuggestionStack-suggested-location"
        index={index}
        location={location}
        onClick={onSuggestionClick}
      />
    ))}
  </Layout.Stack>
);
