import { styled } from '@abyss/web/tools/styled';

export const ProviderIconImg = styled('img', {
  width: '56px',
  height: '56px',
  borderRadius: '50%',
});

export const visionCardContainer = {
  'abyss-flex-root': {
    borderRadius: '8px',
    border: '1px solid var(--abyss-colors-gray3)',
    boxShadow: 'rgba(25, 25, 26, 0.16) 0px 1px 4px 0px',
    width: '278px',
    height: '88px',
    marginTop: '16px',
  },
};

export const visionCardContainerMobile = {
  'abyss-flex-root': {
    borderRadius: '8px',
    border: '1px solid var(--abyss-colors-gray3)',
    boxShadow: 'rgba(25, 25, 26, 0.16) 0px 1px 4px 0px',
    width: '235px',
    height: '84px',
    marginTop: '12px',
  },
};

export const visionCardSubContainer = {
  'abyss-layout-group': {
    width: '246px',
    padding: '12px',
  },
};

export const visionCardSubContainerMobile = {
  'abyss-layout-group': {
    width: '203px',
    justifyContent: 'left',
    padding: '12px 16px',
  },
};

export const visionCardIcon = {
  'abyss-icon': {
    marginLeft: '4px',
    paddingBottom: '2px',
    fontWeight: '$bold',
  },
};

export const visionCardIconMobile = {
  'abyss-icon': {
    marginLeft: '4px',
    fontWeight: '$bold',
    height: '18px',
    width: '18px',
    marginTop: '1px',
  },
};

export const visionCardLink = {
  'abyss-flex-root': {
    color: '#196ECF',
    fontWeight: '$bold',
  },
};

export const visionCardLinkMobile = {
  'abyss-flex-root': {
    color: '#196ECF',
    fontWeight: '$bold',
    width: '140px',
    lineHeight: '20px',
    paddingBottom: '2px',
  },
};

export const specialtyText = {
  'abyss-text-root': {
    paddingBottom: '4px',
    fontSize: '14.22px',
    fontWeight: '$semibold',
  },
};

export const specialtyTextMobile = {
  'abyss-text-root': {
    paddingBottom: '4px',
    fontSize: '14.22px',
    fontWeight: '$medium',
  },
};

export const inOfficeTextMobile = {
  'abyss-text-root': {
    fontSize: '12.64px',
    width: '131px',
    color: '#4B4D4F',
  },
};

export const inOfficeText = {
  'abyss-text-root': {
    fontSize: '14.22px',
    width: '148px',
    color: '#4B4D4F',
    linHeight: '16px',
    fontWeight: '$medium',
  },
};
