import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { phoneOnly } from '../ConstantsStyles';
import { DirectionWrapper } from './DataCard.style';

type Props = {
  color?: string;
  fontWeight?: string;
  sectionType?: string;
  handleClick?: () => void;
};

export const Direction = ({
  color = '$interactive1',
  fontWeight = '$bold',
  sectionType,
  handleClick,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);

  return (
    <React.Fragment>
      <DirectionWrapper
        data-testid={`data-card-direction-${sectionType}`}
        onClick={(e) => {
          e.stopPropagation();
          handleClick?.();
        }}
      >
        <IconMaterial
          aria-hidden="true"
          color={color}
          icon="directions"
          size={mobileScreen ? 18 : 24}
        />
        <Text
          color={color}
          css={{
            '@screen < $sm': {
              fontSize: '$extraSmallText',
              lineHeight: '16px',
            },
          }}
          data-auto-testid={`data-card-direction-${sectionType}-text`}
          data-testid={`data-card-direction-${sectionType}-text`}
          fontWeight={fontWeight}
        >
          {t('Directions')}
        </Text>
      </DirectionWrapper>
    </React.Fragment>
  );
};
