export const searchInputStyle = {
  'abyss-search-input-root': {
    height: 'auto',
  },
  'abyss-search-input-input': {
    background: '$neutralGray2',
    border: '1px solid $neutralGray2',
    borderRadius: '100px',
    fontStyle: 'italic',
  },
  'abyss-search-input-icon-container': {
    display: 'none',
  },
  'abyss-search-input-label': {
    width: 'auto',
    height: '20px',
    fontWeight: '$medium',
    fontSize: '$md',
    lineHeight: '20px',
    color: ' $neutralGray7',
    marginBottom: '$sm',
  },
  'abyss-search-input-option-list': {
    display: 'none',
  },
};
