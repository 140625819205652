import { event } from '@abyss/web/tools/event';

export const adobeLinkTrackEvent = (input) => {
  const { searchBlock, customAttributesBlock } = input;

  event('LINK_TRACK_EVENT', {
    link: {
      name: input.name?.toLowerCase().replaceAll('-', ' '),
      location: input.location?.toLowerCase().replaceAll('-', ' '),
      type: input.type?.toLowerCase() ?? 'internal',
      destinationUrl: input.destinationUrl?.toLowerCase() ?? '',
    },
    component: {
      name: '',
    },
    ...(searchBlock && { search: searchBlock }),
    ...(customAttributesBlock && { customAttributes: customAttributesBlock }),
  });
};
