import { useForm } from '@abyss/web/hooks/useForm';
import { storage } from '@abyss/web/tools/storage';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import { TextInput } from '@abyss/web/ui/TextInput';
import React from 'react';

import { useLagoon } from '../../../hooks/useLagoon';
import { useOBAPI } from '../../../hooks/useOBAPI';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { Constants } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { dateGroupByYearMonthDay } from '../../Utils/datesUtils/formatDate';
import { getFeatureFlag } from '../../Utils/getFeatureFlag';
import { findLoggedInMember } from '../../Utils/memberUtils/memberUtils';
import { MemberInfo, MemberProfile } from './memberProfile';

const { ACTIVE_MEMBER_INDEX, DEMO_MEMBER_INFO, LOGGED_IN_MEMBER_INDEX } =
  Constants.STORAGE_KEYS.SESSION;

const StyledTextInput = styled(TextInput, {
  '.abyss-text-input-label': {
    color: '$primary1',
  },
});

type Props = {
  setMemberProfiles: (a: MemberInfo[]) => void;
  setMemberId?: (a: number) => void;
};

export const setProfileData = (
  result: { data: MemberProfile },
  setMemberProfiles: (a: MemberInfo[]) => void,
  setMemberState: (a: MemberInfo[]) => void,
  firstName: string,
  lastName: string,
  groupNumber: string,
  memberId: string,
  dob: string,
  usePreProd: boolean
) => {
  if (result?.data?.memberInfo) {
    const loggedInMemberIndex = findLoggedInMember(result.data?.memberInfo, {
      FirstName: firstName,
      LastName: lastName,
      DOB: dob,
      GroupNumber: groupNumber,
      MemberNumber: memberId,
    });

    setMemberState(result.data?.memberInfo);
    storage.session.set(ACTIVE_MEMBER_INDEX, loggedInMemberIndex.toString());
    storage.session.set(LOGGED_IN_MEMBER_INDEX, loggedInMemberIndex);
    setMemberProfiles(
      result?.data?.memberInfo?.filter(
        (member) =>
          !!member.eligibility[0]?.memberHealthCoverage?.coverageType?.length
      )
    );
    storage.session.set(DEMO_MEMBER_INFO, {
      firstName,
      lastName,
      groupNumber,
      memberId,
      dob,
      usePreProd,
    });
  }
};

export const ObapiDemo = ({
  setMemberProfiles,
  setMemberId = () => {},
}: Props) => {
  const form = useForm();
  const featureFlags = useLagoon('feature-flags')();
  const requestReciprocityId = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.REQUEST_RECIPROCITY_ID
  );
  const enablePreProd = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_PRE_PROD
  );

  const fpcPsxRedirection = useLagoon(
    Constants.LAGOON_TABLE.FPC_PSX_REDIRECTION
  )();
  const eligiblePolicyIds =
    fpcPsxRedirection[0]?.eligiblePolicyNumbers?.split(',') || [];

  const { firstName, lastName, groupNumber, memberId, dob } = form.getValues();
  const setMemberState = useStore(StoreKeys.SET_OBAPI_MEMBERS);
  const [, getUserProfile] = useOBAPI({
    onCompleted: (result: { data: MemberProfile }) => {
      setProfileData(
        result,
        setMemberProfiles,
        setMemberState,
        firstName,
        lastName,
        groupNumber,
        memberId,
        dateGroupByYearMonthDay(dob),
        enablePreProd
      );
    },
    onError: () => {},
  });

  const handleOnClick = () => {
    getUserProfile({
      variables: {
        firstName,
        lastName,
        groupNumber,
        memberId,
        dob: dateGroupByYearMonthDay(dob),
        requestReciprocityId,
        eligiblePolicyIds,
        usePreProd: enablePreProd,
      },
    });
    setMemberId(parseInt(memberId, 10));
  };

  return (
    <React.Fragment>
      <Heading color="$primary1" offset={5}>
        OBAPI
      </Heading>
      <StyledTextInput
        data-auto-testid="obapi-firstName"
        data-testid="obapi-firstName"
        label="First Name"
        model="firstName"
        placeholder="First Name"
      />
      <StyledTextInput
        data-auto-testid="obapi-lastName"
        data-testid="obapi-lastName"
        label="Last Name"
        model="lastName"
        placeholder="Last Name"
      />
      <StyledTextInput
        data-auto-testid="obapi-dob"
        data-testid="obapi-dob"
        label="Date of birth (MM/DD/YYYY)"
        mask="##-##-####"
        model="dob"
        placeholder="Date of birth"
      />
      <StyledTextInput
        data-auto-testid="obapi-groupNumber"
        data-testid="obapi-groupNumber"
        label="Group Number"
        model="groupNumber"
        placeholder="Group Number"
      />
      <StyledTextInput
        data-auto-testid="obapi-memberId"
        data-testid="obapi-memberId"
        label="Member ID"
        model="memberId"
        placeholder="Member ID"
      />
      <Button
        css={{ marginTop: '$sm', marginBottom: '$md' }}
        data-auto-testid="obapi-submit-button"
        data-testid="obapi-submit-button"
        onClick={handleOnClick}
        variant="outline"
      >
        Submit
      </Button>
    </React.Fragment>
  );
};
