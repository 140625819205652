import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Layout } from '@abyss/web/ui/Layout';
import { pick } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { APIConstants } from '../../../../../../api/src/services/Constants';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import { CardWrapper } from '../../../../common/CardWrapper';
import {
  Constants,
  NULL_RESULTS,
  RECOMMENDED_PROVIDER,
  RecentSearchesSearchTypes,
  SUGGESTED_PROVIDER,
} from '../../../../common/Constants';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { HealthGradesDisclaimer } from '../../../../common/Healthgrades/HealthGradesDisclaimer';
import { NullResultsPage } from '../../../../common/NullResultsPage';
import { PSXHeader } from '../../../../common/PSXHeader';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { ResultSection } from '../../../../common/ResultSection/ResultSection';
import {
  convertProviderTypeToAdobeType,
  getDisplayedProviderCardsCount,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useProviderResults } from '../../../../hooks/useProviderSearch';
import { useSaveRecentSearchesResults } from '../../../../hooks/useRecentSearches';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import {
  getAutoIncrementRadius,
  getLagoonConfigValue,
  getSearchRadius,
} from '../../../../utils/providerSearch.utils';
import { getDependentInfo } from '../../../../utils/user.utils';
import { HealthGradesContainerStyled } from '../PSXHome/HealthGradesContainer.styled';
import { SearchResultsTitle } from './SearchResultsTitle';

const specialtyCodes = require('../../../../common/PSXHeader/specialtyCodes.json');

const isEmptyResult = (
  resultType,
  totalCountOrganizations,
  totalCountMedicalGroup,
  totalCountPractitioners
) =>
  resultType === NULL_RESULTS ||
  (totalCountOrganizations === 0 &&
    totalCountMedicalGroup === 0 &&
    totalCountPractitioners === 0);
export const SearchResults = () => {
  const { t } = useTranslation();

  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const {
    searchType,
    providerType = '',
    userZip,
    category: searchCategory,
    resultType = 'All',
    pesKeyword,
    choosePCP,
    search = '',
    chipValue,
    includeSpecialityRollupCodes: includeSpecialityRollupCodesProps,
    originLinkNameForAnalytics = '',
    searchMethod,
    searchTerm,
    dependentSeqNbr,
    coverageType,
    acceptingNewPatients,
    llmFlag = false,
    keyword = '',
    medicalSpeciality,
  } = tokenData;

  const { clearSearchFilters } = useContext(SearchFilterContext);

  function getProviderType() {
    if (providerType === '') {
      return `${convertProviderTypeToAdobeType(
        APIConstants.PROVIDER_TYPES.practitioner
      )}|${convertProviderTypeToAdobeType(
        APIConstants.PROVIDER_TYPES.organization
      )}|${convertProviderTypeToAdobeType(
        APIConstants.PROVIDER_TYPES.medical_group
      )}`;
    }
    return convertProviderTypeToAdobeType(providerType);
  }

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'overview',
    sitesectionLevel1: 'search results',
    sitesectionLevel2: 'category',
  });

  const [breadcrumbUrls, setBreadcrumbUrls] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const dependentInfo = getDependentInfo(dependentSeqNbr);

  const specialtyCode =
    pesKeyword || specialtyCodes[searchCategory?.toUpperCase()];

  const includeSpecialityRollupCodes = useMemo(
    () => [specialtyCode || includeSpecialityRollupCodesProps],
    [specialtyCode, includeSpecialityRollupCodesProps]
  );

  const allConfigs = useLagoon('config');
  const searchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const autoIncrementRadius = getLagoonConfigValue(
    allConfigs,
    'AUTO_INCREMENT_SEARCH_RADIUS'
  );

  const getSearchTitle = (defaultSearchTitle) => {
    if (!isEmpty(tokenData)) {
      const searchTitle =
        searchType === Constants.SEARCH_TYPES.LOCATION &&
        search !== Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS
          ? userZip
          : defaultSearchTitle;

      return searchTitle;
    }

    return defaultSearchTitle;
  };

  const { longitude, latitude, name: location } = getGeoLocationFromStorage();

  const recentSearchArgs = {
    psxSearchTerm: search,
    pesSearchTerm: includeSpecialityRollupCodes.join(','),
    searchType: RecentSearchesSearchTypes.OTHER,
    category: chipValue,
    location,
    latitude,
    longitude,
    coverageType,
  };

  useSaveRecentSearchesResults(recentSearchArgs);

  const [providersResult, getProvidersResult] = useProviderResults();

  const { data, isLoading, headers } = providersResult;
  const emptyResults = isEmptyResult(
    resultType,
    data.totalCountOrganizations,
    data.totalCountMedicalGroup,
    data.totalCountPractitioners
  );

  function getNoOfResultsForAnalytics() {
    let totalDisplayedProviderCardsCount = 0;
    totalDisplayedProviderCardsCount = getDisplayedProviderCardsCount(
      totalDisplayedProviderCardsCount,
      data?.totalCountPractitioners || 0
    );
    totalDisplayedProviderCardsCount = getDisplayedProviderCardsCount(
      totalDisplayedProviderCardsCount,
      data?.totalCountMedicalGroup || 0
    );
    totalDisplayedProviderCardsCount = getDisplayedProviderCardsCount(
      totalDisplayedProviderCardsCount,
      data?.totalCountOrganizations || 0
    );

    return totalDisplayedProviderCardsCount;
  }

  function getProviderTypesFromDisplayedResults() {
    let providerTypesFromDisplayedProviderCards = '';
    if (data?.totalCountPractitioners && data?.totalCountPractitioners > 0) {
      providerTypesFromDisplayedProviderCards = convertProviderTypeToAdobeType(
        APIConstants.PROVIDER_TYPES.practitioner
      );
    }
    if (data?.totalCountOrganizations && data?.totalCountOrganizations > 0) {
      providerTypesFromDisplayedProviderCards =
        providerTypesFromDisplayedProviderCards !== ''
          ? `${providerTypesFromDisplayedProviderCards}|${convertProviderTypeToAdobeType(
              APIConstants.PROVIDER_TYPES.organization
            )}`
          : convertProviderTypeToAdobeType(
              APIConstants.PROVIDER_TYPES.organization
            );
    }
    if (data?.totalCountMedicalGroup && data?.totalCountMedicalGroup > 0) {
      providerTypesFromDisplayedProviderCards =
        providerTypesFromDisplayedProviderCards !== ''
          ? `${providerTypesFromDisplayedProviderCards}|${convertProviderTypeToAdobeType(
              APIConstants.PROVIDER_TYPES.medical_group
            )}`
          : convertProviderTypeToAdobeType(
              APIConstants.PROVIDER_TYPES.medical_group
            );
    }

    return providerTypesFromDisplayedProviderCards;
  }

  useEffect(() => {
    if (
      data?.totalCountPractitioners !== undefined ||
      data?.totalCountOrganizations !== undefined ||
      data?.totalCountMedicalGroup !== undefined
    ) {
      const adobeSearchMethod = searchMethod ?? 'guided';
      const adobeSearchTerm =
        adobeSearchMethod === 'guided' ? '' : searchTerm || search;
      adobeStandardSearchEvent({
        term: adobeSearchTerm,
        type: getProviderType(),
        linkName: originLinkNameForAnalytics,
        method: adobeSearchMethod,
        numberOfResults:
          resultType === NULL_RESULTS ? 0 : getNoOfResultsForAnalytics(),
        customAttributesBlock: {
          providerType:
            resultType === NULL_RESULTS
              ? ''
              : getProviderTypesFromDisplayedResults(),
        },
      });
    }
  }, [data]);

  const searchTitle = getSearchTitle(search);

  useEffect(() => {
    getProvidersResult({
      search: searchTitle,
      providerType,
      includeSpecialityRollupCodes,
      searchType,
      pcpIndicator: choosePCP,
      searchRadius: getSearchRadius(searchRadius),
      autoIncrementRadius: getAutoIncrementRadius(autoIncrementRadius),
      acceptingNewPatients,
      ...(dependentSeqNbr && {
        memberDemographics: {
          gender: dependentInfo?.demographics.gender,
          dateOfBirth: dependentInfo?.demographics.dateOfBirth,
        },
      }),
      coverageType,
      llmFlag,
      keyword,
    });
  }, [
    searchTitle,
    providerType,
    JSON.stringify(includeSpecialityRollupCodes),
    searchType,
    choosePCP,
    coverageType,
  ]);

  useEffect(() => {
    clearSearchFilters();
  }, []);

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  useEffect(() => {
    breadcrumbUrls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key] =
      window.location.pathname.replace('/findcare', '');
    const updatedBreadCrumbUrls = pick(
      breadcrumbUrls,
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
    );
    setBreadcrumbUrls(updatedBreadCrumbUrls);
  }, [searchTitle]);

  const breadcrumbs = [
    {
      title: t('BC Results'),
      href: '',
    },
  ];

  const offset = 1;
  const isAllSection = resultType === Constants.RESULT_SECTION.ALL;
  const mobileScreen = useMediaQuery(phoneOnly);

  const ResultsSectionMain = (
    res,
    secType,
    count,
    sectionProviderType,
    radius
  ) => (
    <ResultSection
      acceptingNewPatients={acceptingNewPatients}
      dependentSeqNbr={dependentSeqNbr}
      headers={headers}
      isLoading={isLoading}
      providerType={sectionProviderType}
      results={res}
      search={searchTitle}
      searchMethod={searchMethod}
      searchRadius={radius}
      searchTerm={searchTerm}
      searchType={searchType}
      sectionType={secType}
      specialtyCode={specialtyCode}
      totalCount={count}
      userZip={userZip}
    />
  );

  return (
    <React.Fragment>
      {!choosePCP ? (
        <PSXHeader
          breadcrumbs={breadcrumbs}
          dataTestId="search-results-search-form"
          showChips={false}
          showChoosePCPHeader={false}
          showDemoHeader={false}
          showMemberSelection={!mobileScreen}
          showSearchInputBackButton={mobileScreen}
        />
      ) : (
        <PSXHeader
          breadcrumbs={breadcrumbs}
          dataTestId="search-results-search-form"
          showChips={false}
          showChoosePCPHeader
          showDemoHeader={false}
          showMemberSelection={false}
        />
      )}

      {specialtyCode === RECOMMENDED_PROVIDER ||
      specialtyCode === SUGGESTED_PROVIDER ||
      emptyResults ? (
        (medicalSpeciality &&
          chipValue === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS &&
          navigate(
            `${ConstantsRoutes.NULL_SPECIALTY_RESULTS.path}/${token}`
          )) || (
          <NullResultsPage
            emptyResults={emptyResults}
            searchTitle={searchTitle}
            specialtyCode={specialtyCode}
          />
        )
      ) : (
        <React.Fragment>
          <CardWrapper>
            <Layout.Stack grow space={0}>
              <SearchResultsTitle
                choosePCP={choosePCP}
                offset={offset}
                resultType={resultType}
                searchTitle={searchTitle}
              />
              {(isAllSection ||
                resultType === Constants.RESULT_SECTION.PROVIDER) &&
                ResultsSectionMain(
                  data?.providers,
                  Constants.SEARCH_TYPES.PROVIDER,
                  data?.totalCountPractitioners,
                  APIConstants.PROVIDER_TYPES.practitioner,
                  data?.practitionerSearchRadius
                )}
              {(isAllSection ||
                resultType === Constants.RESULT_SECTION.PROVIDER_GROUPS) &&
                ResultsSectionMain(
                  data?.medicalGroup,
                  Constants.SEARCH_TYPES.PROVIDER_GROUP,
                  data?.totalCountMedicalGroup,
                  APIConstants.PROVIDER_TYPES.medical_group,
                  data?.medicalGroupSearchRadius
                )}
              {!choosePCP &&
                (isAllSection ||
                  resultType === Constants.RESULT_SECTION.FACILITY) &&
                ResultsSectionMain(
                  data?.facilities,
                  Constants.SEARCH_TYPES.FACILITY,
                  data?.totalCountOrganizations,
                  APIConstants.PROVIDER_TYPES.organization,
                  data?.organizationSearchRadius
                )}
            </Layout.Stack>
          </CardWrapper>
          <HealthGradesContainerStyled css={{ marginTop: '0px' }}>
            <HealthGradesDisclaimer hideDivider />
          </HealthGradesContainerStyled>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
