import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';

export const PageContainer = styled(Flex, {
  background: '#FAFCFF',
  height: '384px',
  flexDirection: 'column',
  alignItems: 'center',
  '@screen < $sm': {
    height: '229px',
    paddingTop: '$md',
    paddingLeft: '$lg',
    alignItems: 'flex-start',
    flexDirection: 'row-reverse',
  },
});

export const PageContent = styled(Flex, {
  alignContent: 'center',
  alignItems: 'center',
  flex: 1,
  flexDirection: 'column',
  '@screen < $sm': {
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
});
