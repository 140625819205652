import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Divider } from '@abyss/web/ui/Divider';
import { Text } from '@abyss/web/ui/Text';
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { useMapKey } from '../../hooks/useMapKey';
import { FacilityLocationsResponse } from '../../models/FacilityDetails';
import { Provider } from '../../models/Provider';
import { ProviderLocation } from '../../models/ProviderDetails';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { Directions } from '../../models/RouteDirections';
import {
  clearRoute,
  getDistanceString,
  getDurationString,
  getNavTypeString,
  getRoute,
  getStepDistanceString,
  getStepIcon,
  loadMapPins,
  loadRoute,
  setMapboxKey,
} from '../../utils/map.utils';
import { Constants } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { ClusterCarousel } from './ClusterCarousel';
import { MapControlDesktop } from './MapControlDesktop';
import { MapControlMobile } from './MapControlMobile';
import {
  ArrowContainer,
  DetailsContainer,
  DistanceContainer,
  DurationDistanceWrapper,
  DuratonContainer,
  HorizontalDivider,
  MapContainer,
  NavigationContainer,
  RouteDetailsContainer,
  RouteInstructionsContainer,
  RoutesContainer,
  RoutesTab,
  RoutesTabContainer,
  SeeDirectionsContainer,
  StepContainer,
  StepDataContainer,
  StepIconContainer,
  StepsContainter,
  StepsWrapper,
  TripDurationContainer,
  TripLocationContainer,
  TripLocationDataContainer,
  VerticalDivider,
} from './MapDisplay.styled';
import { NavigationButtonsRow } from './Navigation/NavigationButtonsRow';

type Props = {
  closeSidePanel?: boolean;
  coords: { latitude: string; longitude: string };
  disabledPinAction?: boolean;
  headers: ResponseHeaders;
  locationResults?: ProviderLocation[] | FacilityLocationsResponse[];
  map: any;
  isOpenCompareShare?: boolean;
  providerResults?: Provider[];
  routeEndCoords?: [number | null, number | null];
  setRouteEndCoords?(coords: [number | null, number | null]): void;
  selectedItems?: any[];
  disableCost?: boolean;
  mobileMapControlChildren?: React.ReactNode;
  mobileRouteView?: boolean;
  setPopupContent?(
    content: ProviderLocation | FacilityLocationsResponse | null
  ): void;
  refToBeFocusedOnDirectionsClose?: HTMLElement;
  updatePin?(
    content: ProviderLocation | FacilityLocationsResponse | null
  ): void;
  selectLocation?: (locationId, locationLng, locationLat) => void;
  directions?: Directions;
};

export const MapDisplay = ({
  coords,
  disabledPinAction = false,
  isOpenCompareShare = false,
  providerResults = [],
  locationResults = [],
  map,
  closeSidePanel,
  routeEndCoords = [null, null],
  setRouteEndCoords,
  selectedItems,
  disableCost,
  mobileMapControlChildren,
  mobileRouteView,
  setPopupContent,
  refToBeFocusedOnDirectionsClose,
  updatePin,
  selectLocation,
  directions,
  headers,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const mapContainerTestId: string = 'map-view-container';
  const { longitude, latitude } = coords;
  const [navType, setNavType] = useState<string>('driving-traffic');
  const mapContainerRef = React.useRef<any>(null);
  const navigationContainerRef = useRef<HTMLElement>();
  const routesContainerRef = useRef<HTMLElement>();
  const [highlightId, setHighlightId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID,
    { providerId: '', from: '' }
  );
  const [selectedId, setSelectedId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { search, sectionType } = tokenData;

  const [, setmapPinCoords] = useSessionStorage<
    (string[] | (string | undefined)[])[]
  >(Constants.STORAGE_KEYS.SESSION.MAP_PIN_COORDS, []);
  const [clusterStatus, setClusterStatus] = useState(false);
  const [feature, setFeature] = useState([]);
  const [currentRoute, setCurrentRoute] = useState<number>(0);
  const [showStepInstructions, setShowStepInstructions] =
    useState<boolean>(false);

  const [mapDirections, setMapDirections] = useState<Directions>(
    directions || {
      userPlaceName: '',
      endPlaceName: '',
      routes: [],
    }
  );

  const {
    DISPLAY_DIRECTIONS: {
      SEE_DIRECTIONS,
      ROUTES,
      STEPS,
      START_LOCATION,
      DESTINATION,
    },
  } = Constants;

  useEffect(() => {
    if (directions) {
      setMapDirections(directions);
      setShowStepInstructions(false);
      setCurrentRoute(0);
    }
  }, [directions]);

  useEffect(() => {
    if (!highlightId.from) {
      setClusterStatus(false);
    }
  }, [highlightId]);

  const handlePinSelect = (newEndCoords, locationId?) => {
    if (setRouteEndCoords) {
      setRouteEndCoords(newEndCoords);
    }

    if (locationId) {
      setSelectedId(locationId);
    }
  };

  const [, getMapBoxKey] = useMapKey({
    onCompleted: (result) => {
      const { mapKey } = result;
      setMapboxKey(mapKey);
      map.current = new mapboxgl.Map({
        accessToken: mapKey,
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [longitude, latitude],
        zoom: Constants.DEVICE_LOCATION.ZOOM,
      });

      setHighlightId({ providerId: '', from: '' });
      map.current.on('load', async () => {
        const providerFeatures = providerResults.map((practitioner) => ({
          type: 'Feature',
          id: practitioner.providerId,
          properties: {
            description: JSON.stringify(practitioner),
            providerId: practitioner.providerId,
            type: 'provider',
          },
          geometry: {
            type: 'Point',
            coordinates: [practitioner.longitude, practitioner.latitude],
          },
        }));

        const locationFeatures = locationResults.map(
          (location: ProviderLocation | FacilityLocationsResponse, index) => ({
            type: 'Feature',
            id: index.toString(),
            properties: {
              description: JSON.stringify(location),
              providerId: location.locationId,
              type: 'location',
            },
            geometry: {
              type: 'Point',
              coordinates: [location.longitude, location.latitude],
            },
          })
        );

        const featuresAr = [...providerFeatures, ...locationFeatures].filter(
          (featureItem) =>
            featureItem.geometry.coordinates[0] !== '00.00000' &&
            featureItem.geometry.coordinates[1] !== '00.00000'
        );

        const coordinates = [
          ...featuresAr.map((item) => item.geometry.coordinates),
          [longitude, latitude],
        ];
        setmapPinCoords(coordinates);

        await map.current.isStyleLoaded();
        clearRoute(map.current);
        setSelectedId(null);
        await loadMapPins(
          map.current,
          mapKey,
          longitude,
          latitude,
          featuresAr,
          coordinates,
          setHighlightId,
          handlePinSelect,
          disabledPinAction,
          setClusterStatus,
          setFeature,
          setPopupContent,
          updatePin,
          search,
          sectionType
        );
      });
      // Clean up on unmount
      return () => map?.current?.remove();
    },
  });

  // Initialize map when component mounts
  useEffect(() => {
    getMapBoxKey({});
  }, [
    JSON.stringify(providerResults),
    JSON.stringify(locationResults),
    selectedItems,
    isOpenCompareShare,
  ]);

  useEffect(() => {
    const getMapRoute = async () => {
      const routeDirections = await getRoute(
        map.current,
        longitude,
        latitude,
        routeEndCoords[0],
        routeEndCoords[1],
        false,
        navType
      );
      if (routeDirections) setMapDirections(routeDirections);
    };
    if (routeEndCoords[0]) {
      setCurrentRoute(0);
      getMapRoute();
    }
  }, [navType]);

  useEffect(() => {
    setNavType('driving-traffic');
    if (selectedId === null) {
      refToBeFocusedOnDirectionsClose?.focus();
    } else {
      navigationContainerRef?.current?.focus();
    }
  }, [selectedId]);

  useEffect(() => {
    if (map.current) map.current.resize();
  }, [closeSidePanel, mobileRouteView]);

  const handleCloseNav = () => {
    clearRoute(map.current, setRouteEndCoords, highlightId.providerId);
    setSelectedId(null);
  };

  useEffect(() => {
    if (showStepInstructions) {
      routesContainerRef?.current?.focus();
    } else {
      navigationContainerRef?.current?.focus();
    }
  }, [showStepInstructions]);
  const hasRoute = routeEndCoords[0];
  const mapControl = () => {
    if (mobileScreen)
      return (
        <MapControlMobile
          controlChildren={mobileMapControlChildren}
          map={map.current}
          mobileRouteView={!!routeEndCoords[0]}
        />
      );
    if (!mobileScreen) return <MapControlDesktop map={map.current} />;
    return null;
  };
  return (
    <React.Fragment>
      <MapContainer
        cssProps={{
          clusterStatus,
          isOpenCompareShare,
          mobileRouteView,
          hasRoute,
        }}
      >
        <div
          ref={mapContainerRef}
          className="map-container"
          data-auto-testid={mapContainerTestId}
          data-testid={mapContainerTestId}
        />
        {mapControl()}
        <NavigationContainer
          ref={navigationContainerRef}
          cssProps={{ hasRoute }}
          id="navigation-container-id"
          tabindex="-1"
        >
          {hasRoute && mapDirections?.routes?.length ? (
            <React.Fragment>
              <NavigationButtonsRow
                handleCloseNav={handleCloseNav}
                locationForAnalytics={mapContainerTestId}
                navType={navType}
                setNavType={setNavType}
              />
              <Divider
                color="$neutralGray4"
                css={{
                  'abyss-divider-root': {
                    boxShadow: '0 0 2px 0 rgba(25, 25, 26, 0.16)',
                  },
                }}
                height="1px"
                margin="0"
                width="100%"
              />
              <TripLocationContainer>
                <TripLocationDataContainer>
                  <Text
                    css={{
                      color: '$gray8',
                      fontSize: '$smallText',
                      fontWeight: '$bold',
                      lineHeight: '$sm',
                      '@screen < $sm': {
                        fontWeight: '$semibold',
                      },
                    }}
                  >
                    A. {START_LOCATION}
                  </Text>
                  <Text
                    css={{
                      color: '$gray8',
                      fontSize: '$md',
                      fontWeight: '$medium',
                      lineHeight: '$md',
                      textWrap: 'nowrap',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {mapDirections.userPlaceName}
                  </Text>
                </TripLocationDataContainer>
                <TripLocationDataContainer>
                  <Text
                    css={{
                      color: '$gray8',
                      fontSize: '$smallText',
                      fontWeight: '$bold',
                      lineHeight: '$sm',
                      '@screen < $sm': {
                        fontWeight: '$semibold',
                      },
                    }}
                  >
                    B. {DESTINATION}
                  </Text>
                  <Text
                    css={{
                      color: '$gray8',
                      fontSize: '$md',
                      fontWeight: '$medium',
                      lineHeight: '$md',
                      textWrap: 'nowrap',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {mapDirections.endPlaceName}
                  </Text>
                </TripLocationDataContainer>
              </TripLocationContainer>

              <HorizontalDivider />
              <TripDurationContainer>
                <DurationDistanceWrapper>
                  <DuratonContainer>
                    {getDurationString(
                      mapDirections.routes[currentRoute]?.duration
                    )}{' '}
                    {getNavTypeString(navType)}
                  </DuratonContainer>
                  <DistanceContainer>
                    (
                    {getDistanceString(
                      mapDirections.routes[currentRoute]?.distance
                    )}
                    )
                  </DistanceContainer>
                </DurationDistanceWrapper>

                <SeeDirectionsContainer
                  data-auto-testid="see-directions"
                  data-testid="see-directions"
                  onClick={() => {
                    setShowStepInstructions(!showStepInstructions);
                  }}
                  role="button"
                  tabindex="0"
                >
                  <Text
                    css={{
                      fontWeight: showStepInstructions ? '$bold' : '$normal',
                      fontSize: showStepInstructions
                        ? '$extraSmallText'
                        : '$sm',
                      lineHeight: showStepInstructions ? '16px' : '18px',
                      textWrap: 'nowrap',
                      color: '$interactive1',
                    }}
                  >
                    {SEE_DIRECTIONS}
                  </Text>
                  <ArrowContainer>
                    {showStepInstructions ? (
                      <svg
                        fill="none"
                        height="18"
                        viewBox="0 0 18 18"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clipRule="evenodd"
                          d="M9 6L4.5 10.5L5.5575 11.5575L9 8.1225L12.4425 11.5575L13.5 10.5L9 6Z"
                          fill="#196ECF"
                          fillRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        fill="none"
                        height="18"
                        viewBox="0 0 18 18"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clipRule="evenodd"
                          d="M12.4425 6.44238L9 9.87738L5.5575 6.44238L4.5 7.49988L9 11.9999L13.5 7.49988L12.4425 6.44238Z"
                          fill="#196ECF"
                          fillRule="evenodd"
                        />
                      </svg>
                    )}
                  </ArrowContainer>
                </SeeDirectionsContainer>
              </TripDurationContainer>
              <RouteInstructionsContainer
                css={{ display: showStepInstructions ? 'block' : 'none' }}
              >
                <HorizontalDivider />

                <RouteDetailsContainer>
                  <DetailsContainer>
                    <Text
                      css={{
                        display: 'block',
                        fontSize: '$md',
                        color: '$gray8',
                        fontWeight: '$bold',
                        lineHeight: '$md',
                      }}
                    >
                      {getDurationString(
                        mapDirections.routes[currentRoute]?.duration
                      )}
                    </Text>
                    <Text
                      css={{
                        color: '$gray8',
                        marginTop: '$xs',
                        display: 'block',
                        fontSize: '$md',
                        fontWeight: '$medium',
                        lineHeight: '$md',
                      }}
                    >
                      {getDistanceString(
                        mapDirections?.routes[currentRoute]?.distance
                      )}
                    </Text>
                  </DetailsContainer>
                </RouteDetailsContainer>

                <RoutesContainer ref={routesContainerRef} tabindex="-1">
                  <Text
                    css={{
                      color: '$gray8',
                      fontSize: '$md',
                      fontWeight: '$bold',
                      lineHeight: '$md',
                    }}
                  >
                    {ROUTES}
                  </Text>
                  <RoutesTabContainer>
                    {mapDirections?.routes?.map((_, index: number) => (
                      <React.Fragment>
                        <RoutesTab
                          aria-pressed={currentRoute === index}
                          css={{
                            background:
                              index === currentRoute ? '$info1' : 'none',
                            color: index === currentRoute ? '$white' : '$gray6',
                          }}
                          data-auto-testid={`routes-tab-${index}`}
                          data-testid={`routes-tab-${index}`}
                          onClick={() => {
                            setCurrentRoute(index);
                            loadRoute(
                              map.current,
                              mapDirections?.routes,
                              index,
                              longitude,
                              latitude,
                              routeEndCoords[0],
                              routeEndCoords[1]
                            );
                          }}
                        >
                          {index + 1}
                        </RoutesTab>
                        {index !== mapDirections.routes.length - 1 && (
                          <VerticalDivider />
                        )}
                      </React.Fragment>
                    ))}
                  </RoutesTabContainer>
                </RoutesContainer>
                <StepsContainter tabindex="0">
                  <Text
                    css={{
                      display: 'block',
                      padding: '$md 0',
                      fontWeight: '$bold',
                      lineHeight: '$md',
                      color: '$gray8',
                      fontSize: '$md',
                    }}
                  >
                    {STEPS}
                  </Text>
                  <StepsWrapper>
                    {!mobileScreen && (
                      <StepContainer>
                        <StepIconContainer>A</StepIconContainer>
                        <StepDataContainer>
                          <Text
                            css={{
                              color: '$gray8',
                              display: 'block',
                              fontSize: '$smallText',
                              lineHeight: '$md',
                              fontWeight: '$bold',
                            }}
                          >
                            {START_LOCATION}
                          </Text>
                          <Text
                            css={{
                              color: '$neutralGray5',
                              display: 'block',
                              fontSize: '$smallText',
                              lineHeight: '$md',
                              fontWeight: '$medium',
                            }}
                          >
                            {mapDirections.userPlaceName}
                          </Text>
                        </StepDataContainer>
                      </StepContainer>
                    )}

                    {mapDirections.routes?.[currentRoute]?.steps?.map(
                      (
                        { distance, maneuver: { modifier, instruction, type } },
                        index
                      ) => (
                        <StepContainer>
                          <StepIconContainer>
                            <img
                              alt=""
                              aria-hidden="true"
                              src={`data:image/svg+xml,${encodeURIComponent(
                                getStepIcon(modifier, type)
                              )}`}
                            />
                          </StepIconContainer>
                          <StepDataContainer>
                            <Text
                              css={{
                                color:
                                  (mobileScreen && index === 0) ||
                                  index ===
                                    (mapDirections?.routes?.[currentRoute]
                                      ?.steps?.length as number) -
                                      1
                                    ? '$gray8'
                                    : '$gray7',
                                display: 'block',
                                fontSize: '$smallText',
                                lineHeight: '$md',
                                fontWeight:
                                  (mobileScreen && index === 0) ||
                                  index ===
                                    (mapDirections?.routes?.[currentRoute]
                                      ?.steps?.length as number) -
                                      1
                                    ? '$bold'
                                    : '$semibold',
                              }}
                            >
                              {instruction}
                            </Text>
                            <Text
                              css={{
                                color: '$neutralGray5',
                                display: 'block',
                                fontSize: '$smallText',
                                lineHeight: '$md',
                                fontWeight: '$medium',
                              }}
                            >
                              {getStepDistanceString(distance)}
                            </Text>
                          </StepDataContainer>
                        </StepContainer>
                      )
                    )}

                    {!mobileScreen && (
                      <StepContainer>
                        <StepIconContainer>B</StepIconContainer>
                        <StepDataContainer>
                          <Text
                            css={{
                              color: '$gray8',
                              display: 'block',
                              fontSize: '$smallText',
                              lineHeight: '$md',
                              fontWeight: '$bold',
                            }}
                          >
                            {DESTINATION}
                          </Text>
                          <Text
                            css={{
                              color: '$neutralGray5',
                              display: 'block',
                              fontSize: '$smallText',
                              lineHeight: '$md',
                              fontWeight: '$medium',
                            }}
                          >
                            {mapDirections.endPlaceName}
                          </Text>
                        </StepDataContainer>
                      </StepContainer>
                    )}
                  </StepsWrapper>
                </StepsContainter>
              </RouteInstructionsContainer>
            </React.Fragment>
          ) : null}
        </NavigationContainer>
      </MapContainer>
      <ClusterCarousel
        data={feature}
        disableCost={disableCost}
        headers={headers}
        search={search}
        sectionType={sectionType}
        selectLocation={selectLocation}
      />
    </React.Fragment>
  );
};
