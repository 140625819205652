import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useTranslation } from 'react-i18next';

import { handleLinkAndModalTrack } from '../../../../../../../common/Utils/adobeTrackUtils';
import { adobeCompareDrawerLinkLocation } from '../../utility/compareDrawerConstants';

type Props = {
  isEditCompareOpen: boolean;
  setIsEditCompareOpen: (a: boolean) => void;
  setNameOfButtonClickedLast: Function;
};

export const EditCompareButton = ({
  isEditCompareOpen,
  setIsEditCompareOpen,
  setNameOfButtonClickedLast,
}: Props) => {
  const { t } = useTranslation();
  const editCompareButtonTestId = 'edit-compare-button';
  const adobeTrackEvent = () => {
    const linkName: string = editCompareButtonTestId;
    const linkLocation: string = `modal:${adobeCompareDrawerLinkLocation}`;
    const modalName: string = 'edit comparison';
    const linkType: string = 'comparison';
    handleLinkAndModalTrack(linkName, linkLocation, modalName, linkType);
  };
  return (
    <Button
      css={{
        'abyss-button-root': {
          marginLeft: '-25px',
        },
      }}
      data-auto-testid={editCompareButtonTestId}
      data-testid={editCompareButtonTestId}
      fontWeight="$bold"
      onClick={() => {
        if (!isEditCompareOpen) {
          adobeTrackEvent();
          setIsEditCompareOpen(true);
          setNameOfButtonClickedLast('edit');
        }
      }}
      variant="tertiary"
    >
      <IconMaterial css={{ marginRight: '5px' }} icon="edit" size="$md" />
      {t('Edit comparison')}
    </Button>
  );
};
