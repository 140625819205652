import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Divider } from '@abyss/web/ui/Divider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Modal } from '@abyss/web/ui/Modal';
import find from 'lodash/find';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import {
  HealthGradesStyled,
  StyledDisclaimerText,
  StyledIcon,
  StyledMarkdown,
  disclaimerLinkStyles,
} from './HealthGradesDisclaimer.styled';

interface HGProps {
  hideDivider?: Boolean;
  showPremiumCareDisclaimer?: boolean;
}
export const HealthGradesDisclaimer = ({
  hideDivider,
  showPremiumCareDisclaimer = false,
}: HGProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const mobile = useMediaQuery(phoneOnly);
  const lagoonData = useLagoon('ui-messaging')();
  const { t } = useTranslation();
  const modalDisclaimerShort = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.MODAL_DISCLAIMER_SHORT,
  });
  const modalDisclaimerFull = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.MODAL_DISCLAIMER_FULL,
  });
  const disclaimerInPage = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.DISCLAIMER_IN_PAGE,
  });

  return (
    <React.Fragment>
      {!hideDivider && (
        <Divider
          color="$gray3"
          data-auto-testid="disclaimer-line"
          data-testid="disclaimer-line"
          height="1"
          orientation="horizontal"
        />
      )}

      <HealthGradesStyled>
        <ContentWrapper>
          <Layout.Stack alignItems="left" space="0">
            <StyledMarkdown>{disclaimerInPage?.message}</StyledMarkdown>
            {showPremiumCareDisclaimer ? (
              <React.Fragment>
                <StyledMarkdown
                  data-auto-testid="premium-care-disclaimer-content"
                  data-testid="premium-care-disclaimer-content"
                >
                  {t('PREMIUM_CARE_DISCLAIMER.CONTENT')}
                </StyledMarkdown>
                <LinkWithTracking
                  analyticsInfo={{
                    location: 'body:premium care disclaimer',
                    type: 'download',
                  }}
                  css={disclaimerLinkStyles}
                  data-auto-testid="premium-care-disclaimer-link-text"
                  data-testid="premium-care-disclaimer-link-text"
                  href={t('PREMIUM_CARE_DISCLAIMER.LINK')}
                >
                  {t('PREMIUM_CARE_DISCLAIMER.LINK_TEXT')}
                  <IconMaterial icon="chevron_right" size="$xs" />
                </LinkWithTracking>
              </React.Fragment>
            ) : null}
            <StyledIcon icon="healthgrades" size={`${mobile ? 132 : 165}`} />
            <StyledDisclaimerText>
              {`${modalDisclaimerShort?.message} ` ?? ''}
              <Link
                css={disclaimerLinkStyles}
                data-auto-testid="healthgrades-link-text"
                data-testid="healthgrades-link-text"
                href={null}
                onClick={() => setIsOpen(true)}
              >
                {t('HEALTHGARDE_DISCLAIMER.LINK_TEXT')}
                <IconMaterial icon="chevron_right" size="$xs" />
              </Link>
            </StyledDisclaimerText>
            <Modal
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              title={`${t('HEALTHGARDE_DISCLAIMER.HEALTHGRADES_INFORMATION')}`}
            >
              <Modal.Section>
                <Markdown
                  options={{
                    overrides: {
                      Link: {
                        component: Link,
                      },
                    },
                  }}
                >
                  {modalDisclaimerFull?.message}
                </Markdown>
              </Modal.Section>
            </Modal>
          </Layout.Stack>
        </ContentWrapper>
      </HealthGradesStyled>
    </React.Fragment>
  );
};
