import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Modal } from '@abyss/web/ui/Modal';
import { Pagination } from '@abyss/web/ui/Pagination';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { useAdobePageTrackEvent } from '../../hooks/adobeHook/useAdobePageTrackEvent';
import { useHealthgradesGetProviderReview } from '../../hooks/useHealthgrades';
import { HealthgradesProviderReview, Survey } from '../../models/Healthgrades';
import { ProviderType } from '../../models/ProviderDetails';
import { getProviderId } from '../../utils/providerDetails.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { phoneOnly } from '../ConstantsStyles';
import { DetailsPageHeader } from '../DetailsPageHeader/DetailsPageHeader';
import { HealthGradesDisclaimer } from '../Healthgrades/HealthGradesDisclaimer';
import { PatientReviewsModal } from '../ProviderDetailTabs/About/PatientReviews/PatientReviewModal';
import {
  AllPatientReviewsContainer,
  ContainerSubHeading,
  PaginationComponentStyled,
  RatingSectionContainerStyles,
  SectionContainer,
  TextHeadingPatientReviews,
  TextSubHeadingWhatPatientsAreSaying,
  modalStyles,
  showingResultsCountTextStyles,
  showingVerbatimCountTextStyles,
  sortButtonMobileStyles,
  verbatimHeader,
  viewAllVerbatimFlexStyles,
} from '../ProviderDetailTabs/About/PatientReviews/PatientReviews.styled';
import { PatientReviewsOverviewSection } from '../ProviderDetailTabs/About/PatientReviews/PatientReviewsOverviewSection';
import { PatientVerbatimCard } from '../ProviderDetailTabs/About/PatientReviews/PatientVerbatimCard';
import { swapIconStyles } from '../ProviderDetailTabs/PhysicianDirectory/PhysicianDirectory.styled';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import { AllPatientReviewsSortPopover } from './AllPatientReviewsSort';
import { PatientReviewsSortOrder } from './AllPatientReviewsSortOrder';

export const sortReviewsMostRecent = (a: Survey, b: Survey) => {
  if (
    !a.submittedDate ||
    !b.submittedDate ||
    new Date(a.submittedDate) === new Date(b.submittedDate)
  ) {
    return 0;
  }
  return new Date(a.submittedDate) > new Date(b.submittedDate) ? -1 : 1;
};

export const sortReviewsHighestRating = (a: Survey, b: Survey) => {
  if (!a.overallScore || !b.overallScore || a.overallScore === b.overallScore)
    return 0;
  return a.overallScore > b.overallScore ? -1 : 1;
};

export const sortReviewsLowestRating = (a: Survey, b: Survey) => {
  if (!a.overallScore || !b.overallScore || a.overallScore === b.overallScore)
    return 0;
  return a.overallScore < b.overallScore ? -1 : 1;
};

export const AllPatientReviews = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const sectionHeader: string =
    Constants.PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION
      .WHAT_PATIENTS_ARE_SAYING;
  const patientReviewsDataTestId: string = 'patient-reviews-section';
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const providerId = getProviderId(tokenData);
  const {
    showDirection,
    avatar,
    inNetwork,
    providerName,
    primarySpeciality,
    professionalDesignation,
    rating,
    npi,
    imageLocation,
    providerDetails,
  } = tokenData;
  const [navigateToDirections, setNavigateToDirections] = useState(
    Boolean(showDirection)
  );
  const providerLocations = providerDetails
    ? JSON.parse(providerDetails).providerLocations
    : {};
  const [reviewModal, setReviewModal] = useState<Survey | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [healthgradesReview, setHealthgradesReview] =
    useState<HealthgradesProviderReview>({});
  const [, getHealthgradesProviderReview] = useHealthgradesGetProviderReview({
    onCompleted: (result) => {
      setIsLoading(false);
      if (result?.data?.healthgradesGetProviderReview) {
        setHealthgradesReview(result.data.healthgradesGetProviderReview);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      throw error;
    },
  });
  const [sortOrder, setSortOrder] = useState<PatientReviewsSortOrder>(
    PatientReviewsSortOrder.MOST_RECENT
  );
  const [pageSize, setPageSize] = useState<number>(16);
  const { gotoPage, state, ...paginationProps } = usePagination({
    pages: Math.ceil(
      (healthgradesReview.surveyResults?.surveys?.length || 0) / pageSize
    ),
  });
  const { currentPage, pageIndex, pageCount } = state;

  const [isOpen, setIsOpen] = useState(false);

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'about:patient reviews',
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `provider details`,
    impressionBlock: {
      type: 'provider',
      indicator: '',
    },
  });

  const [, setDirectionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );
  useEffect(() => {
    if (showDirection) {
      setDirectionsTriggeredFrom('');
      setNavigateToDirections(true);
    }
  }, [showDirection]);

  useEffect(() => {
    if (navigateToDirections) {
      const updatedToken = tokenizer.update(token, {
        showDirection: navigateToDirections,
      });
      navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${updatedToken}`);
    }
  }, [navigateToDirections]);

  useEffect(() => {
    adobeLinkTrackEvent({
      name: `results per page:${pageSize}`,
      location: `body:pagination control:page ${currentPage}`,
    });
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (reviewModal) {
      const linkName: string = patientReviewsDataTestId;
      const linkLocation: string = `body:${sectionHeader}:page ${currentPage}`;
      const modalName: string = 'patient review';
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [reviewModal]);

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  const form = useForm({
    defaultValues: {
      'dropdown-menu-radios': 10,
    },
  });

  useEffect(() => {
    getHealthgradesProviderReview({
      variables: {
        npi,
      },
    });
  }, []);

  const sortedReviews = (healthgradesReview.surveyResults?.surveys || []).sort(
    // eslint-disable-next-line no-nested-ternary
    sortOrder === PatientReviewsSortOrder.MOST_RECENT
      ? sortReviewsMostRecent
      : sortOrder === PatientReviewsSortOrder.HIGHEST_RATING
      ? sortReviewsHighestRating
      : sortReviewsLowestRating
  );

  const showingFrom = () => currentPage * pageSize - pageSize + 1;
  const showingTo = (limit: number) =>
    currentPage * pageSize > limit ? limit : currentPage * pageSize;

  const handleGoBack = () => {
    navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${token}`);
  };

  const handleOnClick = () => {
    handleLinkAndModalTrack(
      `all-patient-reviews-sort-order`,
      `modal:all patient reviews sort`,
      'all patient reviews sort'
    );
    setIsOpen(true);
  };

  return (
    <div>
      <Box
        color="#FBFCFE"
        css={{
          'abyss-box-root': {
            height: 'auto',
            padding: '0px',
            borderBottom: '1px solid  var(--abyss-colors-cardBorder)',
          },
        }}
      >
        <DetailsPageHeader
          avatar={avatar}
          imageLocation={imageLocation}
          inNetwork={inNetwork}
          primarySpeciality={primarySpeciality?.split('|')}
          professionalDesignation={professionalDesignation}
          providerId={providerId}
          providerLocations={providerLocations}
          providerName={providerName}
          providerType={ProviderType.PRACTITIONER}
          rating={rating}
          setNavigateToDirections={setNavigateToDirections}
        />
      </Box>
      <Modal
        css={modalStyles}
        isOpen={reviewModal !== null}
        onClose={() => setReviewModal(null)}
      >
        <Modal.Section
          data-auto-testId={patientReviewsDataTestId}
          data-testId={patientReviewsDataTestId}
        >
          <PatientReviewsModal review={reviewModal} />
        </Modal.Section>
      </Modal>
      <AllPatientReviewsContainer>
        {!mobileScreen && (
          <Link
            css={{
              paddingTop: '8px',
              paddingBottom: '8px',
              marginBottom: '16px',
            }}
            data-auto-testid="back-to-provider-details"
            data-testid="back-to-provider-details"
            href="#"
            isStandardAnchor
            onClick={handleGoBack}
          >
            <IconMaterial
              color="$interactive1"
              css={{ width: '24px', height: '24px', marginRight: '4px' }}
              icon="arrow_back"
            />
            <Text color="$interactive1" fontWeight="$bold">
              {t('BACK')}
            </Text>
          </Link>
        )}
        {!mobileScreen && (
          <div style={{ marginBottom: '12px' }}>
            <TextHeadingPatientReviews>
              {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.PATIENT_REVIEWS')}
            </TextHeadingPatientReviews>
          </div>
        )}
        {isLoading ? (
          <LoadingSpinner
            ariaLoadingLabel="loading results"
            isLoading={isLoading}
            size="$sm"
          />
        ) : (
          <div style={RatingSectionContainerStyles}>
            {healthgradesReview.surveyResults ? (
              <div>
                <PatientReviewsOverviewSection
                  healthgradesReview={healthgradesReview}
                />
                {healthgradesReview.surveyResults?.surveys?.length ? (
                  <div>
                    <Flex
                      css={verbatimHeader}
                      direction="row"
                      justify="space-between"
                    >
                      <ContainerSubHeading>
                        <TextSubHeadingWhatPatientsAreSaying>
                          {t(
                            'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING'
                          )}
                        </TextSubHeadingWhatPatientsAreSaying>
                      </ContainerSubHeading>
                      {mobileScreen && (
                        <div style={{ display: 'flex' }}>
                          <Button
                            css={sortButtonMobileStyles}
                            data-auto-testid="result-sort-mobile"
                            data-testid="result-sort-mobile"
                            onClick={handleOnClick}
                            variant="outline"
                          >
                            <Layout.Group>
                              <IconMaterial
                                css={swapIconStyles}
                                icon="swap_vert"
                              />
                              <Text color="$primary1" fontWeight={600}>
                                {t('Sort')}
                              </Text>
                            </Layout.Group>
                          </Button>
                        </div>
                      )}
                    </Flex>
                    <Flex
                      alignItems="flex-end"
                      css={{
                        '@screen < $sm': {
                          marginBottom: '0px',
                        },
                      }}
                      direction="row"
                      justify="space-between"
                    >
                      <SectionContainer>
                        <Flex alignItems="center" css={{ gap: '$sm' }}>
                          <FormProvider
                            data-auto-testid="set-page-size-reviews"
                            data-testid="set-page-size-reviews"
                            onSubmit={setPageSize}
                            state={form}
                          >
                            {!mobileScreen && (
                              <Layout.Group>
                                <Text css={showingVerbatimCountTextStyles}>
                                  {t('Results per page')}
                                </Text>
                                <DropdownMenu
                                  after={
                                    <IconMaterial icon="keyboard_arrow_down" />
                                  }
                                  label={pageSize}
                                  menuItems={[
                                    {
                                      value: pageSize,
                                      onChange: setPageSize,
                                      model: 'dropdown-menu-radios',
                                      radios: [
                                        {
                                          label: 5,
                                          value: 5,
                                        },
                                        {
                                          label: 10,
                                          value: 10,
                                        },
                                        {
                                          label: 25,
                                          value: 25,
                                        },
                                      ],
                                    },
                                  ]}
                                  outline
                                />
                              </Layout.Group>
                            )}
                          </FormProvider>
                          {!mobileScreen && (
                            <Pagination
                              {...paginationProps}
                              data-auto-testid="pagination-component-desktop"
                              data-testid="pagination-component-desktop"
                              gotoPage={gotoPage}
                              pageSize={pageSize}
                              variant="compact"
                            />
                          )}
                        </Flex>
                        {!mobileScreen && (
                          <div
                            style={{ marginTop: '10px', lineHeight: '18px' }}
                          >
                            <Text css={showingResultsCountTextStyles}>
                              {`${t('Showing')} ${showingFrom()} - ${showingTo(
                                healthgradesReview.surveyResults?.surveys
                                  ?.length
                              )} ${t('of')} ${
                                healthgradesReview.surveyResults?.surveys
                                  ?.length
                              } ${t('results')}`}
                            </Text>
                          </div>
                        )}
                      </SectionContainer>

                      <AllPatientReviewsSortPopover
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        setSortOrder={setSortOrder}
                        sortOrder={sortOrder}
                      />
                    </Flex>

                    <Flex css={viewAllVerbatimFlexStyles}>
                      {sortedReviews
                        .slice(pageIndex * pageSize, currentPage * pageSize)
                        .map((survey) => (
                          <PatientVerbatimCard
                            key={survey.id}
                            setReviewModal={setReviewModal}
                            survey={survey}
                          />
                        ))}
                    </Flex>
                    {pageCount >= 1 && (
                      <PaginationComponentStyled
                        {...paginationProps}
                        data-auto-testid="pagination-component-mobile"
                        data-testid="pagination-component-mobile"
                        gotoPage={gotoPage}
                        pageSize={pageSize}
                        variant="extended"
                      />
                    )}
                    <HealthGradesDisclaimer hideDivider />
                  </div>
                ) : null}
              </div>
            ) : (
              <Text>
                {t(
                  'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.NO_REVIEWS_FOUND'
                )}
              </Text>
            )}
          </div>
        )}
      </AllPatientReviewsContainer>
    </div>
  );
};
