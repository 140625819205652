import { storage } from '@abyss/web/tools/storage';

import { Constants } from '../../../../common/Constants';
import { useLagoon } from '../../../../hooks/useLagoon';
import { PSXHome } from '../PSXHome';

const getDLRoute = <T extends { deeplink: string; route: string; cid: string }>(
  deepLinksParam: T[] = [],
  dlCookie: string = '',
  cidCookie: string = ''
) => {
  const { origin } = window.location;
  if (dlCookie) {
    const dpRoute = deepLinksParam?.find(
      (e) => e.deeplink === dlCookie && (e.cid ? e.cid === cidCookie : true)
    )?.route;
    if (dpRoute && dpRoute !== 'findcare') {
      storage.cookie.remove(Constants.COOKIES.DEEPLINK, {
        path: '/',
        domain: '.uhc.com',
      });
      return window.location.replace(`${origin}/${dpRoute}`);
    }
  }
  return <PSXHome />;
};

export const DeepLinks = () => {
  const deepLinks = useLagoon('deep-links')();
  const DEEP_LINK_COOKIE = Constants.COOKIES.DEEPLINK;
  const dlCookieValue = storage.cookie.get(DEEP_LINK_COOKIE);
  const CID_COOKIE = Constants.COOKIES.CID;
  const cidCookieValue = storage.cookie.get(CID_COOKIE);
  return getDLRoute(deepLinks, dlCookieValue, cidCookieValue);
};
