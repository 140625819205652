import { createStore } from '@abyss/web/tools/createStore';

import { State, StoreKeys, intialUIState } from './state';

const initialState: State = {
  [StoreKeys.SESSION_ID]: '',
  [StoreKeys.PLAN_SELECTOR]: {
    bigN: {
      DOB: '',
      FirstName: '',
      GroupNumber: '',
      LastName: '',
      MemberNumber: '',
    },
  },
  [StoreKeys.OBAPI_MEMBERS]: [],
  [StoreKeys.UI_STATE]: intialUIState,
};

export const useStore = createStore((set) => ({
  ...initialState,
  [StoreKeys.SET_SESSION_ID]: (sessionId) => set(() => ({ sessionId })),
  [StoreKeys.SET_PLAN_SELECTOR]: (planSelector) =>
    set(() => ({ planSelector })),
  [StoreKeys.SET_OBAPI_MEMBERS]: (memberInfo) =>
    set(() => ({
      memberInfo: memberInfo.filter(
        (member) =>
          !!member.eligibility[0]?.memberHealthCoverage?.coverageType?.length
      ),
    })),
  [StoreKeys.SET_UI_STATE]: (uiState) => set(() => ({ uiState })),
}));
