import { Brandmark } from '@abyss/web/ui/Brandmark';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Print } from '@abyss/web/ui/Print';
import { format } from 'date-fns';
// eslint-disable-next-line import/no-unresolved
import React from 'react';
import { useLagoon } from '../../../../../hooks/useLagoon';

import { RatingText } from '../../../../../common/DataCard/DataCard.style';
import {
  PrintTierInfoBox,
  PrintViewAddress,
  PrintViewDivider,
  PrintViewProviderBoldText,
  PrintViewProviderDetailsMediumText,
  PrintViewProviderDetailsNameText,
  PrintViewProviderDetailsXSText,
  PrintViewProviderMediumText,
  PrintViewProviderNormalText,
  PrintViewProviderTierOneText,
} from './PrintView.style';
import { PrintPreviewTierOneIcon } from './TierOneIcon';
import { Constants } from '../../../../../common/Constants';
import { getFeatureFlag } from '../../../../../common/Utils';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';

type Props = {
  selectedProviderList: {
    numberOfReviews?: string;
    acceptingNewPatients: boolean;
    providerName: string;
    providerId: string;
    speciality: string;
    providerType?: string;
    networkStatus: string;
    distance: string;
    healthGradeRating?: string;
    isTieredProvider: boolean;
    languagesSpoken: string[];
    address: {
      postalCode: string;
      district: string;
      line: string[];
      state: string;
      city: string;
    };
  }[];
};

export const getStarsRating = (providerHealthGradeRating: number) => {
  let stars = '';
  const count = Array.from([1, 2, 3, 4, 5]);
  for (let i = 0; i < count.length; i++) {
    if (count[i] <= providerHealthGradeRating) {
      stars += '★';
    } else {
      stars += '☆';
    }
  }
  return stars;
};

export const PrintView = ({ selectedProviderList }: Props) => (
  <Print.Section css={{ border: 'none', padding: '30px 30px 30px 30px' }}>
    <Flex alignContent="flex-start" direction="column">
      <Flex justify="space-between">
        <Brandmark
          affiliate="uhc"
          brand="uhc"
          color="blue"
          css={{
            'abyss-brandmark': {
              width: '114.24px',
              height: '35.36px',
            },
          }}
          size="$lg"
          variant="lockup"
        />
        <Flex
          alignContent="flex-start"
          css={{
            'abyss-flex-root': {
              textAlign: 'right',
            },
          }}
          direction="column"
        >
          <PrintViewAddress>United HealthCare Services, Inc</PrintViewAddress>
          <PrintViewAddress>Duluth Service Center</PrintViewAddress>
          <PrintViewAddress>Atlanta, GA 30374-0800</PrintViewAddress>
          <PrintViewAddress>Phone: 1-800-357-1371</PrintViewAddress>
        </Flex>
      </Flex>

      <PrintViewDivider orientation="horizontal" />
      <PrintViewProviderMediumText color="#000000">
        Primary Care Providers
      </PrintViewProviderMediumText>

      <Flex justify="space-between">
        <Flex>
          <PrintViewProviderBoldText color="#000000">
            Provider:
          </PrintViewProviderBoldText>
          <PrintViewProviderNormalText color="#000000">
            Healthcare Group
          </PrintViewProviderNormalText>
        </Flex>
        <Flex>
          <PrintViewProviderBoldText color="#000000">
            Date:
          </PrintViewProviderBoldText>
          <PrintViewProviderNormalText color="#000000">
            {format(new Date(), 'MMMM dd, yyyy')}
          </PrintViewProviderNormalText>
        </Flex>
      </Flex>
      <Flex>
        <PrintViewProviderBoldText color="#000000">
          Provider Status:
        </PrintViewProviderBoldText>
        <PrintViewProviderNormalText color="#000000">
          Network
        </PrintViewProviderNormalText>
      </Flex>

      <PrintViewDivider orientation="horizontal" />

      {selectedProviderList?.map((provider, index) => {
        const healthGradeRating = parseInt(
          provider.healthGradeRating || '',
          10
        );

        const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
        const decemberFlag: boolean = getFeatureFlag(
          featureFlags,
          ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
        );

        return (
          <React.Fragment>
            <Flex
              css={{
                'abyss-flex-root': {
                  gap: '4px',
                  width: '100%',
                },
              }}
            >
              <span
                style={{
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  background: 'black',
                  fontSize: '14px',
                  lineHeight: '16.71px',
                  border: '2px solid #666',
                  color: 'white',
                  textAlign: 'center',
                  fontWeight: '500',
                }}
              >
                {index + 1}
              </span>
              <Flex
                css={{
                  'abyss-flex-root': {
                    gap: '2px',
                    padding: 0,
                    flexWrap: 'nowrap',
                    width: '93%',
                  },
                }}
                direction="column"
              >
                <Flex justify="space-between">
                  <PrintViewProviderDetailsNameText color="#333333">
                    {provider?.providerName}
                  </PrintViewProviderDetailsNameText>
                  <PrintViewProviderDetailsXSText color="#333333">
                    {provider.networkStatus === 'INN'
                      ? 'In-network'
                      : 'Out of network'}
                  </PrintViewProviderDetailsXSText>
                </Flex>
                <Flex justify="space-between">
                  <PrintViewProviderDetailsXSText color="#333333">
                    {provider?.speciality}
                  </PrintViewProviderDetailsXSText>
                  <PrintViewProviderDetailsXSText color="#333333">
                    {provider?.acceptingNewPatients
                      ? 'Accepting new patients'
                      : 'Not accepting new patients'}
                  </PrintViewProviderDetailsXSText>
                </Flex>
                <Layout.Group space="">
                  {getStarsRating(healthGradeRating)}
                  {healthGradeRating ? (
                    <RatingText className="text" size="11px">
                      {provider?.numberOfReviews
                        ? `${provider?.numberOfReviews} Reviews`
                        : healthGradeRating.toFixed(1)}
                    </RatingText>
                  ) : null}
                </Layout.Group>
                {provider.isTieredProvider && decemberFlag? (
                  <PrintTierInfoBox
                    data-auto-testId="print-preview-tier-one-indicator"
                    data-testId="print-preview-tier-one-indicator"
                  >
                    <PrintPreviewTierOneIcon />
                    <PrintViewProviderTierOneText color="#333333">
                      Tier 1 Provider
                    </PrintViewProviderTierOneText>
                  </PrintTierInfoBox>
                ) : null}
                <PrintViewProviderDetailsXSText color="#333333">
                  {provider.languagesSpoken?.join(', ')}
                </PrintViewProviderDetailsXSText>
                <PrintViewProviderDetailsXSText color="#333333">
                  <Flex justify="space-between">
                    {`${provider?.address?.line?.[0]}, ${
                      provider?.address?.city
                    }, ${provider?.address?.state} • ${parseFloat(
                      provider?.distance
                    ).toFixed(1)} Miles away`}
                    <PrintViewProviderDetailsMediumText color="#333333" />
                  </Flex>
                </PrintViewProviderDetailsXSText>
              </Flex>
              {index + 1 < selectedProviderList.length && (
                <PrintViewDivider orientation="horizontal" />
              )}
            </Flex>
          </React.Fragment>
        );
      })}
    </Flex>
  </Print.Section>
);
