/* eslint-disable no-nested-ternary */

import { TextInput } from '@abyss/web/ui/TextInput';
import { t } from 'i18next';
import isNil from 'lodash/isNil';

import { APIConstants } from '../../../../../../../api/src/services/Constants';
import { Constants, NetworkStatus } from '../../../../../common/Constants';
import { NULL_ATTRIBUTE_STATE } from '../CompareDrawer/utility/compareDrawerConstants';
import { EmailInputStyles } from './ShareDrawerContents/ShareDrawerContents.styled';

export const sectionTypeToProviderType = (sectionType) => {
  if (
    sectionType?.toLowerCase() ===
    Constants.RESULT_SECTION.PROVIDER.toLowerCase()
  ) {
    return APIConstants.PROVIDER_TYPES.practitioner;
  }
  if (
    sectionType?.toLowerCase() ===
    Constants.RESULT_SECTION.FACILITY.toLowerCase()
  ) {
    return APIConstants.PROVIDER_TYPES.organization;
  }
  if (
    sectionType?.toLowerCase() ===
    Constants.RESULT_SECTION.PROVIDER_GROUPS.toLowerCase()
  ) {
    return APIConstants.PROVIDER_TYPES.medical_group;
  }

  return sectionType;
};

export const emailInput = (onChange, value, label, errorMessage, onBlur) => (
  <TextInput
    css={EmailInputStyles}
    data-auto-testid="email-id"
    data-testid="email-id"
    label={label}
    model={label}
    onBlur={() => {
      onBlur(label);
    }}
    onChange={(e) => {
      onChange(e.target.value);
    }}
    validators={{
      required: label === 'Email Address 1',
      pattern: {
        value:
          /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+([.+\-_][a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@[a-zA-Z0-9]+([.-][a-zA-Z0-9]*[a-zA-Z][a-zA-Z0-9]*)*[a-zA-Z]$/,
        message: errorMessage,
      },
    }}
  />
);

export const convertBase64PdfStringToFile = (pdfBase64String, shareTitle) => {
  // decode base64 string, remove space for IE compatibility
  const binary = Buffer.from(
    pdfBase64String.replace(/\s/g, ''),
    'base64'
  ).toString('binary');
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  // create the file object with content-type "application/pdf"
  return new File([view], `${shareTitle}.pdf`, { type: 'application/pdf' });
};

export const getProviderGender = (gender: string | undefined): string =>
  gender
    ? gender.charAt(0).toUpperCase().concat(gender.slice(1))
    : `${NULL_ATTRIBUTE_STATE.GENDER}`;

export const getProviderSpecialty = (speciality) =>
  speciality || `${NULL_ATTRIBUTE_STATE.SPECIALTY}`;

export const getProviderLanguages = (languagesSpoken: string[]) => {
  const formattedLanguage =
    languagesSpoken?.length > 0 && languagesSpoken.filter(Boolean).join(', ');
  return formattedLanguage || `${NULL_ATTRIBUTE_STATE.LANGUAGES_SPOKEN}`;
};

export const getNetworkStatus = (networkStatus: string) =>
  networkStatus ? NetworkStatus.INN : NetworkStatus.OON;

export const getStatusText = (
  status: boolean | undefined | null,
  trueText: string,
  falseText: string,
  nullText: string
) => {
  if (isNil(status)) {
    return nullText;
  }
  return status ? t(trueText) : t(falseText);
};

export const adobeShareDrawerLinkLocation = 'share drawer';
export const adobeShareProviders = 'share providers';
