export const isReferralRequired = (
  specialtiesWithReferral,
  selectedProviderLocation
) => {
  const specialties = selectedProviderLocation?.specialties;
  const providerSelectedState = selectedProviderLocation?.address?.state;
  if (!specialties || !providerSelectedState) {
    return false;
  }
  const specialityFound = specialtiesWithReferral.some(
    (r) => specialties.indexOf(r.name) >= 0
  );
  return specialityFound;
};

export const isReferralRequiredSpeciality = (
  specialtiesWithReferral,
  locationData
) => {
  const location = locationData;
  if (!location) {
    return false;
  }
  const referralRequired = specialtiesWithReferral?.referralRequired;

  const stateEligibility =
    referralRequired?.excludedStates.length > 0
      ? referralRequired?.excludedStates.indexOf(location) === -1
      : referralRequired?.isRequired;

  return referralRequired?.isRequired && stateEligibility;
};
