import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';

import { FacilityLocationsResponse } from '../../../models/FacilityDetails';
import { ProviderLocation } from '../../../models/ProviderDetails';
import { phoneOnly } from '../../ConstantsStyles';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
import {
  EveningAppointemnt,
  FlexContainer,
  IconAlignmentContainer,
  InfoAlignmentContainer,
  InfoContainer,
  InfoWebAlignmentContainer,
  websiteStyles,
} from './ProviderLocationsDetails.style';

type Props = {
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
  isFromVirtualProviderGroup?: boolean;
};

export const SupressEmailWeb = ({
  selectedLocation,
  isFromVirtualProviderGroup = true,
}: Props) => {
  const isMobile = useMediaQuery(phoneOnly);
  let contentWidth = '';
  if (isFromVirtualProviderGroup) {
    if (isMobile) {
      contentWidth = '100%';
    } else {
      contentWidth = '50%';
    }
  }
  return (
    <React.Fragment>
      {selectedLocation?.website || selectedLocation?.emailAddress?.length ? (
        <FlexContainer
          style={{
            flexBasis: isFromVirtualProviderGroup ? '100%' : '50%',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          {selectedLocation?.website ? (
            <IconAlignmentContainer
              style={{
                width: contentWidth,
              }}
            >
              <IconMaterial
                color="$primary1"
                css={{
                  marginTop: '4px',
                  'abyss-icon-material': {
                    cursor: 'pointer',
                  },
                }}
                icon="web"
                isScreenReadable
                size={20}
                title="Website"
              />
              <EveningAppointemnt>
                <LinkWithTracking
                  analyticsInfo={{
                    name: 'website',
                    location: 'body:provider-website',
                  }}
                  css={websiteStyles}
                  data-auto-testid={`prvider-website${selectedLocation.website}`}
                  data-testid={`prvider-website${selectedLocation.website}`}
                  href={selectedLocation.website}
                >
                  {selectedLocation.website}
                </LinkWithTracking>
              </EveningAppointemnt>
            </IconAlignmentContainer>
          ) : null}

          {selectedLocation.emailAddress?.length
            ? selectedLocation.emailAddress.map((email) =>
                isFromVirtualProviderGroup ? (
                  <InfoWebAlignmentContainer>
                    <IconMaterial color="$primary1" icon="mail" size={16.67} />
                    <InfoContainer>
                      <a
                        data-auto-testid={`${email}-email-address`}
                        data-testid={`${email}-email-address`}
                        href={`mailto:${email}`}
                      >
                        {email}
                      </a>
                    </InfoContainer>
                  </InfoWebAlignmentContainer>
                ) : (
                  <InfoAlignmentContainer>
                    <IconMaterial color="$primary1" icon="mail" size={16.67} />
                    <InfoContainer>
                      <a
                        data-auto-testid={`${email}-email-address`}
                        data-testid={`${email}-email-address`}
                        href={`mailto:${email}`}
                      >
                        {email}
                      </a>
                    </InfoContainer>
                  </InfoAlignmentContainer>
                )
              )
            : null}
        </FlexContainer>
      ) : null}
    </React.Fragment>
  );
};
