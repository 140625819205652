import { storage } from '@abyss/web/tools/storage';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Fragment, useEffect } from 'react';

import { Constants } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { MOCK_MEMBERS_INFO } from '../../../../common/MockMemberInfo';
import { dateGroupByYearMonthDay } from '../../../../common/Utils/datesUtils/formatDate';
import { getFeatureFlag } from '../../../../common/Utils/getFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useOBAPI } from '../../../../hooks/useOBAPI';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { loginNotRequired } from '../../../../utils/session.utils';
import { IChildrenProp } from '../../types';

export const Obapi = ({ children }: IChildrenProp) => {
  const { bigN } = useStore(StoreKeys.PLAN_SELECTOR);
  const setMemberState = useStore(StoreKeys.SET_OBAPI_MEMBERS);
  const isLocal = loginNotRequired();

  const [members, setMembers] = useOBAPI({});
  const { isLoading } = members;
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const requestReciprocityId = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.REQUEST_RECIPROCITY_ID
  );
  const enablePreProd = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_PRE_PROD
  );
  const { DEMO_MEMBER_INFO } = Constants.STORAGE_KEYS.SESSION;
  const obapiDemoValues = storage.session.get(DEMO_MEMBER_INFO);

  const fpcPsxRedirection = useLagoon(
    Constants.LAGOON_TABLE.FPC_PSX_REDIRECTION
  )();
  const eligiblePolicyIds =
    fpcPsxRedirection?.[0]?.eligiblePolicyNumbers?.split(',') || [];

  useEffect(() => {
    if (obapiDemoValues) {
      setMembers({
        variables: {
          ...obapiDemoValues,
          requestReciprocityId,
          eligiblePolicyIds,
        },
      });
    } else if (!isLocal && bigN?.MemberNumber.length) {
      setMembers({
        variables: {
          firstName: bigN.FirstName,
          lastName: bigN.LastName,
          groupNumber: bigN.GroupNumber,
          memberId: bigN.MemberNumber,
          dob: dateGroupByYearMonthDay(bigN.DOB),
          requestReciprocityId,
          eligiblePolicyIds,
          usePreProd: enablePreProd,
        },
      });
    }
  }, [bigN]);

  useEffect(() => {
    if (members.data) {
      setMemberState(members.data.memberInfo);
    }
    if (isLocal && !members.data) {
      setMemberState(MOCK_MEMBERS_INFO);
    }
  }, [members]);

  return (
    <Fragment>
      {isLoading ? (
        <LoadingSpinner
          ariaLoadingLabel="Loading page"
          isLoading={isLoading}
          size="$sm"
        />
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </Fragment>
  );
};
