import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';

import { phoneOnly } from '../../../../common/ConstantsStyles';
import { handleLinkAndModalTrack } from '../../../../common/Utils/adobeTrackUtils';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { CompareProvider } from '../../../../models/Provider';

type Props = {
  text: string;
  sectionType: string;
  setOpenShare: (a: boolean) => void;
  openShare?: boolean;
  openCompare?: boolean;
  setOpenCompare: (a: boolean) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  isShareButton?: boolean;
  view: String;
};

const ShareCompareButtonStyled = styled(Button, {
  variants: {
    view: {
      desktop: {
        '&.abyss-button-root': {
          paddingLeft: 10,
          paddingRight: 15,
        },
      },
    },
    color: {
      primary: {
        '&:hover,&:active': {
          '> span': {
            backgroundColor: '$gray4',
          },
          backgroundColor: '$gray4',
        },
        backgroundColor: '$primary1',
      },
      nocolor: { backgroundColor: 'unset' },
    },
  },
});

export const ShareCompareButton = ({
  text,
  setOpenShare,
  openShare,
  openCompare,
  sectionType,
  setOpenCompare,
  setSelectedCheckbox,
  setSelectedItems,
  isShareButton = true,
  view,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const isOpen = openShare || openCompare;
  const textColor = isOpen ? '$tint1' : '$info1';

  const setUIstate = useStore(StoreKeys.SET_UI_STATE);
  const uiState = useStore(StoreKeys.UI_STATE);

  const handleAdobeButtonClickTrack = (buttonName) => {
    const linkName = `${buttonName} button`;
    const location = `body:${sectionType} results`;
    const modalName: string = `${buttonName} drawer`;
    handleLinkAndModalTrack(linkName, location, modalName);
  };

  const handleButton = () => {
    if (isShareButton) {
      setUIstate({
        ...uiState,
        shareProvidersFlow: {
          indexOflastProviderSelected: 0,
          shouldFocusLastIndex: true,
        },
      });
      handleAdobeButtonClickTrack('share');
      setOpenShare(!openShare);
      setOpenCompare(false);
      setSelectedCheckbox({ checked: {} });
      setSelectedItems([]);
    } else {
      setUIstate({
        ...uiState,
        compareProvidersFlow: {
          indexOflastProviderSelected: 0,
          shouldFocusLastIndex: true,
        },
      });
      handleAdobeButtonClickTrack('compare');
      setOpenCompare(!openCompare);
      setOpenShare(false);
      setSelectedCheckbox({ checked: {} });
      setSelectedItems([]);
    }
  };

  return (
    <ShareCompareButtonStyled
      aria-pressed={!!isOpen}
      color={!mobileScreen && isOpen ? 'primary' : 'nocolor'}
      css={
        mobileScreen && {
          '&.abyss-button-root': {
            border: 'solid 2px $primary1',
            '&:active, &:focus, &:hover': {
              backgroundColor: '#CCF2F7',
            },
          },
        }
      }
      data-auto-testid={`${
        isShareButton ? 'filter-buttons-share' : 'filter-buttons-compare'
      }`}
      data-testid={`${
        isShareButton ? 'filter-buttons-share' : 'filter-buttons-compare'
      }`}
      id="mybutton"
      onClick={handleButton}
      size={mobileScreen ? '$sm' : '$md'}
      variant={mobileScreen ? 'outline' : 'ghost'}
      view={view}
    >
      <Layout.Group>
        <IconMaterial
          color={isOpen ? '$tint1' : '$info1'}
          icon={isShareButton ? 'share' : 'compare_arrows'}
        />
        <Text
          color={mobileScreen ? '$primary1' : textColor}
          data-auto-testid={`${
            isShareButton ? 'text-buttons-share' : 'text-buttons-compare'
          }`}
          data-testid={`${
            isShareButton ? 'text-buttons-share' : 'text-buttons-compare'
          }`}
          fontWeight={mobileScreen && 600}
        >
          {text}
        </Text>
      </Layout.Group>
    </ShareCompareButtonStyled>
  );
};
