import { styled } from '@abyss/web/tools/styled';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { useEffect, useRef, useState } from 'react';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { hideScrollbar } from '../../../../common/ConstantsStyles';
import { DataCard } from '../../../../common/DataCard';
import { NationalGroupProvidersVirtualSnackCard } from '../../../../common/NationalGroupProvidersVirtualBiteCard/NationalGroupProvidersVirtualSnackCard';
import { CheckBoxDataCardStyle } from '../../../../common/ResultSection/ResultSection.style';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { Provider } from '../../../../models/Provider';
import { ProviderType } from '../../../../models/ProviderDetails';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { scrollWithRef } from './CompareDrawer/utility/scrollWithRef';

type Props = {
  headers: ResponseHeaders;
  sectionType: string;
  showCheckboxes: boolean;
  providerResults: any[];
  addSelectedProvider: (a: any) => void;
  updatePin: (a: any) => void;
  selectedCheckbox: any;
  setSelectedCheckbox: (a: any) => void;
  isMobileMap?: boolean;
  DataCardStyle?: Object;
  selectLocation?: (locationId, locationLng, locationLat) => void;
  map?: any;
  setRouteEndCoords?(coords: [number | null, number | null]): void;
  locationForAnalytics?: string;
  pageNumber?: number;
  parentRef?: any;
  selectedFilters?: string;
  searchTerm?: string;
  usedAt?: string;
  navigateToDirections?: boolean;
  setNavigateToDirections?: (a: boolean) => void;
  mobileRouteView?: boolean;
};

export const DataCardListStyled = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  gap: '$sm',
  zIndex: '0',
  variants: {
    variant: {
      flexDirectionRow: {
        flexDirection: 'row',
        overflowX: 'auto',
        flexWrap: 'nowrap',
      },
    },
  },
});

export const DataCardList = ({
  headers,
  sectionType,
  showCheckboxes,
  providerResults,
  addSelectedProvider,
  updatePin,
  selectedCheckbox,
  setSelectedCheckbox,
  isMobileMap,
  selectLocation,
  map,
  setRouteEndCoords,
  DataCardStyle,
  locationForAnalytics,
  pageNumber,
  parentRef,
  selectedFilters,
  searchTerm,
  usedAt = '',
  navigateToDirections = false,
  setNavigateToDirections,
  mobileRouteView = false,
}: Props) => {
  const checkedCount = Object.keys(selectedCheckbox.checked).filter(
    (key) => selectedCheckbox.checked[key]
  ).length;

  // begin: a11y keyboard navigation
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number>(-1);
  const uiState = useStore(StoreKeys.UI_STATE);

  const { compareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const { indexOflastProviderSelected } = compareProvidersFlow;
  const setUIstate = useStore(StoreKeys.SET_UI_STATE);

  useEffect(() => {
    if (indexOflastProviderSelected === -1 || lastSelectedIndex >= 0) {
      const newUIState = {
        ...uiState,
        compareProvidersFlow: {
          ...compareProvidersFlow,
          indexOflastProviderSelected: lastSelectedIndex + 1,
        },
      };
      setUIstate(newUIState);
    }
  }, [lastSelectedIndex]);

  const [lastSelectedShareIndex, setLastSelectedShareIndex] =
    useState<number>(-1);

  const { shareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const { indexOflastProviderSelected: indexOflastShareProviderSelected } =
    shareProvidersFlow;

  useEffect(() => {
    if (
      indexOflastShareProviderSelected === -1 ||
      lastSelectedShareIndex >= 0
    ) {
      const newUIState = {
        ...uiState,
        shareProvidersFlow: {
          ...shareProvidersFlow,
          indexOflastProviderSelected: lastSelectedShareIndex + 1,
        },
      };
      setUIstate(newUIState);
    }
  }, [lastSelectedShareIndex]);
  // end: a11y keyboard navigation

  const handleDirectionClick = (provider) => {
    if (selectLocation)
      selectLocation(
        provider.locationId,
        provider.longitude,
        provider.latitude
      );
  };

  const listRef = useRef<HTMLDivElement>(null);
  const scrollIntoView = (index: number) => {
    scrollWithRef(listRef, index);
    parentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const [decemberFlag, disableCost] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_COST,
  ]);

  const adobeLinkTrackEventCheckBox = (checkedState: any, index: number) => {
    adobeLinkTrackEvent({
      name: `provider${checkedState ? ':uncheck' : ':checked'}`,
      location: `body:${sectionType} results:page ${pageNumber}:position ${
        index + 1
      }`,
      type: 'checkbox',
    });
  };

  const displayVirtualProvider = (provider: Provider) =>
    decemberFlag &&
    !provider.address &&
    provider?.isVirtualCareOffered &&
    (provider.providerType === ProviderType.PRACTITIONER ||
      provider.providerType === ProviderType.PROVIDER_GROUP);
  const displayDataCard = (provider, index) =>
    displayVirtualProvider(provider) ? (
      <NationalGroupProvidersVirtualSnackCard
        css={DataCardStyle}
        practitioner={provider}
        sectionType={sectionType}
      />
    ) : (
      <DataCard
        key={provider?.locationId}
        css={DataCardStyle}
        data-auto-testid="data-card"
        data-testid="data-card"
        decemberFlag={decemberFlag}
        disableCost={disableCost}
        handleDirectionClick={() => handleDirectionClick(provider)}
        headers={headers}
        index={index}
        isLoading={false}
        locationForAnalytics={locationForAnalytics}
        map={map}
        mobileRouteView={mobileRouteView}
        navigateToDirections={navigateToDirections}
        pageNumber={pageNumber}
        practitioner={provider}
        scrollIntoView={scrollIntoView}
        searchTerm={searchTerm}
        sectionType={sectionType}
        selectedFilters={selectedFilters}
        setNavigateToDirections={setNavigateToDirections}
        setRouteEndCoords={setRouteEndCoords}
        updatePin={updatePin}
        usedAt={usedAt}
      />
    );

  useEffect(() => {
    const provider = providerResults[0];
    if (
      decemberFlag &&
      provider &&
      sectionType === Constants.RESULT_SECTION.PROVIDER
    ) {
      provider.address = undefined;
      provider.isVirtualCareOffered = true;
    }
  }, []);
  return (
    <DataCardListStyled
      ref={listRef}
      css={{ '@screen < $sm': { hideScrollbar } }}
      data-auto-testid="data-card-list-flex"
      data-testid="data-card-list-flex"
      variant={isMobileMap ? 'flexDirectionRow' : ''}
    >
      {providerResults.map((provider: any, index) =>
        showCheckboxes ? (
          <Checkbox
            key={provider?.locationId}
            css={{
              'abyss-checkbox-root': {
                '& > div': { alignItems: 'center' },
                paddingLeft: '$xs',
                width: '100%',
              },
              'abyss-checkbox-label': {
                padding: 0,
                marginLeft: '19px',
                width: '100%',
              },
            }}
            data-auto-testid="map-view-checkbox-group"
            data-testid="map-view-checkbox-group"
            isChecked={selectedCheckbox.checked[provider?.locationId] || false}
            isDisabled={
              !selectedCheckbox.checked[provider?.locationId] &&
              checkedCount >= 5
            }
            label={
              <DataCard
                key={provider?.locationId}
                css={CheckBoxDataCardStyle}
                decemberFlag={decemberFlag}
                disableCost={disableCost}
                handleDirectionClick={() => handleDirectionClick(provider)}
                headers={headers}
                index={index}
                isLoading={false}
                locationForAnalytics={locationForAnalytics}
                pageNumber={pageNumber}
                practitioner={provider}
                scrollIntoView={scrollIntoView}
                searchTerm={searchTerm}
                sectionType={sectionType}
                selectedFilters={selectedFilters}
              />
            }
            onChange={() => {
              setSelectedCheckbox((prevState) => ({
                checked: {
                  ...prevState.checked,
                  [provider?.locationId]:
                    !prevState.checked[provider?.locationId],
                },
              }));
              addSelectedProvider(provider);
              adobeLinkTrackEventCheckBox(
                selectedCheckbox.checked[provider?.locationId],
                index
              );
              setLastSelectedIndex(index);
              setLastSelectedShareIndex(index);
            }}
            size={18}
          />
        ) : (
          displayDataCard(provider, index)
        )
      )}
    </DataCardListStyled>
  );
};
