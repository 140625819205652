import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';

import { handleLinkAndModalTrack } from '../../../../../common/Utils/adobeTrackUtils';

type Props = {
  onClick: (a: boolean) => void;
  icon: String;
  view: String;
  locationForAnalytics?: String;
};

const DrawerMenuButtonStyled = styled(Button, {
  variants: {
    view: {
      mobile: {
        '&.abyss-button-root': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
});

const handleMoreActionsModalLinkTrack = (locationForAnalytics, icon) => {
  const linkName: string = 'more actions';
  const modalName: string =
    icon === 'close' ? 'close confirmation' : 'more actions';
  const location: string = locationForAnalytics;
  handleLinkAndModalTrack(linkName, location, modalName);
};

export const DrawerMenuButton = ({
  onClick,
  icon,
  view,
  locationForAnalytics,
}: Props) => (
  <DrawerMenuButtonStyled
    data-auto-testid="mapview-compare-drawer-menu"
    data-testid="mapview-compare-drawer-menu"
    onClick={() => {
      handleMoreActionsModalLinkTrack(locationForAnalytics, icon);
      onClick(true);
    }}
    variant="tertiary"
    view={view}
  >
    <IconMaterial
      data-auto-testid={`compare-drawer-close-${icon}`}
      data-testid={`compare-drawer-close-${icon}`}
      icon={icon}
      size="$md"
    />
  </DrawerMenuButtonStyled>
);
