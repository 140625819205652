import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
/* eslint-disable no-nested-ternary */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchFilterContext } from '../../context/SearchFilterContext';
/* eslint-disable @optum-fpc-psx-mobile-apps/no-missing-testid */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useLagoon } from '../../hooks/useLagoon';
import { Provider } from '../../models/Provider';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { Constants, FilterCollectionModel } from '../Constants';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { phoneOnly, resultsCarouselScreen } from '../ConstantsStyles';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import { MixedResultCards } from './MixedResultCards';
import {
  HeaderWrapper,
  InfoPopoverDrawerStyle,
  LinkWrapper,
  ListWrapper,
  NoResultsFoundContainer,
  ResultWrapper,
  headerTextStyle,
  viewAllTextStyle,
} from './ResultSection.style';

type Props = {
  acceptingNewPatients?: boolean;
  dependentSeqNbr?: string;
  headers: ResponseHeaders;
  results: Provider[];
  totalCount: number;
  sectionType: string;
  search: string;
  userZip: string;
  specialtyCode: string;
  searchType: string;
  isLoading: boolean;
  providerType?: string;
  searchRadius: number;
  searchMethod: string;
  searchTerm: string;
};

export const ResultSection = ({
  acceptingNewPatients,
  dependentSeqNbr,
  headers,
  results,
  totalCount = 0,
  sectionType,
  search,
  userZip,
  specialtyCode,
  searchType,
  isLoading,
  providerType,
  searchRadius,
  searchMethod,
  searchTerm,
}: Props) => {
  const result = results?.slice(0, 3);
  const { t } = useTranslation();
  const resultsBySectionType = `${sectionType} ${Constants.RESULT_SECTION.RESULTS}`;
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const mobileScreen = useMediaQuery(phoneOnly);
  const displayCarousel = useMediaQuery(resultsCarouselScreen);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const resultSectionPopover: string = `result-section-${sectionType}-popover`;
  const resultsPopoverContent = useLagoon('mixed-results-popover')();
  const viewAllDataTestId = `results-section-${sectionType}-view-all`.replace(
    / /g,
    '-'
  );
  const { setDefaultSearchFilters } = useContext(SearchFilterContext);

  let popoverContent = '';
  let popoverTitle = '';

  switch (sectionType) {
    case Constants.RESULT_SECTION.PROVIDER:
      popoverContent = resultsPopoverContent[0]?.text;
      popoverTitle = resultsPopoverContent[0]?.headerTitle;
      break;
    case Constants.RESULT_SECTION.PROVIDER_GROUPS:
      popoverContent = resultsPopoverContent[1]?.text;
      popoverTitle = resultsPopoverContent[1]?.headerTitle;
      break;
    case Constants.RESULT_SECTION.FACILITY:
      popoverContent = resultsPopoverContent[2]?.text;
      popoverTitle = resultsPopoverContent[2]?.headerTitle;
      break;
    default:
      popoverContent = 'Unable to retrieve information';
      popoverTitle = 'Unable to retrieve information';
  }

  const getCoverageTypes = () =>
    sectionType === Constants.RESULT_SECTION.PROVIDER_GROUPS
      ? JSON.stringify(['033', '050'])
      : [];

  const handleViewAllOnClick = () => {
    const updatedToken = tokenizer.update(token, {
      specialtyCode,
      search,
      sectionType,
      searchType,
      totalCount,
      userZip,
      includeOrgTypeCodes: getCoverageTypes(),
      linkName: viewAllDataTestId,
      providerType,
      searchRadius,
      dependentSeqNbr,
      acceptingNewPatients,
      searchMethod,
      searchTerm,
    });
    if (acceptingNewPatients) {
      setDefaultSearchFilters([FilterCollectionModel.ACCEPTING_NEW_PATIENTS]);
    }
    navigate(
      `${ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.path}/${updatedToken}`
    );
  };

  useEffect(() => {
    if (isOpenPopover) {
      const linkName: string = resultSectionPopover;
      const linkLocation: string = `body:${sectionType}`;
      const modalName: string =
        typeof sectionType !== 'string' ? String(sectionType) : sectionType;
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpenPopover]);

  return (
    <React.Fragment>
      {isLoading || results?.length ? (
        <Layout.Stack grow space={0}>
          <HeaderWrapper>
            <ResultWrapper>
              <Layout.Group>
                <Heading
                  css={headerTextStyle}
                  data-auto-testid={`results-section-header-${sectionType}`.replace(
                    / /g,
                    '-'
                  )}
                  data-testid={`results-section-header-${sectionType}`.replace(
                    / /g,
                    '-'
                  )}
                  display="h6"
                  offset={2}
                >
                  {t(resultsBySectionType)}
                </Heading>
                {!mobileScreen ? (
                  <Popover
                    aria-label={`Information about ${t(sectionType)} ${t(
                      'results'
                    )}`}
                    content={popoverContent}
                    css={{
                      '.abyss-popover-title': {
                        marginRight: '2px',
                      },
                    }}
                    data-auto-testid="result-section-popover-icon"
                    data-testid="result-section-popover-icon"
                    onOpenChange={() => setIsOpenPopover(!isOpenPopover)}
                    open={isOpenPopover}
                    title={popoverTitle || t(resultsBySectionType)}
                  >
                    <IconMaterial
                      data-auto-testid="result-section-popover-icon"
                      data-testid="result-section-popover-icon"
                      icon="info"
                      onClick={() => setIsOpenPopover(!isOpenPopover)}
                      size={16}
                      variant="outlined"
                    />
                  </Popover>
                ) : (
                  <Button
                    data-auto-testid="mobile-view-info-drawer-icon"
                    data-testid="mobile-view-info-drawer-icon"
                    onClick={() => setIsOpen(true)}
                    rounded
                    size="$xs"
                    variant="ghost"
                  >
                    <IconMaterial icon="info" size="$sm" variant="outlined" />
                  </Button>
                )}
              </Layout.Group>
              <Drawer
                css={InfoPopoverDrawerStyle}
                data-auto-testid="mobile-view-info-drawer"
                data-testid="mobile-view-info-drawer"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                position="bottom"
                size="$xs"
                title={
                  <Heading color="$gray8" offset={4}>
                    {popoverTitle || t(resultsBySectionType)}
                  </Heading>
                }
              >
                {popoverContent}
              </Drawer>
            </ResultWrapper>
            {result?.length ? (
              <LinkWrapper>
                <LinkWithTracking
                  after={<IconMaterial icon="chevron_right" size="$xs" />}
                  analyticsInfo={{
                    location: `body:${sectionType} results`,
                  }}
                  data-auto-testid={viewAllDataTestId}
                  data-testid={viewAllDataTestId}
                  isStandardAnchor
                  onClick={handleViewAllOnClick}
                >
                  <Text css={viewAllTextStyle}>{t('View all')}</Text>
                </LinkWithTracking>
              </LinkWrapper>
            ) : null}
          </HeaderWrapper>
          <ListWrapper displayCarousel={displayCarousel}>
            <MixedResultCards
              headers={headers}
              isLoading={isLoading}
              results={results}
              searchTerm={searchTerm}
              sectionType={sectionType}
            />
          </ListWrapper>
        </Layout.Stack>
      ) : (
        <Layout.Stack css={{ marginBottom: '$md' }} grow space={0}>
          <HeaderWrapper>
            <ResultWrapper>
              <Layout.Group>
                <Heading
                  css={headerTextStyle}
                  data-auto-testid={`results-section-header-${sectionType}`.replace(
                    / /g,
                    '-'
                  )}
                  data-testid={`results-section-header-${sectionType}`.replace(
                    / /g,
                    '-'
                  )}
                  display="h6"
                  offset={2}
                >
                  {t(resultsBySectionType)}
                </Heading>
                {!mobileScreen ? (
                  <Popover
                    aria-label={`Information about ${t(sectionType)} ${t(
                      'results'
                    )}`}
                    content={popoverContent}
                    css={{
                      '.abyss-popover-title': {
                        marginRight: '2px',
                      },
                    }}
                    data-auto-testid="result-section-popover-icon"
                    data-testid="result-section-popover-icon"
                    onOpenChange={() => setIsOpenPopover(!isOpenPopover)}
                    open={isOpenPopover}
                    title={popoverTitle || t(resultsBySectionType)}
                  >
                    <IconMaterial
                      data-auto-testid="result-section-popover-icon"
                      data-testid="result-section-popover-icon"
                      icon="info"
                      onClick={() => setIsOpenPopover(!isOpenPopover)}
                      size={16}
                      variant="outlined"
                    />
                  </Popover>
                ) : (
                  <Button
                    data-auto-testid="mobile-view-info-drawer-icon"
                    data-testid="mobile-view-info-drawer-icon"
                    onClick={() => setIsOpen(true)}
                    rounded
                    size="$xs"
                    variant="ghost"
                  >
                    <IconMaterial icon="info" size="$sm" variant="outlined" />
                  </Button>
                )}
              </Layout.Group>
              <Drawer
                css={InfoPopoverDrawerStyle}
                data-auto-testid="mobile-view-info-drawer"
                data-testid="mobile-view-info-drawer"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                position="bottom"
                size="$xs"
                title={
                  <Heading color="$gray8" offset={4}>
                    {popoverTitle || t(resultsBySectionType)}
                  </Heading>
                }
              >
                {popoverContent}
              </Drawer>
            </ResultWrapper>
          </HeaderWrapper>
          <NoResultsFoundContainer>
            <Text>{t('No Results Found')}</Text>
          </NoResultsFoundContainer>
        </Layout.Stack>
      )}
    </React.Fragment>
  );
};
