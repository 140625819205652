import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton } from '@abyss/web/ui/Skeleton';

const ImageContent = styled('div', {
  minWidth: '64px',
});

const ImageWrapper = styled('div', {
  transform: 'translate(25%, 0%)',
});

export const DataCardSkeletonLoader = () => (
  <Layout.Stack alignLayout="left" grow space="9">
    <Layout.Group marginLeft="12">
      <ImageContent>
        <ImageWrapper>
          <Skeleton
            alignLayout="right"
            grow
            height={48}
            variant="circular"
            width={48}
          />
        </ImageWrapper>
      </ImageContent>
      <Layout.Stack
        alignItems="left"
        alignLayout="left"
        css={{ paddingTop: '20px' }}
      >
        <Skeleton height={29} variant="rounded" width={325} />

        <Flex alignContent="stretch" width={350}>
          <Skeleton height={24} variant="rounded" width={75} />
          <Skeleton height={24} variant="rounded" width={100} />
        </Flex>

        <Skeleton height={29} variant="rounded" width={250} />
      </Layout.Stack>
    </Layout.Group>
    <Layout.Stack
      alignItems="left"
      alignLayout="center"
      css={{ marginLeft: '20px' }}
      grow
    >
      <Skeleton height={24} variant="rounded" width={325} />
      <Layout.Group alignItems="left" alignLayout="space-around" space={75}>
        <Skeleton height={24} variant="rounded" width={86} />
        <Skeleton height={23} variant="rounded" width={161} />
      </Layout.Group>
    </Layout.Stack>
  </Layout.Stack>
);
