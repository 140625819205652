import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { ImageComponent } from '../../../../../common/ImageComponent';
import { parseProviderName } from '../../../../../utils/providerDetails.utils';
import {
  AvatarShareCard,
  BoxStyled,
  ButtonCss,
  LocationIcon,
  PlaceHolderText,
  RemoveButtonIcon,
  RemoveButtonText,
  ShareBoxStyledPlaceHolders,
  ShareBoxTextAddress,
  ShareBoxTextName,
  addressFlexCss,
} from './ShareDrawer.styled';

type Props = {
  removeItem: (item: string) => void;
  selected: number;
  selectedProviderList: {
    providerName: string;
    providerType: string;
    providerId: string;
    primaryDegrees: string[];
    speciality: string;
    networkStatus: string;
    languagesSpoken: string[];
    acceptingNewPatients: boolean;
    gender: string;
    virtualCareOffered: boolean;
    address: {
      line: string[];
    };
    phones: {
      phone: string[];
    };
    locationId: string;
    imageLocation: string;
  }[];
};

export const ShareDrawerDesktop = ({
  selected,
  selectedProviderList,
  removeItem,
}: Props) => {
  const { t } = useTranslation();

  const imgRef = useRef<HTMLImageElement>(null);

  return (
    <React.Fragment>
      <Layout.Group
        css={{
          'abyss-layout-group': {
            marginTop: selected ? '0px' : '$lg',
            marginLeft: '20px',
            overflow: 'auto',
          },
        }}
        space={12}
      >
        {selectedProviderList.slice(0, 5).map((selectedProvider, index) => (
          <div>
            <Flex
              alignItems="center"
              data-auto-testid="share-provider-card"
              data-testid="share-provider-card"
              direction="column"
            >
              <BoxStyled
                key={selectedProvider.locationId}
                align="start"
                cssProps={{ selected }}
                data-auto-testid={`share-card-${selectedProvider.providerId}`}
                data-testid={`share-card-${selectedProvider.providerId}`}
              >
                <AvatarShareCard>
                  {ImageComponent({
                    imgRef,
                    providerType: 'Practitioner',
                    imgSrc: selectedProvider?.imageLocation,
                  })}
                </AvatarShareCard>
                <Flex direction="column">
                  <Tooltip
                    align="center"
                    asChild={false}
                    content={parseProviderName(
                      selectedProvider.providerName,
                      selectedProvider.providerType,
                      selectedProvider.primaryDegrees?.[0]
                    )}
                    position="top"
                    positionOffset={8}
                  >
                    <Text
                      css={ShareBoxTextName}
                      data-auto-testid="share-provider-name"
                      data-testid="share-provider-name"
                    >
                      {parseProviderName(
                        selectedProvider.providerName,
                        selectedProvider.providerType,
                        selectedProvider.primaryDegrees?.[0]
                      )}
                    </Text>
                  </Tooltip>
                  <Flex css={addressFlexCss}>
                    <IconMaterial css={LocationIcon} icon="location_on" />
                    <Tooltip
                      align="center"
                      asChild={false}
                      content={t(selectedProvider.address?.line[0])}
                      position="top"
                      positionOffset={8}
                    >
                      <Text css={ShareBoxTextAddress}>
                        {t(selectedProvider.address?.line[0])}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Flex>
              </BoxStyled>
            </Flex>
            <Layout.Group css={ButtonCss}>
              <Button
                data-auto-testid="button-remove"
                data-testid="button-remove"
                onClick={() => {
                  adobeLinkTrackEvent({
                    name: 'remove button',
                    location: `modal:share card:position ${index + 1}`,
                    type: 'internal',
                  });
                  removeItem(selectedProvider.locationId);
                }}
                variant="ghost"
              >
                <IconMaterial
                  css={RemoveButtonIcon}
                  icon="remove_circle_outline"
                />
                <Text css={RemoveButtonText}>
                  {t('SHARE_DRAWER_TEXT.REMOVE')}
                </Text>
              </Button>
            </Layout.Group>
          </div>
        ))}
        {Array.from({
          length: Math.max(0, 5 - selectedProviderList.length),
        }).map((place, index) => (
          <Box
            key={place}
            css={ShareBoxStyledPlaceHolders}
            data-auto-testid="share-selections-container"
            data-testid="share-selections-container"
            index={index}
          >
            <Text
              css={PlaceHolderText}
              data-auto-testid="share-drawer-placeholder"
              data-testid="share-drawer-placeholder"
            >
              {t('SHARE_DRAWER_TEXT.CARD_PLACEHOLDER')}
            </Text>
          </Box>
        ))}
      </Layout.Group>
    </React.Fragment>
  );
};
