import { Constants } from './Constants';

const FEATURE_FLAG_KEY = {
  GALILEO: 'GALILEO_VIRTUAL_CARE',
};

const SECTION_TERM = {
  BROWSE_SEARCHES: 'BROWSE_SEARCHES',
  COMMONLY_SEARCHED: 'COMMONLY_SEARCHED',
  MY_BENEFITS: 'MY_BENEFITS',
  RECENT_SEARCHES: 'RECENT_SEARCHES',
  RECENT_VISIT: 'RECENT_VISIT',
  VIRTUAL_CARE: 'VIRTUAL_CARE',
  ACTIVE_REFERRALS: 'ACTIVE_REFERRALS',
  REFERRAL_CARD: 'REFERRAL_CARD',
  TIER_ONE: 'TIER_ONE',
};

const FEATURE_FLAG_KEY_CHIP_MAP = {
  [Constants.CHIPS_CATEGORIES.ALL]: {
    [SECTION_TERM.COMMONLY_SEARCHED]: 'ALL_CARE_COMMONLY_SEARCHED',
    [SECTION_TERM.MY_BENEFITS]: 'ALL_CARE_MY_BENEFITS',
    [SECTION_TERM.RECENT_SEARCHES]: 'ALL_CARE_RECENT_SEARCHES',
    [SECTION_TERM.VIRTUAL_CARE]: 'ALL_CARE_VIRTUAL_CARE',
  },
  [Constants.CHIPS_CATEGORIES.PRIMARY_CARE]: {
    [SECTION_TERM.COMMONLY_SEARCHED]: 'PRIMARY_CARE_COMMONLY_SEARCHED',
    [SECTION_TERM.MY_BENEFITS]: 'PRIMARY_CARE_MY_BENEFITS',
    [SECTION_TERM.RECENT_SEARCHES]: 'PRIMARY_CARE_RECENT_SEARCHES',
    [SECTION_TERM.RECENT_VISIT]: 'PRIMARY_CARE_RECENT_VISIT',
    [SECTION_TERM.VIRTUAL_CARE]: 'PRIMARY_CARE_VIRTUAL_CARE',
    [SECTION_TERM.TIER_ONE]: 'TIER_ONE',
  },
  [Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS]: {
    [SECTION_TERM.ACTIVE_REFERRALS]: 'MEDICAL_SPECIALISTS_ACTIVE_REFERRALS',
    [SECTION_TERM.COMMONLY_SEARCHED]: 'MEDICAL_SPECIALISTS_COMMONLY_SEARCHED',
    [SECTION_TERM.MY_BENEFITS]: 'MEDICAL_SPECIALISTS_MY_BENEFITS',
    [SECTION_TERM.RECENT_SEARCHES]: 'MEDICAL_SPECIALISTS_RECENT_SEARCHES',
    [SECTION_TERM.RECENT_VISIT]: 'MEDICAL_SPECIALISTS_RECENT_VISIT',
    [SECTION_TERM.REFERRAL_CARD]: 'MEDICAL_SPECIALISTS_REFERRAL_CARD',
    [SECTION_TERM.VIRTUAL_CARE]: 'MEDICAL_SPECIALISTS_VIRTUAL_CARE',
  },
  [Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH]: {
    [SECTION_TERM.BROWSE_SEARCHES]: 'BEHAVIORAL_HEALTH_BROWSE_SEARCHES',
    [SECTION_TERM.COMMONLY_SEARCHED]: 'BEHAVIORAL_HEALTH_COMMONLY_SEARCHED',
    [SECTION_TERM.MY_BENEFITS]: 'BEHAVIORAL_HEALTH_MY_BENEFITS',
    [SECTION_TERM.RECENT_SEARCHES]: 'BEHAVIORAL_HEALTH_RECENT_SEARCHES',
    [SECTION_TERM.RECENT_VISIT]: 'BEHAVIORAL_HEALTH_RECENT_VISIT',
    [SECTION_TERM.VIRTUAL_CARE]: 'BEHAVIORAL_HEALTH_VIRTUAL_CARE',
  },
  [Constants.CHIPS_CATEGORIES.HEALTH_SUPPLIES]: {
    [SECTION_TERM.COMMONLY_SEARCHED]: 'MEDICAL_SUPPLIES_COMMONLY_SEARCHED',
    [SECTION_TERM.MY_BENEFITS]: 'MEDICAL_SUPPLIES_MY_BENEFITS',
    [SECTION_TERM.RECENT_SEARCHES]: 'MEDICAL_SUPPLIES_RECENT_SEARCHES',
    [SECTION_TERM.RECENT_VISIT]: 'MEDICAL_SUPPLIES_RECENT_VISIT',
    [SECTION_TERM.VIRTUAL_CARE]: 'MEDICAL_SUPPLIES_VIRTUAL_CARE',
  },
  [Constants.CHIPS_CATEGORIES.FACILITIES]: {
    [SECTION_TERM.COMMONLY_SEARCHED]: 'LABS_AND_SERVICES_COMMONLY_SEARCHED',
    [SECTION_TERM.MY_BENEFITS]: 'LABS_AND_SERVICES_MY_BENEFITS',
    [SECTION_TERM.RECENT_SEARCHES]: 'LABS_AND_SERVICES_RECENT_SEARCHES',
    [SECTION_TERM.RECENT_VISIT]: 'LABS_AND_SERVICES_RECENT_VISIT',
    [SECTION_TERM.VIRTUAL_CARE]: 'LABS_AND_SERVICES_VIRTUAL_CARE',
  },
  [Constants.CHIPS_CATEGORIES.DENTAL]: {
    [SECTION_TERM.COMMONLY_SEARCHED]: 'DENTAL_COMMONLY_SEARCHED',
    [SECTION_TERM.MY_BENEFITS]: 'DENTAL_MY_BENEFITS',
    [SECTION_TERM.RECENT_SEARCHES]: 'DENTAL_RECENT_SEARCHES',
    [SECTION_TERM.VIRTUAL_CARE]: 'DENTAL_VIRTUAL_CARE',
  },
  [Constants.CHIPS_CATEGORIES.VISION]: {
    [SECTION_TERM.COMMONLY_SEARCHED]: 'VISION_COMMONLY_SEARCHED',
    [SECTION_TERM.MY_BENEFITS]: 'VISION_MY_BENEFITS',
    [SECTION_TERM.RECENT_SEARCHES]: 'VISION_RECENT_SEARCHES',
    [SECTION_TERM.VIRTUAL_CARE]: 'VISION_VIRTUAL_CARE',
  },
};

export const FeatureFlags = {
  FEATURE_FLAG_KEY,
  FEATURE_FLAG_KEY_CHIP_MAP,
};
