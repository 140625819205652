import React from 'react';
import PropTypes from 'prop-types';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';
import { styled } from '@abyss/web/tools/styled';
import { FormInput } from '@abyss/web/ui/FormInput';
import { Focusable } from '@abyss/web/ui/Focusable';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';

const SelectRoot = styled(FormInput.Wrapper, {
  static: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    minWidth: '225px',
    height: '40px',
  },
  dynamic: ({ cssProps }) => {
    const { width } = cssProps;
    return {
      width,
    };
  },
});

const FormWrapper = styled('div', {
  position: 'relative',
  borderRadius: '4px',
  width: '100%',
  variants: {
    rounded: {
      true: {
        borderRadius: '50px',
      },
    },
  },
});

const StyledFormInput = styled(FormInput, {
  static: {
    variants: {
      isOpen: {
        true: {
          '&:focus-visible': {
            outline: 'none !important',
            boxShadow: 'none !important',
          },
          '&:focus': {
            outline: 'none !important',
            boxShadow: 'none !important',
          },
          borderColor: '$interactive1',
        },
      },
      error: {
        true: {
          borderColor: '$error1',
        },
      },
      placedSide: {},
      rounded: {
        true: {
          borderRadius: '50px',
        },
      },
    },
    compoundVariants: [
      {
        isOpen: true,
        placedSide: 'bottom',
        css: {
          paddingBottom: '1px',
          borderRadius: '4px 4px 0px 0px',
          borderBottom: 'none',
        },
      },
      {
        isOpen: true,
        rounded: true,
        placedSide: 'bottom',
        css: {
          borderRadius: '1rem 1rem 0px 0px',
        },
      },
      {
        isOpen: true,
        placedSide: 'top',
        css: {
          paddingTop: '1px',
          borderRadius: '0px 0px 4px 4px',
          borderTop: 'none',
        },
      },
      {
        isOpen: true,
        rounded: true,
        placedSide: 'top',
        css: {
          borderRadius: '0px 0px 1rem 1rem',
        },
      },
      {
        isOpen: true,
        error: true,
        css: {
          borderColor: '$error1',
        },
      },
    ],
  },
  dynamic: ({ cssProps }) => {
    const { height } = cssProps;
    return {
      height,
    };
  },
});

const IconContainer = styled(Focusable, {
  padding: 0,
  borderRadius: 4,
  display: 'flex',
  border: 'none',
  background: 'transparent',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35px',
  height: '100%',
  variants: {
    isDisabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
  },
});

export const SearchInputField = ({
  rootProps,
  labelProps,
  inputProps,
  searchButtonProps,
  clearProps,
  descriptorProps,
  onClick,
  state,
  children,
  inputLeftElement,
  inputRightElement,
  ...props
}) => {
  const abyssProps = useAbyssProps(props);
  const {'data-auto-testid': dataAutoTestid} = {...props};

  const { hideLabel, showClear, label, width, height, isLoading, rounded } =
    state;

  return (
    <SelectRoot cssProps={{ width }}  {...abyssProps('search-input-root')}>
      <FormInput.Label
        {...labelProps}
        hideLabel={hideLabel}
        {...abyssProps('search-input-label')}
      >
        {label}
      </FormInput.Label>
      <FormWrapper
        {...rootProps}
        {...abyssProps('search-input-input-container')}
      >
        <StyledFormInput
          cssProps={{ height }}
          {...abyssProps('search-input-input')}
          {...inputProps}
          data-auto-testid={dataAutoTestid}
        />
        <FormInput.ElementWrapper
          orientation="left"
          rounded={rounded}
          {...abyssProps('search-input-left-element-wrapper')}
        >
          <FormInput.Element {...abyssProps('search-input-left-element')}>
            {inputLeftElement}
          </FormInput.Element>
        </FormInput.ElementWrapper>

        <FormInput.ElementWrapper
          {...abyssProps('search-input-right-element-wrapper')}
          rounded={rounded}
        >
          {showClear && !isLoading && (
            <FormInput.Clear
              {...abyssProps('search-input-clear')}
              {...clearProps}
            />
          )}
          <FormInput.Element {...abyssProps('search-input-right-element')}>
            {inputRightElement}
          </FormInput.Element>
          <IconContainer
            {...searchButtonProps}
            {...abyssProps('search-input-icon-container')}
          >
            <IconMaterial
              color={inputProps.disabled ? '$gray5' : '$interactive1'}
              icon="search"
              title="Search"
              {...abyssProps('search-input-icon')}
            />
          </IconContainer>
        </FormInput.ElementWrapper>
        {children}
      </FormWrapper>
      <FormInput.Descriptors
        {...descriptorProps}
        {...abyssProps('search-input-descriptors')}
      />
    </SelectRoot>
  );
};

SearchInputField.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  rootProps: PropTypes.shape({}).isRequired,
  labelProps: PropTypes.shape({}).isRequired,
  inputProps: PropTypes.shape({
    disabled: PropTypes.bool,
  }).isRequired,
  clearProps: PropTypes.shape({}).isRequired,
  searchButtonProps: PropTypes.shape({}).isRequired,
  descriptorProps: PropTypes.shape({}).isRequired,
  state: PropTypes.shape({
    isOpen: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    showClear: PropTypes.bool,
    hideLabel: PropTypes.bool,
    label: PropTypes.string,
    multiLabel: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rounded: PropTypes.bool,
    selectedItems: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    selectedItem: PropTypes.shape({}),
  }).isRequired,
  inputLeftElement: PropTypes.node,
  inputRightElement: PropTypes.node,
};

SearchInputField.defaultProps = {
  onClick: null,
  inputLeftElement: null,
  inputRightElement: null,
};
