import { styled } from '@abyss/web/tools/styled';

export const TabContainer = styled('div', {
  color: '$primary1',
});

export const SectionContainer = styled('div', {
  marginTop: '8px',
  borderStyle: 'solid',
  borderWidth: '1px 0px',
  borderRadius: '0px',
  borderColor: '#E5E5E6',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  backgroundColor: 'white',
  boxSizing: 'border-box',
  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
});

export const RatingHeader = {
  'abyss-text-root': {
    fontSize: '$md',
    fontWeight: '$bold',
    lineHeight: '$md',
    maxWidth: '343px',
    color: '$neutralGray',
    marginBottom: '$sm',
    '@screen < $sm': {
      fontSize: '$sm',
      lineHeight: '$md',
    },
  },
};
export const RatingText = {
  'abyss-text-root': {
    fontSize: '$extraSmallText',
    fontWeight: '$medium',
    lineHeight: '$sm',
    maxWidth: '343px',
    color: '$neutralGray',
    marginTop: '$sm',
    '@screen < $sm': {
      fontSize: '$sm',
      lineHeight: '$md',
    },
  },
};

export const RatingContainer = {
  'abyss-flex-root': {
    marginBottom: '$lg',
  },
};
export const TextStyle = {
  'abyss-text-root': {
    display: 'inline',
    fontSize: '$lg',
    lineHeight: '$lg',
    fontWeight: '$medium',
    '@screen < $sm': {
      fontSize: '$md',
      lineHeight: '$md',
    },
  },
};
export const LinkStyle = {
  'abyss-text-root': {
    fontSize: '$lg',
    lineHeight: '$lg',
    fontWeight: '$medium',
    '@screen < $sm': {
      fontSize: '$md',
      lineHeight: '$md',
    },
  },
};

export const FillBar = {
  'abyss-box-root': {
    width: '96px',
    height: '6px',
    marginRight: '$sm',
    padding: 0,
  },
};

export const LeapFrogContainer = styled('div', {
  fontSize: '16px',
  lineHeight: '20px',
  display: 'flex',
  flexDirection: 'column',
  '@screen < $md': {
    fontSize: '$sm',
  },
});

export const LeapFrogTextStyle = {
  'abyss-text-root': {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '$medium',
    margin: '20px 0 0 0',
    '@screen < $sm': {
      fontSize: '14.22px',
      lineHeight: '20px',
    },
    color: '$neutralGray',
  },
};

export const AccreditationTextStyle = {
  'abyss-text-root': {
    display: 'flex',
    maxWidth: '840px',
    fontSize: '$lg',
    lineHeight: '$lg',
    fontWeight: '$medium',
    color: '$neutralGray',
    '@screen < $sm': {
      fontSize: '$md',
      lineHeight: '$md',
    },
  },
};
