import { styled } from '@abyss/web/tools/styled';

export const DefaultErrorWrapper = styled('div', {
  background: '$tint1',
  height: '363px',
  '@screen < $sm': {
    height: '388px',
  },
});

export const BodyWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gridGap: '20px',
  marginRight: '1rem',
  '@screen < $sm': {
    marginRight: 0,
  },
});

export const textBody = {
  'abyss-text-root': {
    width: '55%',
    '@screen < $lg': {
      width: '100%',
    },
    a: {
      fontWeight: '$bold',
      textDecoration: 'underline',
    },
  },
};
