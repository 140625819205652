import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Pagination } from '@abyss/web/ui/Pagination';

export const SidePaneCloseButtonWrap = styled('div', {
  position: 'absolute',
  '@screen < $sm': {
    display: 'none',
  },
});

export const SidePaneCloseButton = styled(Flex, {
  top: '$lg',
  position: 'absolute',
  cursor: 'pointer',
  zIndex: 1,
  height: '42px',
  width: '26.17px',
  backgroundColor: 'white',
  boxShadow: '0 2px 6px rgba(25, 25, 26, 0.16)',
  border: '1px solid $gray3',
  borderRadius: '0 4px 4px 0',
});

export const PopupStyle = styled({
  background: 'transparent !important',
});

export const MapDataPagination = styled(Pagination, {
  'abyss-pagination-root': {
    margin: '10px auto 20px',
    '@screen < $md': {
      visibility: 'hidden',
      display: 'inline-block',
    },
  },
});

export const ListWrapColumn = styled('div', {
  dynamic: ({ cssProps: { isOpenCompareShare } }) => ({
    height: isOpenCompareShare ? 'calc(100vh - 220px)' : 'calc(100vh - 187px)',
  }),
  display: 'inline-block',
  boxShadow: '#xs 0px $xs rgba(0, 0, 0, 0.2)',
  backgroundColor: '$primary2',
  zIndex: 2,
  '@screen < $sm': {
    position: 'fixed',
    width: '100%',
    background: 'transparent',
    bottom: '$lg',
    height: 'auto !important',
    '.side-btn': { display: 'none' },
  },
  flexShrink: 1,
});

export const ListWrap = styled('div', {
  display: 'flex',
  width: 432,
  paddingRight: '$md',
  height: '100%',
  gap: '$md',
  flexWrap: 'nowrap',
  flexDirection: 'column',
  '@screen >= $sm': {
    overflowY: 'auto',
  },
  '.abyss-pagination-results-text': {
    marginLeft: 0,
    '@screen < $md': {
      display: 'none',
    },
  },
  '@screen < $sm': {
    width: '100%',
    background: 'transparent',
    display: 'flex',
    gap: '$md',
    padding: '0px',
    flexDirection: 'column',
    '.abyss-card-root': {
      display: 'inline-flex',
      width: '320px',
    },
  },
});

export const MapViewWrap = styled('div', {
  position: 'relative',
  width: '100%',
  height: 'calc(100vh - 187px)',
});

export const PaginationControl = styled('div', {
  display: 'flex',
  '.abyss-pagination-page-size-root': {
    marginRight: '10px',
  },
  '@screen < $md': {
    display: 'none',
  },
});

export const LoadingContainer = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
});

export const ZoomButton = styled(Flex, {
  background: '$primary2',
  width: '48px',
  height: '88px',
  borderRadius: '100px',
  gap: '$md',
  padding: '3px',
});

export const PagingationWrapStyled = styled('div', {
  display: 'flex',
  gap: '16px',
  position: 'sticky',
  paddingBottom: '16px',
});

export const EAPStyle = styled('div', {
  paddingBottom: '32px',
});

export const SkeletonWrapper = styled('div', {
  overflow: 'auto',
});

export const MapviewWrapper = styled(Flex, {
  dynamic: ({
    cssProps: { isOpenCompareShare, openMapview, navigateToDirections },
  }) => ({
    height: isOpenCompareShare ? 'calc(100vh - 123px)' : 'calc(100vh - 90px)',
    display: openMapview || navigateToDirections ? 'flex' : 'none',
  }),
  flexWrap: 'nowrap',
  '@screen < $sm': { height: '100vh' },
});
