import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';

export const BreadcrumbsContainer = styled('div', {
  variants: {
    showBreadcrumbAtMediumScreenSize: {
      true: {
        '@screen < $sm': {
          display: 'none',
        },
      },
      false: {
        '@screen < $lg': {
          display: 'none',
        },
      },
    },
  },
  '.abyss-breadcrumbs-root': {
    backgroundColor: '$primary1',
    flexWrap: 'no-wrap',
    paddingBottom: '$sm',
    color: '$primary2',
  },
  '.abyss-breadcrumb-link': {
    color: '$primary2',
    fontWeight: '500',
  },
  '.abyss-breadcrumb-current': {
    color: '$primary2',
  },
});

export const BreadcrumbsSpacing = styled('div', {
  '@screen < $sm': {
    backgroundColor: '$primary1',
    height: '16px',
  },
  '@screen >= $sm': {
    display: 'none',
  },
});

export const HeaderIcon = styled(IconMaterial, {
  '&.abyss-icon-material': {
    marginRight: '12px',
    minWidth: '24px',
    minHeight: '24px',
  },
});

export const BackButton = styled(Button, {
  variants: {
    showBreadcrumbAtMediumScreenSize: {
      true: {
        '@media (min-width: 463px)': {
          display: 'none',
        },
      },
      false: {
        '@screen >= $lg': {
          display: 'none',
        },
      },
    },
  },
  '&.abyss-button-root': {
    height: 'unset',
    border: 'none',
    padding: '0',
    lineHeight: '$sm',
  },
});
