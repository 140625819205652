export const FillBar = {
  width: '96px',
  height: '6px',
  padding: 0,
};

export const RatingContainerStyle = {
  'abyss-flex-root': {
    marginBottom: '$lg',
    marginRight: '$lg',
    '@screen < $sm': {
      marginRight: '0',
    },
  },
};

export const RatingHeader = {
  'abyss-text-root': {
    fontSize: '$md',
    fontWeight: '$bold',
    lineHeight: '$md',
    maxWidth: '343px',
    color: '$neutralGray',
    marginBottom: '$sm',
    '@screen < $sm': {
      fontSize: '$sm',
      lineHeight: '$md',
    },
  },
};
export const RatingText = {
  'abyss-text-root': {
    fontSize: '$extraSmallText',
    fontWeight: '$medium',
    lineHeight: '$sm',
    maxWidth: '343px',
    color: '$neutralGray',
    marginTop: '$sm',
    textTransform: 'uppercase',
    '@screen < $sm': {
      fontSize: '$sm',
      lineHeight: '$md',
    },
  },
};
