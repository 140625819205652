import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import isEmpty from 'lodash/isEmpty';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../hooks/useIsOverflow';
import { CompareProvider } from '../../../../../../../../models/Provider';
import {
  StyledHoursOfOperationDiv,
  TextRoot,
  italicFontStyle,
} from '../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../utility/compareDrawerConstants';
import { getHoursOfOperaion } from '../../../utility/getHoursOfOperation';

type Props = {
  selectedProvider: CompareProvider;
};

export const HoursOfOperation = ({ selectedProvider }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const { daysWithTime, possibleHours } = getHoursOfOperaion(
    selectedProvider.hoursOfOperation
  );

  const isAttributeEmpty =
    isEmpty(daysWithTime) ||
    isEmpty(possibleHours) ||
    isEmpty(selectedProvider.hoursOfOperation);

  return (
    <StyledHoursOfOperationDiv
      ref={containerRef}
      cssProps={{ isOverflow, isAttributeEmpty }}
    >
      {isAttributeEmpty ? (
        <TextRoot css={italicFontStyle}>
          {t(NULL_ATTRIBUTE_STATE.HOURS_OF_OPERATION)}
        </TextRoot>
      ) : (
        <Layout.Group alignItems="bottom" space={5}>
          <IconMaterial
            color="$primary1"
            icon="access_time"
            isScreenReadable
            size={20}
            title="Hours"
          />
          <TextRoot css={{ fontWeight: 'bold' }}>{daysWithTime} :</TextRoot>
          <TextRoot>{possibleHours}</TextRoot>
        </Layout.Group>
      )}
    </StyledHoursOfOperationDiv>
  );
};
