/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { desktopOnly } from '../../../ConstantsStyles';
// import { ChangeLocationInput } from './ChangeLocationInput';
import { LocationSuggestionSkeleton } from './ChangeLocationSkeleton';
// import { CurrentLocationLink } from './CurrentLocationLink';
import { ChangeLocationDrawer, popoverStyles } from './LocationDropdown.styled';
import { LocationSuggestionStack } from './LocationSuggestionStack';

// import { IChangeLocationFormProps, IChangeLocationProps } from './types';

export const ChangeLocationForm = ({ onLocationSelect, typeAheadSearch }) => {
  const form = useForm();
  const { t } = useTranslation();

  // event handlers
  const onFormSubmit = () => {
    onLocationSelect();
  };

  const onInputReceived = (value: string) => {
    // setLocationSearchInputTextValue(value);
  };

  const handleLocationLinkClick = () => {
    onLocationSelect();
    // setLocationSearchInputTextValue('');
  };

  return (
    <FormProvider
      data-testid="change-location-form"
      onSubmit={onFormSubmit}
      state={form}
    >
      <br />
      <Layout.Stack grow space={15}>
        <Layout.Group>
          <Text color="$gray9">
            {t('Enter a street address, city and state or a 5-digit zip code.')}
          </Text>
        </Layout.Group>
        <Layout.Group grow>
          <Layout.Stack css={{ 'abyss-layout-stack': { width: '100%' } }} grow>
            {/* <ChangeLocationInput
              currentSearchInput={locationSearchInputTextValue}
              data-testid="changeLocation_change-location-input"
              onInput={onInputReceived}
              warningMessage={warningMessage}
            /> */}
          </Layout.Stack>
        </Layout.Group>
        <Layout.Group>
          {/* <CurrentLocationLink
            data-testid="changeLocation_currentLocationAction"
            onLocationSelect={handleLocationLinkClick}
          /> */}
        </Layout.Group>
        {/* {isLoadingLocationSuggestions ? (
          <LocationSuggestionSkeleton />
        ) : ( */}
        <LocationSuggestionStack
          activeSuggestion={-1}
          onSuggestionClick={onFormSubmit}
          suggestions={locationSuggestions}
        />
        {/* )} */}
      </Layout.Stack>
    </FormProvider>
  );
};

export const ChangeLocation = ({ title, children, onLocationSelect }) => {
  //   const { t } = useTranslation();

  const [isFullScreenOpen, setFullScreenOpen] = useState(false);
  const [isPopoverOpen, setIsPopOverOpen] = useState(false);
  const isDesktopView = useMediaQuery(desktopOnly);

  const closeFullScreen = () => setFullScreenOpen(false);
  const openFullScreen = () => setFullScreenOpen(true);
  const closePopOver = () => setIsPopOverOpen(false);
  const openPopOver = () => setIsPopOverOpen(true);
  const togglePopOver = () => setIsPopOverOpen(!isPopoverOpen);

  const handleLocationChange = () => {
    if (isDesktopView) {
      closePopOver();
    } else {
      closeFullScreen();
    }
    onLocationSelect();
  };

  return (
    <React.Fragment>
      {!isDesktopView && (
        <React.Fragment>
          <ChangeLocationDrawer
            data-testid="changeLocation_change-location-drawer"
            isOpen={isFullScreenOpen}
            onClose={closeFullScreen}
            position="bottom"
            size="$lg"
            title={title}
          >
            <ChangeLocationForm
              activeSuggestion={-1}
              data-testid="change-location-form"
              onLocationSelect={handleLocationChange}
              //   typeAheadSearch={typeAheadSearch}
            />
          </ChangeLocationDrawer>
          <Layout.Group
            data-testid="change-location-button-lg-minus"
            onClick={openFullScreen}
          >
            {children}
          </Layout.Group>
        </React.Fragment>
      )}
      {isDesktopView && (
        <Popover
          align="end"
          content={
            isPopoverOpen && (
              <ChangeLocationForm
                activeSuggestion={-1}
                data-testid="change-location-form"
                onLocationSelect={handleLocationChange}
                // typeAheadSearch={typeAheadSearch}
              />
            )
          }
          css={popoverStyles}
          data-testid="changeLocation_change-location-popover"
          onOpenChange={togglePopOver}
          open={isPopoverOpen}
          position="bottom"
          title={title}
          width="375px"
        >
          <Layout.Group
            data-testid="change-location-button-lg-plus"
            onClick={openPopOver}
          >
            {children}
          </Layout.Group>
        </Popover>
      )}
    </React.Fragment>
  );
};
