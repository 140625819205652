/* eslint-disable no-param-reassign */
import { useForm } from '@abyss/web/hooks/useForm';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Flex } from '@abyss/web/ui/Flex';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import {
  editCompareButtonsApplyCancelStyle,
  editCompareCheckboxStyle,
  editCompareFloatingSectionStyle,
} from '../../utility/compareDrawer.styled';
import {
  CompareCheckboxInfo,
  MAX_NUM_OF_ATTRIBUTES,
} from '../../utility/compareDrawerConstants';
import { editCompareButtonRules } from './EditCompareButtonRules';

type Props = {
  initialCheckCount: number;
  items: CompareCheckboxInfo[];
  setChangedTempItems: Function;
  isEditCompareOpen: boolean;
  setOpenEdit: (a: boolean) => void;
  nameOfButtonClickedLast: string;
  setNameOfButtonClickedLast: Function;
};

export const EditCompareDrawer = ({
  initialCheckCount,
  items,
  setChangedTempItems,
  isEditCompareOpen,
  setOpenEdit,
  nameOfButtonClickedLast,
  setNameOfButtonClickedLast,
}: Props) => {
  const { t } = useTranslation();

  const itemsToShowInCheckboxes = useMemo(() => cloneDeep(items), [items]);
  const [checkBoxStateChanged, setCheckBoxStateChanged] = useState(false);
  const numChecked = useRef<number>(initialCheckCount);

  const setNumChecked = (count) => {
    numChecked.current = count;
  };

  const handleAdobeLinkTrackEditComparision = (buttonName: string) => {
    adobeLinkTrackEvent({
      name: buttonName,
      location: `modal:edit comparison`,
      type: buttonName.includes('check') ? 'checkbox' : 'internal',
    });
  };

  const getApplyButtonState = (useCompareCheckboxes) =>
    !useCompareCheckboxes.some(
      (checkbox) =>
        checkbox.name !== 'inNetwork' &&
        checkbox.name !== 'cost' &&
        checkbox.isChecked === true
    );

  useEffect(() => {
    setChangedTempItems(itemsToShowInCheckboxes);
  }, [checkBoxStateChanged]);

  const form = useForm({
    defaultValues: {
      'checkbox-form': [
        'reviews',
        'specialties',
        'distance',
        'tierOneProvider',
        'education',
      ],
    },
  });

  const onSubmit = (data) => {
    if (isEditCompareOpen) {
      const changedTempItems = cloneDeep(itemsToShowInCheckboxes);
      const formData = data['checkbox-form'];
      changedTempItems.forEach((checkbox, index) => {
        const tempCheckBox = checkbox;
        tempCheckBox.isChecked = formData?.includes(checkbox.name) || false;
        changedTempItems[index] = tempCheckBox;
      });
      if (changedTempItems?.length > 0) setChangedTempItems(changedTempItems);
    }
  };
  const closeEditCompare = () => {
    handleAdobeLinkTrackEditComparision('close button');
    setNameOfButtonClickedLast('cancel');
    numChecked.current = initialCheckCount;
  };

  const cancelEditCompare = () => {
    handleAdobeLinkTrackEditComparision('cancel button');
    setNameOfButtonClickedLast('cancel');
    numChecked.current = initialCheckCount;
  };

  useEffect(() => {
    editCompareButtonRules(
      items,
      setChangedTempItems,
      setOpenEdit,
      setNumChecked,
      nameOfButtonClickedLast,
      setNameOfButtonClickedLast,
      itemsToShowInCheckboxes,
      form
    );
  }, [nameOfButtonClickedLast]);

  const handleCheckBoxClick = (checkbox: CompareCheckboxInfo) => {
    handleAdobeLinkTrackEditComparision(
      `${checkbox.label}${checkbox.isChecked ? ':unchecked' : ':checked'}`
    );
    checkbox.isChecked = !checkbox.isChecked;
    setCheckBoxStateChanged(!checkBoxStateChanged);

    const newNumChecked = itemsToShowInCheckboxes?.filter(
      (item) => item?.isChecked
    ).length;

    setNumChecked(newNumChecked);
  };

  return isEditCompareOpen ? (
    <FloatingSection css={editCompareFloatingSectionStyle}>
      <FormProvider
        data-auto-testid="compare-providers-edit-compare-apply-edits-button"
        data-testid="compare-providers-edit-compare-apply-edits-button"
        onSubmit={onSubmit}
        state={form}
      >
        <Flex justify="space-between">
          <Layout.Group>
            <Heading color="$gray7" offset={5}>
              {t('Edit comparison')}
            </Heading>{' '}
            <Button
              data-auto-testid="compare-providers-edit-compare-deselect-all"
              data-testid="compare-providers-edit-compare-deselect-all"
              onClick={() => {
                handleAdobeLinkTrackEditComparision('deselect all button');
                setNameOfButtonClickedLast('deselect-all');
              }}
              variant="tertiary"
            >
              {t('Deselect all')}
            </Button>
            <Button
              css={{ paddingLeft: '0px' }}
              data-auto-testid="compare-providers-edit-compare-reset"
              data-testid="compare-providers-edit-compare-reset"
              onClick={() => {
                handleAdobeLinkTrackEditComparision('reset button');
                setNameOfButtonClickedLast('reset');
              }}
              variant="tertiary"
            >
              {t('Reset')}
            </Button>
          </Layout.Group>
          <Layout.Group>
            <IconMaterial
              data-auto-testid="compare-providers-edit-compare-close-icon"
              data-testid="compare-providers-edit-compare-close-icon"
              icon="close"
              onClick={closeEditCompare}
            />
          </Layout.Group>
        </Flex>
        <Layout.Stack alignItems="left" space={16}>
          <CheckboxGroup display="row" model="checkbox-form">
            {itemsToShowInCheckboxes.map(
              (checkbox) =>
                checkbox.name !== 'inNetwork' &&
                checkbox.name !== 'cost' && (
                  <Checkbox
                    key={`CheckBoxKey-${checkbox.name}`}
                    css={editCompareCheckboxStyle}
                    data-auto-testid={`compare-providers-edit-compare-checkbox-${checkbox.name}`}
                    data-testid={`compare-providers-edit-compare-checkbox-${checkbox.name}`}
                    isChecked={checkbox.isChecked}
                    isDisabled={
                      numChecked.current >= MAX_NUM_OF_ATTRIBUTES &&
                      !checkbox?.isChecked
                    }
                    label={t(checkbox.label || '')}
                    onChange={() => handleCheckBoxClick(checkbox)}
                    value={checkbox.name}
                  />
                )
            )}
          </CheckboxGroup>
          <Layout.Group>
            <Button
              css={editCompareButtonsApplyCancelStyle}
              data-auto-testid="compare-providers-apply"
              data-testid="compare-providers-apply"
              isDisabled={getApplyButtonState(itemsToShowInCheckboxes)}
              onClick={() => {
                handleAdobeLinkTrackEditComparision('apply button');
                setNameOfButtonClickedLast('apply');
              }}
              onSubmit={onSubmit}
              type="submit"
            >
              {t('Apply')}
            </Button>
            <Button
              css={editCompareButtonsApplyCancelStyle}
              data-auto-testid="compare-providers-cancel"
              data-testid="compare-providers-cancel"
              onClick={cancelEditCompare}
              variant="outline"
            >
              {t('CANCEL')}
            </Button>
          </Layout.Group>
        </Layout.Stack>
      </FormProvider>
    </FloatingSection>
  ) : null;
};
