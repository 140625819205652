import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
/* eslint-disable no-nested-ternary */
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { SnackCard } from '../SnackCard/SnackCard';
import {
  carouselStyle,
  slideContainerStyle,
  slideStyleSnack,
} from '../SnackCard/SnackCard.styles';
import { TierOne } from '../SnackCard/TierOne';
import { getFeatureFlag } from '../Utils';

type Props = {
  snackCardProviders: SnackCardProvider[];
  isLoading: boolean;
  isFixes: boolean;
  locationForAnalytics?: string;
  headers: ResponseHeaders;
};

export const SnackCardCarousel = ({
  snackCardProviders = [],
  isLoading,
  isFixes,
  locationForAnalytics,
  headers,
}: Props) => {
  const { t } = useTranslation();

  const featureFlags = useLagoon('feature-flags')();
  const decemberFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );
  const isHorizontalDisplay = snackCardProviders.some(
    (e) => e.isTieredProvider === true
  );

  const slides = isLoading
    ? [1, 2, 3, 4, 5].map((item) => (
        <SnackCard key={item} isFixes={isFixes} isLoading={isLoading} />
      ))
    : snackCardProviders.length
    ? // eslint-disable-next-line arrow-body-style
      snackCardProviders.map((snackCardProvider, index) => {
        return (
          <div>
            <TierOne
              data-auto-testid="tier-one-snack-card-tag"
              data-testid="tier-one-snack-card-tag"
              isHorizontalDisplay={isHorizontalDisplay}
              isTierOne={snackCardProvider?.isTieredProvider}
            />
            <Slide css={slideStyleSnack}>
              <Slide.Container css={slideContainerStyle(decemberFlag)}>
                <SnackCard
                  key={snackCardProvider.locationId}
                  distance={snackCardProvider.distance}
                  hasPhysicalAddress={index === 0}
                  headers={headers}
                  imgSrc={snackCardProvider.imageLocation}
                  index={index}
                  isFixes={isFixes}
                  isLoading={isLoading}
                  isTieredProvider={snackCardProvider?.isTieredProvider}
                  isVirtualCareOffered={index === 4}
                  locationForAnalytics={locationForAnalytics}
                  organizationType={snackCardProvider.organizationType}
                  professionalDesignation={
                    snackCardProvider.primaryDegrees?.length
                      ? snackCardProvider.primaryDegrees[0]
                      : ''
                  }
                  providerId={snackCardProvider.providerId}
                  providerName={snackCardProvider.providerName}
                  providerType={snackCardProvider.providerType}
                  rating={
                    snackCardProvider.healthGradeRating
                      ? parseFloat(
                          parseFloat(
                            snackCardProvider.healthGradeRating
                          ).toFixed(1)
                        )
                      : undefined
                  }
                  snackCardProvider={snackCardProvider}
                  speciality={snackCardProvider?.speciality}
                  website={snackCardProvider?.website || ''}
                />
              </Slide.Container>
            </Slide>
          </div>
        );
      })
    : t('No Results Found');

  return (
    <Carousel
      css={carouselStyle(decemberFlag)}
      data-auto-testid="browse-facilities-carousel"
      data-testid="browse-facilities-carousel"
      minimal
      noLoop
      slides={slides}
      slidesPerView={1}
    />
  );
};
