import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';
import { useVisuallyHidden } from '@abyss/web/hooks/useVisuallyHidden';
import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton } from '@abyss/web/ui/Skeleton';
import { Option } from './Option';

const convertToInt = (value) => {
  if (typeof value === 'string') {
    if (!value.endsWith('%')) {
      return parseInt(value, 10);
    }
  }
  return value;
};

const List = styled('ul', {
  static: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1000,
    backgroundColor: '$white',
    width: '100%',
    left: 0,
    right: 0,
    maxHeight: 190,
    overflowY: 'auto',

    variants: {
      isOpen: {
        true: {
          border: '1px solid $gray3',
          borderRadius: '0px 0px 4px 4px',
          borderColor: '$interactive1',
        },
      },
      error: {
        true: {
          borderColor: '$error1',
        },
      },
      placedSide: {},
      rounded: {},
    },
    compoundVariants: [
      {
        isOpen: true,
        placedSide: 'bottom',
        css: {
          borderRadius: '0px 0px 4px 4px',
          borderTop: 'none',
        },
      },
      {
        isOpen: true,
        rounded: true,
        placedSide: 'bottom',
        css: {
          borderRadius: '0px 0px 1rem 1rem',
        },
      },
      {
        isOpen: true,
        placedSide: 'top',
        css: {
          borderRadius: '4px 4px 0px 0px',
          borderBottom: 'none',
        },
      },
      {
        isOpen: true,
        rounded: true,
        placedSide: 'top',
        css: {
          borderRadius: '1rem 1rem 0px 0px',
        },
      },
      {
        isOpen: true,
        error: true,
        css: {
          borderColor: '$error1',
        },
      },
    ],
  },
  dynamic: ({ cssProps }) => {
    const { height } = cssProps;
    return { top: convertToInt(height) };
  },
});

const NoOptions = styled('li', {
  margin: '$xs',
  padding: '$xs $sm',
  textAlign: 'center',
  color: '$gray5',
});

const Section = styled('li', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'default',
  padding: '$sm',
  fontSize: '$sm',
  fontFamily: '$primary',
  fontWeight: '$bold',
  scrollMargin: '$sm',
  color: '#323334',
});

export const OptionList = ({
  height,
  items,
  isOpen,
  value,
  getItemProps,
  menuProps,
  uniqueStorageId,
  showLocalStorage,
  valueKey,
  labelKey,
  activeIndex,
  error,
  customRender,
  placedSide,
  isLoading,
  customLoader,
  customNoOptionsMessage,
  searchTerm,
  enterClick,
  ...props
}) => {
  const abyssProps = useAbyssProps(props);
  const { visuallyHiddenProps } = useVisuallyHidden();
  let noOptionMessage;
  let searchItems;

  const getSearchItemsList = () => {
      searchItems = items.map((item, index) => {
       const currentItem = item?.item ? item.item : item;

       if (currentItem?.section) {
         return (
           <Section
             // eslint-disable-next-line react/no-array-index-key
             key={`${currentItem.section}_${index}`}
             {...getItemProps({ item, index, disabled: true })}
             aria-hidden="true"
             {...abyssProps('search-input-section')}
           >
             {customRender ? customRender(currentItem) : currentItem.section}
           </Section>
         );
       }

       const isFocused = activeIndex === index;

       const optionProps = {
         isFocused,
         currentItem,
         index,
         ...props,
       };

       return (
        currentItem?
         <Option
           key={`${currentItem[valueKey]}_${index}`}
           customRender={customRender}
           item={item}
           itemProps={getItemProps({ item, index })}
           labelKey={labelKey}
           // eslint-disable-next-line react/no-array-index-key
           showLocalStorage={showLocalStorage}
           uniqueStorageId={uniqueStorageId}
           {...optionProps}
         /> : ''
       );
     });
    }

  const renderItems = () => {
    if (isLoading) {
      return (
        <NoOptions {...abyssProps('search-input-loading-container')}>
          {customLoader || (
            <Layout.Stack alignItems="left" grow space={11}>
              <Skeleton height={12} />
              <Skeleton height={12} />
              <Skeleton height={12} />
            </Layout.Stack>
          )}
        </NoOptions>
      );
    }

    const searchTermCheck = /[A-Za-z0-9]/.test(searchTerm);
    if (!items.length || (enterClick && searchTerm.length<=1) ||!searchTermCheck && searchTerm!=='') {
      noOptionMessage = (<NoOptions {...abyssProps('search-input-no-options')}>
          {customNoOptionsMessage}
        </NoOptions>)
    }

    getSearchItemsList();

    return [noOptionMessage,(!enterClick && searchTerm.length<=1)|| searchTerm.length > 1?searchItems:''];
  };

  return (
    <React.Fragment>
      <List
        {...abyssProps('search-input-option-list')}
        {...menuProps}
        cssProps={{ height }}
        error={error}
        isOpen={isOpen}
        placedSide={placedSide}
        role="listbox"
      >
        {isOpen && renderItems()}
      </List>
      <span {...visuallyHiddenProps} aria-atomic="true" role="status">
        {isOpen && items.length && `${items.length} results found`}
      </span>
    </React.Fragment>
  );
};

OptionList.propTypes = {
  model: PropTypes.string,
  listId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  searchProps: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  error: PropTypes.bool,
  isSearchable: PropTypes.bool,
  labelId: PropTypes.string,
  placedSide: PropTypes.string,
  onSelection: PropTypes.func,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  getItemProps: PropTypes.func.isRequired,
  menuProps: PropTypes.shape({}).isRequired,
  customRender: PropTypes.func,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  uniqueStorageId: PropTypes.string,
  showLocalStorage: PropTypes.bool,
  isLoading: PropTypes.bool,
  customLoader: PropTypes.node,
  customNoOptionsMessage: PropTypes.string,
  searchTerm: PropTypes.string,
  enterClick: PropTypes.bool,
};

OptionList.defaultProps = {
  model: null,
  showLocalStorage: false,
  listId: null,
  height: null,
  items: null,
  value: null,
  isOpen: false,
  error: false,
  isSearchable: false,
  uniqueStorageId: null,
  searchProps: {},
  labelId: null,
  placedSide: 'bottom',
  onSelection: null,
  activeIndex: null,
  setActiveIndex: null,
  customRender: null,
  isLoading: false,
  customLoader: null,
  customNoOptionsMessage: 'No Options',
  searchTerm: null,
  enterClick: false,
};
