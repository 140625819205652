import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeFuncErrorEvent } from '../../common/AdobeTagging/adobeFuncErrorEvent';
import { PSXHeader } from '../../common/PSXHeader';
import { StillNeedHelp } from '../../common/StillNeedHelp';
import { PageContainer, PageContent } from './Page4XX.styled';

export const WHISTLE_PNG = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/whistle.png`;

export const Page4XX = () => {
  const { t } = useTranslation();
  const breadcrumbs = [{ title: t('ERROR_PAGE.4xx.title'), href: '' }];

  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { message, code, source, uiSection } = tokenData;

  useEffect(() => {
    adobeFuncErrorEvent({
      message,
      source,
      code: code ?? '4xx',
      uiSection,
    });
  }, []);

  return (
    <React.Fragment>
      <PSXHeader
        breadcrumbs={breadcrumbs}
        dataTestId="error-4xx-search-form"
        showChips={false}
        showChoosePCPHeader={false}
        showMemberSelection={false}
      />
      <PageContainer>
        <img alt="whistle" src={WHISTLE_PNG} />
        <PageContent>
          <Text
            color="$interactive4"
            css={{
              'abyss-text-root': {
                marginTop: '46px',
                marginBottom: '4px',
                '@screen < $sm': {
                  marginBottom: '29px',
                  marginTop: 'unset',
                },
                display: 'block',
              },
            }}
            fontWeight="$bold"
            size="$xs"
          >
            {t('ERROR_PAGE.4xx.type')}
          </Text>
          <Heading
            css={{
              marginBottom: '4px',
              '@screen < $sm': {
                marginBottom: '16px',
              },
            }}
          >
            {t('ERROR_PAGE.4xx.heading')}
          </Heading>
          <Heading
            css={{
              marginBottom: '12px',
              '@screen < $sm': {
                marginBottom: '20px',
                maxWidth: '181px',
              },
            }}
            offset={5}
          >
            {t('ERROR_PAGE.4xx.subHeading')}
          </Heading>
          <Text size="$sm">{t('ERROR_PAGE.4xx.body1')}</Text>
        </PageContent>
      </PageContainer>

      <StillNeedHelp />
    </React.Fragment>
  );
};
