import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Box } from '@abyss/web/ui/Box';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';

import { CardHeader } from '../CardHeader/CardHeader';
import { ContentWrapper } from '../ContentWrapper';
import { LinkContainer } from '../LinkContainer/LinkContainer';

export type NullPageProps = {
  searchTitle: string;
};

export const NoResult = ({ searchTitle }: NullPageProps) => {
  const { navigate } = useRouter();
  const handleOnClick = () => {
    const updatedToken = tokenizer.create({
      openRecentActivity: true,
    });
    navigate(`/${updatedToken}`);
  };
  return (
    <Box
      color="$white"
      css={{
        'abyss-box-root': {
          height: 'auto',
          minHeight: '189px',
        },
      }}
      data-auto-testid="null-page"
      data-testid="null-page"
    >
      <ContentWrapper>
        <CardHeader
          css={{
            'abyss-heading-root': {
              '@screen < $sm': {
                fontSize: '22.78px !important',
              },
            },
          }}
          data-auto-testid="browse-common-specialities-heading"
          data-testid="browse-common-specialities-heading"
          offset={2}
        >
          {t('NULL_PAGE.NULL_RESULT_HEADING')} {`“${searchTitle}”`}
        </CardHeader>
        <Layout.Stack alignItems="left">
          <ul
            style={{
              listStyle: 'disc',
              paddingLeft: '20px',
              fontWeight: '600',
            }}
          >
            <li>
              <Text size="$sm"> {t('NULL_PAGE.NULL_RESULT_SUGGESTION_1')}</Text>
            </li>
            <li>
              <Text size="$sm">{t('NULL_PAGE.NULL_RESULT_SUGGESTION_2')}</Text>
            </li>
            <li>
              <Text size="$sm">
                {t('NULL_PAGE.NO_SEARCH_DATA_LIST_VIEW')}{' '}
                <LinkContainer
                  data-auto-testid="null-page-view-history"
                  data-testid="null-page-view-history"
                  iconSize="$sm"
                  onClick={() => handleOnClick()}
                  size="$sm"
                  text={t('NULL_PAGE.NULL_RESULT_SUGGESTION_3')}
                />
              </Text>
            </li>
            <li>
              <Text size="$sm"> {t('NULL_PAGE.NULL_RESULT_SUGGESTION_4')}</Text>
            </li>
          </ul>
        </Layout.Stack>
      </ContentWrapper>
    </Box>
  );
};
