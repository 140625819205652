/* eslint-disable no-nested-ternary */
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getStatusText } from '../../../../ShareDrawer/utils';
import {
  TextRoot,
  italicFontStyle,
} from '../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../utility/compareDrawerConstants';

type Props = {
  acceptingNewPatients: boolean;
};

export const AcceptingNewPatients = ({ acceptingNewPatients }: Props) => {
  const { t } = useTranslation();
  const acceptingNewPatientsText = getStatusText(
    acceptingNewPatients,
    ATTRIBUTE_STATUS_TEXT.ACCEPTING_NEW_PATIENTS.TRUE,
    ATTRIBUTE_STATUS_TEXT.ACCEPTING_NEW_PATIENTS.FALSE,
    NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS
  );

  return (
    <React.Fragment>
      <Layout.Group>
        {acceptingNewPatients ? (
          <IconMaterial color="$primary1" icon="check" size={20} />
        ) : acceptingNewPatients === false ? (
          <IconMaterial color="$primary1" icon="close" size={20} />
        ) : (
          <IconMaterial color="$primary1" icon="do_not_disturb" size={20} />
        )}
        <TextRoot
          css={
            acceptingNewPatientsText ===
              NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS && italicFontStyle
          }
          data-auto-testid="accepting-new-patients-text"
          data-testid="accepting-new-patients-text"
        >
          {t(acceptingNewPatientsText)}
        </TextRoot>
      </Layout.Group>
    </React.Fragment>
  );
};
