import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeFuncErrorEvent } from '../../common/AdobeTagging/adobeFuncErrorEvent';
import { PSXHeader } from '../../common/PSXHeader';
import { Icon } from './Icon';
import { IconContainer, PageContainer, PageContent } from './Page408.style';

export const Page408 = () => {
  const { t } = useTranslation();
  const refreshPage = () => {
    window.location.reload();
  };
  const breadcrumbs = [{ title: t('ERROR_PAGE.408.title'), href: '' }];

  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { message, code, source, uiSection } = tokenData;

  useEffect(() => {
    adobeFuncErrorEvent({
      message,
      source,
      code: code ?? '408',
      uiSection,
    });
  }, []);

  return (
    <Fragment>
      <PSXHeader
        breadcrumbs={breadcrumbs}
        dataTestId="error-408-search-form"
        showChips={false}
        showChoosePCPHeader={false}
        showDemoHeader={false}
        showMemberSelection={false}
      />
      <PageContainer>
        <IconContainer>
          <Icon />
        </IconContainer>
        <PageContent>
          <Text
            color="$interactive4"
            css={{
              'abyss-text-root': {
                marginTop: '40px',
                marginBottom: '4px',
                '@screen < $sm': {
                  marginBottom: '29px',
                  marginTop: 'unset',
                },
              },
            }}
            fontWeight="$bold"
            size="$xs"
          >
            {t('ERROR_PAGE.408.type')}
          </Text>
          <Heading
            css={{
              marginBottom: '4px',
              '@screen < $sm': {
                marginBottom: '16px',
              },
            }}
          >
            {t('ERROR_PAGE.408.heading')}
          </Heading>
          <Heading
            css={{
              marginBottom: '12px',
              '@screen < $sm': {
                marginBottom: '19px',
              },
            }}
            offset={5}
          >
            {t('ERROR_PAGE.408.subHeading')}
          </Heading>
          <Text
            css={{
              'abyss-text-root': {
                marginBottom: '30px',
                '@screen < $sm': {
                  marginBottom: '9px',
                  maxWidth: '190px',
                },
              },
            }}
            size="$sm"
          >
            {t('ERROR_PAGE.408.body1')}
          </Text>
          <Layout.Group
            css={{
              cursor: 'pointer',
            }}
            data-auto-testid="refresh"
            data-testid="refresh"
            onClick={refreshPage}
            role="button"
          >
            <Text color="$interactive2" fontWeight="$bold" size="$sm">
              {t('ERROR_PAGE.408.Refresh')}
            </Text>
            <IconMaterial icon="refresh" size="12px" />
          </Layout.Group>
        </PageContent>
      </PageContainer>
      {/* TODO: Replace below code with Still need help section when it's ready */}
      <div
        style={{
          height: '152px',
          background: '#E5E5E6',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Placeholder for "Still need help" section
      </div>
    </Fragment>
  );
};
