import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../Constants';
import { ConstantsRoutes } from '../../ConstantsRoutes';
import { phoneOnly } from '../../ConstantsStyles';
import { ContentWrapper } from '../../ContentWrapper';
import {
  ProviderIconImg,
  inOfficeText,
  inOfficeTextMobile,
  specialtyText,
  specialtyTextMobile,
  visionCardContainer,
  visionCardContainerMobile,
  visionCardIcon,
  visionCardIconMobile,
  visionCardLink,
  visionCardLinkMobile,
  visionCardSubContainer,
  visionCardSubContainerMobile,
} from './RecentActivityVision.styled';

const providerDetails = {
  showDirection: false,
  sectionType: 'Practitioner',
  imageLocation:
    'https://findcare-dev.uhc.com/cdn/assets/images/default_Practitioner.jpg',
  locationId: '001956461-954526112-T-37A3195763F9953098EA396946687F45',
  providerName: 'Hoang Thao Y',
  providerId: '001956461',
  chipValue: '',
};

export const RecentActivityVision = () => {
  const { t } = useTranslation();
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const handleOnClick = () => {
    const updatedToken = tokenizer.update(token, {
      providerId: '001956461',
      sectionType: 'Practitioner',
      imageLocation:
        'https://findcare-dev.uhc.com/cdn/assets/images/default_Practitioner.jpg',
      locationId: '001956461-954526112-T-37A3195763F9953098EA396946687F45',
      showDirection: false,
      chipValue: '',
    });

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      '001956461'
    );

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_FACILITY_LOCATION_ID,
      '001956461-954526112-T-37A3195763F9953098EA396946687F45'
    );

    navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${updatedToken}`);
  };
  const mobileScreen = useMediaQuery(phoneOnly);

  return (
    <React.Fragment>
      <ContentWrapper>
        <Flex direction="column">
          <Heading display={mobileScreen ? 'h6' : 'h4'} offset={1}>
            {t('RECENT_VISIT_VISION.HEADING')}
          </Heading>
          <Flex
            css={mobileScreen ? visionCardContainerMobile : visionCardContainer}
          >
            <Layout.Group
              alignLayout="center"
              css={
                mobileScreen
                  ? visionCardSubContainerMobile
                  : visionCardSubContainer
              }
            >
              <Flex
                css={{
                  'abyss-flex-root': {
                    width: '60px',
                  },
                }}
              >
                <ProviderIconImg
                  src={providerDetails.imageLocation}
                  title="github"
                />
              </Flex>
              <Flex direction="column">
                <Flex
                  css={mobileScreen ? visionCardLinkMobile : visionCardLink}
                  data-auto-testid="recent-activty-vision-provder"
                  data-testid="recent-activty-vision-provder"
                  fontWeight="$bold"
                  onClick={handleOnClick}
                  variant="ghost"
                >
                  {providerDetails.providerName}
                  <IconMaterial
                    css={mobileScreen ? visionCardIconMobile : visionCardIcon}
                    icon="chevron_right"
                  />
                </Flex>
                <Text
                  color={mobileScreen ? '#19191A' : '#4B4D4F'}
                  css={mobileScreen ? specialtyTextMobile : specialtyText}
                >
                  {t('RECENT_VISIT_VISION.SPECIALTY')}
                </Text>

                <Text
                  color="#4B4D4F"
                  css={mobileScreen ? inOfficeTextMobile : inOfficeText}
                  size="sm"
                  weight="$md"
                >
                  {t('RECENT_VISIT_VISION.IN_OFFICE')}
                </Text>
              </Flex>
            </Layout.Group>
          </Flex>
        </Flex>
      </ContentWrapper>
    </React.Fragment>
  );
};
