import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useTranslation } from 'react-i18next';

import { NavigationButton } from './NavigationButton';
import {
  DesktopVerticalDivider,
  MobileVerticalDivider,
  NavCloseButton,
  NavCloseButtonContainer,
  NavigationButtonContainer,
  NavigationButtonGroup,
} from './NavigationButton.styled';

type Props = {
  handleCloseNav(): void;
  navType: string;
  setNavType(navType: string): void;
  locationForAnalytics?: string;
};

export const NavigationButtonsRow = ({
  handleCloseNav,
  navType,
  setNavType,
  locationForAnalytics,
}: Props) => {
  const { t } = useTranslation();

  return (
    <NavigationButtonContainer alignLayout="center" space="0">
      <NavigationButtonGroup>
        <NavigationButton
          buttonNavType="driving-traffic"
          curNavType={navType}
          label={t('LOCATION_DIALOG_BOX.DISTANCE_TRAFFIC')}
          locationForAnalytics={locationForAnalytics}
          setNavType={setNavType}
        />
        <MobileVerticalDivider />
        <NavigationButton
          buttonNavType="driving"
          curNavType={navType}
          label={t('LOCATION_DIALOG_BOX.DRIVING_DISTANCE')}
          locationForAnalytics={locationForAnalytics}
          setNavType={setNavType}
        />
        <MobileVerticalDivider />
        <NavigationButton
          buttonNavType="walking"
          curNavType={navType}
          label={t('LOCATION_DIALOG_BOX.WALKING_DISTANCE')}
          locationForAnalytics={locationForAnalytics}
          setNavType={setNavType}
        />
        <MobileVerticalDivider />
        <NavigationButton
          buttonNavType="cycling"
          curNavType={navType}
          label={t('LOCATION_DIALOG_BOX.CYCLING_DISTANCE')}
          locationForAnalytics={locationForAnalytics}
          setNavType={setNavType}
        />
      </NavigationButtonGroup>
      <NavCloseButtonContainer>
        <DesktopVerticalDivider />
        <NavCloseButton
          aria-label={t('LOCATION_DIALOG_BOX.CLOSE_DIALOG')}
          data-auto-testid="navigation-close"
          data-testid="navigation-close"
          onClick={handleCloseNav}
        >
          <IconMaterial color="$black" css={{ margin: 'auto' }} icon="close" />
        </NavCloseButton>
      </NavCloseButtonContainer>
    </NavigationButtonContainer>
  );
};
