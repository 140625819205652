import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../../common/Constants';
import { hideScrollbar } from '../../../../../common/ConstantsStyles';
import { MapDisplay } from '../../../../../common/MapDisplay';
import { getGeoLocationFromStorage } from '../../../../../common/PSXHeader/SearchBar/utils';
import { ProviderLocation } from '../../../../../models/ProviderDetails';
import { setZoomLevel } from '../../../../../utils/map.utils';
import { SidePaneCloseButton } from '../ChoosePCPLocation/ChoosePCPLocation.styled';
import {
  SuccessMapViewWrap,
  SuccessPageWrapColumn,
} from './ChoosePCPSuccess.styled';

type Props = {
  providerLocations?: ProviderLocation[];
  successPageDetails?: React.ReactNode;
};
export const SuccesssMapViewWrap = ({
  providerLocations = [],
  successPageDetails,
}: Props) => {
  const [closeSidePanel, setCloseSidePanel] = useState<boolean>(false);
  const map = React.useRef<mapboxgl.Map | null>(null);
  const { longitude, latitude } = getGeoLocationFromStorage();

  const [mapPinCoords] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_COORDS,
    []
  );

  useEffect(() => {
    map.current?.resize();
    if (!closeSidePanel) {
      setZoomLevel(map.current, mapPinCoords);
      // map?.current?.flyTo({
      //   center: [+longitude, +latitude],
      // });
    }
  }, [closeSidePanel]);

  return (
    <React.Fragment>
      <SuccessPageWrapColumn css={hideScrollbar}>
        {!closeSidePanel && (
          <Layout.Stack alignItems="left" grow space={12}>
            {successPageDetails}
          </Layout.Stack>
        )}
        <SidePaneCloseButton
          alignItems="center"
          className="side-btn"
          justify="center"
        >
          <IconMaterial
            css={{
              fill: '$black',
            }}
            data-auto-testid="sidepanel-close-icon"
            data-testid="sidepanel-close-icon"
            icon={
              closeSidePanel ? 'keyboard_arrow_right' : 'keyboard_arrow_left'
            }
            onClick={() => {
              adobeLinkTrackEvent({
                name: `sidepanel:${closeSidePanel ? 'open' : 'close'}`,
                location: 'body:map',
              });
              setCloseSidePanel(!closeSidePanel);
            }}
            size="$md"
          />
        </SidePaneCloseButton>
      </SuccessPageWrapColumn>
      <SuccessMapViewWrap cssProps={{ closeSidePanel }}>
        {providerLocations?.length ? (
          <MapDisplay
            coords={{ longitude, latitude }}
            disabledPinAction
            locationResults={providerLocations}
            map={map}
          />
        ) : null}
      </SuccessMapViewWrap>
    </React.Fragment>
  );
};
