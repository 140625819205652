import { config } from '@abyss/web/tools/config';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { DataCard } from '../../../../common/DataCard';
import { DataCardStyle } from '../../../../common/ResultSection/ResultSection.style';
import { useLagoon } from '../../../../hooks/useLagoon';
import { Provider } from '../../../../models/Provider';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { PopupStyle } from './MapView.styled';

type Props = {
  headers: ResponseHeaders;
  practitioner?: Provider;
  disableCost?: boolean;
  locationForAnalytics?: string;
  pageNumber?: number;
  selectedFilters?: string;
  selectLocation?: (locationId, locationLng, locationLat) => void;
};
export const Popup = ({
  headers,
  practitioner,
  disableCost,
  locationForAnalytics,
  pageNumber,
  selectedFilters,
  selectLocation,
}: Props) => {
  const [popupContainer, setPopupContainer] = useState<any>(null);
  const lagoonData = useLagoon('ui-messaging')();
  const nationaAncillaryNoLocation = find(lagoonData, {
    key: ConstantsLagoon.NATIONAL_ANCILLARY_NO_LOCATION_MODAL,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (practitioner) {
      const rootElem = document.querySelector('.datacard-popup');
      if (rootElem) {
        (rootElem as HTMLElement).style.height = 'fit-content';
        (rootElem as HTMLElement).style.width = 'fit-content';
        (rootElem as HTMLElement).style.visibility = 'visible';
      }
      setPopupContainer(rootElem);
      document.querySelectorAll('.mapboxgl-popup-tip').forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        (el as HTMLElement).style.display = 'block';
        if (!practitioner?.address?.line[0]) {
          // eslint-disable-next-line no-param-reassign
          (el as HTMLElement).style.paddingTop = '10px';
        }
      });
    }
  }, [practitioner]);

  const handleDirectionClick = (provider) => {
    if (selectLocation)
      selectLocation(
        provider.locationId,
        provider.longitude,
        provider.latitude
      );
  };

  const showPopup = () =>
    practitioner?.address?.line[0] ? (
      <DataCard
        css={DataCardStyle}
        disableCost={disableCost}
        handleDirectionClick={() => handleDirectionClick(practitioner)}
        headers={headers}
        locationForAnalytics={locationForAnalytics}
        pageNumber={pageNumber}
        practitioner={practitioner || {}}
        selectedFilters={selectedFilters}
      />
    ) : (
      <Flex
        css={{
          display: 'flex',
          width: '623px',
          padding: '32px 24px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
          borderRadius: '10.08px',
          background: 'rgba(255, 255, 255, 0.80)',
          '@screen < $md': {
            width: '311px',
            padding: '40px 32px',
            borderRadius: '12px',
          },
        }}
      >
        <img
          alt="national ancillary facility"
          src={`${config(
            'CDN_BASE_URL'
          )}/cdn/assets/images/national_ancillary_facility.png`}
        />
        <Text
          css={{
            'abyss-text-root': {
              alignSelf: 'stretch',
              fontSize: '18px',
              fontWeight: '$bold',
              lineHeight: '24px',
              color: '#000',
            },
          }}
        >
          {t('Find providers near you')}
        </Text>
        <Text
          css={{
            'abyss-text-root': {
              alignSelf: 'stretch',
              fontSize: '18px',
              fontWeight: '$medium',
              lineHeight: '24px',
              color: '#000',
            },
          }}
        >
          {nationaAncillaryNoLocation?.message}
        </Text>
      </Flex>
    );
  return (
    <React.Fragment>
      {popupContainer !== null &&
        createPortal(<PopupStyle>{showPopup()}</PopupStyle>, popupContainer)}
    </React.Fragment>
  );
};
