import sortBy from 'lodash/sortBy';

import translationsEnJson from '../../public/locale/en/translation.json';
import {
  AUTO_INCREMENT_SEARCH_RADIUS,
  DEFAULT_SEARCH_RADIUS,
  FilterCollectionModel,
  FilterKeysAndParamsMapping,
} from '../common/Constants';
import {
  FiltersData,
  LastModifiedFilter,
  Option,
  ProviderFiltersType,
  ProviderSearchFilters,
} from '../models/ProviderSearch';

export const getValuesFromOptionsArray = (filter: Option[] = []) => {
  const values: any[] = [];
  filter?.forEach((option: Option) => {
    values.push(option.value);
  });
  return values;
};

const getArrayTypeFilterValues = (
  params: ProviderSearchFilters,
  pickFirstValue = false
) => {
  const filterParams = {};
  Object.keys(params).forEach((key) => {
    if (params[key]?.length) {
      const options = getValuesFromOptionsArray(params[key]);
      filterParams[FilterKeysAndParamsMapping[key]] = pickFirstValue
        ? options[0]
        : options;
    }
  });
  return filterParams;
};

const getObjectTypeFilterValues = (params: ProviderSearchFilters) => {
  const filterParams = {};
  Object.keys(params).forEach((key) => {
    if (params[key]?.value)
      filterParams[FilterKeysAndParamsMapping[key]] = params[key].value;
  });
  return filterParams;
};

export const parseProviderSearchFilters = (
  selectedFilters: ProviderSearchFilters
) => {
  const {
    ExpressAccessProviderIndicator,
    TieredProvider,
    PremiumCarePhysician,
    PlatinumProviderIndicator,
    VirtualCare,
    Distance,
    FacilityType,
    SpecialtyCode,
    AreaOfExpertise,
    PatientReviews,
    HealthSuppliesOffered,
    LanguageCode,
    Medical,
    HospitalAffiliations,
    AcceptingNewPatients,
    EveningAndWeekend,
    AccessibilityCode,
    GenderCode,
    PatientSafetyInfoAvailable,
    SortBy,
    EthnicityCode,
    BehavioralProgramIndicator,
  } = selectedFilters;

  const objectTypeFilterValues = getObjectTypeFilterValues({
    ExpressAccessProviderIndicator,
    TieredProvider,
    PremiumCarePhysician,
    PlatinumProviderIndicator,
    VirtualCare,
    SortBy,
  });

  const arrayTypeFilterValues = getArrayTypeFilterValues({
    FacilityType,
    SpecialtyCode,
    AreaOfExpertise,
    HealthSuppliesOffered,
    LanguageCode,
    AccessibilityCode,
    Medical,
    HospitalAffiliations,
    EthnicityCode,
    BehavioralProgramIndicator,
  });

  const arrayTypeFilterValuesFromFirstIndex = getArrayTypeFilterValues(
    {
      PatientReviews,
      GenderCode,
      Distance,
    },
    true
  );

  return {
    ...objectTypeFilterValues,
    ...arrayTypeFilterValues,
    ...arrayTypeFilterValuesFromFirstIndex,
    ...(AcceptingNewPatients?.length && {
      acceptingNewPatients:
        getValuesFromOptionsArray(AcceptingNewPatients).includes('Y'),
    }),
    ...(EveningAndWeekend?.length &&
      getValuesFromOptionsArray(EveningAndWeekend).includes('E') && {
        eveningAvailability: true,
      }),
    ...(EveningAndWeekend?.length &&
      getValuesFromOptionsArray(EveningAndWeekend).includes('W') && {
        weekendAvailability: true,
      }),
    ...(PatientSafetyInfoAvailable?.length &&
      getValuesFromOptionsArray(PatientSafetyInfoAvailable).includes('Y') && {
        patientSafetyInfoAvailable: true,
      }),
  };
};

const getEveningAndWeekendFilter = (
  { EveningAvailability, WeekendAvailability }: FiltersData,
  translate
) => {
  const eveningAvailability = getValuesFromOptionsArray(
    EveningAvailability?.options
  ).includes('Y')
    ? [
        {
          label: translate('FILTER_OPTIONS.EveningAvailability.Y'),
          value: 'E',
          dnt_label: translationsEnJson.FILTER_OPTIONS.EveningAvailability.Y,
        },
      ]
    : [];

  const weekendAvailability = getValuesFromOptionsArray(
    WeekendAvailability?.options
  ).includes('Y')
    ? [
        {
          label: translate('FILTER_OPTIONS.WeekendAvailability.Y'),
          value: 'W',
          dnt_label: translationsEnJson.FILTER_OPTIONS.WeekendAvailability.Y,
        },
      ]
    : [];

  return [...eveningAvailability, ...weekendAvailability];
};

export const transformFiltersData = (
  translate,
  filtersCollection: ProviderFiltersType[],
  filtersData: FiltersData = {},
  lastModifiedFilter: LastModifiedFilter = {}
) => {
  const readLabelFromTranslations: string[] = [
    FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
    FilterCollectionModel.GENDER_CODE,
  ];
  const readOptionsFromFilterCollection: string[] = [
    FilterCollectionModel.DISTANCE,
    FilterCollectionModel.PATIENT_REVIEWS,
  ];
  const filters: ProviderFiltersType[] = [];

  filtersCollection.forEach((filter) => {
    let filterOptions: Option[] = [];

    if (filter.model === lastModifiedFilter?.model) {
      filterOptions.push(...(lastModifiedFilter?.options || []));
    } else if (filter.model === FilterCollectionModel.EVENING_AND_WEEKEND) {
      const options = getEveningAndWeekendFilter(filtersData, translate);
      filterOptions.push(...options);
    } else if (readOptionsFromFilterCollection.includes(filter.model)) {
      filterOptions.push(...filter.options);
    } else {
      filtersData[filter.model]?.options?.forEach((option) => {
        let label = '';
        if (readLabelFromTranslations.includes(filter.model))
          label = translate(`FILTER_OPTIONS.${filter.model}.${option.value}`);
        else label = option.label;

        if (label)
          filterOptions.push({
            label,
            value: option.value,
            dnt_label: option.dnt_label,
          });
      });
    }
    if (filter?.type !== 'radio') {
      filterOptions = sortBy(filterOptions, 'label');
    }

    if (filterOptions.length)
      filters.push({ ...filter, options: [...filterOptions] });
  });

  return filters;
};

export const getLagoonConfigValue = (allConfigs, key) => {
  const config = allConfigs(key);
  return config ? config.value : '';
};

export const getSearchRadius = (searchRadius) =>
  searchRadius ? +searchRadius : DEFAULT_SEARCH_RADIUS;

export const getAutoIncrementRadius = (autoIncrementRadius) =>
  autoIncrementRadius
    ? autoIncrementRadius.split(',')
    : AUTO_INCREMENT_SEARCH_RADIUS;
