import { Tooltip } from '@abyss/web/ui/Tooltip';
import isEmpty from 'lodash/isEmpty';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../hooks/useIsOverflow/useIsOverflow';
import { Address } from '../../../../../../../../models/ProviderDetails';
import {
  AddressDiv,
  italicFontStyle,
} from '../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../utility/compareDrawerConstants';

type Props = {
  address: Address;
};

export const AddressText = ({ address }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);
  const { t } = useTranslation();

  const isAttributePresent = !isEmpty(address) && address.line[0] !== '';

  const formatAddress = (add) => `${add.line[0]},
    ${add.city},
    ${add.state}`;

  const displayedAddressText = isAttributePresent
    ? formatAddress(address)
    : t(NULL_ATTRIBUTE_STATE.ADDRESS);

  const addressAttribute = () => (
    <AddressDiv
      ref={containerRef}
      css={!isAttributePresent && italicFontStyle}
      cssProps={{ isOverflow }}
      data-auto-testid="provider-address"
      data-testid="provider-address"
    >
      {displayedAddressText}
    </AddressDiv>
  );

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip
          content={displayedAddressText}
          position="top"
          positionOffset={8}
        >
          {addressAttribute()}
        </Tooltip>
      ) : (
        addressAttribute()
      )}
    </React.Fragment>
  );
};
