import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { HealthgradesProviderReview } from '../../../../models/Healthgrades';
import { Rating } from '../../../SnackCard/Rating';
import { RatingSectionHeader } from './PatientReviews.styled';

type Props = {
  healthgradesReview: HealthgradesProviderReview;
};

export const ProviderAndOfficeAndStaffExperienceSectionDesktop = ({
  healthgradesReview,
}: Props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {healthgradesReview.providerExperienceRating ? (
        <Flex
          css={{ display: 'flex', width: '192px', gap: '8px' }}
          direction="column"
          justify="center"
        >
          <RatingSectionHeader>
            {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.PROVIDER_EXPERIENCE')}
          </RatingSectionHeader>
          <Rating
            hideNumber
            outOf={5}
            rating={healthgradesReview.providerExperienceRating}
            size={16.67}
          />
        </Flex>
      ) : null}
      {healthgradesReview.officeAndStaffExperienceRating ? (
        <Flex
          css={{ display: 'flex', width: '192px', gap: '8px' }}
          direction="column"
          justify="center"
        >
          <RatingSectionHeader>
            {t(
              'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.OFFICE_AND_STAFF_EXPERIENCE'
            )}
          </RatingSectionHeader>
          <Rating
            hideNumber
            outOf={5}
            rating={healthgradesReview.officeAndStaffExperienceRating}
            size={16.67}
          />
        </Flex>
      ) : null}
    </React.Fragment>
  );
};
