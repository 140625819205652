export const adobeTags = {
  LINK_TRACK_EVENT: (data) => ({
    event: 'link track',
    data
  }),
  PAGE_TRACK_EVENT: (data) => ({
    event: 'page track',
    data
  }),
  STANDARD_SEARCH_EVENT: (data) => ({
    event: 'standard search',
    data
  }),
  SEARCH_RESULT_CLICK_EVENT: (data) =>({
    event:'search result click',
    data
  }),
  SEARCH_FILTER_EVENT: (data) =>({
    event:'search filter',
    data
  }),
  MODAL_TRACK_EVENT: (data) =>({
    event: 'modal track',
    data
  }),
  IMPRESSION_TRACK_EVENT: (data) =>({
    event:'impression track',
    data
  }),
  FUNC_ERROR_EVENT: (data) =>({
    event: 'func error',
    data
  })
};
