import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React, { useContext, useEffect, useState } from 'react';

import { SearchFilterContext } from '../../context/SearchFilterContext';
import { Option } from '../../models/ProviderSearch';
import { Constants } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import { AllFilterDrawer } from './AllFilterDrawer';

type Props = {
  name: string;
  size: string;
  color?: string;
  icon?: string;
  variant?: string;
};

export const ResultFilter = ({
  name,
  size,
  color = '$primary1',
  icon = 'tune',
  variant = 'outline',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = useMediaQuery(phoneOnly);
  const { searchFilters } = useContext(SearchFilterContext);
  const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(0);

  const resultFilterDataTestId: string = 'result-filter';

  let properties: any = {
    buttonStyles: {
      backgroundColor: selectedFiltersCount > 0 ? '#CCF2F7' : '$primary2',
      border: '1px solid $gray4',
      padding: '$sm $lg $sm $md',
      height: '2.625em',
      fontWeight: '$semibold',
      borderRadius: '24px',
    },
    drawerSize: 492,
    drawerposition: 'right',
  };

  if (mobileScreen) {
    properties = {
      buttonStyles: {
        backgroundColor: selectedFiltersCount > 0 ? '$tint1' : '$primary2',
        boxShadow:
          selectedFiltersCount > 0 ? '0 2px rgba(25, 25, 26, 0.16)' : 'unset',
        border: 'solid 2px $primary1',
        '&:active, &:focus, &:hover': {
          backgroundColor: '#CCF2F7',
        },
      },
      drawerSize: window.innerHeight - 15,
      drawerPosition: 'bottom',
    };
  }

  useEffect(() => {
    const selectedOptions: {
      [key: string]: string;
    } = {};

    Object.keys(searchFilters).forEach((model) => {
      if (Array.isArray(searchFilters[model])) {
        searchFilters[model].forEach((option: Option) => {
          if (!option.hide) selectedOptions[option.label] = model;
        });
      } else {
        const option: Option = searchFilters[model];
        if (option.value && !option.hide) {
          selectedOptions[option.label] = model;
        }
      }
    });
    setSelectedFiltersCount(Object.keys(selectedOptions).length);
  }, [JSON.stringify(searchFilters)]);

  const handleOnClickResultFilter = () => {
    const linkName: string = 'all filters';
    const linkLocation: string = `body:${Constants.PROVIDER_RESULTS.FILTER_BY}`;
    const modalName: string = `${linkName} drawer`;
    handleLinkAndModalTrack(linkName, linkLocation, modalName);
    setIsOpen(true);
  };

  return (
    <React.Fragment>
      {isOpen ? (
        <AllFilterDrawer
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          position={properties.drawerPosition}
          size={properties.drawerSize}
        />
      ) : null}
      <Button
        css={{ 'abyss-button-root': { ...properties.buttonStyles } }}
        data-auto-testid={resultFilterDataTestId}
        data-testid={resultFilterDataTestId}
        onClick={handleOnClickResultFilter}
        size={size}
        variant={variant}
      >
        <Layout.Group>
          <IconMaterial color={color} icon={icon} />
          <Text color={color} fontWeight={mobileScreen && 600}>
            {name}
          </Text>
        </Layout.Group>
      </Button>
    </React.Fragment>
  );
};
