import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Badge } from '@abyss/web/ui/Badge';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import { Constants } from '../Constants';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { Name } from '../PSXHeader/ObapiDemo/memberProfile';
import {
  EditPCPPopover,
  EditPCPPopoverButton,
  EditPCPPopoverButtonText,
  EditPCPPopoverContainer,
  PCPDrawerMobile,
  PCPDrawerSection,
  YourPCPHeaderMobile,
  editPCPMenuStyle,
} from './PrimaryCareProvider.styled';

export type EditPCPMenuProps = {
  dependentSeqNbr?: string;
  name?: Name;
  providerID: string;
  providerType?: string;
  locationForAnalytics?: string;
  badge?: boolean;
};

export const EditPCPMenu = ({
  providerID,
  dependentSeqNbr,
  name,
  providerType,
  locationForAnalytics,
  badge,
}: EditPCPMenuProps) => {
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDesktopModal, setIsOpenDesktopModal] = useState(false);
  const mobileScreen = useMediaQuery('(max-width: 463px)');
  const changePCPButtonTestId: string = 'change-pcp-button';
  const changePCPLocationButtonTestId: string = 'change-pcp-location-button';
  const dependentPCPModalTestId: string = `yourPCP-${dependentSeqNbr}`;

  let tokenForChoosePCP;
  let tokenForPCPLocations;

  const handleChoosePCPLocations = () => {
    tokenForPCPLocations = tokenizer.update(token, {
      choosePCPId: providerID,
      dependentSeqNbr,
      selectedProviderType: providerType,
      originLinkNameForAnalytics: changePCPLocationButtonTestId,
    });

    navigate(
      `${ConstantsRoutes.CHOOSE_PCP_LOCATION.path}${tokenForPCPLocations}`
    );
  };

  const handleChoosePCP = () => {
    tokenForChoosePCP = tokenizer.update(token, {
      choosePCP: true,
      pcpIndicator: true,
      search: Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS,
      searchType: Constants.SEARCH_TYPES.SPECIALTY,
      resultType: Constants.RESULT_SECTION.ALL,
      dependentSeqNbr,
      acceptingNewPatients: true,
      originLinkNameForAnalytics: changePCPButtonTestId,
    });
    navigate(`/results/${tokenForChoosePCP}`);
  };

  const editIcon = (
    <IconMaterial
      css={{ marginLeft: '14px', width: '18px', height: '18px' }}
      icon="edit"
    />
  );

  const handleAdobeLinkTrackEvent = (
    linkTrackName: string,
    locationSection: string
  ) => {
    adobeLinkTrackEvent({
      destinationUrl: '',
      name: linkTrackName,
      location: `${locationSection}:${locationForAnalytics}`,
      type: 'internal',
    });
  };

  const handleLinkAndModalTrackEvents = (
    isOpenModalResponsive: boolean,
    isOpenModalDesktop: boolean
  ) => {
    const modalName = Constants.PRIMARY_CARE_PROVIDER.YOUR_PCP;
    if (isOpenModalResponsive || isOpenModalDesktop) {
      handleAdobeLinkTrackEvent(dependentPCPModalTestId, 'body');
      adobeModalTrackEvent(modalName);
    }
  };

  useEffect(() => {
    handleLinkAndModalTrackEvents(isOpen, isOpenDesktopModal);
  }, [isOpen, isOpenDesktopModal]);

  const popoverContent = (
    <React.Fragment>
      <Layout.Stack alignItems="left">
        <EditPCPPopoverButton
          data-auto-testid={changePCPButtonTestId}
          data-testid={changePCPButtonTestId}
          onClick={() => {
            handleAdobeLinkTrackEvent(changePCPButtonTestId, 'modal');
            handleChoosePCP();
          }}
          variant="tertiary"
        >
          <IconMaterial icon="edit" />
          <EditPCPPopoverButtonText color="$interactive1">
            {name
              ? `Change ${name.firstName}'s PCP`
              : t('PRIMARY_CARE_PROVIDER.CHANGE_YOUR_PCP')}
          </EditPCPPopoverButtonText>
        </EditPCPPopoverButton>
        <EditPCPPopoverButton
          data-auto-testid={changePCPLocationButtonTestId}
          data-testid={changePCPLocationButtonTestId}
          onClick={() => {
            handleAdobeLinkTrackEvent(changePCPLocationButtonTestId, 'modal');
            handleChoosePCPLocations();
          }}
          variant="tertiary"
        >
          <IconMaterial icon="edit" />
          <EditPCPPopoverButtonText color="$interactive1">
            {name
              ? `Change ${name.firstName}'s PCP location`
              : t('PRIMARY_CARE_PROVIDER.CHANGE_YOUR_PCP_LOCATION')}
          </EditPCPPopoverButtonText>
        </EditPCPPopoverButton>
      </Layout.Stack>
    </React.Fragment>
  );

  const PopoverIconForMobileScreen = () =>
    badge ? (
      <Badge css={editPCPMenuStyle}>
        <Flex css={{ flexWrap: 'nowrap' }}>
          <IconMaterial
            data-auto-testid="edit-pcp-detaile-header"
            data-testid="edit-pcp-detaile-header"
            icon="edit"
            onClick={() => setIsOpen(true)}
            size={16}
          />
          {t('PCP_BANNER.EDIT_PCP')}
        </Flex>
      </Badge>
    ) : (
      <IconMaterial
        data-auto-testid="edit-pcp"
        data-testid="edit-pcp"
        icon="more_vert"
        onClick={() => {
          setIsOpen(true);
        }}
      />
    );

  const PopoverIconForDesktopScreen = () =>
    badge ? (
      <Badge css={editPCPMenuStyle}>
        <Flex wrap="nowrap">
          <IconMaterial
            data-auto-testid="vertIcon-detaile-header"
            data-testid="vertIcon-detaile-header"
            icon="edit"
            size={16}
          />
          {t('PCP_BANNER.EDIT_PCP')}
        </Flex>
      </Badge>
    ) : (
      <IconMaterial
        data-auto-testid="vertIcon"
        data-testid="vertIcon"
        icon="more_vert"
      />
    );

  return (
    <EditPCPPopoverContainer>
      {mobileScreen ? (
        <React.Fragment>
          <PopoverIconForMobileScreen />
          <Drawer
            css={PCPDrawerMobile}
            data-auto-testid={dependentPCPModalTestId}
            data-testid={dependentPCPModalTestId}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            position="bottom"
            size={mobileScreen ? window.innerHeight - 104 : 464}
            title={
              <YourPCPHeaderMobile>
                {name
                  ? `${name.firstName}'s PCP`
                  : t('PRIMARY_CARE_PROVIDER.YOUR_PCP')}
              </YourPCPHeaderMobile>
            }
          >
            <Drawer.Section>
              <Layout.Stack alignItems="left">
                <LinkWithTracking
                  analyticsInfo={{
                    location: `modal:mobile ${locationForAnalytics}`,
                  }}
                  before={editIcon}
                  css={{ marginBottom: '35px' }}
                  data-auto-testid="changePCP"
                  data-testid="changePCP"
                  href={tokenForChoosePCP}
                  isStandardAnchor
                  onClick={handleChoosePCP}
                >
                  <PCPDrawerSection>
                    {name
                      ? `Change ${name.firstName}'s PCP`
                      : t('PRIMARY_CARE_PROVIDER.CHANGE_YOUR_PCP')}
                  </PCPDrawerSection>
                </LinkWithTracking>
                <LinkWithTracking
                  analyticsInfo={{
                    location: `modal:mobile ${locationForAnalytics}`,
                  }}
                  before={editIcon}
                  data-auto-testid="changePCPLocations"
                  data-testid="changePCPLocations"
                  href={tokenForPCPLocations}
                  isStandardAnchor
                  onClick={handleChoosePCPLocations}
                >
                  <PCPDrawerSection>
                    {name
                      ? `Change ${name.firstName}'s PCP location`
                      : t('PRIMARY_CARE_PROVIDER.CHANGE_YOUR_PCP_LOCATION')}
                  </PCPDrawerSection>
                </LinkWithTracking>
              </Layout.Stack>
            </Drawer.Section>
          </Drawer>
        </React.Fragment>
      ) : (
        <EditPCPPopover
          align="start"
          content={popoverContent}
          data-auto-testid={dependentPCPModalTestId}
          data-testid={dependentPCPModalTestId}
          onOpenChange={() => {
            setIsOpenDesktopModal(!isOpenDesktopModal);
          }}
          open={isOpenDesktopModal}
          position="bottom"
          showClose={false}
          title={
            name
              ? `${name.firstName}'s PCP`
              : t('PRIMARY_CARE_PROVIDER.YOUR_PCP')
          }
          width="263"
        >
          {PopoverIconForDesktopScreen()}
        </EditPCPPopover>
      )}
    </EditPCPPopoverContainer>
  );
};
