import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../hooks/useIsOverflow';
import { StyledOverflowAttributeDiv } from '../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../utility/compareDrawerConstants';

type Props = {
  accessProvider: boolean;
};

export const ExpressAccessProviderText = ({ accessProvider }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const expressAccessProvider = !accessProvider
    ? NULL_ATTRIBUTE_STATE.EXPRESS_ACCESS_PROVIDER
    : 'CONTENT_FILTERS.ALL_FILTERS_DRAWER.EXPRESS_ACCESS_PROVIDER';

  const isAttributeEmpty =
    expressAccessProvider === NULL_ATTRIBUTE_STATE.EXPRESS_ACCESS_PROVIDER;

  const ExpressAccessProviderAttribute = (
    <StyledOverflowAttributeDiv
      ref={containerRef}
      cssProps={{ isOverflow, isAttributeEmpty }}
    >
      {t(expressAccessProvider)}
    </StyledOverflowAttributeDiv>
  );

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip
          content={expressAccessProvider}
          position="top"
          positionOffset={8}
        >
          {ExpressAccessProviderAttribute}
        </Tooltip>
      ) : (
        ExpressAccessProviderAttribute
      )}
    </React.Fragment>
  );
};
