import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import {
  EAPCodeBoxContainer,
  EAPCodeNeeded,
  EAPCodeText,
  EAPGetMyCodeLink,
  EAPIcon,
  EAPPopoverStyling,
} from './EAPCode.styled';

export const EAPCode = () => {
  const { t } = useTranslation();
  const navigationURL = 'https://member.uhc.com/coverage/mental-health/eap/';
  const onClickGetMyLink = () => {
    adobeLinkTrackEvent({
      name: 'Get my code',
      location: 'body:eap code',
      type: 'external',
      destinationUrl: navigationURL,
    });
  };

  return (
    <EAPCodeBoxContainer>
      <Flex
        data-auto-testid="eap-code-card-container"
        data-testid="eap-code-card-container"
        justify="space-between"
      >
        <EAPCodeNeeded
          data-auto-testid="eap-code-needed"
          data-testid="eap-code-needed"
        >
          <EAPIcon brand="optum" icon="handshake" size="27" variant="twotone" />
          <EAPCodeText>
            {t('PROVIDER_DETAILS.ABOUT_TAB.EAP_CODE_CARD.HEADING')}
          </EAPCodeText>
          <Popover
            css={EAPPopoverStyling}
            data-auto-testid="eap-code-needed-popover"
            data-testid="eap-code-needed-popover"
          />
        </EAPCodeNeeded>
        <LinkWithTracking
          after={<IconMaterial icon="chevron_right" size="24" />}
          analyticsInfo={{
            location: 'body:get code',
            destinationUrl: navigationURL,
          }}
          css={EAPGetMyCodeLink}
          data-auto-testid="eap-code-card-get-my-code"
          data-testid="eap-code-card-get-my-code"
          hideIcon
          href={navigationURL}
          onClick={onClickGetMyLink}
        >
          {t('PROVIDER_DETAILS.ABOUT_TAB.EAP_CODE_CARD.LINK_TEXT')}
        </LinkWithTracking>
      </Flex>
    </EAPCodeBoxContainer>
  );
};
