import { PageBody } from '@abyss/web/ui/PageBody';
import { Router } from '@abyss/web/ui/Router';
import { Suspense } from 'react';

import { AllPatientReviews } from '../../../common/AllPatientReviews/AllPatientReviews';
import { CardWrapper } from '../../../common/CardWrapper';
import { Page404 } from '../../../errors/Page404';
import { Page408 } from '../../../errors/Page408';
import { Page4XX } from '../../../errors/Page4XX';
import { Page500 } from '../../../errors/Page500';
import { ChoosePCPLocation, ChoosePCPSuccess } from './ChoosePCP';
import { DeepLinks } from './DeepLinks';
import { FacilityDetails } from './FacilityDetails/FacilityDetails';
import { Footer } from './Footer';
import { GroupDetails } from './GroupDetails';
import { NullResultPageForSpecialty } from './NullSpecialtyPage';
import { ProviderDetailsView } from './ProviderDetailsView';
import { PSXHome } from './PSXHome';
import { SearchResults } from './SearchResults';
import { ViewAll } from './ViewAll';
import { VirtualProviderGroupDetails } from './VirtualProviderGroupDetails';

export const Routes = () => (
  <PageBody
    css={{
      'abyss-page-body-container': {
        maxWidth: '100%',
        padding: 0,
      },
    }}
  >
    <Suspense fallback={<div>LOADING...</div>}>
      <Router.Routes>
        {/* deeplinks component navigates to psxhome if there is no deeplink cookie or no matching route from lagoon */}
        <Router.Route element={<DeepLinks />} exact path="/" />
        <Router.Route element={<PSXHome />} exact path="/:token" />
        <Router.Route element={<SearchResults />} path="/results/:token" />
        <Router.Route
          element={<NullResultPageForSpecialty />}
          path="/no-specialty-results/:token"
        />
        {/* search results page with list view */}
        <Router.Route element={<ViewAll />} path="/results/viewall/:token" />
        {/* search results page with map view */}
        <Router.Route element={<ViewAll />} path="/results/mapview/:token" />
        <Router.Route
          element={<ProviderDetailsView />}
          path="/provider-details/:token"
        />
        <Router.Route
          element={<AllPatientReviews />}
          path="/provider-details/reviews/:token"
        />
        <Router.Route
          element={<FacilityDetails />}
          path="/facility-details/:token"
        />
        <Router.Route element={<GroupDetails />} path="/group-details/:token" />
        <Router.Route
          element={<VirtualProviderGroupDetails />}
          path="/virtual-group-details/:token"
        />
        <Router.Route
          element={<ChoosePCPLocation />}
          path="/choose-pcp/locations/:token"
        />
        <Router.Route
          element={<ChoosePCPSuccess />}
          path="/choose-pcp/success/:token"
        />
        <Router.Route element={<Page500 />} path="/500/:token" />
        <Router.Route element={<Page408 />} path="/408/:token" />
        <Router.Route element={<Page404 />} path="/404/:token" />
        <Router.Route element={<Page4XX />} path="/4xx/:token" />
      </Router.Routes>
    </Suspense>
    <CardWrapper backgroundColor="rgb(244, 246, 250)">
      <Footer />
    </CardWrapper>
  </PageBody>
);
