import { Text } from '@abyss/web/ui/Text';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TextRoot,
  specialtyNullStyle,
} from '../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../utility/compareDrawerConstants';

type Props = {
  specialty: string;
};

export const SpecialtyText = ({ specialty }: Props) => {
  const { t } = useTranslation();
  const isAttributePresent: boolean = !isEmpty(specialty);
  const providerSpecialty: string = isAttributePresent
    ? specialty
    : t(NULL_ATTRIBUTE_STATE.SPECIALTY);

  return (
    <React.Fragment>
      {isAttributePresent ? (
        <TextRoot>{providerSpecialty}</TextRoot>
      ) : (
        <Text css={specialtyNullStyle}>{t(providerSpecialty)}</Text>
      )}
    </React.Fragment>
  );
};
