import get from 'lodash/get';

const LAGOON_CONTEXTS = {};
const CACHED_JSON = {};

export const fetchJSON = (url) => {
  if (CACHED_JSON[url]) {
    return new Promise((resolve) => {
      resolve(CACHED_JSON[url]);
    });
  }
  return fetch(url, { cache: 'no-cache' })
    .then((response) => response.json())
    .then((data) => {
      CACHED_JSON[url] = data;
      return data;
    });
};

export const getCloudData = (env, locale) => {
  const url = `https://lagoon-psx.uhc.com/${env}/${locale}/${locale}.json`;
  return fetchJSON(url).then((data) => {
    const mappedData = {};

    Object.keys(data).forEach((tableKey) => {
      if (!mappedData[tableKey]) {
        mappedData[tableKey] = {};
      }

      data[tableKey].forEach((dataObj) => {
        if (dataObj.key || dataObj.id) {
          mappedData[tableKey][dataObj.key || dataObj.id] = dataObj;
        }
      });
    });

    return mappedData;
  });
};

export const getTokenizedValue = (value, data) => {
  if (data && /{{[a-zA-Z0-9_]+}}/.test(value)) {
    const stringMatches = value.match(/{{([a-zA-Z0-9_]+)}}/g);

    let newValue = value;
    stringMatches.forEach((match) => {
      const dataMatch = match.replace('{{', '').replace('}}', '');
      newValue = newValue.replace(match, data[dataMatch] || '');
    });

    return newValue;
  }

  return value;
};

const getLagoonTableFns = (tableData) => {
  const tableFunctions = {};

  Object.keys(tableData).forEach((tableKey) => {
    const tableFunction = (key, data, keyPath) => {
      const values = tableData[tableKey] || {};

      if (key) {
        let value = values[key];
        if (keyPath) {
          value = get(value, keyPath);
        } else if (typeof value === 'object') {
          const objKeys = Object.keys(value);
          if (objKeys.length === 2) {
            const valueKey = objKeys.find((objKey) => objKey !== 'key');
            value = value[valueKey];
          }
        }
        return getTokenizedValue(value, data);
      }

      return Object.values(values);
    };
    tableFunctions[tableKey] = tableFunction;
  });

  return tableFunctions;
};

const noop = () => undefined;

const setLagoonContext = (app, env, hostEnv, user, events, locale) =>
  getCloudData(env, locale).then((cloudData) => {
    const tableFns = getLagoonTableFns(cloudData);
    LAGOON_CONTEXTS[`${app}.${env}.${locale}`] = tableFns;
    if (events) {
      if (events.onReady) {
        events.onReady();
      }

      if (events.onData) {
        events.onData(tableFns);
      }
    }

    return tableFns;
  });

export const getLagoonTable = (lagoonContext, tablePath) => {
  if (lagoonContext) {
    const tableFn = lagoonContext[tablePath];
    if (tableFn) {
      return (key, data) => tableFn(key, data);
    }

    return tableFn || noop;
  }

  return lagoonContext;
};

export const lagoon = (app, env, hostEnv, locale) => ({
  initialize: (user, events) =>
    setLagoonContext(app, env, hostEnv, user, events, locale),
});
