import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import React, { Suspense, lazy, useEffect, useState } from 'react';

import { useLagoon } from '../../hooks/useLagoon';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import {
  getChipsByCoverages,
  getMemberLocation,
} from '../../utils/psxHeader.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { PSXHeaderCardWrapper } from '../CardWrapper';
import { ChipsCategory } from '../ChipsCategory';
import { Constants } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { getTypeAheadCategory } from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { getFeatureFlag } from '../Utils/getFeatureFlag';
import { findLoggedInMember } from '../Utils/memberUtils/memberUtils';
import { Breadcrumb } from './Breadcrumb';
import { DemoHeader } from './DemoHeader/index';
import { MemberSelectionHeader } from './MemberSelectionHeader';
import { MemberInfo } from './ObapiDemo/memberProfile';
import { SearchBar } from './SearchBar';
import { SearchInputBox } from './SearchInputBox';
import { handleSearchSelected } from './SearchInputBox/TypeaheadSearch';

const ChoosePCPHeader = lazy(async () => ({
  default: (await import('./ChoosePCPHeader/ChoosePCPHeader')).ChoosePCPHeader,
}));

type Props = {
  breadcrumbs?: any[];
  dataTestId?: string;
  onMemberIdChange?: (a: number) => void;
  onMemberDataChange?: (a: MemberInfo[]) => void;
  showChips?: boolean;
  showMemberSelection?: boolean;
  showChoosePCPHeader?: boolean;
  showSearchInputBackButton?: boolean;
};

export const PSXHeader = ({
  breadcrumbs = [],
  dataTestId = '',
  onMemberIdChange = () => {},
  onMemberDataChange = () => {},
  showChips = true,
  showMemberSelection = true,
  showChoosePCPHeader = false,
  showSearchInputBackButton = false,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);

  const featureFlags = useLagoon('feature-flags')();
  const searchBarV2 = getFeatureFlag(featureFlags, 'SEARCH_BAR_V2');

  const { MEMBER_LOCATION, ACTIVE_MEMBER_INDEX, LOGGED_IN_MEMBER_INDEX } =
    Constants.STORAGE_KEYS.SESSION;
  const { ZIPCODE } = Constants.STORAGE_KEYS.LOCAL;

  const { getRouteParams, navigate } = useRouter();

  const [searchType, setSearchType] = useState('');
  const [providerType, setProviderType] = useState('');
  const obapimembers = useStore(StoreKeys.OBAPI_MEMBERS);
  const big5 = useStore(StoreKeys.PLAN_SELECTOR);
  const [memberProfiles, setMemberProfiles] =
    useState<MemberInfo[]>(obapimembers);
  const [memberLocation, setMemberLocation] = useState<string>(
    storage.session.get(MEMBER_LOCATION) ?? ''
  );
  const [activeMemberIndex, setActiveMemberIndex] = useState(
    Number(storage.session.get(ACTIVE_MEMBER_INDEX)) ?? 0
  );
  const [activeUser, setActiveUser] = useState<MemberInfo>(
    memberProfiles[activeMemberIndex]
  );
  const { token } = getRouteParams();

  const currentZipcode = storage.local.get(ZIPCODE);

  const llmSearch = useLagoon(Constants.LAGOON_TABLE.LLM_SEARCH)();

  const chips = getChipsByCoverages(
    Constants.MOCK_CARE_CATEGORIES,
    activeUser?.eligibility
  );

  const form = useForm({
    defaultValues: {
      'care-category': 'all',
      'member-select': activeMemberIndex.toString(),
      search: '',
      includeSpecialityRollupCodes: '',
      searchType: '',
      firstName: 'FNVUJNKMDBNI',
      lastName: 'LNAHIGESDO',
      dob: '04-24-1978',
      memberId: '00990005391',
      groupNumber: '0168504',
    },
  });

  useEffect(() => {
    if (!currentZipcode) {
      form?.setValue('userZip', '07104');
      storage.local.set(ZIPCODE, '07104');
    }
  }, []);

  useEffect(() => {
    const loggedInMemberIndex = findLoggedInMember(memberProfiles, big5.bigN);

    if (!storage.session.get(ACTIVE_MEMBER_INDEX)) {
      storage.session.set(ACTIVE_MEMBER_INDEX, loggedInMemberIndex.toString());
    }
    if (!storage.session.get(LOGGED_IN_MEMBER_INDEX)) {
      storage.session.set(LOGGED_IN_MEMBER_INDEX, loggedInMemberIndex);
    }
    if (memberProfiles) {
      const memberIndex = Number(storage.session.get(ACTIVE_MEMBER_INDEX));
      form.setValue('member-select', memberIndex.toString());
      const location = getMemberLocation(memberProfiles[activeMemberIndex]);
      storage.session.set(MEMBER_LOCATION, location);
      setMemberLocation(location);
      setActiveMemberIndex(memberIndex);
      setActiveUser(memberProfiles[memberIndex]);
    }
  }, [memberProfiles, activeMemberIndex]);

  const onSubmit = (data) => {
    const searchTerm = form.getValues('searchTerm');
    const analyticsLinkName = 'search button';

    if (searchTerm?.length > 1) {
      handleSearchSelected(
        {
          ...data,
          searchTerm,
          linkName: analyticsLinkName,
          searchMethod: 'typed',
        },
        token,
        navigate,
        llmSearch
      );
    }

    let analyticsLocationName = 'search-input-option-list';
    let analyticsSearchBlock;
    if (data?.searchType) {
      analyticsLocationName += `:${getTypeAheadCategory(data?.resultType)}`;
      analyticsSearchBlock = {
        linkPosition: '1',
      };
    }
    adobeLinkTrackEvent({
      name: analyticsLinkName,
      location: analyticsLocationName,
      type: 'internal',
      destinationUrl: '',
      ...(analyticsSearchBlock && { searchBlock: analyticsSearchBlock }),
    });
  };

  const displayMobileBreadcrumbs = () => {
    if (mobileScreen && !showSearchInputBackButton) {
      return <Breadcrumb breadcrumbs={breadcrumbs} />;
    }
    return null;
  };

  return (
    <React.Fragment>
      <PSXHeaderCardWrapper backgroundColor="#FBFCFE" showSearchInputBackButton>
        {!mobileScreen && !showChoosePCPHeader ? (
          <Breadcrumb breadcrumbs={breadcrumbs} />
        ) : null}
        <FormProvider
          data-auto-testid={dataTestId}
          data-testid={dataTestId}
          onSubmit={onSubmit}
          state={form}
        >
          <Layout.Stack grow space={0}>
            <Layout.Group alignItems="top">
              {displayMobileBreadcrumbs()}
              {showMemberSelection ? (
                <MemberSelectionHeader
                  activeUser={activeUser}
                  form={form}
                  memberProfiles={memberProfiles}
                  setActiveMemberIndex={setActiveMemberIndex}
                />
              ) : null}
              {showChoosePCPHeader ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <Layout.Stack grow space={0}>
                    {!mobileScreen ? (
                      <Breadcrumb breadcrumbs={breadcrumbs} />
                    ) : null}
                    <ChoosePCPHeader />
                  </Layout.Stack>
                </Suspense>
              ) : null}
            </Layout.Group>

            <DemoHeader
              form={form}
              onMemberDataChange={onMemberDataChange}
              onMemberIdChange={onMemberIdChange}
              providerType={providerType}
              searchType={searchType}
              setMemberProfiles={setMemberProfiles}
              setProviderType={setProviderType}
              setSearchType={setSearchType}
            />
            {searchBarV2 ? (
              <SearchBar
                breadcrumbs={breadcrumbs}
                choosePCPHeader={showChoosePCPHeader}
                memberLocation={memberLocation}
                showSearchInputBackButton={showSearchInputBackButton}
              />
            ) : (
              <SearchInputBox
                breadcrumbs={breadcrumbs}
                choosePCPHeader={showChoosePCPHeader}
                data-auto-testid="search-home-input-box"
                data-testid="search-home-input-box"
                form={form}
                memberLocation={memberLocation}
                providerType={providerType}
                searchType={searchType}
                showSearchInputBackButton={showSearchInputBackButton}
              />
            )}
            {showChips ? (
              <Layout.Stack grow space={0}>
                <ChipsCategory
                  chips={chips}
                  data-auto-testid="search-home-chips-category"
                  data-testid="search-home-chips-category"
                />
              </Layout.Stack>
            ) : null}
          </Layout.Stack>
        </FormProvider>
      </PSXHeaderCardWrapper>
    </React.Fragment>
  );
};
