import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';

export const ReferralBannerBoxBanner = styled(Box, {
  '&.abyss-box-root': {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    background: '$tint2',
    height: '42px',
    width: '470px',
    padding: '0',
    '.abyss-popover-trigger ~ div': {
      zIndex: '9 !important',
    },

    '@screen < $sm': {
      maxHeight: '70px',
      width: '100%',
    },
    '@screen < $md': {
      height: '100%',
    },
  },
});

export const ReferralRequiredIcon = styled(IconMaterial, {
  '&.abyss-icon-material': {
    width: '18.33px',
    height: '20px',
    margin: '3px 8.83px 3px 16.83px',
    '@screen < $md': { marginLeft: '8.83px' },
  },
});

export const ReferralBannerReferralRequired = styled(Flex, {
  '&.abyss-flex-root': {
    display: 'flex',
    flexWrap: 'nowrap',
    fontWeight: '$medium',
    fontSize: '18px',
    lineHeight: '24px',
    paddingLeft: '8.83px',
    color: '$primary1',
    '@screen < $sm': {
      fontSize: '16px',
      paddingLeft: '0',
      marginTop: '8px',
      lineHeight: '20px',
    },
  },
});

export const ReferralBannerContactPCP = styled(Flex, {
  '&.abyss-flex-root': {
    cursor: 'pointer',
    display: 'flex',
    color: '#196ECF',
    fontWeight: '$bold',
    fontSize: '$md',
    flexWrap: 'nowrap',
    paddingLeft: '13.33px',
    '@screen < $sm': {
      fontSize: '12.64px',
      paddingTop: '10px',
      paddingRight: '8px',
      lineHeight: '16px',
    },
  },
});

export const ReferralBannerNeedHelp = styled(Link, {
  '&.abyss-link-root': {
    height: '26px',
    width: '177px',
    color: '#196ECF',
    fontSize: '12.64px',
    display: 'flex',
    padding: '5px 8px 5px 10px',
    background: '#FFFFFF',
    borderRadius: '4px',
    alignItems: 'center',
    fontWeight: '$bold',
    marginLeft: '16px',
    '@screen < $sm': {
      margin: '0px 8px 0px 8px',
      lineHeight: '16px',
    },
  },
  '.abyss-popover-trigger ~ div': {
    zIndex: '9 !important',
  },
});
