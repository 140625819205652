import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Popover } from '@abyss/web/ui/Popover';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProviderLocation, ProviderType } from '../../models/ProviderDetails';
import { SuggestEditLocationText } from './SuggestEditDrawerCard.styled';
import {
  LocationBox,
  LocationsDropdownOption,
  PopoverContent,
  PopoverContentSelected,
  suggestEditProviderCardStyle,
} from './SuggestEditProvider.styled';

export type Props = {
  providerType: ProviderType;
  providerName: string;
  avatar: string | undefined;
  providerLocations: ProviderLocation[];
  updateLocation: any;
};

export const SuggestEditProviderCard = ({
  providerType,
  providerName,
  providerLocations,
  avatar,
  updateLocation,
}: Props) => {
  const [selectedLocation, setSelectedLocation] = useState(
    providerLocations[0]
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const getLabel = (item) =>
    `${item.address.line[0]},${item.address.city}, ${item.address.state} ${item.address.postalCode}`;
  const [selectedLocationValue, setSelectedLocationValue] = useState(
    getLabel(providerLocations[0])
  );

  const locationsList = providerLocations.map((item) => ({
    label: (
      <Flex
        css={{
          '.abyss-text-root': {
            color:
              selectedLocationValue === getLabel(item) ? '#323334' : '#4B4D4F',
            fontWeight:
              selectedLocationValue === getLabel(item) ? '$bold' : '$semibold',
          },
        }}
        direction="column"
      >
        <Text>{getLabel(item).split(',')[0]}</Text>
        <Text>
          {getLabel(item).split(',')[1]}{' '}
          {getLabel(item).split(',').slice(2).join(',')}
        </Text>
      </Flex>
    ),
    value: getLabel(item),
  }));
  const locations = (
    <RadioGroup
      css={{
        'abyss-radio-group-container': { overflowX: 'hidden' },

        'abyss-radio-root': { marginLeft: '10px' },
      }}
      data-auto-testid="radio-group"
      data-testid="radio-group"
      onChange={(event) => {
        setSelectedLocation(event.target._wrapperState.initialValue);
      }}
      value={selectedLocation}
    >
      {providerLocations.map((item) => (
        <RadioGroup.Radio
          key={item?.address.line[0]}
          css={
            selectedLocation?.locationId === item?.locationId
              ? PopoverContentSelected
              : PopoverContent
          }
          label={`${item?.address.line[0]} ${'\n'} ${item?.address.city} ${
            item?.address.state
          } ${item?.address.postalCode}`}
          value={item}
        />
      ))}
      <Layout.Group>
        <Button
          css={{ marginTop: '36px', marginLeft: '10px' }}
          data-auto-testid="change-button"
          data-testid="change-button"
          onClick={() => {
            updateLocation(selectedLocation);
            setSelectedLocationValue(getLabel(selectedLocation));
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          {t('Choose')}
        </Button>
      </Layout.Group>
    </RadioGroup>
  );
  return (
    <Flex
      alignContent="flex-start"
      css={{
        'abyss-flex-root': {
          gap: '16px',
          padding: 0,
          width: '100%',
          flexWrap: 'wrap',
        },
      }}
      direction="column"
    >
      <Flex
        alignContent="flex-start"
        css={suggestEditProviderCardStyle}
        direction="column"
      >
        <Flex
          alignContent="flex-start"
          css={{
            'abyss-flex-root': {
              alignSelf: 'stretch',
              gap: '10px',
              padding: '0px 16px',
            },
          }}
          direction="row"
        >
          <IconBrand icon={avatar} size="48" variant="twotonelightcircle" />
          <Flex
            alignContent="flex-start"
            css={{
              'abyss-flex-root': {
                flexGrow: 1,
                gap: '2px',
                padding: '0px',
              },
            }}
            direction="column"
          >
            <Flex alignItems="center" direction="row">
              <Link
                data-auto-testid="suggest-edit-facility-name"
                data-testid="suggest-edit-facility-name"
                isStandardAnchor
              >
                <Text
                  color="#323334"
                  css={{
                    'abyss-text-root': {
                      fontSize: '$md',
                      fontWeight: '$bold',
                      lineHeight: '20px',
                    },
                  }}
                >
                  {providerName}
                </Text>
              </Link>
            </Flex>
            <Flex
              alignContent="flex-start"
              css={{
                'abyss-flex-root': {
                  gap: '40px',
                  padding: '0px',
                },
              }}
              direction="row"
            >
              <Flex alignContent="flex-start" direction="row">
                <Text
                  color="#000000"
                  css={{
                    'abyss-text-root': {
                      fontSize: '$smallText',
                      fontWeight: '$medium',
                      lineHeight: '20px',
                    },
                  }}
                >
                  {providerType === ProviderType.PRACTITIONER
                    ? 'Family Medicine'
                    : 'Facility'}
                </Text>
              </Flex>
            </Flex>
            {locationsList.length === 1 && (
              <SuggestEditLocationText
                aria-haspopup="dialog"
                data-auto-testid="suggst-edit-choose-location"
                data-testid="suggst-edit-choose-location"
              >
                {selectedLocationValue}
              </SuggestEditLocationText>
            )}
            {locationsList.length > 1 && (
              <Popover
                align="start"
                content={locations}
                css={{
                  'abyss-popover-arrow': { display: 'none !important' },
                  'abyss-popover-title': {},
                  'abyss-popover-content': {
                    width: '246px',
                  },
                }}
                data-auto-testid="suggest-edit-popover"
                data-testid="suggest-edit-popover"
                onOpenChange={() => {
                  setIsDropdownOpen(!isDropdownOpen);
                }}
                open={isDropdownOpen}
                position="bottom"
                showClose={false}
                title={
                  <Text
                    css={{
                      fontWeight: '700',
                      fontStyle: 'Normal',
                      fontSize: '25.63px',
                      marginTop: '24px',
                      marginLeft: '10px',
                      color: '#323334',
                    }}
                  >
                    {t('PROVIDER_DETAILS.LOCATIONS_TAB.CHANGE_LOCATION')}
                  </Text>
                }
              >
                <LocationBox
                  data-auto-testid="choose-location-dropdown"
                  data-testid="choose-location-dropdown"
                >
                  <LocationsDropdownOption>
                    {`${selectedLocationValue.split(',')[0]}\n${
                      selectedLocationValue.split(',')[1] +
                      selectedLocationValue.split(',')[2]
                    }` || t('SUGGEST_EDIT.CHOOSE_LOCATION')}
                  </LocationsDropdownOption>
                  <IconMaterial icon="keyboard_arrow_down" width="24px" />
                </LocationBox>
              </Popover>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
