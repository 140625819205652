import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../hooks/useLagoon';
import { FacilityDetails } from '../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
} from '../../../models/ProviderDetails';
import {
  getSelectedLocationIndex,
  providerHasLocations,
  shortenZipCode,
} from '../../../utils/providerDetails.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../Constants';
import { FeatureFlags } from '../../ConstantsFeatureFlags';
import { h2SmallerFontForResponsive, phoneOnly } from '../../ConstantsStyles';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
import { MapViewLocationsWrap } from '../../MapViewLocationsWrap/MapViewLocationsWrap';
import { MapViewUpdateLocationButton } from '../../MapViewUpdateLocationButton/MapViewUpdateLocationButton';
import { getFeatureFlag } from '../../Utils';
import { convertProviderTypeToAdobeType } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { Distance } from './Distance';
import { InfoProvider } from './InfoProvider';
import { LocationsContentForPopOver } from './LocationsContentForPopOver';
import { PopOver } from './PopOver';
import {
  AddressContainer,
  ChipContainer,
  Container,
  DividerContainer,
  IconAlignmentContainer,
  InnerContainer,
  LocationsHeader,
  MapViewBoxContainer,
  SectionContainer,
  ViewOnMapLabel,
} from './ProviderLocationsDetails.style';
import { TieredProvider } from './TieredProvider';
import { TierOneAlert } from './TierOneAlert';

type Props = {
  providerDetails: ProviderDetails;
  facilityDetails?: FacilityDetails;
  setUpdateLocation: (a: boolean) => void;
  navigateToDirections?: boolean;
  setNavigateToDirections: (a: boolean) => void;
  tabTitle?: string;
  isTiered?: boolean;
  isMapView?: boolean;
  setIsMapView: (a: boolean) => void;
};

const doShowNonTieredAlert = (
  isTieredUser,
  isTieredProvider,
  showTierProviderTag
) => isTieredUser && !isTieredProvider && showTierProviderTag;
const getIndicatorsForImpressions = (selectedLocation) => {
  const indicators: string[] = [];

  if (selectedLocation?.acceptingNewPatients)
    indicators.push(Constants.ADOBE_TRACKING.NEW_PATIENTS);
  if (
    selectedLocation?.virtualCareOffered &&
    selectedLocation?.virtualCareOffered.length
  )
    indicators.push(Constants.ADOBE_TRACKING.VIRTUAL_CARE);

  if (selectedLocation?.hasPCPServiceAvailability)
    indicators.push(Constants.ADOBE_TRACKING.PCP_SERVICES);

  if (selectedLocation?.hasEveningAppointments)
    indicators.push(Constants.ADOBE_TRACKING.EVENING_APPOINTMENT);
  return indicators?.join('|').toLocaleLowerCase();
};
const stickyButtonRowCheck = (hasMultipleLocations, mobileScreen) =>
  hasMultipleLocations && !mobileScreen;

export const ProviderLocationsDetails = ({
  providerDetails,
  setUpdateLocation,
  facilityDetails,
  navigateToDirections = false,
  setNavigateToDirections,
  tabTitle,
  isTiered,
  isMapView = false,
  setIsMapView,
}: Props) => {
  const locationsDetails = providerDetails?.providerLocations;
  const hasMultipleLocations = providerHasLocations(providerDetails);
  const [selectedId, setSelectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );
  const [directionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  const { navigate } = useRouter();

  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const featureTierOneFlags = useLagoon('feature-flags')();
  const showTierProviderTag = getFeatureFlag(
    featureTierOneFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const intialIndex = getSelectedLocationIndex(locationsDetails, selectedId);

  const [selectedAddressIndex, setSelectedAddressIndex] =
    useState<number>(intialIndex);
  const [currentIndex, setCurrentIndex] = useState<number>(intialIndex);
  const [selectedLocation, setSelectedLocation] = useState<ProviderLocation>(
    locationsDetails?.[selectedAddressIndex]
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [mobileRouteView, setMobileRouteView] = useState<boolean>(false);
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerDetails.providerType),
      indicator: getIndicatorsForImpressions(selectedLocation),
    },
  });

  useEffect(() => {
    if (locationsDetails?.[selectedAddressIndex]) {
      const selectedIndex: string = selectedAddressIndex.toString();
      const location = locationsDetails[selectedIndex];
      const { locationId } = location;
      setSelectedLocation(location);
      setSelectedId(locationId);
    }
  }, [selectedAddressIndex]);

  useEffect(() => {
    if (locationsDetails) {
      const index = locationsDetails.findIndex(
        (item) => item.locationId === selectedLocation?.locationId
      );
      setSelectedAddressIndex(index);
      setCurrentIndex(index);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedLocation) adobePageTrackEvent();
  }, [isMapView, selectedLocation]);

  const handleHideMap = () => {
    adobeLinkTrackEvent({
      name: 'back',
      location: `body:${Constants.PROVIDER_DETAILS.VIEWONMAP}`,
      type: 'internal',
    });
    setIsMapView(false);
    setNavigateToDirections(false);

    if (
      directionsTriggeredFrom !==
      Constants.DETAILS_PAGE_HEADER_DIRECTIONS_BUTTON
    ) {
      navigate(-1);
    }
  };

  useEffect(() => {
    setIsMapView(navigateToDirections);
  }, [navigateToDirections, mobileScreen]);

  useEffect(() => {
    if (!mobileRouteView && mobileScreen && navigateToDirections)
      handleHideMap();
  }, [mobileRouteView]);

  const changeAriaLabelForAlert = () => {
    const elements = document.getElementsByClassName(
      'abyss-alert-close-button'
    );
    return elements?.[0]?.setAttribute('aria-label', 'Close Alert');
  };

  useEffect(() => {
    if (
      doShowNonTieredAlert(
        isTiered,
        selectedLocation?.isTieredProvider,
        showTierProviderTag
      )
    ) {
      changeAriaLabelForAlert();
    }
  }, [selectedLocation, showTierProviderTag, isTiered]);

  const form = useForm({
    defaultValues: {
      'radio-form': '',
    },
  });

  const handleViewMap = () => {
    setIsMapView(true);
  };

  const handleUpdateLocation = () => {
    adobeLinkTrackEvent({
      name: 'save location',
      location: `body:${Constants.PROVIDER_DETAILS.VIEWONMAP}`,
      type: 'internal',
    });
    setUpdateLocation(true);
    setIsMapView(false);
    setSelectedAddressIndex(currentIndex);
    setNavigateToDirections(false);
  };

  const handleSelectedLocation = (location, index) => {
    setSelectedLocation(location);
    setSelectedAddressIndex(index);
  };

  if (!selectedLocation) {
    return null;
  }

  const useStickyButtonRow = stickyButtonRowCheck(
    hasMultipleLocations,
    mobileScreen
  );
  const isSameLocationSelected = selectedAddressIndex === currentIndex;
  const {
    line: addressLine,
    city,
    state,
    postalCode,
  } = selectedLocation.address;

  return !isMapView ? (
    <Container
      data-auto-testid="provider-locations-details-section"
      data-testid="provider-locations-details-section"
    >
      <TierOneAlert
        data-auto-testid="alert-header-container"
        data-testid="alert-header-container"
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        showNonTieredAlert={doShowNonTieredAlert(
          isTiered,
          selectedLocation.isTieredProvider,
          showTierProviderTag
        )}
      />
      <FormProvider state={form}>
        <SectionContainer>
          <LocationsHeader alignItems="flex-end">
            <Heading
              css={h2SmallerFontForResponsive}
              data-auto-testid="directory-update-title"
              data-testid="directory-update-title"
              display="h4"
              offset={1}
            >
              {t('PROVIDER_DETAILS.LOCATIONS')}
              {` (${providerDetails.providerLocations.length})`}
            </Heading>
            <Layout.Group css={{ marginTop: '10px' }}>
              <LinkWithTracking
                analyticsInfo={{
                  location: 'body:locations',
                  name: Constants.PROVIDER_DETAILS.VIEWONMAP,
                }}
                data-auto-testid="view-facility-locations-on-map"
                data-testid="view-facility-locations-on-map"
                href="#"
                isStandardAnchor
                onClick={handleViewMap}
              >
                <IconMaterial
                  color="$interactive1"
                  css={{ width: '18px', height: '18px', marginRight: '4px' }}
                  icon="map"
                />
                <ViewOnMapLabel color="$interactive1">
                  {t('PROVIDER_DETAILS.VIEWONMAP')}
                </ViewOnMapLabel>
              </LinkWithTracking>
            </Layout.Group>
          </LocationsHeader>
          <PopOver
            isOpen={isOpen}
            locationsContentForPopOver={
              <LocationsContentForPopOver
                isOpen={isOpen}
                locationsDetails={locationsDetails}
                mobileScreen={mobileScreen}
                selectedIndex={selectedAddressIndex}
                selectedLocation={selectedLocation}
                setIsOpen={setIsOpen}
                setSelectedLocation={handleSelectedLocation}
              />
            }
            locationsDetails={locationsDetails}
            selectedLocation={selectedLocation}
            setIsOpen={setIsOpen}
          />
        </SectionContainer>
      </FormProvider>
      <TieredProvider isTieredProvider={selectedLocation.isTieredProvider} />
      <InnerContainer>
        <ChipContainer
          data-auto-testid="selected-location-heading"
          data-testid="selected-location-heading"
        >
          <Heading
            css={{
              '&.abyss-heading-root': {
                fontSize: '$extraSmallText !important',
                fontWeight: '$medium !important',
                color: '$neutralGray !important',
              },
            }}
            display="h6"
            offset={2}
          >
            {t('PROVIDER_LOCATIONS_DETAILS.SELECTED_LOCATION')}
          </Heading>
        </ChipContainer>
        <Flex
          css={{ flexWrap: 'nowrap' }}
          data-auto-testid="provider-location-details"
          data-testid="provider-location-details"
        >
          <IconAlignmentContainer>
            <IconMaterial color="$primary1" icon="corporate_fare" size={20} />
            <Flex
              data-auto-testid="provider-selected-location"
              data-testid="provider-selected-location"
              direction="column"
            >
              <AddressContainer> {addressLine?.join(', ')}</AddressContainer>
              <AddressContainer>
                {city}, {state} {shortenZipCode(postalCode)}
              </AddressContainer>
            </Flex>
          </IconAlignmentContainer>
          <DividerContainer>
            <Divider height="100%" orientation="vertical" width={1} />
          </DividerContainer>
          <Distance
            content={t('DISTANCE_POPOVER_CONTENT')}
            selectedLocation={selectedLocation}
            title={t('CONTENT_FILTERS.DISTANCE')}
          />
        </Flex>

        <InfoProvider
          detailType={providerDetails.providerType}
          facilityDetails={facilityDetails}
          providerDetails={providerDetails}
          selectedLocation={selectedLocation}
        />
      </InnerContainer>
    </Container>
  ) : (
    <Container
      data-auto-testid="provider-locations-details-section"
      data-testid="provider-locations-details-section"
    >
      <MapViewBoxContainer>
        <MapViewLocationsWrap
          directionsButtonClicked={navigateToDirections}
          handleGoBack={handleHideMap}
          handleUpdateLocation={handleUpdateLocation}
          hasMultipleLocations={hasMultipleLocations}
          isMapView={isMapView}
          mobileRouteView={mobileRouteView}
          mobileScreen={mobileScreen}
          providerDetails={providerDetails}
          setCurrentIndex={setCurrentIndex}
          setIsMapView={setIsMapView}
          setMobileRouteView={setMobileRouteView}
          tabTitle={tabTitle}
          updateLocationButton={
            <MapViewUpdateLocationButton
              handleSaveLocation={handleUpdateLocation}
              isSameLocationSelected={isSameLocationSelected}
            />
          }
          useStickyButtonRow={useStickyButtonRow}
        />
      </MapViewBoxContainer>
    </Container>
  );
};
