import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import { CommonlySearchedCard } from '../../../../common/CommonlySearched';
import { Constants } from '../../../../common/Constants';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { PSXHeader } from '../../../../common/PSXHeader';
import { StillNeedHelp } from '../../../../common/StillNeedHelp';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { NoSpecialtyResult } from './NoSpecialtyResult';
import { NullPageContainerStyled } from './NullSpecialtyPage.styles';

export type NullResultsPageProps = {
  searchTitle: string;
};

export const NullResultPageForSpecialty = () => {
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'null specialty results',
    sitesectionLevel1: `${Constants.ADOBE_TRACKING.VIEWALL_SITESECTION1}`,
    sitesectionLevel2: `${Constants.ADOBE_TRACKING.VIEWALL_SITESECTION2}`,
  });

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  const { t } = useTranslation();

  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { sectionType = '', search, linkName } = tokenData;

  const [breadcrumbUrls, setBreadcrumbUrls] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const parseBreadcrumbs = (urls) => {
    const sectionTypeCondition =
      sectionType === Constants.RESULT_SECTION.PROVIDER
        ? t('Provider results')
        : t('Provider group results');
    const breadcrumbs = [
      {
        title:
          sectionType === Constants.RESULT_SECTION.FACILITY
            ? t('Facility results')
            : sectionTypeCondition,
        href: '',
      },
    ];
    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]) {
      breadcrumbs.unshift({
        title: t('BC Results'),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]}`,
      });
    }
    return breadcrumbs;
  };

  const breadcrumbs = parseBreadcrumbs(breadcrumbUrls);

  useEffect(() => {
    breadcrumbUrls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key] =
      window.location.pathname.replace('/findcare', '');
    setBreadcrumbUrls(breadcrumbUrls);
  }, []);

  useEffect(() => {
    adobeStandardSearchEvent({
      linkName,
      numberOfResults: 0,
      method: 'guided',
    });
  }, []);
  return (
    <React.Fragment>
      <PSXHeader
        breadcrumbs={breadcrumbs}
        dataTestId="no-specialty-page"
        showChips={false}
        showChoosePCPHeader={false}
        showMemberSelection={false}
      />
      <NullPageContainerStyled>
        <NoSpecialtyResult
          data-auto-testid="null-specialty-page-abyss-box-root"
          data-testid="null-specialty-page-abyss-box-root"
          searchName={search}
        />
        <CommonlySearchedCard />
        <StillNeedHelp />
      </NullPageContainerStyled>
    </React.Fragment>
  );
};
