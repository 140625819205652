import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FlexContainer,
  InfoAlignmentContainer,
  InfoSubTextContainer,
  InfoTitleContainer,
} from './ProviderLocationsDetails.style';

type Props = {
  icon: string;
  infoTitle: string;
  infoSubtext?: string;
  children?: ReactNode;
  variant?: String;
  showSubTitle?: Boolean;
  isFromVirtualProviderGroup?: boolean;
};

export const InfoProviderContent = ({
  icon,
  infoTitle,
  infoSubtext,
  children,
  variant,
  showSubTitle = true,
  isFromVirtualProviderGroup,
}: Props) => {
  const { t } = useTranslation();
  const infoTitleLowerCase = infoTitle.toLowerCase().split(' ').join('-');
  return (
    <FlexContainer
      data-auto-testid={`provider-info-container-${
        infoTitleLowerCase.includes('accepting')
          ? 'accepting-patients-status'
          : infoTitleLowerCase
      }`}
      data-testid={`provider-info-container-${
        infoTitleLowerCase.includes('accepting')
          ? 'accepting-patients-status'
          : infoTitleLowerCase
      }`}
    >
      <IconMaterial color="$primary1" icon={icon} size={20} variant={variant} />
      <InfoAlignmentContainer>
        <React.Fragment>
          <InfoTitleContainer>{infoTitle}</InfoTitleContainer>
          {showSubTitle && !isFromVirtualProviderGroup
            ? children || (
                <InfoSubTextContainer
                  data-testid={`${infoTitle}-subtext`.replace(/ /g, '-')}
                >
                  {infoSubtext || t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                </InfoSubTextContainer>
              )
            : null}
        </React.Fragment>
      </InfoAlignmentContainer>
    </FlexContainer>
  );
};
