/* eslint-disable no-nested-ternary */
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TextRoot,
  pcpServiceAvailableStyle,
} from '../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../utility/compareDrawerConstants';

type Props = {
  pcpServicesAvaliable: boolean;
};

export const PcpServicesAvaliable = ({ pcpServicesAvaliable }: Props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {pcpServicesAvaliable ? (
        <TextRoot>
          {t(ATTRIBUTE_STATUS_TEXT.PCP_SERVICES_AVAILABILE.TRUE)}
        </TextRoot>
      ) : pcpServicesAvaliable === false ? (
        <TextRoot>
          {t(ATTRIBUTE_STATUS_TEXT.PCP_SERVICES_AVAILABILE.FALSE)}
        </TextRoot>
      ) : (
        <Text css={pcpServiceAvailableStyle}>
          {t(NULL_ATTRIBUTE_STATE.PCP_SERVICES_AVAILABILE)}
        </Text>
      )}
    </React.Fragment>
  );
};
