import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Icon } from '@abyss/web/ui/Icon';

export const PageContainer = styled(Flex, {
  background: '$tint1',
  height: '384px',
  gap: '12px',
  alignItems: 'center',
  alignContent: 'center',
  flexDirection: 'column',
  paddingBottom: '$md',
  '@screen < $sm': {
    height: '273px',
    paddingTop: '$md',
    paddingLeft: '$lg',
    alignContent: 'flex-start',
    flexDirection: 'row-reverse',
  },
});

export const IconContainer = styled('div', {
  height: '141px',
  width: '175px',
  overflow: 'hidden',
  position: 'relative',
  '@screen < $sm': {
    height: '173px',
    width: '128px',
  },
});

export const PageContent = styled(Flex, {
  alignContent: 'center',
  alignItems: 'center',
  flex: 1,
  flexDirection: 'column',
  '@screen < $sm': {
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
});

export const BrokenLinkIcon = styled(Icon, {
  position: 'absolute',
  top: '-32px',
  width: '175px ! important',
  height: '173px! important',
  '@screen < $sm': {
    right: '-47px',
    top: 'unset',
  },
});
