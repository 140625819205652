import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { mobileOnly } from '../../ConstantsStyles';
import { ReferralBannerBox } from '../About/ReferralBannerBox';
import {
  CommonCostProceduresHeader,
  CommonProceduresAndCostContainer,
  CostAccordianContainer,
  CostAccordianTrigger,
  CostAndProceduresContainer,
  CostAverageContainer,
  CostBreakDownSection,
  CostEstimateButton,
  CostEstimateButtonContainer,
  CostHeader,
  CostTopSection,
  InNetworkContainer,
  MobileBox,
} from './Cost.styled';

type Props = {
  rateData?: any;
};

export const Cost = ({ rateData = {} }: Props) => {
  const mobile = useMediaQuery(mobileOnly);
  const textSize = mobile ? 'md' : 'lg';
  const { t } = useTranslation();
  const proceduresData = [
    {
      serviceName: 'Specialist - High Complexity',
      averageCost: '561',
      estimatedTotalCost: '621',
      insurancePays: '200',
      outOfPocketMax: '421',
    },
    {
      serviceName: 'Specialist - Low Complexity',
      averageCost: '281',
      estimatedTotalCost: '183',
      insurancePays: '0',
      outOfPocketMax: '183',
    },
  ];
  return (
    <React.Fragment>
      <ReferralBannerBox
        data-auto-testid="referral-banner-box-cost-tab"
        data-testid="referral-banner-box-cost-tab"
      />
      <CostTopSection>
        <InNetworkContainer alignItems="top">
          <IconMaterial color="$success1" icon="check_circle" />
          <Layout.Stack alignItems="left">
            <CostHeader>{t('PROVIDER_DETAILS.COST_TAB.IN_NETWORK')}</CostHeader>
            <Text fontWeight="$medium" size={textSize}>
              {t('PROVIDER_DETAILS.SECTION_TEXT')}
            </Text>
          </Layout.Stack>
        </InNetworkContainer>
        {mobile ? <Divider color="$gray4" height={1} /> : null}
        <CostAverageContainer alignItems="top">
          <IconMaterial color="$primary1" icon="attach_money" />
          <Layout.Stack alignItems="left">
            <CostHeader>
              {rateData?.geoAvgCostIndicator ??
                t('PROVIDER_DETAILS.COST_TAB.COST_AVERAGES.BELOW_AVERAGE')}
            </CostHeader>
            <Text fontWeight="$medium" size={textSize}>
              {t('PROVIDER_DETAILS.SECTION_TEXT')}
            </Text>
          </Layout.Stack>
        </CostAverageContainer>
        {mobile ? <MobileBox color="$gray1" height="10" padding="0" /> : null}
        <CostEstimateButtonContainer>
          <CostEstimateButton>
            {t('PROVIDER_DETAILS.COST_TAB.GET_COST_ESTIMATE')}
          </CostEstimateButton>
        </CostEstimateButtonContainer>
        {mobile ? <MobileBox color="$gray1" height="10" padding="0" /> : null}
      </CostTopSection>
      <CommonProceduresAndCostContainer>
        <CommonCostProceduresHeader color="$primary1">
          {t('PROVIDER_DETAILS.COST_TAB.COMMON_PROCEDURES_AND_COSTS')}
        </CommonCostProceduresHeader>
        <Accordion type="multiple">
          <Accordion.Item value="office">
            <CostAccordianTrigger>
              {t('PROVIDER_DETAILS.COST_TAB.OFFICE_VISITS')}
            </CostAccordianTrigger>
            <CostAccordianContainer>
              {rateData?.servicesCost?.map((service, index) => (
                <CostAndProceduresContainer>
                  <Text fontWeight="$bold" size={mobile ? '$md' : '$lg'}>
                    {service.serviceName}
                  </Text>
                  <Flex
                    alignItems="flex-end"
                    css={{ marginTop: mobile ? '$sm' : '$md' }}
                  >
                    <div
                      style={{
                        width: mobile ? '100%' : '25%',
                        lineHeight: '16px',
                        marginBottom: mobile ? '20px' : '0px',
                      }}
                    >
                      <Layout.Stack alignItems="left" space={4}>
                        <Text css={{ wordBreak: 'break-word' }} size={12.64}>
                          {`${t(
                            'PROVIDER_DETAILS.COST_TAB.AVERAGE_COST_NEAR'
                          )} XXXXX`}
                        </Text>
                        <Text fontWeight="$bold" size={mobile ? '$md' : '$lg'}>
                          {`$${service.averageCost}`}
                        </Text>
                      </Layout.Stack>
                    </div>
                    <CostBreakDownSection>
                      <Layout.Stack alignItems="left" space={4}>
                        <Text css={{ wordBreak: 'break-word' }} size={12.64}>
                          {t('PROVIDER_DETAILS.COST_TAB.ESTIMATED_TOTAL_COST')}
                        </Text>
                        <Text fontWeight="$bold" size={mobile ? '$md' : '$lg'}>
                          {`$${service.estimatedTotalCost}`}
                        </Text>
                      </Layout.Stack>
                    </CostBreakDownSection>
                    <CostBreakDownSection>
                      <Layout.Stack alignItems="left" space={4}>
                        <Text css={{ wordBreak: 'break-word' }} size={12.64}>
                          {t('PROVIDER_DETAILS.COST_TAB.INSURANCE_PAYS')}
                        </Text>
                        <Text fontWeight="$bold" size={mobile ? '$md' : '$lg'}>
                          {`$${service.insurancePays}`}
                        </Text>
                      </Layout.Stack>
                    </CostBreakDownSection>
                    <CostBreakDownSection>
                      <Layout.Stack alignItems="left" space={4}>
                        <Text css={{ wordBreak: 'break-word' }} size={12.64}>
                          {t(
                            'PROVIDER_DETAILS.COST_TAB.ESTIMATED_OUT_OF_POCKET_COST'
                          )}
                        </Text>
                        <Text
                          css={{ wordBreak: 'break-word' }}
                          fontWeight="$bold"
                          size={mobile ? '$md' : '$lg'}
                        >
                          {`$${service.outOfPocketMax}`}
                        </Text>
                      </Layout.Stack>
                    </CostBreakDownSection>
                  </Flex>
                  {index < rateData?.servicesCost?.length - 1 ? (
                    <Divider color="$cardBorder" margin="20px" />
                  ) : null}
                </CostAndProceduresContainer>
              ))}
            </CostAccordianContainer>
          </Accordion.Item>
          <Accordion.Item value="procedures">
            <CostAccordianTrigger>
              {t('PROVIDER_DETAILS.COST_TAB.PROCEDURES')}
            </CostAccordianTrigger>
            <CostAccordianContainer>
              {proceduresData.map((service, index) => (
                <CostAndProceduresContainer>
                  <Text fontWeight="$bold" size={mobile ? '$md' : '$lg'}>
                    {service.serviceName}
                  </Text>
                  <Flex
                    alignItems="flex-end"
                    css={{ marginTop: mobile ? '$sm' : '$md' }}
                  >
                    <div
                      style={{
                        width: mobile ? '100%' : '25%',
                        lineHeight: '16px',
                        marginBottom: mobile ? '20px' : '0px',
                      }}
                    >
                      <Layout.Stack alignItems="left" space={4}>
                        <Text css={{ wordBreak: 'break-word' }} size={12.64}>
                          {`${t(
                            'PROVIDER_DETAILS.COST_TAB.AVERAGE_COST_NEAR'
                          )} XXXXX`}
                        </Text>
                        <Text fontWeight="$bold" size={mobile ? '$md' : '$lg'}>
                          {`$${service.averageCost}`}
                        </Text>
                      </Layout.Stack>
                    </div>
                    <CostBreakDownSection>
                      <Layout.Stack alignItems="left" space={4}>
                        <Text css={{ wordBreak: 'break-word' }} size={12.64}>
                          {t('PROVIDER_DETAILS.COST_TAB.ESTIMATED_TOTAL_COST')}
                        </Text>
                        <Text fontWeight="$bold" size={mobile ? '$md' : '$lg'}>
                          {`$${service.estimatedTotalCost}`}
                        </Text>
                      </Layout.Stack>
                    </CostBreakDownSection>
                    <CostBreakDownSection>
                      <Layout.Stack alignItems="left" space={4}>
                        <Text css={{ wordBreak: 'break-word' }} size={12.64}>
                          {t('PROVIDER_DETAILS.COST_TAB.INSURANCE_PAYS')}
                        </Text>
                        <Text fontWeight="$bold" size={mobile ? '$md' : '$lg'}>
                          {`$${service.insurancePays}`}
                        </Text>
                      </Layout.Stack>
                    </CostBreakDownSection>
                    <CostBreakDownSection>
                      <Layout.Stack alignItems="left" space={4}>
                        <Text css={{ wordBreak: 'break-word' }} size={12.64}>
                          {t(
                            'PROVIDER_DETAILS.COST_TAB.ESTIMATED_OUT_OF_POCKET_COST'
                          )}
                        </Text>
                        <Text
                          css={{ wordBreak: 'break-word' }}
                          fontWeight="$bold"
                          size={mobile ? '$md' : '$lg'}
                        >
                          {`$${service.outOfPocketMax}`}
                        </Text>
                      </Layout.Stack>
                    </CostBreakDownSection>
                  </Flex>
                  {index < rateData?.servicesCost?.length - 1 ? (
                    <Divider color="$cardBorder" margin="20px" />
                  ) : null}
                </CostAndProceduresContainer>
              ))}
            </CostAccordianContainer>
          </Accordion.Item>
        </Accordion>
      </CommonProceduresAndCostContainer>
    </React.Fragment>
  );
};
