import React from 'react';

import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
} from '../../../models/ProviderDetails';
import { dateFormatCurrent } from '../../Utils/datesUtils/formatDate';
import { AllOtherInfosContainer } from './AllOtherInfosContainer';
import { VirtualProviderGroupAllOtherInfo } from './VirtualProviderGroupAllOtherInfo';

type Props = {
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
  detailType: String;
  providerDetails?: ProviderDetails;
  facilityDetails?: FacilityDetails;
  isFromVirtualProviderGroup?: boolean;
};

export const AllOtherInfos = ({
  selectedLocation,
  detailType,
  providerDetails,
  facilityDetails,
  isFromVirtualProviderGroup,
}: Props) => {
  const practiceLimitation = `${
    selectedLocation?.practiceLimitations?.age || ''
  } ${
    selectedLocation?.practiceLimitations?.gender
      ? `(${selectedLocation?.practiceLimitations?.gender})`
      : ''
  }`;

  const inNetworkStartDate = (inNetworkStartingDate: string) => {
    if (inNetworkStartingDate) return dateFormatCurrent(inNetworkStartingDate);
    return undefined;
  };

  return (
    <React.Fragment>
      {isFromVirtualProviderGroup ? (
        <VirtualProviderGroupAllOtherInfo
          detailType={detailType}
          facilityDetails={facilityDetails}
          inNetworkStartDate={inNetworkStartDate(
            selectedLocation?.network?.start
          )}
          practiceLimitation={practiceLimitation}
          providerDetails={providerDetails}
          selectedLocation={selectedLocation}
        />
      ) : (
        <AllOtherInfosContainer
          detailType={detailType}
          facilityDetails={facilityDetails}
          inNetworkStartDate={inNetworkStartDate(
            selectedLocation?.network?.start
          )}
          practiceLimitation={practiceLimitation}
          providerDetails={providerDetails}
          selectedLocation={selectedLocation}
        />
      )}
    </React.Fragment>
  );
};
