import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Card } from '@abyss/web/ui/Card';
import { CollapseProvider } from '@abyss/web/ui/CollapseProvider';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { capitalizeEachWord } from '../../../../utils/providerDetails.utils';
import { Constants } from '../../../Constants';
import { mobileOnly } from '../../../ConstantsStyles';
import {
  CollapseCardHeaderStyle,
  CollapseCardSectionStyle,
  CollapseSection,
  CollapseSubSection,
} from '../../../ProviderDetailTabs/About/About.styled';
import { CopyToClipBoard } from '../../../ProviderDetailTabs/ProviderLocationsDetails/CopyToClipBoard';

export interface ProviderListItem {
  title: string;
  value: string | string[];
  translation?: {
    title: string;
  };
  canCopy?: boolean;
}

export type Props = {
  collapseHeader: string;
  collapseContent?: string[] | null;
  providerGroupDetail?: Array<ProviderListItem> | null;
};

export const CollapseProviderDetailsSection = ({
  collapseHeader,
  collapseContent,
  providerGroupDetail,
}: Props) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(mobileOnly);
  const listProviderDetail = () => {
    const fontSize = mobile ? '$md' : '$lg';
    return (
      <CollapseSubSection>
        {providerGroupDetail?.map((item, index) => (
          <Flex direction="column">
            <Text
              color="$gray7"
              data-auto-testid={`${item.title
                .replace(/ /g, '-')
                .toLocaleLowerCase()}`}
              data-testid={`${item.title
                .replace(/ /g, '-')
                .toLocaleLowerCase()}`}
              fontWeight={index !== 0 ? '$semibold' : '$bold'}
              size={fontSize}
            >
              {item?.translation?.title}
            </Text>
            <Layout.Stack alignItems="left">
              {(typeof item.value === 'string' ? [item.value] : item.value).map(
                (value) => (
                  <Flex
                    css={{
                      'abyss-icon-material': {
                        cursor: 'pointer',
                      },
                      gap: '6px',
                    }}
                  >
                    <Text
                      color={item?.canCopy ? '$info1' : '$gray7'}
                      fontWeight={item?.canCopy ? '$bold' : '$medium'}
                      size={fontSize}
                    >
                      {value}
                    </Text>
                    {item?.value?.[0] !==
                      Constants.PROVIDER_GROUP_DETAILS.ABOUT_TAB.DETAILS_SECTION
                        .NOT_AVAILABLE && (
                      <CopyToClipBoard
                        label={item.title}
                        text={value}
                        title={item.title}
                      />
                    )}
                  </Flex>
                )
              )}
            </Layout.Stack>
          </Flex>
        ))}
      </CollapseSubSection>
    );
  };

  return (
    <React.Fragment>
      <CollapseSection>
        {mobile ? (
          <CollapseProvider>
            <Card
              collapse
              css={CollapseCardHeaderStyle}
              header={
                <Heading
                  css={{
                    '&.abyss-heading-root': {
                      fontSize: '20.25px !important',
                      lineHeight: '24px !important',
                    },
                  }}
                  display="h4"
                  offset={1}
                >
                  {collapseHeader}
                </Heading>
              }
            >
              {collapseHeader ===
              t(
                'PROVIDER_GROUP_DETAILS.ABOUT_TAB.PROVIDER_GROUP_DETAILS_TITLE'
              ) ? (
                <Card.Section css={CollapseCardSectionStyle}>
                  {listProviderDetail()}
                </Card.Section>
              ) : (
                collapseContent?.map((item) => (
                  <Card.Section
                    key={`collapse-section-${item}`}
                    css={CollapseCardSectionStyle}
                  >
                    {capitalizeEachWord(item)}
                  </Card.Section>
                ))
              )}
            </Card>
          </CollapseProvider>
        ) : (
          <Layout.Stack alignItems="left" space={12}>
            <Heading
              data-auto-testid={`${collapseHeader
                .replace(/ /g, '-')
                .toLocaleLowerCase()}-heading`}
              data-testid={`${collapseHeader
                .replace(/ /g, '-')
                .toLocaleLowerCase()}-heading`}
              display="h4"
              offset={1}
            >
              {collapseHeader}
            </Heading>
            {collapseHeader ===
            t('PROVIDER_GROUP_DETAILS.ABOUT_TAB.PROVIDER_GROUP_DETAILS_TITLE')
              ? listProviderDetail()
              : collapseContent?.map((item) => (
                  <Text
                    key={`expand-section-${item}`}
                    color="#4B4D4F"
                    data-auto-testid="specialty-type"
                    data-testid="specialty-type"
                    fontWeight="$medium"
                    size="$lg"
                  >
                    {capitalizeEachWord(item)}
                  </Text>
                ))}
          </Layout.Stack>
        )}
      </CollapseSection>
    </React.Fragment>
  );
};
