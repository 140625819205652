import { Constants } from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { setGeoLocationToStorage } from '../../common/PSXHeader/SearchBar/utils';
import { getFeatureFlag } from '../../common/Utils';
import { useLagoon } from '../useLagoon';
import { useLocation } from '../useLocation';
import { useUserLocation } from '../useUserLocation';

export const useDeviceLocation = (
  setDeviceLocation: (location: string) => void,
  setIsLoading?: (loading: boolean) => void
) => {
  const featureFlags: [{ key: string; active: boolean }] = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_FLAGS
  )();
  const decemberFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );
  const [, getLocation] = useLocation({
    onCompleted: (result: {
      data: {
        location: {
          features: [
            {
              place_name: string;
              id: string;
              center: [string, string];
              stateCode: string;
            }
          ];
        };
      };
    }) => {
      const targetLocation = result?.data?.location?.features?.[0] || {};
      const [long, lat] = targetLocation.center || [];
      const placeName = targetLocation.place_name;
      // set geo coords
      setGeoLocationToStorage({
        name: placeName,
        longitude: long,
        latitude: lat,
        stateCode: targetLocation.stateCode,
      });
      if (setIsLoading) {
        setIsLoading(false);
      }
    },
  });
  const [, getUserLocation] = useUserLocation({
    onCompleted: (result: {
      data: { userLocation: { features: { place_name: string }[] } };
    }) => {
      const { ADDRESS_CITY_STATE_ZIPCODE } = Constants.DEVICE_LOCATION;
      const devicePlaceName = result?.data?.userLocation?.features[
        ADDRESS_CITY_STATE_ZIPCODE
      ]?.place_name?.slice(0, -15);
      getLocation({
        variables: {
          address: devicePlaceName,
          countySearchEnabled: decemberFlag,
        },
      });
      setDeviceLocation(devicePlaceName);
    },
  });

  const handleFindLocation = async (longitude: string, latitude: string) => {
    await getUserLocation({
      variables: {
        longitude,
        latitude,
      },
    });
  };

  const getDeviceLocation = () => {
    const successCallback = (position: {
      coords: { latitude: number; longitude: number };
    }) => {
      const { latitude, longitude } = position.coords;
      handleFindLocation(String(longitude), String(latitude));
    };

    navigator.geolocation.getCurrentPosition(successCallback, () => {
      if (setIsLoading) {
        setIsLoading(false);
      }
    });
  };
  return { getDeviceLocation };
};
