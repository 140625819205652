/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../hooks/useLagoon';
import { usePrimaryCare } from '../../../hooks/usePrimaryCare';
import { useSpecialtiesResult } from '../../../hooks/useSpecialties';
import {
  Education,
  Identifiers,
  ProviderDetails,
} from '../../../models/ProviderDetails';
import { getProviderPCPId } from '../../../utils/providerDetails.utils';
import { isReferralRequired } from '../../../utils/specialtyWithReferral.utils';
import {
  getCurrentMember,
  getCurrentMemberPCPInfo,
} from '../../../utils/user.utils';
import { Constants } from '../../Constants';
import { FeatureFlags } from '../../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { ContentWrapper } from '../../ContentWrapper';
import { EAPCode } from '../../EAPCode';
import { getFeatureFlag } from '../../Utils';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  convertSpecialtiesList,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { dateFormatCurrent } from '../../Utils/datesUtils/formatDate';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs.styled';
import {
  CollapseContainer,
  CollapseContainerOtherProviders,
  StyledPCPAlert,
  StyledPCPWrapper,
} from './About.styled';
import { BiographySection } from './BiographySection';
import { CollapseProviderDetailsSection } from './CollapseProviderDetailsSection/CollapseProviderDetailsSection';
import { PatientReviews } from './PatientReviews/PatientReviews';
import { ReferralBannerBox } from './ReferralBannerBox';

type Props = {
  providerDetails: ProviderDetails;
  tabTitle?: string;
  coverageType: string;
};

const {
  AREAS_OF_EXPERTISE,
  HOSPITAL_AFFILIATIONS,
  PROVIDER_SPECIALTIES,
  EDUCATION,
  OTHER_PROVIDER_DETAILS,
  NA,
} = Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION;

const getFuturePCPDetails = (providerDetails, memberPCP) => {
  let isFuturePCP = false;
  let futurePCPStartDate;
  if (memberPCP?.futurePCP) {
    const { providerID: memberFuturePCPId, startDate } = memberPCP?.futurePCP;
    const providerPCPId = getProviderPCPId(
      providerDetails.providerLocations,
      providerDetails.providerId,
      memberFuturePCPId
    );

    if (memberFuturePCPId === providerPCPId) {
      isFuturePCP = true;
      futurePCPStartDate = dateFormatCurrent(startDate);
    }
  }

  return {
    isFuturePCP,
    futurePCPStartDate,
  };
};

export const About = ({ providerDetails, tabTitle, coverageType }: Props) => {
  const { t } = useTranslation();
  const providerLocations = providerDetails?.providerLocations;
  const [selectedLocation, setSelectedLocation] = useState(providerLocations);
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const decemberFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );
  const currentMember = getCurrentMember();
  const { data: pcpData } = usePrimaryCare();

  const selectedLocationValue = selectedLocation
    ? selectedLocation[0]
    : undefined;
  const locationId =
    selectedId !== undefined ? selectedId : providerLocations[0].locationId;

  let currentLocation;
  providerLocations.forEach((location) => {
    location.locationId === locationId ? (currentLocation = location) : null;
  });

  const tier =
    (showTierProviderTag && currentLocation?.isTieredProvider) ||
    (showTierProviderTag && providerLocations[0]?.isTieredProvider)
      ? 'tier 1'
      : '';
  const { hasPCPServiceAvailability } = currentLocation || providerLocations[0];

  let providerRole = 'specialist';
  hasPCPServiceAvailability ? (providerRole += '|pcp') : null;

  useEffect(() => {
    setSelectedLocation(providerLocations);
  }, []);

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerDetails.providerType),
      indicator: '',
    },
    providerBlock: {
      type: convertCoverageType(coverageType),
      specialty:
        convertSpecialtiesList(
          providerDetails?.defaultTranslatedValues?.specialties
        ) || '',
      tier,
      role: providerRole || '',
    },
  });

  useEffect(() => {
    if (providerDetails.providerName) adobePageTrackEvent();
  }, [providerDetails]);

  const { primaryCare } = pcpData;
  const memberPCP = getCurrentMemberPCPInfo(
    currentMember?.demographics?.dependentSeqNbr,
    primaryCare
  );

  const { isFuturePCP, futurePCPStartDate } = getFuturePCPDetails(
    providerDetails,
    memberPCP
  );

  const userState = selectedLocationValue?.address?.state;
  // below gets the list of specialities which requires referral
  const { data: specialties = [] } = useSpecialtiesResult({
    isReferralRequired: true,
    userState,
  });

  return (
    <div style={{ overflow: 'auto hidden' }}>
      {isFuturePCP && (
        <StyledPCPWrapper>
          <ContentWrapper>
            <StyledPCPAlert
              title={`${t('PROVIDER_DETAILS.FUTURE_PCP')}${futurePCPStartDate}`}
              variant="info"
            />
          </ContentWrapper>
        </StyledPCPWrapper>
      )}
      {!!currentMember?.isGatedUser &&
        isReferralRequired(specialties, selectedLocationValue) && (
          <ReferralBannerBox
            data-auto-testid="referral-banner-box"
            data-testid="referral-banner-box"
            pcpData={pcpData?.primaryCare}
          />
        )}

      {decemberFlag && (
        <ContainerForAllSectionsStyled>
          <ContentWrapper>
            <EAPCode />
          </ContentWrapper>
        </ContainerForAllSectionsStyled>
      )}
      <BiographySection
        content={providerDetails?.biographicData}
        data-auto-testid="about-tab-biography-section"
        data-testid="about-tab-biography-section"
        ethnicity={providerDetails?.ethnicity}
        gender={providerDetails?.gender}
        heading="BIOGRAPHY_SECTION.HEADING"
        language={providerDetails?.languagesSpoken}
      />
      <PatientReviews
        providerDetails={providerDetails}
        selectedLocation={selectedLocationValue}
      />
      <CollapseContainer>
        <CollapseProviderDetailsSection
          collapseConstantKey={PROVIDER_SPECIALTIES}
          collapseContent={
            providerDetails?.specialties || [
              t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'),
            ]
          }
          collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.PROVIDER_SPECIALTIES"
        />
      </CollapseContainer>
      <CollapseContainer>
        <CollapseProviderDetailsSection
          collapseConstantKey={AREAS_OF_EXPERTISE}
          collapseContent={
            providerDetails?.areaOfExpertise || [
              t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'),
            ]
          }
          collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.AREAS_OF_EXPERTISE"
        />
        <CollapseProviderDetailsSection
          collapseConstantKey={EDUCATION}
          collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION"
          culturalCompetencies={providerDetails?.culturalCompetencies}
          education={
            providerDetails?.educationInfo ||
            (new Array({
              degrees: [
                {
                  degreeCode: t(
                    'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                  ),
                  isPrimary: true,
                  description: t(
                    'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                  ),
                },
              ],
              schoolName: t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA'),
            }) as Education[])
          }
        />
      </CollapseContainer>
      <CollapseContainerOtherProviders>
        <CollapseProviderDetailsSection
          collapseConstantKey={HOSPITAL_AFFILIATIONS}
          collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS"
          hospitalAffiliations={
            providerDetails?.hospitalAffiliations ||
            ([
              {
                id: NA,
                value: t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA'),
              },
            ] as Identifiers[])
          }
          providerId={providerDetails?.providerId || ''}
        />
      </CollapseContainerOtherProviders>
      <CollapseContainerOtherProviders>
        <CollapseProviderDetailsSection
          collapseConstantKey={OTHER_PROVIDER_DETAILS}
          collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.OTHER_PROVIDER_DETAILS"
          licenseNumber={providerDetails?.licenseNumber || []}
          npi={providerDetails?.npi || ''}
          providerId={providerDetails?.providerId || ''}
        />
      </CollapseContainerOtherProviders>
    </div>
  );
};
