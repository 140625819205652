import { ReactNode } from 'react';

import { PSXHeaderWrapper, Wrapper } from './ContentWrapper.styled';

export type ContentWrapperProps = {
  children: ReactNode;
  showSearchInputBackButton?: boolean;
};

export const ContentWrapper = ({ children }: ContentWrapperProps) => (
  <Wrapper>{children}</Wrapper>
);

export const PSXHeaderContentWrapper = ({
  children,
  showSearchInputBackButton,
}: ContentWrapperProps) => (
  <PSXHeaderWrapper showSearchInputBackButton={showSearchInputBackButton}>
    {children}
  </PSXHeaderWrapper>
);
