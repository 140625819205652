import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import escapeRegExp from 'lodash/escapeRegExp';
import uniqBy from 'lodash/uniqBy';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ChipCategoryContext } from '../../../../context/ChipCategoryContext';
import { useLagoon } from '../../../../hooks/useLagoon';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants, ReverseCoverageTypesCodes } from '../../../Constants';
import { phoneOnly } from '../../../ConstantsStyles';
import {
  CustomAttributesBlock,
  convertTypeaheadProviderIdAndType,
  getNameForLinkTrack,
  getTypeAheadCategory,
} from '../../../Utils/adobeTrackUtils/adobeTrackUtils';
import { handleSearchSelected } from '../../SearchInputBox/TypeaheadSearch';
import { HighlightedSuggestion } from './HighlightedSuggestion';
import {
  Divider,
  DropdownContainer,
  SuggestionListContainer,
  isPrimaryActiveSuggestion,
  keywordSearchDropdownIconColor,
} from './KeywordSearchDropdown.styled';
import { Skeleton } from './Skeleton';

type Props = {
  activeSuggestion: number;
  headers: ResponseHeaders;
  isKeywordSearchLoading: boolean;
  typeAheadSuggestions?: any;
  keywordSearchTerm: string;
  isLoading: boolean;
  setIsFocusedOnKeywordSearchInput: (val: boolean) => void;
  enter: boolean;
  dentalCoverage: string;
  visionCoverage: string;
};

const SuggestionItem = ({
  value,
  index,
  activeSuggestion,
  keywordSearchTerm,
  onClick,
}) => {
  const escapedKeywordSearchTerm = escapeRegExp(keywordSearchTerm);
  const isPhone = useMediaQuery(phoneOnly);
  return (
    <Link
      key={value.id}
      before={
        <IconMaterial color={keywordSearchDropdownIconColor} icon="search" />
      }
      css={{
        'abyss-link-root':
          activeSuggestion === index ? isPrimaryActiveSuggestion : '',
      }}
      data-testid="search-input-box-common-suggestion"
      onClick={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        onClick(value);
      }}
      style={{ whiteSpace: isPhone ? '' : 'pre' }}
      tabIndex={-1}
    >
      <div
        style={{
          ...(!isPhone && {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '8px',
          }),
        }}
      >
        <div>
          {value?.suggestion
            ?.split(new RegExp(`(${escapedKeywordSearchTerm})`, 'gi'))
            .map((part) => {
              if (part.toLowerCase() === keywordSearchTerm.toLowerCase()) {
                return (
                  <Text color="$interactive1" size="18px">
                    {part}
                  </Text>
                );
              }
              return (
                <Text color="$interactive1" fontWeight="bold" size="18px">
                  {part}
                </Text>
              );
            })}
        </div>
        <Text color="#5A5A5A" fontWeight={500} size="18px">
          {`in ${value?.vertical?.providerType}`}
        </Text>
      </div>
    </Link>
  );
};

export const KeywordSearchDropdown = ({
  activeSuggestion,
  headers,
  isKeywordSearchLoading,
  typeAheadSuggestions,
  keywordSearchTerm,
  isLoading,
  setIsFocusedOnKeywordSearchInput,
  enter,
  visionCoverage,
  dentalCoverage,
}: Props) => {
  const { t } = useTranslation();
  const commonSearchesLagoon: () => any = useLagoon('common-searches');
  const commonSearchesData = commonSearchesLagoon();

  const llmSearch = useLagoon(Constants.LAGOON_TABLE.LLM_SEARCH)();
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const { setChipValue } = useContext(ChipCategoryContext);
  const noResultsFound = t('No Results Found');
  const specialCharSearch = t('Special char search');
  const shortsearch = t('Short search');
  if (isKeywordSearchLoading) {
    return <Skeleton text="Search is taking longer than expected" />;
  }

  const searchInputOptionLocation = 'search-input-option-list';
  const moreProvidersLinkDataTestid: string = 'more-providers-link';

  function getLinkNameForAnalytics(value) {
    return getNameForLinkTrack(
      value?.suggestion ||
        value?.displayName ||
        `common search:${value?.psxKeyword}`,
      value?.providerType
    );
  }

  function getSearchMethodForAnalytics(value) {
    let searchMethod;
    if (value?.psxKeyword) {
      searchMethod = 'guided';
    } else {
      searchMethod = 'predictive';
    }
    return searchMethod;
  }

  function adobeLinkTrack(value, index) {
    const customAttributesBlock =
      value?.searchType === 'Speciality'
        ? undefined
        : ({
            correlationId: headers?.correlationId,
            ...convertTypeaheadProviderIdAndType(value),
          } as CustomAttributesBlock);

    adobeLinkTrackEvent({
      name: getLinkNameForAnalytics(value),
      location: `body:${searchInputOptionLocation}:${getTypeAheadCategory(
        value?.providerType
      )}`,
      type: 'internal',
      searchBlock: {
        linkPosition: index + 1 || '1',
      },
      customAttributesBlock,
    });
  }

  const onChipClicked = (value, coverageType) => {
    adobeLinkTrackEvent({
      name: value,
      location: `body:${searchInputOptionLocation}`,
    });
    const updatedToken = tokenizer.update(token, {
      chipValue: value,
      coverageType,
    });
    setChipValue(value);
    navigate(`/${updatedToken}`);
  };

  const onSuggestionClick = (value, index) => {
    if (value) {
      setIsFocusedOnKeywordSearchInput(false);
      adobeLinkTrack(value, index);
      handleSearchSelected(
        {
          ...value,
          searchTerm: keywordSearchTerm,
          linkName: getLinkNameForAnalytics(value),
          searchMethod: getSearchMethodForAnalytics(value),
        },
        token,
        navigate,
        llmSearch
      );
    }
  };

  const setMessage = () => {
    let message: string;
    if (!/[A-Za-z0-9]/.test(keywordSearchTerm) && keywordSearchTerm !== '') {
      message = specialCharSearch;
    } else if (keywordSearchTerm?.length <= 1) {
      message = shortsearch;
    } else {
      message = noResultsFound;
    }

    return message;
  };

  const typeAheadProviderLinks = [
    {
      name: t('TYPEAHEAD_PROVIDERS_LINK_MEDICAL'),
      coverageType: ReverseCoverageTypesCodes.MEDICAL,
    },
    {
      name: t('TYPEAHEAD_PROVIDERS_LINK_BEHAVIORAL'),
      coverageType: ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'],
    },
  ];

  const formatSpecialty = (specialtiesList = []) => {
    const distinctSpecialty = uniqBy(specialtiesList, 'category');
    const list = distinctSpecialty.slice(0, 2);
    let displaySpecialty = '';
    list.map((item: any, index: number) => {
      displaySpecialty += `${item.category}${
        index !== list.length - 1 ? ', ' : ''
      }`;
      return displaySpecialty;
    });
    if (distinctSpecialty.length > 2) {
      displaySpecialty += ` (${distinctSpecialty.length - 2} ${t('More')})`;
    }

    return displaySpecialty;
  };

  return (
    <React.Fragment>
      {(keywordSearchTerm?.trim().length === 0 ||
        keywordSearchTerm?.trim().length === 1) &&
      !enter ? (
        <React.Fragment>
          {dentalCoverage || visionCoverage ? <Divider /> : null}
          <DropdownContainer data-testid="primary-dropdown-container">
            {dentalCoverage ? (
              <Link
                after={<IconMaterial icon="chevron_right" />}
                data-testid="search-input-box-dental-common-suggestion"
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  setIsFocusedOnKeywordSearchInput(false);
                  onChipClicked(Constants.CHIPS_CATEGORIES.DENTAL, 'D');
                }}
                style={{ whiteSpace: 'pre' }}
                tabIndex={-1}
              >
                {t('DENTAL_SEARCH')}
              </Link>
            ) : null}
            {visionCoverage ? (
              <Link
                after={<IconMaterial icon="chevron_right" />}
                data-testid="search-input-box-vision-common-suggestion"
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  setIsFocusedOnKeywordSearchInput(false);
                  onChipClicked(Constants.CHIPS_CATEGORIES.VISION, 'V');
                }}
                style={{ whiteSpace: 'pre' }}
                tabIndex={-1}
              >
                {t('VISION_SEARCH')}
              </Link>
            ) : null}
            <Divider />
            <Text
              data-testid="heading-common-search"
              fontWeight={700}
              size="$sm"
            >
              {t('COMMON_SEARCH')}
            </Text>
            <SuggestionListContainer
              data-auto-testid="suggestion-list-container"
              data-testid="suggestion-list-containter"
            >
              {commonSearchesData.map((value, index) => (
                <Link
                  key={value.id}
                  before={
                    <IconMaterial
                      color={keywordSearchDropdownIconColor}
                      icon="search"
                    />
                  }
                  css={{
                    'abyss-link-root':
                      activeSuggestion === index
                        ? isPrimaryActiveSuggestion
                        : '',
                  }}
                  data-testid="search-input-box-common-suggestion-link"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    onSuggestionClick(value, index);
                  }}
                  style={{ whiteSpace: 'pre' }}
                  tabIndex={-1}
                >
                  <HighlightedSuggestion
                    keyword={value?.psxKeyword}
                    text={value?.psxKeyword}
                  />
                </Link>
              ))}
            </SuggestionListContainer>
          </DropdownContainer>
        </React.Fragment>
      ) : (
        <DropdownContainer data-testid="primary-dropdown-container">
          <SuggestionListContainer
            data-auto-testid="suggestion-list-container"
            data-testid="suggestion-list-containter"
          >
            {typeAheadSuggestions?.length > 0 && !enter
              ? typeAheadSuggestions?.map((value, index) => {
                  if (value?.suggestion) {
                    return (
                      <React.Fragment key={value.id}>
                        <SuggestionItem
                          key={value.id}
                          activeSuggestion={activeSuggestion}
                          data-testid="search-input-box-typeahead-suggestion"
                          index={index}
                          keywordSearchTerm={keywordSearchTerm}
                          onClick={() => onSuggestionClick(value, index)}
                          value={value}
                        />
                      </React.Fragment>
                    );
                  }
                  if (value?.section) {
                    return (
                      <React.Fragment>
                        <Divider />
                        <Text
                          color="$gray8"
                          data-testid="heading-typeahead-suggested-searches"
                          fontWeight={700}
                          size="14.22px"
                        >
                          {value.section}
                        </Text>
                        {value?.items?.map((item, ix) => (
                          <React.Fragment>
                            <Link
                              color="$interactive1"
                              data-testid="search-input-box-typeahead-suggestion-section"
                              fontWeight="bold"
                              index={ix}
                              onClick={(ev) => {
                                ev.stopPropagation();
                                ev.preventDefault();
                                onSuggestionClick(item, ix);
                              }}
                              transform="capitalize"
                            >
                              <div>
                                <Text color="$info1">
                                  {t(item?.displayName)}
                                </Text>
                                <Text
                                  color="$gray7"
                                  css={{ marginLeft: '8px' }}
                                  fontWeight={500}
                                  size="14.22px"
                                >
                                  {formatSpecialty(item.speciality)}
                                </Text>
                              </div>
                            </Link>
                          </React.Fragment>
                        ))}
                        {typeAheadProviderLinks.map((item) => (
                          <Link
                            after={
                              <IconMaterial
                                css={{ width: '14px' }}
                                icon="chevron_right"
                              />
                            }
                            data-testid={moreProvidersLinkDataTestid}
                            fontWeight={500}
                            onClick={(ev) => {
                              ev.stopPropagation();
                              ev.preventDefault();
                              setIsFocusedOnKeywordSearchInput(false);
                              adobeLinkTrackEvent({
                                name: `${moreProvidersLinkDataTestid} ${
                                  item.coverageType ===
                                  ReverseCoverageTypesCodes.MEDICAL
                                    ? 'medical'
                                    : 'behavioral'
                                }`,
                                location: `body:${searchInputOptionLocation}`,
                              });
                              handleSearchSelected(
                                {
                                  ...value,
                                  search: keywordSearchTerm,
                                  searchType: Constants.SEARCH_TYPES.NAME,
                                  searchTerm: keywordSearchTerm,
                                  coverageType: item.coverageType,
                                  linkName: `${moreProvidersLinkDataTestid} ${
                                    item.coverageType ===
                                    ReverseCoverageTypesCodes.MEDICAL
                                      ? 'medical'
                                      : 'behavioral'
                                  }`,
                                  searchMethod:
                                    getSearchMethodForAnalytics(value),
                                },
                                token,
                                navigate,
                                llmSearch
                              );
                            }}
                            size="12.64px"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </React.Fragment>
                    );
                  }
                  return null;
                })
              : !isLoading && (
                  <span
                    className="no-results"
                    data-testid="suggestion-list-no-results"
                  >
                    {setMessage()}
                  </span>
                )}
          </SuggestionListContainer>
        </DropdownContainer>
      )}
    </React.Fragment>
  );
};
