import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import { getStatusText } from '../../../../../../ShareDrawer/utils';
import {
  StyledVirtualCareOfferedDiv,
  popoverStyle,
} from '../../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../../utility/compareDrawerConstants';

type Props = {
  virtualCareOffered: boolean;
  mobile: boolean;
};

export const VirtualCareOfferedAttribute = ({
  virtualCareOffered,
  mobile,
}: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const virtualCareOfferredText = getStatusText(
    virtualCareOffered,
    ATTRIBUTE_STATUS_TEXT.VIRTUAL_CARE_OFFERED.TRUE,
    ATTRIBUTE_STATUS_TEXT.VIRTUAL_CARE_OFFERED.FALSE,
    NULL_ATTRIBUTE_STATE.VIRTUAL_CARE_OFFERED
  );

  const isAttributeEmpty =
    virtualCareOfferredText === NULL_ATTRIBUTE_STATE.VIRTUAL_CARE_OFFERED;

  const getVirtualCareOffered = (icon) => (
    <StyledVirtualCareOfferedDiv
      ref={containerRef}
      cssProps={{ isOverflow, isAttributeEmpty }}
    >
      <Layout.Group>
        <IconMaterial color="$primary1" icon={icon} size={20} />
        {t(virtualCareOfferredText)}
      </Layout.Group>
    </StyledVirtualCareOfferedDiv>
  );

  const VirtualCareOffered = () => {
    if (virtualCareOffered === true) {
      return getVirtualCareOffered('videocam');
    }
    if (virtualCareOffered === false) {
      return getVirtualCareOffered('close');
    }
    return getVirtualCareOffered('do_not_disturb');
  };

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={virtualCareOfferredText}
            css={popoverStyle}
            showClose={false}
            width={150}
          >
            {VirtualCareOffered()}
          </Popover>
        ) : (
          VirtualCareOffered()
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip
          content={virtualCareOfferredText}
          position="top"
          positionOffset={8}
        >
          {VirtualCareOffered()}
        </Tooltip>
      ) : (
        VirtualCareOffered()
      )}
    </React.Fragment>
  );
};
