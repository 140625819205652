/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { FacilityDetails } from '../../../models/FacilityDetails';
import { ProviderDetails } from '../../../models/ProviderDetails';
import { Constants } from '../../Constants';
import { CollapseContainer } from '../../ProviderDetailTabs/About/About.styled';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  convertSpecialtiesList,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  CollapseProviderDetailsSection,
  ProviderListItem,
} from './CollapseProviderDetailsSection/CollapseProviderDetailsSection';

type Props = {
  providerDetails: FacilityDetails | ProviderDetails;
  tabTitle?: string;
  coverageType: string;
};

const {
  CULTURAL_COMPLETENCE,
  NATIONAL_PROVIDER_IDENTIFIER,
  PROVIDER_GROUP_ID,
} = Constants.PROVIDER_GROUP_DETAILS.ABOUT_TAB.DETAILS_SECTION;

export const ProviderGroupAbout = ({
  providerDetails,
  tabTitle,
  coverageType,
}: Props) => {
  const { t } = useTranslation();
  const notAvailable = [t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')];
  const providerLocations = providerDetails?.providerLocations;
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const getProviderGroupRole = () => {
    const locationId =
      selectedId && selectedId !== ''
        ? selectedId
        : providerLocations?.at(0)?.locationId;

    let currentLocation;
    providerLocations?.forEach((location) => {
      location.locationId === locationId ? (currentLocation = location) : null;
    });
    let role = 'group';
    if (currentLocation?.hasPCPServiceAvailability) role += '|pcp';

    return role;
  };

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerDetails.providerType),
      indicator: '',
    },
    providerBlock: {
      type: convertCoverageType(coverageType),
      specialty:
        convertSpecialtiesList(
          providerDetails?.defaultTranslatedValues?.specialties
        ) || '',
      role: getProviderGroupRole() || '',
    },
  });

  useEffect(() => {
    if (providerDetails.providerLocations) {
      adobePageTrackEvent();
    }
  }, [providerDetails]);

  const specialities = useMemo(() => {
    if (providerDetails?.specialties?.length)
      return providerDetails.specialties;
    return notAvailable;
  }, providerDetails?.providerLocations);

  const providerGroupDetails: Array<ProviderListItem> = useMemo(
    () => [
      {
        title: CULTURAL_COMPLETENCE,
        value: providerDetails?.culturalCompetencies || notAvailable,
        translation: {
          title: t('PROVIDER_GROUP_DETAILS.ABOUT_TAB.CULTURAL_COMPLETENCE'),
        },
      },
      {
        title: NATIONAL_PROVIDER_IDENTIFIER,
        value: providerDetails?.npi || notAvailable,
        canCopy: Boolean(providerDetails?.npi),
        translation: {
          title: t(
            'PROVIDER_GROUP_DETAILS.ABOUT_TAB.NATIONAL_PROVIDER_IDENTIFIER'
          ),
        },
      },
      {
        title: PROVIDER_GROUP_ID,
        value: providerDetails?.providerId || notAvailable,
        canCopy: Boolean(providerDetails?.providerId),
        translation: {
          title: t('PROVIDER_GROUP_DETAILS.ABOUT_TAB.PROVIDER_GROUP_ID'),
        },
      },
    ],
    [providerDetails]
  );

  return (
    <div style={{ overflow: 'auto hidden' }}>
      <CollapseContainer>
        <CollapseProviderDetailsSection
          collapseContent={specialities}
          collapseHeader={t('PROVIDER_GROUP_DETAILS.ABOUT_TAB.SPECIALTIES')}
        />
      </CollapseContainer>
      <CollapseContainer>
        <CollapseProviderDetailsSection
          collapseHeader={t(
            'PROVIDER_GROUP_DETAILS.ABOUT_TAB.PROVIDER_GROUP_DETAILS_TITLE'
          )}
          providerGroupDetail={providerGroupDetails}
        />
      </CollapseContainer>
    </div>
  );
};
