import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackCardProviderResults } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { getSnackCardDisplayValByLength } from '../../utils/providerDetails.utils';
import { CardHeader } from '../CardHeader/CardHeader';
import { Constants } from '../Constants';
import {
  cardHeaderContainerStyle,
  cardHeaderWithNavigatorStyle,
} from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { LoadingSpinnerComp } from '../Loader';
import { SnackCardCarousel, SnackCardContainer } from '../SnackCardContainer';
import { TopRecomendedChipsStyled } from './NullResultsPage.styles';

export const TopRecommendedProvider = () => {
  const { t } = useTranslation();
  const { data, isLoading, headers } = useSnackCardProviderResults({
    providerType: 'practitioner',
    pcpIndicator: false,
  });
  const { snackCardList } = data;

  const numberOfCards = data?.snackCardList?.length;
  const displayCarousel = getSnackCardDisplayValByLength(numberOfCards);

  const sectionHeading = Constants.TOP_PROVIDERS_NEAR_YOU.HEADING;

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingSpinnerComp isLoading={isLoading} />
      ) : (
        <ContentWrapper>
          <Flex
            alignItems="flex-end"
            css={cardHeaderContainerStyle}
            justify="start"
          >
            <CardHeader css={cardHeaderWithNavigatorStyle}>
              {t('NULL_PAGE.TOP_PROVIDER_NEAR_YOU')}
            </CardHeader>
          </Flex>
          {snackCardList?.length ? (
            <TopRecomendedChipsStyled>
              {displayCarousel ? (
                <SnackCardCarousel
                  headers={headers}
                  isFixes
                  isLoading={false}
                  locationForAnalytics={sectionHeading}
                  snackCardProviders={snackCardList?.slice(0, 5)}
                />
              ) : (
                <SnackCardContainer
                  headers={headers}
                  isLoading={false}
                  locationForAnalytics={sectionHeading}
                  snackCardProviders={snackCardList?.slice(0, 5)}
                />
              )}
            </TopRecomendedChipsStyled>
          ) : (
            t('No Results Found')
          )}
        </ContentWrapper>
      )}
    </React.Fragment>
  );
};
