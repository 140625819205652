/* eslint-disable react/no-array-index-key */
import { SkeletonContainer } from './SkeletonContainer';

type HorizontalAlignment = 'left' | 'center' | 'right';

interface IParagraphSkeletonProps {
  width?: number;
  height?: number;
  widths: number[];
  lineSpacing?: number;
  fontSize?: number;
  textAlign?: HorizontalAlignment;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  testId?: string;
}

export const ParagraphSkeleton = ({
  width: widthFromProps,
  height,
  widths,
  lineSpacing = 12,
  fontSize = 12,
  textAlign = 'left',
  paddingTop = 0,
  paddingBottom = 0,
  paddingLeft = 0,
  paddingRight = 0,
  testId,
}: IParagraphSkeletonProps) => {
  const numberOfRows = widths.length;
  if (numberOfRows === 0) {
    return null;
  }

  const containerWidth =
    widthFromProps ??
    Math.max(0, ...widths.map((width) => paddingLeft + width + paddingRight));
  const containerHeight =
    height ??
    numberOfRows * fontSize +
      (numberOfRows - 1) * lineSpacing +
      paddingTop +
      paddingBottom;
  return (
    <SkeletonContainer
      height={containerHeight}
      testId={testId}
      width={containerWidth}
    >
      {widths.map((providedWidth, index) => {
        const width = Math.min(
          providedWidth,
          containerWidth - paddingLeft - paddingRight
        );
        const x = {
          left: paddingLeft,
          right: containerWidth - width - paddingRight,
          center:
            paddingLeft +
            (containerWidth - width - paddingLeft - paddingRight) / 2,
        }[textAlign];
        const y = index * (fontSize + lineSpacing) + paddingTop;
        return (
          <rect
            key={index}
            height={fontSize}
            rx="8"
            ry="8"
            width={width}
            x={x}
            y={y}
          />
        );
      })}
    </SkeletonContainer>
  );
};
