export enum TranslationType {
  SPECIALTY,
  SPECILATYWITHREFERRAL,
}

export interface Specialty {
  description: string;
  name: string;
  specialtyCode: string;
}

export interface SpecialtyWithReferral {
  name: string;
  code: string[];
  referralRequired: Referral;
}

export interface Referral {
  isRequired: boolean;
  excludedStates: string[];
}
