import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { storage } from '@abyss/web/tools/storage';
import { useTranslation } from 'react-i18next';

import { Constants, NetworkStatus, ProviderTypes } from '../common/Constants';
import { ConstantsRoutes } from '../common/ConstantsRoutes';
import {
  carouselScreen,
  fiveRecentSearchCardsScreen,
  fourCardscarouselScreen,
  fourRecentSearchCardsScreen,
  reviewsFourCardsCarouselScreen,
  reviewsThreeCardsCarouselScreen,
  reviewsTwoCardsCarouselScreen,
  sixCardscarouselScreen,
  threeCardscarouselScreen,
  threeRecentSearchCardsScreen,
  twoCardscarouselScreen,
} from '../common/ConstantsStyles';
import { MockProviderGroupResults } from '../common/MockResults';
import { FacilityDetails } from '../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../models/ProviderDetails';

export function getProviderSpecialtyOrOrgTypeCode(providerType, organizationType, speciality) {
  return (providerType === Constants.RESULT_SECTION.ORGANIZATION
      ? organizationType?.[0]
      : speciality) || '';
}

export function shortenZipCode(postalCode) {
  return postalCode?.slice(0, 5);
}

export function capitalizeEachWord(words) {
  return words?.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  );
}

export const getSubText = (flag: boolean | undefined) => {
  const { t } = useTranslation();
  if (flag === true) return t('Yes');
  if (flag === false) return t('No');
  return t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE');
};

export function formatProviderNameWithFirstMiddleLastName(providerName) {
  const splitName = providerName.split(' ');
  const firstName = splitName[1];
  const lastName = splitName[0].replace(
    /-(.)/g,
    (char) => `${char.toUpperCase()}`
  );
  const middleName = splitName[2] || '';
  if (middleName.length > 0) {
    return `${firstName} ${middleName.slice(0, 1)} ${lastName}`;
  }
  return `${firstName} ${lastName}`;
}

export const parseProviderName = (
  providerName,
  providerType,
  primaryDegree?: string
) =>
  providerType === ProviderType.PRACTITIONER
    ? `${formatProviderNameWithFirstMiddleLastName(providerName)}${
        primaryDegree ? `, ${primaryDegree}` : ''
      }`
    : providerName;

export function commaInBetweenLastAndFirstName(providerName) {
  const splitName = providerName.split(' ');
  const firstName = splitName[1];
  const lastName = splitName[0];
  return `${lastName}, ${firstName}`;
}

export function capitalizeFirstLetters(words) {
  return words?.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`
  );
}

export function convertTimeToAmPm(time: string) {
  if (time?.length !== 5 || time[2] !== ':') return time;

  // compare as a string instead of casting to a number for resiliency
  const amTimes = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
  ];
  const pmTimes = [
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];

  if (time.slice(0, 2) === '00' || time.slice(0, 2) === '24')
    return `12:${time.slice(3, 5)} AM`;
  if (time.slice(0, 2) === '12') return time.concat(' PM');
  if (amTimes.includes(time.slice(0, 2))) {
    if (time[0] === '0') return time.slice(1, 5).concat(' AM');
    return time.slice(0, 5).concat(' AM');
  }

  if (pmTimes.includes(time.slice(0, 2))) {
    const convertedHour = amTimes[pmTimes.indexOf(time.slice(0, 2))];
    if (convertedHour[0] === '0')
      return convertedHour[1].concat(time.slice(2, 5).concat(' PM'));
    return convertedHour.concat(time.slice(2, 5).concat(' PM'));
  }
  return time;
}

export const getFormattedPhoneNumber = (phoneNumber: String) => {
  if (!phoneNumber || phoneNumber.length <= 3) {
    return phoneNumber;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(4)}`;
};

export const getUncommonElements = (array1, array2) => {
  const differenceInResults: string[] = [];
  array1.forEach((e1) => {
    if (!array2?.includes(e1)) {
      differenceInResults.push(e1);
    }
  });
  return differenceInResults;
};

function removeDuplicatePhones(filteredAllPhones, appointmentPhone) {
  if (
    filteredAllPhones.length > 1 &&
    filteredAllPhones[0] !== appointmentPhone[0]
  ) {
    return filteredAllPhones[0];
  }

  return filteredAllPhones[1];
}

export const getAdditionalPhone = (additionalPhone, appointmentPhone) => {
  if (additionalPhone && appointmentPhone) {
    const allPhones = [...additionalPhone, ...appointmentPhone];
    const filteredAllPhones = allPhones.filter(
      (item, index) => allPhones.indexOf(item) === index
    );
    const phoneNumber = removeDuplicatePhones(
      filteredAllPhones,
      appointmentPhone
    );
    return phoneNumber;
  }
  if (appointmentPhone?.length > 1 && !additionalPhone) {
    const filteredPhones = appointmentPhone.filter(
      (item, index) => appointmentPhone.indexOf(item) === index
    );
    const additional = filteredPhones[1];
    return additional;
  }

  if (additionalPhone?.length > 1 && !appointmentPhone) {
    const filteredPhones = additionalPhone.filter(
      (item, index) => additionalPhone.indexOf(item) === index
    );
    const additional = filteredPhones[1];
    return additional;
  }
  return null;
};

export const getPrimaryPhone = (phone, appointmentPhone) => {
  if (appointmentPhone) {
    return appointmentPhone[0];
  }
  if (!appointmentPhone && phone) {
    return phone[0];
  }
  return null;
};

export const getProviderResults = (
  sectionType,
  practitionerResults,
  useMockData = false
) => {
  let filteredPractitionerResults: any = [];
  let totalResultCount = 0;

  switch (sectionType) {
    case Constants.RESULT_SECTION.PROVIDER:
      totalResultCount = practitionerResults?.data?.totalCountPractitioners;
      filteredPractitionerResults = useMockData
        ? Constants.MOCK_RESULTS.providers
        : practitionerResults?.data?.providers;
      break;
    case Constants.RESULT_SECTION.FACILITY:
      totalResultCount = practitionerResults?.data?.totalCountOrganizations;
      filteredPractitionerResults = useMockData
        ? Constants.MOCK_RESULTS.facilities
        : practitionerResults?.data?.facilities;
      break;
    case Constants.RESULT_SECTION.PROVIDER_GROUPS:
      totalResultCount = practitionerResults?.data?.totalCountMedicalGroup;
      filteredPractitionerResults = useMockData
        ? MockProviderGroupResults
        : practitionerResults?.data?.medicalGroup;
      break;
    default:
      totalResultCount = MockProviderGroupResults.length;
      filteredPractitionerResults = MockProviderGroupResults;
      break;
  }

  return {
    error: practitionerResults?.error,
    results: filteredPractitionerResults,
    totalResultsCount: totalResultCount,
    isLoading: practitionerResults?.isLoading || practitionerResults?.loading,
  };
};

export const getProviderType = (providerType, sectionType) => {
  switch (sectionType) {
    case Constants.SEARCH_TYPES.PROVIDER:
      return ProviderTypes.PRACTITIONER;
    case Constants.SEARCH_TYPES.PROVIDER_GROUP:
      return ProviderTypes.MEDICAL_GROUP;
    case Constants.SEARCH_TYPES.FACILITY:
      return ProviderTypes.ORGANIZATION;
    default:
      return providerType;
  }
};

export const providerHasLocations = (
  providerDetails: ProviderDetails | FacilityDetails
): boolean => providerDetails?.providerLocations?.length > 1;

export const filterNotEmptyHoursOfOperations = (selectedLocation) =>
  selectedLocation?.hoursOfOperation?.filter(
    (str) => str.closingTime !== '' || str.openingTime !== ''
  );

/**
 * groupingClosingAndOpeningTime Fn
 *
 *
 * @param notEmptyHoursOfOperation -> An array with the hours of operation that are not empty.
 * PES return in some cases for some providers hours of operation empty so we have to deal with it.
 * i.e Monday hours of operation could potentially come empty since practitioner or facility is not open on Mondays
 *
 * @returns a grouped by day object with the hours of operation (opening and closing) time of the particular provider or an empty {} object that we expect in front-end to display " 🕔 Not Available "
 */
export const groupingClosingAndOpeningTime = (notEmptyHoursOfOperation): {} => {
  const { t } = useTranslation();
  const actualHoursOfOperationFiltered = notEmptyHoursOfOperation?.reduce(
    (group, p) => {
      const filteredGroup = { ...group };
      if (!filteredGroup[p.openingTime]) filteredGroup[p.openingTime] = {};

      if (!filteredGroup[p.openingTime][p.closingTime])
        filteredGroup[p.openingTime][p.closingTime] = [];
      const translatedDay = t(p.day);
      filteredGroup[p.openingTime][p.closingTime].push(translatedDay);
      return filteredGroup;
    },
    {}
  );

  return actualHoursOfOperationFiltered;
};

export const getLocationId = (tokenData) =>
  tokenData?.locationId ||
  storage.session.get(
    Constants.STORAGE_KEYS.SESSION.SELECTED_FACILITY_LOCATION_ID
  );

export const getProviderId = (tokenData) =>
  tokenData?.providerId || storage.session.get('selectedProviderID');

export const getPracticeLimitations = (startAge, endAge) => {
  if (startAge && endAge) {
    const practiceLimit = `${startAge}-${endAge}`;
    return practiceLimit;
  }

  if (!startAge && endAge) {
    const practiceLimit = `0-${endAge}`;
    return practiceLimit;
  }
  if (startAge && !endAge) {
    const practiceLimit = `${startAge}+`;
    return practiceLimit;
  }
  return 'Not Available';
};

export const getDdpCode = (code) => {
  const codeData = {
    R: 'Radiology',
    L: 'Lab',
    Imaging: 'Imaging',
    Lab: 'Lab',
    'Lab and Imaging': 'Lab and Imaging',
  };
  return codeData[code] || '';
};

export const getNetworkStatus = (status) =>
  NetworkStatus[status] ||
  Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE;

export const getSelectedLocationIndex = (providerLocations, selectedId) => {
  const selectedIdIndex = providerLocations?.findIndex(
    (location) => selectedId === location.locationId
  );

  return selectedIdIndex === undefined || selectedIdIndex === -1
    ? 0
    : selectedIdIndex;
};

export const getSnackCardDisplayValByLength = (reviewCardsCount): boolean => {
  const bSixCardDisplayCarousel = useMediaQuery(sixCardscarouselScreen);
  const bFiveCardsDisplayCarousel = useMediaQuery(carouselScreen);
  const bFourCardDisplayCarousel = useMediaQuery(fourCardscarouselScreen);
  const bThreeCardDisplayCarousel = useMediaQuery(threeCardscarouselScreen);
  const bTwoCardDisplayCarousel = useMediaQuery(twoCardscarouselScreen);
  const b = false;
  if (reviewCardsCount === 2) {
    return bTwoCardDisplayCarousel;
  }
  if (reviewCardsCount === 3) {
    return bThreeCardDisplayCarousel;
  }
  if (reviewCardsCount === 4) {
    return bFourCardDisplayCarousel;
  }
  if (reviewCardsCount === 5) {
    return bFiveCardsDisplayCarousel;
  }
  if (reviewCardsCount > 5) {
    return bSixCardDisplayCarousel;
  }
  return b;
};

export const getRecentSearchCarouselDisplayValByLength = (
  reviewCardsCount
): boolean => {
  const threeCardsCarousel = useMediaQuery(threeRecentSearchCardsScreen);
  const fourCardsCarousel = useMediaQuery(fourRecentSearchCardsScreen);
  const fiveCardsCarousel = useMediaQuery(fiveRecentSearchCardsScreen);
  if (reviewCardsCount === 3) {
    return threeCardsCarousel;
  }
  if (reviewCardsCount === 4) {
    return fourCardsCarousel;
  }
  if (reviewCardsCount === 5) {
    return fiveCardsCarousel;
  }
  if (reviewCardsCount >= 6) {
    return true;
  }
  return false;
};

export const getPatientCardLength = (reviewCardsCount): boolean => {
  const bFourCardsCarouselDisplay = useMediaQuery(
    reviewsFourCardsCarouselScreen
  );
  const bThreeCardsCarouselDisplay = useMediaQuery(
    reviewsThreeCardsCarouselScreen
  );
  const bTwoCardsCarouselDisplay = useMediaQuery(reviewsTwoCardsCarouselScreen);
  const l = false;
  if (reviewCardsCount === 2) {
    return bTwoCardsCarouselDisplay;
  }
  if (reviewCardsCount === 3) {
    return bThreeCardsCarouselDisplay;
  }
  if (reviewCardsCount >= 4) {
    return bFourCardsCarouselDisplay;
  }
  return l;
};

export const getProviderPCPId = (
  providerLocations: ProviderLocation[],
  providerId: string = '',
  memberPCPId: string = ''
): string => {
  if (!providerLocations && !providerId) {
    return '';
  }
  const providerPCPIdWithAddress = providerLocations?.[0]?.pcpId;

  if (providerPCPIdWithAddress) {
    return providerPCPIdWithAddress.split(' ')[0].trim();
  }

  let pcpId = providerId || '';
  const diff = (memberPCPId?.length || 0) - pcpId.length;

  // The provider id from PES and UES has limited leading zero's when compared to the response from OBAPI.
  // In-order for proper comparison of PCP, concat with leading zero's.
  for (let index = 0; index < diff; index++) {
    pcpId = `0${pcpId}`;
  }

  return pcpId;
};

export const getDetailsPathByType = (providerType: string) => {
  switch (ProviderType[providerType]) {
    case ProviderType.MEDICAL_GROUP:
    case ProviderType.PROVIDER_GROUP:
      return ConstantsRoutes.PROVIDER_GROUP_DETAILS.path;
    case ProviderType.ORGANIZATION:
      return ConstantsRoutes.FACILITY_DETAILS.path;
    default:
      return ConstantsRoutes.PROVIDER_DETAILS.path;
  }
};
