import { tokenizer } from '@abyss/web/tools/tokenizer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetters } from '../../../utils/providerDetails.utils';
import {
  Constants,
  NULL_RESULTS,
  TypeaheadBHType,
  TypeaheadProviderTypes,
  TypeaheadProviderTypesMapping,
} from '../../Constants';
import { ConstantsRoutes } from '../../ConstantsRoutes';
/* eslint-disable no-nested-ternary */
import {
  mappingRollUpCodeToCategory,
  mappingSpecialityRollUpCodeToCategory,
} from '../../RecentActivity/helper';
import {
  CircleIcon,
  CommonSearchList,
  DistanceIcon,
  ListItemSearch,
} from './SearchInputBox.styled';

export const setTypeAheadSearchResults = (item, searchTerm: string) => {
  const { t } = useTranslation();
  const getLink = (linkItem: { psxKeyword: string; displayName: string }) => {
    if (linkItem.psxKeyword) {
      return (
        <span>
          {linkItem.psxKeyword}
          {linkItem?.displayName === 'Dental' ||
          linkItem?.displayName === 'Vision' ? (
            <IconMaterial
              css={{
                'abyss-icon': {
                  marginBottom: '-5px',
                },
              }}
              icon="chevron_right"
              size={20}
            />
          ) : (
            ''
          )}
        </span>
      );
    }
    return null;
  };

  return (
    <div style={item?.psxKeyword ? CommonSearchList : ListItemSearch}>
      {getLink(item) ||
        (item?.displayName ? (
          <div>
            {capitalizeFirstLetters(t(item?.displayName))}
            <div className="searchResults">
              {item?.providerType === TypeaheadProviderTypes.PRACTITIONER
                ? capitalizeFirstLetters(t(item?.category))
                : item?.providerType !== TypeaheadProviderTypes.PRACTITIONER &&
                  item?.category
                ? `in ${capitalizeFirstLetters(t(item?.category))} in ${
                    item?.providerType
                  }`
                : ''}
              <IconMaterial css={CircleIcon} icon="circle" />
              <IconMaterial css={DistanceIcon} icon="location_on" />
              {`${item.distance} mi`}
            </div>
          </div>
        ) : (
          <div>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: item?.suggestion?.replace(
                  new RegExp(`(${searchTerm})`, 'gi'),
                  '<span style="font-weight: 500; margin-left:-2px">$1</span>'
                ),
              }}
            />
            <div className="searchResults">
              {`in ${capitalizeFirstLetters(t(item?.vertical?.category))} in ${
                item?.vertical?.providerType
              }`}
            </div>
          </div>
        ))}
    </div>
  );
};

export const setTypeAheadResults = (
  suggestions,
  providers,
  facilities,
  network: string
) =>
  suggestions
    ?.map((item) => ({ ...item, showSearchIcon: true }))
    .concat(
      network && (providers?.length || facilities?.length)
        ? {
            section: Constants.TYPEAHEAD_SEARCH_HEADER,
            items: providers
              ?.concat(facilities)
              .map((item) => ({ ...item, showSearchIcon: false })),
          }
        : []
    );

export const setToken = (token, value) =>
  tokenizer.update(token, {
    providerId: value?.providerId,
    coverageType: value?.coverageType,
    chipValue: mappingSpecialityRollUpCodeToCategory(
      value?.speciality,
      value?.providerType
    ),
  });

export const setNullToken = (term, analyticsLinkName) =>
  tokenizer.create({
    originLinkNameForAnalytics: analyticsLinkName,
    search: term,
    searchType: '',
    searchMethod: 'typed',
    resultType: NULL_RESULTS,
  });

export const navigateToMixedResultsPage = (
  value,
  token,
  navigate,
  llmSearch
) => {
  let llmFlag: boolean = false;

  const rollUpCodes =
    value?.pesKeyword ||
    value?.vertical?.code ||
    value?.includeSpecialityRollupCodes;

  const rollUpCode = rollUpCodes?.split(',');

  const lagoonValues = llmSearch?.[0]?.rollUpCodes?.split(',');

  const flag = lagoonValues.find((lagValue) => rollUpCode?.includes(lagValue));

  if (flag) {
    llmFlag = true;
  }

  const updatedToken = tokenizer.update(token, {
    ...value,
    search: value?.psxKeyword || value?.suggestion || value?.search,
    category: value?.category,
    searchType: value?.searchType
      ? value?.searchType?.toLowerCase()
      : Constants.SEARCH_TYPES.SPECIALTY,
    choosePCP: false,
    chipValue:
      mappingRollUpCodeToCategory(
        value?.pesKeyword || value?.includeSpecialityRollupCodes
      ) || TypeaheadProviderTypesMapping[value?.vertical?.providerType],
    includeSpecialityRollupCodes: rollUpCodes,
    originLinkNameForAnalytics: value?.linkName,
    pesKeyword: value?.pesKeyword,
    searchMethod: value?.searchMethod,
    searchTerm: value?.searchTerm,
    coverageType: value?.coverageType,
    resultType:
      value?.resultType === NULL_RESULTS
        ? NULL_RESULTS
        : Constants.RESULT_SECTION.ALL,
    llmFlag,
    keyword: value?.psxKeyword ? value?.psxKeyword : value?.searchTerm,
    pcpIndicator: false,
  });

  navigate(`/results/${updatedToken}`);
};

export const handleSearchSelected = (value, token, navigate, llmSearch) => {
  if (value?.providerType === TypeaheadProviderTypes.PRACTITIONER) {
    navigate(
      `${ConstantsRoutes.PROVIDER_DETAILS.path}${setToken(token, value)}`
    );
  } else if (
    value?.providerType === TypeaheadProviderTypes.FACILITY ||
    value?.providerType === TypeaheadProviderTypes.MEDICAL_HEALTH_SUPPLIES ||
    (value?.providerType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
      value?.providerId?.startsWith(TypeaheadBHType.FAC))
  ) {
    navigate(
      `${ConstantsRoutes.FACILITY_DETAILS.path}${setToken(token, value)}`
    );
  } else if (
    value?.providerType === TypeaheadProviderTypes.PROVIDER_GROUP ||
    (value?.providerType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
      value?.providerId?.startsWith(TypeaheadBHType.GRP))
  ) {
    navigate(
      `${ConstantsRoutes.PROVIDER_GROUP_DETAILS.path}${setToken(token, value)}`
    );
  } else {
    navigateToMixedResultsPage(value, token, navigate, llmSearch);
  }
};

export const handleRandomSearch = (navigate, searchTerm, analyticsLinkName) => {
  navigate(
    `${ConstantsRoutes.NULL_SEARCH_RESULTS.path}/${setNullToken(
      searchTerm,
      analyticsLinkName
    )}`
  );
};
