import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import find from 'lodash/find';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChipCategoryContext } from '../../../context/ChipCategoryContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useDeviceLocation } from '../../../hooks/useDeviceLocation';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../hooks/useLagoon';
import { useLocation } from '../../../hooks/useLocation';
import { useTypeAheadQuery } from '../../../hooks/useTypeAheadQuery';
import {
  getCoverageTypes,
  getCurrentMember,
  getMemberCoverage,
} from '../../../utils/user.utils';
import { adobeImpressionTrackEvent } from '../../AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants, NULL_RESULTS } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { mobileOnly } from '../../ConstantsStyles';
import {
  getNameForLinkTrack,
  getTypeAheadCategory,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { Breadcrumb } from '../Breadcrumb';
import { LocationInput } from '../LocationInput';
import {
  getGeoLocationFromStorage,
  setGeoLocationToStorage,
} from '../SearchBar/utils';
import {
  SearchInputContainer,
  StyledSearchInput,
} from './SearchInputBox.styled';
import { Shimmer } from './Shimmer';
import {
  handleRandomSearch,
  handleSearchSelected,
  setTypeAheadResults,
  setTypeAheadSearchResults,
} from './TypeaheadSearch';

type Props = {
  choosePCPHeader?: boolean;
  form: {
    formState: {
      isSubmitted: boolean;
    };
    setValue: (
      a: string,
      b: string,
      { shouldValidate, shouldDirty }?: any
    ) => void;
    clearErrors: (a: string) => void;
  };
  memberLocation?: string;
  breadcrumbs?: any[];
  showSearchInputBackButton?: boolean;
};

let [long, lat] = ['', ''];

export const SearchInputBox = ({
  choosePCPHeader = false,
  form,
  memberLocation = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.MEMBER_LOCATION
  ) ?? '',
  breadcrumbs = [],
  showSearchInputBackButton = false,
}: Props) => {
  const { clearSearchFilters } = useContext(SearchFilterContext);
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(mobileOnly);
  const { DEVICE_LOCATION, CUSTOM_LOCATION, MEMBER_LOCATION } =
    Constants.STORAGE_KEYS.SESSION;
  const { LOCATION, ZIPCODE } = Constants.STORAGE_KEYS.LOCAL;
  const resultLimit = Constants.COMMON_SEARCH_SECTION.RESULT_LIMIT;

  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { search = '' } = tokenData;

  const data = useLagoon('ui-messaging')();
  const searchLabelText = find(data, {
    key: ConstantsLagoon.SEARCH_LABEL,
  });

  const [decemberFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED,
  ]);
  const [captureResults] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.AUTOCOMPLETE_CAPTURE_RESULTS,
  ]);

  const { setChipValue } = useContext(ChipCategoryContext);
  const commonSearchesLagoon: () => any = useLagoon('common-searches');
  const commonSearchesData = commonSearchesLagoon();
  const currentMember = getCurrentMember();
  const language = getLanguage()?.code || 'en';
  const [results, setResults] = useState<any>(null);
  const [enter, setEnter] = useState(false);
  const memberCoverages = getCoverageTypes(currentMember);
  const dentalCoverage = memberCoverages?.find((cov) => cov === 'D');
  const visionCoverage = memberCoverages?.find((cov) => cov === 'V');
  const [currentListItemIndex, setCurrentListItemIndex] = useState(-1);
  const [typeaheadData, setTypeaheadData] = useState<any>(null);
  const llmSearch = useLagoon(Constants.LAGOON_TABLE.LLM_SEARCH)();
  let searchText: string;
  let network: string;

  const commonSearchData = [
    dentalCoverage
      ? {
          psxKeyword: t('DENTAL_SEARCH'),
          displayName: Constants.CHIPS_CATEGORIES.DENTAL,
        }
      : null,
    visionCoverage
      ? {
          psxKeyword: t('VISION_SEARCH'),
          displayName: Constants.CHIPS_CATEGORIES.VISION,
        }
      : null,
    {
      section: t('COMMON_SEARCH'),
      items: commonSearchesData
        ?.slice(0, resultLimit)
        .map((item) => ({ ...item, showSearchIcon: true })),
    },
  ];

  const commonSearchResults = commonSearchData.filter((val) => val != null);
  const [searchTerm, setSearchterm] = useState('');
  const [loading, setLoading] = useState(false);

  const [location, setLocation] = useState<string>(
    storage.session.get(CUSTOM_LOCATION) ??
      storage.session.get(DEVICE_LOCATION) ??
      storage.session.get(MEMBER_LOCATION) ??
      ''
  );
  const [deviceLocation, setDeviceLocation] = useState<string>(
    storage.session.get(DEVICE_LOCATION) ?? ''
  );
  const { getDeviceLocation } = useDeviceLocation(setDeviceLocation);

  // We are unable to get latitide and longitude from useSessionStorage so I am setting it globally for now.
  const { name } = getGeoLocationFromStorage();

  const [, getLocation] = useLocation({
    onCompleted: (result: {
      data: {
        location: {
          features: [
            { center: string[]; place_name: string; stateCode: string }
          ];
        };
      };
    }) => {
      [long, lat] = result?.data?.location?.features?.[0]?.center || [];
      const placeName =
        result?.data?.location?.features?.[0]?.place_name || location;
      const stateCode = result?.data?.location?.features?.[0]?.stateCode;
      // set geo coords
      setGeoLocationToStorage({
        name: placeName,
        longitude: long,
        latitude: lat,
        stateCode,
      });
      // set zipcode
      form?.setValue('userZip', placeName?.slice(-20, -15));
      storage.local.set(ZIPCODE, placeName?.slice(-20, -15));
      // set user selected location
      if (storage.session.get(CUSTOM_LOCATION)) {
        storage.session.set(CUSTOM_LOCATION, placeName);
      }
    },
  });

  const searchInputOptionLocation = 'search-input-option-list';

  useEffect(() => {
    if (!location) {
      getDeviceLocation();
    }
  }, []);

  useEffect(() => {
    if (!location && deviceLocation) {
      setLocation(deviceLocation);
    }
    if (!deviceLocation) {
      getDeviceLocation();
    }
    if (deviceLocation && !storage.session.get(CUSTOM_LOCATION)) {
      setLocation(deviceLocation);
    }
  }, [deviceLocation]);

  useEffect(() => {
    if (!location && memberLocation) {
      setLocation(memberLocation);
    }
    if (!storage.session.get(CUSTOM_LOCATION) && !deviceLocation) {
      setLocation(memberLocation);
    }
  }, [memberLocation]);

  useEffect(() => {
    storage.session.set(LOCATION, location);
    if (location && name !== location) {
      getLocation({
        variables: {
          address: location,
          countySearchEnabled: decemberFlag,
        },
      });
    }
  }, [location]);

  const setSelectedLocation = (loc) => {
    setLocation(loc);
  };

  const searchTimeOut: any = null;

  const setFormValues = (suggestions, providers, facilities, searchInput) => {
    form?.setValue('searchTerm', searchText);
    form?.setValue('resultType', Constants.RESULT_SECTION.ALL);
    if (!suggestions?.length && !providers?.length && !facilities?.length) {
      form?.setValue('resultType', NULL_RESULTS);
      form?.setValue('search', searchText);
    } else if (suggestions?.length) {
      form?.setValue('search', suggestions[0]?.suggestion);
      form?.setValue(
        'includeSpecialityRollupCodes',
        suggestions[0]?.vertical?.code
      );
      form?.setValue('searchType', Constants.SEARCH_TYPES.SPECIALTY);
      form?.setValue('typedSearchInput', searchInput);
      form?.setValue('coverageType', suggestions[0]?.coverageType);
    } else if (providers?.length) {
      form?.setValue('providerType', providers[0]?.providerType);
      form?.setValue('providerId', providers[0]?.providerId);
      form?.setValue('coverageType', providers[0]?.coverageType);
      form?.setValue('speciality', providers[0]?.speciality);
    } else if (facilities?.length) {
      form?.setValue('providerType', facilities[0]?.providerType);
      form?.setValue('providerId', facilities[0]?.providerId);
      form?.setValue('coverageType', facilities[0]?.coverageType);
      form?.setValue('orgCode', facilities[0]?.orgCode);
    }
  };

  const [, GetSearchSuggestions] = useTypeAheadQuery({
    onCompleted: (response) => {
      const suggestions =
        response?.data?.typeAhead?.lang_provider?.langProvider;
      const providers = response?.data?.typeAhead?.practitioners_uhc?.provData;
      const facilities = response?.data?.typeAhead?.organizations_uhc?.orgData;
      setLoading(false);
      const parsedResults = setTypeAheadResults(
        suggestions,
        providers,
        facilities,
        network
      );
      clearTimeout(searchTimeOut);
      const searchInput = form.getValues('search');
      setFormValues(suggestions, providers, facilities, searchInput);
      setTypeaheadData(
        suggestions
          ?.splice(0, 4)
          .concat(providers?.splice(0, 4))
          .concat(facilities?.splice(0, 4))
      );
      setResults(parsedResults);
      adobeImpressionTrackEvent({
        searchTerm: searchText,
        type: 'typeahead search',
        message:
          parsedResults.length === 0
            ? 'no results found for your search'
            : 'results found for your search',
      });
    },
    onError: () => {
      setLoading(false);
      clearTimeout(searchTimeOut);
      setResults([]);
    },
  });

  const handleSearch = (currentValue: string) => {
    setSearchterm(currentValue.trim());
    searchText = currentValue.trim();
    if (currentValue.replace(/\s/g, '').length > 1) {
      setLoading(true);
      network = getMemberCoverage(currentMember);
      GetSearchSuggestions({
        variables: {
          query: currentValue.trim(),
          network,
          latitude: lat,
          longitude: long,
          lang: language,
          captureResults,
        },
      });
    } else {
      clearTimeout(searchTimeOut);
      setLoading(false);
      setResults(null);
    }
  };

  const onChipClicked = (value, coverageType) => {
    adobeLinkTrackEvent({
      name: value,
      location: `body:${searchInputOptionLocation}`,
    });
    const updatedToken = tokenizer.update(token, {
      chipValue: value,
      coverageType,
    });
    setChipValue(value);
    navigate(`/${updatedToken}`);
  };

  const handleSelected = (value, isEnterKey = false) => {
    const analyticsLinkNameOnEnter = 'search enter';
    clearSearchFilters();
    if (!value) {
      adobeLinkTrackEvent({
        name: analyticsLinkNameOnEnter,
        location: `body:${searchInputOptionLocation}`,
        type: 'internal',
        destinationUrl: '',
      });
      handleRandomSearch(navigate, searchTerm, analyticsLinkNameOnEnter);
    } else if (value.displayName === Constants.CHIPS_CATEGORIES.DENTAL) {
      onChipClicked(value.displayName, value.coverageType);
    } else if (value.displayName === Constants.CHIPS_CATEGORIES.VISION) {
      onChipClicked(value.displayName, value.coverageType);
    } else {
      const index = results?.findIndex(
        (item) =>
          item.vertical?.category.toLowerCase() ===
          value?.vertical?.category.toLowerCase()
      );

      const adjustedIndex = index + 1;
      const searchName = getNameForLinkTrack(
        value?.suggestion ||
          value?.displayName ||
          `common search:${value?.psxKeyword}`,
        value?.providerType
      );
      const searchLocation = `body:${searchInputOptionLocation}:${getTypeAheadCategory(
        value?.providerType
      )}`;

      const analyticsLinkName = isEnterKey
        ? analyticsLinkNameOnEnter
        : searchName?.toLowerCase();
      adobeLinkTrackEvent({
        name: analyticsLinkName,
        location: searchLocation,
        type: 'internal',
        destinationUrl: '',
        searchBlock: {
          linkPosition: adjustedIndex || value?.key || '1',
        },
      });

      let searchMethod;
      if (isEnterKey) {
        searchMethod = 'typed';
      } else if (value?.psxKeyword) {
        searchMethod = 'guided';
      } else {
        searchMethod = 'predictive';
      }
      handleSearchSelected(
        {
          ...value,
          searchTerm,
          linkName: analyticsLinkName,
          searchMethod,
        },
        token,
        navigate,
        llmSearch
      );
    }
  };

  const handleEnter = () => {
    const currentIndex = currentListItemIndex === -1;
    const psxSearchKeyword = currentIndex
      ? typeaheadData?.[0]?.suggestion
      : typeaheadData?.[currentListItemIndex]?.suggestion;
    const searchTypeValue = form.getValues('searchType');
    const resultTypeValue = form.getValues('resultType');
    const includeSpecialityRollupCodes = currentIndex
      ? typeaheadData?.[0]?.vertical?.code
      : typeaheadData?.[currentListItemIndex]?.vertical?.code;

    const isDisabled = searchTerm?.length <= 1 || loading;
    setEnter(true);

    if (!isDisabled) {
      const searchValue = {
        psxKeyword: psxSearchKeyword,
        searchType: searchTypeValue,
        pesKeyword: includeSpecialityRollupCodes,
        resultType: resultTypeValue,
        search: searchTerm,
      };
      const provData = currentIndex
        ? typeaheadData?.[0]
        : typeaheadData?.[currentListItemIndex];
      const value = searchValue?.psxKeyword ? searchValue : provData;
      const isEnterKey = true;
      handleSelected(value, isEnterKey);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleEnter();
    }
    if (e.key === 'ArrowDown') {
      const index = (currentListItemIndex + 1) % typeaheadData?.length;
      setCurrentListItemIndex(index);
    }
    if (e.key === 'ArrowUp' && currentListItemIndex > 0) {
      setCurrentListItemIndex(currentListItemIndex - 1);
    }
  };

  const setMessage = () => {
    let message: string;
    if (!/[A-Za-z0-9]/.test(searchTerm) && searchTerm !== '') {
      message = t('Special char search');
    } else if (enter && searchTerm?.length <= 1) {
      message = t('Short search');
    } else {
      message = t('No Results Found');
    }

    return message;
  };

  return (
    <Layout.Stack
      className="hide-scrollbar"
      css={{
        backgroundColor: '#FBFCFE',
        marginBottom: '0px',
        scrollbarColor:
          'transparent transparent' /* Hide scrollbar for Firefox */,
        /**
         * This CSS class override div styled + add pseudo class to hide the horizontal scrollbar,
         * but keet its functionality.
         * Only for this div container.
         * */
        '&.hide-scrollbar': {
          overflow: 'auto',
          scrollBehavior: 'smooth',
        },
        '&.hide-scrollbar::-webkit-scrollbar': {
          display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
        },
      }}
      grow
    >
      <Flex
        alignItems="center"
        css={{
          'abyss-flex-root': {
            flexWrap: 'nowrap',
            paddingTop: '0px',
            minWidth:
              mobileScreen && showSearchInputBackButton ? '396px' : null,
          },
        }}
        direction="row"
        variant="custom"
      >
        {mobileScreen && showSearchInputBackButton ? (
          <Breadcrumb breadcrumbs={breadcrumbs} />
        ) : null}
        <SearchInputContainer
          css={
            choosePCPHeader && {
              '@screen < $md': {
                height: '42px',
              },
            }
          }
        >
          <StyledSearchInput
            activeIndex={currentListItemIndex}
            apiFiltering={handleSearch}
            css={{
              '.abyss-search-input-section': {
                backgroundColor: 'white',
                borderTop: 'solid 1px #D0D0D0',
              },
              '.abyss-search-input-label': {
                display: 'inline',
                fontWeight: 700,
                fontSize: '12.64px',
                paddingLeft: '10px',
                paddingTop: '8px',
              },
              '.abyss-search-input-left-element-wrapper': {
                zIndex: 0,
              },
            }}
            customLoader={<Shimmer />}
            customNoOptionsMessage={setMessage()}
            customRender={(item) =>
              item.section ? (
                <div>{item.section}</div>
              ) : (
                setTypeAheadSearchResults(item, searchTerm)
              )
            }
            data-auto-testid="search-input-box"
            data-testid="search-input-box"
            debounceTime={400}
            enterClick={enter}
            hideLabel={choosePCPHeader && mobileScreen}
            inputLeftElement={
              <IconMaterial
                color={choosePCPHeader && mobileScreen && '#196ECF'}
                icon="search"
                size={mobileScreen ? 20 : 24}
              />
            }
            isLoading={loading}
            keys={['value', 'psxKeyword', 'suggestion']}
            label={searchLabelText?.message ?? ''}
            model="search"
            onInputChange={(inputValue, isArrowKeyDown) => {
              setEnter(false);
              if (!isArrowKeyDown) {
                setLoading(true);
              }

              form?.setValue('search', inputValue, {
                shouldValidate: true,
                shouldDirty: true,
              });

              if (inputValue.length >= 0) {
                setLoading(false);
              }
            }}
            onKeyDown={handleKeyDown}
            onSearch={handleSelected}
            openOnFocus
            options={results || commonSearchResults}
            placeholder={search || t('SEARCH_PLACEHOLDER')}
            resultLimit={15}
            rounded
            searchTerm={searchTerm}
          />
        </SearchInputContainer>
        {!mobileScreen && (
          <LocationInput
            data-auto-testid="search-home-user-location-desktop"
            data-testid="search-home-user-location-desktop"
            location={location}
            searchTerm={searchTerm}
            setLocation={setSelectedLocation}
          />
        )}
      </Flex>
      {mobileScreen && (
        <LocationInput
          data-auto-testid="search-home-user-location-mobile"
          data-testid="search-home-user-location-mobile"
          location={location}
          searchTerm={searchTerm}
          setLocation={setSelectedLocation}
        />
      )}
    </Layout.Stack>
  );
};
