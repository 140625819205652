import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import mapboxgl from 'mapbox-gl';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import translationsEnJson from '../../../../../public/locale/en/translation.json';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import { Constants, PAGE_SIZE } from '../../../../common/Constants';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import {
  phoneOnly,
  providerFloatDesktopClosed,
  providerFloatDesktopOpen,
  providerFloatDesktopOpenSelected,
  providerFloatMobile,
} from '../../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../../common/ContentWrapper';
import { HealthGradesDisclaimer } from '../../../../common/Healthgrades/HealthGradesDisclaimer';
import { PSXHeader } from '../../../../common/PSXHeader';
import {
  convertProviderTypeToAdobeType,
  getFormattedFilters,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { ErrorBoundary } from '../../../../errors/ErrorBoundary';
import { useCompareProviders } from '../../../../hooks/useCompareProviders';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useFilterProviderResults } from '../../../../hooks/useProviderSearch';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { ProviderSearchFilters } from '../../../../models/ProviderSearch';
import {
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../../../utils/user.utils';
import { getLanguage } from '../../context/Internationalization/helpers';
import { MapView } from '../MapView';
import { AllPaginationProps } from '../MapView/AllPaginationProps';
import { CompareDrawer } from '../MapView/CompareDrawer';
import { CompareDrawerMobile } from '../MapView/CompareDrawer/CompareDrawerMobile';
import {
  CompareCheckboxInfo,
  bhSpecificItemsArray,
  defaultItemsArray,
} from '../MapView/CompareDrawer/utility/compareDrawerConstants';
import { CompareDrawerProps } from '../MapView/CompareDrawerProps';
import { ShareDrawer } from '../MapView/ShareDrawer/ShareDrawer';
import { ShareDrawerMobile } from '../MapView/ShareDrawer/ShareDrawerMobile';
import { HealthGradesContainerStyled } from '../PSXHome/HealthGradesContainer.styled';
import { MobileListView } from './MobileListView';
import { successToast } from './Toast';

const PageContainer = styled('div', {
  padding: '0px',
});

const MapViewWrapper = styled('div', {
  maxWidth: '1440px',
  marginLeft: 'calc((100vw - 1200px)/2) ',
  marginRight: '24px',
  padding: '24px 0',

  '@media (max-width: 1248px)': {
    marginLeft: '24px',
    marginRight: '24px',
  },
  '@screen < $md': {
    marginLeft: '16px',
    marginRight: '16px',
    padding: '16px 0',
  },
  '@screen < $sm': {
    padding: '0',
    margin: '0',
  },
});

const selectedItemFloatSize = (mobileScreen, openBoxContents, selected) => {
  if (mobileScreen) {
    return providerFloatMobile;
  }
  if (openBoxContents && selected.length === 0) {
    return providerFloatDesktopOpen;
  }
  if (openBoxContents && selected.length > 0) {
    return providerFloatDesktopOpenSelected;
  }
  if (!openBoxContents) {
    return providerFloatDesktopClosed;
  }
  return null;
};

export const ViewAll = () => {
  const { t } = useTranslation();

  const { searchFilters: selectedFilters } = useContext(SearchFilterContext);
  const [filters, setFilters] =
    useState<ProviderSearchFilters>(selectedFilters);
  const [navigateToDirections, setNavigateToDirections] = useState(false);
  const map = React.useRef<mapboxgl.Map>(null);
  const mobileScreen = useMediaQuery(phoneOnly);
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const coverageType = useCoverageType();
  const [items, setItems] = useState<CompareCheckboxInfo[]>(
    coverageType === 'B' ? bhSpecificItemsArray : defaultItemsArray
  );

  const {
    useMockData = false,
    sectionType = '',
    search = '',
    searchTerm = '',
    searchMethod,
    providerType = '',
    specialtyCode,
    includeSpecialityRollupCodes,
    category,
    searchType,
    pcpIndicator = false,
    includeOrgTypeCodes,
    choosePCP = false,
    requestType = '',
    linkName,
    searchRadius,
    virtualCare = false,
    medicalSpeciality,
    llmFlag = false,
    keyword = '',
  } = tokenData;

  const [openMapview, setOpenMapview] = useState<boolean>(!mobileScreen);
  const [mobileRouteView, setMobileRouteView] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(selectedFilters.PageNumber || 1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const pageSizeOptions = [10, 15, 20];
  const containerRef = useRef<HTMLDivElement>(null);

  const currentMember = getCurrentMember();

  if (virtualCare) {
    selectedFilters.VirtualCare = {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.VIRTUAL_CARE'),
      value: virtualCare,
      hide: !virtualCare,
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.VIRTUAL_CARE,
    };
  }

  const {
    results = [],
    totalResultsCount,
    isLoading = false,
    headers,
  } = useFilterProviderResults({
    useMockData,
    sectionType,
    search,
    providerType,
    specialtyCode: specialtyCode || includeSpecialityRollupCodes,
    category,
    searchType,
    pcpIndicator,
    pageNumber,
    pageSize,
    includeOrgTypeCodes,
    requestType,
    searchRadius,
    selectedFilters,
    llmFlag,
    keyword,
  });

  const { state, gotoPage, nextPage, previousPage, ...paginationProps } =
    usePagination({
      pages: Math.ceil(totalResultsCount / pageSize),
      start: pageNumber,
    });

  const { currentPage } = state;
  const { firstPage } = paginationProps;

  useEffect(() => {
    if (pageNumber !== currentPage) {
      setPageNumber(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (JSON.stringify(selectedFilters) !== JSON.stringify(filters)) {
      setFilters(selectedFilters);
      firstPage();
    }
  }, [JSON.stringify(selectedFilters)]);

  useEffect(() => {
    setOpenMapview(!mobileScreen);
  }, [mobileScreen]);
  useEffect(() => {
    if (openMapview && mobileScreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openMapview, mobileScreen]);

  const serializedSelectedFilters = getFormattedFilters(selectedFilters);
  useEffect(() => {
    if (totalResultsCount === 0 && medicalSpeciality) {
      navigate(`${ConstantsRoutes.NULL_SPECIALTY_RESULTS.path}/${token}`);
    } else if (totalResultsCount !== undefined) {
      adobeStandardSearchEvent({
        linkName,
        filters: serializedSelectedFilters,
        numberOfResults: totalResultsCount,
        method: searchMethod ?? 'guided',
        customAttributesBlock: {
          providerType: convertProviderTypeToAdobeType(providerType),
        },
        term: searchTerm,
      });
    }
  }, [results]);

  const [breadcrumbUrls, setBreadcrumbUrls] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const parseBreadcrumbs = (urls) => {
    const sectionTypeCondition =
      sectionType === Constants.RESULT_SECTION.PROVIDER
        ? t('Provider results')
        : t('Provider group results');
    const breadcrumbs = [
      {
        title:
          sectionType === Constants.RESULT_SECTION.FACILITY
            ? t('Facility results')
            : sectionTypeCondition,
        href: '',
      },
    ];
    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]) {
      breadcrumbs.unshift({
        title: t('BC Results'),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]}`,
      });
    }
    return breadcrumbs;
  };

  const breadcrumbs = parseBreadcrumbs(breadcrumbUrls);

  useEffect(() => {
    breadcrumbUrls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key] =
      window.location.pathname.replace('/findcare', '');
    setBreadcrumbUrls(breadcrumbUrls);
  }, []);

  const [selectedCheckbox, setSelectedCheckbox] = useState({ checked: {} });
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const [openCompare, setOpenCompare] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [openBoxContents, setOpenBoxContents] = useState<boolean>(
    !!selectedItems.length
  );
  const [isShareAllResults, setIsShareAllResults] = useState<boolean>(false);
  const toastMessage = t('Your results have been shared');
  const closeAllDrawersAndOpenSuccessAlert = () => {
    setOpenCompare(false);
    setOpenShare(false);
    // after integration, check for success here
    successToast(toastMessage);
  };

  // begin: a11y keyboard navigation
  const { compareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const { indexOflastProviderSelected, shouldFocusLastIndex } =
    compareProvidersFlow;

  useEffect(() => {
    const cards = document.querySelectorAll("input[type='checkbox']");
    if (cards && shouldFocusLastIndex) {
      const lastSelectedCard = cards[
        indexOflastProviderSelected
      ] as HTMLInputElement;
      if (lastSelectedCard) lastSelectedCard?.focus();
    }
  }, [openCompare, shouldFocusLastIndex]);

  const { shareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const {
    indexOflastShareProviderSelected,
    shouldFocusLastShareProviderIndex,
  } = shareProvidersFlow;

  useEffect(() => {
    const cards = document.querySelectorAll("input[type='checkbox']");
    if (cards && shouldFocusLastIndex) {
      const lastSelectedCard = cards[
        indexOflastShareProviderSelected
      ] as HTMLInputElement;
      if (lastSelectedCard) lastSelectedCard?.focus();
    }
  }, [openShare, shouldFocusLastShareProviderIndex]);
  // end: a11y keyboard navigation

  const [, getCompareDetails] = useCompareProviders({
    onCompleted: (result) => result,
    onError: () => {},
  });

  const { stateCode } = useGeoLocationStorage();

  const addSelectedProvider = async (providerData) => {
    const index = selectedItems.findIndex(
      (provider) => provider.locationId === providerData.locationId
    );
    if (index === -1) {
      const locale = getLanguage()?.code || 'en';
      const result = await getCompareDetails({
        variables: {
          locale,
          providerId: providerData.providerId,
          providerType: providerData.providerType,
          latitude: providerData.latitude || '',
          longitude: providerData.longitude || '',
          stateCode,
          locationId: providerData.locationId || '',
          reciprocityId: getNetworkIdsForPES(currentMember, coverageType),
          planVariationCode: getPlanVariationCode(currentMember, coverageType),
          planYear: getCurrentPlanYear(),
          policyId: currentMember?.policyNumber,
          coverageType,
        },
      });

      const compareProviderData = result.data.compare.provider;
      let finalData = providerData;
      if (compareProviderData) {
        finalData = { ...providerData, ...compareProviderData };
      }
      setSelectedItems((prevState) => [...prevState, finalData]);
    } else {
      const selected = selectedItems;
      selected.splice(index, 1);
      setSelectedItems([...selected]);
    }
  };

  const removeItem = (locationId: string) => {
    setSelectedCheckbox((prevState) => ({
      checked: {
        ...prevState.checked,
        [locationId]: !prevState.checked[locationId],
      },
    }));
    const selectedProvider = selectedItems.find(
      (provider) => provider.locationId === locationId
    );

    addSelectedProvider(selectedProvider); // To remove selected provider from compare drawer
  };

  const showShareFloatingSection = () =>
    openShare ? (
      <FloatingSection
        alwaysFloat
        css={selectedItemFloatSize(
          mobileScreen,
          openBoxContents,
          selectedItems
        )}
        data-auto-testid="mapview-share-drawer"
        data-testid="mapview-share-drawer"
        position="bottom"
      >
        {mobileScreen ? (
          <ShareDrawerMobile
            compareCheckboxes={items}
            data-auto-testid="drawer-share-mobile"
            data-testid="drawer-share-mobile"
            isShareAllResults={isShareAllResults}
            openBoxContents={openBoxContents}
            openShare={openShare}
            removeItem={removeItem}
            selected={selectedItems.length}
            selectedProviderList={selectedItems}
            setIsShareAllResults={setIsShareAllResults}
            setOpenBoxContents={setOpenBoxContents}
            setOpenShare={setOpenShare}
            setSelectedCheckbox={setSelectedCheckbox}
            setSelectedItems={setSelectedItems}
            showSuccessAlertCallback={closeAllDrawersAndOpenSuccessAlert}
            total={5}
          />
        ) : (
          <ShareDrawer
            compareCheckboxes={items}
            data-auto-testid="drawer-share-desktop"
            data-testid="drawer-share-desktop"
            isShareAllResults={isShareAllResults}
            map={map}
            openBoxContents={openBoxContents}
            openShare={openShare}
            removeItem={removeItem}
            selected={selectedItems.length}
            selectedProviderList={selectedItems}
            setIsShareAllResults={setIsShareAllResults}
            setOpenBoxContents={setOpenBoxContents}
            setOpenShare={setOpenShare}
            setSelectedCheckbox={setSelectedCheckbox}
            setSelectedItems={setSelectedItems}
            showSuccessAlertCallback={closeAllDrawersAndOpenSuccessAlert}
          />
        )}
      </FloatingSection>
    ) : null;
  const showCompareFloatingSection = () =>
    openCompare ? (
      <FloatingSection
        containerRef={containerRef}
        css={mobileScreen ? providerFloatMobile : providerFloatDesktopOpen}
        data-auto-testid="mapview-compare-drawer"
        data-testid="mapview-compare-drawer"
        isOpen={openCompare}
        onClose={() => {
          setOpenCompare(false);
          setSelectedCheckbox({ ...selectedCheckbox });
        }}
        position="bottom"
        size={mobileScreen ? window.innerHeight - 104 : 464}
      >
        {mobileScreen ? (
          <CompareDrawerMobile
            data-auto-testid="drawer-compare"
            data-testid="drawer-compare"
            headers={headers}
            items={items}
            removeItem={removeItem}
            selectedCount={selectedItems.length}
            selectedProviders={selectedItems}
            setItems={setItems}
            setOpenCompare={setOpenCompare}
            setSelectedCheckbox={setSelectedCheckbox}
            setSelectedProviders={setSelectedItems}
            total={5}
          />
        ) : (
          <CompareDrawer
            data-auto-testid="drawer-compare"
            data-testid="drawer-compare"
            headers={headers}
            items={items}
            map={map}
            removeItem={removeItem}
            selectedCount={selectedItems.length}
            selectedProviders={selectedItems}
            setItems={setItems}
            setOpenCompare={setOpenCompare}
            setSelectedCheckbox={setSelectedCheckbox}
            setSelectedProviders={setSelectedItems}
            total={5}
          />
        )}
      </FloatingSection>
    ) : null;

  const compareDrawerProps: CompareDrawerProps = {
    map,
    openCompare,
    setOpenCompare,
    selectedItems,
  };

  const allPaginationProps: AllPaginationProps = {
    pageSize,
    pageSizeOptions,
    paginationProps,
    setPageSize,
    gotoPage,
    nextPage,
    previousPage,
    pageNumber,
  };

  return (
    <div ref={containerRef}>
      <ErrorBoundary>
        {!mobileRouteView &&
          (!choosePCP ? (
            <PSXHeader
              breadcrumbs={breadcrumbs}
              dataTestId="view-all-on-submit"
              showChips={false}
              showChoosePCPHeader={false}
              showMemberSelection={!mobileScreen}
              showSearchInputBackButton={mobileScreen}
            />
          ) : (
            <PSXHeader
              breadcrumbs={breadcrumbs}
              dataTestId="view-all-on-submit"
              showChips={false}
              showChoosePCPHeader
              showMemberSelection={false}
            />
          ))}
        <PageContainer>
          <React.Fragment>
            <MapViewWrapper>
              <MapView
                addSelectedProvider={addSelectedProvider}
                allPaginationProps={allPaginationProps}
                compareDrawerProps={compareDrawerProps}
                headers={headers}
                isLoading={isLoading}
                mobileRouteView={mobileRouteView}
                navigateToDirections={navigateToDirections}
                openMapview={openMapview}
                openShare={openShare}
                providerType={providerType}
                results={results}
                search={search}
                searchTerm={searchTerm}
                sectionType={sectionType}
                selectedCheckbox={selectedCheckbox}
                selectedFilters={serializedSelectedFilters}
                setMobileRouteView={setMobileRouteView}
                setNavigateToDirections={setNavigateToDirections}
                setOpenMapview={setOpenMapview}
                setOpenShare={setOpenShare}
                setSelectedCheckbox={setSelectedCheckbox}
                setSelectedItems={setSelectedItems}
                totalResultsCount={totalResultsCount}
              />
              {showShareFloatingSection()}
              {showCompareFloatingSection()}
            </MapViewWrapper>
            {mobileScreen ? (
              <ContentWrapper>
                <MobileListView
                  addSelectedProvider={addSelectedProvider}
                  allPaginationProps={allPaginationProps}
                  gotoPage={gotoPage}
                  headers={headers}
                  map={map}
                  mobileRouteView={mobileRouteView}
                  navigateToDirections={navigateToDirections}
                  nextPage={nextPage}
                  openCompare={openCompare}
                  openMapview={openMapview}
                  openShare={openShare}
                  pageSize={pageSize}
                  paginationProps={paginationProps}
                  previousPage={previousPage}
                  results={results}
                  search={search}
                  searchTerm={searchTerm}
                  sectionType={sectionType}
                  selectedCheckbox={selectedCheckbox}
                  selectedFilters={serializedSelectedFilters}
                  setNavigateToDirections={setNavigateToDirections}
                  setOpenCompare={setOpenCompare}
                  setOpenMapview={setOpenMapview}
                  setOpenShare={setOpenShare}
                  setSelectedCheckbox={setSelectedCheckbox}
                  setSelectedItems={setSelectedItems}
                  totalResultsCount={totalResultsCount}
                />
              </ContentWrapper>
            ) : null}
          </React.Fragment>
        </PageContainer>
        {/*  */}
        {sectionType !== Constants.RESULT_SECTION.FACILITY &&
          sectionType !== Constants.RESULT_SECTION.PROVIDER_GROUPS && (
            <HealthGradesContainerStyled css={{ marginTop: '0px' }}>
              <HealthGradesDisclaimer hideDivider />
            </HealthGradesContainerStyled>
          )}
      </ErrorBoundary>
    </div>
  );
};
