import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Alert } from '@abyss/web/ui/Alert';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs } from '@abyss/web/ui/Tabs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { HealthGradesContainerStyled } from '../../frontends/ProviderSearch/routes/PSXHome/HealthGradesContainer.styled';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useRate } from '../../hooks/useRate';
import { useSaveRecentSearchesResults } from '../../hooks/useRecentSearches';
import { FacilityDetails } from '../../models/FacilityDetails';
import { ProviderDetails, ProviderType } from '../../models/ProviderDetails';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants, RecentSearchesSearchTypes } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { FacilityDetailsAboutSection } from '../FacilityDetailsTabs/FacilityDetailsAboutSection';
import { FacilityLocation } from '../FacilityDetailsTabs/FacilityLocation';
import { FacilityQuality } from '../FacilityDetailsTabs/FacilityQuality/FacilityQuality';
import { HealthGradesDisclaimer } from '../Healthgrades/HealthGradesDisclaimer';
import { ProviderGroupAbout } from '../ProviderGroupDetailTabs/ProviderGroupAbout';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import { translatedTabTitle } from '../Utils/translatedTabTitle.util';
import { About } from './About/About';
import { Cost } from './Cost/Cost';
import { PhysicianDirectory } from './PhysicianDirectory';
import {
  Container,
  TabContainer,
  TabsContainer,
} from './ProviderDetailTabs.styled';
import { ProviderLocationsDetails } from './ProviderLocationsDetails/ProviderLocationsDetails';
import { Quality } from './Quality/Quality';

type Props = {
  providerDetails?: ProviderDetails;
  facilityDetails?: FacilityDetails;
  providerGroupDetails?: ProviderDetails;
  chipValue: string;
  navigateToDirections: boolean;
  setNavigateToDirections: (a: boolean) => void;
  isTiered?: boolean;
  isMapView?: boolean;
  coverageType: string;
  setIsMapView: (a: boolean) => void;
};

export const ProviderDetailTabs = ({
  providerDetails,
  facilityDetails,
  providerGroupDetails,
  navigateToDirections,
  chipValue,
  coverageType,
  isTiered,
  isMapView = false,
  setNavigateToDirections,
  setIsMapView,
}: Props) => {
  const [disableCost] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_COST,
  ]);

  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);

  const locationTabIndex =
    facilityDetails?.providerType === ProviderType.ORGANIZATION ? 0 : 1;
  const [active, setActive] = useState(
    navigateToDirections ? locationTabIndex : 0
  );
  const [displayAlert, setDisplayAlert] = useState(false);

  const { longitude, latitude, name: location } = getGeoLocationFromStorage();

  const { BEHAVIORAL_HEALTH_TYPECODES } = Constants.PROVIDER_DETAILS;
  const isBehavioralHealthProvider =
    BEHAVIORAL_HEALTH_TYPECODES.includes(coverageType);

  useEffect(() => {
    if (navigateToDirections) {
      setActive(locationTabIndex);
    }
  }, [navigateToDirections]);

  let recentSearchArgs = {
    psxSearchTerm: '',
    pesSearchTerm: '',
    searchType: '',
    category: chipValue,
    location,
    latitude,
    longitude,
    coverageType,
  };

  const boardCertifications = providerDetails?.boardCertifications;
  const [rateData, setRateData] = useRate({});
  useEffect(() => {
    if (providerDetails && !disableCost) {
      const { providerId } = providerDetails;
      setRateData({
        variables: {
          providerId,
        },
      });
    }
  }, [providerDetails, disableCost]);

  const displayTabs =
    !boardCertifications?.length && !providerDetails?.providerLocations?.length
      ? Constants.PROVIDER_DETAILS.TABS_LIST.filter((tab) => tab !== 'Quality')
      : Constants.PROVIDER_DETAILS.TABS_LIST;

  if (providerDetails?.providerType === ProviderType.PRACTITIONER) {
    recentSearchArgs = {
      ...recentSearchArgs,
      psxSearchTerm: providerDetails.providerName,
      pesSearchTerm: providerDetails.providerId,
      searchType: RecentSearchesSearchTypes.PRACTITIONER_DETAIL,
    };
  } else if (facilityDetails?.providerType === ProviderType.ORGANIZATION) {
    recentSearchArgs = {
      ...recentSearchArgs,
      psxSearchTerm: facilityDetails.providerName,
      pesSearchTerm: facilityDetails.providerId,
      searchType: RecentSearchesSearchTypes.ORGANIZATION_DETAIL,
    };
  } else if (providerGroupDetails) {
    recentSearchArgs = {
      ...recentSearchArgs,
      psxSearchTerm: providerGroupDetails.providerName,
      pesSearchTerm: providerGroupDetails.providerId,
      searchType: RecentSearchesSearchTypes.MEDICAL_GROUP_DETAIL,
    };
  }

  const activeTabs: string[] = [];
  const handleTabChange = (index) => {
    if (index !== 1) {
      setNavigateToDirections(false);
    }
    setActive(index);

    adobeLinkTrackEvent({
      name: activeTabs[index],
      location: `body:details tabs`,
      type: 'tab',
    });
  };

  useSaveRecentSearchesResults(recentSearchArgs);

  return (
    <Container>
      {displayAlert && !(isMapView && mobileScreen) && (
        <ContentWrapper>
          <Alert
            onClose={() => {
              setDisplayAlert(false);
            }}
            title={t('LOCATION_IS_UPDATED')}
            variant="success"
          />
        </ContentWrapper>
      )}
      <Layout.Stack
        css={{
          'abyss-layout-stack': {
            margin: '0px',
          },
        }}
        grow
        space={0}
      >
        <TabsContainer
          key="provider-detail-tabs-list"
          active={active}
          cssProps={{ isMapView }}
          grow={false}
          onTabChange={handleTabChange}
          title={t('PROVIDER_DETAILS.TAB_TITLE')}
          variant="line"
        >
          {providerDetails?.providerType === ProviderType.PRACTITIONER
            ? displayTabs.map((tabTitle: string) => {
                if (tabTitle === Constants.PROVIDER_DETAILS.COST && disableCost)
                  return null;
                activeTabs.push(tabTitle);
                return (
                  <Tabs.Tab
                    key={tabTitle}
                    data-auto-testid={tabTitle}
                    data-testid={tabTitle}
                    label={t(translatedTabTitle('PROVIDER_DETAILS', tabTitle))}
                    value={t(translatedTabTitle('PROVIDER_DETAILS', tabTitle))}
                  >
                    <TabContainer>
                      {tabTitle === Constants.PROVIDER_DETAILS.ABOUT && (
                        <About
                          coverageType={coverageType}
                          providerDetails={providerDetails}
                          tabTitle={tabTitle}
                        />
                      )}
                      {tabTitle === Constants.PROVIDER_DETAILS.LOCATIONS && (
                        <ProviderLocationsDetails
                          isMapView={isMapView}
                          isTiered={isTiered}
                          navigateToDirections={navigateToDirections}
                          providerDetails={providerDetails}
                          setIsMapView={setIsMapView}
                          setNavigateToDirections={setNavigateToDirections}
                          setUpdateLocation={setDisplayAlert}
                          tabTitle={tabTitle}
                        />
                      )}
                      {tabTitle === Constants.PROVIDER_DETAILS.COST && (
                        <Cost rateData={rateData?.data?.rate} />
                      )}
                      {tabTitle === Constants.PROVIDER_DETAILS.QUALITY && (
                        <Quality
                          boardCertifications={boardCertifications}
                          providerDetails={providerDetails}
                          tabTitle={tabTitle}
                        />
                      )}
                      <HealthGradesContainerStyled>
                        <HealthGradesDisclaimer
                          hideDivider
                          showPremiumCareDisclaimer={
                            !isBehavioralHealthProvider
                          }
                        />
                      </HealthGradesContainerStyled>
                    </TabContainer>
                  </Tabs.Tab>
                );
              })
            : null}
          {facilityDetails?.providerType === ProviderType.ORGANIZATION
            ? Constants.FACILITY_DETAILS.TABS_LIST.map((tabTitle: string) => {
                const [selectedId] = useSessionStorage<string>(
                  Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
                  ''
                );
                const selectedIdIndex =
                  facilityDetails?.providerLocations?.findIndex(
                    (locationData) => selectedId === locationData.locationId
                  );
                const facilityIndex =
                  selectedIdIndex === undefined || selectedIdIndex === -1
                    ? 0
                    : selectedIdIndex;

                activeTabs.push(tabTitle);
                return (
                  <Tabs.Tab
                    key={tabTitle}
                    data-auto-testid={tabTitle}
                    data-testid={tabTitle}
                    label={t(translatedTabTitle('FACILITY_DETAILS', tabTitle))}
                    value={t(translatedTabTitle('FACILITY_DETAILS', tabTitle))}
                  >
                    <TabContainer>
                      {tabTitle === Constants.FACILITY_DETAILS.ABOUT && (
                        <FacilityDetailsAboutSection
                          coverageType={coverageType}
                          facilityDetails={facilityDetails}
                          tabTitle={tabTitle}
                        />
                      )}
                      {tabTitle === Constants.FACILITY_DETAILS.LOCATIONS && (
                        <FacilityLocation
                          coverageType={coverageType}
                          facilityDetails={facilityDetails}
                          isMapView={isMapView}
                          navigateToDirections={navigateToDirections}
                          setIsMapView={setIsMapView}
                          setNavigateToDirections={setNavigateToDirections}
                          setUpdateLocation={setDisplayAlert}
                          tabTitle={tabTitle}
                        />
                      )}
                      {tabTitle ===
                        Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY && (
                        <PhysicianDirectory
                          providerId={facilityDetails.providerId}
                          providerType={facilityDetails.providerType}
                        />
                      )}
                      {tabTitle === Constants.FACILITY_DETAILS.QUALITY && (
                        <FacilityQuality
                          facilityDetails={facilityDetails}
                          selectedLocationIndex={facilityIndex}
                          tabTitle={tabTitle}
                        />
                      )}
                    </TabContainer>
                  </Tabs.Tab>
                );
              })
            : null}
          {providerGroupDetails
            ? Constants.PROVIDER_GROUP_DETAILS.TABS_LIST.map(
                (tabTitle: string) => {
                  activeTabs.push(tabTitle);
                  return (
                    <Tabs.Tab
                      key={tabTitle}
                      data-auto-testid={tabTitle}
                      data-testid={tabTitle}
                      label={t(
                        translatedTabTitle('PROVIDER_GROUP_DETAILS', tabTitle)
                      )}
                      value={t(
                        translatedTabTitle('PROVIDER_GROUP_DETAILS', tabTitle)
                      )}
                    >
                      <TabContainer>
                        {tabTitle ===
                          Constants.PROVIDER_GROUP_DETAILS.ABOUT && (
                          <ProviderGroupAbout
                            coverageType={coverageType}
                            providerDetails={providerGroupDetails}
                            tabTitle={tabTitle}
                          />
                        )}

                        {tabTitle === Constants.PROVIDER_DETAILS.LOCATIONS && (
                          <ProviderLocationsDetails
                            isMapView={isMapView}
                            navigateToDirections={navigateToDirections}
                            providerDetails={providerGroupDetails}
                            setIsMapView={setIsMapView}
                            setNavigateToDirections={setNavigateToDirections}
                            setUpdateLocation={setDisplayAlert}
                            tabTitle={tabTitle}
                          />
                        )}
                        {tabTitle ===
                          Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY && (
                          <PhysicianDirectory
                            providerId={providerGroupDetails?.providerId}
                            providerType={providerGroupDetails?.providerType}
                          />
                        )}
                      </TabContainer>
                    </Tabs.Tab>
                  );
                }
              )
            : null}
        </TabsContainer>
      </Layout.Stack>
    </Container>
  );
};
