import { useTranslation } from 'react-i18next';

import {
  Constants,
  FilterCollectionModel,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';

const { PROVIDER, PROVIDER_GROUPS, FACILITY } = Constants.RESULT_SECTION;
const BEHAVIORAL_HEALTH = ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];
const { MEDICAL } = ReverseCoverageTypesCodes;

export const useProviderFilters = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('CONTENT_FILTERS.DISTANCE'),
      model: FilterCollectionModel.DISTANCE,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
      },
      allFilterDrawerOrder: 4,
      chipFiltersOrder: 1,
      options: [1, 5, 10, 25, 50, 100].map((value) => ({
        label: `${value} ${t('CONTENT_FILTERS.MILE')}${value > 1 ? 's' : ''}`,
        value,
        dnt_label: `${value} miles`,
      })),
    },
    {
      label: t('CONTENT_FILTERS.PATIENT_REVIEWS'),
      model: FilterCollectionModel.PATIENT_REVIEWS,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
      },
      allFilterDrawerOrder: 5,
      chipFiltersOrder: 2,
      options: [
        {
          label: t('CONTENT_FILTERS.5_STARS'),
          value: '5',
          dnt_label: '5 Stars',
        },
        ...['4', '3', '2'].map((value) => ({
          label: `${value} ${t('CONTENT_FILTERS.STARS_AND_UP')}`,
          value,
          dnt_label: `${value} Stars and up`,
        })),
        {
          label: t('CONTENT_FILTERS.1_STAR'),
          value: '1',
          dnt_label: '1 Star and up',
        },
      ],
    },
    {
      label: t('CONTENT_FILTERS.SPECIALTY'),
      model: FilterCollectionModel.SPECIALTY_CODE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
      },
      allFilterDrawerOrder: 6,
      chipFiltersOrder: 6,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.AREAS_OF_EXPERTISE'),
      model: FilterCollectionModel.AREA_OF_EXPERTISE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
      },
      allFilterDrawerOrder: 8,
      chipFiltersOrder: 10,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.FACILITY_TYPE'),
      model: FilterCollectionModel.FACILITY_TYPE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [FACILITY],
        [BEHAVIORAL_HEALTH]: [FACILITY],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [FACILITY],
        [BEHAVIORAL_HEALTH]: [FACILITY],
      },
      allFilterDrawerOrder: 6,
      chipFiltersOrder: 5,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.EVENING_WEEKENDS'),
      model: FilterCollectionModel.EVENING_AND_WEEKEND,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
      },
      allFilterDrawerOrder: 11,
      chipFiltersOrder: 10,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.HEALTH_SUPPLIES_OFFERED'),
      model: FilterCollectionModel.HEALTH_SUPPLIES_OFFERED,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [FACILITY],
        [BEHAVIORAL_HEALTH]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [FACILITY],
        [BEHAVIORAL_HEALTH]: [],
      },
      allFilterDrawerOrder: 12,
      chipFiltersOrder: 7,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.ACCESSIBILITY'),
      model: FilterCollectionModel.ACCESSIBILITY_CODE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
      },
      allFilterDrawerOrder: 13,
      chipFiltersOrder: 10,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.LANGUAGE'),
      model: FilterCollectionModel.LANGUAGE_CODE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
      },
      allFilterDrawerOrder: 14,
      chipFiltersOrder: 11,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.GENDER'),
      model: FilterCollectionModel.GENDER_CODE,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
      },
      allFilterDrawerOrder: 15,
      chipFiltersOrder: 10,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.ETHNICITY'),
      model: FilterCollectionModel.ETHNICITY_CODE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
      },
      allFilterDrawerOrder: 16,
      chipFiltersOrder: 10,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.PROVIDER_GROUP'),
      model: FilterCollectionModel.MEDICAL,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
      },
      allFilterDrawerOrder: 17,
      chipFiltersOrder: 3,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.HOSPITAL_AFFILIATIONS'),
      model: FilterCollectionModel.HOSPITAL_AFFILIATIONS,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
      },
      allFilterDrawerOrder: 18,
      chipFiltersOrder: 10,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.ACCEPTING_NEW_PATIENTS'),
      model: FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
      },
      allFilterDrawerOrder: 19,
      chipFiltersOrder: 10,
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.PROGRAMS'),
      model: FilterCollectionModel.BEHAVIORAL_PROGRAM_INDICATOR,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
      },
      allFilterDrawerOrder: 7,
      chipFiltersOrder: 8,
      options: [],
    },
  ];
};
