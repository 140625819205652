import { useEffect } from 'react';

import { useAdobePageTrackEvent } from '../../hooks/adobeHook/useAdobePageTrackEvent';
import { CardWrapper } from '../CardWrapper';
import { CommonlySearchedCard } from '../CommonlySearched';
import { RECOMMENDED_PROVIDER, SUGGESTED_PROVIDER } from '../Constants';
import { StillNeedHelp } from '../StillNeedHelp';
import { SuggestedProviders } from '../SuggestedProviders/SuggestedProviders';
import { NoResult } from './NoResult';
import {
  CommonlySearchedStyled,
  NullPageContainerStyled,
  TopRecommendedStyled,
} from './NullResultsPage.styles';
import { TopRecommendedProvider } from './TopRecommendedProvider';

export type NullResultsPageProps = {
  searchTitle: string;
  specialtyCode: string;
  emptyResults: boolean;
};

export const NullResultsPage = ({
  searchTitle,
  specialtyCode,
  emptyResults,
}: NullResultsPageProps) => {
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'null results',
    sitesectionLevel1: 'search results',
    sitesectionLevel2: 'category',
  });

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  return (
    <NullPageContainerStyled>
      <NoResult
        data-auto-testid="null-page-abyss-box-root"
        data-testid="null-page-abyss-box-root"
        searchTitle={searchTitle}
      />
      {specialtyCode === SUGGESTED_PROVIDER ? <SuggestedProviders /> : null}
      {specialtyCode === SUGGESTED_PROVIDER && emptyResults ? (
        <TopRecommendedStyled>
          <SuggestedProviders />
        </TopRecommendedStyled>
      ) : null}
      {specialtyCode !== RECOMMENDED_PROVIDER && emptyResults ? (
        <TopRecommendedStyled>
          <TopRecommendedProvider />
        </TopRecommendedStyled>
      ) : null}
      {!emptyResults && (
        <CardWrapper>
          {specialtyCode === RECOMMENDED_PROVIDER ? (
            <TopRecommendedProvider />
          ) : null}
        </CardWrapper>
      )}
      <CommonlySearchedStyled>
        <CommonlySearchedCard />
      </CommonlySearchedStyled>
      <StillNeedHelp />
    </NullPageContainerStyled>
  );
};
