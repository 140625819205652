import { styled } from '@abyss/web/tools/styled';
import { Badge } from '@abyss/web/ui/Badge';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';

export const LocationCardWrapper = styled('button', {
  background: '$primary2',
  border: '1px solid #E5E5E6',
  boxShadow: '0px 1px 4px rgba(25, 25, 26, 0.16)',
  borderRadius: '12px',
  cursor: 'pointer',
  width: '408px',
  textAlign: 'left',
  padding: '16px 16px 16px 12px',
  '@screen < $sm': {
    height: 'auto',
  },
  '&.highlight': {
    border: '3px solid $secondary1',
  },
  '&.location-card-map-view': {
    width: '320px',
    '.location-details': {
      '>div': {
        marginBottom: 0,
        '&:last-child': {
          marginTop: '10px',
        },
      },
    },
  },
});

export const LocationCardGroup = styled(Layout.Group, {
  '&.abyss-layout-group': {
    alignItems: 'flex-start',
  },
});

export const LocationDetailsStack = styled(Layout.Stack, {
  '&.abyss-layout-stack': {
    flexGrow: '1',
    marginTop: '-7px',
    lineHeight: '23px',
    '@screen < $sm': {
      lineHeight: '18px',
    },
  },
  '>span': {
    overflowWrap: 'anywhere',
    width: '100%',
  },
});

export const LocationBadgeRow = styled(Flex, {
  height: '20px',
});

export const AcceptingNewPatientsBadge = styled(Badge, {
  fontSize: '12px',
});

export const StyledCheckIcon = styled('div', {
  height: '24px',
});

export const UpdateButtonMobileView = styled(Button, {
  '&.abyss-button-root': {
    display: 'flex',
    padding: '8px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    height: '42px',
    fontWeight: '$semibold',
    fontSize: '16px',
    lineHeight: '20px',
    '.abyss-insert-element-after': {
      paddingLeft: 0,
    },
  },
});
