import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton } from '@abyss/web/ui/Skeleton';

export const SnackCardSkeleton = () => (
  <Layout.Stack alignItems="center" alignLayout="center" space="9">
    <Skeleton height={72} variant="circular" width={72} />
    <Skeleton height={16} variant="rounded" width={150} />
    <Skeleton height={10} variant="rounded" width={143} />
    <Skeleton height={12} variant="rounded" width={100} />
    <Skeleton height={12} variant="rounded" width={72} />
  </Layout.Stack>
);
