import { storage } from '@abyss/web/tools/storage';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import update from 'lodash/update';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../../hooks/useLagoon';
import { useLocation } from '../../../hooks/useLocation';
import { adobeImpressionTrackEvent } from '../../AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { getFeatureFlag } from '../../Utils';
import { refactorPlaceName } from '../../Utils/locationSearch.util';
import {
  FindUserLocationContainer,
  StyledLocationError,
  StyledLocationHeader,
  StyledLocationItems,
  StyledLocationSearch,
} from './LocationSearchDesktop.styled';

type Props = {
  findUserLocation: Function;
  location: string;
  setLocation: (a: string) => void;
};

export const LocationSearchDesktop = ({
  findUserLocation,
  location = storage.session.get(Constants.STORAGE_KEYS.LOCAL.LOCATION),
  setLocation,
}: Props) => {
  const { t } = useTranslation();
  const [isOnFocus, setIsOnFocus] = useState(false);
  const locationSearchInputDataTestId: string = 'location-search-input';
  const locationForAnalytics: string = 'change search location';
  const featureFlags: [{ key: string; active: boolean }] = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_FLAGS
  )();
  const decemberFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );

  const [inputVal, setInputVal] = useState('');
  let typeAheadLocationTextForAnalytics;
  const [isLoading, setIsLoading] = useState(false);
  const [gqlSuggestions, setgqlSuggestions] = useState<object[]>([
    {
      section: decemberFlag ? (
        <div id="instructional-text">
          {t('LOCATION_SEARCH.HEADER_DECEMBER_RELEASE')}
        </div>
      ) : (
        <div id="instructional-text">{t('LOCATION_SEARCH.HEADER')}</div>
      ),
      items: [],
    },
  ]);

  const [, getLocation] = useLocation({
    onCompleted: (result: {
      data: {
        location: {
          features: [{ place_name: string; id: string }];
        };
      };
    }) => {
      const resultSuggestions = cloneDeep(result?.data?.location?.features);

      adobeImpressionTrackEvent({
        searchTerm: typeAheadLocationTextForAnalytics,
        type: 'location search',
        message:
          resultSuggestions.length > 0
            ? 'results found for your search'
            : 'no results found for your search',
      });

      resultSuggestions?.map((loc) =>
        update(loc, 'place_name', () => refactorPlaceName(loc.place_name))
      );
      const findSuggestion =
        resultSuggestions?.length > 0 &&
        resultSuggestions.concat({
          place_name: 'find',
          id: 'find',
        });

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !isEmpty(resultSuggestions)
        ? setgqlSuggestions([
            {
              section: decemberFlag
                ? t('LOCATION_SEARCH.HEADER_DECEMBER_RELEASE')
                : t('LOCATION_SEARCH.HEADER'),
              items: findSuggestion,
            },
          ])
        : setgqlSuggestions([
            {
              section: decemberFlag
                ? t('LOCATION_SEARCH.HEADER_DECEMBER_RELEASE')
                : t('LOCATION_SEARCH.HEADER'),
              items: [],
            },
            {
              section: decemberFlag
                ? t('LOCATION_SEARCH.ERR_DEC_RELEASE')
                : t('LOCATION_SEARCH.ERROR'),
              items: [],
            },
          ]);
    },
  });

  const handleLocationChange = async (loc: string) => {
    typeAheadLocationTextForAnalytics = loc;
    await getLocation({
      variables: {
        address: loc,
        countySearchEnabled: decemberFlag,
      },
    });
  };

  const debounceApiFilteringSearch = useCallback(
    debounce(async (inputValue: string) => {
      if (inputValue?.trim().length) {
        await handleLocationChange(inputValue);
      }
      setIsLoading(false);
    }, 300),
    [inputVal]
  );

  const adobeLinkTrackEventFindLocation = () =>
    adobeLinkTrackEvent({
      name: Constants.LOCATION_SEARCH.FIND_MY_LOCATION,
      location: `body:${locationForAnalytics}`,
      type: 'internal',
    });

  const adobeLinkTrackEventSuggestedLocation = () =>
    adobeLinkTrackEvent({
      name: 'suggested location',
      location: `body:${locationForAnalytics}`,
      type: 'internal',
    });

  const handleClear = () => {
    setgqlSuggestions([
      {
        section: decemberFlag
          ? t('LOCATION_SEARCH.HEADER_DECEMBER_RELEASE')
          : t('LOCATION_SEARCH.HEADER'),
        items: [],
      },
    ]);
    setInputVal('');
  };

  const handleLocationInputChange = (inputValue) => {
    if (inputValue?.trim().length) {
      setIsLoading(true);
      setInputVal(inputValue);
    } else {
      handleClear();
    }
  };

  useEffect(() => {
    // Remove <span> element with role="status" for a11y fix
    const statusElement = document.querySelector('span[role="status"]');
    if (statusElement) {
      statusElement?.parentNode?.removeChild(statusElement);
    }

    // Find the element based on its test id
    const element = document.querySelector(
      '[data-testid="location-search-input-abyss-search-input-input"]'
    );

    // Check if the element exists
    if (element) {
      // Add the data-describedby attribute with a value
      element.setAttribute('aria-describedby', 'instructional-text');
    }
  }, [isOnFocus]);

  const handleSearch = (newLocation) => {
    if (newLocation.place_name === 'find') {
      findUserLocation();
      adobeLinkTrackEventFindLocation();
    } else {
      setLocation(newLocation.place_name);
      adobeLinkTrackEventSuggestedLocation();
    }
  };

  return (
    <StyledLocationSearch
      apiFiltering={debounceApiFilteringSearch}
      customRender={(suggestion) =>
        // eslint-disable-next-line no-nested-ternary
        suggestion.section ? (
          suggestion.section !== t('LOCATION_SEARCH.ERR_DEC_RELEASE') &&
          suggestion.section !== t('LOCATION_SEARCH.ERROR') ? (
            <StyledLocationHeader>
              {suggestion.section}
              {!inputVal.includes('Search') && suggestion.items?.length > 0
                ? t(`LOCATION_SEARCH.DID_YOU_MEAN`)
                : ''}
            </StyledLocationHeader>
          ) : (
            <StyledLocationError alignItems="top">
              <IconMaterial color="$error1" icon="error" size="$sm" />
              <Text color="$error1" size="14.22px">
                {suggestion.section}
              </Text>
            </StyledLocationError>
          )
        ) : suggestion.place_name !== 'find' ? (
          <StyledLocationItems grow space={0}>
            <Text color="$accent1" fontWeight="$bold" size="$sm">
              {suggestion.place_name}
            </Text>
          </StyledLocationItems>
        ) : (
          <StyledLocationItems>
            <FindUserLocationContainer
              data-auto-testid="find-user-location-clickable-element"
              data-testid="find-user-location-clickable-element"
              onClick={() => {
                handleClear();
                findUserLocation();
              }}
            >
              <Layout.Group space={4}>
                <IconMaterial
                  css={{ marginRight: '10px' }}
                  icon="my_location"
                />
                <Text color="$interactive1" fontWeight="$bold" size="sm">
                  {t('Find my location')}
                </Text>
              </Layout.Group>
            </FindUserLocationContainer>
          </StyledLocationItems>
        )
      }
      data-auto-testid={locationSearchInputDataTestId}
      data-testid={locationSearchInputDataTestId}
      hideLabel
      inputLeftElement={
        <IconMaterial color="$gray8" icon="location_on" size={24} />
      }
      isLoading={isLoading}
      keys={['value', 'location']}
      label={t('Location')}
      onClear={handleClear}
      onFocus={() => setIsOnFocus(true)}
      onInputChange={handleLocationInputChange}
      onSearch={handleSearch}
      openOnFocus
      options={gqlSuggestions}
      placeholder={
        location ||
        (decemberFlag
          ? t('LOCATION_SEARCH.PLACEHOLDER_DECEMBER_RELEASE')
          : t('LOCATION_SEARCH.PLACEHOLDER'))
      }
      rounded
      value={location}
    />
  );
};
