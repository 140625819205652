export const getSpecialityDescription = (specialties, specialityName) => {
  if (!specialityName) {
    return '';
  }

  const speciality = specialties?.find(
    (element) => element?.name?.toLowerCase() === specialityName.toLowerCase()
  );

  return speciality?.description || '';
};
export const getSpecialityPopOverContent = (specialties, specialityName) => {
  if (!specialityName) {
    return '';
  }

  const speciality = specialties?.find(
    (element) =>
      element?.heading?.toLowerCase() === specialityName.toLowerCase()
  );

  return speciality?.popOverContent || '';
};
