import { Link } from '@abyss/web/ui/Link';
import isNil from 'lodash/isNil';
import React, { ReactNode } from 'react';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import {
  CustomAttributesBlock,
  isExternalDomain,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';

type AnalyticsInfo = {
  destinationUrl?: string | null;
  location: string;
  name?: string | null;
  type?: string | null;
  customAttributesBlock?: CustomAttributesBlock | null;
};

type Props = {
  after?: ReactNode;
  before?: ReactNode;
  analyticsInfo?: AnalyticsInfo;
  'aria-label'?: string;
  children?: ReactNode;
  css?: any;
  'data-testid': string;
  'data-auto-testid': string;
  fontWeight?: string;
  hideIcon?: Boolean;
  href?: string | null;
  isStandardAnchor?: Boolean;
  onClick?: Function;
  size?: number | string;
  variant?: string;
  isDisabled?: Boolean;
};

export const LinkWithTracking = ({
  after,
  before,
  analyticsInfo,
  'aria-label': ariaLabel = '',
  children,
  css,
  'data-testid': testId = '',
  'data-auto-testid': dataAutoTestId = '',
  fontWeight,
  hideIcon = false,
  href = null,
  isStandardAnchor,
  onClick = () => {},
  size,
  variant,
  isDisabled = false,
}: Props) => {
  const onClickWithTracking = () => {
    if (!isNil(onClick)) onClick();

    function getDestinationUrl() {
      return analyticsInfo?.destinationUrl || href || '';
    }

    function getType() {
      const url = getDestinationUrl();
      if (url?.startsWith('tel')) {
        return 'phone';
      }
      if (url?.startsWith('email')) {
        return 'email';
      }
      if (url?.startsWith('phone:')) {
        return 'phone';
      }
      if (isExternalDomain(url)) {
        return 'external';
      }
      return 'internal';
    }

    adobeLinkTrackEvent({
      name: analyticsInfo?.name || testId,
      location: analyticsInfo?.location || testId,
      type: analyticsInfo?.type || getType(),
      destinationUrl: analyticsInfo?.destinationUrl || getDestinationUrl(),
      customAttributesBlock: analyticsInfo?.customAttributesBlock,
    });
  };

  return (
    <Link
      after={after}
      aria-label={ariaLabel}
      before={before}
      css={css}
      data-auto-testid={dataAutoTestId}
      data-testid={testId}
      fontWeight={fontWeight}
      hideIcon={hideIcon}
      href={href}
      isDisabled={isDisabled}
      isStandardAnchor={isStandardAnchor}
      onClick={onClickWithTracking}
      size={size}
      variant={variant}
    >
      {children}
    </Link>
  );
};
