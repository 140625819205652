import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChipCategoryContext } from '../../context/ChipCategoryContext';
import { useAdobePageTrackEvent } from '../../hooks/adobeHook/useAdobePageTrackEvent';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  CoverageTypesCodes,
  ReverseCoverageTypesCodes,
} from '../Constants';
import { notMobile } from '../ConstantsStyles';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import { CategoryChipsDrawer } from './CategoryChipsDrawer';
import {
  CareCategoriesText,
  ChipContainer,
  ChipMenuIcon,
  MenuList,
  Wrapper,
  WrapperStyle,
  chipSelectorDrawerMobile,
  chipsCategory,
  drawerHeadingStyle,
  hamburgerButton,
} from './ChipsCategory.styles';

interface ChipObject {
  label: string;
  value: string;
  id: string;
}
type Props = {
  chips: ChipObject[];
};

export const ChipsCategory = ({ chips }: Props) => {
  const desktopScreen = useMediaQuery(notMobile);
  const { t } = useTranslation();
  const { value: contextValue, setChipValue } = useContext(ChipCategoryContext);
  const { getRouteParams, navigate } = useRouter();
  const { token } = getRouteParams();
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);
  const [careCategory, setCareCategory] = useState(contextValue);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuListRef = useRef<HTMLElement>();
  const chipsRefs = useRef<HTMLDivElement[]>([]);
  chipsRefs.current = [];
  const [displayHamburgerMenuIcon, setDisplayHamburgerMenuIcon] =
    useState(false);
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: `${contextValue} overview`,
  });

  const addChipRef = (el) => {
    if (el && !chipsRefs.current.includes(el)) {
      chipsRefs.current.push(el);
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (containerRef.current != null) {
        setWindowSize(containerRef.current.offsetWidth);
      }
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    const updateScrollCategory = () => {
      setTimeout(() => {
        if (!desktopScreen && windowSize < desktopScreen) {
          const index = chips.findIndex((x) => x.value === contextValue);
          chipsRefs?.current[index]?.scrollIntoView({ inline: 'start' });
        }
      }, 800);
    };
    updateScrollCategory();
    window.addEventListener('resize', updateScrollCategory);
    return () => {
      debounce(
        () => window.removeEventListener('resize', handleWindowResize),
        400
      );
    };
  }, [windowSize]);

  useEffect(() => {
    if (shouldScroll) {
      containerRef?.current?.scrollIntoView({ block: 'center' });
    }
    if (isOpen) {
      menuListRef?.current?.focus();
      handleLinkAndModalTrack(
        Constants.CHIPS_CATEGORIES.TYPES_OF_CARE,
        'sub nav',
        Constants.CHIPS_CATEGORIES.TYPES_OF_CARE
      );
    }

    if (
      contextValue === 'Dental' ||
      contextValue === 'Vision' ||
      contextValue === 'Health Supplies'
    ) {
      const chipsLengthValue =
        contextValue === 'Dental' || contextValue === 'Vision' ? 1 : 4;
      if (!desktopScreen) {
        chipsRefs?.current[chips.length - chipsLengthValue]?.scrollIntoView({
          inline: 'start',
        });
      }
      containerRef?.current?.scrollIntoView({ block: 'center' });

      setCareCategory(contextValue);
    }
  }, [contextValue, isOpen, menuListRef]);

  useEffect(() => {
    const chipsContainer = document.querySelector(
      "[data-testid='chip-container']"
    ) as HTMLElement;

    setDisplayHamburgerMenuIcon(
      chipsContainer?.offsetWidth < chipsContainer?.scrollWidth
    );
  }, [windowSize]);

  useEffect(() => {
    adobePageTrackEvent();
  }, [contextValue]);

  const onChipClicked = (value, index, isResponsiveView?: boolean) => {
    adobeLinkTrackEvent({
      name: isResponsiveView ? 'change button' : value,
      location: isResponsiveView
        ? `modal:${Constants.CHIPS_CATEGORIES.TYPES_OF_CARE}`
        : 'sub-nav',
      type: 'internal',
    });

    if (index !== undefined) {
      chipsRefs?.current[index]?.scrollIntoView({ inline: 'start' });
    }

    if (value === Constants.CHIPS_CATEGORIES.ALL) {
      setChipValue(value);
      setCareCategory(value);
      navigate(`/`);
    } else {
      const selectedCoverageType =
        find(Constants.MOCK_CARE_CATEGORIES, {
          value,
        })?.coverageType || CoverageTypesCodes.MEDICAL;
      const updatedToken = tokenizer.update(token, {
        chipValue: value,
        coverageType: ReverseCoverageTypesCodes[selectedCoverageType],
      });
      setChipValue(value);
      setCareCategory(value);
      setShouldScroll(true);
      navigate(`/${updatedToken}`);
    }
  };

  const handleKeyDown = (value, index, e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onChipClicked(value, index);
    }
  };

  return (
    <React.Fragment>
      {!desktopScreen && isOpen && (
        <Drawer
          css={chipSelectorDrawerMobile}
          isOpen={isOpen}
          onClose={() => {
            adobeLinkTrackEvent({
              name: 'close',
              location: `modal:${Constants.CHIPS_CATEGORIES.TYPES_OF_CARE}`,
            });
            setIsOpen(false);
          }}
          position="bottom"
          title={
            <Heading css={drawerHeadingStyle} offset={3}>
              {t(Constants.CHIPS_CATEGORIES.TYPES_OF_CARE)}
            </Heading>
          }
        >
          <CategoryChipsDrawer
            careCategory={careCategory}
            chips={chips}
            onChipClicked={onChipClicked}
            setCareCategory={setCareCategory}
            setIsOpen={setIsOpen}
          />
        </Drawer>
      )}
      {desktopScreen && isOpen && displayHamburgerMenuIcon && (
        <MenuList
          ref={menuListRef}
          data-auto-testid="category-menu-list"
          data-testid="category-menu-list"
          id="category-menu-list"
          tabIndex="0"
        >
          <Flex justify="space-between">
            <CareCategoriesText>
              {t(Constants.CHIPS_CATEGORIES.TYPES_OF_CARE)}
            </CareCategoriesText>
            <Button
              css={{
                paddingTop: '6px',
                paddingBottom: '36px',
                paddingLeft: '8px',
                paddingRight: '0px',
              }}
              data-auto-testid="chips-menu-drawer-close"
              data-testid="chips-menu-drawer-close"
              onClick={() => {
                setIsOpen(false);
              }}
              variant="tertiary"
            >
              <IconMaterial icon="close" />
            </Button>
          </Flex>
          <CategoryChipsDrawer
            careCategory={careCategory}
            chips={chips}
            onChipClicked={onChipClicked}
            setCareCategory={setCareCategory}
            setIsOpen={setIsOpen}
          />
        </MenuList>
      )}
      <Flex
        css={{ display: 'flex', flexWrap: 'nowrap', paddingLeft: '0px' }}
        direction="row"
      >
        <Text css={chipsCategory} id="care-categories">
          {t('CARE_CATEGORIES')}
        </Text>
      </Flex>
      <Wrapper css={WrapperStyle} direction="row">
        {displayHamburgerMenuIcon && (
          <ChipMenuIcon>
            <Button
              aria-label={t('CARE_OPTIONS')}
              css={hamburgerButton}
              data-auto-testid="hamburger-icon-chips-header-container"
              data-testid="hamburger-icon-chips-header-container"
              onKeyDown={(e) => {
                if (e.key === ' ' || e.key === 'Enter') {
                  setIsOpen(true);
                }
              }}
              variant="ghost"
            >
              <IconMaterial
                color="$primary1"
                data-auto-testid="bulleted-icon-chips-list"
                data-testid="bulleted-icon-chips-list"
                icon="format_list_bulleted"
                onClick={() => {
                  setIsOpen(true);
                }}
                size="24px"
              />
            </Button>
          </ChipMenuIcon>
        )}
        <ChipContainer
          ref={containerRef}
          aria-labelledby="care-categories"
          className="hide-scrollbar"
          data-auto-testid="chip-container"
          data-testid="chip-container"
          role="group"
        >
          {chips.map((item: ChipObject, index) => (
            <React.Fragment>
              <Flex
                key={item.label}
                data-auto-testid={`${item.id}-chip`}
                data-testid={`${item.id}-chip`}
                value={item.value}
              >
                <Button
                  ref={addChipRef}
                  aria-label={t(item.label)}
                  aria-pressed={contextValue === item.value ? 'true' : 'false'}
                  className={contextValue === item.value ? 'active' : ''}
                  css={{
                    'abyss-chip-icon': {
                      marginLeft: '5px',
                    },
                    'abyss-chip-text': {
                      fontWeight: '$semibold',
                      fontSize: '$md',
                      lineHeight: '$md',
                      '@screen < $sm': {
                        fontWeight: '$bold',
                        fontSize: '14px',
                      },
                    },
                    backgroundColor: '$primary2',
                    padding: '11px 20px',
                    color: '$primary1',
                    cursor: 'pointer',
                    height: '40px',
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse',
                    '&:hover, &:focus, &.active': {
                      backgroundColor: '#002677',
                      color: '#FBFCFE',
                    },
                    '@screen < $sm': {
                      height: '32px',
                      padding: '6px $md',
                    },
                  }}
                  data-auto-testid={`chips-category-${item.id}-chip`}
                  data-testid={`chips-category-${item.id}-chip`}
                  onClick={(e) => {
                    onChipClicked(item.value, e);
                  }}
                  onKeyDown={(e) => {
                    handleKeyDown(item.value, index, e);
                  }}
                  outline
                  text={t(item.label)}
                >
                  <Text
                    color
                    css={{
                      'abyss-text-root': {
                        whiteSpace: 'nowrap',
                        fontWeight: '$semibold',
                        fontSize: '$md',
                        lineHeight: '$sm',
                        '@screen < $sm': {
                          fontWeight: '$bold',
                          fontSize: '14px',
                        },
                      },
                    }}
                  >
                    {t(item.label)}
                  </Text>
                </Button>
              </Flex>
            </React.Fragment>
          ))}
        </ChipContainer>
      </Wrapper>
    </React.Fragment>
  );
};
