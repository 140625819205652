/* eslint-disable react/prop-types */
import { Link } from '@abyss/web/ui/Link';

import { isLocationActiveSuggetion } from './LocationDropdown.styled';

// import { IChangeLocationItemProps } from './types';

export const LocationItem = ({
  location,
  onClick,
  index,
  activeSuggestion,
}) => {
  const handleOnClick = (e: Event) => {
    e.preventDefault();
    onClick(location);
  };

  return (
    <Link
      css={{
        'abyss-link-active':
          activeSuggestion === index ? isLocationActiveSuggetion : '',
      }}
      data-auto-testid={`suggested-location-item${
        activeSuggestion === index ? '-active-suggestion' : ''
      }`}
      data-testid={`suggested-location-item${
        activeSuggestion === index ? '-active-suggestion' : ''
      }`}
      fontWeight="bold"
      onClick={handleOnClick}
      tabIndex={-1}
    >
      {location?.place_name}
    </Link>
  );
};
