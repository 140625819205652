import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { useEffect, useState } from 'react';

import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { ProviderType } from '../../models/ProviderDetails';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import FACILITY_DETAILS from './FacilityDetails.graphql';

export const useFacilityDetailsSearch = (options) => {
  const [facilityDetails, setFacilityDetails] = useState({});
  const [queryResult, queryAction] = useCustomQuery(FACILITY_DETAILS, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'facilityDetails',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setFacilityDetails(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: facilityDetails,
  };

  return [parsedResult, queryAction];
};

export const useFacilityDetails = (variables) => {
  const locale = getLanguage()?.code || 'en';
  const { providerId = '', locationId = '', providerType } = variables;
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { coverageType } = tokenData;
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const currentMember = getCurrentMember();
  const planVariationCodeForMedical = getPlanVariationCode(currentMember, 'M');

  const [facilityDetails, getFacilityDetails] = useFacilityDetailsSearch({});

  useEffect(() => {
    getFacilityDetails({
      variables: {
        locale,
        providerId,
        providerType: providerType ?? ProviderType.ORGANIZATION,
        policyID: currentMember?.policyNumber,
        planYear: getCurrentPlanYear(),
        lob: currentMember?.lineOfBusiness,
        planVariationCode: planVariationCodeForMedical,
        latitude,
        longitude,
        stateCode,
        coverages: getCoverageTypes(currentMember),
        reciprocityId: getNetworkIdsForPES(currentMember, coverageType),
        locationId,
        coverageType,
      },
    });
  }, [latitude, longitude, stateCode, providerId, locationId, coverageType]);

  return facilityDetails;
};
