import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';

import { DirectoryLink } from '../DirectoryLink/DirectoryLink';
import { searchProviderName } from '../helper';

type props = {
  physician: {
    providerId?: string | undefined;
    providerName?: string;
  };
  linkOnClick: (a: any) => void;
  search: string;
  isTablet: boolean;
};

export const DirectoryListMobile = ({
  physician,
  linkOnClick,
  search,
  isTablet,
}: props) => {
  if (searchProviderName(physician, search)) {
    if (isTablet) {
      <Grid style={{ flexDirection: 'column', margin: 0 }}>
        <Grid.Col
          css={{
            'abyss-grid-col': {
              padding: '0',
              width: '100%',
            },
          }}
        >
          <DirectoryLink
            physician={physician}
            linkOnClick={linkOnClick}
            search={search}
          />
        </Grid.Col>
      </Grid>;
    }
    return (
      <Layout.Stack grow css={{ div: { margin: 0 } }}>
        <DirectoryLink
          physician={physician}
          linkOnClick={linkOnClick}
          search={search}
        />
      </Layout.Stack>
    );
  }
  return null;
};
