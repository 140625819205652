import { useState, useEffect, useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useCombobox } from 'downshift';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';
import { useFuse } from '@abyss/web/hooks/useFuse';
import { useFormInput } from '@abyss/web/hooks/useFormInput';
import { useAriaProps } from '@abyss/web/hooks/useAriaProps';
import { usePopper } from '@abyss/web/hooks/usePopper';

// helper function to get set default value if one was passed
const getDefaultValue = (value, options, valueKey) => {
  if (typeof value !== 'undefined' && options.length) {
    const defaultValue = options.find((item) => item && item[valueKey] === value && !item?.isDisabled);
    if (defaultValue) {
      return defaultValue;
    }
  }
  return null;
};

export const useSearchInput = ({
  options,
  fuseConfig,
  placeholder,
  keys,
  label,
  model,
  value,
  onClear,
  customFilter,
  openOnFocus,
  onClickSearchButton,
  onInputChange,
  results,
  isClearable,
  isDisabled,
  resultLimit,
  isSearchable,
  isLoading,
  onChange,
  onSearch,
  onFocus,
  onKeyDown,
  uniqueStorageId,
  onBlur,
  onPaste,
  labelKey,
  valueKey,
  subText,
  descriptorsDisplay,
  debounceTime,
  apiFiltering,
  rounded,
  inputLeftElement,
  inputRightElement,
  ...props
}) => {
  const flatOptions = useMemo(() => options.flatMap((item) => {
      const result = [item];
      if (item?.section) {
        item.items.forEach((sectionItem) => {
          result.push({ ...sectionItem, sectionName: item.section });
        });
      }

      return result;
    }), [options]);

  const [inputItems, setInputItems] = useState(flatOptions);
  const [showClear, setShowClear] = useState(false);
  const [showLocalStorage, setShowLocalStorage] = useState(false);
  const [localStorageJSON, setLocalStorageJSON] = useState([]);
  let debounceTimeUpdate = debounceTime;
  if (apiFiltering !== null && debounceTimeUpdate === 0) {
    debounceTimeUpdate = 1000;
  }

  const itemToString = (item) => item ? item?.[labelKey] : '';

  const {
    inputError,
    formInputProps,
    formContext,
    triggerFocus,
    descriptorProps,
    isRequired,
    descriptorsDisplayForm,
  } = useFormInput({
    label,
    model,
    isController: true,
    defaultError: 'Select a value',
    ...props,
  });

  const { ariaLabelProps, ariaInputProps, ariaDescriptorProps } = useAriaProps({
    errorMessage: inputError?.message,
    hasSubText: !!subText,
    isRequired,
  });

  const fuse = useFuse({
    list: flatOptions,
    config: {
      threshold: 0.4,
      ...fuseConfig,
    },
    keys,
  });

  const iconRightPadding = () => {
    let padding = 1;
    if (isClearable) {
      padding += 1;
    }
    if (inputRightElement) {
      padding += 1;
    }

    return padding;
  };

  const { anchorRef, placedSide, ...popperProps } = usePopper({
    position: 'bottom',
    matchAnchorWidth: true,
  });

  const currentInputValue = formContext?.watch(model) || value;

  const getEventValues = (e, selectedItem) => ({
      ...e,
      target: {
        ...e.target,
        value: selectedItem?.[valueKey] || '',
      },
    });

  // Breakdown of state and actions and how to handle them
  const stateReducer = (state, actionAndChanges) => {
    const {
      type,
      changes,
      props: { items },
    } = actionAndChanges;
    const { isOpen } = state;

    const rootValue = formContext?.getValues(model) || value;

    const highlightedDisabledState =
      !!inputItems?.[changes?.highlightedIndex]?.isDisabled;

    const selectedDisabledState = !!changes?.selectedItem?.isDisabled;

    if (highlightedDisabledState) return changes;

    if (
      type === useCombobox.stateChangeTypes.InputKeyDownEnter &&
      selectedDisabledState
    ) {
      return state;
    }

    if (
      type === useCombobox.stateChangeTypes.ItemClick &&
      isOpen &&
      state.highlightedIndex < 0
    ) {
      return {
        ...changes,
        inputValue: '',
      };
    }

    if (
      type === useCombobox.stateChangeTypes.InputKeyDownEnter &&
      isOpen &&
      state.highlightedIndex < 0
    ) {
      const firstSelectableOptionIndex = items.findIndex((item) => item != null && !!item[valueKey]);
      const openOptionsMenu = !rootValue || rootValue?.length <= 1;
      const inputValueOptions = !openOptionsMenu ? {
        inputValue: '',
      } : null;
      return {
        ...changes,
        isOpen: openOptionsMenu,
        highlightedIndex: firstSelectableOptionIndex,
        ...inputValueOptions,
      };
    }

    if (type === useCombobox.stateChangeTypes.InputBlur) {
      setShowLocalStorage(false);
    }

    if (type === useCombobox.stateChangeTypes.InputFocus && !openOnFocus) {
      return {
        ...changes,
        isOpen: false,
      };
    }

    if (
      type === useCombobox.stateChangeTypes.ItemClick ||
      type === useCombobox.stateChangeTypes.InputKeyDownEnter
    ) {
      if (onSearch) onSearch(changes?.selectedItem);
      if (!rootValue && !changes?.selectedItem && changes?.inputValue) {
        return {
          ...changes,
          inputValue: '',
        };
      }

      if (changes?.selectedItem?.[labelKey] !== changes.inputValue) {
        return {
          ...changes,
          inputValue: changes?.selectedItem?.[labelKey],
        };
      }
    }

    if (
      type === useCombobox.stateChangeTypes.InputKeyDownArrowDown ||
      type === useCombobox.stateChangeTypes.InputKeyDownArrowUp
    ) {
      return {
        ...changes,
        inputValue: inputItems?.[changes?.highlightedIndex]?.[labelKey],
      };
    }

    return changes;
  };

  // helper function to handle the search on the current value. checks if it needs to debounce or not
  const searchHelper = (currentValue, closeMenu) => {
    const result = fuse.search(currentValue, { limit: resultLimit });
    if (!currentValue || !result) {
      setInputItems(flatOptions);
      closeMenu();
    } else {
      setInputItems(
        result.map((item) => item?.item)
      );
    }
  };

  // Fuse override debounce. We manage option list state internally
  const debounceSearchHelper = useCallback(
    debounce(searchHelper, debounceTimeUpdate),
    []
  );

  // API Search Filter Debounce.
  const debounceApiFilteringSearch = useCallback(
    debounce((inputValue, selectedItem) => {
      apiFiltering(inputValue, selectedItem);
    }, debounceTimeUpdate),
    []
  );

  // searches the input items with a debounce
  const debounceSearch = useCallback(
    debounce(setInputItems, debounceTimeUpdate),
    []
  );

  const scrollIntoView = (optionNode, containerNode) => {
    if (optionNode && containerNode) {
      optionNode.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  };

  const initialSelectedItem = getDefaultValue(
    formContext?.getValues(model) || value,
    flatOptions,
    valueKey
  );

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
    selectItem,
    selectedItem,
    inputValue,
  } = useCombobox({
    items: inputItems,
    scrollIntoView,
    initialSelectedItem,
    getA11yStatusMessage: ({ resultCount }) => {
      if (!isOpen) {
        return '';
      }

      if (!resultCount) {
        return 'No results found. Please enter a valid search term.';
      }

      return `${resultCount} result${
        resultCount === 1 ? ' is' : 's are'
      } available, use up and down arrow keys to navigate. Press Enter or Space Bar keys to select.`;
    },
    stateReducer,
    itemToString,
    onSelectedItemChange: ({ selectedItem: item, type }) => {
      const currentItem = item?.item ? item.item : item;
      const storageItems = localStorage.getItem(uniqueStorageId);
      setShowLocalStorage(false);

      if (uniqueStorageId && storageItems && currentItem) {
        const currentLocalStorage = JSON.parse(storageItems);

        const checkStorage = uniqBy(
          [currentItem, ...currentLocalStorage],
          (storageItem) => storageItem.id
        )?.slice(0, 5);

        localStorage.setItem(uniqueStorageId, JSON.stringify(checkStorage));
        setLocalStorageJSON(checkStorage);
      } else if (currentItem && uniqueStorageId) {
        const localStorageJSONtemp = localStorageJSON || [];
        setLocalStorageJSON(localStorageJSONtemp.push(currentItem));
        localStorage.setItem(
          uniqueStorageId,
          JSON.stringify(localStorageJSONtemp)
        );
      }

      if (
        type !== useCombobox.stateChangeTypes.ItemClick &&
        type !== useCombobox.stateChangeTypes.InputKeyDownEnter
      ) {
        return;
      }

      if (model) {
        formInputProps.onChange(currentItem?.[valueKey]);
      }

      if (onChange) {
        onChange(currentItem?.[valueKey], currentItem);
      }

      closeMenu();
    },
    onInputValueChange: ({
      inputValue: onChangeInputValue,
      type,
      selectedItem: onChangeSelectedItem,
    }) => {
      const isArrowKeyDown =
        type === useCombobox.stateChangeTypes.InputKeyDownArrowDown ||
        type === useCombobox.stateChangeTypes.InputKeyDownArrowUp;

      if (onInputChange) {
        onInputChange(onChangeInputValue, isArrowKeyDown);
      }

      if (isArrowKeyDown) {
        setShowClear(!!onChangeInputValue);
        return;
      }
      setShowLocalStorage(false);
      setShowClear(!!onChangeInputValue);

      if (customFilter && onChangeInputValue && debounceTimeUpdate) {
        debounceSearch(customFilter(onChangeInputValue));
        debounce(openMenu, debounceTimeUpdate);
        return;
      }
      if (
        customFilter &&
        onChangeInputValue.length === 0 &&
        debounceTimeUpdate
      ) {
        debounceSearch.cancel();
        setInputItems([]);
      }

      if (customFilter && onChangeInputValue) {
        setInputItems(customFilter(onChangeInputValue));
        return;
      }

      // apiFiltering prop to handle api/debounce callbacks
      if (apiFiltering && debounceTimeUpdate) {
        debounceApiFilteringSearch.cancel();
        debounceApiFilteringSearch(onChangeInputValue, onChangeSelectedItem);
        debounce(openMenu, debounceTimeUpdate);
        return;
      }

      if (debounceTimeUpdate) {
        debounceSearchHelper.cancel();
        if (!inputItems) {
          closeMenu();
        } else {
          openMenu();
        }
        debounceSearchHelper(onChangeInputValue, openMenu, closeMenu);
        return;
      }

      searchHelper(onChangeInputValue, openMenu);
    },
  });

  /*
useEffect to check when either options or isLoading changes, check to see if
inputItems is the same as options. If not, then update inputItems to be the same as options.
*/
  useEffect(() => {
    if (!isEqual(inputItems, flatOptions) && !isLoading) {
      setInputItems(flatOptions);
    }
  }, [flatOptions, isLoading]);

  /*
useEffect to check that when uniqueStorageId changes, check to see if their is anything
in the localStorageJSON object. If their isn't then update the localStorageJSON object to
be whatever is at the uniqueStorageId in localStorage.
*/
  useEffect(() => {
    if (uniqueStorageId && !localStorageJSON.length) {
      setLocalStorageJSON(JSON.parse(localStorage.getItem(uniqueStorageId)));
    }
  }, [uniqueStorageId]);

  /*
useEffect to check when either options or currentInputValue changes, is the default value is the same as the selectedItem.
If not, change the selected item to the default value, and set the input items to options
*/
  useEffect(() => {
    const checkValue = getDefaultValue(
      currentInputValue,
      flatOptions,
      valueKey
    );
    if (!isEqual(checkValue, selectedItem)) {
      selectItem(checkValue || null);
      setInputItems(flatOptions);
    }
  }, [currentInputValue, flatOptions]);

  // inputOnClick handles the click into the FormInput box
  const inputOnClick = () => {
    setShowLocalStorage(false);
    if (uniqueStorageId && localStorageJSON?.length && !inputValue) {
      setInputItems(localStorageJSON);
      setShowLocalStorage(true);
    } else if (inputValue) {
      const updatedOptions = resultLimit
        ? inputItems.slice(0, resultLimit)
        : inputItems;
      setInputItems(updatedOptions);
    }
     openMenu();
  };

  // helper function to handle how items are removed from local storage
  // const handleRemoveItem = (currentItem) => {
  //   const activeLocalStorage = JSON.parse(
  //     localStorage.getItem(uniqueStorageId)
  //   );
  //   const updatedLocalStorageJSON = activeLocalStorage.filter((item) => {
  //     return item.value !== currentItem.value;
  //   });
  //   localStorage.setItem(
  //     uniqueStorageId,
  //     JSON.stringify(updatedLocalStorageJSON)
  //   );
  //   setLocalStorageJSON(updatedLocalStorageJSON);
  //   if (updatedLocalStorageJSON.length === 0) {
  //     setInputItems(options);
  //     setShowLocalStorage(false);
  //     closeMenu();
  //   } else {
  //     setInputItems(updatedLocalStorageJSON);
  //   }
  // };

  const inputOnClear = () => {
    selectItem(null);
    if (model) {
      formContext.setValue(model, '');
    }
    if (onChange) {
      onChange('');
    }
    if (onClear) {
      onClear();
    }
  };

  const menuProps = getMenuProps({}, { suppressRefError: true });
  delete menuProps['aria-labelledby'];

  const inputProps = {
    ...ariaInputProps,
    ...getInputProps({
      onClick: inputOnClick,
      onBlur: (e) => {
        const onBlurEvent = getEventValues(e, selectedItem);
        if (onBlur) {
          onBlur(onBlurEvent);
        }
      },
      onPaste,
      onFocus: (e) => {
        const onFocusEvent = getEventValues(e, selectedItem);
        if (onFocus) {
          onFocus(onFocusEvent);
        }
        if (openOnFocus) {
          openMenu();
        }
      },
      onKeyDown: (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
        if (onKeyDown) {
          onKeyDown(e);
        }
      },
      ref: formInputProps?.ref,
    }),
    isOpen,
    inputType: 'text',
    disabled: isDisabled,
    error: !!inputError?.message,
    'aria-autocomplete': 'list',
    placeholder,
    type: 'text',
    as: 'input',
    placedSide,
    rounded,
    inputLeftElement: !!inputLeftElement,
    iconRight: iconRightPadding(),
  };
  delete inputProps['aria-labelledby'];

  return {
    rootProps: {
      ref: anchorRef,
      rounded,
    },
    labelProps: {
      ...ariaLabelProps,
      ...getLabelProps(),
      isDisabled,
    },
    searchButtonProps: {
      ...(onClickSearchButton
        ? {
            as: 'button',
            type: 'button',
            'aria-label': 'Search',
            onClick: onClickSearchButton,
          }
        : { as: 'div' }),
    },
    inputProps,
    clearProps: {
      isClearable,
      tabindex: -1,
      triggerFocus,
      isDisabled,
      onClear: inputOnClear,
      'aria-label': 'Clear Search',
    },
    descriptorProps: {
      errorMessage: inputError?.message,
      subText,
      descriptorsDisplay: descriptorsDisplayForm || descriptorsDisplay,
      ...ariaDescriptorProps,
      ...descriptorProps,
    },
    optionProps: {
      menuProps: {
        ...menuProps,
        'aria-label': 'Search Results',
        rounded,
      },
      getItemProps,
      activeIndex: highlightedIndex,
      selectedItem,
      showLocalStorage,
      items: inputItems,
      isOpen,
      valueKey,
      labelKey,
      // handleRemoveItem,
      error: !!inputError?.message,
    },
    popperProps: { placedSide, ...popperProps },
    state: {
      selectedItem,
      isOpen,
      label,
      isDisabled,
      showClear,
      isLoading,
      isSearchable,
      rounded,
      ...props,
    },
  };
};
