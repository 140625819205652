import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Card } from '@abyss/web/ui/Card';
import { CollapseProvider } from '@abyss/web/ui/CollapseProvider';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Degrees,
  Education,
  Identifiers,
} from '../../../../models/ProviderDetails';
import { capitalizeEachWord } from '../../../../utils/providerDetails.utils';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../Constants';
import { ConstantsRoutes } from '../../../ConstantsRoutes';
import {
  h2SmallerFontForResponsive,
  phoneOnly,
  tabletOnly,
} from '../../../ConstantsStyles';
import { CopyToClipBoard } from '../../ProviderLocationsDetails/CopyToClipBoard';
import { InfoContainer } from '../../ProviderLocationsDetails/ProviderLocationsDetails.style';
import {
  CollapseCardHeaderStyle,
  CollapseCardSectionStyle,
  CollapseHospitalAffiliationsStyle,
  CollapseSection,
  HospitalAffiliationsContainerStyle,
  NAText,
  OtherDetailsContentTitle,
} from '../About.styled';

export type Props = {
  collapseHeader: string;
  collapseConstantKey: string;
  collapseContent?: string[];
  education?: Education[] | null;
  culturalCompetencies?: string[];
  hospitalAffiliations?: Identifiers[] | null;
  providerId?: string;
  npi?: string;
  licenseNumber?: string[];
};

export function listDegrees(school: string, degrees: Degrees[]) {
  const { t } = useTranslation();
  const getDegree = (degree) =>
    `${degree?.description ? capitalizeEachWord(degree?.description) : ''} (${
      degree?.degreeCode || ''
    })`;

  return school === t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
    degrees?.map((degree) => getDegree(degree))
  ) : (
    <React.Fragment>
      <b> {school ? capitalizeEachWord(school) : ''}</b>
      <Layout.Stack alignItems="left" space={2}>
        {degrees?.map((degree) => getDegree(degree))}
      </Layout.Stack>
    </React.Fragment>
  );
}

export function listCulturalCompetencies(culturalCompetencies: string[] = []) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <b>
        {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.CULTURAL_COMPETENCE')}
      </b>
      <Layout.Stack alignItems="left" space={2}>
        {culturalCompetencies?.length > 0 ? (
          culturalCompetencies?.map(
            (competence) => `${capitalizeEachWord(competence)}`
          )
        ) : (
          <NAText>
            {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE')}
          </NAText>
        )}
      </Layout.Stack>
    </React.Fragment>
  );
}

function listOtherProviderDetails(idNumber?: string, idName?: string) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <OtherDetailsContentTitle> {idName}</OtherDetailsContentTitle>
      {idNumber ? (
        <Layout.Stack alignItems="left" space={2}>
          <Flex css={CollapseHospitalAffiliationsStyle}>
            <InfoContainer> {idNumber} </InfoContainer>
            <CopyToClipBoard
              label={`Copy ${idName} to clipboard` || ''}
              text={idNumber || ''}
              title={idName || ''}
            />
          </Flex>
        </Layout.Stack>
      ) : (
        <InfoContainer>
          {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE')}
        </InfoContainer>
      )}
    </React.Fragment>
  );
}

export const CollapseProviderDetailsSection = ({
  collapseContent,
  collapseHeader,
  collapseConstantKey,
  education,
  culturalCompetencies,
  hospitalAffiliations,
  licenseNumber,
  npi,
  providerId,
}: Props) => {
  const mobile = useMediaQuery(phoneOnly);
  const tablet = useMediaQuery(tabletOnly);
  const { navigate } = useRouter();
  const { t } = useTranslation();
  const handleHospitalAffiliationOnClick = (
    hospitalAffiliationProviderId,
    name
  ) => {
    adobeLinkTrackEvent({
      name,
      location: `body:${Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS}`,
      type: 'internal',
      destinationUrl: '',
    });
    const updatedToken = tokenizer.create({
      hospitalAffiliationProviderId,
      sectionType: Constants.RESULT_SECTION.FACILITY,
    });
    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      hospitalAffiliationProviderId
    );
    navigate(`${ConstantsRoutes.FACILITY_DETAILS.path}${updatedToken}`);
  };

  function listHospitalAffiliationsResponsive(
    name: string,
    hospitalAffiliationProviderId: string
  ) {
    return (
      <Layout.Stack alignItems="left" space={2}>
        <Link
          data-auto-testid={`hospital-affiliations-content-desktop-${name}`}
          data-testid={`hospital-affiliations-content-desktop-${name}`}
          fontWeight="$bold"
          isStandardAnchor
          onClick={() => {
            handleHospitalAffiliationOnClick(
              hospitalAffiliationProviderId,
              name
            );
          }}
        >
          {capitalizeEachWord(name)}
        </Link>
      </Layout.Stack>
    );
  }

  function hospitalAffiliationsChips(
    name: string,
    hospitalAffiliationProviderId: string
  ) {
    return (
      <Flex css={HospitalAffiliationsContainerStyle}>
        <Link
          css={{
            'abyss-link-root': {
              maxWidth: '155px',
            },
          }}
          data-auto-testid={`hospital-affiliations-content-desktop-${name}`}
          data-testid={`hospital-affiliations-content-desktop-${name}`}
          fontWeight="$bold"
          isStandardAnchor
          onClick={() =>
            handleHospitalAffiliationOnClick(
              hospitalAffiliationProviderId,
              name
            )
          }
        >
          <Text
            color="$info1"
            css={{
              'abyss-text-root': {
                maxWidth: '127px',
                fontWeight: '$semibold',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          >
            {capitalizeEachWord(name)}
          </Text>
          <IconMaterial css={{ marginLeft: '4px' }} icon="chevron_right" />
        </Link>
      </Flex>
    );
  }

  function listHospitalAffiliations(
    name: string,
    hospitalAffiliationProviderId: string
  ) {
    return tablet ? (
      hospitalAffiliationsChips(name, hospitalAffiliationProviderId)
    ) : (
      <Tooltip
        align="center"
        asChild={false}
        content={capitalizeEachWord(name)}
        css={{
          'abyss-text-root': {
            fontSize: '14.22px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '16px',
          },
        }}
        position="top"
        positionOffset={8}
      >
        {hospitalAffiliationsChips(name, hospitalAffiliationProviderId)}
      </Tooltip>
    );
  }

  const getAboutDetailResponsive = (detailCollapseHeaderResponsive) => {
    switch (detailCollapseHeaderResponsive) {
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION:
        return (
          <React.Fragment>
            {education?.map((item) => (
              <Card.Section
                key={`collapse-section-${item?.schoolName}`}
                css={CollapseCardSectionStyle}
              >
                <Layout.Stack alignItems="left" space={2}>
                  {listDegrees(item.schoolName, item.degrees)}
                </Layout.Stack>
              </Card.Section>
            ))}
            <Card.Section
              key="collapse-section-cultural-competence"
              css={CollapseCardSectionStyle}
            >
              <Layout.Stack
                alignItems="left"
                space={2}
                style={{ marginTop: '12px' }}
              >
                {listCulturalCompetencies(culturalCompetencies)}
              </Layout.Stack>
            </Card.Section>
          </React.Fragment>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .HOSPITAL_AFFILIATIONS:
        return hospitalAffiliations?.map((item) =>
          item.value === t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
            <NAText>
              {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA')}
            </NAText>
          ) : (
            listHospitalAffiliationsResponsive(item.value, item.id)
          )
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .OTHER_PROVIDER_DETAILS:
        return (
          <Layout.Stack alignItems="left" space={12}>
            {listOtherProviderDetails(
              providerId,
              t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.PROVIDER_ID')
            )}
            {listOtherProviderDetails(
              npi,
              t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NATIONAL_PROVIDER_IDENTIFIER'
              )
            )}
            {listOtherProviderDetails(
              licenseNumber?.join(', '),
              t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.LICENSE_NUMBER')
            )}
          </Layout.Stack>
        );
      default:
        return collapseContent?.map((item) => (
          <Card.Section
            key={`collapse-section-${item}`}
            css={CollapseCardSectionStyle}
          >
            {capitalizeEachWord(item)}
          </Card.Section>
        ));
    }
  };

  const getAboutDetail = (detailCollapseHeader) => {
    switch (detailCollapseHeader) {
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION:
        return (
          <React.Fragment>
            {education?.map((item) => (
              <Text
                key={`expand-section-${item?.schoolName}`}
                color="#4B4D4F"
                fontWeight="$medium"
                size={18}
              >
                <Layout.Stack alignItems="left" space={2}>
                  {listDegrees(item.schoolName, item.degrees)}
                </Layout.Stack>
              </Text>
            ))}
            <Text
              key="expand-section-cultural-competence"
              color="#4B4D4F"
              fontWeight="$medium"
              size={18}
            >
              <Layout.Stack
                alignItems="left"
                space={2}
                style={{ marginTop: '12px' }}
              >
                {listCulturalCompetencies(culturalCompetencies)}
              </Layout.Stack>
            </Text>
          </React.Fragment>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .HOSPITAL_AFFILIATIONS:
        return (
          <Layout.Group>
            {hospitalAffiliations
              ?.slice(0, 6)
              .map((item) =>
                item.value ===
                t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
                  <NAText>
                    {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA')}
                  </NAText>
                ) : (
                  listHospitalAffiliations(item.value, item.id)
                )
              )}
          </Layout.Group>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .OTHER_PROVIDER_DETAILS:
        return (
          <Layout.Stack alignItems="left" space={24}>
            {listOtherProviderDetails(
              providerId,
              t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.PROVIDER_ID')
            )}
            {listOtherProviderDetails(
              npi,
              t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NATIONAL_PROVIDER_IDENTIFIER'
              )
            )}
            {listOtherProviderDetails(
              licenseNumber?.join(', '),
              t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.LICENSE_NUMBER')
            )}
          </Layout.Stack>
        );
      default:
        return collapseContent?.map((item) => (
          <Text
            key={`expand-section-${item}`}
            color="#4B4D4F"
            fontWeight="$medium"
            size={18}
          >
            {capitalizeEachWord(item)}
          </Text>
        ));
    }
  };

  return (
    <React.Fragment>
      {mobile ? (
        <CollapseSection isFullWidth>
          <CollapseProvider defaultIsOpen={false}>
            <Card
              collapse
              css={CollapseCardHeaderStyle}
              header={t(collapseHeader)}
            >
              {getAboutDetailResponsive(collapseConstantKey)}
            </Card>
          </CollapseProvider>
        </CollapseSection>
      ) : (
        <CollapseSection
          isFullWidth={
            collapseHeader ===
            t(
              'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS'
            )
          }
        >
          <Layout.Stack alignItems="left">
            <Heading css={h2SmallerFontForResponsive} display="h4" offset={1}>
              {t(collapseHeader)}
            </Heading>
            {getAboutDetail(collapseConstantKey)}
          </Layout.Stack>
        </CollapseSection>
      )}
    </React.Fragment>
  );
};
