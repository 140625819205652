import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { HealthgradesProviderReview } from '../../../../models/Healthgrades';
import { phoneOnly } from '../../../ConstantsStyles';
import { Rating } from '../../../SnackCard/Rating';
import {
  ProviderAndOfficeAndStaffExperienceSectionStyles,
  RatingSectionHeader,
} from './PatientReviews.styled';
import { ProviderAndOfficeAndStaffExperienceSectionDesktop } from './ProviderAndOfficeAndStaffExperienceSectionDesktop';

type Props = {
  healthgradesReview: HealthgradesProviderReview;
};

export const ProviderAndOfficeAndStaffExperienceSection = ({
  healthgradesReview,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);

  if (mobileScreen) {
    return (
      <Flex css={ProviderAndOfficeAndStaffExperienceSectionStyles}>
        {healthgradesReview.providerExperienceRating ? (
          <Flex css={{ display: 'flex' }} direction="row">
            <RatingSectionHeader>
              {t(
                'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.PROVIDER_EXPERIENCE'
              )}
            </RatingSectionHeader>
            <Rating
              hideNumber
              outOf={5}
              rating={healthgradesReview.providerExperienceRating}
              size={13.33}
            />
          </Flex>
        ) : null}
        {healthgradesReview.officeAndStaffExperienceRating ? (
          <Flex css={{ display: 'flex' }} direction="row">
            <RatingSectionHeader>
              {t(
                'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.OFFICE_AND_STAFF_EXPERIENCE'
              )}
            </RatingSectionHeader>
            <Rating
              hideNumber
              outOf={5}
              rating={healthgradesReview.officeAndStaffExperienceRating}
              size={13.33}
            />
          </Flex>
        ) : null}
      </Flex>
    );
  }

  return (
    <ProviderAndOfficeAndStaffExperienceSectionDesktop
      healthgradesReview={healthgradesReview}
    />
  );
};
