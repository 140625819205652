import { storage } from '@abyss/web/tools/storage';

import { Constants, CoverageTypesCodes } from '../common/Constants';
import { StoreKeys } from '../hooks/useStore/state';
import { useStore } from '../hooks/useStore/useStore';

export const getCurrentMember = () => {
  const currentIndex =
    storage.session.get(Constants.STORAGE_KEYS.SESSION.ACTIVE_MEMBER_INDEX) ||
    0;

  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  return memberInfo?.[currentIndex];
};

export const getDependentInfo = (dependentSeqNbr) => {
  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  const dependentInfo = memberInfo?.find(
    (member) => member?.demographics.dependentSeqNbr === dependentSeqNbr
  );

  return dependentInfo;
};

export const fetchMemberStateFromMapBox = (longitude, latitude, mapKey) =>
  fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=address&&access_token=${mapKey}`,
    { method: 'GET' }
  ).then((resp) => resp.json());

export const getLoggedInMember = () => {
  const loggedInMemberIndex = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.LOGGED_IN_MEMBER_INDEX
  );
  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  return memberInfo?.[loggedInMemberIndex];
};

export const getCoverageTypes = (currentMember) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.map(
    (coverage) => coverage.typeCode.coverageTypeCode
  );

export const getMarketTypes = (currentMember) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.map(
    (coverage) => coverage.marketType
  );

export const getPlanVariationCode = (currentMember, coverageTypeCode) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
    (coverage) => coverage.typeCode?.coverageTypeCode === coverageTypeCode
  )?.planVariationCode;

export const getCurrentMemberPCPInfo = (dependentSeqNbr, primaryCare) =>
  primaryCare?.find(
    ({ depSeqNbr, pcpInfo }) =>
      pcpInfo.hasPCP &&
      pcpInfo.isCoverageActive &&
      depSeqNbr === dependentSeqNbr
  )?.pcpInfo;

export const getCurrentPlanYear = () => String(new Date().getFullYear());

export const getMemberByDependentSeqNbr = (dependentSeqNbr) => {
  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  const reqMember = memberInfo.filter(
    (member) => member.demographics.dependentSeqNbr === dependentSeqNbr
  );
  return reqMember[0];
};

export const getMemberCoverage = (currentMember: any) => {
  const medicalCov = `md_${
    currentMember?.reciprocityId +
    '%3B'.concat(Constants.NETWORK_COVERAGE_CODE.MEDICAL)
  }`;

  const todayDate = new Date().toISOString().slice(0, 10);

  const coverageTypeCodes = currentMember?.eligibility
    .flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
    .map((coverageType) => {
      const coverageDate =
        coverageType?.coverageEffDateRange?.endDate >= todayDate &&
        coverageType?.coverageEffDateRange?.startDate < todayDate;

      if (
        coverageType?.typeCode?.desc === CoverageTypesCodes.MEDICAL &&
        coverageType?.typeCode?.coverageTypeCode === 'M' &&
        coverageDate
      ) {
        return medicalCov;
      }

      if (
        coverageType?.typeCode?.desc === CoverageTypesCodes.BEHAVIORAL_HEALTH &&
        coverageType?.typeCode?.coverageTypeCode === 'B' &&
        coverageDate
      ) {
        return Constants.NETWORK_COVERAGE_CODE.BEHAVIORAL_B;
      }

      if (
        coverageType?.typeCode?.desc ===
          CoverageTypesCodes.BEHAVIORAL_CARVE_OUT &&
        coverageType?.typeCode?.coverageTypeCode === 'OBH' &&
        coverageDate
      ) {
        return Constants.NETWORK_COVERAGE_CODE.BEHAVIORAL_OBH;
      }

      return '';
    });

  let network = coverageTypeCodes.filter((networkType: string) => networkType);
  if (
    network.includes(Constants.NETWORK_COVERAGE_CODE.BEHAVIORAL_B) &&
    network.includes(Constants.NETWORK_COVERAGE_CODE.BEHAVIORAL_OBH)
  ) {
    network = network.filter(
      (networkType: string) =>
        networkType !== Constants.NETWORK_COVERAGE_CODE.BEHAVIORAL_OBH
    );
  }

  return network?.join('%3B');
};

export const getNetworkIdsForPES = (
  currentMember: any,
  coverageType: string
) => {
  if (coverageType === 'B') {
    const todayDate = new Date().toISOString().slice(0, 10);

    const coverageTypeCodes = currentMember?.eligibility
      .flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
      .map((covType) => {
        const coverageDate =
          covType?.coverageEffDateRange?.endDate >= todayDate &&
          covType?.coverageEffDateRange?.startDate < todayDate;

        if (
          covType?.typeCode?.desc === CoverageTypesCodes.BEHAVIORAL_HEALTH &&
          covType?.typeCode?.coverageTypeCode === 'B' &&
          coverageDate
        ) {
          return Constants.NETWORK_COVERAGE_CODE_PES.BEHAVIORAL_B;
        }

        if (
          covType?.typeCode?.desc === CoverageTypesCodes.BEHAVIORAL_CARVE_OUT &&
          covType?.typeCode?.coverageTypeCode === 'OBH' &&
          coverageDate
        ) {
          return Constants.NETWORK_COVERAGE_CODE_PES.BEHAVIORAL_OBH;
        }

        return '';
      });

    const network = coverageTypeCodes.filter(
      (networkType: string) => networkType
    );
    return network[0];
  }

  return currentMember?.reciprocityId;
};
