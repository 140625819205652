import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FacilityLocationsResponse } from '../../../models/FacilityDetails';
import { ProviderLocation } from '../../../models/ProviderDetails';
import { phoneOnly } from '../../ConstantsStyles';
import { popoverMobileStyle } from '../../DataCard/Miles.style';
import { handleLinkAndModalTrack } from '../../Utils/adobeTrackUtils';
import { MilesContent } from './ProviderLocationsDetails.style';

type Props = {
  selectedLocation?: ProviderLocation | FacilityLocationsResponse;
  title: string;
  content: string;
  widths?: number;
  position?: string;
  align?: string;
  alignOffset?: number;
  locationForAnalytics?: string;
};

export const PopOverWrapper = styled('div', {
  '.abyss-popover-trigger ~ div': {
    zIndex: '999 !important',
  },
});

export const Distance = ({
  selectedLocation,
  title,
  content,
  widths = 220,
  position = 'bottom',
  align = 'center',
  alignOffset = 0,
  locationForAnalytics,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = useMediaQuery(phoneOnly);

  useEffect(() => {
    if (isOpen) {
      const linkName: string = 'distance-popover';
      const linkLocation: string = `body:${
        locationForAnalytics ?? 'location detail distance'
      }`;
      const modalName: string = 'distance';
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  const displayContent = () => (
    <IconMaterial
      data-auto-testid="provider-location-detail-miles-Info"
      data-testid="provider-location-detail-miles-Info"
      icon="info"
      onClick={() => setIsOpen(true)}
      size={20}
      variant="outlined"
    />
  );

  return (
    <React.Fragment>
      <Flex
        alignItems="center"
        css={{
          flexWrap: 'nowrap',
          gap: '4px',
        }}
        data-auto-testid="provider-location-detail-distance"
        data-testid="provider-location-detail-distance"
      >
        {selectedLocation ? (
          <React.Fragment>
            <IconMaterial icon="directions" />
            {selectedLocation?.distance?.length ? (
              <MilesContent>
                {`${parseFloat(selectedLocation.distance).toFixed(1)} ${t(
                  'miles'
                )}`}
              </MilesContent>
            ) : (
              <MilesContent>
                {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
              </MilesContent>
            )}
          </React.Fragment>
        ) : null}

        {!mobileScreen ? (
          <PopOverWrapper>
            <Popover
              align={align}
              alignOffset={alignOffset}
              aria-label={t('ACCESSIBILITY_LABELS.INFORMATION_ABOUT_DISTANCE')}
              content={content}
              css={{
                'abyss-popover-trigger': {
                  display: 'flex',
                  alignItem: 'center',
                },
              }}
              data-auto-testid="provider-location-detail-miles-Info-popover"
              data-testid="provider-location-detail-miles-Info-popover"
              onOpenChange={() => {
                setIsOpen(!isOpen);
              }}
              position={position}
              title={<Text size="$sm">{title}</Text>}
              width={widths}
            >
              {displayContent()}
            </Popover>
          </PopOverWrapper>
        ) : (
          <React.Fragment>
            {displayContent()}
            <Drawer
              css={popoverMobileStyle}
              data-auto-testid="provider-location-detail-miles-Info-popover"
              data-testid="provider-location-detail-miles-Info-popover"
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              position="bottom"
              title={title}
              titleAlign="left"
            >
              <Drawer.Section>{content}</Drawer.Section>
            </Drawer>
          </React.Fragment>
        )}
      </Flex>
    </React.Fragment>
  );
};
