import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
/* eslint-disable no-nested-ternary */
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { phoneOnly } from '../ConstantsStyles';
import { SnackCard } from '../SnackCard/SnackCard';
import { TierOne } from '../SnackCard/TierOne';

const flexStyles = {
  'abyss-flex-root': {
    gap: '$md',
    flexWrap: 'nowrap',
    overflowX: 'auto',

    '& > div': {
      flex: 'none',
    },
  },
};

type Props = {
  dataTestId?: string;
  snackCardProviders: SnackCardProvider[];
  isLoading: boolean;
  locationForAnalytics?: string;
  headers: ResponseHeaders;
};

export const SnackCardContainer = ({
  snackCardProviders = [],
  dataTestId = '',
  isLoading,
  locationForAnalytics,
  headers,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const isHorizontalDisplay = snackCardProviders.some(
    (e) => e.isTieredProvider === true
  );

  return (
    <Flex
      css={flexStyles}
      data-auto-testid={dataTestId}
      data-testid={dataTestId}
    >
      {isLoading
        ? mobileScreen
          ? [1].map((item) => <SnackCard key={item} isLoading={isLoading} />)
          : [1, 2, 3, 4, 5].map((item) => (
              <SnackCard key={item} isLoading={isLoading} />
            ))
        : snackCardProviders.length
        ? snackCardProviders.map((snackCardProvider, index) => (
            <div
              style={{
                display: 'grid',
                flexDirection: 'column',
                gridTemplateRows: 'max-content',
              }}
            >
              <TierOne
                data-auto-testid="tier-one-snack-card-tag"
                data-testid="tier-one-snack-card-tag"
                isHorizontalDisplay={isHorizontalDisplay}
                isTierOne={snackCardProvider?.isTieredProvider}
              />
              <SnackCard
                key={snackCardProvider.locationId}
                distance={snackCardProvider.distance}
                hasPhysicalAddress={index === 0}
                headers={headers}
                imgSrc={snackCardProvider.imageLocation}
                index={index}
                isLoading={isLoading}
                isTieredProvider={snackCardProvider?.isTieredProvider}
                isVirtualCareOffered={index === 4}
                locationForAnalytics={locationForAnalytics}
                organizationType={snackCardProvider.organizationType}
                professionalDesignation={
                  snackCardProvider.primaryDegrees?.length
                    ? snackCardProvider.primaryDegrees[0]
                    : ''
                }
                providerId={snackCardProvider.providerId}
                providerName={snackCardProvider.providerName}
                providerType={snackCardProvider.providerType}
                rating={
                  snackCardProvider.healthGradeRating
                    ? parseFloat(
                        parseFloat(snackCardProvider.healthGradeRating).toFixed(
                          1
                        )
                      )
                    : undefined
                }
                snackCardProvider={snackCardProvider}
                speciality={snackCardProvider?.speciality}
                website={snackCardProvider?.website || ''}
              />
            </div>
          ))
        : t('No Results Found')}
    </Flex>
  );
};
