import { useTranslation } from 'react-i18next';

export const parseSuggestionResults = (results: {
  features: [{ place_name: string }];
}) => {
  const { features } = results;
  const placeSuggestions = features.map((feature: { place_name: string }) => {
    const trimmedPlaceName = feature.place_name.slice(0, -15);
    return trimmedPlaceName;
  });
  return placeSuggestions || [];
};

export const refactorPlaceName = (placeName: string) =>
  placeName.split(',').slice(0, -1).join(',');

export const getLocationSearchSuggestions = (
  header: string,
  suggestions: any = []
) => {
  const { t } = useTranslation();
  let searchSuggestionHeaders: any = {};
  if (header === 'LOCATION_SEARCH_HEADER') {
    searchSuggestionHeaders = {
      section: t('LOCATION_SEARCH.HEADER'),
      items: [...suggestions],
    };
  } else if (header === 'LOCATION_SEARCH_ERROR') {
    searchSuggestionHeaders = {
      section: t('LOCATION_SEARCH.ERROR'),
      items: [...suggestions],
    };
  }
  return searchSuggestionHeaders;
};
