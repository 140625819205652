/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
// import { useGeolocation } from '../../CostEstimate/api/useGeoLocation';
// import { useZipCode } from '../../CostEstimate/api/useZipCode';
import { useTranslation } from 'react-i18next';
// import { useEffect, useState } from 'react';

// import { ICurrentLocationLink } from './types';

export const CurrentLocationLink = ({ onLocateMeSelect }) => {
  const { t } = useTranslation();

  return (
    <Link
      before={<IconMaterial icon="my_location" />}
      data-testid="use_current_location_link"
      fontWeight={600}
      onClick={() => onLocateMeSelect()}
    >
      {t('Find my location')}
    </Link>
  );
};
