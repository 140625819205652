import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { TranslationType } from '../../models/TranslationContent';

export const useTranslationContent = async (type: TranslationType) => {
  const language = getLanguage()?.code || 'en';

  switch (type) {
    case TranslationType.SPECILATYWITHREFERRAL:
      return import(
        `parcels/public/locale/${language}/specialtyWithReferral.json`
      );
    case TranslationType.SPECIALTY:
    default:
      return import(`parcels/public/locale/${language}/specialty.json`);
  }
};
