import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { NULL_ATTRIBUTE_STATE } from '../frontends/ProviderSearch/routes/MapView/CompareDrawer/utility/compareDrawerConstants';

export const getFormattedPhoneNumber = (
  number: string | null | undefined
): string => {
  if (
    typeof number !== 'string' ||
    isNil(number) ||
    isEmpty(number) ||
    number === ''
  ) {
    return NULL_ATTRIBUTE_STATE.PHONE_NUMBER;
  }

  try {
    const phoneRegex =
      /^\(?[-+ ]?[-1 ]?[-() ]?([0-9]{3})\)?\s*[-. ]?([0-9]{3})\s*[-. ]?([0-9]{4})$/;
    if (phoneRegex.test(number)) {
      return number.replace(phoneRegex, '($1) $2-$3');
    }
  } catch (error) {
    // TODO --> handle parse or format error
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
  }

  return number;
};
