import { config } from '@abyss/web/tools/config';

export type Props = {
  imgRef: React.RefObject<HTMLImageElement>;
  providerType?: string;
  imgSrc?: string;
};

export const ImageComponent = ({ imgRef, providerType, imgSrc }: Props) => {
  const handleImageError = () => {
    if (imgRef.current) {
      // eslint-disable-next-line no-param-reassign
      imgRef.current.src = `${config(
        'CDN_BASE_URL'
      )}/cdn/assets/images/default_${providerType}.jpg`;
    }
  };
  return (
    <img
      ref={imgRef}
      alt="care provider"
      onError={handleImageError}
      src={imgSrc}
    />
  );
};
