import { storage } from '@abyss/web/tools/storage';

import { Constants } from '../../../../common/Constants';
import { DefaultLanguage, LANGUAGES, LanguageObject, Locale } from './types';

// eslint-disable-next-line consistent-return
export const getLanguageFromCookie = () => {
  if (typeof document !== 'undefined') {
    return storage.cookie.get(Constants.PSLOCALE);
  }
};

export const getLanguage = (): LanguageObject => {
  const lang = getLanguageFromCookie() || Locale.enUS;
  const actualLanguageCode =
    lang === Locale.enUS ? DefaultLanguage.code : LANGUAGES.es.code;
  return LANGUAGES[actualLanguageCode];
};

export const initializeLanguage = (): LanguageObject => {
  const language = getLanguage();
  return language;
};
