import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIConstants } from '../../../../../../api/src/services/Constants';
import { useLagoon } from '../../../../hooks/useLagoon';
import {
  getCurrentMemberPCPInfo,
  getLoggedInMember,
} from '../../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../Constants';
import { ConstantsRoutes } from '../../../ConstantsRoutes';
import { phoneOnly } from '../../../ConstantsStyles';
import { ContentWrapper } from '../../../ContentWrapper';
import { handleLinkAndModalTrack } from '../../../Utils/adobeTrackUtils';
import { ContainerForAllSectionsStyled } from '../../ProviderDetailTabs.styled';
import {
  ReferralBannerBoxBanner,
  ReferralBannerContactPCP,
  ReferralBannerReferralRequired,
  ReferralRequiredIcon,
} from './ReferralBannerBox.style';

const POPOVER_CONTENT_KEY = 'REFERRAL';
const REFERRAL_LAGOON_PATH = 'referral-required';

export const ReferralBannerBox = ({ pcpData }: any) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const referralInfoLagoon =
    useLagoon(REFERRAL_LAGOON_PATH)(POPOVER_CONTENT_KEY) ?? {};
  const referralInfoLagoonText = referralInfoLagoon['1'] ?? '';
  const { t } = useTranslation();
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const [isOpen, setIsOpen] = useState(false);
  const referralBannerContactPcpTestId = 'referral-banner-contact-pcp';
  const referralRequiredPopOverTestId = 'referral-required-popover';

  const loggedInMember = getLoggedInMember();
  const loggedInMemberDepSeqNbr = loggedInMember?.demographics?.dependentSeqNbr;

  const handleContactPcpOnClick = () => {
    const loggedInMemberPCPInfo = getCurrentMemberPCPInfo(
      loggedInMemberDepSeqNbr,
      pcpData
    );

    adobeLinkTrackEvent({
      name: referralBannerContactPcpTestId,
      location: `body:${Constants.PROVIDER_DETAILS.BANNER.REFERRAL_REQUIRED}`,
    });

    if (loggedInMemberPCPInfo) {
      const { providerID } = loggedInMemberPCPInfo;
      const updatedToken = tokenizer.update(token, { providerId: providerID });
      navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${updatedToken}`);
    } else {
      const updatedToken = tokenizer.update(token, {
        choosePCP: true,
        dependentSeqNbr: loggedInMemberDepSeqNbr,
        pcpIndicator: true,
        providerType: APIConstants.PROVIDER_TYPES.practitioner,
        searchType: APIConstants.PES.LOCATION,
        acceptingNewPatients: true,
        originLinkNameForAnalytics: referralBannerContactPcpTestId,
      });
      navigate(`/results/${updatedToken}`);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const linkName: string = referralRequiredPopOverTestId;
      const linkLocation: string = `body:${Constants.PROVIDER_DETAILS.BANNER.REFERRAL_REQUIRED}`;
      const modalName: string =
        Constants.PROVIDER_DETAILS.BANNER.REFERRAL_REQUIRED;
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  return (
    <ContainerForAllSectionsStyled>
      <ContentWrapper>
        <ReferralBannerBoxBanner>
          <Flex
            css={{ padding: '8px 0 8px 0 ' }}
            data-auto-testid="referral-banner"
            data-testid="referral-banner"
            justify="space-between"
          >
            <ReferralBannerReferralRequired
              data-auto-testid="referral-required"
              data-testid="referral-required"
            >
              <ReferralRequiredIcon color="$primary1" icon="back_hand" />
              {t('Referral required')}
              <Popover
                content={referralInfoLagoonText}
                css={{
                  'abyss-popover-trigger-icon': {
                    width: '13.33px',
                    height: '13.33px',
                    marginLeft: '5.33px',
                    '@screen < $sm': { marginBottom: '4px' },
                  },
                }}
                data-auto-testid={referralRequiredPopOverTestId}
                data-testid={referralRequiredPopOverTestId}
                onOpenChange={() => setIsOpen(!isOpen)}
                open={isOpen}
                title={t('PROVIDER_DETAILS.BANNER.POPOVER_TITLE')}
              />
            </ReferralBannerReferralRequired>
            <ReferralBannerContactPCP
              data-auto-testid={referralBannerContactPcpTestId}
              data-testid={referralBannerContactPcpTestId}
              onClick={handleContactPcpOnClick}
            >
              {t('PROVIDER_DETAILS.BANNER.CONTACT_MY_PCP')}
              <IconMaterial
                icon="chevron_right"
                size={mobileScreen ? '$sm' : '$md'}
              />
            </ReferralBannerContactPCP>
            {/* Uncomment once chat is back in scope
          <ReferralBannerNeedHelp>
            <IconBrand css={{ marginRight: '4px' }} icon="chat" size="18px" />

            {t('PROVIDER_DETAILS.BANNER.NEED_HELP')}
          </ReferralBannerNeedHelp> */}
          </Flex>
        </ReferralBannerBoxBanner>
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
