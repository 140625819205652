import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import React, { ReactNode, useEffect, useState } from 'react';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { FilterCollectionModel } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { DistanceInfoIcon } from './DistanceInfoIcon';
import { HealthGradesDisclaimer } from './Healthgrades';

type Radio = {
  // eslint-disable-next-line react/no-unused-prop-types
  label: String | ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  value: string | number | boolean;
};

interface Props {
  groupLabel?: string;
  options: Array<Radio>;
  defaultValue?: string | number | boolean;
  model: string;
  submitBtnName?: string;
  disableSubmitBtn?: boolean;
  onOptionChange?: (any) => void;
  onApply?: (value: string) => void;
  labelFontSize?: string;
  locationForAnalytics?: string;
}

export const FilterRadioGroup = ({
  groupLabel,
  options,
  defaultValue,
  model,
  submitBtnName,
  disableSubmitBtn = false,
  onOptionChange = () => {},
  onApply = () => {},
  labelFontSize = '16px',
  locationForAnalytics,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const [radioValue, setRadioValue] = useState(
    defaultValue &&
      options.filter((option) => option.value === defaultValue).length > 0
      ? String(defaultValue)
      : ''
  );
  const buttonStyles: any = {
    'abyss-button-root': {
      height: '42px',
      marginTop: '20px',
      border: 'none',
      borderRadius: '24px',
      width: mobileScreen ? '100%' : '',
      fontWeight: 600,
    },
  };

  useEffect(() => {
    setRadioValue(
      defaultValue &&
        options.filter((option) => option.value === defaultValue).length > 0
        ? String(defaultValue)
        : ''
    );
  }, [defaultValue]);

  // TODO: This data will be collated later for sending to API
  const onSubmit = () => {
    onApply(radioValue);
  };

  function onChangeRadioGroupOption(val) {
    if (val) {
      adobeLinkTrackEvent({
        name: options.filter((option) => val === option.value.toString())?.[0]
          ?.dnt_label,
        location: locationForAnalytics,
        type: 'radio',
      });
    }

    setRadioValue(val);
    onOptionChange({
      [model]: options.filter((option) => val === option.value.toString()),
    });
  }

  return (
    <React.Fragment>
      <Flex
        css={{
          'abyss-flex-root': {
            overflow: 'auto',
            maxHeight: '350px',
          },
        }}
        direction="column"
      >
        <RadioGroup
          css={{
            'abyss-radio-group-label': {
              margin: '0',
            },
            'abyss-radio-group-root': {
              border: 'none',
            },
          }}
          data-auto-testid="radio-group"
          data-testid={`${model}-group`}
          label={groupLabel}
          onChange={(e: { target: { value: string } }) => {
            onChangeRadioGroupOption(e.target.value);
          }}
          validators={{ required: false }}
          value={radioValue}
        >
          {options.map(({ value, label }: Radio) => (
            <RadioGroup.Radio
              key={label}
              css={{
                'abyss-radio-root': {
                  padding: '12px 0px 12px $xs',
                  margin: '0 !important',
                  alignItems: 'center !important',
                },
                'abyss-radio-label': {
                  fontSize: labelFontSize,
                  lineHeight: '24px',
                  color: value.toString() === radioValue ? '$gray8' : '$gray7',
                  '.abyss-text-root': {
                    fontSize: labelFontSize,
                    lineHeight: '24px',
                    color: value === radioValue ? '$gray8' : '$gray7',
                  },
                },
              }}
              data-testid={`${
                model === 'SortBy'
                  ? `sortBy-${value
                      .toString()
                      .replace(/ /g, '-')
                      .toLocaleLowerCase()}`
                  : model
              }-radio`}
              label={label}
              value={value.toString()}
            />
          ))}
        </RadioGroup>
        {model === FilterCollectionModel.DISTANCE ? <DistanceInfoIcon /> : null}
        {model === FilterCollectionModel.PATIENT_REVIEWS ? (
          <HealthGradesDisclaimer />
        ) : null}
      </Flex>
      {submitBtnName ? (
        <Button
          css={buttonStyles}
          data-auto-testid="apply-button"
          data-testid={`${model}-apply-button`}
          isDisabled={disableSubmitBtn}
          onClick={onSubmit}
        >
          {submitBtnName}
        </Button>
      ) : null}
    </React.Fragment>
  );
};
