import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';

export const LocationInputContainerMobile = styled('div', {
  backgroundColor: '#FBFCFE',
  paddingBottom: '12px',
  color: '$primary2',
  button: {
    backgroundColor: '$primary1',
    color: '$primary2',
    padding: '0',
    border: '0',
    textDecorationLine: 'underline',
    textDecorationThickness: '2px',
    fontWeight: 'bold',
    '&:hover': {
      textDecorationColor: '$primary2',
      color: '$interactive1',
    },
  },
});

export const LocationInputContainerDesktop = styled(Flex, {
  marginRight: '24px',
  flexWrap: 'nowrap',
  height: '71px',
  backgroundColor: '#FFFFFF',
  borderRight: 'solid',
  borderLeft: 'none',
  borderTop: 'solid',
  borderBottom: 'solid',
  borderColor: '#CBCCCD',
  borderRadius: '0px 50px 50px 0px',
  paddingRight: '20px',
  float: 'right',
  minWidth: '320px',
  width: '35%',
  '@screen < $md': {
    marginRight: '16px',
  },
  '.abyss-search-input-root': {
    height: '38px',
  },
  '.abyss-search-input-input': {
    height: '38px',
  },
});

export const LocationLabel = {
  fontWeight: '$bold',
  fontSize: '12.64px',
  paddingLeft: '15px',
  paddingTop: '8px',
};
