import { config } from '@abyss/web/tools/config';
import { t } from 'i18next';
/* istanbul ignore file */
/* eslint-disable no-param-reassign */
import mapboxgl from 'mapbox-gl';

import { adobeLinkTrackEvent } from '../common/AdobeTagging/adobeLinkTrackEvent';
import { Directions } from '../models/RouteDirections';
import { mapIcons } from './mapIcons.utils';

export const USER_LAYER = 'curLocation';
export const CLUSTER_LAYER = 'clusters';
export const RESULT_LAYER = 'unclustered-point';
export const ROUTE_LAYER = 'nav-route';
export const PLACES_SOURCE = 'places';
export const USER_LOCATION_PNG = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/user_location.png`;
export const RESULT_LOCATION_PNG = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/location_pin.png`;

let clickResultId = null;
let hoverResultId = null;
let isUnclusteredPointClick = false;
let curNavType = 'driving-traffic';
let mapboxKey = '';

const loadUserLocationPin = (map, userLng, userLat) => {
  map.loadImage(USER_LOCATION_PNG, (error, image) => {
    if (error) {
      throw error;
    }

    map.addImage('userLocation', image);
  });

  map.addSource(USER_LAYER, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [userLng, userLat],
          },
        },
      ],
    },
  });

  map.addLayer({
    id: 'curLocationLayer',
    type: 'symbol',
    source: USER_LAYER,
    layout: {
      'icon-image': 'userLocation',
      'icon-allow-overlap': true,
    },
  });
};

// set zoom level based on marker, it make all the marker visiable on intialload
export const setZoomLevel = async (map, coordinates) => {
  if (coordinates?.length) {
    const bounds = new mapboxgl.LngLatBounds();
    coordinates?.forEach((f: [number, number]) => bounds.extend(f));
    await map?.fitBounds(bounds, { maxZoom: 20, padding: 100 });
  }
};

const loadResultLocationPins = async (map, features) => {
  await map.isStyleLoaded();
  await map.loadImage(RESULT_LOCATION_PNG, (error, image) => {
    if (error) {
      throw error;
    }

    map.addImage('resultIcon', image);
  });

  map?.addSource('places', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features,
    },
    cluster: true,
    clusterMaxZoom: 22,
    clusterRadius: 50,
  });

  map.addLayer({
    id: CLUSTER_LAYER,
    type: 'circle',
    source: 'places',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#0C55B8',
        100,
        '#0C55B8',
        750,
        '#0C55B8',
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    },
  });

  map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source: 'places',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['Arial Unicode MS Bold'],
      'text-size': 16,
      'text-allow-overlap': true,
    },
    paint: {
      'text-color': '#FFFFFF',
    },
  });

  map.addLayer({
    id: RESULT_LAYER,
    type: 'symbol',
    source: 'places',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'resultIcon',
      'icon-allow-overlap': true,
      'icon-anchor': 'bottom',
    },
  });
};

const initClusterEvents = (
  map,
  setClusterStatus,
  setFeature,
  search,
  sectionType
) => {
  map.on('click', CLUSTER_LAYER, (e) => {
    adobeLinkTrackEvent({
      name: 'map cluster carousel pin',
      location: `body:map:${sectionType} results for ${search}`,
    });
    const features = map.queryRenderedFeatures(e.point, {
      layers: [CLUSTER_LAYER],
    });
    const clusterId = features[0].properties.cluster_id;
    map.getSource('places').getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }
      if (zoom > 17) zoom = 17;
      map.easeTo({
        center: features[0].geometry.coordinates,
        zoom,
      });
    });
  });

  map.on('mouseenter', CLUSTER_LAYER, (e) => {
    map.getCanvas().style.cursor = '';
    const path = window.location.pathname?.split('/');
    const pathname = path.length > 1 && path[2];

    // close popup if open already for othe cluster
    const popup = document.getElementsByClassName('mapboxgl-popup');
    if (popup.length) {
      popup[0].remove();
      setClusterStatus(false);
    }
    map.getCanvas().style.cursor = 'pointer';
    const features = map.queryRenderedFeatures(e.point, {
      layers: [CLUSTER_LAYER],
    });
    const clusterId = features[0].properties.cluster_id;
    const clusterSource = map.getSource(PLACES_SOURCE);
    const coordinates = e.features[0].geometry.coordinates.slice();
    const pointCount = features[0]?.properties?.point_count;
    if (pathname === 'results') {
      const dataCardHTML = document.querySelectorAll(
        '[id^=data-card-for-map-]'
      );
      const height = (dataCardHTML[0] as HTMLElement).offsetHeight + 50 || 248;
      const width = (dataCardHTML[0] as HTMLElement).offsetWidth || 408;
      const marker = new mapboxgl.Marker().setLngLat(coordinates).setPopup(
        new mapboxgl.Popup({
          closeButton: false,
        }).setHTML(
          `<div class="datacard-popup" style="height:${height}px;width:${width}px;border:none;visibility:hidden;"></div>`
        )
      );
      clusterSource.getClusterLeaves(
        clusterId,
        pointCount,
        0,
        (err, aFeatures) => {
          if (!marker.getPopup().isOpen()) {
            setClusterStatus(true);
            marker.getPopup().addTo(map);
            setFeature(aFeatures);
          }
        }
      );
    }
  });
  map.on('mouseleave', CLUSTER_LAYER, () => {
    map.getCanvas().style.cursor = '';
    const popup = document.getElementsByClassName('mapboxgl-popup');
    if (popup.length < 1) {
      setClusterStatus(false);
    }
  });
};

export const setResultIcon = (
  map,
  targetId,
  isHighlighting,
  highlightPinCallback,
  providerId
) => {
  const iconSize = isHighlighting ? 1.2 : 1;
  map?.setLayoutProperty(RESULT_LAYER, 'icon-size', [
    'match',
    ['id'],
    targetId,
    iconSize,
    1,
  ]);

  if (map) {
    highlightPinCallback({ providerId, from: 'mapView' });
  }
};

export const getDistanceString = (distance) => {
  const miles = distance / 1609.344;
  return `${miles.toFixed(1)} mi`;
};

export const getStepDistanceString = (distance: number) => {
  if (distance > 0) {
    const miles = distance / 1609.344;
    return `${miles.toFixed(1)} mi`;
  }
  return '';
};

export const getStepIcon = (modifier: string | undefined, type: string) => {
  const direction = `${type ? type.replace(/ /g, '_') : ''}${
    modifier ? `_${modifier.replace(/ /g, '_')}` : ''
  }`;
  const icon = Object.keys(mapIcons).includes(direction)
    ? mapIcons[direction]
    : '';
  return icon;
};

export const getDurationString = (duration, isSecondRequired = false) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const formattedTime = [
    { time: hours, title: hours > 1 ? 'hours' : 'hour' },
    { time: minutes, title: minutes > 1 ? 'minutes' : 'minute' },
    { time: seconds, title: seconds > 1 ? 'seconds' : 'second' },
  ];

  if (!isSecondRequired) formattedTime.pop();

  const result = formattedTime
    .filter(({ time }) => Boolean(time))
    .map((item) => `${item.time} ${item.title}`)
    .join(' ');

  return result;
};

export const getNavTypeString = (navType) => {
  switch (navType) {
    case 'driving-traffic':
      return 'drive with traffic';
    case 'walking':
      return 'walk';
    case 'cycling':
      return 'bike ride';
    default:
      return 'drive';
  }
};

export const clearRoute = (
  map,
  selectPinCallback?,
  highlightPinId?,
  highlightPinCallback?
) => {
  if (map.getSource(ROUTE_LAYER)) {
    map.removeLayer(ROUTE_LAYER);
    map.removeSource(ROUTE_LAYER);
  }

  if (highlightPinCallback) {
    setResultIcon(map, highlightPinId, false, highlightPinCallback, null);
  }

  if (selectPinCallback) {
    selectPinCallback([null, null]);
  }
};

export const loadRoute = (
  map,
  routes,
  choosenRoute,
  userLng,
  userLat,
  endLng,
  endLat,
  mediumScreen = false
) => {
  clearRoute(map);

  const data = routes[choosenRoute];
  const route = data.geometry.coordinates;
  const geojson = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: route,
    },
  };

  if (map.getSource(ROUTE_LAYER)) {
    map.getSource(ROUTE_LAYER).setData(geojson);
  } else {
    map.addLayer({
      id: ROUTE_LAYER,
      type: 'line',
      source: {
        type: 'geojson',
        data: geojson,
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#3887be',
        'line-width': 5,
        'line-opacity': 0.75,
      },
    });
  }

  map.fitBounds(
    [
      [userLng, userLat],
      [endLng, endLat],
    ],
    { padding: mediumScreen ? 50 : 120 }
  );
};

export const getRoute = async (
  map,
  userLng,
  userLat,
  endLng,
  endLat,
  mediumScreen = false,
  navType?,
  openDirections?,
  selectedRoute = 0
): Promise<void | Directions> => {
  if (!map) {
    return;
  }

  if (navType) {
    curNavType = navType;
  }

  const routeQuery = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/${curNavType}/${userLng},${userLat};${endLng},${endLat}?steps=true&geometries=geojson&alternatives=true&access_token=${mapboxKey}`,
    { method: 'GET' }
  );
  const userPlaceQuery = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${userLng},${userLat}.json?types=address&&access_token=${mapboxKey}`,
    { method: 'GET' }
  );
  const endPlaceQuery = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${endLng},${endLat}.json?types=address&&access_token=${mapboxKey}`,
    { method: 'GET' }
  );
  const routeJson = await routeQuery.json();
  const userPlaceJson = await userPlaceQuery.json();
  const endPlaceJson = await endPlaceQuery.json();
  const { routes } = routeJson;
  const choosenRoute = selectedRoute < routes.length ? selectedRoute : 0;
  const directions: Directions = {
    userPlaceName: userPlaceJson.features[0].place_name,
    endPlaceName: endPlaceJson.features[0].place_name,
    routes: routes.map((route) => ({
      steps: route.legs[0].steps,
      duration: route.duration,
      distance: route.distance,
      geometry: route.geometry,
    })),
  };

  loadRoute(
    map,
    routes,
    choosenRoute,
    userLng,
    userLat,
    endLng,
    endLat,
    mediumScreen
  );

  // eslint-disable-next-line consistent-return
  return directions;
};

const initResultEvents = (
  map,
  userLng,
  userLat,
  highlightPinCallback,
  selectPinCallback,
  setPopupContent,
  updatePin,
  setClusterStatus
) => {
  map.on('click', RESULT_LAYER, async (e) => {
    const coordinates = e.features[0].geometry.coordinates.slice();
    const { type, providerId, description } = e.features[0].properties;
    const practitioner = JSON.parse(description);
    clickResultId = e.features[0].id;
    isUnclusteredPointClick = true;
    highlightPinCallback({ providerId, from: 'mapView' });
    updatePin?.(practitioner);
    setClusterStatus(false);

    setResultIcon(map, hoverResultId, false, highlightPinCallback, null);
    setResultIcon(map, clickResultId, true, highlightPinCallback, providerId);
    if (type === 'location') {
      selectPinCallback([coordinates[0], coordinates[1]], providerId);
      await getRoute(map, userLng, userLat, coordinates[0], coordinates[1]);
    }
  });

  // On any map click, if not a result click and no active route clear pin highlight
  map.on('click', () => {
    if (map.getSource(ROUTE_LAYER)) {
      return;
    }

    if (!isUnclusteredPointClick && clickResultId !== null) {
      setResultIcon(map, clickResultId, false, highlightPinCallback, null);
      clickResultId = null;
    }
    if (!isUnclusteredPointClick) {
      setPopupContent?.(null);
      highlightPinCallback({ providerId: '', from: 'mapView' });
    }
    isUnclusteredPointClick = false;
  });

  // Highlight pin on result hover when no existing click highlight
  map.on('mouseenter', RESULT_LAYER, (e) => {
    map.getCanvas().style.cursor = 'pointer';
    const { description } = e.features[0].properties;
    const practitioner = JSON.parse(description);

    if (clickResultId === null) {
      if (hoverResultId !== null) {
        setResultIcon(map, hoverResultId, false, highlightPinCallback, null);
      }

      hoverResultId = e.features[0].id;
      setResultIcon(
        map,
        hoverResultId,
        true,
        highlightPinCallback,
        e.features[0].properties.providerId
      );
      updatePin?.(practitioner);
      setClusterStatus(false);
    }
  });

  // Clear pin highlight on hover-off when no existing click highlight
  map.on('mouseleave', RESULT_LAYER, () => {
    map.getCanvas().style.cursor = '';

    if (hoverResultId !== null && clickResultId === null) {
      setResultIcon(map, hoverResultId, false, highlightPinCallback, null);
    }
  });
  map.getCanvas()?.setAttribute('aria-label', t('ACCESSIBILITY_LABELS.MAP'));
};

export const loadMapPins = async (
  map,
  mapKey,
  userLng,
  userLat,
  features,
  coordinates,
  highlightPinCallback,
  selectPinCallback,
  disabledPinAction,
  setClusterStatus,
  setFeature,
  setPopupContent,
  updatePin,
  search,
  sectionType
) => {
  if (!map.getSource(USER_LAYER)) {
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    mapboxKey = mapKey;
    await setZoomLevel(map, coordinates);
    await loadResultLocationPins(map, features);
    loadUserLocationPin(map, userLng, userLat);
    initClusterEvents(map, setClusterStatus, setFeature, search, sectionType);
    if (!disabledPinAction)
      initResultEvents(
        map,
        userLng,
        userLat,
        highlightPinCallback,
        selectPinCallback,
        setPopupContent,
        updatePin,
        setClusterStatus
      );
  }
};

export const setMapboxKey = (mapKey: string) => {
  mapboxKey = mapKey;
};
