import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import {
  Constants,
  NEW_TAB_CONFIRMATION,
  PRACTITIONER_NAME_FOR_ANALYTICS,
} from '../../../../../../../common/Constants';
import { phoneOnly } from '../../../../../../../common/ConstantsStyles';
import { ImageComponent } from '../../../../../../../common/ImageComponent';
import { handleLinkAndModalTrack } from '../../../../../../../common/Utils/adobeTrackUtils';
import {
  CustomAttributesBlock,
  convertProviderTypeToAdobeType,
  formatProviderId,
} from '../../../../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useIsOverflow } from '../../../../../../../hooks/useIsOverflow';
import { CompareProvider } from '../../../../../../../models/Provider';
import { ResponseHeaders } from '../../../../../../../models/ResponseHeaders';
import { getFormattedPhoneNumber } from '../../../../../../../utils/phone.utils';
import { parseProviderName } from '../../../../../../../utils/providerDetails.utils';
import {
  AvatarCompareCard,
  CompareCardCollapsedTextStyle,
  CompareCardExpandedStyle,
  CompareCardNibbleStyle,
  CompareCardTextStyle,
  CompareNameDetailsDiv,
  HighlightedBorderStyle,
  nullPhoneNumberStyle,
} from '../../utility/compareDrawer.styled';
import {
  CompareCheckboxInfo,
  NULL_ATTRIBUTE_STATE,
} from '../../utility/compareDrawerConstants';
import { RemoveLink } from '../Buttons/RemoveLink';
import { ConfirmationModal } from '../ConfirmationModal';
import { CompareExpandedCard } from './CompareExpandedCard/CompareExpandedCard';
import { CompareAddress } from './CompareLocationDetails/CompareAddress';

type Props = {
  compareCheckboxes: CompareCheckboxInfo[];
  selectedProvider: CompareProvider;
  removeItem: (item: string) => void;
  isExpandedCardOpen?: boolean;
  isCompareViewOpen?: boolean;
  handleDetailsOnClick: (providerId: string, options?: Object) => void;
  setCardHeight: Function;
  index: number;
  headers: ResponseHeaders;
};

export const CompareCard = ({
  compareCheckboxes,
  selectedProvider,
  removeItem,
  isExpandedCardOpen,
  isCompareViewOpen,
  handleDetailsOnClick,
  headers,
  setCardHeight,
  index,
}: Props) => {
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const mobile = useMediaQuery(phoneOnly);
  const [highlightId, setHighlightId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID,
    { providerId: '', from: '' }
  );
  const cardRef = useRef(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const providerType = selectedProvider?.providerType;
  const imgSrc = selectedProvider?.imageLocation;
  useEffect(() => {
    const height = cardRef?.current?.getBoundingClientRect()?.height;
    setCardHeight(height);
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);
  const displayedPhoneNumber = getFormattedPhoneNumber(
    selectedProvider.phones?.phone[0]
  );

  const HeartIcon = () => (
    <IconBrand
      css={{
        '&.abyss-icon-root': {
          width: '146px',
          height: '112px',
        },
        '&.abyss-icon-brand': {
          marginLeft:
            mobile && isExpandedCardOpen && !isCompareViewOpen ? '27%' : 'none',
        },
      }}
      icon="heart"
      size="$xl"
    />
  );

  const HandleIcon = () => (
    <IconMaterial
      color="$gray5"
      css={{
        '&.abyss-icon': {
          marginLeft: '25px',
        },
      }}
      data-auto-testid={`compare-card-handle-icon-${selectedProvider.providerId}`}
      data-testid={`compare-card-handle-icon-${selectedProvider.providerId}`}
      icon="drag_handle"
      size="20px"
    />
  );
  const customAttributesBlock: CustomAttributesBlock = {
    correlationId: headers?.correlationId,
    providerId: formatProviderId(selectedProvider),
    providerType: convertProviderTypeToAdobeType(
      selectedProvider?.providerType
    ),
  };

  const handleProviderNameAndCarrotClick = () => {
    handleLinkAndModalTrack(
      PRACTITIONER_NAME_FOR_ANALYTICS,
      `modal:compare card:position ${index + 1}`,
      NEW_TAB_CONFIRMATION,
      undefined,
      customAttributesBlock
    );
    setIsOpenConfirmationModal(true);
  };

  const CompareNameDetails = () => (
    <Layout.Group>
      <CompareNameDetailsDiv
        ref={containerRef}
        cssProps={{ isOverflow }}
        data-auto-testid="provider-card-detail"
        data-testid="provider-card-detail"
      >
        <Text
          css={
            isExpandedCardOpen && isCompareViewOpen
              ? CompareCardTextStyle
              : CompareCardCollapsedTextStyle
          }
          data-auto-testid={`provider-card-detail-button-${selectedProvider.providerId}`}
          data-testid={`provider-card-detail-button-${selectedProvider.providerId}`}
          onClick={() => handleProviderNameAndCarrotClick()}
        >
          {parseProviderName(
            selectedProvider.providerName,
            selectedProvider.providerType,
            selectedProvider.primaryDegrees?.[0]
          )}
        </Text>
      </CompareNameDetailsDiv>

      {isExpandedCardOpen && isCompareViewOpen && (
        <IconMaterial
          data-auto-testid="arrow-right"
          data-testid="arrow-right"
          icon="keyboard_arrow_right"
          onClick={() => handleProviderNameAndCarrotClick()}
        />
      )}
    </Layout.Group>
  );
  const CompareText = () => (
    <Layout.Stack alignItems="left" space={0}>
      {isOverflow ? (
        <Tooltip
          align="center"
          asChild={false}
          content={parseProviderName(
            selectedProvider.providerName,
            selectedProvider.providerType,
            selectedProvider.primaryDegrees?.[0]
          )}
          position="top"
          positionOffset={8}
        >
          <CompareNameDetails />
        </Tooltip>
      ) : (
        <CompareNameDetails />
      )}

      <Text
        css={
          displayedPhoneNumber === NULL_ATTRIBUTE_STATE.PHONE_NUMBER &&
          nullPhoneNumberStyle
        }
        data-auto-testid="provider-phonenumber"
        data-testid="provider-phonenumber"
      >
        {isExpandedCardOpen && isCompareViewOpen && displayedPhoneNumber}
      </Text>
    </Layout.Stack>
  );

  const handleClickCb = () => {
    setIsOpenConfirmationModal(false);
    handleDetailsOnClick(selectedProvider.providerId, {
      openInNewTab: true,
    });
  };

  return (
    <div ref={cardRef}>
      <Flex
        alignItems="center"
        data-auto-testid="compare-provider-card"
        data-testid="compare-provider-card"
        direction="column"
      >
        <Box
          key={selectedProvider.providerId}
          align="start"
          css={
            isExpandedCardOpen && isCompareViewOpen
              ? CompareCardExpandedStyle
              : CompareCardNibbleStyle
          }
          data-auto-testid={`compare-card-${selectedProvider.providerId}`}
          data-testid={`compare-card-${selectedProvider.providerId}`}
          style={
            selectedProvider.providerId === highlightId.providerId
              ? HighlightedBorderStyle
              : null
          }
          {...(isExpandedCardOpen &&
            isCompareViewOpen && {
              className: mobile
                ? 'compare-card-container-mobile'
                : 'compare-card-container',
            })}
          onClick={() => {
            setHighlightId({
              providerId: selectedProvider.providerId,
              from: '',
            });
          }}
        >
          <Layout.Stack alignItems="left">
            {mobile ? (
              <React.Fragment>
                <Layout.Group
                  alignItems="top"
                  alignLayout={isExpandedCardOpen ? 'left' : 'center'}
                  grow
                  space={46}
                >
                  <HeartIcon />
                  {isExpandedCardOpen && isCompareViewOpen && <HandleIcon />}
                </Layout.Group>
                <CompareText />
              </React.Fragment>
            ) : (
              <Layout.Group>
                <AvatarCompareCard>
                  {ImageComponent({ imgRef, providerType, imgSrc })}
                </AvatarCompareCard>
                <Layout.Space space={15} />
                <Layout.Stack alignItems="center">
                  <CompareText />
                  {isExpandedCardOpen && !isCompareViewOpen ? (
                    <CompareAddress
                      addressLine={selectedProvider.address?.line[0]}
                    />
                  ) : null}
                </Layout.Stack>
              </Layout.Group>
            )}
            {isExpandedCardOpen && isCompareViewOpen ? (
              <CompareExpandedCard
                adobeIndex={index}
                compareCheckboxes={compareCheckboxes}
                headers={headers}
                selectedProvider={selectedProvider}
              />
            ) : null}
          </Layout.Stack>
        </Box>
        <RemoveLink
          index={index}
          removeItem={removeItem}
          selectedProvider={selectedProvider}
        />
      </Flex>
      <ConfirmationModal
        closeButtonText={
          t('COMPARE_DRAWER_CONFIRMATION_MODAL.VIEW_PROVIDER_BUTTON') || ''
        }
        handleClickCb={handleClickCb}
        isOpen={isOpenConfirmationModal}
        isViewProvider
        locationForAnalytics={`modal:${NEW_TAB_CONFIRMATION}`}
        setIsOpen={setIsOpenConfirmationModal}
      />
    </div>
  );
};
