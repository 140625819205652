import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCoverageType } from '../../../hooks/useCoverageType';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../../models/ProviderDetails';
import {
  capitalizeEachWord,
  getDdpCode,
  getSubText,
} from '../../../utils/providerDetails.utils';
import { Constants, ReverseCoverageTypesCodes } from '../../Constants';
import { ConstantsRoutes } from '../../ConstantsRoutes';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
import { CopyToClipBoard } from './CopyToClipBoard';
import { InfoProviderContent } from './InfoProviderContent';
import {
  FlexContainer,
  IconAlignmentContainer,
  InfoContainer,
  InfoProviderContentContainer,
  InfoSubTextContainer,
  InfoTitleContainer,
  OtheInfoContainer,
  ProviderGroupContainer,
  ProviderGroupLink,
} from './ProviderLocationsDetails.style';
import { SupressEmailWeb } from './SupressEmailWeb';

type Props = {
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
  detailType: String;
  providerDetails?: ProviderDetails;
  facilityDetails?: FacilityDetails;
  isFromVirtualProviderGroup?: boolean;
  practiceLimitation?: string;
  inNetworkStartDate?: string;
};

export const AllOtherInfosContainer = ({
  selectedLocation,
  detailType,
  providerDetails,
  facilityDetails,
  practiceLimitation,
  inNetworkStartDate,
  isFromVirtualProviderGroup,
}: Props) => {
  const { t } = useTranslation();

  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const coverageType = useCoverageType();

  const handleRedirectToProviderGroupDetails = (providerGroupClicked) => {
    const newToken = tokenizer.update(token, {
      providerId: providerGroupClicked.id,
      sectionType: Constants.RESULT_SECTION.PROVIDER_GROUPS,
    });

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      providerGroupClicked.id
    );

    navigate(`${ConstantsRoutes.PROVIDER_GROUP_DETAILS.path}${newToken}`);
  };

  return (
    <React.Fragment>
      <InfoProviderContentContainer>
        <SupressEmailWeb
          isFromVirtualProviderGroup={isFromVirtualProviderGroup}
          selectedLocation={selectedLocation}
        />

        <InfoProviderContent
          icon="videocam"
          infoSubtext={selectedLocation.virtualCareOffered?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.OFFERS_VIRTUAL_CARE')}
        />
        {coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
        detailType === ProviderType.ORGANIZATION ? (
          <InfoProviderContent
            icon="playlist_add_check"
            infoSubtext={(
              selectedLocation as FacilityLocationsResponse
            )?.programs?.join(',')}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PROGRAMS')}
          />
        ) : null}
        <InfoProviderContent
          icon="date_range"
          infoSubtext={getSubText(selectedLocation.hasEveningAppointments)}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.OFFERS_EVENING_APPOINTMENT')}
        />
        <InfoProviderContent
          icon="accessibility"
          infoSubtext={selectedLocation.accessibility?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.ACCESSIBILITY')}
        />
        <InfoProviderContent
          icon="translate"
          infoSubtext={selectedLocation.languagesSpokenByOffice?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.LANGUAGES_SPOKEN_BY_OFFICE')}
        />
        {coverageType !== ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] && (
          <InfoProviderContent
            icon="local_hospital"
            infoSubtext={getSubText(selectedLocation.hasPCPServiceAvailability)}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PCP_SERVICES_AVAILABLE')}
            variant="outlined"
          />
        )}
        {providerDetails?.providerType === ProviderType.PRACTITIONER ? (
          <FlexContainer
            css={{
              marginBottom: '3px',
              '@screen < $sm': { marginBottom: '0' },
            }}
          >
            <IconAlignmentContainer>
              <IconMaterial
                color="$primary1"
                css={{ marginTop: '3px' }}
                icon="group"
                size={18}
              />
              <OtheInfoContainer>
                {t('PROVIDER_LOCATIONS_DETAILS.PROVIDER_GROUP')}
                {selectedLocation?.providerGroupsAffiliations?.length > 0 ? (
                  selectedLocation?.providerGroupsAffiliations?.map((group) => (
                    <ProviderGroupContainer>
                      <LinkWithTracking
                        analyticsInfo={{
                          name: group?.value,
                          location: `body:${Constants.PROVIDER_DETAILS.LOCATIONS_TAB.GROUP_AFFILIATION}`,
                        }}
                        css={ProviderGroupLink}
                        data-auto-testid="redirect-to-provider-group-details"
                        data-testid="redirect-to-provider-group-details"
                        isDisabled={group?.id === null}
                        isStandardAnchor
                        onClick={() =>
                          handleRedirectToProviderGroupDetails(group)
                        }
                      >
                        {capitalizeEachWord(group.value)}
                      </LinkWithTracking>
                    </ProviderGroupContainer>
                  ))
                ) : (
                  <InfoContainer>
                    {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                  </InfoContainer>
                )}
              </OtheInfoContainer>
            </IconAlignmentContainer>
          </FlexContainer>
        ) : null}

        <InfoProviderContent
          icon="pan_tool"
          infoSubtext={
            practiceLimitation && practiceLimitation?.trim().length > 0
              ? practiceLimitation
              : t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
          }
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PRACTICE_LIMITATIONS')}
        />
        {detailType === ProviderType.ORGANIZATION ? (
          <React.Fragment>
            <InfoProviderContent
              icon="accessible"
              infoSubtext={selectedLocation?.medicalEquipments?.join(', ')}
              infoTitle={t(
                'PROVIDER_LOCATIONS_DETAILS.MEDICAL_EQUIPMENT_OFFER'
              )}
            />
            <InfoProviderContent
              icon="science"
              infoSubtext={getDdpCode(selectedLocation?.ddpCode)}
              infoTitle={t('PROVIDER_LOCATIONS_DETAILS.DESIGNATED_DIAGNOSTIC')}
              variant="outlined"
            />
          </React.Fragment>
        ) : null}
        <InfoProviderContent
          icon="event"
          infoSubtext={inNetworkStartDate}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.IN_NETWORK')}
          variant="outlined"
        />
        {detailType === ProviderType.ORGANIZATION && (
          <InfoProviderContent
            icon="task"
            infoTitle={t('PROVIDER_DETAILS.LOCATIONS_TAB.SPECIALTY_INNETWORK')}
            variant="outlined"
          >
            <Flex css={{ gap: '4px' }}>
              <InfoSubTextContainer>
                {t('PROVIDER_DETAILS.LOCATIONS_TAB.ANESTHESIOLOGY')} -{' '}
              </InfoSubTextContainer>
              <InfoTitleContainer>
                {getSubText(
                  selectedLocation?.facilityProviderSpecialties
                    ?.hasAnesthesiology
                )}
              </InfoTitleContainer>
            </Flex>
            <Flex css={{ gap: '4px' }}>
              <InfoSubTextContainer>
                {t('PROVIDER_DETAILS.LOCATIONS_TAB.EMERGENCY_MEDICINE')} -{' '}
              </InfoSubTextContainer>
              <InfoTitleContainer>
                {getSubText(
                  selectedLocation?.facilityProviderSpecialties
                    ?.hasEmergencyMedicine
                )}
              </InfoTitleContainer>
            </Flex>
            <Flex css={{ gap: '4px' }}>
              <InfoSubTextContainer>
                {t('PROVIDER_DETAILS.LOCATIONS_TAB.PATHOLOGY')} -{' '}
              </InfoSubTextContainer>
              <InfoTitleContainer>
                {getSubText(
                  selectedLocation?.facilityProviderSpecialties?.hasPathology
                )}
              </InfoTitleContainer>
            </Flex>
            <Flex css={{ gap: '4px' }}>
              <InfoSubTextContainer>
                {t('PROVIDER_DETAILS.LOCATIONS_TAB.RADIOLOGY')} -{' '}
              </InfoSubTextContainer>
              <InfoTitleContainer>
                {getSubText(
                  selectedLocation?.facilityProviderSpecialties?.hasRadiology
                )}
              </InfoTitleContainer>
            </Flex>
          </InfoProviderContent>
        )}

        {coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] ? (
          <InfoProviderContent
            icon="speed"
            infoSubtext={getSubText(selectedLocation?.isExpressAccessProvider)}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.EXPRESS_ACCESS_PROVIDER')}
          />
        ) : null}

        {detailType === ProviderType.ORGANIZATION ? (
          <InfoProviderContent
            icon="location_city"
            infoSubtext={facilityDetails?.providerId}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.FACILITY_ID')}
            variant="outlined"
          >
            <Flex
              css={{
                gap: '$xs',
                width: '500px',
                '@screen < $sm': { width: 'auto' },
              }}
            >
              <InfoContainer>
                {facilityDetails?.providerId ? facilityDetails?.providerId : ''}
              </InfoContainer>

              <CopyToClipBoard
                gap="0"
                label="Copy to clipboard"
                text={
                  facilityDetails?.providerId ? facilityDetails?.providerId : ''
                }
                title="facility-id"
              />
            </Flex>
          </InfoProviderContent>
        ) : null}
        <InfoProviderContent
          icon="pin_drop"
          infoSubtext={selectedLocation.locationId}
          infoTitle={
            providerDetails?.providerType === ProviderType.PRACTITIONER
              ? t('PROVIDER_LOCATIONS_DETAILS.PROVIDER_LOCATION_ID')
              : t('PROVIDER_LOCATIONS_DETAILS.LOCATION_ID')
          }
          variant="outlined"
        >
          <Flex
            css={{
              gap: '$xs',
              width: '500px',
              '@screen < $sm': { width: 'auto' },
            }}
          >
            <InfoContainer>{selectedLocation.locationId}</InfoContainer>
            <CopyToClipBoard
              gap="0"
              label="Copy to clipboard"
              text={selectedLocation?.locationId}
              title="location-id"
            />
          </Flex>
        </InfoProviderContent>
      </InfoProviderContentContainer>
    </React.Fragment>
  );
};
