import { styled } from '@abyss/web/tools/styled';
import { Alert } from '@abyss/web/ui/Alert';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';

export const LocationsDrawerHeaderMobile = styled('div', {
  width: '279px',
  height: '24px',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '20.25px',
  lineHeight: '24px',
  color: '#323334',
  fontFamily: '$primary',
});

export const Container = styled('div', {
  padding: '0px 0px',
  gap: '$lg',
  background: '$primary2',
});

export const FlexContainer = styled(Flex, {
  flexWrap: 'nowrap',
  gap: '12px',
  width: '100%',
  flexBasis: '50%',
});

export const InnerContainer = styled('div', {
  marginTop: '$sm',
  border: '1px solid $cardBorder',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$lg',
  background: '$primary2',
  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
  '@screen < $sm': {
    gap: '20px',
  },
});

export const TierProvider = styled('div', {
  background: '#F2F8E6',
  padding: '2px $sm',
  borderRadius: '4px',
});

export const IconMaterialContainer = styled(IconMaterial, {
  marginTop: '3px',
});

export const CollapseCardHeaderStyle = {
  backgroundColor: 'white',
  borderRadius: 'unset',
  border: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  boxShadow: 'none',
  color: '#196ECF',
  minHeight: 'unset',
  padding: '0',
  width: '230px',
  '.abyss-card-root': {
    width: '215px',
    gap: '0px',
  },
  '.abyss-card-title': {
    margin: '11px 0px 0  30px',
    fontSize: '16px',
    fontWeight: '$bold',
    lineHeight: '20px',
    height: '20px',
  },
  '.abyss-card-collapse-button-container': {
    marginLeft: '0px',
    padding: '0px',
  },
  '.abyss-card-collapse-button': {
    width: '24px',
    span: {
      padding: '0px',
      gap: '0px',
      width: '24px',
      marginRight: '0px',
    },
  },
};

export const CollapseCardSectionStyle = {
  color: '#4B4D4F',
  padding: '0',
  borderTop: 'none !important',
};

export const IconMaterialStyle = {
  width: '18px',
  height: '18px',
  marginRight: '7px',
  marginLeft: '27px',
  '@screen < $sm': {
    width: '13.5px',
    height: '13.5px',
    marginRight: '6.25px',
  },
};

export const DividerContainer = styled('div', {
  marginRight: '8px',
  marginLeft: '8px',
});

export const ChipContainer = styled('div', {
  color: '$neutralGray',
  fontSize: '$extraSmallText',
  fontWeight: '$medium',
  lineHeight: '$md',
  background: 'rgba(0, 12, 39, 0.05)',
  padding: '4px 12px',
  borderRadius: '20px',
  fontStyle: 'medium',
  fontFamily: '$primary',
});

export const IconAlignmentContainer = styled('div', {
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
});

export const InfoAlignmentContainer = styled(Flex, {
  flexDirection: 'column',
});

export const InfoWebAlignmentContainer = styled(Flex, {
  width: '50%',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
  '@screen < $md': {
    width: '100%',
  },
});

export const AddressContainer = styled('div', {
  fontWeight: '$semibold',
  fontSize: '$lg',
  lineHeight: '24px',
  color: '#4B4D4F',
  whiteSpace: 'pre-line',
  '@screen < $sm': {
    width: '162px',
    fontSize: '$md',
    lineHeight: '$md',
  },
});

export const EveningAppointemnt = styled('div', {
  fontWeight: '$semibold',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$neutralGray7',
  '@screen < $sm': {
    fontSize: '$md',
    lineHeight: '20px',
  },
});

export const MilesContent = styled('div', {
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '20px',
  alignItems: 'center',
  textAlign: 'center',
  color: '$interactive1',
  '@screen < $sm': {
    height: '20px',
    fontSize: '$md',
    lineHeight: '20px',
  },
});

export const PhoneNumberContainer = styled('div', {
  fontWeight: '$bold',
  fontSize: '18px',
  lineHeight: '24px',
  width: '149.5px',
  height: '24px',
  color: '$interactive1',
});

export const PrimaryPhoneNumberContainer = styled('div', {
  marginTop: '8px',
  fontWeight: '$bold',
  fontSize: '16px',
  lineHeight: '20px',
  width: '149.5px',
  height: '$lg',
  color: '$interactive1',
});

export const PrimaryPhoneContainer = styled('div', {
  fontWeight: '$semibold',
  height: '48px',
  fontSize: '$md',
  lineHeight: '20px',
  color: '#4B4D4F',
  marginLeft: '12px',
});

export const InfoTitleContainer = styled('div', {
  fontWeight: '$semibold',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$neutralGray7',
  '@screen < $sm': {
    fontSize: '$md',
    lineHeight: '$md',
  },
});

export const InfoSubTextContainer = styled('div', {
  width: '500px',
  fontWeight: '$medium',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$neutralGray',
  '@screen < $sm': {
    width: '200px',
    fontSize: '$md',
    lineHeight: '$md',
  },
});

export const InfoContainer = styled('div', {
  fontWeight: '$bold',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$info1',
  '@screen < $sm': {
    fontSize: '$md',
    lineHeight: '$md',
  },
});

export const PhoneContainer = styled('div', {
  height: '48px',
  fontWeight: '$semibold',
  fontSize: '18px',
  lineHeight: '24px',
  color: '#4B4D4F',
  marginLeft: '12px',
});

export const PhoneAllContainer = styled('div', {
  display: 'flex',
  marginTop: '$sm',
  marginRight: '37px',
  '@screen < $md': {
    marginRight: 0,
  },
});

export const TimeContainer = styled('div', {
  marginLeft: '3px',
  fontWeight: '$medium',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$gray7',
  '@screen < $sm': {
    fontSize: '$md',
    lineHeight: '20px',
  },
});

export const MorePhoneLink = styled(Link, {
  '&.abyss-link-root': {
    fontSize: '$md',
    fontWeight: '$bold',
    height: '18px',
    lineHeight: '18px',
    marginTop: '8px',
    marginLeft: '32px',
  },
});

export const SectionContainer = styled('div', {
  borderWidth: '1px 0',
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  borderColor: '$cardBorder',
  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
});

export const SectionExpertiseContainer = styled('div', {
  padding: '0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  flexBasis: '50%',
  '@screen < $sm': {
    padding: '$md',
  },
});

export const TierOneAlertContainer = styled(Alert, {
  '&.abyss-alert-root': {
    borderWidth: '1px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    borderColor: '$cardBorder',
  },
  '.abyss-alert-container': {
    border: ' 1px solid #196ECF',
    display: 'flex',
    width: '343px',
    height: '52px',
    padding: '$md',
    background: '#EDF3FB',
    borderRadius: '4px',
  },
  '.abyss-alert-title': {
    color: '#323334',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    marginRight: 0,
    height: '18px',
  },
});

export const LocationsHeader = styled(Flex, {
  '&.abyss-flex-root': {
    gap: '24px',
    padding: '0px',
    width: '407px',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: '12px',
    '@screen < $sm': {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
});

export const ViewOnMapLabel = styled(Text, {
  fontStyle: 'normal',
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '$md',
  marginRight: '7px',
  '&.abyss-text-root': {
    '@screen < $sm': {
      fontSize: '$md',
      lineHeight: '20px',
    },
  },
});

export const MapViewBoxContainer = styled('div', {
  borderTop: '1px solid $cardBorder',
  boxSizing: 'border-box',
  height: 'unset',
  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
  '@screen < $sm': {
    background: 'white',
    padding: '0px',
  },
});

export const NetworkStartDateCard = styled('div', {
  fontWeight: '$bold',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$gray7',
  marginTop: '$lg',
  '@screen <= $sm': {
    fontSize: '$md',
    lineHeight: '$md',
    marginTop: '$xl',
    height: '40px',
  },
});

export const ProviderNetworkStartDateSection = styled('div', {
  fontWeight: '$light',
  paddingBottom: '$md',
});

export const InfoProviderContentContainer = styled(Flex, {
  flexWrap: 'wrap',
  width: '100%',
  rowGap: '$lg',
  paddingLeft: 'calc((100% - 1200px) / 2)',
  paddingRight: 'calc((100% - 1200px) / 2)',
  '@screen < $sm': {
    flexDirection: 'column',
    width: 'unset',
    rowGap: '20px',
    padding: '0',
  },
});

export const websiteStyles = {
  '&.abyss-link-root': {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '24px',
    alignItems: 'center',
    textAlign: 'center',
    color: '#196ECF',
    '@screen < $sm': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  '.abyss-link-icon': {
    height: '20px',
    width: '20px',
  },
};

export const GridColumnStyle = {
  '@screen < $sm': {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
};

export const OtheInfoContainer = styled('div', {
  fontWeight: '$semibold',
  fontSize: '18px',
  lineHeight: '24px',
  color: '#4B4D4F',

  '@screen < $sm': {
    fontSize: '$md',
    lineHeight: '20px',
  },
});

export const ProviderGroupContainer = styled('div', {
  display: 'flex',
  color: '#196ECF',
  marginTop: '3px',
  fontSize: '18px',
  fontWeight: '$bold',
  lineHeight: '24px',
  alignItems: 'center',
  textAlign: 'center',
  '@screen < $sm': {
    fontSize: '16px',
    lineHeight: '20px',
    margin: '0',
  },
});
export const ProviderIDContainer = styled('div', {
  display: 'flex',
  alignItem: 'center',
  color: '#196ECF',
  marginTop: '0',
  fontSize: '18px',
  fontWeight: '$bold',
  lineHeight: '24px',
  '@screen < $sm': {
    fontSize: '16px',
    lineHeight: '20px',
    width: '300px',
    overflow: 'auto',
  },
});
export const CopyToClipboardContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '#196ECF',
  marginTop: '0',
  fontSize: '18px',
  fontWeight: '$bold',
  lineHeight: '24px',
  '@screen < $sm': {
    fontSize: '16px',
    lineHeight: '20px',
  },
});

export const ProviderGroupLink = {
  'abyss-link-root': {
    width: 'auto',
    lineHeight: '$lg',
    fontSize: '$lg',
    fontWeight: '$lg',
    '@screen < $sm': {
      width: 'auto',
      lineHeight: '$md',
      fontSize: '$md',
      fontWeight: '$md',
    },
  },
};
export const Heading = styled('div', {
  width: '338px',
  height: '32px',
  fontWeight: '$bold',
  fontSize: '25.63px',
  lineHeight: '32px',
  color: '$primary1',
  marginBottom: '12px',
  '@screen < $sm': {
    height: '24px',
    fontSize: '20.25px',
    lineHeight: '24px',
    marginBottom: 'initial',
  },
});
export const SectionHeadingContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  borderColor: '$cardBorder',
  marginRight: 0,
  flexBasis: '50%',
  '@screen < $md': {
    padding: '$md',
  },
});

export const VoiceOnlyText = styled('span', {
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  userSelect: 'none',
  width: '1px',
});
