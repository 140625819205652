import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
} from '../../../models/ProviderDetails';
import {
  filterNotEmptyHoursOfOperations,
  getUncommonElements,
} from '../../../utils/providerDetails.utils';
import { Constants } from '../../Constants';
import { notPhone } from '../../ConstantsStyles';
import { AllOtherInfos } from './AllOtherInfos';
import { CollapsePhones } from './CollapsePhones';
import { InfoProviderContent } from './InfoProviderContent';
import { OpsHours } from './OpsHours';
import { PhoneNumberList } from './PhoneNumberList';

type Props = {
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
  detailType: String;
  providerDetails?: ProviderDetails;
  facilityDetails?: FacilityDetails;
};

export const InfoProvider = ({
  selectedLocation,
  detailType,
  providerDetails,
  facilityDetails,
}: Props) => {
  const { t } = useTranslation();
  const DesktopScreen = useMediaQuery(notPhone);
  const { phone, appointment, emergency } = selectedLocation?.phones || {};
  const tty = selectedLocation?.tty;

  let icon: string;
  let acceptingPatientInfo: string;

  if (
    selectedLocation?.acceptingNewPatients == null ||
    selectedLocation?.acceptingNewPatients === undefined
  ) {
    icon = 'do_disturb_alt';
    acceptingPatientInfo = 'PROVIDER_LOCATIONS_DETAILS.NO_INFO_AVAILABLE';
  } else if (selectedLocation?.acceptingNewPatients) {
    icon = 'done';
    acceptingPatientInfo = 'PROVIDER_LOCATIONS_DETAILS.ACCEPTING_PATIENTS';
  } else {
    icon = 'close';
    acceptingPatientInfo = 'PROVIDER_LOCATIONS_DETAILS.NOT_ACCEPTING_PATIENTS';
  }

  // utils calls
  const notEmptyHoursOfOperation =
    filterNotEmptyHoursOfOperations(selectedLocation);

  const availableDays = notEmptyHoursOfOperation?.map((days) =>
    days.day.toLowerCase()
  );

  const notAvailableDays = getUncommonElements(
    Constants.ALL_DAYS,
    availableDays
  );

  return (
    <React.Fragment>
      <InfoProviderContent
        icon={icon}
        infoTitle={t(acceptingPatientInfo)}
        showSubTitle={false}
      />
      {!(notAvailableDays.length >= 7) && (
        <OpsHours selectedLocation={selectedLocation} />
      )}
      {DesktopScreen ? (
        <PhoneNumberList
          appointment={appointment}
          emergency={emergency}
          phone={phone}
          tty={tty}
        />
      ) : (
        <CollapsePhones
          collapseContent={selectedLocation?.phones}
          collapseHeader={t('PROVIDER_LOCATIONS_DETAILS.SHOW_MORE_PHONE')}
          tty={tty}
        />
      )}
      <AllOtherInfos
        detailType={detailType}
        facilityDetails={facilityDetails}
        providerDetails={providerDetails}
        selectedLocation={selectedLocation}
      />
    </React.Fragment>
  );
};
