import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Layout } from '@abyss/web/ui/Layout';
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { CardWrapperDetailsPage } from '../../../../common/CardWrapper';
import { Constants } from '../../../../common/Constants';
import { FeatureFlags } from '../../../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { SectionSkeleton } from '../../../../common/LazyLoaderSkeleton';
import { RecentSearchSkeleton } from '../../../../common/LazyLoaderSkeleton/RecentSearchSkeleton';
import { PSXHeader } from '../../../../common/PSXHeader';
import {
  CoverageType,
  MemberInfo,
} from '../../../../common/PSXHeader/ObapiDemo/memberProfile';
import { RecentActivityVision } from '../../../../common/RecentActivityAndVisit/RecentActivityVision';
import { getFeatureFlag } from '../../../../common/Utils';
import { ChipCategoryContext } from '../../../../context/ChipCategoryContext';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { ErrorBoundary } from '../../../../errors/ErrorBoundary';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { VisionCareProviders } from '../VisionCareProviders';
import { HealthGradesContainerStyled } from './HealthGradesContainer.styled';

/** Lazy */
const BrowseLabAndServices = lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "BrowseLab" */ '../../../../common/BrowseLabsAndServices'
    )
  ).BrowseLabAndServices,
}));

const RecentActivityAndVisit = lazy(async () => ({
  default: (await import('../../../../common/RecentActivityAndVisit'))
    .RecentActivityAndVisit,
}));

const ReferralCard = lazy(async () => ({
  default: (await import('../../../../common/ReferralCard')).ReferralCard,
}));

const TalkToNurseCard = lazy(async () => ({
  default: (await import('../../../../common/TalkToNurseCard')).TalkToNurseCard,
}));

const ThingsToKnowCard = lazy(async () => ({
  default: (await import('../../../../common/ThingsToKnowCard'))
    .ThingsToKnowCard,
}));

const TopPrimaryCareMedicalGroupsCards = lazy(async () => ({
  default: (await import('../../../../common/TopPrimaryCardMedicalGroups'))
    .TopPrimaryCareMedicalGroupsCards,
}));

const TopPrimaryCareProviderCards = lazy(async () => ({
  default: (await import('../../../../common/TopPrimaryCardProviders'))
    .TopPrimaryCareProviderCards,
}));

const TopPrimaryCareClinicsSection = lazy(async () => ({
  default: (await import('../../../../common/TopPrimaryCareClinicsSection'))
    .TopPrimaryCareClinicsSection,
}));

const BrowseBehavioralHealthProviders = lazy(async () => ({
  default: (await import('../BrowseBehavioralHealthProviders'))
    .BrowseBehavioralHealthProviders,
}));

const BrowseHealthSupplies = lazy(async () => ({
  default: (await import('../BrowseHealthSupplies')).BrowseHealthSupplies,
}));

const BrowseMedicalSpecialists = lazy(async () => ({
  default: (await import('../BrowseMedicalSpecialists'))
    .BrowseMedicalSpecialists,
}));

const GalileoCard = lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "GalileoCard" */ '../../../../common/VirtualCareCard'
    )
  ).GalileoCard,
}));

const HealthGradesDisclaimer = lazy(async () => ({
  default: (
    await import('../../../../common/Healthgrades/HealthGradesDisclaimer')
  ).HealthGradesDisclaimer,
}));

const Notifications = lazy(async () => ({
  default: (await import('../../../../common/Notifications')).Notifications,
}));

const PrimaryCareProvider = lazy(async () => ({
  default: (await import('../../../../common/PrimaryCareProvider'))
    .PrimaryCareProvider,
}));

const BHCardsSection = lazy(async () => ({
  default: (await import('../../../../common/BHCardsSection')).BHCardsSection,
}));

const CareOptions = lazy(async () => ({
  default: (await import('../../../../common/CareOptions')).CareOptions,
}));

const ChipsGeneralContent = lazy(async () => ({
  default: (await import('../../../../common/ChipsGeneralContent'))
    .ChipsGeneralContent,
}));

const CommonlySearchedCard = lazy(async () => ({
  default: (await import('../../../../common/CommonlySearched'))
    .CommonlySearchedCard,
}));

/** Lazy Ends */

const Loader = () => <SectionSkeleton />;

export const PSXHome = () => {
  const { LOGGED_IN_MEMBER_INDEX } = Constants.STORAGE_KEYS.SESSION;

  const storedMembers = useStore(StoreKeys.OBAPI_MEMBERS);
  const [memberProfile, setMemberProfile] =
    useState<MemberInfo[]>(storedMembers);
  const loggedInMemberIndex = storage.session.get(LOGGED_IN_MEMBER_INDEX);
  const loggedInMemberInfo = memberProfile[loggedInMemberIndex];

  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token);
  const { chipValue } = tokenData || {};

  const [value, setChipValue] = useState(
    chipValue || Constants.CHIPS_CATEGORIES.ALL
  );

  useEffect(() => {
    const updatedToken = tokenizer.parse(token);
    if (updatedToken?.chipValue) {
      setChipValue(updatedToken.chipValue);
    } else {
      setChipValue(Constants.CHIPS_CATEGORIES.ALL);
    }
  }, [token]);

  const [virtualCareCardShow, setVirtualCareCardShow] =
    useState<boolean>(false);

  const [memberId, setMemberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  const [, setBreadcrumbUrls] = useSessionStorage<BreadcrumbSessionStorage>(
    Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
    {}
  );

  const { clearSearchFilters } = useContext(SearchFilterContext);

  const featureFlags = useLagoon('feature-flags')();
  const galileoFlag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY.GALILEO
  );
  const notificationsFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.NOTIFICATIONS
  );
  const thingsToKnowFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.THINGS_TO_KNOW_DECEMBER_RLS
  );

  const featureFlagsDecRelease: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const decemberFlag: boolean = getFeatureFlag(
    featureFlagsDecRelease,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );

  useEffect(() => {
    setBreadcrumbUrls({});
    setMemberId(memberId);
    clearSearchFilters();
  }, []);

  useEffect(() => {
    let showVirtualCareCard: boolean = false;
    if (memberProfile[0]?.eligibility[0]?.memberHealthCoverage?.coverageType) {
      const coverageType =
        memberProfile[0]?.eligibility[0]?.memberHealthCoverage.coverageType.find(
          (x: CoverageType) =>
            x.typeCode &&
            x.typeCode.desc === Constants.COVERAGE_TYPECODE_TO_CHIP.M
        );
      if (coverageType && coverageType?.designatedNetwork) {
        showVirtualCareCard = coverageType?.designatedNetwork.includes('DVCN');
      }
    }
    setVirtualCareCardShow(showVirtualCareCard);
  }, [memberProfile]);

  const handleMemberIdChange = (id) => {
    setMemberId(id);
  };

  const handleMemberDataChange = (data: MemberInfo[]) => {
    setMemberProfile(data);
  };

  return (
    <React.Fragment>
      <ErrorBoundary>
        <ChipCategoryContext.Provider value={{ value, setChipValue }}>
          <PSXHeader
            dataTestId="search-home-on-submit"
            onMemberDataChange={handleMemberDataChange}
            onMemberIdChange={handleMemberIdChange}
          />
        </ChipCategoryContext.Provider>
        <CardWrapperDetailsPage>
          {notificationsFlag ? <Notifications value={value} /> : null}
          <Layout.Stack grow space={0}>
            {value !== Constants.CHIPS_CATEGORIES.PRIMARY_CARE && (
              <Suspense fallback={<RecentSearchSkeleton />}>
                <RecentActivityAndVisit activeChip={value} />
              </Suspense>
            )}

            {value === Constants.CHIPS_CATEGORIES.ALL && (
              <ErrorBoundary>
                <CareOptions virtualCareCardShowFlag={virtualCareCardShow} />
                <CommonlySearchedCard selectedChipValue={value} />
                <ChipsGeneralContent
                  data-auto-testid="chips-general-content"
                  data-testid="chips-general-content"
                  selectedChipValue={value}
                  virtualPCPAssignedFlag={false}
                />
              </ErrorBoundary>
            )}
            {value === Constants.CHIPS_CATEGORIES.PRIMARY_CARE && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  <PrimaryCareProvider
                    data-auto-testid="search-home-primary-card"
                    data-testid="search-home-primary-card"
                    loggedInMemberInfo={loggedInMemberInfo}
                    memberInfo={memberProfile}
                  />
                  <RecentActivityAndVisit activeChip={value} />
                  <TopPrimaryCareProviderCards
                    data-auto-testid="home-top-primary-card"
                    data-testid="home-top-primary-card"
                  />
                  <TopPrimaryCareMedicalGroupsCards
                    data-auto-testid="home-top-primary-care-medical-groups"
                    data-testid="home-top-primary-care-medical-groups"
                  />
                  <TopPrimaryCareClinicsSection
                    data-auto-testid="top-primary-care-clinics-section"
                    data-testid="top-primary-care-clinics-section"
                  />
                </Suspense>
              </ErrorBoundary>
            )}

            {value === Constants.CHIPS_CATEGORIES.VISION &&
              (decemberFlag ? (
                <React.Fragment>
                  <RecentActivityVision />
                </React.Fragment>
              ) : null)}

            {value === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS && (
              <ErrorBoundary>
                {/* Active Referrals Cards are not part of V1 release */}

                {/* <ActiveReferralsCard
                  data-auto-testid="active-referals-card"
                  data-testid="active-referals-card"
                  referralsCount="2"
                  to="#"
                /> */}
                <Suspense fallback={<Loader />}>
                  <BrowseMedicalSpecialists
                    data-auto-testid="search-home-browse-common-specialties-card"
                    data-testid="search-home-browse-common-specialties-card"
                  />
                  {!!loggedInMemberInfo?.isGatedUser && (
                    <ReferralCard
                      data-auto-testid="search-home-referral-card"
                      data-testid="search-home-referral-card"
                    />
                  )}
                </Suspense>
              </ErrorBoundary>
            )}
            {value === Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  <BrowseBehavioralHealthProviders />
                </Suspense>
              </ErrorBoundary>
            )}
            {value === Constants.CHIPS_CATEGORIES.HEALTH_SUPPLIES && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  <BrowseHealthSupplies
                    data-auto-testid="browse-health-supplies-facility-section"
                    data-testid="browse-health-supplies-facility-section"
                  />
                </Suspense>
              </ErrorBoundary>
            )}
            {value === Constants.CHIPS_CATEGORIES.FACILITIES && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  <BrowseLabAndServices />
                  {thingsToKnowFlag && <ThingsToKnowCard />}
                </Suspense>
              </ErrorBoundary>
            )}
          </Layout.Stack>
          {value !== Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS &&
            value !== Constants.CHIPS_CATEGORIES.HEALTH_SUPPLIES &&
            value !== Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH &&
            value !== Constants.CHIPS_CATEGORIES.FACILITIES && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  {galileoFlag && (
                    <GalileoCard
                      data-auto-testid="search-home-galileo-card"
                      data-testid="search-home-galileo-card"
                    />
                  )}
                </Suspense>
              </ErrorBoundary>
            )}
          {value !== Constants.CHIPS_CATEGORIES.ALL && (
            <Suspense fallback={<Loader />}>
              <CommonlySearchedCard selectedChipValue={value} />
            </Suspense>
          )}
          {value === Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH && (
            <Suspense fallback={<Loader />}>
              <BHCardsSection />
            </Suspense>
          )}
          {value === Constants.CHIPS_CATEGORIES.VISION && (
            <Suspense fallback={<Loader />}>
              <VisionCareProviders />
            </Suspense>
          )}

          {value !== Constants.CHIPS_CATEGORIES.ALL && (
            <Suspense fallback={<Loader />}>
              <ChipsGeneralContent
                data-auto-testid="chips-general-content"
                data-testid="chips-general-content"
                selectedChipValue={value}
                virtualPCPAssignedFlag={virtualCareCardShow}
              />
            </Suspense>
          )}
          {value === Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH && (
            <Suspense fallback={<Loader />}>
              <TalkToNurseCard
                data-auto-testid="psx-home-talk-to-nurse-card"
                data-testid="psx-home-talk-to-nurse-card"
                isBehavioralHealth
              />
            </Suspense>
          )}
          {value === Constants.CHIPS_CATEGORIES.VISION &&
            (decemberFlag ? (
              <TalkToNurseCard
                data-auto-testid="talk-to-nurse-card-vision"
                data-testid="talk-to-nurse-card-vision"
              />
            ) : null)}
          {(value === Constants.CHIPS_CATEGORIES.PRIMARY_CARE ||
            value === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS ||
            value === Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH) && (
            <Suspense fallback={<Loader />}>
              <HealthGradesContainerStyled>
                <HealthGradesDisclaimer hideDivider />
              </HealthGradesContainerStyled>
            </Suspense>
          )}
        </CardWrapperDetailsPage>
      </ErrorBoundary>
    </React.Fragment>
  );
};
