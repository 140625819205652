import { config } from '@abyss/web/tools/config';
import { useEffect, useState } from 'react';

import { useCustomQuery } from '../useCustomQuery';
import GetRecentSearches from './RecentSearches.graphql';
import SaveRecentSearch from './SaveRecentSearch.graphql';

export interface RecentSearchesParams {
  psxSearchTerm: string;
  pesSearchTerm: string;
  searchType: string;
  category: string;
  location: string;
  latitude: string;
  longitude: string;
  coverageType: string;
}

export const useGetRecentSearches = (options) => {
  const [recentSearchesData, setRecentSearchesData] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GetRecentSearches, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'recentSearches',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setRecentSearchesData(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: recentSearchesData,
  };

  return [parsedResult, queryAction];
};

export const useSaveRecentSearches = (options) => {
  const [recentSearchesData, setRecentSearchesData] = useState({});
  const [queryResult, queryAction] = useCustomQuery(SaveRecentSearch, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'saveRecentSearch',
    clearCache: [
      {
        key: 'RecentSearches',
      },
    ],
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setRecentSearchesData(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: recentSearchesData,
  };

  return [parsedResult, queryAction];
};

export const useGetRecentSearchesResults = () => {
  const [recentSearches, getRecentSearches] = useGetRecentSearches({});

  useEffect(() => {
    getRecentSearches({});
  }, []);

  return recentSearches;
};

export const useSaveRecentSearchesResults = ({
  ...variables
}: RecentSearchesParams) => {
  const [saveRecentSearches, getSaveRecentSearches] = useSaveRecentSearches({});

  const { pesSearchTerm } = variables;
  // eslint-disable-next-line no-param-reassign
  variables.category = variables.category ?? 'category';

  useEffect(() => {
    if (pesSearchTerm) {
      getSaveRecentSearches({ variables });
    }
  }, [pesSearchTerm]);

  return saveRecentSearches;
};
