import React from 'react';
import PropTypes from 'prop-types';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';
import { styled } from '@abyss/web/tools/styled';


const ListItem = styled('li', {
  display: 'flex',
  alignItems: 'center',
  padding: '$sm',
  fontSize: '$sm',
  fontFamily: '$primary',
  cursor: 'pointer',
  scrollMargin: '$sm',
  color: '$gray8',
  '&:focus-visible': {
    outline: 'none',
    boxShadow: 'none',
  },
  variants: {
    isSelected: {
      true: {
        backgroundColor: '$selectedOption',
        color: '$interactive1',
        '&:hover': {
          backgroundColor: '$info2',
        },
      },
    },
    isFocused: {
      true: {
        backgroundColor: '$gray4',
      },
    },
    isDisabled: {
      true: {
        color: '$gray5',
        cursor: 'not-allowed',
        '&:hover': {
          backgroundColor: '$gray3',
        },
      },
    },
  },

  compoundVariants: [
    {
      isSelected: true,
      isFocused: true,
      css: {
        backgroundColor: '$gray4',
        '&:hover': {
          backgroundColor: '$gray4',
        },
      },
    },
    {
      isFocused: true,
      isDisabled: true,
      css: {
        backgroundColor: '$gray3',
      },
    },
  ],
});

const ListItemContainer = styled('div', {
  display: 'grid',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  span: {
    marginLeft: '$xs',
  },
});

// const RemoveButton = styled('button', {
//   color: '$gray6',
//   fontSize: '$sm',
//   '&:hover': {
//     textDecoration: 'underline',
//     color: '$interactive2',
//   },
//   '&:focus-visible': {
//     outline: 'none',
//     boxShadow: 'none',
//   },
// });

const StyledIconMaterial = styled(IconMaterial, {
  marginRight: '10px',
  variants: {
    isDisabled: {
      true: {
        fill: '$gray5 !important',
      },
    },
  },
});

const LocalStorageOptionContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const LocalStorageOption = styled('button', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

export const Option = ({
  isFocused,
  labelKey,
  itemProps,
  currentItem,
  customRender,
  uniqueStorageId,
  showLocalStorage,
  // handleRemoveItem,
  index,
  isSelected,
  ...props
}) => {
  const abyssProps = useAbyssProps(props);

  const { onClick, ...rest } = itemProps;
  const label = currentItem[labelKey];
  const showSearchIcon = currentItem['showSearchIcon'];
  const isLocalStorageItem = showLocalStorage && uniqueStorageId;
  const isDisabled = !!currentItem?.isDisabled;

  return (
    <ListItem
      {...abyssProps('search-input-option')}
      {...rest}
      onClick={!isLocalStorageItem && !isDisabled ? onClick : null}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      isFocused={isFocused}
      isDisabled={isDisabled}
      aria-disabled={isDisabled}
    >
      {isLocalStorageItem ? (
        <LocalStorageOptionContainer
          {...abyssProps('search-input-local-storage-option-container')}
        >
          <LocalStorageOption type="button" onClick={onClick}>
            {showSearchIcon && <StyledIconMaterial
              {...abyssProps('search-input-option-icon')}
              icon="history"
              color="$interactive2"
              title="Recent Search"
              isDisabled={isDisabled}
            />
            }
            <ListItemContainer {...abyssProps('search-input-option-content')}>
              {customRender ? customRender(currentItem) : label}
            </ListItemContainer>
          </LocalStorageOption>
          {/* <RemoveButton
            {...abyssProps('search-input-result-remove')}
            type="button"
            onClick={() => {
              return handleRemoveItem(currentItem);
            }}
          >
            Remove
          </RemoveButton> */}
        </LocalStorageOptionContainer>
      ) : (
        <React.Fragment>
          {showSearchIcon && <StyledIconMaterial
            icon="search"
            color="$interactive1"
            isDisabled={isDisabled}
            {...abyssProps('search-input-option-icon')}
          />
          }
            <ListItemContainer {...abyssProps('search-input-option-content')}>
              {customRender ? customRender(currentItem) : label}
            </ListItemContainer>
        </React.Fragment>
      )}
    </ListItem>
  );
};

Option.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  labelKey: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  uniqueStorageId: PropTypes.string,
  // handleRemoveItem: PropTypes.func.isRequired,
  itemProps: PropTypes.shape({
    onClick: PropTypes.func,
  }).isRequired,
  currentItem: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    isDisabled: PropTypes.bool,
  }).isRequired,
  customRender: PropTypes.func,
  index: PropTypes.number.isRequired,
  showLocalStorage: PropTypes.bool.isRequired,
};

Option.defaultProps = {
  isSelected: false,
  customRender: null,
  uniqueStorageId: null,
};
