import { useForm } from '@abyss/web/hooks/useForm';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Drawer } from '@abyss/web/ui/Drawer';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  editCompareCheckboxMobileStyle,
  editCompareDrawerMobileStyle,
} from '../../utility/compareDrawer.styled';
import {
  CompareCheckboxInfo,
  MAX_NUM_OF_ATTRIBUTES,
} from '../../utility/compareDrawerConstants';
import { editCompareButtonRules } from './EditCompareButtonRules';

type Props = {
  initialCheckCount: number;
  items: CompareCheckboxInfo[];
  setChangedTempItems: Function;
  isEditCompareOpen: boolean;
  setOpenEdit: (a: boolean) => void;
  nameOfButtonClickedLast: string;
  setNameOfButtonClickedLast: Function;
};

export const EditCompareDrawerMobile = ({
  initialCheckCount,
  items,
  setChangedTempItems,
  isEditCompareOpen,
  setOpenEdit,
  nameOfButtonClickedLast,
  setNameOfButtonClickedLast,
}: Props) => {
  const { t } = useTranslation();
  const itemsToShowInCheckboxes = useMemo(() => cloneDeep(items), [items]);
  const numChecked = useRef<number>(initialCheckCount);

  const setNumChecked = (count) => {
    numChecked.current = count;
  };

  const checkedItems = items.filter((item) => item.isChecked);
  const checkedItemNames = checkedItems.map((item) => item.name);
  const form = useForm({
    defaultValues: {
      'checkbox-form': checkedItemNames,
    },
  });

  const onSubmit = (data) => {
    if (isEditCompareOpen) {
      const changedTempItems = cloneDeep(items);
      const formData = data['checkbox-form'];
      changedTempItems.forEach((checkbox, index) => {
        const tempCheckBox = checkbox;
        tempCheckBox.isChecked = formData.includes(checkbox.name) || false;
        changedTempItems[index] = tempCheckBox;
      });
      setChangedTempItems(changedTempItems);
    }
  };
  const closeEditCompare = () => {
    setNameOfButtonClickedLast('cancel');
    numChecked.current = initialCheckCount;
  };

  useEffect(() => {
    editCompareButtonRules(
      items,
      setChangedTempItems,
      setOpenEdit,
      setNumChecked,
      nameOfButtonClickedLast,
      setNameOfButtonClickedLast,
      itemsToShowInCheckboxes,
      form
    );
  }, [nameOfButtonClickedLast]);

  const getApplyButtonState = (useCompareCheckboxes) =>
    !useCompareCheckboxes.some(
      (checkbox) =>
        checkbox.name !== 'inNetwork' &&
        checkbox.name !== 'cost' &&
        checkbox.isChecked === true
    );

  const handleCheckBoxClick = (checkbox: CompareCheckboxInfo) => {
    // eslint-disable-next-line no-param-reassign
    checkbox.isChecked = !checkbox.isChecked;

    const newNumChecked = itemsToShowInCheckboxes?.filter(
      (item) => item?.isChecked
    ).length;
    setNumChecked(newNumChecked);
  };

  return isEditCompareOpen ? (
    <FormProvider
      data-auto-testid="compare-providers-edit-compare-apply-edits-button"
      data-testid="compare-providers-edit-compare-apply-edits-button"
      onSubmit={onSubmit}
      state={form}
    >
      <Drawer
        css={editCompareDrawerMobileStyle}
        footer={
          <Drawer.Section
            css={{
              boxShadow: '0px -1px 6px 0px rgba(0, 0, 0, 0.15)',
            }}
          >
            <Layout.Group alignLayout="center" grow>
              <Button
                css={{ width: '100%' }}
                data-auto-testid="edit-compare-mobile-apply"
                data-testid="edit-compare-mobile-apply"
                isDisabled={getApplyButtonState(itemsToShowInCheckboxes)}
                onClick={() => {
                  setNameOfButtonClickedLast('apply');
                }}
              >
                {t('Apply')}
              </Button>
            </Layout.Group>
          </Drawer.Section>
        }
        isOpen={isEditCompareOpen}
        onClose={() => closeEditCompare()}
        position="bottom"
        size="$md"
        title={
          <Heading color="$gray7" offset={4}>
            {t('Edit comparison')}
          </Heading>
        }
      >
        <Drawer.Section css={{ paddingTop: '0px', paddingBottom: '$sm' }}>
          <Button
            css={{ paddingLeft: '0px' }}
            data-auto-testid="compare-providers-edit-compare-mobile-deselect-all"
            data-testid="compare-providers-edit-compare-mobile-deselect-all"
            onClick={() => {
              setNameOfButtonClickedLast('deselect-all');
            }}
            variant="tertiary"
          >
            {t('Deselect all')}
          </Button>
          <Button
            data-auto-testid="compare-providers-edit-compare-mobile-reset"
            data-testid="compare-providers-edit-compare-mobile-reset"
            onClick={() => {
              setNameOfButtonClickedLast('reset');
            }}
            variant="tertiary"
          >
            {t('Reset')}
          </Button>
        </Drawer.Section>
        <Drawer.Section>
          <CheckboxGroup model="checkbox-form">
            {itemsToShowInCheckboxes.map(
              (checkbox) =>
                checkbox.name !== 'inNetwork' &&
                checkbox.name !== 'cost' && (
                  <Checkbox
                    key={`CheckBoxKey-${checkbox.name}`}
                    css={editCompareCheckboxMobileStyle}
                    data-auto-testid={`compare-providers-edit-compare-mobile-checkbox-${checkbox.name}`}
                    data-testid={`compare-providers-edit-compare-mobile-checkbox-${checkbox.name}`}
                    isChecked={checkbox.isChecked}
                    isDisabled={
                      numChecked.current >= MAX_NUM_OF_ATTRIBUTES &&
                      !checkbox?.isChecked
                    }
                    label={t(checkbox.label || '')}
                    onChange={() => handleCheckBoxClick(checkbox)}
                    value={checkbox.name}
                  />
                )
            )}
          </CheckboxGroup>
        </Drawer.Section>
      </Drawer>
    </FormProvider>
  ) : null;
};
