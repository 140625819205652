import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Grid } from '@abyss/web/ui/Grid';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchFilterContext } from '../../../context/SearchFilterContext';
import {
  useEducationalContentStatus,
  useSaveEducationalContentStatus,
} from '../../../hooks/useEducationalContent';
import { getChipsByCoverages } from '../../../utils/psxHeader.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../Constants';
import { phoneOnly } from '../../ConstantsStyles';
import { handleLinkAndModalTrack } from '../../Utils/adobeTrackUtils';
import {
  Dob,
  WelcomeMessageScreen,
  memberSelectorButtonStyle,
  memberSelectorChangeButtonStyle,
  memberSelectorDrawerStyle,
  memberSelectorGridStyle,
  memberSelectorPopoverStyle,
  memberSelectorRadioButtonStyle,
  memberSelectorWebGridStyle,
  memberSelectorWebIconStyle,
} from './MemberSelection.styled';
import { MemberSelectionConfirmationModal } from './MemberSelectionConfirmationModal';

type Props = {
  menuItems: {};
  name: any;
  setActiveMemberIndex: (a: number) => void;
  form: any;
};

export const MemberSelection = ({
  form,
  menuItems,
  name,
  setActiveMemberIndex,
}: Props) => {
  const { ACTIVE_MEMBER_INDEX } = Constants.STORAGE_KEYS.SESSION;
  const currentIndex = storage.session.get(ACTIVE_MEMBER_INDEX);
  const mobileScreen = useMediaQuery(phoneOnly);
  const memberSelectionLocation: string = 'member selection';
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [educationalContent, getEducationalContentStatus] =
    useEducationalContentStatus({});
  const memberSelectionStatus =
    educationalContent?.data?.educationalContentStatus
      ?.hasViewedMemberSelectorAlert;
  const [hasViewedMemberSelect, setHasViewedMemberSelect] = useState(false);
  const [, setEducationalContentStatus] = useSaveEducationalContentStatus({
    onCompleted: () => {
      setHasViewedMemberSelect(true);
    },
  });
  const [
    isMemberSelectionConfirmationModalOpen,
    setIsMemberSelectionConfirmationModalOpen,
  ] = useState(false);
  const arrowDownDataTestId: string = mobileScreen
    ? 'arrowDownMobileDataTestId'
    : 'one-abyss-popover-trigger';

  const { getRouteParams, navigate } = useRouter();
  const { clearSearchFilters, defaultSearchFilters, searchFilters } =
    useContext(SearchFilterContext);

  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token);

  useEffect(() => {
    if (isOpen) {
      const linkName: string = 'active member selection';
      const linkLocation: string = 'body:search home message';
      const modalName: string = 'member selection';
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  useEffect(() => {
    getEducationalContentStatus();
  }, []);

  const handleMemberSelectionChange = (formValue) => {
    if (isMemberSelectionConfirmationModalOpen) {
      adobeLinkTrackEvent({
        name: 'continue',
        location: 'modal:member change notification',
        type: 'internal',
        destinationUrl: '',
      });
      setIsMemberSelectionConfirmationModalOpen(false);
      setEducationalContentStatus({
        variables: {
          hasViewedMemberSelectorAlert: true,
          hasViewedContent: educationalContent?.data?.hasViewedContent || true,
        },
      });
    }

    const previousSelectedIndex = storage.session.get(ACTIVE_MEMBER_INDEX);
    if (
      (formValue || selectedValue) !== previousSelectedIndex &&
      JSON.stringify(searchFilters) !== JSON.stringify(defaultSearchFilters)
    ) {
      clearSearchFilters();
    }
    storage.session.set(ACTIVE_MEMBER_INDEX, formValue || selectedValue);
    setActiveMemberIndex(Number(formValue || selectedValue));
    setIsOpen(false);
    adobeLinkTrackEvent({
      name: 'contine button',
      location: `modal:${memberSelectionLocation}`,
      type: 'internal',
      destinationUrl: '',
    });
    const coverage = getChipsByCoverages(
      Constants.MOCK_CARE_CATEGORIES,
      menuItems[0].radios[Number(formValue || selectedValue)]?.eligibility
    ).flatMap((chip) => chip?.label);

    if (coverage.includes(tokenData?.chipValue)) navigate(0);
    navigate(`/`);
  };

  return (
    <React.Fragment>
      {mobileScreen ? (
        <div>
          <Drawer
            css={memberSelectorDrawerStyle}
            data-auto-testid={arrowDownDataTestId}
            data-testId={arrowDownDataTestId}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            position="bottom"
            title={t('Find care for')}
          >
            <Grid>
              <Grid.Col css={memberSelectorGridStyle} span={12}>
                <RadioGroup
                  data-auto-testid="responsive-member-selector"
                  data-testid="responsive-member-selector"
                  model="member-select"
                  onChange={(e) => {
                    adobeLinkTrackEvent({
                      name: `position ${+e.target.value + 1}`,
                      location: `modal:${memberSelectionLocation}`,
                      type: 'radio',
                      destinationUrl: '',
                    });
                  }}
                >
                  {menuItems[0].radios.map((member) => (
                    <RadioGroup.Radio
                      key={member.label}
                      css={memberSelectorRadioButtonStyle}
                      data-auto-testid="member-label-value"
                      data-testid="member-label-value"
                      label={
                        <Dob
                          data-auto-testid="member-dob"
                          data-testid="member-dob"
                        >
                          {member.label}
                          <div>{member.dob}</div>
                        </Dob>
                      }
                      value={member.value}
                    />
                  ))}
                </RadioGroup>
                <Layout.Group alignLayout="center" width="100%">
                  <Button
                    css={memberSelectorChangeButtonStyle}
                    data-auto-testid="member-selection-change-button"
                    data-testid="member-selection-change-button"
                    onClick={() => {
                      const formValue = form?.getValues('member-select');
                      if (memberSelectionStatus || hasViewedMemberSelect) {
                        handleMemberSelectionChange(formValue);
                      } else {
                        setSelectedValue(formValue);
                        setIsMemberSelectionConfirmationModalOpen(true);
                      }
                    }}
                  >
                    {t('Change')}
                  </Button>
                </Layout.Group>
              </Grid.Col>
            </Grid>
          </Drawer>
          <Button
            aria-haspopup="dialog"
            css={memberSelectorButtonStyle}
            data-auto-testid="closBTM"
            data-testid="closBTM"
            onClick={() => setIsOpen(true)}
            variant="ghost"
          >
            <WelcomeMessageScreen>
              {name}
              <IconMaterial
                color="$primary1"
                icon="keyboard_arrow_down"
                isScreenReadable
              />
            </WelcomeMessageScreen>
          </Button>
        </div>
      ) : (
        <Popover
          aria-describedby="memberSelectionButton"
          content={
            <Grid>
              <Grid.Col css={memberSelectorWebGridStyle} span={12}>
                <RadioGroup
                  data-auto-testid="member-selector"
                  data-testid="member-selector"
                  model="member-select"
                  onChange={(e) => {
                    adobeLinkTrackEvent({
                      name: `position ${+e.target.value + 1}`,
                      location: `modal:${memberSelectionLocation}`,
                      type: 'radio',
                      destinationUrl: '',
                    });
                  }}
                >
                  {menuItems[0].radios.map((member) => (
                    <RadioGroup.Radio
                      key={member.label}
                      css={memberSelectorRadioButtonStyle}
                      data-auto-testid="member-label-value"
                      data-testid="member-label-value"
                      label={
                        <Dob
                          data-auto-testid="member-dob"
                          data-testid="member-dob"
                        >
                          {member.label}
                          <div>{member.dob}</div>
                        </Dob>
                      }
                      value={member.value}
                    />
                  ))}
                </RadioGroup>
                <Layout.Group alignLayout="center" width="100%">
                  <Button
                    css={{
                      margin: '12px 0 15px 10px',
                    }}
                    data-auto-testid="member-selection-change-button"
                    data-testid="member-selection-change-button"
                    onClick={() => {
                      const formValue = form?.getValues('member-select');
                      if (memberSelectionStatus || hasViewedMemberSelect) {
                        handleMemberSelectionChange(formValue);
                      } else {
                        setSelectedValue(formValue);
                        setIsMemberSelectionConfirmationModalOpen(true);
                      }
                    }}
                  >
                    {t('Change')}
                  </Button>
                </Layout.Group>
              </Grid.Col>
            </Grid>
          }
          css={memberSelectorPopoverStyle}
          data-auto-testid="one-abyss-popover-trigger"
          data-testid="one-abyss-popover-trigger"
          onOpenChange={() => {
            form?.setValue('member-select', currentIndex);
            setIsOpen(!isOpen);
          }}
          open={isOpen}
          position="bottom"
          positionOffset={10}
          title={t('Find care for')}
          width={290}
        >
          {name}
          <IconMaterial
            color="$primary1"
            css={memberSelectorWebIconStyle}
            data-auto-testid="arrow-down"
            data-testid="arrow-down"
            icon="keyboard_arrow_down"
          />
        </Popover>
      )}
      <MemberSelectionConfirmationModal
        handleConfirmMemberSelectionChange={handleMemberSelectionChange}
        isModalOpen={isMemberSelectionConfirmationModalOpen}
        setIsModalOpen={setIsMemberSelectionConfirmationModalOpen}
      />
    </React.Fragment>
  );
};
