import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { PSXIcon } from '../../../../../../../common/PSXIcon';
import { useCoverageType } from '../../../../../../../hooks/useCoverageType';
import { StoreKeys } from '../../../../../../../hooks/useStore/state';
import { useStore } from '../../../../../../../hooks/useStore/useStore';
import { CompareProvider } from '../../../../../../../models/Provider';
import { compareModalButtonStyle } from '../../utility/compareDrawer.styled';
import {
  CompareCheckboxInfo,
  bhSpecificItemsArray,
  defaultItemsArray,
} from '../../utility/compareDrawerConstants';

type Props = {
  setSelectedProviders?: (a: CompareProvider[]) => void;
  setSelectedCheckbox?: (a: { checked: {} }) => void;
  setOpenCompare?: (a: boolean) => void;
  setIsOpen: (a: boolean) => void;
  setItems?: React.Dispatch<React.SetStateAction<CompareCheckboxInfo[]>>;
  isOpen?: boolean;
  handleClickCb?: () => void;
  closeButtonText?: string;
  isViewProvider?: boolean;
  locationForAnalytics?: string;
};

export const ConfirmationModal = ({
  setSelectedProviders,
  setSelectedCheckbox,
  setOpenCompare,
  setIsOpen,
  setItems,
  isOpen,
  closeButtonText,
  handleClickCb,
  isViewProvider,
  locationForAnalytics,
}: Props) => {
  const { t } = useTranslation();
  const coverageType = useCoverageType();

  const handleAdobeLinkTrack = (buttonName: string) => {
    adobeLinkTrackEvent({
      name: buttonName,
      location: locationForAnalytics ?? `modal:close confirmation`,
      type: 'internal',
    });
  };

  const handleClick = () => {
    handleAdobeLinkTrack('done');
    if (setOpenCompare) {
      setOpenCompare(false);
    }
    if (setSelectedProviders) {
      setSelectedProviders([]);
    }
    if (setSelectedCheckbox) {
      setSelectedCheckbox({ checked: {} });
    }
    const newItems = (
      coverageType === 'B' ? bhSpecificItemsArray : defaultItemsArray
    ).map((item) => ({ ...item }));
    if (setItems) {
      setItems(newItems);
    }
  };

  // begin: a11y keyboard navigation: move focus to last  selected provider index
  const setUIstate = useStore(StoreKeys.SET_UI_STATE);
  const uiState = useStore(StoreKeys.UI_STATE);
  const { compareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const { indexOflastProviderSelected } = compareProvidersFlow;

  const handleFocusLastIndex = () => {
    setUIstate({
      ...uiState,
      compareProvidersFlow: {
        indexOflastProviderSelected,
        shouldFocusLastIndex: true,
      },
    });
  };
  // end

  return (
    <Modal
      closeOnClickOutside={false}
      css={{
        '.abyss-modal-header-container': { paddingTop: '0px' },
        '.abyss-modal-content-container': {
          width: '320px',
          height: '262px',
          borderRadius: '8px',
        },
        '.abyss-modal-content-title': {
          marginTop: '10px',
        },
        '.abyss-modal-close-button': {
          marginRight: '5px',
          marginTop: '5px',
          svg: {
            width: '18px',
            height: '18px',
            color: '#1F2937',
            fill: '#1F2937',
          },
        },
        '.abyss-modal-overlay': {
          background: 'rgba(0, 0, 0, 0.7)',
        },
      }}
      data-auto-testid="confirmation-modal-container"
      data-testid="confirmation-modal-container"
      isOpen={isOpen}
      onClose={() => {
        handleAdobeLinkTrack('close');
        setIsOpen(false);
      }}
      size="320px"
      title={<PSXIcon icon="real_time_clock" size={84} />}
      titleAlign="center"
    >
      <Modal.Section
        css={{
          'abyss-modal-section-root': {
            textAlign: 'center',
            paddingBottom: '28px',
            paddingTop: 0,
          },
        }}
      >
        <Text fontWeight="bold" size="14px">
          {isViewProvider
            ? t(
                'COMPARE_DRAWER_CONFIRMATION_MODAL.MODAL_SECTION_PART_ONE_VIEW_PROVIDER'
              )
            : t('COMPARE_DRAWER_CONFIRMATION_MODAL.MODAL_SECTION_PART_ONE')}
        </Text>
        <br />
        <Text size="14px">
          {isViewProvider
            ? t(
                'COMPARE_DRAWER_CONFIRMATION_MODAL.MODAL_SECTION_PART_TWO_VIEW_PROVIDER'
              )
            : t('COMPARE_DRAWER_CONFIRMATION_MODAL.MODAL_SECTION_PART_TWO')}
        </Text>
      </Modal.Section>
      <Modal.Section>
        <Layout.Group alignLayout="center" space={16}>
          <Button
            css={compareModalButtonStyle}
            data-auto-testid="confirmation-modal-close-drawer"
            data-testid="confirmation-modal-close-drawer"
            onClick={handleClickCb || handleClick}
            size="30px"
          >
            {closeButtonText ||
              t('COMPARE_DRAWER_CONFIRMATION_MODAL.CLOSE_BUTTON')}
          </Button>
          <Button
            css={compareModalButtonStyle}
            data-auto-testid="confirmation-modal-keep-comparing"
            data-testid="confirmation-modal-keep-comparing"
            onClick={() => {
              handleFocusLastIndex();
              handleAdobeLinkTrack('keep working');
              setIsOpen(false);
            }}
            size="30px"
            variant="outline"
          >
            {t('COMPARE_DRAWER_CONFIRMATION_MODAL.CONTINUE_WORKING_BUTTON')}
          </Button>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  isViewProvider: false,
};
