export interface State {
  sessionId: string;
  planSelector: {
    bigN: {
      DOB: string;
      FirstName: string;
      GroupNumber: string;
      LastName: string;
      MemberNumber: string;
    };
  };
  memberInfo: any;
  uiState: {
    compareProvidersFlow: {
      indexOflastProviderSelected: number;
    };
  };
}

export const intialUIState = {
  compareProvidersFlow: {
    indexOflastProviderSelected: -1,
    shouldFocusLastIndex: false,
  },
  shareProvidersFlow: {
    indexOflastProviderSelected: -1,
    shouldFocusLastIndex: false,
  },
};

export enum StoreKeys {
  SESSION_ID = 'sessionId',
  SET_SESSION_ID = 'setSessionId',
  PLAN_SELECTOR = 'planSelector',
  SET_PLAN_SELECTOR = 'setPlanSelector',
  OBAPI_MEMBERS = 'memberInfo',
  SET_OBAPI_MEMBERS = 'setMemberInfo',
  SET_UI_STATE = 'setUiState',
  UI_STATE = 'uiState',
}
