import { Label } from '@abyss/web/ui/Label';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Modal } from '@abyss/web/ui/Modal';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { ConstantsLagoon } from '../ConstantsLagoon';

type Props = {
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
};

export const InfoModal = ({ isOpen, setIsOpen }: Props) => {
  const data = useLagoon('ui-messaging')();
  const modalContent = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.INFO_MODAL_CONTENT,
  });

  const { t } = useTranslation();

  return (
    <Modal
      css={{
        'abyss-modal-header-title': {
          color: '$gray7',
        },
      }}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={<Label size="$xl">{t('SORT_OPTIONS.BEST_MATCH')}</Label>}
    >
      <Modal.Section
        css={{
          'abyss-modal-section-root': {
            paddingBottom: '32px',
          },
        }}
      >
        <Markdown>{modalContent?.message ?? ''}</Markdown>
      </Modal.Section>
    </Modal>
  );
};
