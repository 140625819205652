import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Badge } from '@abyss/web/ui/Badge';
import { Grid } from '@abyss/web/ui/Grid';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Pagination } from '@abyss/web/ui/Pagination';
import { Text } from '@abyss/web/ui/Text';
/* eslint-disable no-nested-ternary */
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { usePhysicianDirectory } from '../../../hooks/usePhysicianDirectory';
import { ProviderType } from '../../../models/ProviderDetails';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  PRACTITIONER_NAME_FOR_ANALYTICS,
  PhysicianDirectorySortOrder,
  ReverseCoverageTypesCodes,
} from '../../Constants';
import { ConstantsRoutes } from '../../ConstantsRoutes';
import { phoneOnly, tabletOnly } from '../../ConstantsStyles';
import { getGeoLocationFromStorage } from '../../PSXHeader/SearchBar/utils';
import { interleave } from '../../Utils';
import { convertProviderTypeToAdobeType } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { DirectoryLink } from './DirectoryLink/DirectoryLink';
import { DirectoryListMobile } from './DirectoryListMobile/DirectoryListMobile';
import { DirectorySearchInput } from './DirectorySearchInput';
import {
  PhysicianDirectoryInterface,
  renderDesktopItems,
  renderTabletItems,
  searchProviderName,
} from './helper';
import {
  ResultsContainerStyle,
  SearchContainer,
  SectionContainer,
  StyledDivider,
  StyledResultsContainer,
  layoutGroupStyles,
  letterBadgeStyles,
  styledIcon,
  textContentStyles,
} from './PhysicianDirectory.styled';
import { SortPopover } from './SortPopover';

interface FirstLetterToPhysicianMapping {
  [letter: string]: PhysicianDirectoryInterface[];
}

const pageSize = 50;

type Props = {
  providerId: string;
  providerType: string;
};

export const matchSearchTextWithPhysician = (
  searchText,
  physicianList,
  token,
  navigate,
  isMobile,
  tablet,
  currentPage
) => {
  const onClick = (physician) => {
    const updatedToken = tokenizer.update(token, {
      providerId: physician.providerId,
    });
    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      physician.providerId
    );
    adobeLinkTrackEvent({
      name: `${PRACTITIONER_NAME_FOR_ANALYTICS}`,
      location: `body:${Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY}:page ${currentPage}`,
      type: 'internal',
      destinationUrl: '',
    });
    navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${updatedToken}`);
  };
  const search = searchText.trim();
  const columnsCount = isMobile ? 1 : tablet ? 2 : 3;
  const rows = Math.ceil(physicianList.length / columnsCount);

  const physicanColumnsList = tablet
    ? renderTabletItems(physicianList, rows)
    : !isMobile
    ? renderDesktopItems(physicianList, rows)
    : [];

  return (
    <React.Fragment>
      {isMobile ? (
        physicianList?.map((physician) => (
          <DirectoryListMobile
            isTablet={false}
            linkOnClick={onClick}
            physician={physician}
            search={search}
          />
        ))
      ) : tablet ? (
        <Grid
          css={{ 'abyss-grid': { margin: 0, gap: '$lg' } }}
          style={{ display: 'flex', flexFlow: 'row' }}
        >
          {physicanColumnsList.map((physicians) => (
            <Grid
              css={{
                'abyss-grid': {
                  margin: 0,
                  flexDirection: 'column',
                  width: '50%',
                },
              }}
            >
              <React.Fragment>
                {physicians?.map((physician) => (
                  <DirectoryListMobile
                    isTablet
                    linkOnClick={onClick}
                    physician={physician}
                    search={search}
                  />
                ))}
              </React.Fragment>
            </Grid>
          ))}
        </Grid>
      ) : (
        <React.Fragment>
          {physicanColumnsList.map((physicians) => (
            <Grid
              css={{ 'abyss-grid': { margin: 0 } }}
              data-auto-testid="desktop-grid"
              data-testid="desktop-grid"
            >
              {physicians?.map((physician) => {
                if (searchProviderName(physician, search)) {
                  return (
                    <Grid.Col
                      css={{
                        'abyss-grid-col': {
                          padding: '0',
                        },
                      }}
                      span={{ xs: 12, md: 6, lg: 4 }}
                    >
                      <DirectoryLink
                        linkOnClick={onClick}
                        physician={physician}
                        search={search}
                      />
                    </Grid.Col>
                  );
                }
                return null;
              })}
            </Grid>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const getStartingLetterSections = (
  physicianDirectoryMapping,
  providerNameToSearch,
  token,
  navigate,
  mobileScreen,
  tablet,
  currentPage
) =>
  interleave(
    Object.keys(physicianDirectoryMapping)
      .filter((letter) => physicianDirectoryMapping[letter].length > 0)
      .map((letter) => (
        <Layout.Group
          key={letter}
          alignItems="left"
          css={layoutGroupStyles}
          style={{ width: '100%' }}
        >
          <div style={{ width: '100%' }}>
            <Badge css={letterBadgeStyles} rounded variant="neutral">
              {letter}
            </Badge>
            {matchSearchTextWithPhysician(
              providerNameToSearch,
              physicianDirectoryMapping[letter],
              token,
              navigate,
              mobileScreen,
              tablet,
              currentPage
            )}
          </div>
        </Layout.Group>
      )),
    <StyledDivider color="$gray3" />
  );

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PhysicianDirectory = ({ providerId, providerType }: Props) => {
  const { t } = useTranslation();
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerType),
      indicator: '',
    },
  });

  const mobileScreen = useMediaQuery(phoneOnly);
  const tablet = useMediaQuery(tabletOnly);
  const [providerNameToSearch, setProviderNameToSearch] = useState('');
  const [sortOrder, setSortOrder] = useState<PhysicianDirectorySortOrder>(
    PhysicianDirectorySortOrder.ASCENDING
  );
  const [physicians, getPhysicians] = usePhysicianDirectory({});
  const { longitude, latitude } = getGeoLocationFromStorage();
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const { coverageType } = tokenizer.parse(token) || {};
  const currentMember = getCurrentMember();
  const [cancelPrevCalls, setCancelPrevCalls] = useState(true);
  const physicianDirectoryMapping: FirstLetterToPhysicianMapping = {};

  const [physicianList, setPhysicianList] = useState(
    physicians?.data?.physicianDirectory?.physicianDirectoryList
  );
  const [totalPhysicanCount, setTotalPhysicanCount] = useState();

  const { gotoPage, state, previousPage, nextPage, ...paginationProps } =
    usePagination({
      pages: Math.ceil((totalPhysicanCount || 0) / pageSize),
    });
  const { currentPage, pageCount } = state;
  const pdAllLinkTrack = (name: String) => {
    adobeLinkTrackEvent({
      name,
      location: `body:${Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY} pagination control:page ${currentPage}`,
      type: 'internal',
      destinationUrl: '',
    });
  };
  useEffect(() => {
    adobePageTrackEvent();
    pdAllLinkTrack(`results per page:${pageSize}`);
  }, []);
  useEffect(() => {
    adobePageTrackEvent();
    pdAllLinkTrack(`results per page:${pageSize}`);
  }, [pageSize]);

  const nextPageFunc = () => {
    pdAllLinkTrack('next page');
    nextPage();
  };
  const gotoPageFunc = (page) => {
    pdAllLinkTrack(`page ${page + 1}`);
    gotoPage(page);
  };
  const previousPageFunc = () => {
    pdAllLinkTrack('previous page');
    previousPage();
  };

  useEffect(() => {
    let medicalGroupAffiliationProviderId;
    let hospitalaffiliationproviderId;
    if (coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']) {
      if (providerId?.includes('GRP')) {
        medicalGroupAffiliationProviderId = providerId;
      }
      if (providerId?.includes('FAC')) {
        hospitalaffiliationproviderId = providerId;
      }
    } else {
      medicalGroupAffiliationProviderId =
        providerType !== ProviderType.ORGANIZATION ? providerId : null;
      hospitalaffiliationproviderId =
        providerType === ProviderType.ORGANIZATION ? providerId : null;
    }
    setCancelPrevCalls(false);
    getPhysicians({
      variables: {
        latitude,
        longitude,
        lob: currentMember?.lineOfBusiness,
        coverages: getCoverageTypes(currentMember),
        planYear: getCurrentPlanYear(),
        planVariationCode: getPlanVariationCode(currentMember, 'M'),
        policyID: currentMember?.policyNumber,
        providerName: providerNameToSearch,
        providerType,
        reciprocityId: getNetworkIdsForPES(currentMember, coverageType),
        medicalGroupAffiliationProviderId,
        hospitalaffiliationproviderId,
        sortOrder,
        pageNumber: currentPage,
        pageSize,
        coverageType,
      },
    });
  }, [providerNameToSearch, currentPage, sortOrder, providerType]);

  useEffect(() => {
    setCancelPrevCalls(false);
  }, [providerNameToSearch, currentPage, sortOrder]);

  useEffect(() => {
    if (!cancelPrevCalls) {
      setPhysicianList(
        physicians?.data?.physicianDirectory?.physicianDirectoryList
      );
      setTotalPhysicanCount(physicians?.data?.physicianDirectory?.totalCount);
    }

    return () => {
      if (
        physicians?.data !== null &&
        physicians.providerNameToSearch === providerNameToSearch
      )
        setCancelPrevCalls(!cancelPrevCalls);
      else setCancelPrevCalls(false);
    };
  }, [physicians]);

  if (sortOrder === PhysicianDirectorySortOrder.ASCENDING) {
    Constants.BROWSE_AZ.forEach((letter) => {
      physicianDirectoryMapping[letter.label] = [];
    });
  } else {
    Constants.BROWSE_AZ.filter((item) => item.id !== 'all' && item.id === '#');
    const sortedAlphabets = orderBy(Constants.BROWSE_AZ, 'label', 'desc');

    uniqBy(sortedAlphabets, 'label').forEach((letter) => {
      physicianDirectoryMapping[letter.label] = [];
    });
  }

  physicianList
    ?.filter((physician) => physician.providerName)
    ?.forEach((physician) => {
      const name = `${physician.providerName}, ${physician.primaryDegrees.join(
        ','
      )}`;
      const startingLetter = name?.substring(0, 1).toUpperCase();
      physicianDirectoryMapping[startingLetter].push({
        providerName: name.replace(' ', ', '),
        providerId: physician.providerId,
      });
    });

  if (
    (physicianList?.length === 0 || !physicianList) &&
    providerNameToSearch.length === 0 &&
    physicians.isLoading === false
  )
    return (
      <SectionContainer>
        <Text>
          {t('PROVIDER_DETAILS.PHYSICIAN_TAB.NO_DIRECTORY_AVAILABLE')}
        </Text>
      </SectionContainer>
    );
  return (
    <React.Fragment>
      <SectionContainer>
        <SearchContainer>
          <DirectorySearchInput
            setProviderNameToSearch={setProviderNameToSearch}
          />
        </SearchContainer>
      </SectionContainer>
      <SectionContainer>
        {physicians.isLoading ? (
          <LoadingSpinner
            ariaLoadingLabel="loading results"
            isLoading={physicians.isLoading}
            size="$sm"
          />
        ) : (
          <React.Fragment>
            <StyledResultsContainer>
              <ResultsContainerStyle>
                <IconMaterial
                  color="$success1"
                  css={styledIcon}
                  icon="check_circle"
                />
                <Text
                  color="$neutralGray7"
                  css={textContentStyles}
                  fontWeight={500}
                  size="$lg"
                >{`${totalPhysicanCount} ${t(
                  'PROVIDER_DETAILS.PHYSICIAN_TAB.IN_NETWORK_PROVIDERS'
                )}`}</Text>
              </ResultsContainerStyle>
              <SortPopover setSortOrder={setSortOrder} sortOrder={sortOrder} />
            </StyledResultsContainer>
            {getStartingLetterSections(
              physicianDirectoryMapping,
              providerNameToSearch,
              token,
              navigate,
              mobileScreen,
              tablet,
              currentPage
            )}
          </React.Fragment>
        )}
        {pageCount > 1 && (
          <Pagination
            {...paginationProps}
            css={{
              'abyss-pagination-root': {
                marginTop: '$sm',
                '@screen < $sm': {
                  marginTop: '$xs',
                },
              },
              'abyss-pagination-page-button': {
                minWidth: '20px',
              },
              'abyss-pagination-arrow': {
                paddingLeft: '4px',
                paddingRight: '4px',
              },
            }}
            data-auto-testid="physician-directory-pagination"
            data-testid="physician-directory-pagination"
            gotoPage={gotoPageFunc}
            nextPage={nextPageFunc}
            pageSize={pageSize}
            previousPage={previousPageFunc}
          />
        )}
      </SectionContainer>
    </React.Fragment>
  );
};
