import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResponsiveText } from '../../../../../../../../common/DataCard/DataCard.style';
import { Miles } from '../../../../../../../../common/DataCard/Miles';
import { getDistance } from '../../../../../../../../common/Utils/dataCardUtils';
import { CompareProvider } from '../../../../../../../../models/Provider';
import { ResponseHeaders } from '../../../../../../../../models/ResponseHeaders';
import {
  DistanceContainer,
  distanceNullStyle,
} from '../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../utility/compareDrawerConstants';

type Props = {
  distance: string;
  providerId: string;
  providerType: string;
  indexForAnalytics?: number;
  headers: ResponseHeaders;
  selectedProvider?: CompareProvider | any;
};

export const isDistanceAttributePresent = (numMiles) => {
  if (isEmpty(numMiles)) {
    return false;
  }
  return true;
};

export const MileageText = ({
  distance,
  headers,
  providerId,
  providerType,
  indexForAnalytics,
  selectedProvider,
}: Props) => {
  const { t } = useTranslation();
  const displayedDistance = isDistanceAttributePresent(distance)
    ? `${getDistance(distance)} ${t('miles')}`
    : NULL_ATTRIBUTE_STATE.DISTANCE;
  const indexAdobe =
    indexForAnalytics && indexForAnalytics >= 0 ? indexForAnalytics + 1 : 1;
  return (
    <DistanceContainer>
      {isDistanceAttributePresent(distance) ? (
        <Layout.Group>
          <Miles
            calledFrom="comparecard"
            distance={displayedDistance}
            headers={headers}
            id={providerId}
            indexForAnalytics={indexAdobe}
            sectionType={providerType}
            selectedProvider={selectedProvider}
          />
          <ResponsiveText color="$interactive1" fontWeight="$bold">
            {t('AWAY')}
          </ResponsiveText>
        </Layout.Group>
      ) : (
        <Text css={distanceNullStyle}>{NULL_ATTRIBUTE_STATE.DISTANCE}</Text>
      )}
    </DistanceContainer>
  );
};
