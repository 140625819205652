import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useTranslation } from 'react-i18next';

import { Constants } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';

type Props = {
  map: any;
};

export const LocateMe = ({ map }: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const { longitude, latitude } = getGeoLocationFromStorage();

  const handleLocateMe = () => {
    map.flyTo({
      center: [longitude, latitude],
      zoom: Constants.DEVICE_LOCATION.ZOOM,
      bearing: 50,
      pitch: 20,
    });
  };

  const iconSize = mobileScreen ? 20.57 : 22;
  const buttonSize = mobileScreen ? '$md' : '$lg';
  return (
    <Button
      aria-label={t('LOCATION_FOCUS_BOX.MY_LOCATION')}
      css={{
        'abyss-button-root': {
          backgroundColor: '$primary2',
          border: 'none',
          boxShadow: '0 2px rgba(25, 25, 26, 0.16)',
        },
      }}
      data-auto-testid="my-location"
      data-testid="my-location"
      onClick={handleLocateMe}
      rounded
      size={buttonSize}
      variant="ghost"
    >
      <IconMaterial color="$neutralGray5" icon="my_location" size={iconSize} />
    </Button>
  );
};
