import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';

import { mobileOnly, notMobile } from '../../../ConstantsStyles';

const lightGrey = '#d0d0d0';
export const primaryDropdownSearchIconColor = '#6F6F6F';

export const Divider = styled('div', {
  borderTop: `1px solid ${lightGrey}`,
  height: '0px',
  marginLeft: '-24px',
  marginTop: '44px',
  width: 'calc(100% + 48px)',
});

export const DropdownPositionContainer = styled('div', {
  backgroundColor: 'red',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const DropdownContentContainer = styled('div', {
  alignItems: 'flex-start',
  backdropFilter: 'blur(13.591408729553223px)',
  backgroundColor: 'white',
  border: `1px solid ${lightGrey}`,
  borderRadius: '0px 30px 32px 32px',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '430px',
  position: 'absolute',
  right: '24px',
  top: 0,
  width: '427px',
  zIndex: 1,
  [`@media ${notMobile}`]: {
    padding: `16px 24px 12px`,
    gap: '12px',
  },
  [`@media ${mobileOnly}`]: {
    padding: `16px 23px 20px`,
    gap: '16px',
  },
});

export const isLocationActiveSuggetion = {
  boxShadow:
    'white 0px 0px 0px 2px, var(--abyss-colors-interactive1) 0px 0px 2px 4px',
};

export const ChangeLocationDrawer = styled(Drawer, {
  '.abyss-modal-close-button': {
    top: 'var(--abyss-space-md)',
    right: 'var(--abyss-space-md)',
  },
  '.abyss-modal-content-title': {
    color: '$primary1',
    paddingTop: 'var(--abyss-space-sm)',
    fontSize: '24px',
    fontWeight: '$semibold',
  },
  '.abyss-modal-header-container': {
    padding: '0px',
  },
  '.abyss-modal-body': {
    height: '100%',
    padding: '0 var(--abyss-space-sm)',
  },
  '.abyss-modal-content-container': {
    borderRadius: '20px 20px 0px 0px',
    padding: '0 var(--abyss-space-lg)',
    height: '100%',
  },
});

export const popoverStyles = {
  'abyss-popover-title': {
    fontSize: '24px !important',
    fontWeight: '$bold !important',
    color: '$primary1 !important',
  },
  'abyss-popover-content': {
    borderRadius: '8px !important',
    zIndex: 9,
  },
  'abyss-popover-content-container': {
    overflow: 'visible',
    padding: '5px',
  },
  'abyss-form-input-wrapper-container': {
    margin: '4px !important',
  },
};
