import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import GET_RATE from './Rate.graphql';

export const useRate = (options) =>
  useCustomQuery(GET_RATE, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'rate',
  });
