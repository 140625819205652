import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { ImageComponent } from '../../../../../common/ImageComponent';
import { slideStyle } from '../../../../../common/SnackCard/SnackCard.styles';
import { parseProviderName } from '../../../../../utils/providerDetails.utils';
import {
  AvatarShareCard,
  BoxStyled,
  ButtonCss,
  LocationIcon,
  PlaceHolderText,
  RemoveButtonIcon,
  RemoveButtonText,
  ShareBoxStyledPlaceHolders,
  ShareBoxTextAddress,
  ShareBoxTextName,
  addressFlexCss,
  carouselStyles,
  slideContainerStyle,
} from './ShareDrawer.styled';

type Props = {
  removeItem: (item: string) => void;
  selected: number;
  selectedProviderList: {
    providerName: string;
    providerType: string;
    providerId: string;
    primaryDegrees: string[];
    speciality: string;
    networkStatus: string;
    languagesSpoken: string[];
    acceptingNewPatients: boolean;
    gender: string;
    virtualCareOffered: boolean;
    address: {
      line: string[];
    };
    phones: {
      phone: string[];
    };
    locationId: string;
    imageLocation: string;
  }[];
};

export const ShareDrawerCarousel = ({
  selected,
  selectedProviderList,
  removeItem,
}: Props) => {
  const { t } = useTranslation();
  const imgRef = useRef<HTMLImageElement>(null);
  const carouselSelectedProviderSlides = Array.from(Array(5).keys()).map(
    (i) => (
      <Slide css={slideStyle(i)}>
        <Slide.Container css={slideContainerStyle}>
          <Card
            css={{
              'abyss-card-root': {
                border: 'none',
                boxShadow: 'none',
              },
            }}
          >
            <Card.Section css={{ padding: '10px' }}>
              {selectedProviderList[i] ? (
                <div>
                  <Flex
                    alignItems="center"
                    data-auto-testid="share-provider-card"
                    data-testid="share-provider-card"
                    direction="column"
                  >
                    <BoxStyled
                      key={selectedProviderList[i].locationId}
                      align="start"
                      css={{ marginTop: '0px' }}
                      cssProps={{ selected }}
                      data-auto-testid={`share-card-${selectedProviderList[i].providerId}`}
                      data-testid={`share-card-${selectedProviderList[i].providerId}`}
                    >
                      <AvatarShareCard>
                        {ImageComponent({
                          imgRef,
                          providerType: 'Practitioner',
                          imgSrc: selectedProviderList[i]?.imageLocation,
                        })}
                      </AvatarShareCard>
                      <Flex direction="column">
                        <Tooltip
                          align="center"
                          asChild={false}
                          content={parseProviderName(
                            selectedProviderList[i].providerName,
                            selectedProviderList[i].providerType,
                            selectedProviderList[i].primaryDegrees?.[0]
                          )}
                          position="top"
                          positionOffset={8}
                        >
                          <Text
                            css={ShareBoxTextName}
                            data-auto-testid="share-provider-name"
                            data-testid="share-provider-name"
                          >
                            {parseProviderName(
                              selectedProviderList[i].providerName,
                              selectedProviderList[i].providerType,
                              selectedProviderList[i].primaryDegrees?.[0]
                            )}
                          </Text>
                        </Tooltip>
                        <Flex css={addressFlexCss}>
                          <IconMaterial css={LocationIcon} icon="location_on" />
                          <Tooltip
                            align="center"
                            asChild={false}
                            content={t(selectedProviderList[i].address.line[0])}
                            position="top"
                            positionOffset={8}
                          >
                            <Text css={ShareBoxTextAddress}>
                              {t(selectedProviderList[i].address.line[0])}
                            </Text>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    </BoxStyled>
                  </Flex>
                  <Layout.Group css={ButtonCss}>
                    <Button
                      data-auto-testid="button-remove"
                      data-testid="button-remove"
                      onClick={() => {
                        adobeLinkTrackEvent({
                          name: 'remove button',
                          location: `modal:share card:position ${i + 1}`,
                          type: 'internal',
                        });
                        removeItem(selectedProviderList[i].locationId);
                      }}
                      variant="ghost"
                    >
                      <IconMaterial
                        css={RemoveButtonIcon}
                        icon="remove_circle_outline"
                      />
                      <Text css={RemoveButtonText}>
                        {t('SHARE_DRAWER_TEXT.REMOVE')}
                      </Text>
                    </Button>
                  </Layout.Group>
                </div>
              ) : (
                <Box
                  key={`share-card-skeleton${i}`}
                  css={ShareBoxStyledPlaceHolders}
                  data-auto-testid="share-selections-container"
                  data-testid="share-selections-container"
                  index={i}
                >
                  <Text
                    css={PlaceHolderText}
                    data-auto-testid="share-drawer-placeholder"
                    data-testid="share-drawer-placeholder"
                  >
                    {t('SHARE_DRAWER_TEXT.CARD_PLACEHOLDER')}
                  </Text>
                </Box>
              )}
            </Card.Section>
          </Card>
        </Slide.Container>
      </Slide>
    )
  );

  return (
    <React.Fragment>
      <Carousel
        css={carouselStyles}
        data-auto-testid="share-drawer-carousel"
        data-testid="share-drawer-carousel"
        minimal
        noLoop
        slides={carouselSelectedProviderSlides}
        slidesPerView={1}
        variant="none"
      />
    </React.Fragment>
  );
};
