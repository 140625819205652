import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Divider } from '@abyss/web/ui/Divider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NetworkStatus } from '../../../../../../../../common/Constants';
import { phoneOnly } from '../../../../../../../../common/ConstantsStyles';
import { CompareProvider } from '../../../../../../../../models/Provider';
import { ResponseHeaders } from '../../../../../../../../models/ResponseHeaders';
import {
  StyledEducationContainerDiv,
  StyledLanguagesSpokenContainerDiv,
  TextRoot,
} from '../../../utility/compareDrawer.styled';
import { CompareCheckboxInfo } from '../../../utility/compareDrawerConstants';
import { HoursOfOperation } from '../CompareHours/HoursOfOperation';
import { AddressText } from '../CompareLocationDetails/AddressText';
import { MileageText } from '../CompareLocationDetails/MileageText';
import { AcceptingNewPatients } from './AcceptingNewPatients';
import { EducationAttributeDesktop } from './Attributes/Education/EducationAttributeDesktop';
import { EducationAttributeMobile } from './Attributes/Education/EducationAttributeMobile';
import { ExpressAccessProviderText } from './Attributes/ExpressAccessProviderText';
import { HealthGradeRating } from './Attributes/HealthGradeRatings';
import { LanguagesSpokenAttributeDesktop } from './Attributes/LanguagesSpoken/LanguagesSpokenAttributeDesktop';
import { LanguagesSpokenAttributeMobile } from './Attributes/LanguagesSpoken/LanguagesSpokenAttributeMobile';
import { ProviderEthnicityExpressText } from './Attributes/ProviderEthnicityExpressText';
import { VirtualCareOfferedAttribute } from './Attributes/VirtualCareOffered/VirtualCareOfferedAttribute';
import { GenderText } from './GenderText';
import { PcpServicesAvaliable } from './PcpServicesAvaliable';
import { PremiumCareProviderText } from './PremiumCareProviderText';
import { SpecialtyText } from './SpecialtyText';
import { TierOneProviderText } from './TierOneProviderText';

type Props = {
  compareCheckboxes: CompareCheckboxInfo[];
  selectedProvider: CompareProvider;
  adobeIndex?: number;
  headers: ResponseHeaders;
};

export const CompareExpandedCard = ({
  compareCheckboxes,
  selectedProvider,
  adobeIndex,
  headers,
}: Props) => {
  const { t } = useTranslation();

  const mobile = useMediaQuery(phoneOnly);
  const dividerWidth = mobile ? 159 : 233;

  return (
    <React.Fragment>
      <React.Fragment>
        {/* NETWORK STATUS */}
        <Divider height={1} width={dividerWidth} />
        <Layout.Group
          data-auto-testid="compare-provider-status"
          data-testid="compare-provider-status"
          direction="row"
        >
          <IconMaterial
            color={selectedProvider.networkStatus ? '$success1' : ''}
            data-auto-testid={`compare-card-check-circle-icon-${selectedProvider.providerId}`}
            data-testid={`compare-card-check-circle-icon-${selectedProvider.providerId}`}
            icon="check_circle"
            size="$sm"
          />
          <TextRoot
            data-auto-testid={`compare-card-network-status-${selectedProvider.providerId}`}
            data-testid={`compare-card-network-status-${selectedProvider.providerId}`}
          >
            {selectedProvider.networkStatus
              ? t(NetworkStatus.INN)
              : t(NetworkStatus.OON)}
          </TextRoot>
        </Layout.Group>
      </React.Fragment>

      {/* SPECIALTIES */}
      {compareCheckboxes.find((item) => item.name === 'specialties')
        ?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <Layout.Group data-testid="provider-specialty">
            <SpecialtyText specialty={selectedProvider.speciality} />
          </Layout.Group>
        </React.Fragment>
      )}

      {/* REVIEWS */}
      {compareCheckboxes.find((item) => item.name === 'reviews')?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <HealthGradeRating rating={selectedProvider.healthGradeRating} />
        </React.Fragment>
      )}

      {/* ADDRESS / DISTANCE */}
      {compareCheckboxes.find((item) => item.name === 'distance')
        ?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <AddressText address={selectedProvider.address} />
          <MileageText
            distance={selectedProvider.distance}
            headers={headers}
            indexForAnalytics={adobeIndex}
            providerId={selectedProvider.providerId}
            providerType={selectedProvider.providerType}
            selectedProvider={selectedProvider}
          />
        </React.Fragment>
      )}

      {/* TIER 1 PROVIDER */}
      {compareCheckboxes.find((item) => item.name === 'tierOneProvider')
        ?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <TierOneProviderText
            isTieredProvider={selectedProvider.isTieredProvider}
          />
        </React.Fragment>
      )}

      {/* EDUCATION */}
      {compareCheckboxes.find((item) => item.name === 'education')
        ?.isChecked && (
        <StyledEducationContainerDiv>
          <Divider height={1} width={dividerWidth} />
          {mobile ? (
            <EducationAttributeMobile
              educationInfo={selectedProvider.educationInfo}
            />
          ) : (
            <EducationAttributeDesktop
              educationInfo={selectedProvider.educationInfo}
            />
          )}
        </StyledEducationContainerDiv>
      )}

      {/* LANGUAGES SPOKEN */}
      {compareCheckboxes.find((item) => item.name === 'languagesSpoken')
        ?.isChecked && (
        <StyledLanguagesSpokenContainerDiv>
          <Divider height={1} width={dividerWidth} />
          {mobile ? (
            <LanguagesSpokenAttributeMobile
              languagesSpoken={selectedProvider.languagesSpoken}
            />
          ) : (
            <LanguagesSpokenAttributeDesktop
              languagesSpoken={selectedProvider.languagesSpoken}
            />
          )}
        </StyledLanguagesSpokenContainerDiv>
      )}

      {/* ACCEPTING NEW PATIENTS */}
      {compareCheckboxes.find((item) => item.name === 'acceptingNewPatients')
        ?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <AcceptingNewPatients
            acceptingNewPatients={selectedProvider.acceptingNewPatients}
          />
        </React.Fragment>
      )}

      {/* GENDER */}
      {compareCheckboxes.find((item) => item.name === 'gender')?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <GenderText gender={selectedProvider.gender} mobile={mobile} />
        </React.Fragment>
      )}

      {/* VIRTUAL CARE OFFERED */}
      {compareCheckboxes.find((item) => item.name === 'virtualCareOffered')
        ?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <VirtualCareOfferedAttribute
            mobile={mobile}
            virtualCareOffered={selectedProvider.virtualCareOffered}
          />
        </React.Fragment>
      )}

      {/* HOURS OF OPERATION */}
      {compareCheckboxes.find((item) => item.name === 'hoursOfOperation')
        ?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <HoursOfOperation selectedProvider={selectedProvider} />
        </React.Fragment>
      )}

      {/* PREMIUM CARE PROVIDER */}
      {compareCheckboxes.find((item) => item.name === 'premiumCareProvider')
        ?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <PremiumCareProviderText
            mobile={mobile}
            premiumCareProvider={selectedProvider.premiumCareProvider}
          />
        </React.Fragment>
      )}

      {/* PCP SERVICES */}
      {compareCheckboxes.find(
        (item) => item.name === 'hasPCPServiceAvailability'
      )?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <PcpServicesAvaliable
            pcpServicesAvaliable={selectedProvider.hasPCPServiceAvailability}
          />
        </React.Fragment>
      )}

      {/* Provider Ethnicity */}
      {compareCheckboxes.find(
        (item) => item.name === 'providerEthnicityExpress'
      )?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <ProviderEthnicityExpressText
            providerEthnicityExpress={selectedProvider.providerEthnicityExpress}
          />
        </React.Fragment>
      )}

      {/* Express Access Provider */}
      {compareCheckboxes.find((item) => item.name === 'expressAccessProvider')
        ?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <ExpressAccessProviderText
            accessProvider={selectedProvider.accessProvider}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
