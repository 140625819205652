import { config } from '@abyss/web/tools/config';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { Constants } from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils/getFeatureFlag';
import { getLoggedInMember } from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useLagoon } from '../useLagoon';
import CLEAR_PRIMARY_CARE from './ClearPCPCache.graphql';
import GET_PRIMARY_CARE from './PrimaryCare.graphql';
import UPDATE_PRIMARY_CARE from './UpdatePCP.graphql';

export const useClearPrimaryCareCache = (options) =>
  useCustomQuery(CLEAR_PRIMARY_CARE, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    clearCache: [
      {
        key: 'PrimaryCare',
        variables: [
          'dependentSeqNbr',
          'dateOfBirth',
          'enablePreProd',
          'familyId',
          'firstName',
          'groupNumber',
          'isGatedUser',
          'lastName',
          'memberId',
        ],
      },
    ],
    requestPolicy: 'no-cache',
    accessor: 'clearPCPCache',
  });

const usePrimaryCareQuery = (options) => {
  const [primaryCare, setPrimaryCare] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GET_PRIMARY_CARE, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'primaryCare',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setPrimaryCare(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: primaryCare,
  };

  return [parsedResult, queryAction];
};

export const usePrimaryCare = () => {
  const [memberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );

  const [primaryCare, setPrimaryCare] = usePrimaryCareQuery({});

  const loggedInMember = getLoggedInMember();
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const enablePreProd = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_PRE_PROD
  );

  useEffect(() => {
    if (loggedInMember) {
      const { demographics, familyId, isGatedUser, policyNumber } =
        loggedInMember;
      const { name, dateOfBirth, dependentSeqNbr } = demographics;
      setPrimaryCare({
        variables: {
          firstName: name.firstName,
          lastName: name.lastName,
          groupNumber: policyNumber,
          memberId: memberId.toString(),
          dateOfBirth,
          familyId,
          dependentSeqNbr,
          isGatedUser,
          enablePreProd,
        },
      });
    }
  }, [loggedInMember]);

  return primaryCare;
};

export const useUpdatePrimaryCare = (options) =>
  useCustomQuery(UPDATE_PRIMARY_CARE, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    requestPolicy: 'no-cache',
    accessor: 'updatePCP',
  });
