import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIConstants } from '../../../../api/src/services/Constants';
import { useLagoon } from '../../hooks/useLagoon';
import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { ProviderType } from '../../models/ProviderDetails';
/* eslint-disable react/jsx-fragments */
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { getDistance } from '../../utils/card.utils';
import { parseProviderName } from '../../utils/providerDetails.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants, ProviderTypes } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { phoneOnly } from '../ConstantsStyles';
import { ImageComponent } from '../ImageComponent';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { NationalGroupProvidersVirtualBiteCard } from '../NationalGroupProvidersVirtualBiteCard';
import { getFeatureFlag } from '../Utils';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import {
  CustomAttributesBlock,
  convertProviderTypeToAdobeType,
  formatProviderId,
  getNameForLinkTrack,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { NationalAncillaryFacilitySnackCard } from './NationalAncillaryFacilitySnackCard';
import { Rating } from './Rating';
import {
  Avatar,
  DistanceDrawerStyles,
  Miles,
  MilesContent,
  MilesText,
  NoRatingText,
  PopoverContainer,
  SnackAvatarContainer,
  SnackContainer,
  SnackDescription,
  SnackDetails,
  SnackInfoCard,
  SnackName,
  SnackRole,
  displayVirtualVisitStyle,
} from './SnackCard.styles';
import { SnackCardSkeleton } from './SnackCardSkeleton';

const snackCardRatingProps = (
  decemberFlag: boolean,
  isMobileScreen: boolean
) => {
  const starRatingOutOf = decemberFlag ? 1 : 5;
  const totalRatings = decemberFlag ? 100 : null; // using mock data for total number of ratings
  const starRatingSize = decemberFlag && !isMobileScreen ? 16 : 12;

  return { starRatingOutOf, totalRatings, starRatingSize };
};

export type SnackCardProps = {
  providerName?: string;
  professionalDesignation?: string;
  speciality?: string;
  rating?: number;
  distance?: string;
  imgSrc?: string;
  providerId?: string;
  providerType?: string;
  isLoading: boolean;
  isFixes?: boolean;
  locationForAnalytics?: string;
  isVirtualCareOffered?: boolean;
  hasPhysicalAddress?: boolean;
  index?: number;
  isTieredProvider?: boolean;
  organizationType?: string[];
  website?: string;
  headers?: ResponseHeaders;
  snackCardProvider?: SnackCardProvider | any;
};

export const SnackCard = ({
  providerName,
  professionalDesignation,
  speciality,
  rating,
  distance,
  imgSrc,
  providerId,
  providerType,
  isLoading,
  isFixes = false,
  locationForAnalytics,
  isVirtualCareOffered,
  hasPhysicalAddress,
  index,
  isTieredProvider,
  organizationType,
  website,
  snackCardProvider = {},
  headers = { correlationId: '' },
}: SnackCardProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const displayName = parseProviderName(
    providerName,
    providerType,
    professionalDesignation
  );
  const analyticsName = getNameForLinkTrack(displayName, providerType);

  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const [milesdata, setMileseData] = useState(0);
  const { SELECTED_PROVIDER_ID } = Constants.STORAGE_KEYS.SESSION;
  const providerDetailsMilesInfo = 'get-provider-details-miles';
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isOpen, setIsOpen] = useState(false);
  const snackCardModalTestId: string = 'snack-card-miles-info-popover';
  const indexForAnalytics = index !== undefined ? index + 1 : 0;

  const navigateToSnackDetails = (
    heading: string,
    showDirection,
    isTiered?
  ) => {
    const updatedToken = tokenizer.update(token, {
      search: heading,
      sectionType: Constants.SEARCH_TYPES.PROVIDER,
      pcpIndicator: true,
      providerType: APIConstants.PROVIDER_TYPES.practitioner,
      searchType: APIConstants.PES.LOCATION,
      providerId,
      showDirection,
      isTieredSnackCard: isTiered,
    });
    storage.session.set(SELECTED_PROVIDER_ID, providerId);
    let path;
    if (providerType === ProviderType.PRACTITIONER) {
      path = `${ConstantsRoutes.PROVIDER_DETAILS.path}${updatedToken}`;
    } else if (providerType === ProviderType.MEDICAL_GROUP) {
      path = `${ConstantsRoutes.PROVIDER_GROUP_DETAILS.path}${updatedToken}`;
    } else path = `${ConstantsRoutes.FACILITY_DETAILS.path}${updatedToken}`;
    navigate(path);
  };
  const gotoDirection = (header) => {
    navigateToSnackDetails(header, true, isTieredProvider);
  };

  useEffect(() => {
    const data = getDistance(distance || '');
    setMileseData(data);
  }, [distance]);

  const { t } = useTranslation();
  const dataTestId = `get-provider-details-name-${providerType}`.replace(
    / /g,
    '-'
  );

  const featureTierOneFlags = useLagoon('feature-flags')();
  const decemberFlag: boolean = getFeatureFlag(
    featureTierOneFlags,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );

  const customAttributesBlock: CustomAttributesBlock = {
    correlationId: headers?.correlationId,
    providerId: formatProviderId(snackCardProvider),
    providerType: convertProviderTypeToAdobeType(providerType),
  };

  const handleAdobeLinkTrack = () => {
    adobeLinkTrackEvent({
      name: providerDetailsMilesInfo,
      location: `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`,
      type: 'internal',
      customAttributesBlock,
    });
  };

  useEffect(() => {
    if (isOpen) {
      const linkName: string = snackCardModalTestId;
      const linkLocation: string = `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`;
      const modalName: string = Constants.CONTENT_FILTERS.DISTANCE;
      handleLinkAndModalTrack(
        linkName,
        linkLocation,
        modalName,
        undefined,
        customAttributesBlock
      );
    }
  }, [isOpen]);

  const displayVirtualVisit =
    decemberFlag &&
    hasPhysicalAddress &&
    // isVirtualCareOffered && //will be enabled once api supports virtualCareOffered field in the response
    providerType === ProviderType.PRACTITIONER;

  const displayVirtualProviderGroup =
    isVirtualCareOffered &&
    !hasPhysicalAddress &&
    decemberFlag &&
    providerType === 'Medical Group';

  const { starRatingOutOf, totalRatings, starRatingSize } =
    snackCardRatingProps(decemberFlag, mobileScreen);
  const directionsIconSize = decemberFlag ? 18 : 16;

  if (displayVirtualProviderGroup) {
    return (
      <NationalGroupProvidersVirtualBiteCard
        data-auto-testid="virtual-provider-group-snack-card"
        data-testid="virtual-provider-group-snack-card-tag"
        handleOnClick={() => {
          navigateToSnackDetails(
            Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM,
            false,
            isTieredProvider
          );
        }}
        isFixes={isFixes}
        isLoading={isLoading}
        providerName="oshiHealth"
        speciality={speciality}
      />
    );
  }

  const isNationalAncFacility =
    providerType === ProviderType.ORGANIZATION && !distance;

  const providerSpeciality =
    (providerType === Constants.RESULT_SECTION.ORGANIZATION
      ? organizationType?.[0]
      : speciality) || '';

  if (isNationalAncFacility)
    return (
      <React.Fragment>
        <SnackContainer
          data-auto-testid="national-ancillary-facility-snack-card-container"
          data-testid="national-ancillary-facility-snack-card-container"
          decemberFeature={decemberFlag}
          onClick={() => {
            adobeLinkTrackEvent({
              name: getNameForLinkTrack(
                website || '',
                ProviderTypes.ORGANIZATION
              ),
              location: `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`,
              type: 'external',
              destinationUrl: website,
              customAttributesBlock,
            });
            window.open(website, '_blank');
          }}
        >
          <SnackInfoCard
            alignContent="center"
            alignItems="center"
            data-auto-testid={`snack-card-${providerType}`.replace(/ /g, '-')}
            data-testid={`snack-card-${providerType}`.replace(/ /g, '-')}
            decemberFeature={decemberFlag}
            direction="column"
            isFixes={isFixes}
            isNationalAncFacility={isNationalAncFacility}
            justify="space-between"
          >
            <NationalAncillaryFacilitySnackCard
              displayName={displayName}
              providerSpeciality={providerSpeciality}
            />
          </SnackInfoCard>
        </SnackContainer>
      </React.Fragment>
    );

  return (
    <>
      {isLoading ? (
        <SnackInfoCard
          alignContent="center"
          alignItems="center"
          direction="column"
          isFixes={isFixes}
          justify="space-between"
        >
          <SnackCardSkeleton />
        </SnackInfoCard>
      ) : (
        <SnackContainer decemberFeature={decemberFlag}>
          <SnackInfoCard
            alignContent="center"
            alignItems="center"
            data-auto-testid={`snack-card-${providerType}`.replace(/ /g, '-')}
            data-testid={`snack-card-${providerType}`.replace(/ /g, '-')}
            decemberFeature={decemberFlag}
            direction="column"
            isFixes={isFixes}
          >
            <Flex alignContent="center" alignItems="center" direction="column">
              <SnackAvatarContainer decemberFeature={decemberFlag}>
                <Avatar
                  alignContent="center"
                  alignItems="center"
                  data-testid={`snack-card-${providerType}-profile`.replace(
                    / /g,
                    '-'
                  )}
                >
                  {ImageComponent({ imgRef, providerType, imgSrc })}
                </Avatar>
                {displayVirtualVisit && (
                  <Flex color="$primary1" css={displayVirtualVisitStyle}>
                    <IconMaterial
                      color="white"
                      data-testid={`snack-card-${providerType}-virtual-visit-icon`.replace(
                        / /g,
                        '-'
                      )}
                      icon="videocam"
                      size={16}
                      variant="filled"
                    />
                  </Flex>
                )}
              </SnackAvatarContainer>
              <SnackDescription
                alignContent="center"
                alignItems="center"
                direction="column"
                justify="space-between"
              >
                <Tooltip content={displayName}>
                  <SnackName
                    color="$accent1"
                    data-auto-testid={dataTestId}
                    data-testid={dataTestId}
                    decemberFeature={decemberFlag}
                    onClick={() => {
                      navigateToSnackDetails(
                        Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM,
                        false,
                        isTieredProvider
                      );
                    }}
                  >
                    <LinkWithTracking
                      analyticsInfo={{
                        name: analyticsName,
                        location: `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`,
                        customAttributesBlock,
                      }}
                      data-auto-testid={`${dataTestId}-link`}
                      data-testid={`${dataTestId}-link`}
                      isStandardAnchor
                    >
                      {displayName}
                    </LinkWithTracking>
                  </SnackName>
                </Tooltip>
              </SnackDescription>
            </Flex>

            <SnackDetails
              alignContent="center"
              alignItems="center"
              decemberFeature={decemberFlag}
              direction="column"
            >
              <Tooltip content={speciality}>
                <SnackRole
                  color="$neutralGray"
                  data-auto-testid="get-provider-details-role"
                  data-testid="get-provider-details-role"
                  decemberFeature={decemberFlag}
                >
                  {speciality}
                </SnackRole>
              </Tooltip>
              {providerType === ProviderType.PRACTITIONER &&
                (rating ? (
                  <Rating
                    data-auto-testid="get-provider-details-rating"
                    data-testid="get-provider-details-rating"
                    navigateToSnackDetails={() => {
                      navigateToSnackDetails(
                        Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM,
                        false,
                        isTieredProvider
                      );
                    }}
                    outOf={starRatingOutOf}
                    rating={rating}
                    size={starRatingSize}
                    totalRatings={totalRatings}
                  />
                ) : (
                  <NoRatingText
                    color="$neutralGray"
                    data-testid="rating-container"
                  >
                    {t('Ratings not available')}
                  </NoRatingText>
                ))}
              {mobileScreen ? (
                <>
                  <Miles
                    alignContent="center"
                    alignItems="center"
                    data-auto-testid="get-provider-details-miles"
                    data-testid="get-provider-details-miles"
                    decemberFeature={decemberFlag}
                  >
                    <Drawer
                      css={DistanceDrawerStyles}
                      data-auto-testid={snackCardModalTestId}
                      data-testid={snackCardModalTestId}
                      isOpen={isOpen}
                      onClose={() => setIsOpen(false)}
                      position="bottom"
                      size="200"
                      title={t('CONTENT_FILTERS.DISTANCE')}
                    >
                      {t('DISTANCE_POPOVER_CONTENT')}
                    </Drawer>
                    <MilesContent
                      data-auto-testid={providerDetailsMilesInfo}
                      data-testid={providerDetailsMilesInfo}
                      onClick={() => {
                        handleAdobeLinkTrack();
                        gotoDirection(
                          Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM
                        );
                      }}
                    >
                      <IconMaterial icon="directions" size="sm" />
                      <MilesText>
                        {milesdata} {t('miles')}
                      </MilesText>
                      <IconMaterial
                        css={{
                          'abyss-icon': {
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          },
                        }}
                        data-auto-testid="snack-card-miles-Info"
                        data-testid="snack-card-miles-Info"
                        icon="info"
                        onClick={(event) => {
                          event.stopPropagation();
                          setIsOpen(true);
                        }}
                        size={16}
                        variant="outlined"
                      />
                    </MilesContent>
                  </Miles>
                </>
              ) : (
                <Miles
                  alignContent="center"
                  alignItems="center"
                  data-auto-testid="get-provider-details-miles"
                  data-testid="get-provider-details-miles"
                >
                  <MilesContent
                    data-auto-testid="get-provider-details-miles"
                    data-testid="get-provider-details-miles"
                    onClick={() => {
                      handleAdobeLinkTrack();
                      gotoDirection(
                        Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM
                      );
                    }}
                  >
                    <IconMaterial icon="directions" size={directionsIconSize} />
                    <MilesText>
                      {milesdata} {t('miles')}
                    </MilesText>
                  </MilesContent>
                  <PopoverContainer>
                    <Popover
                      aria-label={t(
                        'ACCESSIBILITY_LABELS.INFORMATION_ABOUT_DISTANCE'
                      )}
                      content={t('DISTANCE_POPOVER_CONTENT')}
                      data-auto-testid={snackCardModalTestId}
                      data-testid={snackCardModalTestId}
                      onOpenChange={() => {
                        setIsOpen(!isOpen);
                      }}
                      open={isOpen}
                      title={
                        <Text size="$sm">{t('CONTENT_FILTERS.DISTANCE')}</Text>
                      }
                      width={220}
                    >
                      <IconMaterial
                        data-auto-testid="snack-card-miles-Info"
                        data-testid="snack-card-miles-Info"
                        icon="info"
                        onClick={() => setIsOpen(true)}
                        size={16}
                        variant="outlined"
                      />
                    </Popover>
                  </PopoverContainer>
                </Miles>
              )}
            </SnackDetails>
          </SnackInfoCard>
        </SnackContainer>
      )}
    </>
  );
};
