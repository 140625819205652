import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Box } from '@abyss/web/ui/Box';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { CardSection } from '../../../../../common/CardSection/CardSection';
import { Constants } from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../../../common/ContentWrapper';
import { ImageComponent } from '../../../../../common/ImageComponent';
import { Breadcrumb } from '../../../../../common/PSXHeader/Breadcrumb';
import { getFeatureFlag } from '../../../../../common/Utils';
import { useAdobePageTrackEvent } from '../../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../../../hooks/useLagoon';
import {
  useClearPrimaryCareCache,
  useUpdatePrimaryCare,
} from '../../../../../hooks/usePrimaryCare';
import { useProviderDetails } from '../../../../../hooks/useProviderDetails';
import { BreadcrumbSessionStorage } from '../../../../../models/BreadcrumbSessionStorage';
import { ProviderDetails } from '../../../../../models/ProviderDetails';
import { parseProviderName } from '../../../../../utils/providerDetails.utils';
import {
  getLoggedInMember,
  getMemberByDependentSeqNbr,
} from '../../../../../utils/user.utils';
import {
  ButtonRowBox,
  ChoosePCPLocationAvatar,
  PCPCardWrapper,
  PCPHeaderContainer,
  SelectedPCPCard,
  StyledProviderName,
} from './ChoosePCPLocation.styled';
import { ChoosePCPLocationButtonRow } from './ChoosePCPLocationButtonRow';
import { ChoosePCPLocationHeading } from './ChoosePCPLocationHeading';
import { LocationsMapViewWrap } from './LocationsMapViewWrap';
import { UpdateErrorModal } from './UpdateErrorModal';

export const ChoosePCPLocation = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();

  const tokenData = tokenizer.parse(token);
  const { choosePCPId, dependentSeqNbr, selectedProviderType } = tokenData;
  const loggedInMember = getLoggedInMember();
  const memberByDependentSeqNbr = getMemberByDependentSeqNbr(dependentSeqNbr);

  const [, updatePCP] = useUpdatePrimaryCare({});
  const [, clearPCPCache] = useClearPrimaryCareCache({});
  const imgRef = useRef<HTMLImageElement>(null);

  const [isMobileMapView, setIsMobileMapView] = useState<boolean>(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>(0);
  const [isUpdatingPCP, setIsUpdatingPCP] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showButtonVariant, setShowButtonVariant] = useState<boolean>(false);

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const enablePreProd = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_PRE_PROD
  );

  const choosePcpLocation: string = 'body:choose pcp location';

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'choose location',
    sitesectionLevel1: 'pcp',
  });

  const [memberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  const [selectedLocationId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );

  const { data, isLoading } = useProviderDetails({
    providerType: selectedProviderType,
    providerId: choosePCPId,
  });

  useEffect(() => {
    if (!mobileScreen) setIsMobileMapView(mobileScreen);
  }, [mobileScreen]);

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  const [breadcrumbSessionStorage] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const parseBreadcrumbs = (urls) => {
    const breadcrumbs = [
      {
        title: t('Select location'),
        href: '',
      },
    ];
    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]) {
      breadcrumbs.unshift({
        title: t('BC Results'),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]}`,
      });
    }
    return breadcrumbs;
  };

  const breadcrumbs = parseBreadcrumbs(breadcrumbSessionStorage);

  const isErrorButtonVariant = (code) => {
    switch (code) {
      case '50085':
      case '50126':
      case '50128':
      case '50135':
        return true;
      default:
        return false;
    }
  };

  if (!data?.providerDetails) return null;

  if (isLoading) {
    return (
      <CardSection>
        <LoadingSpinner
          ariaLoadingLabel="loading results"
          isLoading={isLoading}
          size="$sm"
        />
      </CardSection>
    );
  }

  const provider = data?.providerDetails.providerDetails as ProviderDetails;
  const {
    providerName,
    providerLocations,
    professionalDesignation,
    specialties,
    primaryDegrees,
    providerType,
    imageLocation,
  } = provider;

  const formattedProviderName = parseProviderName(
    providerName,
    providerType,
    professionalDesignation
  );

  const hasMultipleLocations = providerLocations?.length > 1;

  const isLocationSelected = !hasMultipleLocations || selectedLocationId;
  const useStickyButtonRow = hasMultipleLocations && mobileScreen;

  const isSuperUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.SUPER_USER
  );

  const handleBackButtonClick = () => {
    if (isMobileMapView) {
      setIsMobileMapView(false);
    } else {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    adobeLinkTrackEvent({
      name: 'cancel',
      location: choosePcpLocation,
      type: 'internal',
    });

    navigate(-1);
  };
  const handleCloseModal = () => setShowErrorModal(false);

  const handleSaveLocation = () => {
    adobeLinkTrackEvent({
      name: 'save location',
      location: choosePcpLocation,
      type: 'internal',
    });

    if (!isSuperUser) {
      setIsUpdatingPCP(true);

      const selectedLocation = providerLocations[selectedAddressIndex];

      const { demographics: loggedInDemographics } = loggedInMember;
      const {
        dependentSeqNbr: loggedInDepSeqNbr,
        dateOfBirth: loggedInDob,
        name: loggedInName,
      } = loggedInDemographics;
      const { demographics, familyId, policyNumber, isGatedUser } =
        memberByDependentSeqNbr;
      const { dateOfBirth, name } = demographics;
      const { firstName, lastName } = name;

      updatePCP({
        variables: {
          firstName,
          lastName,
          dateOfBirth,
          groupNumber: policyNumber,
          memberId: memberId.toString(),
          dependentSeqNbr,
          familyId,
          pcpId: selectedLocation?.pcpId,
          pcpName: providerName,
          submittedByDependentSequenceNumber: loggedInDepSeqNbr,
          providerType,
          isGatedUser,
        },
      }).then((result) => {
        setIsUpdatingPCP(false);
        if (result?.data?.updatePCP?.success) {
          const updatedToken = tokenizer.update(token, {
            formattedProviderName,
            providerType,
            selectedLocation: selectedLocation
              ? JSON.stringify(selectedLocation)
              : null,
            primarySpeciality: specialties[0],
            imageLocation,
            pcpEffectiveDate: result?.data?.updatePCP?.pcpEffectiveDate,
          });

          clearPCPCache({
            variables: {
              firstName: loggedInName.firstName,
              lastName: loggedInName.lastName,
              groupNumber: policyNumber,
              memberId: memberId.toString(),
              dateOfBirth: loggedInDob,
              familyId,
              dependentSeqNbr: loggedInDepSeqNbr,
              isGatedUser,
              enablePreProd,
            },
          });

          navigate(`${ConstantsRoutes.CHOOSE_PCP_SUCCESS.path}${updatedToken}`);
        } else {
          setShowErrorModal(true);
          setShowButtonVariant(
            isErrorButtonVariant(result.data.updatePCP.code)
          );
        }
      });
    } else {
      // Bypass the OBAPI call so that super user does not make PCP updates for the member's profile/account.
      // so, navigating to PCP success without talking to OBAPI
      navigate(`${ConstantsRoutes.CHOOSE_PCP_SUCCESS.path}${token}`);
    }
  };

  const saveAndCancelButtons = (
    <ChoosePCPLocationButtonRow
      handleCancel={handleCancel}
      handleSaveLocation={handleSaveLocation}
      isLocationSelected={isLocationSelected}
      isUpdatingPCP={isUpdatingPCP}
    />
  );
  const imgSrc = imageLocation;

  return (
    <React.Fragment>
      <UpdateErrorModal
        handleClose={handleCloseModal}
        isOpen={showErrorModal}
        searchButtonVariant={showButtonVariant}
      />
      <PCPHeaderContainer>
        <ContentWrapper>
          <PCPCardWrapper>
            <Breadcrumb
              breadcrumbs={breadcrumbs}
              onBackButtonClick={handleBackButtonClick}
            />
            <SelectedPCPCard height="66px" padding="10px">
              <Layout.Group
                css={{
                  'abyss-layout-group': {
                    display: 'flex',
                    alignItems: 'flex-start',
                  },
                }}
              >
                <ChoosePCPLocationAvatar>
                  {ImageComponent({ imgRef, providerType, imgSrc })}
                </ChoosePCPLocationAvatar>
                <Layout.Stack alignItems="left" space={0}>
                  <Text
                    css={{
                      color: '#323334',
                      '@screen < $md': { color: '#FFFFFF' },
                    }}
                    fontWeight="bold"
                    size="lg"
                  >
                    {primaryDegrees ? (
                      <StyledProviderName>
                        {formattedProviderName}, {primaryDegrees?.[0]}
                      </StyledProviderName>
                    ) : (
                      <StyledProviderName>
                        {formattedProviderName}
                      </StyledProviderName>
                    )}
                  </Text>
                  <Text
                    css={{
                      fontSize: '12.64px',
                      fontWeight: '500',
                      color: '#4B4D4F',
                      '@screen < $md': { color: '#FFFFFF' },
                    }}
                    size="xs"
                  >
                    {specialties?.[0]}
                  </Text>
                </Layout.Stack>
              </Layout.Group>
            </SelectedPCPCard>
          </PCPCardWrapper>
        </ContentWrapper>
      </PCPHeaderContainer>
      <ContentWrapper>
        <Box color="white" height="unset" padding="0px">
          <ChoosePCPLocationHeading
            formattedProviderName={formattedProviderName}
            hasMultipleLocations={hasMultipleLocations}
            isMobileMapView={isMobileMapView}
          />
          <LocationsMapViewWrap
            hasMultipleLocations={hasMultipleLocations}
            isMobileMapView={isMobileMapView}
            mobileScreen={mobileScreen}
            providerLocations={providerLocations}
            saveAndCancelButtons={saveAndCancelButtons}
            setIsMobileMapView={setIsMobileMapView}
            setSelectedAddressIndex={setSelectedAddressIndex}
            useStickyButtonRow={useStickyButtonRow}
          />
        </Box>
      </ContentWrapper>
      {useStickyButtonRow && !isMobileMapView ? (
        <ButtonRowBox color="$white" padding="0">
          {saveAndCancelButtons}
        </ButtonRowBox>
      ) : null}
    </React.Fragment>
  );
};
