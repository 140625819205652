import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { HealthgradesProviderReview } from '../../../../models/Healthgrades';
import { Rating } from '../../../SnackCard/Rating';
import {
  NumberOfReviewsTextStyles,
  OverallRatingContainer,
  OverallRatingNumberStyles,
  RatingOverviewSection,
  RatingSectionHeader,
} from './PatientReviews.styled';
import { ProviderAndOfficeAndStaffExperienceSection } from './ProviderAndOfficeAndStaffExperienceSection';

type Props = {
  healthgradesReview: HealthgradesProviderReview;
};

export const PatientReviewsOverviewSection = ({
  healthgradesReview,
}: Props) => {
  if (!healthgradesReview) return null;

  // Todo: This can be configurable from Lagoon comeonnn...
  const showSubmit = false;

  const { t } = useTranslation();

  return (
    <RatingOverviewSection alignItems="flex-start" justify="flex-start">
      <Grid
        css={{
          flex: healthgradesReview.providerExperienceRating ? 'unset' : 1,
        }}
      >
        <Grid.Col span={{ xs: '50%', md: '50%', lg: '33%', xl: '33%' }}>
          <Flex direction="column">
            <RatingSectionHeader>
              {t(
                'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.OVERALL_EXPERIENCE'
              )}
            </RatingSectionHeader>
            <Flex alignItems="baseline" css={OverallRatingContainer}>
              <Heading
                css={OverallRatingNumberStyles}
                data-auto-testid="overall-rating"
                data-testid="overall-rating"
              >
                {healthgradesReview.overallSurveyStarScore}
              </Heading>
              <Text
                css={NumberOfReviewsTextStyles}
                data-auto-testid="review-count"
                data-testid="review-count"
              >
                ({healthgradesReview.surveyUserCount}{' '}
                {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.REVIEWS')})
              </Text>
            </Flex>
            <Rating
              hideNumber
              outOf={5}
              rating={healthgradesReview.overallSurveyStarScore}
              size={24}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col
          css={{ display: 'inline-flex' }}
          span={{ xs: '50%', md: '50%', lg: '66.33%', xl: '66.33%' }}
        >
          <ProviderAndOfficeAndStaffExperienceSection
            healthgradesReview={healthgradesReview}
          />
        </Grid.Col>
        {showSubmit ? (
          <Button
            color="$primary1"
            data-auto-testid="submit-a-review-button"
            data-testid="submit-a-review-button"
            size="$sm"
            variant="outline"
          >
            <Text color="$primary1" fontWeight="$semibold">
              {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.SUBMIT_A_REVIEW')}
            </Text>
          </Button>
        ) : null}
      </Grid>
    </RatingOverviewSection>
  );
};
