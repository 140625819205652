export type CompareCheckboxInfo = {
  name?: string;
  label?: string;
  isChecked?: boolean;
};

export const defaultItemsArray: CompareCheckboxInfo[] = [
  {
    name: 'reviews',
    label: 'Ratings',
    isChecked: true,
  },
  {
    name: 'tierOneProvider',
    label: 'Tier 1 provider',
    isChecked: true,
  },
  {
    name: 'education',
    label: 'Education',
    isChecked: true,
  },
  {
    name: 'languagesSpoken',
    label: 'Language(s) spoken',
    isChecked: false,
  },
  {
    name: 'cost',
    label: 'Cost',
    isChecked: false,
  },
  {
    name: 'specialties',
    label: 'Specialty',
    isChecked: true,
  },
  {
    name: 'distance',
    label: 'Distance from me',
    isChecked: true,
  },
  {
    name: 'acceptingNewPatients',
    label: 'Accepting new patients',
    isChecked: false,
  },
  {
    name: 'gender',
    label: 'Gender',
    isChecked: false,
  },
  {
    name: 'virtualCareOffered',
    label: 'Virtual care offered',
    isChecked: false,
  },
  {
    name: 'hoursOfOperation',
    label: 'Hours of operation',
    isChecked: false,
  },
  {
    name: 'premiumCareProvider',
    label: 'Premium Care Physician',
    isChecked: false,
  },
  {
    name: 'hasPCPServiceAvailability',
    label: 'PCP services',
    isChecked: false,
  },
];

export const bhSpecificItemsArray: CompareCheckboxInfo[] = [
  {
    name: 'reviews',
    label: 'Ratings',
    isChecked: true,
  },
  {
    name: 'education',
    label: 'Education',
    isChecked: true,
  },
  {
    name: 'languagesSpoken',
    label: 'Language(s) spoken',
    isChecked: false,
  },
  {
    name: 'cost',
    label: 'Cost',
    isChecked: false,
  },
  {
    name: 'specialties',
    label: 'Specialty',
    isChecked: true,
  },
  {
    name: 'distance',
    label: 'Distance from me',
    isChecked: true,
  },
  {
    name: 'acceptingNewPatients',
    label: 'Accepting new patients',
    isChecked: false,
  },
  {
    name: 'gender',
    label: 'Gender',
    isChecked: false,
  },
  {
    name: 'virtualCareOffered',
    label: 'Virtual care offered',
    isChecked: false,
  },
  {
    name: 'hoursOfOperation',
    label: 'Hours of operation',
    isChecked: false,
  },
  {
    name: 'providerEthnicityExpress',
    label: 'Provider Ethnicity',
    isChecked: false,
  },
  {
    name: 'AccessProvider',
    label: 'Express Access Provider',
    isChecked: false,
  },
];

export const NULL_ATTRIBUTE_STATE = {
  SPECIALTY: 'Specialty information not available',
  LANGUAGES_SPOKEN: 'Languages spoken information not available',
  ACCEPTING_NEW_PATIENTS: 'Accepting new patients information not available',
  GENDER: 'Gender information not available',
  HEALTH_GRADE_RATINGS: 'Reviews information not available',
  VIRTUAL_CARE_OFFERED: 'Virtual care information not available',
  DISTANCE: 'Distance information not available',
  ADDRESS: 'Address information not available',
  PHONE_NUMBER: 'Phone number not available',
  HOURS_OF_OPERATION: 'Hours of operation not available',
  TIER_ONE_PROVIDER: 'Tier 1 information not available',
  PREMIUM_CARE_PROVIDER: 'Premium Care Physician information not available',
  PCP_SERVICES_AVAILABILE: 'PCP Services information not available',
  EDUCATION_SCHOOLS: 'University information not available',
  EDUCATION_DEGREES: 'Degree information not available',
};

export const ATTRIBUTE_STATUS_TEXT = {
  ACCEPTING_NEW_PATIENTS: {
    TRUE: 'Accepting new patients',
    FALSE: 'Not accepting new patients',
  },
  VIRTUAL_CARE_OFFERED: {
    TRUE: 'Offers virtual care',
    FALSE: 'Does not offer virtual care',
  },
  TIER_ONE_PROVIDER: {
    TRUE: 'Tier 1 Provider',
    FALSE: 'Not a Tier 1 Provider',
  },
  PREMIUM_CARE_PROVIDER: {
    TRUE: 'Premium Care Physician',
    FALSE: 'Not a Premium Care Physician',
  },
  PCP_SERVICES_AVAILABILE: {
    TRUE: 'PCP services available',
    FALSE: 'PCP services not available',
  },
};

export const MAX_NUM_OF_ATTRIBUTES = 7; // in-Network/Not-In-Network is the 8th attribute on Edit Compare Floating section

export const MockNullProvider = {
  address: {
    line: [''],
    city: '',
    district: '',
    state: '',
    postalCode: '',
  },
  phones: {
    phone: [''],
    emergency: [''],
    appointment: [''],
    fax: [''],
  },
  distance: '',
  facilityType: '',
  gender: '',
  latitude: '',
  locationId: '',
  longitude: '',
  networkStatus: '',
  practitionerRoleId: '',
  professionalDesignation: '',
  providerId: '',
  providerName: 'Mock Null Provider for QA',
  specialities: [],
  primaryDegrees: [],
  providerType: '',
  acceptingNewPatients: null,
  efficiencyCode: '',
  languagesSpoken: [''],
  hoursOfOperation: {
    sunday: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
  },
  qualityCode: '',
  virtualCareOffered: null,
  speciality: '',
  healthGradeRating: 'Reviews information not available',
  premiumCareProvider: null,
  hasPCPServiceAvailability: null,
  isTieredProvider: null,
  educationInfo: [
    {
      degrees: [
        { degreeCode: '', isPrimary: false },
        { degreeCode: '', isPrimary: false },
      ],
      schoolName: '',
    },
    {
      degrees: [
        { degreeCode: '', isPrimary: false },
        { degreeCode: '', isPrimary: false },
      ],
      schoolName: '',
    },
  ],
};

export const MockTrueProvider = {
  address: {
    line: ['785 Fake Really Long Street Name for testing'],
    city: 'North Pole',
    district: '',
    state: 'CA',
    postalCode: '92804',
  },
  phones: {
    phone: ['12345678900'],
    emergency: [''],
    appointment: [''],
    fax: [''],
  },
  distance: '',
  facilityType: '',
  gender: 'Male',
  latitude: '',
  locationId: '',
  longitude: '',
  networkStatus: '',
  practitionerRoleId: '',
  professionalDesignation: '',
  providerId: '',
  providerName: 'Mock True Provider',
  specialities: [],
  primaryDegrees: [],
  providerType: '',
  acceptingNewPatients: true,
  efficiencyCode: '',
  languagesSpoken: [
    'English, French, Italian, Chinese, Klingon, Zoomer, Hippie',
  ],
  hoursOfOperation: {},
  qualityCode: '',
  virtualCareOffered: true,
  speciality: '',
  healthGradeRating: 'Reviews information not available',
  premiumCareProvider: true,
  hasPCPServiceAvailability: true,
  isTieredProvider: true,
  educationInfo: [
    {
      degrees: [
        { degreeCode: 'MD', isPrimary: false },
        { degreeCode: 'DD', isPrimary: false },
      ],
      schoolName: 'Fake University of Northern Southern Californiaa',
    },
    {
      degrees: [
        { degreeCode: 'MD', isPrimary: false },
        { degreeCode: 'CD', isPrimary: false },
      ],
      schoolName: 'UC Berkeley; Berkeley, CA',
    },
    {
      degrees: [
        { degreeCode: 'MD', isPrimary: false },
        { degreeCode: 'DD', isPrimary: false },
      ],
      schoolName: 'University of California Davis; Davis, CA',
    },
  ],
};

export const MockFalseProvider = {
  phones: {
    phone: ['12345678900'],
    emergency: [''],
    appointment: [''],
    fax: [''],
  },
  distance: '5',
  facilityType: '',
  gender: 'Male',
  latitude: '',
  locationId: '',
  longitude: '',
  networkStatus: '',
  practitionerRoleId: '',
  professionalDesignation: '',
  providerId: '',
  providerName: 'Mock False Provider',
  specialities: [],
  primaryDegrees: [],
  providerType: '',
  acceptingNewPatients: false,
  efficiencyCode: '',
  languagesSpoken: ['English, French'],
  hoursOfOperation: {},
  qualityCode: '',
  virtualCareOffered: false,
  speciality: '',
  healthGradeRating: 'Reviews information not available',
  premiumCareProvider: false,
  hasPCPServiceAvailability: false,
  isTieredProvider: false,
  educationInfo: [
    {
      schoolName: '',
      degrees: [
        {
          degreeCode: '',
          isPrimary: '',
        },
      ],
    },
  ],
};

export const MockAttributesMissingProvider = {
  providerName: 'No Attributes Provider',
};

export const adobeCompareDrawerLinkLocation = 'compare drawer';
