import { useCollapse } from '@abyss/web/hooks/useCollapse';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIConstants } from '../../../../../api/src/services/Constants';
import { useLagoon } from '../../../hooks/useLagoon';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { getFeatureFlag } from '../../Utils/getFeatureFlag';
import { ObapiDemo } from '../ObapiDemo';
import { MemberInfo } from '../ObapiDemo/memberProfile';
import {
  CollapseIcon,
  CollapseIconContainer,
  DemoProviderSearchContainer,
  StyledSelectInput,
} from './DemoHeader.styled';

type Props = {
  form: any;
  providerType: string;
  searchType: string;
  onMemberDataChange?: (a: MemberInfo[]) => void;
  onMemberIdChange?: (a: number) => void;
  setMemberProfiles: (a: MemberInfo[]) => void;
  setProviderType: (a: string) => void;
  setSearchType: (a: string) => void;
};

export const DemoHeader = ({
  form = {},
  providerType = '',
  searchType = '',
  onMemberDataChange = () => {},
  onMemberIdChange = () => {},
  setMemberProfiles = () => {},
  setProviderType = () => {},
  setSearchType = () => {},
}: Props) => {
  const { t } = useTranslation();

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const demoFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DEMO_FLAG
  );

  const {
    collapseProps,
    buttonProps,
    isOpen: isOpenCollapse,
  } = useCollapse({ defaultIsOpen: false });

  const { 'aria-expanded': ariaExpanded, ...onClickButtonProp } = buttonProps;

  if (!demoFlag) {
    return null;
  }

  return (
    <React.Fragment>
      <CollapseIconContainer>
        <CollapseIcon
          data-testid={
            isOpenCollapse ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
          }
          {...onClickButtonProp}
          color="$primary1"
          icon={isOpenCollapse ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
        />
      </CollapseIconContainer>
      {isOpenCollapse ? (
        <div {...collapseProps}>
          <DemoProviderSearchContainer>
            <ObapiDemo
              setMemberId={onMemberIdChange}
              setMemberProfiles={(memberProfile) => {
                setMemberProfiles(memberProfile);
                onMemberDataChange(memberProfile);
              }}
            />
            <StyledSelectInput
              data-auto-testid="pick-one-search-type"
              data-testid="pick-one-search-type"
              isClearable
              isSearchable
              label="Search Type"
              onChange={(e) => {
                form?.setValue('searchType', e);
                setSearchType(e);
              }}
              onClear={() => setSearchType('')}
              options={[
                { value: 'name', label: 'Name' },
                { value: 'speciality', label: 'Speciality' },
                { value: 'location', label: 'Location' },
              ]}
              placeholder={t('Pick one')}
              value={searchType}
            />
            <StyledSelectInput
              data-auto-testid="pick-one-provider-type"
              data-testid="pick-one-provider-type"
              isClearable
              isSearchable
              label="Provider Type"
              onChange={(e) => {
                form?.setValue('providerType', e);
                setProviderType(e);
              }}
              onClear={() => setProviderType('')}
              options={[
                {
                  value: APIConstants.PROVIDER_TYPES.practitioner,
                  label: 'Practitioner',
                },
                {
                  value: APIConstants.PROVIDER_TYPES.organization,
                  label: 'Facility',
                },
              ]}
              placeholder={t('Pick one')}
              value={providerType}
            />
          </DemoProviderSearchContainer>
        </div>
      ) : null}
    </React.Fragment>
  );
};
