import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../../../../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../../../../../common/ConstantsLagoon';
import { useFeatureFlag } from '../../../../../../../../../hooks/useFeatureFlag';
import {
  ReviewsText,
  healthGradeRatingsNullStyle,
} from '../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../utility/compareDrawerConstants';

export interface Props {
  rating: string | number;
}
export const HealthGradeRating = ({ rating }: Props) => {
  const truncatedRatingForIcon = Math.trunc(Number(rating));
  const isRatingAvailable = !Number.isNaN(parseFloat(rating as string));
  const [disableCost] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_COST,
  ]);
  const { t } = useTranslation();

  return (
    <Layout.Group
      css={{ minHeight: '24px' }}
      data-auto-testid="compare-card-rating"
      data-testid="compare-card-rating"
      space=""
    >
      {isRatingAvailable &&
        Constants.RATE_LIMITING.map((param) => (
          <span key={param}>
            {disableCost ? null : (
              <Text color="$error1" size="14.22px">
                $$$
              </Text>
            )}
            <IconMaterial
              className="star-icon"
              css={{
                'abyss-icon': {
                  fill:
                    param < truncatedRatingForIcon ? '$secondary2' : '$black',
                  stroke: param < truncatedRatingForIcon ? '$starBorder' : '',
                },
              }}
              icon={
                param < truncatedRatingForIcon ? 'star_rate' : 'star_border'
              }
              size="12px"
            />
          </span>
        ))}
      {isRatingAvailable ? (
        <ReviewsText className="text" color="$primary1" size="12.64px">
          {Number(rating).toFixed(1)}
        </ReviewsText>
      ) : (
        <Text css={healthGradeRatingsNullStyle}>
          {t(NULL_ATTRIBUTE_STATE.HEALTH_GRADE_RATINGS)}
        </Text>
      )}
    </Layout.Group>
  );
};
