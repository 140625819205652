import { MemberInfo } from './PSXHeader/ObapiDemo/memberProfile';

const START_DATE = '2022-11-01';
const END_DATE = '9999-12-31';
const ADDRESS_LINE1 = '4040 N 62ND AVE';
const TYPE_CODE_DESC = 'EHB DENTAL';

export const MOCK_MEMBERS_INFO_3: MemberInfo[] = [
  {
    isPcpRequired: false,
    demographics: {
      dateOfBirth: '04/01/1986',
      name: {
        firstName: 'Malcolm',
        lastName: 'John',
      },
      gender: 'MALE',
      dependentSeqNbr: '00',
      relationshipTypeCode: {
        code: 'SUBSCRIBER',
      },
      address: [
        {
          addressType: 'PERMANENT',
          address1: '20 W 34th St',
          city: 'New York',
          state: 'NY',
          zipCode: '10001',
        },
      ],
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'Joseph Aurilio',
        providerID: '000308678',
        pcpID: '000308678 001',
      },
    },
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'PHARMACY',
                coverageTypeCode: 'RX',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              designatedNetwork: [],
              virtualPCPAssignedInd: 'N',
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'DENTAL',
                coverageTypeCode: 'D',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'BEHAVIORAL HEALTH',
                coverageTypeCode: 'B',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'VISION',
                coverageTypeCode: 'V',
              },
            },
          ],
        },
      },
    ],
    reciprocityId: '52',
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    demographics: {
      dateOfBirth: '12/06/2008',
      name: {
        firstName: 'Michelangelo',
        lastName: 'John',
      },
      gender: 'MALE',
      dependentSeqNbr: '00',
      relationshipTypeCode: {
        code: 'DEPENDENT',
      },
      address: [
        {
          addressType: 'PERMANENT',
          address1: '21 W 35th St',
          city: 'New York',
          state: 'NY',
          zipCode: '10002',
        },
      ],
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'David Abraham',
        providerID: '006504354',
        pcpID: '006504354 003',
      },
    },
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'PHARMACY',
                coverageTypeCode: 'RX',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
            },
          ],
        },
      },
    ],
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    demographics: {
      dateOfBirth: '03/16/2004',
      name: {
        firstName: 'Mike',
        lastName: 'Lee',
      },
      dependentSeqNbr: '00',
      relationshipTypeCode: {
        code: 'DEPENDENT',
      },
      gender: 'MALE',
      address: [
        {
          addressType: 'PERMANENT',
          address1: '22 W 36th St',
          city: 'New York',
          state: 'NY',
          zipCode: '10003',
        },
      ],
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'Adelakun Elizabeth',
        providerID: '006668623',
        pcpID: '006668623 005',
      },
    },
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'PHARMACY',
                coverageTypeCode: 'RX',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'VISION',
                coverageTypeCode: 'V',
              },
            },
          ],
        },
      },
    ],
  },
];

export const MOCK_MEMBERS_INFO: MemberInfo[] = [
  {
    demographics: {
      dateOfBirth: '1978-04-25',
      name: {
        firstName: 'Fnotfadjlfii',
        lastName: 'Lnihxcmnrp',
      },
      gender: 'FEMALE',
      dependentSeqNbr: '00',
      relationshipTypeCode: {
        code: 'SUBSCRIBER',
      },
      address: [
        {
          addressType: 'PERMANENT',
          address1: '1111 NEW STREET',
          city: 'CYPRESS',
          state: 'CA',
          zipCode: '90630',
        },
      ],
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'Jen Cindy C',
        providerID: '003634837',
        pcpID: '003634837 001',
        imageLocation:
          'https://findcare-dev.uhc.com/bio-pics/796d3936a634dafc156427cee36d162e27a4b17a.jpg',
      },
    },
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
            },
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'BEHAVIORAL HEALTH',
                coverageTypeCode: 'B',
              },
            },
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'DENTAL',
                coverageTypeCode: 'D',
              },
            },
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'VISION',
                coverageTypeCode: 'V',
              },
            },
          ],
          policyKey: '00_MEDICAL_0915181_2023-12-31',
        },
      },
    ],
    isPcpRequired: false,
    isGatedUser: false,
    isUrgentCareEligible: true,
    lineOfBusiness: 'E&I',
    reciprocityId: '52',
    policyNumber: '0915181',
    viewPcp: true,
    editPcp: true,
    viewDependentPcp: true,
    editDependentPcp: true,
    planSummary: [
      {
        productId: 'LAWW',
        productDetail: [
          {
            providerNetwork: [
              'UBH_GENERAL',
              'LEGACYEMP',
              'BCBANETWORK',
              'MEDICARE',
            ],
            externalAccountNumber: '0915181',
            cbmsAccountNumber: '0',
            siteId: '0',
          },
        ],
      },
    ],
    familyId: '123456',
  },
  {
    demographics: {
      dateOfBirth: '1991-04-25',
      name: {
        firstName: 'Fnwrxtbgmjiy',
        lastName: 'Lnihxcmnrp',
      },
      dependentSeqNbr: '01',
      relationshipTypeCode: {
        code: 'SPOUSE',
      },
      gender: 'FEMALE',
      address: [
        {
          addressType: 'PERMANENT',
          address1: '1111 NEW STREET',
          city: 'CYPRESS',
          state: 'CA',
          zipCode: '90630',
        },
      ],
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'Tang Susan',
        providerID: '006862242',
        pcpID: '006862242 003',
        imageLocation:
          'https://findcare-dev.uhc.com/cdn/assets/images/default_Practitioner.jpg',
      },
    },
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
            },
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: '"BEHAVIORAL HEALTH"',
                coverageTypeCode: 'B',
              },
            },
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'DENTAL',
                coverageTypeCode: 'D',
              },
            },
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'VISION',
                coverageTypeCode: 'V',
              },
            },
          ],
          policyKey: '00_MEDICAL_0915181_2023-12-31',
        },
      },
    ],
    isPcpRequired: false,
    isGatedUser: false,
    isUrgentCareEligible: true,
    lineOfBusiness: 'E&I',
    reciprocityId: '52',
    policyNumber: '0915181',
    viewPcp: true,
    editPcp: true,
    viewDependentPcp: false,
    editDependentPcp: false,
    familyId: '123456',
  },
];

export const MOCK_MEMBERS_INFO_2: MemberInfo[] = [
  {
    isPcpRequired: false,
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [],
        },
      },
    ],
    demographics: {
      dateOfBirth: '1979-02-21',
      dependentSeqNbr: '01',
      gender: 'FEMALE',
      address: [
        {
          addressType: 'PERMANENT',
          address1: ADDRESS_LINE1,
          city: 'PHOENIX',
          state: 'AZ',
          zipCode: '85033',
        },
      ],
      relationshipTypeCode: {
        code: 'SUBSCRIBER',
      },
      name: {
        firstName: 'Teresa',
        lastName: 'Zapien Hinojos',
      },
    },
    reciprocityId: '52',
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: 'EXSG',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: TYPE_CODE_DESC,
                coverageTypeCode: 'DS',
              },
            },
          ],
        },
      },
    ],
    demographics: {
      dateOfBirth: '2005-02-07',
      dependentSeqNbr: '04',
      address: [
        {
          addressType: 'PERMANENT',
          address1: ADDRESS_LINE1,
          city: 'PHOENIX',
          state: 'AZ',
          zipCode: '85033',
        },
      ],
      gender: 'FEMALE',
      relationshipTypeCode: {
        code: 'DEPENDENT',
      },
      name: {
        firstName: 'Jimena',
        lastName: 'Lazcano Zapien',
      },
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'up stuff',
        providerID: '678787',
        pcpID: '428722',
      },
    },
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: 'EXSG',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: TYPE_CODE_DESC,
                coverageTypeCode: 'DS',
              },
            },
          ],
        },
      },
    ],
    demographics: {
      dateOfBirth: '2009-10-31',
      dependentSeqNbr: '03',
      address: [
        {
          addressType: 'PERMANENT',
          address1: ADDRESS_LINE1,
          city: 'PHOENIX',
          state: 'AZ',
          zipCode: '85033',
        },
      ],
      gender: 'FEMALE',
      relationshipTypeCode: {
        code: 'DEPENDENT',
      },
      name: {
        firstName: 'Rodrigo',
        lastName: 'Garcia Zapien',
      },
    },
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [],
        },
      },
    ],
    demographics: {
      dateOfBirth: '1979-05-07',
      dependentSeqNbr: '02',
      gender: 'FEMALE',
      address: [
        {
          addressType: 'PERMANENT',
          address1: ADDRESS_LINE1,
          city: 'PHOENIX',
          state: 'AZ',
          zipCode: '85033',
        },
      ],
      relationshipTypeCode: {
        code: 'SPOUSE',
      },
      name: {
        firstName: 'Miguel',
        lastName: 'Garcia',
      },
    },
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: 'EXSG',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: TYPE_CODE_DESC,
                coverageTypeCode: 'DS',
              },
            },
          ],
        },
      },
    ],
    demographics: {
      dateOfBirth: '2008-04-26',
      dependentSeqNbr: '05',
      address: [
        {
          addressType: 'PERMANENT',
          address1: ADDRESS_LINE1,
          city: 'PHOENIX',
          state: 'AZ',
          zipCode: '85033',
        },
      ],
      gender: 'FEMALE',
      relationshipTypeCode: {
        code: 'DEPENDENT',
      },
      name: {
        firstName: 'Adolfo',
        lastName: 'Garcia Zapien',
      },
    },
  },
];

export const MOCK_MEMBERS_INFO_4: MemberInfo[] = [
  {
    demographics: {
      dateOfBirth: '1978-04-25',
      name: {
        firstName: 'Fnotfadjlfii',
        lastName: 'Lnihxcmnrp',
      },
      gender: 'FEMALE',
      dependentSeqNbr: '00',
      relationshipTypeCode: {
        code: 'SUBSCRIBER',
      },
      address: [
        {
          addressType: 'PERMANENT',
          address1: '1111 NEW STREET',
          city: 'CYPRESS',
          state: 'CA',
          zipCode: '90630',
        },
      ],
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'Jen Cindy C',
        providerID: '003634837',
        pcpID: '003634837 001',
        imageLocation:
          'https://findcare-dev.uhc.com/bio-pics/796d3936a634dafc156427cee36d162e27a4b17a.jpg',
      },
    },
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
            },
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'BEHAVIORAL HEALTH',
                coverageTypeCode: 'B',
              },
            },
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'DENTAL',
                coverageTypeCode: 'D',
              },
            },
            {
              groupNumber: '0915181',
              planVariationCode: '8888',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: '2023-01-01',
                endDate: '2023-12-31',
              },
              typeCode: {
                desc: 'VISION',
                coverageTypeCode: 'V',
              },
            },
          ],
          policyKey: '00_MEDICAL_0915181_2023-12-31',
        },
      },
    ],
    isPcpRequired: false,
    isGatedUser: false,
    isUrgentCareEligible: true,
    lineOfBusiness: 'E&I',
    reciprocityId: '52',
    policyNumber: '0915181',
    viewPcp: true,
    editPcp: true,
    viewDependentPcp: true,
    editDependentPcp: true,
    planSummary: [
      {
        productId: 'LAWW',
        productDetail: [
          {
            providerNetwork: [
              'UBH_GENERAL',
              'LEGACYEMP',
              'BCBANETWORK',
              'MEDICARE',
            ],
            externalAccountNumber: '0915181',
            cbmsAccountNumber: '0',
            siteId: '0',
          },
        ],
      },
    ],
    familyId: '123456',
  },
];
