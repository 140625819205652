import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React, { ReactNode, useState } from 'react';

import { RadioGroup } from '../RadioGroup';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';

type Radio = {
  // eslint-disable-next-line react/no-unused-prop-types
  label: String | ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  value: String;
};

type Props = {
  defaultValue?: string | number | boolean;
  title: string;
  name: string;
  size: string;
  color?: string;
  icon?: string;
  variant?: string;
  model: string;
  options: Radio[];
  onApply: (value: string) => void;
};

export const SortByMobile = ({
  defaultValue,
  title,
  name,
  size,
  color = '$primary1',
  icon = 'sort',
  variant = 'outline',
  model,
  options,
  onApply,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const properties = {
    drawerSize: 'fit-content',
    drawerPosition: 'bottom',
  };

  const handOnApply = (value: string) => {
    if (value) {
      onApply(value);
    }
    setIsOpen(false);
  };

  const handleOnClick = () => {
    const linkName: string = title;
    const linkLocation: string = 'body:sort by';
    const modalName: string = title;
    handleLinkAndModalTrack(linkName, linkLocation, modalName);
    setIsOpen(true);
  };

  return (
    <React.Fragment>
      {isOpen ? (
        <Drawer
          css={{
            'abyss-modal-content-container': {
              borderRadius: '20px 20px 0px 0px',
              padding: '$md',
              '.abyss-radio-root': {
                '&:hover': {
                  backgroundColor: '$tint2',
                },
              },
            },
            'abyss-modal-close-button': {
              top: '12px',
              right: '12px',
            },
            'abyss-modal-header-container': {
              padding: '0',
              marginBottom: '$md',
            },
            'abyss-modal-content-title': {
              fontFamily: '$primary !important',
              fontSize: '$mediumText !important',
              fontWeight: '$bold !important',
              lineHeight: '24px !important',
              color: '$gray-07 !important',
              padding: '0',
            },
          }}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          position={properties.drawerPosition}
          size={properties.drawerSize}
          title={title}
        >
          <RadioGroup
            defaultValue={defaultValue}
            labelFontSize="18px"
            locationForAnalytics={`modal:${title}`}
            model={model}
            onApply={handOnApply}
            options={options}
            submitBtnName="Sort"
          />
        </Drawer>
      ) : null}
      <Button
        css={{
          'abyss-button-root': {
            border: 'solid 2px $primary1',
            '&:active, &:focus, &:hover': {
              backgroundColor: '#CCF2F7',
            },
          },
        }}
        data-auto-testid="result-sort-mobile"
        data-testid="result-sort-mobile"
        onClick={handleOnClick}
        size={size}
        variant={variant}
      >
        <Layout.Group>
          <IconMaterial color={color} icon={icon} />
          <Text color={color} fontWeight={600}>
            {name}
          </Text>
        </Layout.Group>
      </Button>
    </React.Fragment>
  );
};
