import { styled } from '@abyss/web/tools/styled';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Print } from '@abyss/web/ui/Print';
import { Text } from '@abyss/web/ui/Text';
import { format } from 'date-fns';
import find from 'lodash/find';
import React from 'react';

import { ConstantsLagoon } from '../../../../../../common/ConstantsLagoon';
import { useLagoon } from '../../../../../../hooks/useLagoon';
import { CompareProvider } from '../../../../../../models/Provider';
import { CompareCheckboxInfo } from '../../CompareDrawer/utility/compareDrawerConstants';
import { PrintExpandedCard } from './PrintExpandedCard';

export const PrintViewAddress = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '9px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '11px',
  },
});

export const PrintViewDivider = styled(Divider, {
  '&.abyss-divider-root': {
    border: '0.5px solid #CCCCCC',
    height: 0,
    marginTop: '$sm !important',
    marginBottom: '$md !important',
    transform: 'matrix(1, 0, 0, 1, 0, 0)',
  },
});

export const PrintViewProviderMediumText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '14px',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '17px',
    marginBottom: '5px',
  },
});

export const PrintViewProviderBoldText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '10px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '11.87px',
  },
});

export const PrintViewProviderNormalText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '10px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '11.87px',
  },
});

export const PrintViewProviderDetailsXSText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '14px',
  },
});

type Props = {
  selectedProviders: CompareProvider[];
  compareCheckboxes: CompareCheckboxInfo[];
};

export const PrintCompareDrawer = ({
  selectedProviders,
  compareCheckboxes,
}: Props) => {
  const lagoonData = useLagoon('ui-messaging')();
  const printViewContent = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.PRINT_VIEW_CONTENT,
  });

  return (
    <Print.Section css={{ border: 'none', padding: '10px' }}>
      <Flex alignContent="flex-start" direction="column">
        <Flex justify="space-between">
          <Brandmark
            affiliate="uhc"
            brand="uhc"
            color="blue"
            css={{
              'abyss-brandmark': {
                width: '114.24px',
                height: '35.36px',
              },
            }}
            size="$lg"
            variant="lockup"
          />
          <Flex
            alignContent="flex-start"
            css={{
              'abyss-flex-root': {
                textAlign: 'right',
              },
            }}
            direction="column"
          >
            <PrintViewAddress>United HealthCare Services, Inc</PrintViewAddress>
            <PrintViewAddress> Duluth Service Center</PrintViewAddress>
            <PrintViewAddress> Atlanta, GA 30374-0800</PrintViewAddress>
            <PrintViewAddress> Phone: 1-800-357-1371</PrintViewAddress>
          </Flex>
        </Flex>
        <PrintViewDivider orientation="horizontal" />
        <PrintViewProviderMediumText color="$black">
          Selected providers
        </PrintViewProviderMediumText>
        <Flex justify="space-between">
          <Flex>
            <PrintViewProviderBoldText color="$black">
              Provider:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="$black">
              Healthcare Group
            </PrintViewProviderNormalText>
          </Flex>
          <Flex>
            <PrintViewProviderBoldText color="$black">
              Date:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="$black">
              {format(new Date(), 'MMMM dd, yyyy')}
            </PrintViewProviderNormalText>
          </Flex>
        </Flex>
        <Flex justify="space-between">
          <Flex>
            <PrintViewProviderBoldText color="$black">
              Provider Status:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="$black">
              Network
            </PrintViewProviderNormalText>
          </Flex>
          <Flex>
            <PrintViewProviderBoldText color="$black">
              Patient Account Number:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="$black">
              FG3355699098
            </PrintViewProviderNormalText>
          </Flex>
        </Flex>
        <PrintViewDivider orientation="horizontal" />
        {selectedProviders?.length ? (
          <Flex
            data-auto-testid="print-expanded-card"
            data-testid="print-expanded-card"
            direction="row"
            gutters
          >
            {selectedProviders.map((item) => (
              <React.Fragment key={item.locationId}>
                <PrintExpandedCard
                  compareCheckboxes={compareCheckboxes}
                  item={item}
                />
              </React.Fragment>
            ))}
          </Flex>
        ) : (
          <Print.Value>No Comparisions to display.</Print.Value>
        )}
      </Flex>
      <Markdown
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: '10px',
          fontSize: '9px',
        }}
      >
        {printViewContent?.message ?? ''}
      </Markdown>
    </Print.Section>
  );
};
