import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';

import { useIsOverflow } from '../../../../../../../../hooks/useIsOverflow';
import {
  CompareAddressDiv,
  CompareAddressText,
} from '../../../utility/compareDrawer.styled';

type Props = {
  addressLine: string;
};

export const CompareAddress = ({ addressLine }: Props) => {
  const address = addressLine || '';
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  return (
    <React.Fragment>
      <Layout.Group>
        <IconMaterial
          className="location-icon"
          data-auto-testid="data-card-location-on-icon"
          data-testid="data-card-location-on-icon"
          icon="location_on"
          size="20px"
        />
        {isOverflow ? (
          <Tooltip
            asChild={false}
            content={address}
            position="top"
            positionOffset={8}
            role="tooltip"
          >
            <CompareAddressDiv ref={containerRef} cssProps={{ isOverflow }}>
              <CompareAddressText
                data-auto-testid="data-card-address-text"
                data-testid="data-card-address-text"
              >
                {address}
              </CompareAddressText>
            </CompareAddressDiv>
          </Tooltip>
        ) : (
          <CompareAddressDiv ref={containerRef} cssProps={isOverflow}>
            <CompareAddressText
              data-auto-testid="data-card-address-text"
              data-testid="data-card-address-text"
            >
              {address}
            </CompareAddressText>
          </CompareAddressDiv>
        )}
      </Layout.Group>
    </React.Fragment>
  );
};
