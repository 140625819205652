export const ConstantsLagoon = {
  CONTENT_FILTERS: {
    INFO_MODAL_CONTENT: 'RESULT_SORT_INFO_MODAL_CONTENT',
    PREMIUM_CARE_PHYSICIAN_CONTENT: 'PREMIUM_CARE_PHYSICIAN_CONTENT',
  },
  FEATURE_FLAGS: {
    DEMO_FLAG: 'DEMO',
    REQUEST_RECIPROCITY_ID: 'REQUEST_RECIPROCITY_ID',
    EDUCATIONAL_CONTENT_ENABLED: 'EDUCATIONAL_CONTENT_ENABLED',
    DISABLE_COST: 'DISABLE_COST',
    ENABLE_PRE_PROD: 'ENABLE_PRE_PROD_ENV',
    PRINT_SHARE_DIRECTORY: 'PRINT_SHARE_DIRECTORY',
    NOTIFICATIONS: 'NOTIFICATIONS',
    DECEMBER_RELEASE_ENABLED: 'DECEMBER_RELEASE_ENABLED',
    SHARE_RECIPIENT_DISABLE: 'SHARE_RECIPIENT_DISABLE',
    THINGS_TO_KNOW_DECEMBER_RLS: 'THINGS_TO_KNOW_DECEMBER_RLS',
    ENABLE_SEARCH_LOCATION: 'ENABLE_SEARCH_LOCATION',
    AUTOCOMPLETE_CAPTURE_RESULTS: 'AUTOCOMPLETE_CAPTURE_RESULTS',
  },
  MEDICAL_SPECIALISTS: {
    HOW_TO_GET_REFERRAL_CARD: 'HOW_TO_GET_REFERRAL',
    TIER_ONE_BENEFITS_ONE: 'TIER_ONE_BENEFITS_ONE',
    TIER_ONE_BENEFITS_TWO: 'TIER_ONE_BENEFITS_TWO',
  },
  BEHAVIORAL_HEALTH: {
    TITLE_KEY: 'TITLE',
    CONTENT_KEY: 'CONTENT',
    TALK_TO_NURSE_CARD_INFO: 'TALK_TO_NURSE_CARD_INFO',
  },
  PRIMARY_CARE: {
    PRIMARY_CARE_PROVIDERS: 'PRIMARY_CARE_PROVIDERS',
    PRIMARY_CARE_LOCATIONS: 'PRIMARY_CARE_LOCATIONS',
    PRIMARY_CARE_CLINICS: 'PRIMARY_CARE_CLINICS',
    PRIMARY_CARE_MEDICAL_GROUPS: 'PRIMARY_CARE_MEDICAL_GROUPS',
    TIER_ONE_PROVIDER: 'TIER_ONE_PROVIDER',
    TIERED_BENEFITS: 'TIERED_BENEFITS',
  },
  SHARE_THIS_PROVIDER: {
    SHARE_THIS_PROVIDER_MESSAGE: 'SHARE_THIS_PROVIDER_MESSAGE',
  },
  VALID_EMAIL_ADDRESS: {
    VALID_EMAIL_ADDRESS_MESSAGE: 'VALID_EMAIL_ADDRESS_MESSAGE',
  },
  SEARCH_LABEL: 'SEARCH_LABEL',
  LOCATION_LABEL: 'LOCATION_LABEL',
  SUGGESTED_PROVIDERS: {
    SUGGESTED_PROVIDERS_MESSAGE: 'SUGGESTED_PROVIDERS_MESSAGE',
  },
  HEALTHGRADES: {
    MODAL_DISCLAIMER_SHORT: 'HEALTH_GRADES_DISCLAIMER_CONTENT',
    MODAL_DISCLAIMER_FULL: 'HEALTH_GRADES_DISCLAIMER_POPUP',
    DISCLAIMER_IN_PAGE: 'HEALTH_GRADES_IN_PAGE',
    PRINT_VIEW_CONTENT: 'HEALTH_GRADES_PRINT_VIEW_CONTENT',
  },
  VISION: {
    TITLE_KEY: 'TITLE',
  },
  EDUCATIONAL_CONTENT_MODAL: {
    EDUCATIONAL_CONTENT_MODAL_TITLE: 'EDUCATIONAL_CONTENT_MODAL_TITLE',
    EDUCATIONAL_CONTENT_MODAL_DESCRIPTION:
      'EDUCATIONAL_CONTENT_MODAL_DESCRIPTION',
  },
  NATIONAL_ANCILLARY_NO_LOCATION_MODAL: 'NATIONAL_ANCILLARY_NO_LOCATION_MODAL',
  MEMBER_SELECTION_CONFIRMATION_MODAL: {
    MEMBER_SELECTION_MODAL_BODY_CONTENT: 'MEMBER_SELECTION_MODAL_BODY_CONTENT',
    MEMBER_SELECTION_MODAL_BODY_HEADER: 'MEMBER_SELECTION_MODAL_BODY_HEADER',
    MEMBER_SELECTION_MODAL_PCP_CONTENT: 'MEMBER_SELECTION_MODAL_PCP_CONTENT',
    MEMBER_SELECTION_MODAL_CARE_SECTION_CONTENT: 'MEMBER_SELECTION_MODAL_CARE_SECTION_CONTENT',
    MEMBER_SELECTION_MODAL_SCHEDULING_SECTION_CONTENT: 'MEMBER_SELECTION_MODAL_SCHEDULING_SECTION_CONTENT'
  },
};
