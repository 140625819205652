import { Box } from '@abyss/web/ui/Box';
import React from 'react';

import { ContentWrapper, PSXHeaderContentWrapper } from '../ContentWrapper';

interface Props {
  backgroundColor?: string;
  height?: string;
  children: React.ReactNode;
  showSearchInputBackButton?: boolean;
}

export const CardWrapper = ({
  backgroundColor = '$white',
  height = 'auto',
  children,
}: Props) => (
  <Box
    color={backgroundColor}
    css={{
      'abyss-box-root': {
        padding: '0',
        height,
      },
    }}
  >
    <ContentWrapper>{children}</ContentWrapper>
  </Box>
);

export const PSXHeaderCardWrapper = ({
  backgroundColor = '$white',
  height = 'auto',
  showSearchInputBackButton,
  children,
}: Props) => (
  <Box
    color={backgroundColor}
    css={{
      'abyss-box-root': {
        '@screen < $md': {
          borderBottom: 'solid',
          borderColor: '#E5E5E6',
        },
        padding: '0',
        height,
      },
    }}
  >
    <PSXHeaderContentWrapper
      showSearchInputBackButton={showSearchInputBackButton}
    >
      {children}
    </PSXHeaderContentWrapper>
  </Box>
);

export const CardWrapperDetailsPage = ({
  backgroundColor = '$white',
  height = 'auto',
  children,
}: Props) => (
  <Box
    color={backgroundColor}
    css={{
      'abyss-box-root': {
        height,
        padding: '0px',
      },
    }}
  >
    {children}
  </Box>
);
