import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { cardHeaderMyBenefits, phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import {
  BoxStyles,
  ChipContainer,
  ContainerStyle,
  textContent,
} from './StillNeedHelp.style';

export const StillNeedHelp = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const largeScreen = useMediaQuery('(max-width: 1100px)');
  const list = [
    {
      title: t('STILL_NEED_HELP.TITLE_CHAT'),
      content1: t('STILL_NEED_HELP.CONTENT_CHAT1'),
      content2: t('STILL_NEED_HELP.CONTENT_CHAT2'),
      icon: 'chat',
      href: 'https://member.uhc.com/help',
      adobeLikType: 'internal',
      dataTestId: 'chat-with-us',
    },
    {
      title: t('STILL_NEED_HELP.TITLE_PHONE'),
      content1: t('STILL_NEED_HELP.CONTENT_PHONE1'),
      content2: t('STILL_NEED_HELP.CONTENT_PHONE2'),
      icon: 'phone',
      href: `tel:18778444999`,
      adobeLikType: 'phone',
      dataTestId: 'call-us',
    },
  ];

  const handleClick = (dataTestId, adobeLinkType) => {
    adobeLinkTrackEvent({
      name: dataTestId,
      location: 'body:still need help',
      type: adobeLinkType,
    });
  };
  return (
    <Box color="$gray1" css={BoxStyles}>
      <ContentWrapper>
        <Heading
          css={cardHeaderMyBenefits}
          data-auto-testid="commonly-searched-card-heading"
          data-testid="commonly-searched-card-heading"
          display="h4"
          offset={1}
        >
          {t('STILL_NEED_HELP.HEADING')}
        </Heading>
        <ChipContainer>
          {list.map((item, i) => (
            <ContainerStyle
              data-auto-testid={`${i}${item.dataTestId}`}
              data-testid={`${i}${item.dataTestId}`}
              href={item.href}
              onClick={() => handleClick(item.dataTestId, item.adobeLikType)}
            >
              {' '}
              <Flex alignItems="center" css={{ gap: '10px', width: '85%' }}>
                <IconBrand icon={item.icon} size={48} variant="twotone" />
                <Layout.Stack alignItems="left" css={textContent}>
                  <Text fontWeight={700} size={mobileScreen ? '$md' : '$lg'}>
                    {item.title}
                  </Text>
                  <Flex direction={largeScreen ? 'column' : 'row'}>
                    <Text fontWeight={500} size={mobileScreen ? '$sm' : '$md'}>
                      {item.content1}
                    </Text>
                    <Text fontWeight={500} size={mobileScreen ? '$sm' : '$md'}>
                      {item.content2}
                    </Text>
                  </Flex>
                </Layout.Stack>
              </Flex>
              <Button rounded size="$sm" variant="ghost">
                <IconMaterial icon="chevron_right" />
              </Button>
            </ContainerStyle>
          ))}
        </ChipContainer>
      </ContentWrapper>
    </Box>
  );
};
