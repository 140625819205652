import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeFuncErrorEvent } from '../../common/AdobeTagging/adobeFuncErrorEvent';
import { PSXHeader } from '../../common/PSXHeader';
import { Icon } from './Icon';
import { IconContainer, PageContainer, PageContent } from './Page404.style';

export const Page404 = () => {
  const { t } = useTranslation();
  const breadcrumbs = [{ title: t('ERROR_PAGE.404.title'), href: '' }];

  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { message, code, source, uiSection } = tokenData;

  useEffect(() => {
    adobeFuncErrorEvent({
      message,
      source,
      code: code ?? '404',
      uiSection,
    });
  }, []);

  return (
    <Fragment>
      <PSXHeader
        breadcrumbs={breadcrumbs}
        dataTestId="error-404-search-form"
        showChips={false}
        showChoosePCPHeader={false}
        showDemoHeader={false}
        showMemberSelection={false}
      />
      <PageContainer>
        <IconContainer>
          <Icon />
        </IconContainer>
        <PageContent>
          <Text
            color="$interactive4"
            css={{
              'abyss-text-root': {
                marginTop: '34px',
                marginBottom: '4px',
                '@screen < $sm': {
                  marginBottom: '29px',
                  marginTop: 'unset',
                },
                display: 'block',
              },
            }}
            fontWeight="$bold"
            size="$xs"
          >
            {t('ERROR_PAGE.404.type')}
          </Text>
          <Heading
            css={{
              marginBottom: '4px',
              '@screen < $sm': {
                marginBottom: '16px',
              },
            }}
          >
            {t('ERROR_PAGE.404.heading')}
          </Heading>
          <Heading
            css={{
              marginBottom: '12px',
              '@screen < $sm': {
                marginBottom: '20px',
                maxWidth: '181px',
              },
            }}
            offset={5}
          >
            {t('ERROR_PAGE.404.subHeading')}
          </Heading>
          <Text size="$sm">{t('ERROR_PAGE.404.body1')}</Text>
        </PageContent>
      </PageContainer>
      {/* TODO: Replace below code with Still need help section when it's ready */}
      <div
        style={{
          height: '152px',
          background: '#E5E5E6',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Placeholder for "Still need help" section
      </div>
    </Fragment>
  );
};
