import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { ProviderLocation } from '../../models/ProviderDetails';
import {
  getFormattedPhoneNumber,
  getPrimaryPhone,
  getSelectedLocationIndex,
} from '../../utils/providerDetails.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { InteractButton } from './DetailsPageHeader.styled';

export type Props = {
  chip: string;
  providerLocations: ProviderLocation[];
};

export const DetailsPageChips = ({ chip, providerLocations }: Props) => {
  const { t } = useTranslation();

  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const index = getSelectedLocationIndex(providerLocations, selectedId);

  const phone = providerLocations?.[index]?.phones?.phone;
  const appointment = providerLocations?.[index]?.phones?.appointment;
  const primaryPhoneNumber = getPrimaryPhone(phone, appointment);
  const website = providerLocations?.[index]?.website;
  const email = providerLocations?.[index]?.emailAddress;

  const chipText = (chipValue) => {
    let chipContent;
    const phonePrimaryTestId = 'phone-primary-detailes-header';

    switch (chipValue) {
      case Constants.DETAILS_CHIP_NAMES.Directions:
        chipContent = Constants.DETAILS_CHIP_NAMES.Directions;
        break;

      case Constants.DETAILS_CHIP_NAMES.Call:
        if (primaryPhoneNumber === undefined || primaryPhoneNumber === null) {
          chipContent = null;
        } else {
          chipContent = (
            <InteractButton
              key={`provider-details-chips-${chipValue}`}
              before={<IconMaterial color="$primary1" icon="call_end" />}
              data-auto-testid={phonePrimaryTestId}
              data-testid={phonePrimaryTestId}
              href={`tel:${getFormattedPhoneNumber(primaryPhoneNumber)}`}
              onClick={() =>
                adobeLinkTrackEvent({
                  name: phonePrimaryTestId,
                  location: 'body:details header',
                  type: 'phone',
                })
              }
              variant="outline"
            >
              {t('DETAILS_CHIP_NAMES.Call')}
            </InteractButton>
          );
        }
        break;

      case Constants.DETAILS_CHIP_NAMES.Email:
        if (email === undefined || email === null) {
          chipContent = null;
        } else {
          chipContent = (
            <InteractButton
              key={`provider-details-chips-${chipValue}`}
              before={<IconMaterial color="$primary1" icon="email" />}
              color="$primary1"
              data-auto-testid={`${email}-email-address`}
              data-testid={`${email}-email-address`}
              fontWeight="$semibold"
              href={`mailto:${email}`}
              onClick={() =>
                adobeLinkTrackEvent({
                  name: 'email chip',
                  location: 'body:details header',
                  type: 'email',
                  destinationUrl: `mailto:${email}`,
                })
              }
              variant="outline"
            >
              {t('DETAILS_CHIP_NAMES.Email')}
            </InteractButton>
          );
        }
        break;

      case Constants.DETAILS_CHIP_NAMES.Website:
        if (website === undefined || website === null || website === '') {
          chipContent = null;
        } else {
          chipContent = (
            <InteractButton
              key={`provider-details-chips-${chipValue}`}
              before={<IconMaterial color="$primary1" icon="web" />}
              color="$primary1"
              data-auto-testid={`provider-website${website}`}
              data-testid={`provider-website${website}`}
              fontWeight="$semibold"
              href={website}
              onClick={() =>
                adobeLinkTrackEvent({
                  name: 'website chip',
                  location: 'body:details header',
                  type: 'external',
                  destinationUrl: website,
                })
              }
              variant="outline"
            >
              {t('DETAILS_CHIP_NAMES.Website')}
            </InteractButton>
          );
        }
        break;
    }
    return chipContent;
  };
  return chipText(chip);
};
