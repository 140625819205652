import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import translationsEnJson from '../../../public/locale/en/translation.json';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useLagoon } from '../../hooks/useLagoon';
import { adobeSearchFilterEvent } from '../AdobeTagging/adobeSearchFilterEvent';
import { Constants, SortOptionValues } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { Distance } from '../ProviderDetailTabs/ProviderLocationsDetails/Distance';
import { getFormattedFilters } from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { getFeatureFlag } from '../Utils/getFeatureFlag';
import { InfoModal } from './InfoModal';
import { SortBy } from './SortBy';
import { SortByMobile } from './SortByMobile';

export const SortContainer = styled('div', {
  '.abyss-radio-root ~div': {
    '&:hover': {
      backgroundColor: '$tint2',
    },
  },
});

export const ToggleSortButton = styled('div', {
  height: '42px',
  fontSize: '16px',
  padding: '$sm $lg $sm $md',
  border: '1px solid $gray4',
  borderRadius: '24px',
  display: 'flex',
  alignItems: 'center',
  gap: '$xs',
});

type Props = {
  sectionType?: string;
};

export const ResultSort = ({ sectionType }: Props) => {
  const { t } = useTranslation();

  const featureFlags = useLagoon('feature-flags')();
  const showCustomSort = getFeatureFlag(
    featureFlags,
    'RESULT_PAGE_CUSTOM_SORT'
  );

  const options = {
    BEST_MATCH: t('SORT_OPTIONS.BEST_MATCH'),
    HIGHEST_RATING: t('SORT_OPTIONS.HIGHEST_RATING'),
    CLOSEST: t('SORT_OPTIONS.CLOSEST'),
  };

  const customSort = {
    TIER_PROV: t('SORT_OPTIONS.TIER_PROV'),
    ACO_PROV: t('SORT_OPTIONS.ACO_PROV'),
    UHPD_PROV: t('SORT_OPTIONS.UHPD_PROV'),
    COST_EFF_RATING: t('SORT_OPTIONS.COST_EFF_RATING'),
    ALPHA_A_Z: t('SORT_OPTIONS.ALPHA_A_Z'),
    ALPHA_Z_A: t('SORT_OPTIONS.ALPHA_Z_A'),
  };

  const { searchFilters, setSearchFilters } = useContext(SearchFilterContext);
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = useMediaQuery(phoneOnly);

  const onApply = (value: string) => {
    const selectedFilter = {
      SortBy: {
        label: options[value] || customSort[value],
        value,
        hide: true,
        dnt_label: translationsEnJson.SORT_OPTIONS[value],
      },
    };
    setSearchFilters({
      ...searchFilters,
      ...selectedFilter,
    });
    adobeSearchFilterEvent({
      type: sectionType,
      filters: getFormattedFilters(selectedFilter),
    });
  };

  const sort = {
    label: (
      <React.Fragment>
        <ToggleSortButton
          data-auto-testid="toggle-sort-button"
          data-testid="toggle-sort-button"
        >
          <IconMaterial
            color={mobileScreen ? '$primary1' : '$descriptionBlack'}
            icon="swap_vert"
          />
          <Text fontWeight="$semibold" size="$md">
            {searchFilters?.SortBy?.label || t('SORT_BUTTONS.SORT')}
          </Text>
          <IconMaterial
            color={mobileScreen ? '$primary1' : '$descriptionBlack'}
            icon="keyboard_arrow_down"
          />
        </ToggleSortButton>
      </React.Fragment>
    ),
    title: t('SORT_BUTTONS.SORT_RESULT'),
    name: t('SORT_BUTTONS.SORT'),
    icon: 'sort',
    model: 'SortBy',
    type: 'radio',
    options: [
      {
        label: (
          <React.Fragment>
            <Flex css={{ gap: '$xs' }}>
              <Text>{options.BEST_MATCH}</Text>
              <Distance
                content={t('SORT_OPTIONS.BEST_MATCH_POPOVER_CONTENT')}
                title={options.BEST_MATCH}
                widths={297}
              />
            </Flex>
          </React.Fragment>
        ),
        value: SortOptionValues.BEST_MATCH,
        dnt_label: translationsEnJson.SORT_OPTIONS.BEST_MATCH,
      },
      {
        label: options.HIGHEST_RATING,
        value: SortOptionValues.HIGHEST_RATING,
        dnt_label: translationsEnJson.SORT_OPTIONS.HIGHEST_RATING,
      },
      {
        label: options.CLOSEST,
        value: SortOptionValues.CLOSEST,
        dnt_label: translationsEnJson.SORT_OPTIONS.CLOSEST,
      },
      ...(showCustomSort
        ? [
            {
              label: customSort.TIER_PROV,
              value: SortOptionValues.TIER_PROV,
              dnt_label: translationsEnJson.SORT_OPTIONS.TIER_PROV,
            },
            {
              label: customSort.ACO_PROV,
              value: SortOptionValues.ACO_PROV,
              dnt_label: translationsEnJson.SORT_OPTIONS.ACO_PROV,
            },
            {
              label: customSort.UHPD_PROV,
              value: SortOptionValues.UHPD_PROV,
              dnt_label: translationsEnJson.SORT_OPTIONS.UHPD_PROV,
            },
            {
              label: customSort.COST_EFF_RATING,
              value: SortOptionValues.COST_EFF_RATING,
              dnt_label: translationsEnJson.SORT_OPTIONS.COST_EFF_RATING,
            },
          ]
        : []),
      ...(sectionType !== Constants.RESULT_SECTION.PROVIDER && showCustomSort
        ? [
            {
              label: customSort.ALPHA_A_Z,
              value: SortOptionValues.ALPHA_A_Z,
              dnt_label: translationsEnJson.SORT_OPTIONS.ALPHA_A_Z,
            },
            {
              label: customSort.ALPHA_Z_A,
              value: SortOptionValues.ALPHA_Z_A,
              dnt_label: translationsEnJson.SORT_OPTIONS.ALPHA_Z_A,
            },
          ]
        : []),
    ],
  };

  return (
    <SortContainer>
      {mobileScreen ? (
        <SortByMobile
          defaultValue={searchFilters?.SortBy?.value}
          icon={sort.icon}
          model={sort.model}
          name={sort.name}
          onApply={onApply}
          options={sort.options}
          size="$sm"
          title={sort.title}
        />
      ) : (
        <SortBy
          defaultValue={searchFilters?.SortBy?.value}
          label={sort.label}
          model={sort.model}
          onApply={onApply}
          options={sort.options}
          title={sort.title}
        />
      )}

      <InfoModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </SortContainer>
  );
};
