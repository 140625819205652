import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton } from '@abyss/web/ui/Skeleton';
import React from 'react';

export const Shimmer = () => {
  const mobileScreen = useMediaQuery('(max-width: 670px)');
  return (
    <Layout.Stack alignItems="left" data-testid="shimmer" space={11}>
      <Skeleton height={12} width={mobileScreen ? 164 : 310} />
      <Skeleton height={12} width={mobileScreen ? 133 : 261} />
      <Skeleton height={12} width={mobileScreen ? 164 : 287} />
      {!mobileScreen && <Skeleton height={12} width={201} />}
    </Layout.Stack>
  );
};
