import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { FacilityLocationsResponse } from '../../models/FacilityDetails';
import { ProviderLocation } from '../../models/ProviderDetails';
import { getDistance } from '../../utils/card.utils';
import { shortenZipCode } from '../../utils/providerDetails.utils';
import { phoneOnly } from '../ConstantsStyles';
import {
  AcceptingNewPatientsBadge,
  LocationBadgeRow,
  LocationCardGroup,
  LocationCardWrapper,
  LocationDetailsStack,
  StyledCheckIcon,
  UpdateButtonMobileView,
} from './MapViewSelectLocationCard.style';

export type Props = {
  className?: string;
  location: ProviderLocation | FacilityLocationsResponse;
  selectLocation: () => void;
  selected?: boolean;
  setIsMapView: (isMapView: boolean) => void;
  showBadgeRow?: boolean;
  showRadioSelect?: boolean;
  handleUpdateLocation: () => void;
};

export const MapViewSelectLocationCard = ({
  location,
  showBadgeRow,
  showRadioSelect,
  selected,
  selectLocation,
  className = '',
  setIsMapView,
  handleUpdateLocation,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();
  const { address, distance } = location;
  const { line, state, postalCode } = address;

  const addressString = `${line.join(', ')}, ${state}, ${shortenZipCode(
    postalCode
  )}`;

  const handleLocationClick = () => {
    selectLocation();
  };

  const wrapperSelectedStyle = {
    border: '3px solid $interactive2',
  };

  return (
    <LocationCardWrapper
      aria-pressed={selected ? 'true' : 'false'}
      className={`location-card-wrap ${className}`}
      css={selected ? wrapperSelectedStyle : ''}
      data-auto-testid="select-location-card"
      data-testid="select-location-card"
      onClick={handleLocationClick}
    >
      <LocationCardGroup>
        {showRadioSelect ? (
          <StyledCheckIcon>
            {selected ? (
              <IconMaterial
                data-auto-testid="icon-location-selected"
                data-testid="icon-location-selected"
                icon="check_circle"
                size={24}
              />
            ) : (
              <IconMaterial
                color="$neutralGray5"
                data-auto-testid="icon-location-unselected"
                data-testid="icon-location-unselected"
                icon="radio_button_unchecked"
                size={24}
              />
            )}
          </StyledCheckIcon>
        ) : null}
        <LocationDetailsStack
          alignItems="left"
          className="location-details"
          grow
        >
          <Text
            aria-label={addressString}
            color="#4B4D4F"
            css={{
              'abyss-text-root': {
                lineHeight: '24px',
                fontSize: '18px',
                '@screen < $sm': { lineHeight: '20px', fontSize: '14.22px' },
              },
            }}
            fontWeight={selected ? '$bold' : '$medium'}
          >
            {addressString}
          </Text>
          {showBadgeRow ? (
            <LocationBadgeRow
              data-auto-testid="select-location-badge-row"
              data-testid="select-location-badge-row"
              justify="space-between"
            >
              <Layout.Group>
                <IconMaterial icon="directions" size="$sm" />
                <Text
                  color="$interactive1"
                  css={{ 'abyss-text-root': { fontSize: '12.64px' } }}
                  fontWeight="$bold"
                >
                  {getDistance(distance)} {t('miles')}
                </Text>
              </Layout.Group>
              <AcceptingNewPatientsBadge
                icon={<IconMaterial color="$grey8" icon="check" size="sm" />}
                variant="neutral"
              >
                {t('Accepting new patients')}
              </AcceptingNewPatientsBadge>
            </LocationBadgeRow>
          ) : null}
          {mobileScreen ? (
            <UpdateButtonMobileView
              data-auto-testid="choose-location"
              data-testid="choose-location"
              onClick={() => {
                handleUpdateLocation();
                setIsMapView(false);
              }}
            >
              {t('UPDATE_LOCATION')}
            </UpdateButtonMobileView>
          ) : null}
        </LocationDetailsStack>
      </LocationCardGroup>
    </LocationCardWrapper>
  );
};
