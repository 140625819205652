import { styled } from '@abyss/web/tools/styled';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';

export const CostBreakDownSection = styled('div', {
  width: '25%',
  lineHeight: '16px',
  '@screen < $md': {
    width: '33%',
    lineHeight: '16px',
  },
});

export const CostTopSection = styled(Flex, {
  boxSizing: 'border-box',
  border: '1px solid $cardBorder',

  borderLeft: 'none',
  borderRight: 'none',
  padding: '$lg 84px',
  width: '100%',
  gap: '24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justify: 'flex-start',
  flex: 'none',
  order: '1',
  alignSelf: 'stretch',
  flexGrow: '0',
  '@screen < $md': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    gap: '8px',
    flex: 'none',
    order: '1',
    alignSelf: 'stretch',
    flexGrow: 0,
  },
});

export const CostHeader = styled(Text, {
  fontSize: '20.25px !important',
  lineHeight: '24px !important',
  color: '$primary1',
  fontWeight: '$bold',
  '@screen < $md': {
    fontSize: '$md !important',
    lineHeight: '20px !important',
  },
});
export const InNetworkContainer = styled(Layout.Group, {
  flex: 1,

  '@screen < $md': {
    margin: '8px -4px 12px -4px !important',
    width: '100%',
  },
});

export const CostAverageContainer = styled(Layout.Group, {
  flex: 1,

  '@screen < $md': {
    margin: '12px -4px 20px -4px !important',
    width: '100%',
  },
});

export const MobileBox = styled(Box, {
  borderTop: '1px solid $cardBorder',
  borderBottom: '1px solid $cardBorder',
});

export const CostEstimateButtonContainer = styled('div', {
  flex: 1,
  '@screen < $md': {
    margin: '10px -4px !important',

    '> span': {
      // width: '100%',
    },
  },
});

export const CostEstimateButton = styled(Button, {
  fontWeight: '$semibold',
});

export const CostAndProceduresContainer = styled('div', {
  margin: '$lg 0px $lg 0px !important',
});

export const CommonProceduresAndCostContainer = styled('div', {
  padding: '$lg 84px $lg 84px',
  borderBottom: '1px solid $cardBorder',
  borderTop: '1px solid $cardBorder',
  gap: '24px',
  width: '100%',
  margin: '$sm 0',

  '@screen < $md': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '$cardBorder',
    padding: '$md $lg',
    gap: '20px',
    flex: 'none',
    order: 4,
    alignSelf: 'stretch',
    flexGrow: 0,
  },
});

export const CommonCostProceduresHeader = styled('h2', {
  fontSize: '25.63px !important',
  fontWeight: '$bold',
  lineHeight: '32px!important',
  '@screen < $md': {
    margin: '14px 36px 14px 0px !important',
    lineHeight: '24px!important',
    fontWeight: '$bold',
    fontSize: '20.25px !important',
  },
});
export const CostAccordianTrigger = styled(Accordion.Trigger, {
  '&.abyss-accordion-trigger': {
    padding: '0px',
    justifyContent: 'flex-start',
    fontSize: '$xl',
    fontWeight: '$bold',
    height: '24px',
    marginTop: '$lg',
    '@screen < $md': {
      fontSize: '$md',
      justifyContent: 'space-between',
    },
  },
});
export const CostAccordianContainer = styled(Accordion.Content, {
  background: '$white',
  '.abyss-accordion-content-text': {
    paddingLeft: 0,
    paddingTop: 0,
  },
});
