import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { ProviderType } from '../../models/ProviderDetails';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import GET_PROVIDER_DETAILS from './ProviderDetails.graphql';

export const useProviderDetailsSearch = (options) => {
  const [providerDetails, setProviderDetails] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GET_PROVIDER_DETAILS, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'providerDetails',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setProviderDetails(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: providerDetails,
  };

  return [parsedResult, queryAction];
};

export const useProviderDetails = (
  variables,
  setSelectedPCP?: Dispatch<SetStateAction<boolean>>
) => {
  const locale = getLanguage()?.code || 'en';
  const { providerId = '', providerType, isOHBS } = variables;
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { coverageType } = tokenData;
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const currentMember = getCurrentMember();
  const planVariationCodeForMedical = getPlanVariationCode(currentMember, 'M');

  const [providerDetails, getProviderDetails] = useProviderDetailsSearch({});

  useEffect(() => {
    if (
      typeof setSelectedPCP !== 'undefined' &&
      currentMember?.demographics?.primaryCare?.providerID === providerId
    ) {
      setSelectedPCP(true);
    }
    getProviderDetails({
      variables: {
        latitude,
        longitude,
        stateCode,
        locale,
        lob: currentMember?.lineOfBusiness,
        coverages: getCoverageTypes(currentMember),
        planYear: getCurrentPlanYear(),
        planVariationCode: planVariationCodeForMedical,
        policyId: currentMember?.policyNumber,
        providerId,
        providerType: providerType ?? ProviderType.PRACTITIONER,
        reciprocityId: isOHBS
          ? '100'
          : getNetworkIdsForPES(currentMember, coverageType),
        coverageType,
      },
    });
  }, [latitude, longitude, stateCode, providerId, coverageType]);

  return providerDetails;
};
