import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Icon } from '@abyss/web/ui/Icon';

export const PageContainer = styled(Flex, {
  background: '$tint1',
  height: '512px',
  gap: '12px',
  alignItems: 'center',
  alignContent: 'center',
  flexDirection: 'column',
  paddingBottom: '$md',
  '@screen < $sm': {
    height: '263px',
    paddingTop: '$md',
    paddingLeft: '$lg',
    alignContent: 'flex-start',
    flexDirection: 'row-reverse',
  },
});

export const IconContainer = styled('div', {
  height: '208px',
  width: '287px',
  overflow: 'hidden',
  position: 'relative',
  '@screen < $sm': {
    height: '176px',
    width: '129px',
  },
});

export const PageContent = styled(Flex, {
  alignContent: 'center',
  alignItems: 'center',
  flex: 1,
  flexDirection: 'column',
  '@screen < $sm': {
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
});

export const PuzzleIcon = styled(Icon, {
  position: 'absolute',
  top: '-81px',
  height: '287px! important',
  '@screen < $sm': {
    right: '-46px',
    top: 'unset',
    width: '175px ! important',
    height: '176px! important',
  },
});
