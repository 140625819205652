import { styled } from '@abyss/web/tools/styled';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';

export const TabContainer = styled('div', {
  color: '$primary1',
  overflow: 'auto hidden',
});

export const PremiumTextIconStyle = {
  '&.abyss-icon-material': {
    marginRight: '12px',
    '@screen < $md': {
      marginRight: '$sm',
    },
  },
};

export const StackDiv = styled('div', {
  borderBottom: '1px solid $cardBorder',
  borderTop: '1px solid $cardBorder',
  marginBottom: '8px',

  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
});

export const PremiumCareSection = styled(Layout.Group, {
  padding: '0 0 8px 0',
  '@screen < $sm': {
    paddingBottom: '4px',
    height: '20px',
  },
});

export const QualityGrid = styled(Grid, {
  '@screen < $md': {
    '&.abyss-grid': {
      '.abyss-grid-col': {
        borderBottom: '1px solid $cardBorder',
        borderTop: '1px solid $cardBorder',
        marginBottom: '8px',
        marginLeft: '-16px',
        padding: '16px 16px',
      },

      '.abyss-grid-col:first-of-type': {
        borderTop: 'none',
        paddingTop: '0',
      },

      '.abyss-grid-col:last-of-type': {
        borderBottom: 'none',
        marginBottom: '0',
        paddingBottom: '0',
      },
    },
  },
});

export const QualityText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '500',

    '@screen < $md': {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: '500',
    },
  },
});

export const PopoverContentContainer = styled('div', {
  '.abyss-icon-material': {
    width: '24px',
    height: '24px',
    marginLeft: '8px',
  },
});
export const ContentText = styled('div', {
  paddingBottom: '8px',
  '@screen < $sm': {
    paddingBottom: '16px',
  },
});

export const PopoverContainer = styled(Popover, {
  '&.abyss-popover-trigger': {
    margin: '6px 0 0 4px',
  },
  '&.abyss-popover-trigger-icon': {
    width: '20px',
    height: '20px',
  },
  '.abyss-popover-close-icon': {
    '@screen < $sm': {
      margin: '8px 12px 0 0 ',
      width: '24px',
      height: '24px',
    },
  },
  '&.abyss-popover-title': {
    fontSize: '14px',
    color: '#323334',
    fontWeight: '600',
    '@screen < $sm': {
      fontSize: '20.25px !important',
      fontWeight: '700 !important',
      lineHeight: '24px !important',
    },
  },
  '&.abyss-popover-content': {
    padding: '20px 24px 20px 16px',
    '@screen < $sm': {
      padding: '16px 20px',
    },
  },
  '.abyss-popover-content-container': {
    overflow: 'hidden',
  },
  '.abyss-popover-header': {
    '@screen < $sm': {
      marginBottom: '16px',
      width: '279px',
    },
  },
});
