import { Modal } from '@abyss/web/ui/Modal';
import { useEffect } from 'react';

import { Provider } from '../../models/Provider';
import { getSubText } from '../../utils/providerDetails.utils';
import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import { InfoProviderContent } from '../ProviderDetailTabs/ProviderLocationsDetails/InfoProviderContent';
import { InfoProviderContentContainer } from '../ProviderDetailTabs/ProviderLocationsDetails/ProviderLocationsDetails.style';

type Props = {
  provider: Provider;
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
};

export const BestMatchDetails = ({
  provider = {},
  isOpen,
  setIsOpen,
}: Props) => {
  const {
    isTieredProvider,
    acoProvider: isACOProvider,
    healthGradeRating: consumerRating,
    superPremium: costEfficiencyRating,
    lastName,
    isPremiumCarePhysician,
    isPlatinumProvider,
  } = provider;

  useEffect(() => {
    if (isOpen) adobeModalTrackEvent('bestmatch details');
  });

  return (
    <Modal
      css={{
        'abyss-modal-header-title': {
          color: '$gray7',
        },
      }}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Best Match Details"
    >
      <Modal.Section
        css={{
          'abyss-modal-section-root': {
            paddingBottom: '32px',
          },
        }}
      >
        <div style={{ overflow: 'auto hidden' }}>
          <InfoProviderContentContainer>
            <InfoProviderContent
              icon="group"
              infoSubtext={getSubText(isTieredProvider)}
              infoTitle="Tiered Providers"
            />
            <InfoProviderContent
              icon="group"
              infoSubtext={getSubText(isACOProvider)}
              infoTitle="ACO Providers"
            />
            <InfoProviderContent
              icon="group"
              infoSubtext={getSubText(isPremiumCarePhysician)}
              infoTitle="UHPD Providers"
            />
            <InfoProviderContent
              icon="group"
              infoSubtext={getSubText(isPlatinumProvider)}
              infoTitle="Platinum Providers"
            />
            <InfoProviderContent
              icon="group"
              infoSubtext={costEfficiencyRating}
              infoTitle="Cost Efficiency Rating"
            />
            <InfoProviderContent
              icon="group"
              infoSubtext={consumerRating}
              infoTitle="Consumer Rating"
            />
            <InfoProviderContent
              icon="group"
              infoSubtext={lastName}
              infoTitle="A-Z Name sort"
            />
          </InfoProviderContentContainer>
        </div>
      </Modal.Section>
    </Modal>
  );
};
