import { Flex } from '@abyss/web/ui/Flex';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { ProviderLocation } from '../../models/ProviderDetails';
import { SuggestEditChooseLocationContainer } from './SuggestEditDrawerCard.styled';

export type Props = {
  providerLocations: ProviderLocation[];
  setRadioValue: (a: string) => void;
  radioValue: string;
};

export const SuggestEditChooseLocation = ({
  providerLocations,
  setRadioValue,
  radioValue,
}: Props) => {
  const { t } = useTranslation();
  const getLabel = (item, index) =>
    `Clinic name ${index + 1},${item.address.line[0]} (${item?.distance} ${t(
      'FACILITY_DETAILS.MILES'
    )})`;
  const locationsList = providerLocations.map((item, index) => ({
    label: getLabel(item, index),
    value: item.locationId,
  }));

  return (
    <SuggestEditChooseLocationContainer>
      <RadioGroup
        css={{
          'abyss-radio-group-label': {
            fontSize: '25.63px',
            fontWeight: '$bold',
            margin: '24px 0 0 0',
          },
        }}
        data-auto-testid="choose-locations-radio"
        data-testid="choose-locations-radio"
        onChange={(e) => setRadioValue(e.target.value)}
        value={radioValue}
      >
        {locationsList.map((item) => (
          <RadioGroup.Radio
            key={item}
            css={{
              'abyss-radio-label': {
                fontWeight: radioValue === item.label ? '$bold' : '$medium',
                fontSize: '16px',
                color: radioValue === item.label ? '#323334' : '#4B4D4F',
              },
              '.abyss-radio-group-container': {
                paddingBottom: '10px',
              },
            }}
            data-auto-testid={item}
            data-testid={item}
            label={
              <Flex
                css={{
                  '.abyss-text-root': {
                    color: radioValue === item.label ? '#323334' : '#4B4D4F',
                  },
                }}
                direction="column"
              >
                <Text>{item.label.split(',')[1]}</Text>
                <Text>{item.label.split(',').slice(2).join(',')}</Text>
              </Flex>
            }
            value={item.label}
          />
        ))}
      </RadioGroup>
    </SuggestEditChooseLocationContainer>
  );
};
