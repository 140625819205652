import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';

export const SubSuccessProviderSpeciality = styled('div', {
  color: '#4B4D4F',
  fontSize: '14.22px',
  '@screen < $md': {
    fontSize: '12.64px',
  },
});

export const SuccessPageYourPCPHeader = styled(Flex, {
  alignContent: 'flex-start',
  alignItems: 'center',
  direction: 'row',
  '@screen < $sm': {
    marginTop: '24px',
  },
});

export const SuccessPageYourPCPLocationHeader = styled('div', {
  marginTop: '20px',
  '@screen < $sm': {
    marginTop: '32px',
  },
});

export const SuccessPageBackButton = styled(Button, {
  '&.abyss-button-root': {
    width: 'auto',
    height: '42px',
    padding: '0 16px 0 24px',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '$semibold',
  },
});

export const SuccessMessageHeading = styled('div', {
  marginLeft: '12px',
  h1: {
    color: '$primary1',
    margin: '0px',
    lineHeight: '44px',
    '@screen < $sm': {
      fontSize: '28.83px',
      lineHeight: '36px',
      width: '299px',
      marginBottom: '8px',
      maxHeight: '72px',
    },
  },
  span: {
    color: '$primary1',
    fontSize: '18px',
    fontWeight: '$medium',
    lineHeight: '24px',
    '@screen < $sm': {
      fontSize: '$md',
      lineHeight: '20px',
    },
  },
});

export const SuccessPageWrapColumn = styled('div', {
  height: '632px',
  position: 'absolute',
  display: 'inline-block',
  overflow: 'visible',
  backgroundColor: '$primary2',
  zIndex: 10,
  boxShadow: '10px 0px 5px -5px rgba(0, 0, 0, 0.2)',
  paddingRight: '$lg',
  width: '432px',
  '@screen >= $sm': { maxWidth: '432px' },
  '@screen < $sm': {
    width: '100%',
    height: '532px',
    boxShadow: 'unset',
    padding: '0',
  },
});

export const SuccessMapViewWrap = styled('div', {
  dynamic: ({ cssProps: { closeSidePanel } }) => ({
    '.abyss-card-root': {
      width: closeSidePanel ? '100%' : 'calc(100% - 400px)',
      marginLeft: closeSidePanel ? '0' : '400px',
    },
  }),
  '.abyss-card-root': {
    height: '632px',
    width: '100%',
    border: 'none',
    boxShadow: 'unset',
    '.map-container': {
      height: '632px',
      width: '100%',
    },
  },
  '@screen < $sm': {
    '.abyss-card-root ': {
      height: '532px',
      width: '100vw',
      marginLeft: '0 !important',
      '.map-container': {
        height: '532px',
        width: '100vw',
      },
    },
  },
  '.mapboxgl-ctrl-bottom-left': {
    display: 'none',
  },
  '.mapboxgl-ctrl-bottom-right': {
    display: 'none',
  },
});

export const ChooseLocationCardsSuccessContainer = styled('div', {
  maxHeight: '632px',
  maxWidth: '432px',
  gap: '12px',
  '.location-cards-wrapper': {
    width: '100%',
  },
  '@screen < $sm': {
    marginRight: 0,
    maxHeight: '500px',
    '&.abyss-box-root': {
      display: 'flex',
    },
  },
});

export const PCPHeaderContainer = styled('div', {
  backgroundColor: '#FBFCFE',
  borderBottom: 'solid #E5E5E6',
});

export const SuccessSelectedPCPBox = styled('div', {
  '&.abyss-box-root': {
    padding: '0',
    '@screen < $sm': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxHeight: '132px',
      minHeight: '132px',
    },
  },
});

export const LocationsDropdown = styled('div', {
  height: '64px',
  border: '1px solid $neutralGray4',
  boxSizing: 'border-box',
  alignItems: 'center',
  padding: '0px 0px 0px 8px',
  background: '$white',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  borderRadius: '12px',
  '@screen < $sm': {
    width: '100%',
  },
  borderColor: '$success1',
});

export const ClinicNameAddress = styled('div', {
  border: '1px solid $neutralGray4',
  boxSizing: 'border-box',
  alignItems: 'center',
  padding: '8px 16px 8px 16px',
  background: '$white',
  borderRadius: '12px',
  '@screen < $sm': {
    padding: '$sm 0 $sm $md',
    width: '100%',
  },
  borderColor: '$success1',
});

export const ClinicSubAddressSuccessPage = styled('div', {
  color: '#4B4D4F',
  lineHeight: '20px',
});

export const ContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  '@screen < $sm': {
    alignItems: 'flex-start',
  },
});

export const SuccessCheckMark = styled('div', {
  display: 'flex',
  '@screen < $sm': {
    marginTop: '0px',
  },
});

export const TextContainer = styled('div', {
  maxWidth: '300px',
});
