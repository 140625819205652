import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import copy from 'copy-to-clipboard';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { APIConstants } from '../../../../../../../../api/src/services/Constants';
import { adobeLinkTrackEvent } from '../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../../../common/Constants';
import { phoneOnly } from '../../../../../../common/ConstantsStyles';
import { LinkWithTracking } from '../../../../../../common/LinkWithTracking/LinkWithTracking';
import { getGeoLocationFromStorage } from '../../../../../../common/PSXHeader/SearchBar/utils';
import { getDistance } from '../../../../../../common/Utils/dataCardUtils';
import { getCurrentDateWithDashFormatting } from '../../../../../../common/Utils/datesUtils';
import { useSendEmail } from '../../../../../../hooks/useSendEmail';
import { useShareAllResults } from '../../../../../../hooks/useShareAllResults/useShareAllResults';
import { EducationInformation } from '../../../../../../models/Provider';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../../../../../utils/user.utils';
import { isDistanceAttributePresent } from '../../CompareDrawer/Components/CompareCard/CompareLocationDetails/MileageText';
import {
  ATTRIBUTE_STATUS_TEXT,
  CompareCheckboxInfo,
  NULL_ATTRIBUTE_STATE,
} from '../../CompareDrawer/utility/compareDrawerConstants';
import {
  getDegreesList,
  getSchoolsList,
} from '../../CompareDrawer/utility/getEducationText';
import { getHoursOfOperaion } from '../../CompareDrawer/utility/getHoursOfOperation';
import {
  adobeShareProviders,
  getNetworkStatus,
  getProviderGender,
  getProviderLanguages,
  getProviderSpecialty,
  getStatusText,
} from '../utils';
import { ShareDrawerButtons } from './ShareDrawerButtons';
import {
  CopyLabelLink,
  InputBoxCss,
  MessageLabel,
  MessageLabelLink,
  ShareContainerInner,
  StyledDiv,
} from './ShareDrawerContents.styled';
import { ShareDrawerEmailInputs } from './ShareDrawerEmailInputs';

type Props = {
  compareCheckboxes: CompareCheckboxInfo[];
  openShareContents: boolean;
  setOpenShareContents: (a: boolean) => void;
  showSuccessAlertCallback: () => void;
  isShareAllResults: boolean;
  providerType: string;
  searchKeyword: string;
  specialtyCode: string;
  selectedProviderList: {
    providerName: string;
    providerId: string;
    speciality: string;
    networkStatus: string;
    languagesSpoken: string[];
    acceptingNewPatients: boolean;
    gender: string;
    virtualCareOffered: boolean;
    distance: string;
    isTieredProvider: boolean;
    educationInfo: EducationInformation;
    hoursOfOperation: {};
    premiumCareProvider: boolean;
    hasPCPServiceAvailability: boolean;
    address: {
      line: string[];
      city: string;
      district: string;
      state: string;
      postalCode: string;
    };
    phones: {
      phone: string[];
    };
  }[];
  isOriginCompareProviders: boolean;
};

export const ShareContentsInnerDrawer = ({
  compareCheckboxes,
  openShareContents,
  setOpenShareContents,
  showSuccessAlertCallback,
  isShareAllResults,
  providerType,
  searchKeyword,
  specialtyCode,
  selectedProviderList,
  isOriginCompareProviders,
}: Props) => {
  const { t } = useTranslation();
  const form = useForm();
  const mobile = useMediaQuery(phoneOnly);
  const [emailOne, setEmailOne] = useState('');
  const [emailTwo, setEmailTwo] = useState('');
  const [emailThree, setEmailThree] = useState('');
  const [emailFour, setEmailFour] = useState('');
  const [emailFive, setEmailFive] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { coverageType } = tokenData;

  const formatEmails = () => {
    const emails = [emailOne];
    if (emailTwo) emails.push(emailTwo);
    if (emailThree) emails.push(emailThree);
    if (emailFour) emails.push(emailFour);
    if (emailFive) emails.push(emailFive);
    return emails;
  };

  const { longitude, latitude } = getGeoLocationFromStorage();
  const currentMember = getCurrentMember();
  const planVariationCodeForMedical = getPlanVariationCode(currentMember, 'M');
  const [, sendEmail] = useSendEmail({});
  const [, shareAll] = useShareAllResults({});

  const subjectText = () =>
    // eslint-disable-next-line no-nested-ternary
    providerType?.toLowerCase() ===
    APIConstants.PROVIDER_TYPES.practitioner.toLowerCase()
      ? t('SHARE_PROVIDERS.PROVIDER')
      : providerType?.toLowerCase() ===
        APIConstants.PROVIDER_TYPES.organization.toLowerCase()
      ? t('SHARE_PROVIDERS.FACILITY')
      : t('SHARE_PROVIDERS.PROVIDER_GROUP');

  const subjectTextForCompare = () => t('SHARE_PROVIDERS.COMPARE_PROVIDERS');
  const shareTitle = `${subjectText()} ${searchKeyword} ${t(
    'SHARE_PROVIDERS.SEARCH_ON'
  )} ${getCurrentDateWithDashFormatting()}`;

  const shareTitleForCompare = `${subjectTextForCompare()} ${getCurrentDateWithDashFormatting()}`;

  const getIsChecked = (editCheckItems: string, providerInformation: string) =>
    compareCheckboxes.find((item) => item.name === editCheckItems)?.isChecked
      ? `${providerInformation}`
      : '';

  const getEmailBodyText = () => {
    let emailBodyText = `${shareTitle}\n`;
    if (isOriginCompareProviders) {
      emailBodyText = `${shareTitleForCompare}\n`;
    }
    selectedProviderList.forEach((provider) => {
      const phone: string = isEmpty(provider.phones?.phone[0])
        ? NULL_ATTRIBUTE_STATE.PHONE_NUMBER
        : provider.phones?.phone[0];
      const networkStatus: string = getNetworkStatus(provider.networkStatus);
      const gender: string = getProviderGender(provider?.gender);
      const formattedAddress: string = `${provider?.address?.line[0]}, ${provider?.address?.city}, ${provider?.address?.state}`;
      const address: string = isEmpty(provider?.address?.line[0])
        ? NULL_ATTRIBUTE_STATE.ADDRESS
        : formattedAddress;

      const distance: string = isDistanceAttributePresent(provider.distance)
        ? `${getDistance(provider.distance)} miles away`
        : NULL_ATTRIBUTE_STATE.DISTANCE;

      const tierOneProvider: string = getStatusText(
        provider.isTieredProvider,
        ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.TRUE,
        ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.FALSE,
        NULL_ATTRIBUTE_STATE.TIER_ONE_PROVIDER
      );
      const educationList = getSchoolsList(provider.educationInfo);
      const degreeList = getDegreesList(provider.educationInfo);
      const speciality: string = getProviderSpecialty(provider.speciality);
      const languages = getProviderLanguages(provider?.languagesSpoken);
      const acceptingPatient: string = getStatusText(
        provider.acceptingNewPatients,
        ATTRIBUTE_STATUS_TEXT.ACCEPTING_NEW_PATIENTS.TRUE,
        ATTRIBUTE_STATUS_TEXT.ACCEPTING_NEW_PATIENTS.FALSE,
        NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS
      );

      const virtualCare: string = getStatusText(
        provider.virtualCareOffered,
        ATTRIBUTE_STATUS_TEXT.VIRTUAL_CARE_OFFERED.TRUE,
        ATTRIBUTE_STATUS_TEXT.VIRTUAL_CARE_OFFERED.FALSE,
        NULL_ATTRIBUTE_STATE.VIRTUAL_CARE_OFFERED
      );

      const { daysWithTime, possibleHours } = getHoursOfOperaion(
        provider.hoursOfOperation
      );
      const hoursOfOperation = isEmpty(provider.hoursOfOperation)
        ? NULL_ATTRIBUTE_STATE.HOURS_OF_OPERATION
        : `${daysWithTime} : ${possibleHours}`;

      const premiumCareProvider = getStatusText(
        provider.premiumCareProvider,
        ATTRIBUTE_STATUS_TEXT.PREMIUM_CARE_PROVIDER.TRUE,
        ATTRIBUTE_STATUS_TEXT.PREMIUM_CARE_PROVIDER.FALSE,
        NULL_ATTRIBUTE_STATE.PREMIUM_CARE_PROVIDER
      );

      const hasPCPServiceAvailability = getStatusText(
        provider.hasPCPServiceAvailability,
        ATTRIBUTE_STATUS_TEXT.PCP_SERVICES_AVAILABILE.TRUE,
        ATTRIBUTE_STATUS_TEXT.PCP_SERVICES_AVAILABILE.FALSE,
        NULL_ATTRIBUTE_STATE.PCP_SERVICES_AVAILABILE
      );

      emailBodyText += `\n${
        provider.providerName
      }\n${phone}\n${networkStatus}${getIsChecked(
        'specialties',
        `\n${speciality}`
      )}${getIsChecked('reviews', `\n${'Rating - 4'}`)}${getIsChecked(
        'distance',
        `\n${address}\n${distance}`
      )}${getIsChecked(
        'tierOneProvider',
        `\n${tierOneProvider}`
      )}${getIsChecked('education', `\n${educationList}`)}${getIsChecked(
        'education',
        `\n${degreeList}`
      )}${getIsChecked('gender', `\n${gender}`)}${getIsChecked(
        'acceptingNewPatients',
        `\n${acceptingPatient}`
      )}${getIsChecked('languagesSpoken', `\n${languages}`)}${getIsChecked(
        'virtualCareOffered',
        `\n${virtualCare}`
      )}${getIsChecked(
        'hoursOfOperation',
        `\n${hoursOfOperation}`
      )}${getIsChecked(
        'premiumCareProvider',
        `\n${premiumCareProvider}`
      )}${getIsChecked(
        'hasPCPServiceAvailability',
        `\n${hasPCPServiceAvailability}`
      )}
      `;
    });

    emailBodyText += `\n[${window.location.href}]`;
    return emailBodyText;
  };

  const getEmailBodyHTML = () => {
    let emailBodyHtml = `<div><div><b>
    ${isOriginCompareProviders ? shareTitleForCompare : shareTitle}</b>
    </div>`;
    selectedProviderList.forEach((provider) => {
      const phone: string = isEmpty(provider.phones?.phone[0])
        ? NULL_ATTRIBUTE_STATE.PHONE_NUMBER
        : provider.phones?.phone[0];
      const gender: string = getProviderGender(provider.gender);
      const address: string = `${provider?.address?.line[0]}, ${provider?.address?.city}, ${provider?.address?.state}`;
      const speciality: string = getProviderSpecialty(provider.speciality);
      const languages = getProviderLanguages(provider?.languagesSpoken);
      const acceptingPatient: string = getStatusText(
        provider.acceptingNewPatients,
        'Accepting new patients',
        'Not accepting new patients',
        NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS
      );
      const virtualCare: string = getStatusText(
        provider.virtualCareOffered,
        'Virtual care offered',
        'Virtual care not offered',
        NULL_ATTRIBUTE_STATE.VIRTUAL_CARE_OFFERED
      );
      const networkStatus: string = getNetworkStatus(provider.networkStatus);

      emailBodyHtml += `<div style="margin-top:15px"><b>${
        provider.providerName
      }</b></div>
      <div>${phone}</div>
      <div>${networkStatus}</div>
      ${getIsChecked('specialties', `<div>${speciality}</div>`)}
      ${getIsChecked('reviews', `<div>${'Rating - 4'}</div>`)}
      ${getIsChecked(
        'distance',
        `<div>${address}\n${getDistance(
          provider.distance
        )} ${'miles away'}</div>`
      )}
      ${getIsChecked('gender', `<div>${gender}</div>`)}
      ${getIsChecked('languagesSpoken', `<div>${languages}</div>`)}
      ${getIsChecked('acceptingNewPatients', ` <div>${acceptingPatient}</div>`)}
      ${getIsChecked('virtualCareOffered', `<div>${virtualCare}</div>`)}
      ${
        // Cost to be included near future
        getIsChecked('hoursOfOperation', `<div>${'TBD'}</div>`)
      }
  <div style="margin-top:10px"></div>
  </div>`;
    });
    emailBodyHtml += `<div>[${window.location.href}]</div>`;
    return emailBodyHtml;
  };

  const handleSendEmailDesktop = async () => {
    if (isShareAllResults) {
      await shareAll({
        variables: {
          latitude,
          longitude,
          providerType,
          lob: currentMember?.lineOfBusiness,
          coverages: getCoverageTypes(currentMember),
          planYear: getCurrentPlanYear(),
          planVariationCode: planVariationCodeForMedical,
          policyID: currentMember?.policyNumber,
          reciprocityId: getNetworkIdsForPES(currentMember, coverageType),
          sendEmail: true,
          linkToSearchResultsPage: window.location.href,
          shareTitle,
          emailAddresses: formatEmails(),
          searchKeyword,
          includeSpecialityRollupCodes: [specialtyCode],
        },
      });
    } else {
      await sendEmail({
        variables: {
          recipientEmails: formatEmails(),
          subject: shareTitle,
          text: '',
          htmlText: getEmailBodyHTML(),
        },
      });
    }
  };

  const onSubmit = () => {
    showSuccessAlertCallback();
  };

  return (
    <React.Fragment>
      <FormProvider
        data-auto-testid="share-email-form"
        data-testid="share-email-form"
        onSubmit={onSubmit}
        state={form}
      >
        <ShareContainerInner>
          <Box
            css={{
              'abyss-box-root': {
                backgroundColor: 'transparent',
                padding: mobile ? '5px 0px 5px 0px' : '0px',
              },
            }}
          >
            <Flex alignItems="left" justify="space-between">
              <Heading
                color="$gray9"
                data-auto-testid="recipient-heading"
                data-testid="recipient-heading"
                offset={6}
              >
                {t('Recipient (add up to 5)')}
              </Heading>
              <IconMaterial
                data-auto-testid="button-share-inner-drawer-close"
                data-testid="button-share-inner-drawer-close"
                icon="close"
                onClick={() => {
                  adobeLinkTrackEvent({
                    name: 'close',
                    location: `modal:${adobeShareProviders}`,
                    type: 'internal',
                  });
                  setOpenShareContents(!openShareContents);
                }}
                size="$md"
              />
            </Flex>
          </Box>
        </ShareContainerInner>

        {mobile ? (
          <Layout.Stack
            css={{ marginTop: '12px', width: '100%' }}
            grow
            justifyContent="start"
          >
            <StyledDiv>
              <ShareDrawerEmailInputs
                emailFive={emailFive}
                emailFour={emailFour}
                emailOne={emailOne}
                emailThree={emailThree}
                emailTwo={emailTwo}
                setEmailFive={setEmailFive}
                setEmailFour={setEmailFour}
                setEmailOne={setEmailOne}
                setEmailThree={setEmailThree}
                setEmailTwo={setEmailTwo}
              />
              <ShareDrawerButtons
                handleSendEmailDesktop={handleSendEmailDesktop}
                openShareContents={openShareContents}
                setOpenShareContents={setOpenShareContents}
              />
            </StyledDiv>
          </Layout.Stack>
        ) : (
          <Layout.Group
            alignItems="start"
            css={{ marginTop: '12px', width: '100%' }}
            grow
          >
            <StyledDiv>
              <ShareDrawerEmailInputs
                emailFive={emailFive}
                emailFour={emailFour}
                emailOne={emailOne}
                emailThree={emailThree}
                emailTwo={emailTwo}
                setEmailFive={setEmailFive}
                setEmailFour={setEmailFour}
                setEmailOne={setEmailOne}
                setEmailThree={setEmailThree}
                setEmailTwo={setEmailTwo}
              />
              <ShareDrawerButtons
                handleSendEmailDesktop={handleSendEmailDesktop}
                openShareContents={openShareContents}
                setOpenShareContents={setOpenShareContents}
              />
            </StyledDiv>
            <StyledDiv>
              <TextInputArea
                css={InputBoxCss}
                data-auto-testid="message-text"
                data-testid="message-text"
                disableAutoSize
                label={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginBottom: '10px',
                    }}
                  >
                    <Text
                      css={MessageLabel}
                      data-auto-testid="message-heading"
                      data-testid="message-heading"
                    >
                      {t('SHARE_INNER_DRAWER_TEXT.MESSAGE')}
                    </Text>
                    {isCopied ? (
                      <Flex direction="row">
                        <IconMaterial
                          color="$success1"
                          icon="check_circle"
                          size="15px"
                        />
                        <Text css={CopyLabelLink}>
                          {t('SHARE_INNER_DRAWER_TEXT.COPIED')}
                        </Text>
                      </Flex>
                    ) : (
                      <LinkWithTracking
                        analyticsInfo={{
                          name: 'copy text',
                          location: `modal:${adobeShareProviders}`,
                          type: 'internal',
                        }}
                        before={
                          <IconMaterial icon="content_copy" size="18px" />
                        }
                        css={MessageLabelLink}
                        data-auto-testid="share-inner-element-text"
                        data-testid="share-inner-element-text"
                        onClick={() => {
                          copy(getEmailBodyHTML(), { format: 'text/html' });
                          setIsCopied(true);
                        }}
                      >
                        {t('SHARE_INNER_DRAWER_TEXT.COPY_TEXT')}
                      </LinkWithTracking>
                    )}
                  </div>
                }
                value={getEmailBodyText()}
              />
            </StyledDiv>
          </Layout.Group>
        )}
      </FormProvider>
    </React.Fragment>
  );
};
