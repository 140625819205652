import { storage } from '@abyss/web/tools/storage';

import { Constants } from '../../Constants';

const { GEO_LOCATION } = Constants.STORAGE_KEYS.SESSION;

export const getGeoLocationFromStorage = () =>
  storage.local.get(GEO_LOCATION) || {};

export const setGeoLocationToStorage = (value) => {
  storage.local.set(GEO_LOCATION, value);
  window.dispatchEvent(new Event('storage'));
};
