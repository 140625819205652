import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { useTranslation } from 'react-i18next';

import { useCoverageType } from '../../../../hooks/useCoverageType';
import { capitalizeFirstLetters } from '../../../../utils/providerDetails.utils';
import { ReverseCoverageTypesCodes } from '../../../Constants';
import { h2SmallerFontForResponsive } from '../../../ConstantsStyles';
import { ContentWrapper } from '../../../ContentWrapper';
import { ContainerForAllSectionsStyled } from '../../ProviderDetailTabs.styled';

const Container = styled('div', {
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  '@screen <= $md': {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'flex-start',
    gap: '12px',
    width: '100%',
    height: '180px',
    flex: 'none',
    order: '2',
    alignSelf: 'stretch',
    flexGrow: '0',
  },
});

const TextContainer = styled('div', {
  maxWidth: '839px',
  fontWeight: '$medium',
  fontSize: '$lg',
  lineHeight: '24px',
  color: '#4B4D4F ',
  '@screen < $sm': {
    fontSize: '$md',
    fontWeight: '500',
    lineHeight: '20px',
  },
});

const Gender = styled('div', {
  height: '20px',
  fontWeight: '$medium',
  fontSize: '$md',
  lineHeight: '20px',
  color: '#4B4D4F',
  '@screen < $sm': {
    fontSize: '14.22px',
  },
});

const Dot = styled('div', {
  width: '4px',
  height: '4px',
  background: '#D9D9D9',
  margin: '$sm $xs $xs $xs',
  borderRadius: '50%',
  color: '#4B4D4F',
});

const DefaultStyled = styled('div', {
  width: 'auto',
  fontWeight: '$medium',
  fontSize: '$md',
  lineHeight: '20px',
  color: '#4B4D4F',
  '@screen < $sm': {
    fontSize: '14.22px',
  },
});

type Props = {
  heading: string;
  content?: string;
  gender: string;
  language: string[] | null;
  ethnicity?: string;
};
export const BiographySection = ({
  heading,
  content,
  gender,
  language,
  ethnicity,
}: Props) => {
  const { t } = useTranslation();
  const coverageType = useCoverageType();

  return (
    <ContainerForAllSectionsStyled>
      <ContentWrapper>
        <Container
          data-auto-testid="about-tab-biography-section-container"
          data-testid="about-tab-biography-section-container"
        >
          <Heading
            css={h2SmallerFontForResponsive}
            data-auto-testid="biography-heading"
            data-testid="biography-heading"
            display="h4"
            offset={1}
          >
            {t(heading)}
          </Heading>
          <TextContainer
            data-auto-testid="biography-text"
            data-testid="biography-text"
          >
            {content || t('BIOGRAPHY_SECTION.BIOGRAPHY_NOT_AVAILABLE')}
          </TextContainer>
          <Flex css={{ flexWrap: 'inherit' }} justify="space-evenly">
            <Gender
              data-auto-testid="biography-gender"
              data-testid="biography-gender"
            >
              {gender.toLowerCase() === 'male'
                ? capitalizeFirstLetters(t('BIOGRAPHY_SECTION.GENDER.MALE'))
                : capitalizeFirstLetters(t('BIOGRAPHY_SECTION.GENDER.FEMALE'))}
            </Gender>
            <Dot />
            {language && (
              <DefaultStyled
                data-auto-testid="biography-language"
                data-testid="biography-language"
              >
                {capitalizeFirstLetters(language?.join(', '))}
              </DefaultStyled>
            )}
          </Flex>
          {coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
          ethnicity ? (
            <DefaultStyled
              data-auto-testid="ethnicity-text"
              data-testid="ethnicity-text"
            >
              {capitalizeFirstLetters(ethnicity)}
            </DefaultStyled>
          ) : null}
        </Container>
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
