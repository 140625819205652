import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';

import { ParagraphSkeleton } from './ParagraphSkeleton';

interface ILoadableTextRowSkeletonProps {
  isLoading?: boolean;
  skeletonWidth: number;
  text?: string | null;
  size: `$${'xs' | 'sm' | 'md' | 'lg'}`;
  textColor?: string;
  fontWeight?: string | number;
}

export const LoadableTextRowSkeleton = ({
  isLoading,
  skeletonWidth,
  text = '',
  size,
  textColor,
  fontWeight,
}: ILoadableTextRowSkeletonProps) => {
  const lineHeight = { $xs: 16, $sm: 16, $md: 20, $lg: 24 }[size];
  const fontSize = { $xs: 12.64, $sm: 14, $md: 16, $lg: 18 }[size];

  if (isLoading) {
    return (
      <Box color="" height={lineHeight} padding={0}>
        <ParagraphSkeleton
          fontSize={fontSize}
          paddingBottom={(lineHeight - fontSize) / 2}
          paddingTop={(lineHeight - fontSize) / 2}
          widths={[skeletonWidth]}
        />
      </Box>
    );
  }
  return (
    <Box color="" height={lineHeight} padding={0}>
      <Flex alignContent="center">
        <Text color={textColor} fontWeight={fontWeight} size={size}>
          {text}
        </Text>
      </Flex>
    </Box>
  );
};
