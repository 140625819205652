import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import React from 'react';
import { useTranslation } from 'react-i18next';

import translationsEnJson from '../../../../../public/locale/en/translation.json';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { ProviderSearchFilters } from '../../../../models/ProviderSearch';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants, FilterCollectionModel } from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';

const { PROVIDER, PROVIDER_GROUPS, FACILITY } = Constants.RESULT_SECTION;
const { MEDICAL, BEHAVIORAL_HEALTH } = Constants.COVERAGE_TYPES_CODES;

interface Props {
  sectionType: string;
  selectedFilters: ProviderSearchFilters;
  setSelectedFilters: (data: ProviderSearchFilters) => void;
}

export const AllFilterToggleSwitchGroup = ({
  sectionType,
  selectedFilters,
  setSelectedFilters,
}: Props) => {
  const { t } = useTranslation();
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token);
  const { chipValue } = tokenData || {};

  const [decemberFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED,
  ]);

  const coverageType =
    chipValue === Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH
      ? Constants.COVERAGE_TYPES_CODES.BEHAVIORAL_HEALTH
      : Constants.COVERAGE_TYPES_CODES.MEDICAL;

  const filters = [
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.EXPRESS_ACCESS_PROVIDER'),
      allFilterDrawerOrder: 5,
      model: FilterCollectionModel.EXPRESS_ACCESS_PROVIDER_INDICATOR,
      sections: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [PROVIDER, FACILITY, PROVIDER_GROUPS],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER
          .EXPRESS_ACCESS_PROVIDER,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.TIER_INDICATOR'),
      model: FilterCollectionModel.TIERED_PROVIDER,
      allFilterDrawerOrder: 1,
      sections: {
        [MEDICAL]: [PROVIDER, FACILITY, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.TIER_INDICATOR,
      show: decemberFlag,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.PREMIUM_CARE_PHYSICIAN'),
      model: FilterCollectionModel.PREMIUM_CARE_PHYSICIAN,
      allFilterDrawerOrder: 2,
      sections: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER
          .PREMIUM_CARE_PHYSICIAN,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.PLATINUM_PROVIDER'),
      model: FilterCollectionModel.PLATINUM_PROVIDER_INDICATOR,
      allFilterDrawerOrder: 4,
      sections: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.PLATINUM_PROVIDER,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.VIRTUAL_CARE'),
      model: FilterCollectionModel.VIRTUAL_CARE,
      allFilterDrawerOrder: 3,
      sections: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.VIRTUAL_CARE,
    },
  ];

  const generateSwitchLabel = (model: string, label: String) =>
    `${t(
      `CONTENT_FILTERS.ALL_FILTERS_DRAWER.${
        selectedFilters[model]?.value ? 'ON' : 'OFF'
      }`
    )} ${label}`;

  return (
    <React.Fragment>
      {filters
        .filter(
          ({ sections, show = true }) =>
            sections[coverageType]?.includes(sectionType) && show
        )
        .sort((a, b) => a.allFilterDrawerOrder - b.allFilterDrawerOrder)
        .map(({ model, label, dnt_label }) => (
          <ToggleSwitch
            key={label}
            data-auto-testid="switch"
            data-testid={`${model}_test`}
            isChecked={selectedFilters[model]?.value || false}
            label={generateSwitchLabel(model, label)}
            onChange={(e) => {
              adobeLinkTrackEvent({
                name: `${dnt_label}:${e.target.checked ? 'check' : 'uncheck'}`,
                location: `modal:all filters drawer`,
                type: 'checkbox',
              });
              setSelectedFilters({
                ...selectedFilters,
                [model]: {
                  label,
                  value: e.target.checked,
                  dnt_label,
                },
              });
            }}
            size="$sm"
          />
        ))}
    </React.Fragment>
  );
};
