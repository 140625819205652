import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../hooks/useLagoon';
import { useTypeAheadQuery } from '../../../hooks/useTypeAheadQuery';
import {
  getCoverageTypes,
  getCurrentMember,
  getMemberCoverage,
} from '../../../utils/user.utils';
import { adobeImpressionTrackEvent } from '../../AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  NULL_RESULTS,
  OPTUM_CORRELATION_ID_HEADER,
} from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { mobileOnly } from '../../ConstantsStyles';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CustomAttributesBlock,
  convertTypeaheadProviderIdAndType,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { Breadcrumb } from '../Breadcrumb';
import {
  handleSearchSelected,
  setTypeAheadResults,
} from '../SearchInputBox/TypeaheadSearch';
import { KeywordSearchDropdown } from './KeywordSearchDropdown';
import { LocationDropdown } from './LocationDropdown';
import { ChangeLocationForm } from './LocationDropdown/ChangeLocation';
import { ChangeLocationDrawer } from './LocationDropdown/LocationDropdown.styled';
import { LocationInput } from './LocationInput';
import {
  DarkeningOverlay,
  IconContainer,
  Input,
  InputBox,
  KeywordSearchInputContainer,
  KeywordSearchLabel,
  KeywordSearchStyledDiv,
  PositioningBox,
  SearchPlacement,
} from './SearchBar.styled';
import { getGeoLocationFromStorage, setGeoLocationToStorage } from './utils';

type Props = {
  choosePCPHeader?: boolean;
  memberLocation?: string;
  showSearchInputBackButton?: boolean;
  breadcrumbs?: any[];
};

export const SearchBar = ({
  choosePCPHeader,
  memberLocation = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.MEMBER_LOCATION
  ) ?? '',
  showSearchInputBackButton = false,
  breadcrumbs = [],
}: Props) => {
  let network: string;
  const language = getLanguage()?.code || 'en';

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const keywordSearchInputId = useId();
  const currentMember = getCurrentMember();
  const mobileScreen = useMediaQuery(mobileOnly);
  const data = useLagoon('ui-messaging')();
  const { getRouteParams, navigate } = useRouter();
  const llmSearch = useLagoon(Constants.LAGOON_TABLE.LLM_SEARCH)();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { search, searchTerm } = tokenData;
  const [isFocusedOnKeywordSearchInput, setIsFocusedOnKeywordSearchInput] =
    useState(false);
  const [isFocusedOnLocationSearch, setIsFocusedOnLocationSearch] =
    useState(false);
  const [isOpenMobileLocation, setIsOpenMobileLocation] = useState(false);
  const [keywordSearchTerm, setKeywordSearchterm] = useState(searchTerm || '');
  let searchText: string;
  const {
    name = '',
    latitude: geoLat,
    longitude: geoLong,
  } = getGeoLocationFromStorage();
  const [locationInputVal, setLocationInputVal] = useState(name);
  const [headers, setHeaders] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.TYPE_AHEAD_HEADERS,
    {}
  );
  const [searchResults, setSearchResults] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.TYPE_AHEAD_RESULTS,
    []
  );
  const [results, setResults] = useState<any>(searchResults);
  const [enter, setEnter] = useState(false);

  const [locationSuggestions, setLocationSuggestions] = useState<object[]>();
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [isKeywordSearchLoading, setIsKeywordSearchLoading] = useState(false);
  const [searchButtonResults, setSearchButtonResults] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.TYPE_AHEAD_SEARCH_RESULTS,
    []
  );
  const commonSearchesLagoon: () => any = useLagoon('common-searches');
  const commonSearchesData = commonSearchesLagoon();
  const memberCoverages = getCoverageTypes(currentMember);
  const dentalCoverage = memberCoverages?.find((cov) => cov === 'D');
  const visionCoverage = memberCoverages?.find((cov) => cov === 'V');

  const [captureResults] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.AUTOCOMPLETE_CAPTURE_RESULTS,
  ]);

  const searchInputOptionLocation = 'search-input-option-list';

  const [cursor, setCursor] = useState(-1);

  const searchLabelText = find(data, {
    key: ConstantsLagoon.SEARCH_LABEL,
  });

  const isShowingKeywordSearchDropdown = isFocusedOnKeywordSearchInput;
  const isShowingLocationDropdown = isFocusedOnLocationSearch && !mobileScreen;
  const setKeywordSearchInputFocused = () => {
    setIsFocusedOnKeywordSearchInput(true);
    setIsFocusedOnLocationSearch(false);
  };

  const setKeywordSearchInputBlur = () => {
    setIsFocusedOnKeywordSearchInput(false);
  };

  const setLocationInputBlur = () => {
    setIsFocusedOnLocationSearch(false);
  };

  const handleKeywordSearchInputFocus = () => {
    setKeywordSearchInputFocused();
    setCursor(-1);
    setEnter(false);
  };

  const handleCloseLocationDropdown = () => {
    setKeywordSearchInputBlur();
    setLocationInputBlur();
  };

  const handleCloseLocationDrawer = () => {
    setKeywordSearchInputBlur();
    setLocationInputBlur();
  };

  const setLocationInputFocused = () => {
    setIsFocusedOnLocationSearch(true);
    setIsFocusedOnKeywordSearchInput(false);
  };

  const handleLocationSearchInputFocus = () => {
    setLocationInputFocused();
  };

  const handleLocationSearchTextChange = (value: string) => {
    setCursor(-1);
    setIsFocusedOnLocationSearch(true);
    setLocationInputVal(value);
  };
  const [typeAheadData, GetSearchSuggestions] = useTypeAheadQuery({
    onCompleted: (response) => {
      const suggestions =
        response?.data?.typeAhead?.lang_provider?.langProvider;
      const providers = response?.data?.typeAhead?.practitioners_uhc?.provData;
      const facilities = response?.data?.typeAhead?.organizations_uhc?.orgData;
      setSearchButtonResults(suggestions?.concat(providers).concat(facilities));
      const parsedResults = setTypeAheadResults(
        suggestions,
        providers,
        facilities,
        network
      );

      setHeaders({
        correlationId: response.headers[OPTUM_CORRELATION_ID_HEADER],
      });
      setResults(parsedResults);
      setSearchResults(parsedResults);
      setIsKeywordSearchLoading(false);
      adobeImpressionTrackEvent({
        searchTerm: searchText,
        type: 'typeahead search',
        message:
          parsedResults.length === 0
            ? 'no results found for your search'
            : 'results found for your search',
        customAttributesBlock: {
          correlationId: response.headers[OPTUM_CORRELATION_ID_HEADER],
        },
      });
    },
    onError: () => {
      setResults(null);
      setIsKeywordSearchLoading(false);
    },
  });

  const handleKeywordSearchInputChange = useCallback(
    debounce(async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setKeywordSearchterm(value.trim());
      setEnter(false);
      searchText = value.trim();
      setCursor(-1);
      setResults([]);
      setSearchResults([]);
      if (value.replace(/\s/g, '').length > 1 && /[A-Za-z0-9]/.test(value)) {
        network = getMemberCoverage(currentMember);
        GetSearchSuggestions({
          variables: {
            query: encodeURIComponent(value.trim()),
            network,
            latitude: geoLat,
            longitude: geoLong,
            lang: language,
            captureResults,
          },
        });
      }
      setIsKeywordSearchLoading(false);
    }, 400),
    [geoLat, geoLong]
  );

  const isShowingLocationDrawer = useMemo(
    () => isFocusedOnLocationSearch && mobileScreen,
    [isFocusedOnLocationSearch, mobileScreen]
  );

  const getTypeaheadData = () =>
    cursor === -1 ? searchButtonResults?.[0] : searchButtonResults?.[cursor];

  const handleKeywordSuggestionKeyDown = (ev: {
    key: string;
    preventDefault: () => void;
  }) => {
    const minCursor = -1;
    const maxCursor = commonSearchesData.length - 1;
    let value: any;

    if (['ArrowUp', 'ArrowDown'].some((key) => key === ev.key)) {
      ev.preventDefault();
    }
    if (ev.key === 'Enter') {
      const analyticsLinkNameOnEnter = 'search enter';
      setEnter(true);
      let customAttributesBlock;
      if (keywordSearchTerm?.length > 1) {
        value = getTypeaheadData();
        customAttributesBlock = value
          ? ({
              correlationId: headers?.correlationId,
              ...convertTypeaheadProviderIdAndType(value),
            } as CustomAttributesBlock)
          : undefined;
        setIsFocusedOnKeywordSearchInput(false);
        handleSearchSelected(
          {
            ...value,
            searchTerm: keywordSearchTerm,
            resultType: value ? Constants.RESULT_SECTION.ALL : NULL_RESULTS,
            search: keywordSearchTerm,
            linkName: analyticsLinkNameOnEnter,
            searchMethod: 'typed',
          },
          token,
          navigate,
          llmSearch
        );
      }

      adobeLinkTrackEvent({
        name: analyticsLinkNameOnEnter,
        location: `body:${searchInputOptionLocation}`,
        type: 'internal',
        customAttributesBlock,
      });
    } else if (ev.key === 'ArrowUp' && cursor > minCursor) {
      setCursor((previous) => previous - 1);
    } else if (ev.key === 'ArrowDown' && cursor < maxCursor) {
      setCursor((previous) => previous + 1);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutsideInput = wrapperRef.current
        ? !wrapperRef.current.contains(event.target)
        : true;
      if (isOutsideInput && !isShowingLocationDrawer) {
        setKeywordSearchInputBlur();
        setLocationInputBlur();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleClickOutside);
    };
  }, [wrapperRef, isShowingLocationDrawer]);

  return (
    <React.Fragment>
      {mobileScreen && isShowingKeywordSearchDropdown && <DarkeningOverlay />}
      <PositioningBox ref={wrapperRef}>
        {mobileScreen && showSearchInputBackButton ? (
          <Breadcrumb breadcrumbs={breadcrumbs} />
        ) : null}
        <InputBox
          hasShadow={isShowingKeywordSearchDropdown}
          isSharpBottomRightCorner={isShowingLocationDropdown}
          mobileScreen={mobileScreen}
        >
          <SearchPlacement>
            <KeywordSearchInputContainer>
              <KeywordSearchLabel
                data-testid="provider-search-label"
                htmlFor={keywordSearchInputId}
              >
                {searchLabelText?.message ?? ''}
              </KeywordSearchLabel>
              <KeywordSearchStyledDiv>
                <IconContainer>
                  <IconMaterial
                    color={choosePCPHeader && mobileScreen && '$interactive1'}
                    icon="search"
                    size={mobileScreen ? 20 : 24}
                  />
                </IconContainer>

                <Input
                  key={search}
                  aria-activedescendant={
                    isFocusedOnKeywordSearchInput
                      ? 'primary-search-bar-result-suggestion'
                      : ''
                  }
                  aria-describedby="initInstr"
                  aria-haspopup="listbox"
                  autoComplete="off"
                  data-testid="primary-search-input"
                  defaultValue={search}
                  id={keywordSearchInputId}
                  onChange={(e) => {
                    if (keywordSearchTerm?.trim().length > 1) {
                      setIsKeywordSearchLoading(true);
                    }
                    handleKeywordSearchInputChange(e);
                  }}
                  onFocus={handleKeywordSearchInputFocus}
                  onKeyDown={handleKeywordSuggestionKeyDown}
                  placeholder={t('SEARCH_PLACEHOLDER')}
                  type="text"
                />
              </KeywordSearchStyledDiv>
            </KeywordSearchInputContainer>
          </SearchPlacement>

          {isShowingKeywordSearchDropdown && (
            <KeywordSearchDropdown
              activeSuggestion={cursor}
              dentalCoverage={dentalCoverage}
              enter={enter}
              headers={headers}
              isKeywordSearchLoading={isKeywordSearchLoading}
              isLoading={typeAheadData?.isLoading}
              keywordSearchTerm={keywordSearchTerm}
              setIsFocusedOnKeywordSearchInput={
                setIsFocusedOnKeywordSearchInput
              }
              typeAheadSuggestions={results}
              visionCoverage={visionCoverage}
            />
          )}
        </InputBox>

        {isShowingLocationDropdown && (
          <LocationDropdown
            activeSuggestion={cursor}
            data-testid="location-search-bar-dropdown"
            isLocationLoading={isLocationLoading}
            locationInputVal={locationInputVal}
            locationSuggestions={locationSuggestions}
            onClose={handleCloseLocationDropdown}
            setLocationInputVal={setLocationInputVal}
            setSelectedLocation={(targetLocation) => {
              const [selectedLong, selectedLat] = targetLocation.center || [];
              const placeName = targetLocation.place_name;
              setGeoLocationToStorage({
                name: placeName,
                longitude: selectedLong,
                latitude: selectedLat,
                stateCode: targetLocation.stateCode,
              });
            }}
          />
        )}

        <LocationInput
          cursor={cursor}
          handleCloseLocationDrawer={handleCloseLocationDrawer}
          handleCloseLocationDropdown={handleCloseLocationDropdown}
          headers={headers}
          isOpenMobileLocation={isOpenMobileLocation}
          isShowingLocationDrawer={isShowingLocationDrawer}
          isShowingLocationDropdown={isShowingLocationDropdown}
          llmSearch={llmSearch}
          locationValue={locationInputVal}
          memberLocation={memberLocation}
          onLocationSearchInputFocus={handleLocationSearchInputFocus}
          onLocationSearchInputTextChange={handleLocationSearchTextChange}
          placeHolderValue={name}
          searchTerm={keywordSearchTerm}
          setCursor={setCursor}
          setIsFocusedOnKeywordSearchInput={setIsFocusedOnKeywordSearchInput}
          setIsLocationLoading={setIsLocationLoading}
          setIsOpenMobileLocation={setIsOpenMobileLocation}
          setLocationInputVal={setLocationInputVal}
          setLocationSuggestions={setLocationSuggestions}
          typeaheadData={getTypeaheadData()}
        />

        <ChangeLocationDrawer
          isOpen={isShowingLocationDrawer}
          onClose={handleCloseLocationDrawer}
          position="bottom"
          size="$lg"
          title={t('Change Location')}
        >
          <ChangeLocationForm
            data-testid="change-location-form"
            onLocationSelect={handleCloseLocationDrawer}
            typeAheadSearch={{}}
          />
        </ChangeLocationDrawer>
      </PositioningBox>

      {mobileScreen && (
        <Layout.Group css={{ paddingBottom: '12px' }}>
          <Layout.Stack css={{ marginTop: '0.1em' }}>
            <IconMaterial
              color="#196ECF"
              css={{
                'abyss-icon-material': {
                  width: '24px',
                  height: '24px',
                },
              }}
              icon="location_on"
            />
          </Layout.Stack>
          <Layout.Stack css={{ color: '$primary2' }}>
            <LinkWithTracking
              analyticsInfo={{
                location: 'body:locations',
              }}
              css={{ color: '#196ECF', textDecoration: 'underline' }}
              data-auto-testid="user-location-current-location"
              data-testid="user-location-current-location"
              fontWeight="$bold"
              onClick={() => setIsOpenMobileLocation(true)}
              size="$sm"
              variant="native"
            >
              {locationInputVal}
            </LinkWithTracking>
          </Layout.Stack>
        </Layout.Group>
      )}
    </React.Fragment>
  );
};
