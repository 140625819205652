import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useParsedProviderFilters } from '../../hooks/useProviderFilterCount';
import { useProviderFilters } from '../../hooks/useProviderFilters';
import {
  LastModifiedFilter,
  ProviderSearchFilters,
} from '../../models/ProviderSearch';
import { transformFiltersData } from '../../utils/providerSearch.utils';
import { NameFilter } from '../ContentFilters/NameFilter';

type Props = {
  sectionType: string;
};

export const ChipFilters = ({ sectionType = '' }: Props) => {
  const { t } = useTranslation();
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token);
  const filtersCollection = useProviderFilters();

  const { searchFilters, setSearchFilters, defaultSearchFilters } =
    useContext(SearchFilterContext);
  const [selectedFilters, setSelectedFilters] =
    useState<ProviderSearchFilters>(searchFilters);
  const [lastModifiedFilter, setLastModifiedFilter] =
    useState<LastModifiedFilter>({});

  const {
    useMockData = false,
    search = '',
    providerType = '',
    specialtyCode,
    category,
    searchType,
    pcpIndicator = false,
    includeOrgTypeCodes,
    includeSpecialityRollupCodes,
    requestType = '',
    searchRadius,
    coverageType,
  } = tokenData;

  const {
    totalCount = 0,
    providerFilterList,
    isLoading,
  } = useParsedProviderFilters({
    useMockData,
    sectionType,
    search,
    providerType,
    specialtyCode: specialtyCode || includeSpecialityRollupCodes,
    category,
    searchType,
    pcpIndicator,
    includeOrgTypeCodes,
    requestType,
    searchRadius,
    selectedFilters,
    coverageType,
  });

  const filters = transformFiltersData(
    t,
    filtersCollection,
    providerFilterList,
    lastModifiedFilter
  );

  const resetFiltersState = () => {
    if (JSON.stringify(selectedFilters) !== JSON.stringify(searchFilters)) {
      setSelectedFilters({ ...searchFilters });
    }
  };

  useEffect(() => {
    resetFiltersState();
  }, [JSON.stringify(searchFilters)]);

  const onApplyFilter = (data) => {
    setLastModifiedFilter({});
    setSearchFilters({
      ...searchFilters,
      ...data,
    });
  };

  const onFilterSelectionChange = (
    data: ProviderSearchFilters,
    lastModifiedModel: string = ''
  ) => {
    setLastModifiedFilter(
      filters.find((filter) => filter.model === lastModifiedModel) || {}
    );
    setSelectedFilters({
      ...searchFilters,
      ...data,
    });
  };

  const onPopoverDismiss = () => {
    resetFiltersState();
  };

  const clearFilter = (model: string) => {
    const filteredData = defaultSearchFilters[model];
    if (filteredData) {
      setSearchFilters({ ...searchFilters, [model]: filteredData });
    } else {
      const updatedSearchFilters = { ...searchFilters };
      delete updatedSearchFilters[model];
      setSearchFilters(updatedSearchFilters);
    }
  };

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      {filters
        .filter(({ chipFilters }) =>
          chipFilters[coverageType]?.includes(sectionType)
        )
        .sort((a, b) => a.chipFiltersOrder - b.chipFiltersOrder)
        .map(({ label, options, model, type }) => (
          <NameFilter
            key={label}
            clearFilter={clearFilter}
            defaultValue={selectedFilters[model]}
            isLoading={isLoading}
            label={label}
            model={model}
            onApplyFilter={onApplyFilter}
            onFilterSelectionChange={onFilterSelectionChange}
            onPopoverDismiss={onPopoverDismiss}
            options={options}
            title={label}
            totalCount={totalCount}
            type={type}
          />
        ))}
    </div>
  );
};
