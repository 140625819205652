import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Popover } from '@abyss/web/ui/Popover';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';

export const StyledLocationsPopover = styled(Popover, {
  '&.abyss-popover-arrow': {
    display: 'none !important',
  },

  '&.abyss-popover-close-button': {
    margin: '20px 20px 0 0',
  },

  '&.abyss-popover-close-icon': {
    height: '25px',
    width: '25px',
  },

  '&.abyss-popover-content': {
    width: 'auto',
  },

  '&.abyss-popover-title': {
    marginTop: '14px',
    marginBottom: '10px',
  },
  '@screen < $sm': {
    width: '100%',
  },
});

export const StyledLocationBoxContainer = styled('div', {
  '.abyss-popover-trigger ~ div': {
    zIndex: '9 !important',
  },
  '@screen < $sm': {
    width: '100%',
  },
});

export const StyledLocationBox = styled(Flex, {
  justifyContent: 'space-between',
  padding: '$md $md',
  minWidth: '407px',
  minHeight: '82px',
  border: '1px solid $neutralGray4',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.09)',
  borderRadius: '4px',
  '@screen < $sm': {
    minWidth: 'auto',
  },
  '>div': {
    '@screen < $sm': {
      width: '85%',
    },
  },
});

export const StyledLocationsDropdownOption = styled('div', {
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '24px',
  color: '$neutralGray7',
  textAlign: 'left',
  '@screen < $sm': {
    fontSize: '$md',
  },
});

export const StyledLocationsContentWrapper = styled('div', {
  maxHeight: '180px',
  overflowY: 'auto',
});

export const StyledPopoverRadio = styled(RadioGroup.Radio, {
  dynamic: ({ cssProps: { selected } }) => ({
    '&.abyss-radio-label': {
      fontWeight: selected ? '700' : '500',
    },
  }),

  '&.abyss-radio': {
    borderColor: '#196ECF',
  },

  '&.abyss-radio-root': {
    marginLeft: '8px',
    marginRight: '8px',
    marginTop: '10px',
  },

  '&.abyss-radio-label': {
    fontWeight: '500',
    lineHeight: '20px',
    marginBottom: '10px',
    whiteSpace: 'pre-line',
  },
});

export const StyledChangeLocationButton = styled(Button, {
  '&.abyss-button-root': {
    margin: '35.5px 8px 8px 8px',
    '@screen < $sm': {
      margin: '16px 0 0 0',
      width: 'calc(100vw - 32px)',
      height: '42px',
      fontWeight: '600',
    },
  },
});

export const StyledAddressLine = styled('span', {
  display: 'block',
});
