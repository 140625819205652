import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Breadcrumbs } from '@abyss/web/ui/Breadcrumbs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { notMobile } from '../../ConstantsStyles';
import {
  BackButton,
  BreadcrumbsContainer,
  HeaderIcon,
} from './Breadcrumb.styled';

interface Props {
  breadcrumbs: any[];
  backgroundColor?: string;
  color?: String;
  currentColor?: String;
  showBreadcrumbAtMediumScreenSize?: boolean;
  onBackButtonClick?: () => void;
}

export const Breadcrumb = ({
  breadcrumbs = [],
  backgroundColor = '#FBFCFE',
  color = '#196ECF',
  currentColor = '#323334',
  showBreadcrumbAtMediumScreenSize = true,
  onBackButtonClick,
}: Props) => {
  const { t } = useTranslation();
  const desktopScreen = useMediaQuery(notMobile);

  const { navigate } = useRouter();

  const initialBreadcrumbs = [{ title: t('Find care'), href: '/' }];

  const additionalBreadcrumbs = breadcrumbs.map((crumb) => ({
    title: t(crumb.title),
    href: crumb.href,
  }));

  const handleBackButton = () => {
    adobeLinkTrackEvent({
      name: 'back button',
      location: 'header',
      type: 'internal',
    });
    if (onBackButtonClick) {
      onBackButtonClick();
    } else {
      navigate(-1);
    }
  };

  const finalBreadcrumbs =
    breadcrumbs.length > 0
      ? initialBreadcrumbs.concat(additionalBreadcrumbs)
      : initialBreadcrumbs;
  useEffect(() => {
    const allBreadcrumbLnks = document.querySelectorAll(
      '.abyss-breadcrumb-link'
    );
    allBreadcrumbLnks.forEach((ele) => {
      const elHref = ele.href?.split('/');
      /* This is a wrokaround for Abyss breadcrumbs limitation of not allowing to pass custom data attributes. the name for adobe link tracking is being extracted from href of the breadcrumb link which some times has an encoded string as last endpoint. So below line will decide which part of href need to be considered. If href has encoded string the url segments will be more than 4 in such cases we ignore the last segment. */
      const hrefIndx = elHref.length > 4 ? 2 : 1;
      ele.addEventListener('click', () => {
        adobeLinkTrackEvent({
          name: elHref[elHref.length - hrefIndx],
          location: `body:bread crumb`,
          type: 'internal',
          destinationUrl: '',
        });
      });
    });
  }, []);

  return (
    <React.Fragment>
      <BreadcrumbsContainer
        css={{
          '.abyss-breadcrumbs-root': {
            backgroundColor,
            color,
            'li:first-child': {
              'a:first-child': {
                color,
              },
            },
          },
          '.abyss-breadcrumb-link': {
            color,
          },
          '.abyss-breadcrumb-current': {
            color: currentColor,
          },
        }}
        showBreadcrumbAtMediumScreenSize={showBreadcrumbAtMediumScreenSize}
      >
        <Breadcrumbs
          data-auto-testid="nav-container"
          data-testid="nav-container"
          divider="/"
          items={finalBreadcrumbs}
          size={desktopScreen ? '14px' : '$sm'}
          space={desktopScreen ? 14 : 8}
        />
      </BreadcrumbsContainer>
      {breadcrumbs.length ? (
        <BackButton
          className="mobile-nav-back-button"
          color="$primary1"
          data-auto-testid="back-button"
          data-testid="back-button"
          onClick={handleBackButton}
          showBreadcrumbAtMediumScreenSize={showBreadcrumbAtMediumScreenSize}
          variant={backgroundColor === '#FBFCFE' ? 'ghost' : 'tertiary'}
        >
          <HeaderIcon
            color="$primary1"
            data-auto-testid="back-button"
            data-testid="back-button"
            icon="arrow_back"
            isScreenReadable
            name="back-button"
            size={24}
          />
        </BackButton>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};
