import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { Rating } from '../../../SnackCard/Rating';
import { RatingDescriptionWithStarsStyles } from './PatientReviews.styled';

type RatingDescriptionProps = {
  copy: string;
  rating: number | undefined;
};

export const RatingDescriptionWithStars = ({
  copy,
  rating,
}: RatingDescriptionProps) => {
  const { t } = useTranslation();
  return (
    <Flex css={RatingDescriptionWithStarsStyles}>
      <Text size="$sm">{t(copy)}</Text>
      <Rating hideNumber outOf={5} rating={rating} />
    </Flex>
  );
};
