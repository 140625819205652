import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SaveAndCancelButton } from '../../frontends/ProviderSearch/routes/ChoosePCP/ChoosePCPLocation/ChoosePCPLocation.styled';
import { ProviderLocation } from '../../models/ProviderDetails';
import { getDistance } from '../../utils/card.utils';
import { shortenZipCode } from '../../utils/providerDetails.utils';
import { Constants } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import {
  AcceptingNewPatientsBadge,
  LocationBadgeRow,
  LocationCardGroup,
  LocationCardWrapper,
  LocationDetailsStack,
} from './SelectLocationCard.styled';

export type Props = {
  className?: string;
  isMobileMapView?: boolean;
  location: ProviderLocation;
  selectLocation(
    locationId,
    locationLng,
    locationLat,
    selectedAddressIndex
  ): void;
  selected?: boolean;
  setIsMobileMapView: (isMobileMapView: boolean) => void;
  showBadgeRow?: boolean;
  showRadioSelect?: boolean;
  selectedAddressIndex: number;
};

export const SelectLocationCard = ({
  selectedAddressIndex,
  location,
  isMobileMapView = false,
  showBadgeRow = false,
  showRadioSelect = false,
  selected = false,
  selectLocation,
  className = '',
  setIsMobileMapView,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();
  const { address, distance, acceptingNewPatients } = location;
  const { line, state, postalCode } = address;
  const addressString = `${line?.join(', ')}, ${state}, ${shortenZipCode(
    postalCode
  )}`;
  const addressFontSizeMobile = mobileScreen ? 'md' : 'lg';

  const choosePcpLocationTestId: string = 'choose-pcp-location';
  const selectLocationCardTestId: string = 'pcp-select-location-card';

  const handleLocationClick = () => {
    const linkName: string = selectLocationCardTestId;
    const linkLocation: string = `body:${choosePcpLocationTestId}`;
    const modalName: string = Constants.MAP_VIEW_NAVIGATION_MODAL;
    const linkType: string = 'radio';
    handleLinkAndModalTrack(linkName, linkLocation, modalName, linkType);

    selectLocation(
      selectedAddressIndex,
      location.locationId,
      location.longitude,
      location.latitude
    );
  };

  const wrapperSelectedStyle = {
    border: '3px solid $interactive2',
  };

  return (
    <LocationCardWrapper
      aria-pressed={selected ? 'true' : 'false'}
      className={`location-card-wrap ${className}`}
      css={selected ? wrapperSelectedStyle : ''}
      data-auto-testid={selectLocationCardTestId}
      data-testid={selectLocationCardTestId}
      onClick={handleLocationClick}
    >
      <LocationCardGroup>
        {showRadioSelect ? (
          <div>
            {selected ? (
              <IconMaterial
                data-auto-testid="icon-location-selected"
                data-testid="icon-location-selected"
                icon="check_circle"
                size={20}
              />
            ) : (
              <IconMaterial
                color="$neutralGray5"
                data-auto-testid="icon-location-unselected"
                data-testid="icon-location-unselected"
                icon="radio_button_unchecked"
                size={20}
              />
            )}
          </div>
        ) : null}
        <LocationDetailsStack
          alignItems="left"
          className="location-details"
          grow
        >
          <Text
            color="#4B4D4F"
            fontWeight="$medium"
            size={isMobileMapView ? 14.22 : addressFontSizeMobile}
          >
            {addressString}
          </Text>
          {showBadgeRow ? (
            <LocationBadgeRow
              data-auto-testid="select-location-badge-row"
              data-testid="select-location-badge-row"
              justify="space-between"
            >
              <Layout.Group>
                <IconMaterial icon="directions" size="$sm" />
                <Text color="$interactive1" fontWeight="$bold" size="xs">
                  {getDistance(distance)} {t('miles')}
                </Text>
              </Layout.Group>

              {acceptingNewPatients ? (
                <AcceptingNewPatientsBadge
                  icon={<IconMaterial color="$grey8" icon="check" size="sm" />}
                  variant="neutral"
                >
                  {t('Accepting new patients')}
                </AcceptingNewPatientsBadge>
              ) : null}
            </LocationBadgeRow>
          ) : null}
          {isMobileMapView ? (
            <SaveAndCancelButton
              after={<IconMaterial color="$white" icon="chevron_right" />}
              data-auto-testid={choosePcpLocationTestId}
              data-testid={choosePcpLocationTestId}
              onClick={() => setIsMobileMapView(false)}
            >
              {t('CHOOSE_PCP_FLOW.SAVE_LOCATION')}
            </SaveAndCancelButton>
          ) : null}
        </LocationDetailsStack>
      </LocationCardGroup>
    </LocationCardWrapper>
  );
};
