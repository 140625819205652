import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Tooltip } from '@abyss/web/ui/Tooltip';

import {
  NationalAncFacility,
  NationalAncFacilityIcon,
  NationalAncFacilityText,
  NationalAncFacilityType,
} from './SnackCard.styles';

type Props = {
  displayName: string;
  providerSpeciality: string;
};

export const NationalAncillaryFacilitySnackCard = ({
  displayName,
  providerSpeciality,
}: Props) => (
  <Flex
    css={{
      'abyss-flex-root': {
        alignSelf: 'stretch',
      },
    }}
    direction="column"
  >
    <NationalAncFacilityIcon>
      <IconMaterial color="$info1" icon="open_in_new" size="18px" />
    </NationalAncFacilityIcon>
    <NationalAncFacility>
      <Tooltip asChild={false} content={displayName}>
        <NationalAncFacilityText>{displayName}</NationalAncFacilityText>
      </Tooltip>
    </NationalAncFacility>
    <Tooltip asChild={false} content={providerSpeciality}>
      <NationalAncFacilityType>{providerSpeciality}</NationalAncFacilityType>
    </Tooltip>
  </Flex>
);
