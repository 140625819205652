import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';

import { ReverseCoverageTypesCodes } from '../../common/Constants';

export const useCoverageType = () => {
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const { coverageType = ReverseCoverageTypesCodes.MEDICAL } =
    tokenizer.parse(token) || {};

  return coverageType;
};
