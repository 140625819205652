import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import find from 'lodash/find';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useLagoon } from '../../hooks/useLagoon';
import { usePrimaryCare } from '../../hooks/usePrimaryCare';
import { BreadcrumbSessionStorage } from '../../models/BreadcrumbSessionStorage';
import { ProviderLocation, ProviderType } from '../../models/ProviderDetails';
import {
  capitalizeEachWord,
  getProviderPCPId,
  getSelectedLocationIndex,
  parseProviderName,
} from '../../utils/providerDetails.utils';
import {
  getCurrentMember,
  getCurrentMemberPCPInfo,
} from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { FeatureFlags } from '../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { hideScrollbar, phoneOnly } from '../ConstantsStyles';
import { ImageComponent } from '../ImageComponent';
import { Breadcrumb } from '../PSXHeader/Breadcrumb';
import { PSXIcon } from '../PSXIcon';
import { ShareThisProviderDrawer } from '../ShareThisProvider/ShareThisProvider';
import {
  PopoverContainer,
  TierOneSnackCardPopoverStyles,
} from '../SnackCard/SnackCard.styles';
import { TieredDrawerStyles } from '../TierBenefitsCard/TierBenefitsCard.style';
import { getFeatureFlag } from '../Utils';
import { DetailsPageChips } from './DetailsPageChips';
import {
  AvatarDetailsPage,
  ChoosePCPButtonContainer,
  DetailTierCardText,
  DetailTierInfoBox,
  DetailsSection,
  DetailsSubSection,
  HeaderContainer,
  HeaderContentWrapper,
  HeaderFlex,
  InteractButton,
  InteractSection,
  ProviderIndicator,
  ProviderIndicatorSection,
  ProviderRatingStar,
  ProviderRatingText,
  ProviderSubtitle,
  ProviderSubtitleSection,
  ProviderTitle,
  ProviderUpdatedSubsection,
  SuggestEditLink,
  VoiceOnlyText,
} from './DetailsPageHeader.styled';
import { ProviderDetailBanner } from './ProviderDetailBanner';
import { SuggestEditDrawerCard } from './SuggestEditDrawerCard';

export type Props = {
  avatar?: string;
  inNetwork: string;
  lastUpdatedDate?: string;
  rating?: string;
  providerId: string;
  providerName?: string;
  providerType?: ProviderType;
  primarySpeciality: string[];
  professionalDesignation?: string;
  providerLocations: ProviderLocation[];
  imageLocation?: string;
  setNavigateToDirections: (a: boolean) => void;
};

export const DetailsPageHeader = ({
  avatar,
  inNetwork,
  rating,
  providerId,
  providerName,
  providerType,
  primarySpeciality,
  professionalDesignation,
  providerLocations,
  imageLocation,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lastUpdatedDate,
  setNavigateToDirections,
}: Props) => {
  const { t } = useTranslation();
  const featureFlags = useLagoon('feature-flags')();
  const showDoesAnythingLookWrong = getFeatureFlag(
    featureFlags,
    'DOES_ANYTHING_LOOK_WRONG'
  );
  // const shareButtonTestId = 'share-button';

  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const [, setDirectionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { choosePCP = false } = tokenData;

  const imgRef = useRef<HTMLImageElement>(null);
  const imgSrc = imageLocation;

  const mobileScreen = useMediaQuery(phoneOnly);
  // const desktopScreen = useMediaQuery(desktopOnly);
  const [suggestEditDrawer, setSuggestEditDrawer] = useState(false);
  const [breadcrumbSessionStorage] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );
  const [isTierDrawerOpen, setIsTierDrawerOpen] = useState(false);
  const searchResultBreadcrumbTitle = (providerTypeLocal) => {
    let titleAssigned;
    switch (providerTypeLocal) {
      case ProviderType.ORGANIZATION:
        titleAssigned = t('Facility results');
        break;
      case Constants.RESULT_SECTION.PROVIDER_GROUPS:
        titleAssigned = t('Provider group results');
        break;
      default:
        titleAssigned = t('Provider results');
        break;
    }
    return titleAssigned;
  };

  const parseBreadcrumbs = (urls) => {
    let breadcrumbs;
    switch (providerType) {
      case ProviderType.ORGANIZATION:
        breadcrumbs = [
          {
            title: t('DETAILS_PAGE.FACILITY_BREADCRUMB'),
            href: '',
          },
        ];
        break;
      case ProviderType.PROVIDER_GROUP:
        breadcrumbs = [
          {
            title: t('DETAILS_PAGE.PROVIDER_GROUP_BREADCRUMB'),
            href: '',
          },
        ];
        break;
      default:
        breadcrumbs = [
          {
            title: t('DETAILS_PAGE.PROVIDER_BREADCRUMB'),
            href: '',
          },
        ];
        break;
    }

    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key]) {
      const breadcrumbToken =
        tokenizer.parse(
          urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key].replace(
            '/results/mapview/',
            ''
          )
        ) || {};
      breadcrumbs.unshift({
        title: searchResultBreadcrumbTitle(breadcrumbToken.sectionType),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key]}`,
      });
    }
    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]) {
      breadcrumbs.unshift({
        title: t('BC Results'),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]}`,
      });
    }
    return breadcrumbs;
  };
  const breadcrumbs = parseBreadcrumbs(breadcrumbSessionStorage);
  const [showShareDrawer, setShowShareDrawer] = useState(false);

  const inNetworkElement = () => (
    <Layout.Group space={0}>
      <IconMaterial
        color="#007000"
        icon="check_circle"
        size={mobileScreen ? '20px' : '24px'}
      />
      <ProviderIndicator
        color="$primary1"
        fontWeight="$medium"
        size={mobileScreen ? '$md' : '18px'}
      >
        {t(inNetwork)}
      </ProviderIndicator>
    </Layout.Group>
  );

  const currentMember = getCurrentMember();
  const { data } = usePrimaryCare();
  const { primaryCare } = data;

  const memberPCPId = getCurrentMemberPCPInfo(
    currentMember?.demographics?.dependentSeqNbr,
    primaryCare
  )?.providerID;

  const providerPCPId = getProviderPCPId(
    providerLocations,
    providerId,
    memberPCPId
  );

  const ChoosePCPButton = () => {
    const handleChoosePCPClick = () => {
      adobeLinkTrackEvent({
        name: 'choose pcp button',
        location: 'body:details header',
        type: 'internal',
      });
      const updatedToken = tokenizer.update(token, {
        choosePCPId: providerId,
        selectedProviderType:
          providerType === ProviderType.PROVIDER_GROUP
            ? ProviderType.MEDICAL_GROUP
            : providerType,
      });

      navigate(`/choose-pcp/locations/${updatedToken}`);
    };

    if (choosePCP && memberPCPId !== providerPCPId)
      return (
        <ChoosePCPButtonContainer
          before={<IconMaterial color="$white" icon="check" />}
          data-auto-testid="choosePCP-button"
          data-testid="choosePCP-button"
          onClick={handleChoosePCPClick}
          size="$md"
        >
          {t('PRIMARY_CARE_PROVIDER.CHOOSE_PCP')}
        </ChoosePCPButtonContainer>
      );
    return null;
  };

  const directionsOnClick = () => {
    if (setNavigateToDirections) {
      setDirectionsTriggeredFrom(
        Constants.DETAILS_PAGE_HEADER_DIRECTIONS_BUTTON
      );
      setNavigateToDirections(true);
    }
  };

  const handleChipsOnClick = (chipName) => {
    if (chipName === Constants.DETAILS_PAGE_HEADER.TABS.DIRECTIONS) {
      directionsOnClick();
    }

    adobeLinkTrackEvent({
      name: chipName,
      location: 'body:details header',
      type: 'internal',
    });
  };

  if (!providerName || !providerType) {
    return null;
  }
  const lagoonData = useLagoon('ui-messaging')();
  const tierOneContentOne = find(lagoonData, {
    key: ConstantsLagoon.MEDICAL_SPECIALISTS.TIER_ONE_BENEFITS_ONE,
  });
  const tierOneContentTwo = find(lagoonData, {
    key: ConstantsLagoon.MEDICAL_SPECIALISTS.TIER_ONE_BENEFITS_TWO,
  });
  const tierContent = `${tierOneContentOne?.message}\n\n${tierOneContentTwo?.message}`;

  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const index = getSelectedLocationIndex(providerLocations, selectedId);

  return (
    <React.Fragment>
      <SuggestEditDrawerCard
        avatar={avatar}
        inNetwork={inNetwork}
        providerLocations={providerLocations}
        providerName={providerName}
        providerType={providerType}
        setSuggestEditDrawer={setSuggestEditDrawer}
        suggestEditDrawer={suggestEditDrawer}
      />
      <ShareThisProviderDrawer
        professionalDesignation={professionalDesignation}
        providerLocations={providerLocations}
        providerName={providerName}
        providerType={providerType}
        setShowShareThisProviderDrawer={setShowShareDrawer}
        showShareThisProviderDrawer={showShareDrawer}
        speciality={primarySpeciality}
      />
      <HeaderContainer>
        <HeaderContentWrapper>
          <HeaderFlex alignItems="center" justify="space-between">
            <Breadcrumb
              breadcrumbs={breadcrumbs}
              showBreadcrumbAtMediumScreenSize={false}
            />
            {/* <NavAndShareButton
              data-auto-testid={shareButtonTestId}
              data-testid={shareButtonTestId}
              onClick={() => {
                handleLinkAndModalTrack(
                  shareButtonTestId,
                  'body:details header',
                  'share provider'
                );
                setShowShareDrawer(true);
              }}
              variant="ghost"
            >
              <HeaderIcon
                color="$primary1"
                data-auto-testid={shareButtonTestId}
                data-testid={shareButtonTestId}
                icon="share"
                isScreenReadable={false}
                name={shareButtonTestId}
                size={desktopScreen ? '$md' : '$sm'}
              />
              {t('Share')}
            </NavAndShareButton> */}
          </HeaderFlex>
          {showTierProviderTag &&
            providerLocations[index]?.isTieredProvider && (
              <div>
                <Drawer
                  css={TieredDrawerStyles}
                  isOpen={isTierDrawerOpen}
                  onClose={() => setIsTierDrawerOpen(false)}
                  position="bottom"
                  size="200"
                  title={t('Tier 1 Provider')}
                >
                  {tierContent ?? t('TIERED_BENEFITS.TIER_CONTENT')}
                </Drawer>
                {mobileScreen ? (
                  <DetailTierInfoBox
                    data-auto-testid="tierOneSnackCardDetailsPageHeader"
                    data-testid="tierOneSnackCardDetailsPageHeader"
                    onClick={() => setIsTierDrawerOpen(true)}
                  >
                    <PSXIcon icon="Group 204976975" size={16} />
                    <DetailTierCardText>
                      {t('Tier 1 Provider')}
                    </DetailTierCardText>
                  </DetailTierInfoBox>
                ) : (
                  <PopoverContainer>
                    <Popover
                      content={tierContent ?? t('TIERED_BENEFITS.TIER_CONTENT')}
                      css={TierOneSnackCardPopoverStyles}
                      data-auto-testid="tierOneDetailHeader"
                      data-testid="tierOneDetailHeader"
                      title={t('Tier 1 Provider')}
                      width={214}
                    >
                      <DetailTierInfoBox>
                        <PSXIcon icon="Group 204976975" size={16} />
                        <DetailTierCardText>
                          {t('Tier 1 Provider')}
                        </DetailTierCardText>
                      </DetailTierInfoBox>
                    </Popover>
                  </PopoverContainer>
                )}
              </div>
            )}
          <DetailsSection>
            <AvatarDetailsPage
              data-auto-testid="provider-profile-picture"
              data-testid="provider-profile-picture"
            >
              {ImageComponent({ imgRef, providerType, imgSrc })}
            </AvatarDetailsPage>
            <DetailsSubSection>
              <ProviderTitle
                color="$primary1"
                data-auto-testid="provider-title"
                data-testid="provider-title"
                display="h5"
              >
                {parseProviderName(
                  providerName,
                  providerType,
                  professionalDesignation
                )}
              </ProviderTitle>
              <ProviderSubtitleSection
                data-auto-testid="provider-subtitle"
                data-testid="provider-subtitle"
              >
                <Flex>
                  {primarySpeciality?.length > 0 && (
                    <ProviderSubtitle
                      color="$primary1"
                      fontWeight="$medium"
                      size={mobileScreen ? '$md' : '$lg'}
                    >
                      {capitalizeEachWord(primarySpeciality[0])}
                    </ProviderSubtitle>
                  )}
                  {!mobileScreen ? inNetworkElement() : ''}
                </Flex>
                <ProviderIndicatorSection>
                  {mobileScreen ? inNetworkElement() : ''}
                  {providerType === ProviderType.PRACTITIONER && (
                    <div>
                      <VoiceOnlyText>
                        {rating
                          ? `${rating} stars`
                          : Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
                              .NOT_AVAILABLE}
                      </VoiceOnlyText>

                      {Constants.RATE_LIMITING.map((param) => (
                        <ProviderRatingStar
                          key={`rating${param}`}
                          aria-hidden="true"
                          className="star-icon"
                          color="$secondary2"
                          icon={
                            param < parseInt(rating || '0', 10)
                              ? 'star'
                              : 'star_border'
                          }
                          size={20}
                        />
                      ))}
                      <ProviderRatingText
                        aria-hidden="true"
                        className="text"
                        color="$primary1"
                        data-auto-testid="provider-details-rating"
                        data-testid="provider-details-rating"
                      >
                        {rating ||
                          Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
                            .NOT_AVAILABLE}
                      </ProviderRatingText>
                    </div>
                  )}
                </ProviderIndicatorSection>
              </ProviderSubtitleSection>
            </DetailsSubSection>
          </DetailsSection>
          <ProviderDetailBanner
            memberPCP={memberPCPId}
            providerPCPId={providerPCPId}
          />
          {showDoesAnythingLookWrong && (
            <ProviderUpdatedSubsection>
              <SuggestEditLink
                data-auto-testid="provider-suggest-edit-link"
                data-testid="provider-suggest-edit-link"
                isStandardAnchor
                onClick={() => setSuggestEditDrawer(true)}
                variant="native"
              >
                {t('DETAILS_PAGE.SUGGEST_EDIT')}
              </SuggestEditLink>
            </ProviderUpdatedSubsection>
          )}
          <InteractSection css={hideScrollbar}>
            <ChoosePCPButton />
            {Constants.DETAILS_CHIPS.map((chip) => (
              <InteractButton
                key={`provider-details-chips-${chip.text}`}
                before={<IconMaterial color="$primary1" icon={chip.icon} />}
                data-auto-testid={`provider-details-chips-${chip.text}`}
                data-testid={`provider-details-chips-${chip.text}`}
                onClick={() => handleChipsOnClick(chip.text)}
                variant="outline"
              >
                {t(chip.text)}
              </InteractButton>
            ))}
            {Constants.DETAILS_CHIPS_NAME.map((chip) => (
              <DetailsPageChips
                chip={chip.text}
                providerLocations={providerLocations}
              />
            ))}
          </InteractSection>
        </HeaderContentWrapper>
      </HeaderContainer>
    </React.Fragment>
  );
};
