import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';

import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { phoneOnly } from '../../ConstantsStyles';
import {
  NavigationButtonBox,
  NavigationButtonBoxContainer,
} from './NavigationButton.styled';

type Props = {
  buttonNavType: string;
  curNavType: string;
  setNavType(navType: string): void;
  locationForAnalytics?: string;
  label: string;
};

const getMaterialIcon = (navType) => {
  switch (navType) {
    case 'driving':
      return 'drive_eta';
    case 'walking':
      return 'directions_walk';
    case 'cycling':
      return 'directions_bike';
    default:
      return 'traffic';
  }
};

export const NavigationButton = ({
  buttonNavType,
  curNavType,
  setNavType,
  locationForAnalytics,
  label,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const INACTIVE_NAV_BUTTON = mobileScreen ? '$gray2' : ' $primary2';
  const INACTIVE_NAV_ICON = '#323334';
  const navigationDataTestId: string = `navigation-${buttonNavType}`;
  const handleLinkTrack = (linkName: string, linkLocation?: string) => {
    adobeLinkTrackEvent({
      name: linkName,
      location: linkLocation,
      type: 'internal',
    });
  };
  return (
    <NavigationButtonBoxContainer color={INACTIVE_NAV_BUTTON}>
      <NavigationButtonBox
        aria-label={label}
        css={{
          background:
            buttonNavType === curNavType
              ? '$interactive1'
              : INACTIVE_NAV_BUTTON,
        }}
        data-auto-testid={navigationDataTestId}
        data-testid={navigationDataTestId}
        onClick={() => {
          handleLinkTrack(
            navigationDataTestId,
            `modal:${locationForAnalytics}`
          );
          setNavType(buttonNavType);
        }}
      >
        <IconMaterial
          color={buttonNavType === curNavType ? '$white' : INACTIVE_NAV_ICON}
          icon={getMaterialIcon(buttonNavType)}
        />
      </NavigationButtonBox>
    </NavigationButtonBoxContainer>
  );
};
