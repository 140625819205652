import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { useParsedProviderFilters } from '../../../hooks/useProviderFilterCount';
import { useProviderFilters } from '../../../hooks/useProviderFilters';
import {
  LastModifiedFilter,
  ProviderSearchFilters,
} from '../../../models/ProviderSearch';
import { transformFiltersData } from '../../../utils/providerSearch.utils';
import { adobeSearchFilterEvent } from '../../AdobeTagging/adobeSearchFilterEvent';
import { getFormattedFilters } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { AllFilterToggleSwitchGroup } from './AllFilterToggleSwitchGroup';
import {
  BottomSection,
  DrawerHeading,
  FilterWrapper,
  ScrollableSection,
  StyledDrawer,
  SubSection,
} from './index.style';
import { ProviderFilters } from './ProviderFilters';
import { SelectedFilters } from './SelectedFilters';

type Props = {
  position: string;
  isOpen: boolean;
  size: number;
  onClose: () => void;
};

export const AllFilterDrawer = ({ position, isOpen, size, onClose }: Props) => {
  const { t } = useTranslation();
  const { searchFilters, setSearchFilters, defaultSearchFilters } =
    useContext(SearchFilterContext);

  const { getRouteParams } = useRouter();
  const filtersCollection = useProviderFilters();
  const { token } = getRouteParams();
  const [selectedFilters, setSelectedFilters] =
    useState<ProviderSearchFilters>(searchFilters);
  const [lastModifiedFilter, setLastModifiedFilter] =
    useState<LastModifiedFilter>({});
  const tokenData = tokenizer.parse(token) || {};
  const {
    useMockData = false,
    sectionType = '',
    search = '',
    providerType = '',
    specialtyCode,
    category,
    searchType,
    pcpIndicator = false,
    includeOrgTypeCodes,
    includeSpecialityRollupCodes,
    requestType = '',
    searchRadius,
    coverageType,
  } = tokenData;

  const {
    totalCount = 0,
    providerFilterList: filtersData,
    isLoading,
  } = useParsedProviderFilters({
    useMockData,
    sectionType,
    search,
    providerType,
    specialtyCode: specialtyCode || includeSpecialityRollupCodes,
    category,
    searchType,
    pcpIndicator,
    includeOrgTypeCodes,
    requestType,
    searchRadius,
    selectedFilters,
    coverageType,
  });

  const filters = transformFiltersData(
    t,
    filtersCollection,
    filtersData,
    lastModifiedFilter
  );

  const onFilterSelectionChange = (
    data: ProviderSearchFilters,
    lastModifiedModel: string = ''
  ) => {
    setLastModifiedFilter(
      filters.find((filter) => filter.model === lastModifiedModel) || {}
    );
    setSelectedFilters(data);
  };

  const handleSubmit = () => {
    adobeSearchFilterEvent({
      filters: getFormattedFilters(selectedFilters),
    });
    setLastModifiedFilter({});
    setSearchFilters(selectedFilters);
    onClose();
  };

  return (
    <StyledDrawer
      data-auto-testid="all-filter-drawer"
      data-testid="all-filter-drawer"
      isOpen={isOpen}
      onClose={onClose}
      position={position}
      size={size}
    >
      <FilterWrapper>
        <Flex css={{ padding: '$md' }}>
          <Heading
            color="$gray8"
            css={DrawerHeading}
            data-testid="all-filters-heading"
            offset={5}
          >
            {t('CONTENT_FILTERS.ALL_FILTERS')}
          </Heading>
        </Flex>
        <SelectedFilters
          defaultSearchFilters={defaultSearchFilters}
          lastModifiedFilter={lastModifiedFilter}
          onFilterSelectionChange={onFilterSelectionChange}
          selectedFilters={selectedFilters}
        />
        <ScrollableSection>
          <SubSection
            css={{
              '.abyss-toggle-switch-root': {
                margin: 0,
              },
              '.abyss-toggle-switch-label': {
                lineHeight: '20px',
              },
              gap: '24px',
            }}
            direction="column"
          >
            <AllFilterToggleSwitchGroup
              sectionType={sectionType}
              selectedFilters={selectedFilters}
              setSelectedFilters={onFilterSelectionChange}
            />
          </SubSection>
          <ProviderFilters
            isData={!isLoading}
            onFilterSelectionChange={onFilterSelectionChange}
            providerFilters={filters}
            sectionType={sectionType}
            selectedFilters={selectedFilters}
          />
        </ScrollableSection>
        <BottomSection>
          <Button
            css={{ fontWeight: '$semibold' }}
            data-auto-testid="show-result"
            data-testid="show-result"
            isDisabled={isLoading || totalCount === 0}
            onClick={handleSubmit}
          >
            {!isLoading
              ? t(
                  'CONTENT_FILTERS.ALL_FILTERS_DRAWER.SHOW_RESULT_BUTTON'
                ).replace('$count', totalCount?.toString())
              : t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.LOADING')}
          </Button>
        </BottomSection>
      </FilterWrapper>
    </StyledDrawer>
  );
};
