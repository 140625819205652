import { Card } from '@abyss/web/ui/Card';
import { CollapseProvider } from '@abyss/web/ui/CollapseProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PhoneNumbers } from '../../../models/ProviderDetails';
import {
  getAdditionalPhone,
  getFormattedPhoneNumber,
  getPrimaryPhone,
} from '../../../utils/providerDetails.utils';
import {
  CollapseCardHeaderStyle,
  IconAlignmentContainer,
  IconMaterialContainer,
  PhoneAllContainer,
  PhoneContainer,
  PhoneNumberContainer,
  PrimaryPhoneContainer,
  PrimaryPhoneNumberContainer,
} from './ProviderLocationsDetails.style';

export type Props = {
  collapseHeader: string;
  collapseContent?: PhoneNumbers | null;
  tty: string;
};

export const CollapsePhones = ({
  collapseHeader,
  collapseContent,
  tty,
}: Props) => {
  const appointmentPhones = getAdditionalPhone(
    collapseContent?.phone,
    collapseContent?.appointment
  );
  const primaryPhones = getPrimaryPhone(
    collapseContent?.phone,
    collapseContent?.appointment
  );
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        data-auto-testid="provider-locations-collapse-phones"
        data-testid="provider-locations-collapse-phones"
      >
        <PhoneAllContainer css={{ margin: '4px 0 0 0' }}>
          <IconMaterialContainer color="$primary1" icon="call" size={18} />
          <PrimaryPhoneContainer>
            {t('PROVIDER_LOCATIONS_DETAILS.PRIMARY_PHONE')}

            <PrimaryPhoneNumberContainer>
              {primaryPhones ? (
                <a
                  data-auto-testid="phone-primary"
                  data-testid="phone-primary"
                  href={`tel:${primaryPhones}`}
                >
                  {getFormattedPhoneNumber(primaryPhones)}
                </a>
              ) : (
                t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
              )}
            </PrimaryPhoneNumberContainer>
          </PrimaryPhoneContainer>
        </PhoneAllContainer>
        <div>
          <CollapseProvider>
            <Card
              collapse
              css={CollapseCardHeaderStyle}
              defaultIsOpen={false}
              header={collapseHeader}
            >
              <IconAlignmentContainer css={{ marginTop: '13px' }}>
                <PhoneAllContainer>
                  <IconMaterialContainer
                    color="$primary1"
                    icon="call"
                    size={18}
                  />
                  <PhoneContainer>
                    {t('PROVIDER_LOCATIONS_DETAILS.APPOINTMENT_PHONE')}
                    <PhoneNumberContainer>
                      {appointmentPhones ? (
                        <a
                          data-auto-testid={appointmentPhones}
                          data-testid={appointmentPhones}
                          href={`tel:${appointmentPhones}`}
                        >
                          {getFormattedPhoneNumber(appointmentPhones)}
                        </a>
                      ) : (
                        t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
                      )}
                    </PhoneNumberContainer>
                  </PhoneContainer>
                </PhoneAllContainer>
              </IconAlignmentContainer>

              <IconAlignmentContainer>
                <PhoneAllContainer>
                  <IconMaterialContainer
                    color="$primary1"
                    icon="call"
                    size={18}
                  />
                  <PhoneContainer>
                    {t('PROVIDER_LOCATIONS_DETAILS.EMERGENCY_PHONE')}
                    <PhoneNumberContainer>
                      {collapseContent?.emergency ? (
                        <a
                          data-auto-testid={`emergency-phone-${collapseContent?.emergency}`}
                          data-testid={`emergency-phone-${collapseContent?.emergency}`}
                          href={`tel:${collapseContent?.emergency[0]}`}
                        >
                          {getFormattedPhoneNumber(
                            collapseContent?.emergency[0]
                          )}
                        </a>
                      ) : (
                        t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
                      )}
                    </PhoneNumberContainer>
                  </PhoneContainer>
                </PhoneAllContainer>
              </IconAlignmentContainer>
              <IconAlignmentContainer>
                <PhoneAllContainer>
                  <IconMaterialContainer
                    color="$primary1"
                    icon="call"
                    size={18}
                  />
                  <PhoneContainer>
                    {t('PROVIDER_LOCATIONS_DETAILS.AFTER_HOURS_PHONE')}
                    <PhoneNumberContainer>
                      {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                    </PhoneNumberContainer>
                  </PhoneContainer>
                </PhoneAllContainer>
              </IconAlignmentContainer>
              <IconAlignmentContainer>
                <PhoneAllContainer css={{ marginBottom: '0px' }}>
                  <IconMaterialContainer
                    color="$primary1"
                    icon="call"
                    size={18}
                  />
                  <PhoneContainer>
                    {t('PROVIDER_LOCATIONS_DETAILS.TTY')}
                    <PhoneNumberContainer>
                      {tty ? (
                        <a
                          data-auto-testid={tty}
                          data-testid={tty}
                          href={`tel:${tty}`}
                        >
                          {tty}
                        </a>
                      ) : (
                        t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
                      )}
                    </PhoneNumberContainer>
                  </PhoneContainer>
                </PhoneAllContainer>
              </IconAlignmentContainer>
            </Card>
          </CollapseProvider>
        </div>
      </div>
    </React.Fragment>
  );
};
