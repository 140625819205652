export const mockproviderDetails = {
  culturalCompetencies: null,
  imageLocation:
    'https://findcare-dev.uhc.com/cdn/assets/images/default_Medical Group.jpg',
  npi: '1275581894',
  pictureId: null,
  organizationType: ['Physician Group'],
  providerId: '001234567',
  providerName: 'Imperial Valley Family Care Med Grp',
  providerType: 'Medical Group',
  specialties: [
    'PEDIATRICIAN',
    'FAMILY PRACTICE',
    'INTERNAL MEDICINE',
    'GENERAL PRACTICE',
  ],
  providerLocations: [
    {
      locationId: 'B631E3A7F4',
      defaultLocation: true,
      accessibility: ['Parking', 'Restroom'],
      ddpCode: null,
      facilityProviderSpecialties: null,
      hasPCPServiceAvailability: true,
      hasWeekendAppointment: false,
      languagesSpokenByOffice: ['English'],
      leapFrogRatings: [],
      lastUpdatedDate: '2023-08-04',
      medicalEquipments: null,
      network: {
        start: '2016-10-01',
        end: '9999-12-31',
        status: 'INN',
      },
      practiceLimitations: null,
      hoursOfOperation: [
        {
          day: 'sunday',
          openingTime: '',
          closingTime: '',
        },
        {
          day: 'monday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'tuesday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'wednesday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'thursday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'friday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'saturday',
          openingTime: '',
          closingTime: '',
        },
      ],
      distance: '64.00',
      emailAddress: ['info@hopkinsmedicine.com'],
      phones: {
        phone: ['760-352-4103'],
        emergency: null,
        appointment: null,
        fax: null,
      },
      tin: '330652678',
      tty: '711',
      acceptingNewPatients: true,
      virtualCareOffered: ['Audio'],
      hasEveningAppointments: false,
      website: 'https://www.avfmuc.com',
      digestId: '19EC49A9244A4F6F6DFFB',
      pcpId: '00001234567 055',
      address: {
        line: ['6125 Paseo Del Norte Ste 140'],
        city: 'Carlsbad',
        district: 'San Diego',
        state: 'CA',
        postalCode: '92011-1119',
      },
      latitude: '33.12138',
      longitude: '-117.31678',
      isTieredProvider: null,
      specialties: [
        'PEDIATRICIAN',
        'FAMILY PRACTICE',
        'INTERNAL MEDICINE',
        'GENERAL PRACTICE',
      ],
    },
  ],
};
