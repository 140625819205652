import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';

import { mobileOnly } from '../ConstantsStyles';

export const TierInfoBox = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '8px 8px 0px 0px',
  marginLeft: '8px',
  backgroundColor: '#F2F8E6',
  height: '24px',
  padding: '$sm',
  minWidth: '120px',
  width: 'fit-content',
});

export const TierCardText = styled(Text, {
  '&.abyss-text-root': {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    color: '#4B4D4F',
    fontSize: '12.64px',
    fontWeight: '$bold',
    lineHeight: '$md',
  },
});

export const SnackContainer = styled('div', {
  display: 'flex',
  '@screen < $md': {
    height: '100%',
  },
});

export const SnackAvatarContainer = styled('div', {
  position: 'relative',
  paddingBottom: '8px',
  variants: {
    decemberFeature: {
      true: {
        paddingBottom: '0px',
      },
    },
  },
});

export const SnackInfoCard = styled(Flex, {
  variants: {
    isFixes: {
      true: {
        boxShadow: 'none',
        border: 'none',
      },
    },
    decemberFeature: {
      true: {
        width: '180px',
        height: '206px',
        gap: '0px',
        '@screen < $sm': {
          width: '144px',
          height: '176px',
        },
      },
    },
    isNationalAncFacility: {
      true: {
        cursor: 'pointer',
      },
    },
  },
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  border: ' 1px solid #E5E5E6',
  padding: '16px 8px',
  borderRadius: '8px',
  width: '192px',
  height: '198px',
  flexWrap: 'nowrap',
  '.abyss-flex-root': {
    textAlign: 'center',
  },
  '@screen < $md': {
    minHeight: '132px',
  },
  '@screen < $sm': {
    width: '143px',
    padding: '12px 6px',
    minHeight: '132px',
    height: 'auto',
  },
});

export const Avatar = styled(Flex, {
  width: '72px',
  height: '72px',
  borderRadius: '50%',
  overflow: 'hidden',
  '@screen < $sm': {
    width: '60px',
    height: '60px',
  },
});

export const slideStyleSnack = {
  'abyss-slide-container': {
    backgroundColor: '$white',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    border: ' 1px solid #E5E5E6',

    visibility: 'visible',
    borderRadius: '8px',
    height: 'auto',
  },
};

export const slideStyle = (i) => ({
  'abyss-slide-container': {
    backgroundColor: '$white',
    marginLeft: i === 0 ? '0px' : '20px',
  },
});

export const mixedResultsSlideStyle = (i: number, count: number) => ({
  'abyss-slide-container': {
    backgroundColor: '$white',
    marginLeft: i === 0 ? '0px' : '16px',
    marginRight: count === 2 && i === 0 ? '16px' : '0px',
  },
});

export const slideContainerStyle = (decemberFlag) => ({
  'abyss-slide-content-wrapper': {
    padding: '0',
    visibility: 'visible',
    '>div': {
      height: decemberFlag ? '' : '100%',
    },
  },
});

export const carouselStyle = (decemberFlag) => ({
  'abyss-carousel-root': {
    display: 'flex',
    [`@media ${mobileOnly}`]: {
      zIndex: 0,
    },
  },
  'abyss-carousel-swiper-container': {
    display: 'flex',
    willChange: 'transform',
    marginLeft: '0',
    marginRight: '0',
  },
  'abyss-carousel-slide-container': {
    position: 'relative',
    width: decemberFlag ? '180px' : '192px',
    gap: '16px',
    paddingBottom: '50px',
    '@screen < $sm': {
      width: decemberFlag ? '144px' : '146px',
    },
  },
  'abyss-carousel-minimal-controls': {
    marginTop: '12px',
    padding: '0px',
    height: '20px',
    position: 'absolute',
    bottom: '0',
    right: '0',
    left: '0',
    'li.abyss-carousel-minimal-pagination-container': {
      padding: ' 0 10px 0 10px',
    },
  },
  'abyss-carousel-minimal-nav-icon-right': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-nav-container-right': {
    width: '20px',
    height: '20px',
    padding: '0px',
  },
  'abyss-carousel-minimal-nav-container-left': {
    width: '20px',
    height: '20px',
    padding: '0px',
  },

  'abyss-carousel-minimal-nav-icon-left': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-pagination-container': {
    padding: '0 19px 0 19px',
  },
});

export const SnackDescription = styled(Flex, {
  lineHeight: '16px',
  textAlign: 'center',
});

export const Miles = styled(Flex, {
  cursor: 'pointer',
  justifyContent: 'center',
  gap: '2px',
});

export const MilesContent = styled('button', {
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  gap: '2px',
  height: '18px',
});

export const SnackName = styled('div', {
  variants: {
    decemberFeature: {
      true: {
        '.abyss-link-root': {
          paddingTop: '8px',
          lineHeight: '20px !important',
          width: '148px',
          '@screen < $sm': {
            width: '114px',
          },
        },
      },
    },
  },
  '.abyss-link-root': {
    paddingBottom: '8px',
    fontSize: '$md !important',
    fontWeight: 600,
    lineHeight: '20px',
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '160px',
    display: 'inline-block',
    '@screen < $sm': {
      fontSize: '14.22px !important',
      fontWeight: 700,
      width: '120px',
    },
  },
});

export const SnackDetails = styled(Flex, {
  marginTop: '-12px',
  variants: {
    decemberFeature: {
      true: {
        gap: '8px',
      },
    },
  },
});

export const MilesText = styled(Text, {
  color: '$accent1 !important',
  fontSize: '$extraSmallText !important',
  fontWeight: 700,
});

export const SnackRole = styled('div', {
  variants: {
    decemberFeature: {
      true: {
        lineHeight: '16px !important',
        wordBreak: 'break-word',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        width: '148px',
        '@screen < $sm': {
          lineHeight: '14px !important',
          width: '114px',
        },
      },
    },
  },
  fontSize: '$extraSmallText !important',
  fontWeight: 500,
  lineHeight: '16px !important',
  position: 'relative',
  top: '4px',
  wordBreak: 'break-word',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '160px',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  '@screen < $sm': {
    width: '120px',
  },
});

export const NoRatingText = styled(Text, {
  fontSize: '$extraSmallText !important',
  fontWeight: 500,
  fontStyle: 'italic',
});

export const PopoverContainer = styled('div', {
  position: 'relative',
  top: '4px',
  '.abyss-popover-content': {
    zIndex: '9 !important',
  },
});

export const DistanceDrawerStyles = {
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0 0',
    height: 'auto',
  },
  'abyss-modal-body': {
    margin: '16px 24px 16px 24px',
    color: '#4B4D4F',
  },
  'abyss-modal-close-button': { margin: '8px 10px 0 0' },
  'abyss-modal-content-title': {
    paddingTop: '2px',
    fontFamily: '$primary !important',
    fontSize: '20.25px !important',
    color: '#323334 !important',
    lineHeight: '24px !important',
    fontWeight: '700 !important',
  },
  'abyss-modal-header-container': {
    padding: '16px 24px 0px 24px',
  },
};

export const TierOnePopover = styled(Popover, {
  '&.abyss-popover-content': {
    position: 'fixed',
    zIndex: '9 !important',
  },
});

export const displayVirtualVisitStyle = {
  'abyss-flex-root': {
    display: 'flex',
    borderRadius: '100px',
    background: '$primary1',
    alignItems: 'center',
    padding: '4px',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '0px',
    right: '-50px',
    zindex: 1,
    '@screen < $sm': {
      right: '-30px',
    },
  },
};

export const TierOneSnackCardPopoverStyles = {
  '.abyss-popover-content-container': {
    whiteSpace: 'pre-line',
    color: '$gray8',
  },
};

export const NationalAncFacilityIcon = styled(Flex, {
  height: '24px',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  alignContent: 'flex-end',
  alignSelf: 'stretch',
});

export const NationalAncFacility = styled(Flex, {
  marginTop: '4px',
  minHeight: '72px',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  alignSelf: 'stretch',
  alignItems: 'center',
  '@screen < $sm': {
    height: '36px',
  },
});

export const NationalAncFacilityText = styled(Text, {
  color: '$primary1 !important',
  textAlign: 'center',
  width: '100%',
  fontSize: '18px !important',
  fontWeight: 600,
  lineHeight: '24px !important',
  '&.abyss-text-root': {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
});

export const NationalAncFacilityType = styled(Text, {
  marginTop: '8px',
  color: '$gray7 !important',
  textAlign: 'center',
  width: '100%',
  fontSize: '12.64px !important',
  fontWeight: 500,
  lineHeight: '$sm !important',
  '&.abyss-text-root': {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
});
