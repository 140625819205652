import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { storage } from '@abyss/web/tools/storage';
import { Button } from '@abyss/web/ui/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeviceLocation } from '../../../hooks/useDeviceLocation';
import { useLagoon } from '../../../hooks/useLagoon';
import { Constants } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { mobileOnly } from '../../ConstantsStyles';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
import { LocationSearchDesktop } from '../LocationSearchDesktop/LocationSearchDesktop';
import { LocationSearchMobile } from '../LocationSearchMobile/LocationSearchMobile';
import { getGeoLocationFromStorage } from '../SearchBar/utils';
import {
  LocationInputContainerDesktop,
  LocationInputContainerMobile,
  LocationLabel,
} from './LocationInput.styled';

/* eslint-disable react/require-default-props */
type Props = {
  location: string;
  setLocation?: (a: string) => void;
  searchTerm: string;
};

export const LocationInput = ({
  location = storage.session.get(Constants.STORAGE_KEYS.LOCAL.LOCATION),
  setLocation = () => {},
  searchTerm,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(mobileOnly);
  const [isOpen, setIsOpen] = useState(false);
  const [isReload, setIsReload] = useState(true);
  const [deviceLocation, setDeviceLocation] = useState<string>(
    getGeoLocationFromStorage() ?? ''
  );
  const { getDeviceLocation } = useDeviceLocation(setDeviceLocation);

  const data = useLagoon('ui-messaging')();
  const locationLabelText = find(data, {
    key: ConstantsLagoon.LOCATION_LABEL,
  });

  const handleChangeLocation = () => {
    setIsOpen(true);
  };

  const findUserLocation = () => {
    setDeviceLocation('');
    setIsReload(false);
    getDeviceLocation();
  };

  useEffect(() => {
    if (deviceLocation && !isReload) {
      setIsOpen(false);
      setLocation(deviceLocation);
    }
  }, [deviceLocation, isReload]);

  return (
    <React.Fragment>
      {!mobileScreen ? (
        <React.Fragment>
          <div
            style={{
              height: '71px',
              background: '#fff',
              padding: '8px 0',
              borderTop: 'solid',
              borderBottom: 'solid',
              borderColor: '#CBCCCD',
            }}
          >
            <Divider
              color="#D0D0D0"
              css={{
                'abyss-divider-root': {
                  marginLeft: '0px !important',
                },
              }}
              height="fill"
              orientation="vertical"
              width="1px"
            />
          </div>
          <LocationInputContainerDesktop>
            <div>
              <Text css={LocationLabel}>
                {locationLabelText?.message ?? ''}
              </Text>
              <LocationSearchDesktop
                findUserLocation={findUserLocation}
                location={location}
                setLocation={setLocation}
              />
            </div>
            <div style={{ alignSelf: 'center' }}>
              <Button
                data-auto-testid="submit-button"
                data-testid="submit-button"
                disabled={searchTerm?.length <= 1}
                size="$md"
                type="submit"
              >
                {t('Search')}
              </Button>
            </div>
          </LocationInputContainerDesktop>
        </React.Fragment>
      ) : (
        <LocationInputContainerMobile>
          <Layout.Group>
            <Layout.Stack css={{ marginTop: '0.1em' }}>
              <IconMaterial
                color="#196ECF"
                css={{
                  'abyss-icon-material': {
                    width: '24px',
                    height: '24px',
                  },
                }}
                icon="location_on"
              />
            </Layout.Stack>
            <Layout.Stack css={{ color: '$primary2' }}>
              <LinkWithTracking
                analyticsInfo={{
                  location: 'body:locations',
                }}
                css={{ color: '#196ECF', textDecoration: 'underline' }}
                data-auto-testid="user-location-current-location"
                data-testid="user-location-current-location"
                fontWeight="$bold"
                onClick={handleChangeLocation}
                size="$sm"
                variant="native"
              >
                {location}
              </LinkWithTracking>
            </Layout.Stack>
          </Layout.Group>
          <Drawer
            css={{
              'abyss-modal-close-button': {
                marginTop: '$md',
                marginRight: '8px',
              },
              'abyss-modal-header-container': {
                marginLeft: '$sm',
              },
              'abyss-modal-content-container': {
                borderRadius: '20px 20px 0px 0px',
              },
            }}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            position="bottom"
            size="$lg"
            title={
              <Heading color="$gray8" offset={4}>
                {!location ? t('My search location') : t('Location Change')}
              </Heading>
            }
          >
            <LocationSearchMobile
              findUserLocation={findUserLocation}
              location={location}
              setIsOpen={setIsOpen}
              setLocation={setLocation}
            />
          </Drawer>
        </LocationInputContainerMobile>
      )}
    </React.Fragment>
  );
};
