import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';

import { FacilityDetails } from '../../../../models/FacilityDetails';
import {
  FillBar,
  RatingContainerStyle,
  RatingHeader,
  RatingText,
} from './LeapFrogRating.styles';

type Props = {
  facilityDetails?: FacilityDetails;
  selectedLocationIndex?: number;
};

export const LeapFrogRating = ({
  facilityDetails,
  selectedLocationIndex,
}: Props) => {
  const ratings =
    facilityDetails?.providerLocations[selectedLocationIndex || 0]
      ?.leapFrogRatings || [];

  return (
    <Flex
      css={{
        'abyss-flex-root': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          backgroundColor: 'white',
          boxSizing: 'border-box',
        },
      }}
      direction="row"
    >
      {ratings.map((rating) => {
        const ratingAsInt = parseInt(rating.rawValue, 10);
        return (
          <Flex css={RatingContainerStyle} direction="column">
            <Text css={RatingHeader}>{rating.displayField}</Text>
            <Flex direction="row">
              {[0, 1, 2, 3].map((num: number) => (
                <Box
                  key={num}
                  color={
                    num < ratingAsInt && ratingAsInt <= 4
                      ? '$success1'
                      : '$gray4'
                  }
                  css={{
                    'abyss-box-root': {
                      ...FillBar,
                      marginRight: num === 3 ? '0' : '$sm',
                      '@screen < $sm': {
                        width: '79.75px',
                      },
                    },
                  }}
                />
              ))}
            </Flex>
            <Text css={RatingText}>{rating.ratingValue}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
