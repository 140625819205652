export const mockVisionCareSnackCardProviders = {
  totalCount: 6,
  snackCardList: [
    {
      providerId: '6955722',
      providerName: 'Mian Kiran S',
      providerType: 'Practitioner',
      primaryDegrees: ['MD'],
      specialities: ['011', '017'],
      distance: '3.2100444491633886',
      locationId: '002813958-621684203-T-6AF68F56D45832DA50CF5CAF6BA86968-L',
      networkStatus: 'INN',
      npi: '1699932319',
      healthGradeRating: '4.40625',
      organizationType: [''],
      speciality: 'Optometrist',
      imageLocation:
        'https://findcare-dev.uhc.com/cdn/assets/profile_img/default_Practitioner.jpg',
    },
    {
      providerId: '6955722',
      providerName: 'Nia John K',
      providerType: 'Practitioner',
      primaryDegrees: ['MD'],
      specialities: ['011', '017'],
      distance: '3.2100444491633886',
      locationId: '003520263-621684203-T-6AF68F56D45832DA50CF5CAF6BA86968-L',
      networkStatus: 'INN',
      npi: '1316100654',
      healthGradeRating: '4.40625',
      organizationType: [''],
      speciality: 'Optometrist',
      imageLocation:
        'https://findcare-dev.uhc.com/cdn/assets/profile_img/default_Practitioner.jpg',
    },
    {
      providerId: '6955722',
      providerName: 'Colvin Alexis C',
      providerType: 'Practitioner',
      primaryDegrees: ['MD'],
      specialities: ['011', '017'],
      distance: '3.2100444491633886',
      locationId: '003471636-621684203-T-6AF68F56D45832DA50CF5CAF6BA86968-L',
      networkStatus: 'INN',
      npi: '1275742884',
      healthGradeRating: '4.40625',
      organizationType: [''],
      speciality: 'Optometrist',
      imageLocation:
        'https://findcare-dev.uhc.com/cdn/assets/profile_img/default_Practitioner.jpg',
    },
    {
      providerId: '6955722',
      providerName: 'Sobel Gail M',
      providerType: 'Practitioner',
      primaryDegrees: ['MD'],
      specialities: ['011', '017'],
      distance: '3.564678911869846',
      locationId: '002813958-621684203-T-E6E49DC00166E557D6612F18274B0028-L',
      networkStatus: 'INN',
      npi: '1699932319',
      healthGradeRating: '4.40625',
      organizationType: [''],
      speciality: 'Optometrist',
      imageLocation:
        'https://findcare-dev.uhc.com/cdn/assets/profile_img/default_Practitioner.jpg',
    },
    {
      providerId: '695572223',
      providerName: 'Delson Jeffrey',
      providerType: 'Practitioner',
      primaryDegrees: ['MD'],
      specialities: ['011', '017'],
      distance: '3.5646789118698246',
      locationId: '003520263-621684203-T-A860E134D76575A002B268C48DDEFD44-L',
      networkStatus: 'INN',
      npi: '1316100653',
      healthGradeRating: '4.40625',
      organizationType: [''],
      speciality: 'Optometrist',
      imageLocation:
        'https://findcare-dev.uhc.com/cdn/assets/profile_img/default_Practitioner.jpg',
    },
    {
      providerId: '6955722',
      providerName: 'Hazany Salar',
      providerType: 'Practitioner',
      primaryDegrees: ['MD'],
      specialities: ['011', '017'],
      distance: '3.564678911869846',
      locationId: '003520263-621684203-T-A860E134D76575A002B268C47DDEFD44-L',
      networkStatus: 'INN',
      npi: '1316100654',
      healthGradeRating: '4.40625',
      organizationType: [''],
      speciality: 'Optometrist',
      imageLocation:
        'https://findcare-dev.uhc.com/cdn/assets/profile_img/default_Practitioner.jpg',
    },
  ],
};
