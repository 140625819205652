import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { SearchInput } from '@abyss/web/ui/SearchInput';

export const StyledLocationSearch = styled(SearchInput, {
  '.abyss-search-input-input': {
    boxShadow: 'none',
    border: 'none',
    fontSize: '18px !important',
    color: '#4B4D4F',
    fontWeight: '500',
  },
  '&.abyss-search-input-root': {
    minWidth: 'auto',
  },
  '.abyss-search-input-input-container': {
    border: 'none',
  },
  '.abyss-search-input-section': {
    backgroundColor: '$white',
    color: '$gray6',
    fontWeight: '$medium',
  },
  '.abyss-search-input-icon-container': {
    display: 'none',
  },
  '.abyss-search-input-option-icon': {
    display: 'none',
  },
  '&.abyss-search-input-option-list': {
    border: '1px solid $gray4',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
  },
  '.abyss-search-input-left-element-wrapper': {
    zIndex: 0,
  },
});

export const StyledLocationHeader = styled(Layout.Stack, {
  marginLeft: '4px',
  '&.abyss-layout-stack': {
    alignItems: 'flex-start',
    'div:nth-of-type(2)': {
      color: '#323334',
      fontSize: '14.22px',
      fontWeight: '$bold',
      position: 'relative',
      top: '8px',
    },
  },
});

export const StyledLocationItems = styled(Layout.Stack, {
  marginLeft: '2px',
});

export const StyledLocationError = styled(Layout.Group, {
  width: '90%',
});

export const FindUserLocationContainer = styled(Flex, {
  alignItems: 'center',
  height: '40px',
});
