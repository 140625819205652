import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';

import { useLagoon } from '../../hooks/useLagoon';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { getFeatureFlag } from '../Utils';
import {
  RatingContainer,
  RatingText,
  RatingTextLink,
  RatingsStar,
  VoiceOnlyText,
} from './Rating.styles';

type Props = {
  hideNumber?: boolean;
  outOf: number;
  rating?: number;
  size?: number;
  navigateToSnackDetails?: () => void;
  totalRatings?: number;
};

export const Rating = ({
  hideNumber,
  outOf,
  rating,
  size,
  navigateToSnackDetails,
  totalRatings,
}: Props) => {
  if (rating === undefined) {
    return null;
  }

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const decemberFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );
  const ratingText =
    totalRatings && decemberFlag ? `${rating} (${totalRatings})` : `${rating}`;

  const ratingTextComponent = decemberFlag ? (
    <RatingTextLink color="$accent1" fontWeight="$medium" variant="none">
      {ratingText}
    </RatingTextLink>
  ) : (
    <RatingText color="$accent1">{ratingText}</RatingText>
  );

  return (
    <RatingContainer
      alignContent="center"
      alignItems="center"
      data-auto-testid="rating-container"
      data-testid="rating-container"
      decemberFlag={decemberFlag}
      onClick={decemberFlag ? navigateToSnackDetails : null}
    >
      <VoiceOnlyText>
        {totalRatings && decemberFlag
          ? `${rating} stars out of ${totalRatings} ratings`
          : `${rating} stars`}
      </VoiceOnlyText>
      <Flex.Content aria-hidden="true">
        <RatingsStar alignContent="center" alignItems="center">
          {[...new Array(outOf)].map((item, index) => (
            <svg
              key={`rate-star-${index + 1}`}
              fill="none"
              height={size || '12'}
              viewBox="0 0 12 12"
              width={size || '12'}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d={[
                  'M5.80335',
                  '9.12541L9.13639',
                  '11.1458L8.25189',
                  '7.33791L11.1966',
                  '4.77582L7.31886',
                  '4.44541L5.80335',
                  '0.854156L4.28785',
                  '4.44541L0.410095',
                  '4.77582L3.35481',
                  '7.33791L2.47032',
                  '11.1458L5.80335',
                  '9.12541Z',
                ].join(' ')}
                fill={rating < index + 1 ? '' : '#F5B700'}
                stroke={rating < index + 1 ? '#000000' : '#D2800F'}
              />
            </svg>
          ))}
        </RatingsStar>
      </Flex.Content>
      <Flex.Content aria-hidden="true">
        {!hideNumber ? ratingTextComponent : null}
      </Flex.Content>
    </RatingContainer>
  );
};
