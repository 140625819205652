import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { PageSize, Pagination } from '@abyss/web/ui/Pagination';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../../../../common/AdobeTagging/adobeModalTrackEvent';
import { Constants } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DataCard } from '../../../../common/DataCard';
import { EAPCode } from '../../../../common/EAPCode';
import { MapDisplay } from '../../../../common/MapDisplay';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { getFeatureFlag } from '../../../../common/Utils';
import { convertProviderTypeToAdobeType } from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import { CompareProvider } from '../../../../models/Provider';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { Directions } from '../../../../models/RouteDirections';
import { getRoute } from '../../../../utils/map.utils';
import { ShareCompareButton } from '../ViewAll/ShareCompareButton';
import { AllPaginationProps } from './AllPaginationProps';
import { CompareDrawerProps } from './CompareDrawerProps';
import { DataCardList } from './DataCardList';
import { FiltersContainerDesktop } from './FiltersContainerDesktop';
import {
  EAPStyle,
  ListWrap,
  ListWrapColumn,
  MapDataPagination,
  MapViewWrap,
  MapviewWrapper,
  PaginationControl,
  PagingationWrapStyled,
  SidePaneCloseButton,
  SidePaneCloseButtonWrap,
  SkeletonWrapper,
} from './MapView.styled';
import { MobileMapFilter } from './MobileMapFilter';
import { Popup } from './Popup';

type Props = {
  allPaginationProps: AllPaginationProps;
  compareDrawerProps: CompareDrawerProps;
  headers: ResponseHeaders;
  isLoading?: boolean;
  openShare?: boolean;
  results: any[];
  sectionType?: string;
  selectedCheckbox?: any;
  totalResultsCount?: number;
  addSelectedProvider: (a: any) => void;
  setOpenMapview: (a: boolean) => void;
  setMobileRouteView: (a: boolean) => void;
  mobileRouteView: boolean;
  setOpenShare: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  search?: string;
  selectedFilters?: string;
  providerType?: string;
  searchTerm?: string;
  navigateToDirections?: boolean;
  setNavigateToDirections?: (a: boolean) => void;
  openMapview: boolean;
};

export const MapView = ({
  allPaginationProps,
  compareDrawerProps,
  headers,
  isLoading,
  openShare = false,
  results = [],
  sectionType = '',
  selectedCheckbox = { checked: {} },
  addSelectedProvider,
  setOpenMapview,
  setMobileRouteView,
  mobileRouteView,
  setOpenShare,
  setSelectedCheckbox,
  setSelectedItems,
  search,
  selectedFilters,
  providerType,
  searchTerm,
  navigateToDirections = false,
  setNavigateToDirections,
  openMapview = false,
}: Props) => {
  const {
    selectedItems = [],
    map,
    openCompare,
    setOpenCompare,
  } = compareDrawerProps;

  const listRef = useRef<HTMLDivElement>(null);
  const topPaginationRef = useRef<HTMLDivElement>(null);

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'overview',
    sitesectionLevel1: Constants.ADOBE_TRACKING.VIEWALL_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerType
    )} results`,
  });

  const {
    pageSize,
    pageSizeOptions,
    paginationProps,
    nextPage,
    gotoPage,
    previousPage,
    setPageSize,
    pageNumber,
  } = allPaginationProps;

  const mapViewViewAllLinkTrack = (name: String, loc: String) => {
    adobeLinkTrackEvent({
      name,
      location: loc,
      type: 'internal',
      destinationUrl: '',
    });
  };
  const nextPageTop = () => {
    mapViewViewAllLinkTrack(
      'next page',
      `body:top pagination control:page ${pageNumber}`
    );
    nextPage();
  };
  const nextPageBottom = () => {
    mapViewViewAllLinkTrack(
      'next page',
      `body:bottom pagination control:page ${pageNumber}`
    );
    nextPage();
    topPaginationRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  const gotoPageFunc = (page) => {
    mapViewViewAllLinkTrack(
      `page ${page + 1}`,
      `body:bottom pagination control:page ${pageNumber}`
    );
    gotoPage(page);
    topPaginationRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  const previousPageTop = () => {
    mapViewViewAllLinkTrack(
      'previous page',
      `body:top pagination control:page ${pageNumber}`
    );
    previousPage();
  };
  const previousPageBottom = () => {
    mapViewViewAllLinkTrack(
      'previous page',
      `body:bottom pagination control:page ${pageNumber}`
    );
    previousPage();
    topPaginationRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const setPageSizeFunc = (pageSizeValue) => {
    mapViewViewAllLinkTrack(
      `results per page:${pageSizeValue}`,
      `body:top pagination control:page ${pageNumber}`
    );
    setPageSize(pageSizeValue);
  };

  const [closeSidePanel, setCloseSidePanel] = useState<boolean>(false);
  const sidePanelArrowIcon = closeSidePanel
    ? 'keyboard_arrow_right'
    : 'keyboard_arrow_left';

  const [routeEndCoords, setRouteEndCoords] = useState<
    [number | null, number | null]
  >([null, null]);
  const [directions, setDirections] = useState<Directions>({
    userPlaceName: '',
    endPlaceName: '',
    routes: [],
  });

  const mobileScreen = useMediaQuery(phoneOnly);
  const dataCardMinHeight = mobileScreen ? '180px' : '196px';

  const isOpenCompareShare = openCompare || openShare;
  const { longitude, latitude } = getGeoLocationFromStorage();
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const decemberFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );

  const [popupContent, setPopupContent] = useState<any>('');

  const [selectedId, setSelectedId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );

  const [disableCost] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_COST,
  ]);

  useEffect(() => {
    setSelectedId(null);
  }, []);

  useEffect(() => {
    adobePageTrackEvent();
  }, [sectionType]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.scrollTop = 0;
    }
  }, [results]);

  const { t } = useTranslation();
  const pageHeader = `${t(`${sectionType}-results-for`)} ${search || ''}`;
  const toggleSidePanel = () => {
    const actionStr = closeSidePanel ? 'open' : 'close';
    adobeLinkTrackEvent({
      name: `sidepanel:${actionStr}`,
      location: `body:map`,
      type: 'internal',
    });

    setCloseSidePanel(!closeSidePanel);
  };

  const updatePin = (practitioner, flyToPin = false) => {
    const popup = document.getElementsByClassName('mapboxgl-popup');
    if (practitioner.providerId === popupContent?.providerId && popup.length) {
      return;
    }
    if (popup.length) {
      for (let i = 0; i < popup.length; i++) {
        popup[i].remove();
      }
    }
    setPopupContent({ ...practitioner });
    const dataCardHTML = document.querySelector(
      `[id^=data-card-for-map-${practitioner.providerId}]`
    );
    const height = (dataCardHTML as HTMLElement).offsetHeight || 198;
    const width = (dataCardHTML as HTMLElement).offsetWidth || 408;
    if (practitioner?.address?.line[0]) {
      new mapboxgl.Popup({
        closeButton: false,
      })
        .setLngLat([+practitioner.longitude, +practitioner.latitude])
        .setHTML(
          `<div class="datacard-popup" style="height:${height}px;width:${width}px;border:none;visibility:hidden;"></div>`
        )
        .addTo(map.current);
    } else {
      new mapboxgl.Popup({
        closeButton: false,
      })
        .setLngLat(map.current.getCenter())
        .setHTML(
          `<div class="datacard-popup" style="height:${height}px;width:${width}px;border:none;visibility:hidden;"></div>`
        )
        .addTo(map.current);
    }

    document.querySelectorAll('.mapboxgl-popup-tip').forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      (el as HTMLElement).style.display = 'none';
    });

    if (flyToPin) {
      map.current?.flyTo({
        center: [+practitioner.longitude, +practitioner.latitude],
        zoom: Constants.DEVICE_LOCATION.ZOOM_HOVER,
      });
    }
  };
  const finalProviderResults =
    selectedItems.length > 0 ? selectedItems : results;

  const shouldDisplayMap = map && latitude && longitude && results?.length;

  const handleClickLocation = async (locationId, locationLng, locationLat) => {
    if (selectedId === locationId && !mobileScreen) return;
    const routeDirections = await getRoute(
      map.current,
      longitude,
      latitude,
      +locationLng,
      +locationLat,
      false,
      'driving-traffic'
    );
    if (routeDirections) setDirections(routeDirections);
    setRouteEndCoords([locationLng, locationLat]);
    setSelectedId(locationId);
    adobeModalTrackEvent(Constants.MAP_VIEW_NAVIGATION_MODAL);
  };

  useEffect(() => {
    if (mobileScreen) setMobileRouteView(!!routeEndCoords[0]);
  }, [routeEndCoords, mobileScreen]);

  return (
    <React.Fragment>
      <Layout.Stack
        alignItems="left"
        css={{ '@screen < $sm': { display: 'none' } }}
        grow="true"
      >
        <Heading
          css={{
            'abyss-heading-root': {
              '@screen < $md': {
                fontSize: '$mediumText !important',
                lineHeight: '$lg !important',
              },
            },
          }}
          data-auto-testid="results-heading"
          data-testid="results-heading"
          display="h4"
          offset={1}
          textAlign="left"
        >
          {pageHeader}
        </Heading>
        <FiltersContainerDesktop sectionType={sectionType} />
      </Layout.Stack>
      {decemberFlag ? (
        <EAPStyle>
          <EAPCode />
        </EAPStyle>
      ) : null}
      {!mobileScreen ? (
        <PagingationWrapStyled ref={topPaginationRef}>
          <PaginationControl
            className="hide-pagination"
            css={{ paddingBottom: '4px' }}
            data-testid="pagination-results-container"
          >
            <PageSize
              css={{
                'abyss-pagination-page-size-label': {
                  fontWeight: '$semibold',
                },
              }}
              data-testid="pagination-page-size"
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              setPageSize={setPageSizeFunc}
            />
            <Pagination
              {...paginationProps}
              nextPage={nextPageTop}
              previousPage={previousPageTop}
              variant="compact"
            />
          </PaginationControl>
          <Flex>
            <Layout.Group space="15">
              <Layout.Group space="15">
                <React.Fragment>
                  {sectionType === Constants.RESULT_SECTION.PROVIDER && (
                    <ShareCompareButton
                      isShareButton={false}
                      openCompare={openCompare}
                      sectionType={sectionType}
                      setOpenCompare={setOpenCompare}
                      setOpenShare={setOpenShare}
                      setSelectedCheckbox={setSelectedCheckbox}
                      setSelectedItems={setSelectedItems}
                      text={t('COMPARE_BUTTONS.COMPARE')}
                      view="desktop"
                    />
                  )}
                  <ShareCompareButton
                    openShare={openShare}
                    sectionType={sectionType}
                    setOpenCompare={setOpenCompare}
                    setOpenShare={setOpenShare}
                    setSelectedCheckbox={setSelectedCheckbox}
                    setSelectedItems={setSelectedItems}
                    text={t('COMPARE_BUTTONS.SHARE')}
                    view="desktop"
                  />
                </React.Fragment>
              </Layout.Group>
            </Layout.Group>
          </Flex>
        </PagingationWrapStyled>
      ) : null}
      <MapviewWrapper
        cssProps={{
          isOpenCompareShare,
          openMapview,
          navigateToDirections,
        }}
      >
        <Layout.Stack>
          {!closeSidePanel ? (
            <React.Fragment>
              <ListWrapColumn cssProps={{ isOpenCompareShare }}>
                <ListWrap ref={listRef}>
                  {isLoading ? (
                    <SkeletonWrapper>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                        <DataCard key={item} isLoading={isLoading} />
                      ))}
                    </SkeletonWrapper>
                  ) : (
                    !mobileRouteView && (
                      <DataCardList
                        addSelectedProvider={addSelectedProvider}
                        DataCardStyle={{
                          width: '408px',
                          minHeight: dataCardMinHeight,
                        }}
                        headers={headers}
                        isMobileMap={mobileScreen}
                        locationForAnalytics={`${sectionType} results for ${search}`}
                        map={map}
                        navigateToDirections={navigateToDirections}
                        pageNumber={pageNumber}
                        parentRef={listRef}
                        providerResults={results}
                        searchTerm={searchTerm}
                        sectionType={sectionType}
                        selectedCheckbox={selectedCheckbox}
                        selectedFilters={selectedFilters}
                        selectLocation={handleClickLocation}
                        setNavigateToDirections={setNavigateToDirections}
                        setRouteEndCoords={setRouteEndCoords}
                        setSelectedCheckbox={setSelectedCheckbox}
                        showCheckboxes={isOpenCompareShare}
                        updatePin={updatePin}
                      />
                    )
                  )}
                </ListWrap>
                {!mobileScreen ? (
                  <Flex>
                    <Flex
                      alignItems="center"
                      css={{ gap: '21px', marginTop: '16px', width: '408px' }}
                      direction="column"
                    >
                      <MapDataPagination
                        {...paginationProps}
                        data-auto-testid="pagination-component"
                        data-testid="pagination-component"
                        gotoPage={gotoPageFunc}
                        nextPage={nextPageBottom}
                        pageSize={pageSize}
                        previousPage={previousPageBottom}
                      />
                    </Flex>
                  </Flex>
                ) : null}
              </ListWrapColumn>
            </React.Fragment>
          ) : null}
        </Layout.Stack>
        <MapViewWrap>
          <SidePaneCloseButtonWrap>
            <SidePaneCloseButton
              alignItems="center"
              className="side-btn"
              justify="center"
            >
              <IconMaterial
                color="#323334"
                css={{
                  padding: '3px 0px 0px 1px',
                }}
                data-auto-testid="sidepanel-close-icon"
                data-testid="sidepanel-close-icon"
                icon={sidePanelArrowIcon}
                onClick={toggleSidePanel}
                size="$md"
              />
            </SidePaneCloseButton>
          </SidePaneCloseButtonWrap>
          {shouldDisplayMap ? (
            <MapDisplay
              closeSidePanel={closeSidePanel}
              coords={{ latitude, longitude }}
              directions={directions}
              disableCost={disableCost}
              headers={headers}
              isOpenCompareShare={isOpenCompareShare}
              map={map}
              mobileMapControlChildren={
                <MobileMapFilter setOpenMapview={setOpenMapview} />
              }
              mobileRouteView={mobileRouteView}
              providerResults={finalProviderResults}
              routeEndCoords={routeEndCoords}
              selectedItems={selectedItems}
              selectLocation={handleClickLocation}
              setPopupContent={setPopupContent}
              setRouteEndCoords={setRouteEndCoords}
              updatePin={updatePin}
            />
          ) : null}
        </MapViewWrap>
      </MapviewWrapper>
      <Popup
        disableCost={disableCost}
        headers={headers}
        locationForAnalytics={`map:${sectionType} results for ${search}`}
        pageNumber={pageNumber}
        practitioner={popupContent}
        selectedFilters={selectedFilters}
        selectLocation={handleClickLocation}
      />
    </React.Fragment>
  );
};
