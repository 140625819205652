import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import React, { useEffect, useState } from 'react';

import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useLagoon } from '../../hooks/useLagoon';
import { Provider } from '../../models/Provider';
import { ProviderType } from '../../models/ProviderDetails';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { DataCard } from '../DataCard';
import { NationalGroupProvidersVirtualSnackCard } from '../NationalGroupProvidersVirtualBiteCard/NationalGroupProvidersVirtualSnackCard';
import {
  mixedResultsSlideStyle,
  slideContainerStyle,
  slideStyle,
} from '../SnackCard/SnackCard.styles';
import { getFeatureFlag } from '../Utils';
import { DataCardStyle, mixedResultCarouselStyle } from './ResultSection.style';

/* eslint-disable no-nested-ternary */

type Props = {
  headers: ResponseHeaders;
  results: Provider[];
  sectionType: string;
  isLoading: boolean;
  searchTerm: string;
};

export const MixedResultCards = ({
  headers,
  results,
  sectionType,
  isLoading,
  searchTerm,
}: Props) => {
  const result = results?.slice(
    0,
    Constants.RESULT_SECTION.MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN
  );
  const mobileScreen = useMediaQuery(phoneOnly);
  const [disableCost] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_COST,
  ]);

  const [slidesPerView, setSlidesPerView] = useState(2);
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  useEffect(() => {
    let windowWidth = window.innerWidth;
    function handleResize() {
      windowWidth = window.innerWidth;
      setWinWidth(windowWidth);
    }
    window.addEventListener('resize', handleResize);
    if (winWidth > 872) {
      if (winWidth > 872 && result?.length >= 3) setSlidesPerView(2);
      else if (winWidth < 1250 && result?.length === 2) setSlidesPerView(3);
      else if (winWidth < 1250 && result?.length > 2) setSlidesPerView(2);
      else if (winWidth > 1250 && result?.length >= 2) setSlidesPerView(3);
      else if (result?.length >= 1) setSlidesPerView(4);
    }
    if (winWidth < 872) setSlidesPerView(1);
  });
  const featureTierOneFlags = useLagoon('feature-flags')();
  const decemberFlag: boolean = getFeatureFlag(
    featureTierOneFlags,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );

  const displayVirtualProvider = (provider: Provider) =>
    decemberFlag &&
    !provider?.address &&
    provider?.isVirtualCareOffered &&
    (provider.providerType === ProviderType.PRACTITIONER ||
      provider.providerType === ProviderType.PROVIDER_GROUP);

  const isHorizontalDisplay = result?.some((e) => e.isTieredProvider === true);

  const skeletonSlides = [1, 2, 3].map((index) => (
    <Slide css={slideStyle}>
      <Slide.Container css={slideContainerStyle(decemberFlag)}>
        <DataCard
          css={DataCardStyle}
          index={index}
          isLoading
          searchTerm={searchTerm}
          sectionType={sectionType}
        />
      </Slide.Container>
    </Slide>
  ));

  const slides = result?.map((res, i) => (
    <Slide css={mixedResultsSlideStyle(i, result?.length)}>
      <Slide.Container css={slideContainerStyle(decemberFlag)}>
        {!displayVirtualProvider(res) && (
          <DataCard
            css={DataCardStyle}
            disableCost={disableCost}
            headers={headers}
            index={result.indexOf(res)}
            isHorizontalDisplay={isHorizontalDisplay}
            isLoading={false}
            locationForAnalytics={`${sectionType} results`}
            practitioner={res}
            searchTerm={searchTerm}
            sectionType={sectionType}
          />
        )}
        {displayVirtualProvider(res) && (
          <NationalGroupProvidersVirtualSnackCard
            css={DataCardStyle}
            practitioner={res}
            sectionType={sectionType}
          />
        )}
      </Slide.Container>
    </Slide>
  ));

  useEffect(() => {
    if (
      decemberFlag &&
      results &&
      sectionType === Constants.RESULT_SECTION.PROVIDER
    ) {
      const provider = results[0];
      provider.address = undefined;
      provider.isVirtualCareOffered = true;
    }
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        mobileScreen ? (
          <DataCard key={1} css={DataCardStyle} isLoading />
        ) : (
          <Carousel
            css={mixedResultCarouselStyle(result?.length, winWidth)}
            data-auto-testid="mixed-results-carousel-skeleton-loader"
            data-testid="mixed-results-carousel-skeleton-loader"
            minimal
            noLoop
            slides={skeletonSlides}
            slidesPerView={slidesPerView}
          />
        )
      ) : (
        <Carousel
          css={mixedResultCarouselStyle(result?.length, winWidth)}
          data-auto-testid="mixed-results-carousel"
          data-testid="mixed-results-carousel"
          minimal
          noLoop
          slides={slides}
          slidesPerView={slidesPerView}
        />
      )}
    </React.Fragment>
  );
};
