import { APIConstants } from 'api/src/services/Constants';
import { SHA256, enc } from 'crypto-js';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import validUrl from 'valid-url';

import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../../AdobeTagging/adobeModalTrackEvent';
import { adobeSearchResultClickEvent } from '../../AdobeTagging/adobeSearchResultClickEvent';
import {
  Constants,
  ProviderTypes,
  TypeaheadProviderTypes,
} from '../../Constants';

/* eslint-disable @typescript-eslint/no-unused-expressions */

export type CustomAttributesBlock = {
  correlationId: string;
  providerId: string;
  providerType: string;
};

export const handleLinkAndModalTrack = (
  linkName: string,
  linkLocation: string,
  modalName: string,
  linkType?: string,
  customAttributesBlock?: CustomAttributesBlock
) => {
  adobeLinkTrackEvent({
    name: linkName,
    location: linkLocation,
    type: linkType || 'internal',
    customAttributesBlock,
  });
  adobeModalTrackEvent(modalName);
};

export function getUrlPathForAnalytics() {
  const urlPath = window?.location?.pathname?.split('/');
  return urlPath?.slice(0, -1)?.join('/') || '';
}

export const getFormattedFilters = (selectedFilters) => {
  if (isEmpty(selectedFilters)) return '';

  const keys = Object.keys(selectedFilters);
  const formattedFilters = keys?.map((key) => {
    const filterValues = selectedFilters[key];
    if (Array.isArray(filterValues) && filterValues?.length) {
      return `${key?.toLowerCase()}:${filterValues?.map((val) =>
        val?.dnt_label?.toLowerCase()
      )}`;
    }
    if (isObject(filterValues)) {
      return filterValues?.dnt_label === undefined
        ? ''
        : `${key?.toLowerCase()}:${filterValues?.dnt_label?.toLowerCase()}`;
    }
    return '';
  });
  return formattedFilters ? formattedFilters.join('|') : '';
};

export const handleAdobeSearchResultClickAndModalEvent = (input) => {
  adobeSearchResultClickEvent({
    filters: input.filters,
    linkName: input.linkName,
    linkPosition: input.linkPosition,
    linkLocation: input.linkLocation,
    customAttributesBlock: input.customAttributesBlock,
    term: input.term,
  });
  adobeModalTrackEvent(input.modalName);
};

function internalConvertProviderTypeToAdobeType(internalProviderType) {
  if (
    internalProviderType?.toLowerCase() ===
    APIConstants.PROVIDER_TYPES.practitioner.toLowerCase()
  )
    return 'provider';
  if (
    internalProviderType?.toLowerCase() ===
      APIConstants.PROVIDER_TYPES.organization.toLowerCase() ||
    internalProviderType?.toLowerCase() ===
      ProviderTypes?.FACILITY.toLowerCase() ||
    internalProviderType?.toLowerCase() ===
      TypeaheadProviderTypes?.MEDICAL_HEALTH_SUPPLIES.toLowerCase()
  )
    return 'facilities';
  if (
    internalProviderType?.toLowerCase() ===
      APIConstants.PROVIDER_TYPES.medical_group.toLowerCase() ||
    internalProviderType?.toLowerCase() ===
      ProviderTypes?.MEDICALGROUP.toLowerCase() ||
    internalProviderType?.toLowerCase() === ProviderTypes?.GROUP.toLowerCase()
  )
    return 'medical group';

  return undefined;
}

export function convertProviderTypeToAdobeType(internalProviderType) {
  const name = internalConvertProviderTypeToAdobeType(internalProviderType);
  return name || internalProviderType?.toLowerCase();
}

export function getDisplayedProviderCardsCount(count, results) {
  const maxResultsToDisplay =
    Constants.RESULT_SECTION.MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN;
  if (results > maxResultsToDisplay) {
    return count + maxResultsToDisplay;
  }
  if (results > 0 && results <= maxResultsToDisplay) {
    return count + results;
  }
  // return the displayed result count in mixedResultCards screen for analytics tracking
  return count;
}

export const isExternalDomain = (urlLink: string): boolean => {
  if (validUrl.isUri(urlLink)) {
    const internalHostName: string = window.location.hostname;
    const { protocol: linkProtocols, hostname: linkHostName } = new URL(
      urlLink
    );
    return (
      !(linkProtocols === 'https:' || linkProtocols === 'http:') ||
      internalHostName !== linkHostName
    );
  }
  return false;
};

export const convertSpecialtiesList = (details: string[]): string => {
  let specialtyList = '';
  const specialties = details;
  for (let i = 0; i < specialties?.length; i++) {
    specialtyList += specialties[i].toLowerCase().replaceAll('-', ' ');
    i === specialties?.length - 1 ? null : (specialtyList += '|');
  }
  return specialtyList;
};

export const getNameForLinkTrack = (
  searchName: string | undefined,
  providerType: string | undefined
) => {
  const linkName = internalConvertProviderTypeToAdobeType(providerType);
  return linkName ? `${linkName} name` : searchName;
};

export const getTypeAheadCategory = (providerType: ProviderTypes) =>
  providerType ? 'providers' : 'specialty categories';

export const convertCoverageType = (coverageType: string): string => {
  if (coverageType === 'B') return 'behavioral';

  return 'medical';
};

export const formatProviderId = (provider) => {
  const { tin = '', providerId = '', locationId = '', npi = '' } = provider;

  const formattedNpi = npi || 'NoNPI';

  const defaultTin = tin || locationId.split('-')[1];

  const formattedTin = !Number.isNaN(+defaultTin)
    ? SHA256(defaultTin).toString(enc.Hex)
    : 'NoTin';

  const addressId = locationId.split('-')[3];

  return `${providerId}:${formattedNpi}:${formattedTin}:${addressId}`;
};

export const convertTypeaheadProviderIdAndType = (typeaheadData) => {
  if (!typeaheadData.providerType) {
    const slicedRollupCodes =
      typeaheadData.vertical?.code?.split(',').slice(0, 10).join() || undefined;
    return {
      providerId: `${typeaheadData.suggestion}:${slicedRollupCodes}`,
      providerType: 'speciality category',
    };
  }
  return {
    providerId: formatProviderId(typeaheadData),
    providerType: internalConvertProviderTypeToAdobeType(
      typeaheadData.providerType
    ),
  };
};
