import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PSXIcon } from '../../PSXIcon';
import { InnerContainer, TierProvider } from './ProviderLocationsDetails.style';

type Props = {
  isTieredProvider: boolean;
};

export const TieredProvider = ({ isTieredProvider }: Props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {isTieredProvider ? (
        <InnerContainer>
          <TierProvider>
            <Layout.Group css={{ gap: '4px' }}>
              <PSXIcon icon="Group 204976975" size={24} />
              <Text
                color="$neutralGray"
                css={{ 'abyss-text-root': { lineHeight: '18px' } }}
                fontWeight="$bold"
                size="$sm"
              >
                {t('PROVIDER_LOCATIONS_DETAILS.TIER_1_PROVIDER')}
              </Text>
            </Layout.Group>
          </TierProvider>
        </InnerContainer>
      ) : null}
    </React.Fragment>
  );
};
