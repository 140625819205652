import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../../models/ProviderDetails';
import {
  capitalizeEachWord,
  getSubText,
} from '../../../utils/providerDetails.utils';
import { CopyToClipBoard } from './CopyToClipBoard';
import { InfoProviderContent } from './InfoProviderContent';
import {
  FlexContainer,
  IconAlignmentContainer,
  InfoContainer,
  InfoProviderContentContainer,
  OtheInfoContainer,
  ProviderGroupContainer,
} from './ProviderLocationsDetails.style';
import { SupressEmailWeb } from './SupressEmailWeb';

type Props = {
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
  // eslint-disable-next-line react/no-unused-prop-types
  detailType: String;
  providerDetails?: ProviderDetails;
  // eslint-disable-next-line react/no-unused-prop-types
  facilityDetails?: FacilityDetails;
  // eslint-disable-next-line react/no-unused-prop-types
  isFromVirtualProviderGroup?: boolean;
  practiceLimitation?: string;
  inNetworkStartDate?: string;
};

export const VirtualProviderGroupAllOtherInfo = ({
  selectedLocation,
  providerDetails,
  practiceLimitation,
  inNetworkStartDate,
}: Props) => {
  const { t } = useTranslation();
  return (
    <InfoProviderContentContainer>
      <SupressEmailWeb selectedLocation={selectedLocation} />
      <InfoProviderContent
        icon="videocam"
        infoSubtext={t('VIRTUAL_GROUP_PROVIDER_ABOUT.VIRTUAL_VISITS_SUBTEXT')}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.VIRTUAL_VISITS_ONLY')}
      />

      <InfoProviderContent
        icon="date_range"
        infoSubtext=""
        infoTitle={t(
          'VIRTUAL_GROUP_PROVIDER_ABOUT.OFFERS_EVENING_WEEKENDS_APPOINTMENT'
        )}
        isFromVirtualProviderGroup
      />
      <InfoProviderContent
        icon="accessibility"
        infoSubtext={selectedLocation.accessibility?.join(', ')}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.ACCESSIBILITY')}
      />
      <InfoProviderContent
        icon="translate"
        infoSubtext={selectedLocation.languagesSpokenByOffice?.join(', ')}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.LANGUAGES_SPOKEN_BY_OFFICE')}
      />
      <InfoProviderContent
        icon="local_hospital"
        infoSubtext={getSubText(selectedLocation.hasPCPServiceAvailability)}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.PCP_SERVICES_AVAILABLE')}
        variant="outlined"
      />
      {providerDetails?.providerType === ProviderType.PRACTITIONER ? (
        <FlexContainer
          css={{
            marginBottom: '3px',
            '@screen < $sm': { marginBottom: '0' },
          }}
        >
          <IconAlignmentContainer>
            <IconMaterial
              color="$primary1"
              css={{ marginTop: '3px' }}
              icon="group"
              size={18}
            />
            <OtheInfoContainer>
              {t('VIRTUAL_GROUP_PROVIDER_ABOUT.PROVIDER_GROUP')}
              {selectedLocation?.providerGroupsAffiliations?.length > 0 ? (
                selectedLocation?.providerGroupsAffiliations?.map((group) => (
                  <ProviderGroupContainer>
                    {capitalizeEachWord(group.value)}
                  </ProviderGroupContainer>
                ))
              ) : (
                <InfoContainer>
                  {t('VIRTUAL_GROUP_PROVIDER_ABOUT.NOT_AVAILABLE')}
                </InfoContainer>
              )}
            </OtheInfoContainer>
          </IconAlignmentContainer>
        </FlexContainer>
      ) : null}

      <InfoProviderContent
        icon="pan_tool"
        infoSubtext={
          practiceLimitation.trim().length > 0
            ? practiceLimitation
            : t('VIRTUAL_GROUP_PROVIDER_ABOUT.NOT_AVAILABLE')
        }
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.PRACTICE_LIMITATIONS')}
      />

      <InfoProviderContent
        icon="school"
        infoSubtext={inNetworkStartDate}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.CULTURAL_COMPETENCE')}
        variant="outlined"
      />

      <InfoProviderContent
        icon="verified"
        infoSubtext={t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.LICENSE_NUMBER')}
      />

      <InfoProviderContent
        icon="numbers"
        infoSubtext={t(
          'VIRTUAL_GROUP_PROVIDER_ABOUT.NATIONAL_PROVIDER_SUBTEXT'
        )}
        infoTitle={t(
          'VIRTUAL_GROUP_PROVIDER_ABOUT.NATIONAL_PROVIDER_IDENTIFIER'
        )}
      >
        <Flex css={{ gap: '$xs' }}>
          <InfoContainer>
            {t('VIRTUAL_GROUP_PROVIDER_ABOUT.NATIONAL_PROVIDER_SUBTEXT')}
          </InfoContainer>
          <CopyToClipBoard text={selectedLocation?.locationId} />
        </Flex>
      </InfoProviderContent>

      <InfoProviderContent
        icon="person_pin"
        infoSubtext={selectedLocation.locationId}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.PROVIDER_ID')}
        variant="outlined"
      >
        <Flex css={{ gap: '$xs' }}>
          <InfoContainer>
            {t('VIRTUAL_GROUP_PROVIDER_ABOUT.PROVIDER_SUBTEXT')}
          </InfoContainer>
          <CopyToClipBoard text={selectedLocation?.locationId} />
        </Flex>
      </InfoProviderContent>
    </InfoProviderContentContainer>
  );
};
