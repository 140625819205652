import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Link } from '@abyss/web/ui/Link';

export const BoxStyled = styled(Box, {
  dynamic: ({ cssProps: { selected } }) => ({
    backgroundColor: 'transparent',
    border: selected ? '1px solid #E5E5E6' : '2px dashed #929496',
    boxShadow: selected ? '0px 2px 6px rgba(25, 25, 26, 0.16)' : '',
    minWidth: '262px',
    height: '96px',
    borderRadius: '8px',
    justifyContent: selected ? 'left' : 'center',
    alignItems: 'center',
    display: 'flex',
    marginBottom: '10px',
    marginTop: selected ? '38px' : '',
  }),
});

export const ShareBoxStyledPlaceHolders = {
  backgroundColor: 'transparent',
  border: '2px dashed #929496',
  minWidth: '262px',
  height: '96px !important',
  borderRadius: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  marginBottom: '10px',
};

export const slideContainerStyle = {
  'abyss-slide-content-wrapper': {
    padding: '0',
    visibility: 'visible',
    '>div': {
      height: '100%',
    },
  },
};

export const carouselStyles = {
  'abyss-carousel-root': {
    width: '100%',
  },
  'abyss-carousel-swiper-container': {
    display: 'flex',
    willChange: 'transform',
  },
  'abyss-carousel-slide-container': {
    position: 'relative',
    width: '277px',
    marginLeft: '20px',
  },
  'abyss-carousel-minimal-controls': {
    marginTop: '12px',
    marginBottom: '12px',
    padding: '0px',
    height: '20px',
    'li.abyss-carousel-minimal-pagination-container': {
      padding: ' 0 10px 0 10px',
    },
  },
  'abyss-carousel-minimal-nav-icon-right': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-nav-container-right': {
    width: '20px',
    height: '20px',
    padding: '0px',
  },
  'abyss-carousel-minimal-nav-container-left': {
    width: '20px',
    height: '20px',
    padding: '0px',
  },
  'abyss-carousel-minimal-nav-icon-left': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-pagination-container': {
    padding: '0 19px 0 19px',
  },
};

export const LinkTextMobile = styled(Link, {
  '&.abyss-link-root': {
    marginBottom: '24px',
    fontSize: '14.22px',
  },
});

export const HighlightedBorderStyle = { border: '2px solid #D0F1F5' };

export const ShareBoxStyledMobile = {
  'abyss-box-root': {
    backgroundColor: 'transparent',
    border: '2px dashed #929496',
    minWidth: '146px',
    height: '136px',
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
};

export const ShareBoxStyledSelectedMobile = {
  'abyss-box-root': {
    backgroundColor: 'transparent',
    border: '1px solid #E5E5E6',
    boxShadow: '0px 2px 6px rgba(25, 25, 26, 0.16)',
    minWidth: '146px',
    height: '136px',
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
};

export const ShareButtonStyle = {
  'abyss-button-root': {
    width: '146px',
    padding: '8px 28px',
    marginLeft: '30px',
    backgroundColor: '$primary1',
    minWidth: '343px',
    height: '42px',
    borderRadius: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginRight: '30px',
  },
};

export const ShareButtonStyleNotSelected = {
  'abyss-button-root': {
    width: '146px',
    padding: '8px 28px',
    marginLeft: '30px',
    backgroundColor: '$gray2',
    minWidth: '343px',
    height: '42px',
    borderRadius: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginRight: '30px',
  },
};

export const ShareContainer = styled('div', {
  paddingRight: '25px',
});

export const ShareContainerMobile = styled('div', {
  paddingRight: '17px',
});

export const CardContainerMobile = styled('div', {
  marginLeft: '16px',
  overflow: 'auto hidden',
  alignItems: 'flex-start',
  marginTop: '16px',
  display: 'flex',
  gap: '8px',
});

export const ShareBoxText = {
  'abyss-text-root': {
    fontSize: '$md',
    paddingLeft: '5px',
    fontWeight: '$bold',
    lineHeight: '18px',
    textAlign: 'center',
    color: '$accent1',
  },
};

export const ShareBoxTextName = {
  'abyss-text-root': {
    display: 'inherit',
    textAlign: 'left',
    fontSize: '$md',
    fontWeight: '$bold',
    lineHeight: '18px',
    color: '$accent1',
    marginBottom: '10px',
    paddingLeft: '5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '164px',
  },
};

export const ShareBoxTextNameMobile = {
  'abyss-text-root': {
    display: 'inherit',
    fontSize: '14.22px',
    paddingLeft: '5px',
    fontWeight: '$bold',
    lineHeight: '18px',
    textAlign: 'center',
    color: '$accent1',
    marginTop: '5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '112px',
  },
};

export const ShareBoxTextAddress = {
  'abyss-text-root': {
    display: 'inherit',
    textAlign: 'left',
    fontSize: '$sm',
    fontWeight: '$semibold',
    lineHeight: '26px',
    color: '$accent1',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '134px',
  },
};

export const ShareBoxTextAddressMobile = {
  'abyss-text-root': {
    display: 'inherit',
    textAlign: 'left',
    width: '92px',
    fontSize: '$sm',
    fontWeight: '$semibold',
    lineHeight: '18px',
    color: '$accent1',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

export const LocationIcon = {
  'abyss-icon': {
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
};

export const LocationIconMobile = {
  'abyss-icon': {
    width: '18px',
    height: '18px',
    marginRight: '2px',
  },
};

export const ShareBoxTextMobile = {
  'abyss-text-root': {
    fontSize: '14.22px',
    paddingLeft: '5px',
    fontWeight: '$bold',
    lineHeight: '18px',
    textAlign: 'center',
    color: '$accent1',
    marginTop: '5px',
  },
};

export const ButtonCss = {
  'abyss-layout-group': {
    alignContent: 'center',
    justifyContent: 'center',
  },
};

export const addressFlexCss = {
  'abyss-flex-root': {
    alignItems: 'center',
    paddingLeft: '5px',
  },
};

export const RemoveButtonText = {
  'abyss-text-root': {
    color: '$accent1',
    fontWeight: '$bold',
    fontSize: '12.64px',
    lineHeight: '16px',
    marginLeft: '5px',
  },
};

export const RemoveButtonIcon = {
  'abyss-icon': {
    width: '15px',
    height: '15px',
  },
};

export const ShareAllResultsLink = {
  'abyss-link-root': {
    fontSize: '14.22px',
    fontWeight: '$semibold',
    lineHeight: '16px',
  },
};

export const PlaceHolderText = {
  'abyss-text-root': {
    fontSize: '$md',
    alignContent: 'center',
    justifyContent: 'center',
    fontWeight: '$medium',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#6E7072',
  },
};

export const PlaceHolderTextMobile = {
  'abyss-text-root': {
    fontSize: '$md',
    alignContent: 'center',
    justifyContent: 'center',
    fontWeight: '$medium',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#6E7072',
  },
};

export const AvatarShareCard = styled('div', {
  width: '64px',
  height: '64px',
  minWidth: '64px',
  minHeight: '64px',
  borderRadius: '50%',
  overflow: 'hidden',
});
