/* eslint-disable no-param-reassign */
import cloneDeep from 'lodash/cloneDeep';

import { getNamesToCheck } from '../../utility/getNamesToCheck';

export const editCompareButtonRules = (
  items,
  setChangedTempItems,
  setOpenEdit,
  setNumChecked,
  nameOfButtonClickedLast,
  setNameOfButtonClickedLast,
  itemsToShowInCheckboxes,
  form
) => {
  if (nameOfButtonClickedLast === 'apply') {
    items.map((checkbox) => {
      const tempCheckbox = checkbox;
      const changedCheckBox = itemsToShowInCheckboxes.find(
        (e) => e.name === checkbox.name
      );
      if (changedCheckBox) tempCheckbox.isChecked = changedCheckBox.isChecked;
      return tempCheckbox;
    });
    setChangedTempItems(itemsToShowInCheckboxes);
    setOpenEdit(false);
  }
  if (nameOfButtonClickedLast === 'cancel') {
    itemsToShowInCheckboxes = cloneDeep(items);
    form.reset({
      'checkbox-form': getNamesToCheck(itemsToShowInCheckboxes),
    });
  }
  if (nameOfButtonClickedLast === 'deselect-all') {
    form.reset({
      'checkbox-form': [],
    });
    itemsToShowInCheckboxes.map((checkbox) => {
      const tempCheckbox = checkbox;
      tempCheckbox.isChecked = false;
      return tempCheckbox;
    });
    setChangedTempItems(itemsToShowInCheckboxes);
    setNumChecked(0);
  }
  if (nameOfButtonClickedLast === 'reset') {
    form.reset({
      'checkbox-form': [
        'reviews',
        'specialties',
        'distance',
        'education',
        'tierOneProvider',
      ],
    });
    itemsToShowInCheckboxes.map((checkbox) => {
      const tempCheckbox = checkbox;
      if (
        tempCheckbox.name === 'reviews' ||
        tempCheckbox.name === 'specialties' ||
        tempCheckbox.name === 'distance' ||
        tempCheckbox.name === 'education' ||
        tempCheckbox.name === 'tierOneProvider'
      ) {
        tempCheckbox.isChecked = true;
      } else {
        tempCheckbox.isChecked = false;
      }
      return tempCheckbox;
    });
    setChangedTempItems(itemsToShowInCheckboxes);
    setNumChecked(5);
  }
  setNameOfButtonClickedLast('');
};
