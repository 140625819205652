import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Badge } from '@abyss/web/ui/Badge';
import { Heading } from '@abyss/web/ui/Heading';
import { Link } from '@abyss/web/ui/Link';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCoverageType } from '../../../hooks/useCoverageType';
import { useLagoon } from '../../../hooks/useLagoon';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../models/FacilityDetails';
import { Constants, ReverseCoverageTypesCodes } from '../../Constants';
import {
  PlatinumBadgeStyle,
  h2SmallerFontForResponsive,
  mobileOnly,
} from '../../ConstantsStyles';
import { convertProviderTypeToAdobeType } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  AccreditationTextStyle,
  LeapFrogContainer,
  LeapFrogTextStyle,
  SectionContainer,
  TabContainer,
  TextStyle,
} from './FacilityQuality.styled';
import { LeapFrogRating } from './LeapFrogRating/LeapFrogRating';

type Props = {
  facilityDetails: FacilityDetails;
  selectedLocationIndex?: number;
  tabTitle?: string;
};

export const FacilityQuality = ({
  facilityDetails,
  selectedLocationIndex = 0,
  tabTitle,
}: Props) => {
  const [qualityTabText, setQualityTabText] = useState<string>('');
  const patientSafetyText = useLagoon('facility-detail-quality-tab')();
  const mobileScreen = useMediaQuery(mobileOnly);
  const coverageType = useCoverageType();

  const selectedLocation: FacilityLocationsResponse =
    facilityDetails?.providerLocations?.[selectedLocationIndex];

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      facilityDetails.providerType
    )} details`,
  });

  useEffect(() => {
    if (facilityDetails.providerName) adobePageTrackEvent();
  }, [facilityDetails]);

  useEffect(() => {
    if (patientSafetyText) {
      const FACILITY_QUALITY_TAB = patientSafetyText.find(
        (x) => x.key === 'FACILITY_QUALITY_TAB'
      );
      setQualityTabText(FACILITY_QUALITY_TAB?.patientSafetyRating);
    }
  }, [patientSafetyText]);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
      selectedLocation?.isPlatinumProvider ? (
        <SectionContainer>
          <TabContainer>
            <Badge css={PlatinumBadgeStyle} variant="neutral">
              {t('PROVIDER_DETAILS.ABOUT_TAB.PLATINUM_PROVIDER')}
            </Badge>
          </TabContainer>
        </SectionContainer>
      ) : null}
      {facilityDetails?.providerLocations?.[selectedLocationIndex]
        ?.leapFrogRatings?.length > 0 ? (
        <React.Fragment>
          <SectionContainer>
            <TabContainer key={Constants.PROVIDER_DETAILS.QUALITY}>
              <Heading
                css={{
                  paddingBottom: '12px',
                  ...h2SmallerFontForResponsive,
                }}
                display="h4"
                offset={1}
              >
                {t('FACILITY_DETAILS.QUALITY_TAB.PATIENT_SAFETY')}
              </Heading>
              <Text
                css={TextStyle}
                data-auto-testid="facility-quality-tab-patient-safety-ratings"
                data-testid="facility-quality-tab-patient-safety-ratings"
              >
                {t(qualityTabText)}
                <Link
                  css={{
                    'abyss-link-root': {
                      display: 'inline',
                      fontSize: '$lg',
                    },
                  }}
                  data-auto-testid="my-benefits-get-a-ride"
                  data-testid="my-benefits-get-a-ride"
                  href="https://www.leapfroggroup.org/ratings-reports"
                >
                  <Text color="$info1" css={TextStyle}>
                    {' '}
                    {t('FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_GROUP')}
                  </Text>
                </Link>
              </Text>
            </TabContainer>
          </SectionContainer>

          <SectionContainer>
            <LeapFrogRating
              facilityDetails={facilityDetails}
              selectedLocationIndex={selectedLocationIndex}
            />
          </SectionContainer>

          <SectionContainer>
            <LeapFrogContainer>
              <Heading
                css={{
                  '&.abyss-heading-root': {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px !important',
                    lineHeight: '20px !important',
                    color: '$interactive1 !important',
                    '@screen < $sm': {
                      fontSize: '12.64px !important',
                    },
                  },
                }}
                display="h6"
                offset={2}
              >
                {t('FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_RATING_INFORMATION')}
                <React.Fragment>
                  <Popover
                    content={t(
                      'FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_RATING_INFORMATION_CONTENT'
                    )}
                    css={{
                      'abyss-popover-trigger': {
                        display: 'block',
                      },
                      'abyss-popover-trigger-icon': {
                        marginLeft: '4px',
                      },
                    }}
                    position={mobileScreen ? 'top' : 'right'}
                    title={t(
                      'FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_RATING_INFORMATION'
                    )}
                    width={mobileScreen ? 260 : 500}
                  />
                </React.Fragment>
              </Heading>
              <Text
                css={LeapFrogTextStyle}
                data-auto-testid="leap-frog-disclaimer-text"
                data-testid="leap-frog-disclaimer-text"
              >
                {t('FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_DISCLAIMER_TEXT')}
                <Link
                  css={{
                    'abyss-link-root': {
                      display: 'inline',
                    },
                  }}
                  data-auto-testid="leap-frog-hospital-safety"
                  data-testid="leap-frog-hospital-safety"
                  href="http://www.hospitalsafetyscore.org"
                >
                  <Text color="$info1">
                    {' '}
                    http://www.hospitalsafetyscore.org.
                  </Text>
                </Link>
              </Text>
            </LeapFrogContainer>
          </SectionContainer>
        </React.Fragment>
      ) : null}
      <SectionContainer>
        <TabContainer>
          <Heading
            css={{
              paddingBottom: '12px',
              ...h2SmallerFontForResponsive,
            }}
            data-auto-testid="accreditation-heading"
            data-testid="accreditation-heading"
            display="h4"
            offset={1}
          >
            {t('PROVIDER_DETAILS.QUALITY_TAB.ACCREDITATION_TITLE')}
          </Heading>
          {selectedLocation?.facilityProviderSpecialties
            ?.hasJCAHOAccreditation ? (
            <Text css={AccreditationTextStyle}>
              {t(Constants.FACILITY_DETAILS.QUALITY_TAB.ACCREDITATION[0])}
            </Text>
          ) : (
            <Text css={AccreditationTextStyle}>
              {t('FACILITY_DETAILS.QUALITY_TAB.ACCREDITATION_NOT_FOUND')}
            </Text>
          )}
        </TabContainer>
      </SectionContainer>
    </React.Fragment>
  );
};
