import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import get from 'lodash/get';
import { useEffect, useMemo, useState } from 'react';

import { OPTUM_CORRELATION_ID_HEADER } from '../../common/Constants';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { ProviderType } from '../../models/ProviderDetails';
import {
  getProviderType,
  getProviderResults as parseProviderResults,
} from '../../utils/providerDetails.utils';
import { parseProviderSearchFilters } from '../../utils/providerSearch.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getDependentInfo,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import GET_PROVIDER_SEARCH_QUERY from './ProviderSearch.graphql';

const specialtyCodes = require('../../common/PSXHeader/specialtyCodes.json');

export const getFirstNumber = (str: string) => str?.split(',')[0];

export const filterSearchResults = (searchResult) => {
  const providerSearchResult = get(searchResult, 'providerSearch', {});

  const providersData = providerSearchResult.providers;

  const practitionersFiltered = providersData?.filter(
    (data) => data?.providerType === ProviderType.PRACTITIONER
  );

  const facilitiesFiltered = providersData?.filter(
    (data) => data?.providerType === ProviderType.ORGANIZATION
  );

  const medicalGroup = providersData?.filter(
    (data) => data?.providerType === ProviderType.MEDICAL_GROUP
  );

  return {
    totalCountPractitioners: providerSearchResult.totalCountPractitioners,
    totalCountOrganizations: providerSearchResult.totalCountOrganizations,
    totalCountMedicalGroup: providerSearchResult.totalCountMedicalGroup || 0,
    providers: practitionersFiltered || [],
    facilities: facilitiesFiltered || [],
    medicalGroup: medicalGroup || [],
    practitionerSearchRadius: providerSearchResult?.practitionerSearchRadius,
    organizationSearchRadius: providerSearchResult?.organizationSearchRadius,
    medicalGroupSearchRadius: providerSearchResult?.medicalGroupSearchRadius,
  };
};

export const useProviderSearch = (options) => {
  const [filtredResult, setFiltredResultResult] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [headers, setHeaders] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GET_PROVIDER_SEARCH_QUERY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'providerSearch',
    /* istanbul ignore next */
    onCompleted: (result) => {
      const data = filterSearchResults(result?.data);
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
      setFiltredResultResult(data);
      setIsLoading(false);
      setHeaders({
        correlationId: result.headers[OPTUM_CORRELATION_ID_HEADER],
      });
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const parsedResult = {
    ...queryResult,
    isLoading,
    data: filtredResult,
    headers,
  };

  return [parsedResult, queryAction];
};

export const useProviderResults = () => {
  const locale = getLanguage()?.code || 'en';
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { coverageType, dependentSeqNbr } = tokenData;
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();
  const planVariationCodeForMedical = getPlanVariationCode(currentMember, 'M');

  const variables = {
    lob: currentMember?.lineOfBusiness,
    coverages: getCoverageTypes(currentMember),
    planYear: getCurrentPlanYear(),
    planVariationCode: planVariationCodeForMedical,
    policyID: currentMember?.policyNumber,
    reciprocityId: getNetworkIdsForPES(currentMember, coverageType),
    locale,
    latitude,
    longitude,
    stateCode,
    memberDemographics: {
      gender: currentMember.demographics.gender,
      dateOfBirth: currentMember.demographics.dateOfBirth,
    },
    coverageType,
  };

  const [queryParams, setQueryParams] = useState(variables);

  const [providerResults, getProviders] = useProviderSearch({});

  const getProviderResults = (params) => {
    const searchParams = { ...variables, ...params };
    if (JSON.stringify(queryParams) !== JSON.stringify(searchParams)) {
      setQueryParams(searchParams);
      getProviders({
        variables: searchParams,
      });
    }
  };

  useEffect(() => {
    getProviderResults({
      ...queryParams,
      latitude,
      longitude,
      stateCode,
      memberDemographics: {
        gender: currentMember.demographics.gender,
        dateOfBirth: currentMember.demographics.dateOfBirth,
      },
    });
  }, [
    locale,
    latitude,
    longitude,
    stateCode,
    currentMember.demographics.gender,
    currentMember.demographics.dateOfBirth,
  ]);

  return [providerResults, getProviderResults];
};

export const useFilterProviderResults = (params) => {
  const {
    useMockData = false,
    sectionType = '',
    search = '',
    providerType = '',
    specialtyCode,
    category,
    searchType,
    pcpIndicator = false,
    pageNumber = 1,
    pageSize = 10,
    includeOrgTypeCodes,
    requestType = '',
    searchRadius,
    selectedFilters = {},
    llmFlag = false,
    keyword = '',
  } = params;

  const specialityCode =
    (specialtyCode || specialtyCodes[category?.toUpperCase()]) ?? '';

  const includeSpecialityRollupCodes = useMemo(
    () => [specialityCode],
    [specialityCode]
  );

  const [practitionersResult, getPractitionersResult] = useProviderResults();

  const filterParams = parseProviderSearchFilters(selectedFilters);

  useEffect(() => {
    getPractitionersResult({
      providerType: getProviderType(providerType, sectionType),
      search,
      includeSpecialityRollupCodes,
      requestType,
      searchType,
      pcpIndicator,
      pageNumber,
      pageSize,
      includeOrgTypeCodes: includeOrgTypeCodes
        ? JSON.parse(includeOrgTypeCodes)
        : [],
      searchRadius,
      ...filterParams,
      llmFlag,
      keyword,
    });
  }, [
    providerType,
    sectionType,
    search,
    JSON.stringify(includeSpecialityRollupCodes),
    searchType,
    pcpIndicator,
    pageNumber,
    pageSize,
    JSON.stringify(includeOrgTypeCodes),
    searchRadius,
    JSON.stringify(filterParams),
  ]);

  const { results, totalResultsCount, isLoading, error } = parseProviderResults(
    sectionType,
    practitionersResult,
    useMockData
  );

  return {
    results,
    totalResultsCount,
    isLoading,
    error,
    headers: practitionersResult?.headers,
  };
};

export const useProviderSummaryResult = ({ providerId, isOHBS }) => {
  const locale = getLanguage()?.code || 'en';
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { coverageType } = tokenData;
  const currentMember = getCurrentMember();
  const planVariationCodeForMedical = getPlanVariationCode(currentMember, 'M');
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const variables = {
    longitude,
    latitude,
    stateCode,
    providerId,
    providerType: '',
    lob: currentMember?.lineOfBusiness,
    coverages: getCoverageTypes(currentMember),
    planYear: getCurrentPlanYear(),
    planVariationCode: planVariationCodeForMedical,
    policyID: currentMember?.policyNumber,
    reciprocityId: isOHBS
      ? '100'
      : getNetworkIdsForPES(currentMember, coverageType),
    coverageType,
    locale,
  };

  const [providerResult, getProviderResult] = useProviderSearch({});

  useEffect(() => {
    if (providerId) {
      getProviderResult({
        variables,
      });
    }
  }, [providerId]);

  return providerResult;
};
