export enum ProviderType {
  ORGANIZATION = 'Organization',
  PRACTITIONER = 'Practitioner',
  PROVIDER_GROUP = 'Provider groups',
  MEDICAL_GROUP = 'Medical Group',
}

export interface ProviderDetailsResponse {
  providerDetails: ProviderDetails;
  totalCount: number;
}

export interface ProviderDetails {
  areaOfExpertise?: string[];
  biographicData?: string;
  boardCertifications: string[];
  culturalCompetencies: string[];
  defaultTranslatedValues: DefaultTranslatedPractionerInfoProp;
  educationInfo: Education[];
  ethnicity?: string;
  gender: string;
  hospitalAffiliations: Identifiers[];
  imageLocation: string;
  languagesSpoken: string[];
  licenseNumber: string[];
  npi: string;
  primaryDegrees: string[];
  providerId: string;
  providerLocations: ProviderLocation[];
  providerName: string;
  providerType: ProviderType;
  specialties: string[];
  pictureId: string;
  // Not available in ProviderDetails graphql schema
  UHPDRating?: string;
  accreditations?: string[];
  healthGradeRating?: string;
  lastUpdatedDate?: string;
  lastVerifiedDate?: string;
  networkStartDate?: string;
  networkStatus?: string;
  otherProviderDetails?: string[];
  otherSpecialities?: string[];
  primarySpeciality?: string[];
  professionalDesignation?: string;
  providerGroups?: string[];
  providerSpecialities?: string[];
  servicesProvided?: string[];
  organizationType?: string[];
}

export interface DefaultTranslatedPractionerInfoProp {
  areaOfExpertise: string[];
  boardCertifications: string[];
  culturalCompetencies: string[];
  educationInfo: Education[];
  ethnicity: String;
  languagesSpoken: string[];
  primarySpecialities?: string[];
  specialties: string[];
}

export interface ProviderLocation {
  efficiencyCode: string;
  qualityCode: string;
  tin: string;
  tty: string;
  digestId: string;
  pcpId: string;
  lastUpdatedDate: string;
  languagesSpokenByOffice: string[];
  accessibility: string[];
  specialties: string[];
  hasEveningAppointments: boolean;
  hasPCPServiceAvailability: boolean;
  hasWeekendAppointment: boolean;
  isExpressAccessProvider: boolean;
  isPremiumCarePhysician: boolean;
  isTieredProvider: boolean;
  defaultLocation: boolean;
  defaultTranslatedValues: DefaultTranslatedPractitionerLocationProp;
  network: Network;
  practiceLimitations: PracticeLimitations;
  locationId: string;
  acceptingNewPatients: boolean;
  address: Address;
  distance: string;
  emailAddress: string[];
  hoursOfOperation: HoursOfOperation[];
  latitude: string;
  longitude: string;
  virtualCareOffered: string[];
  phones: PhoneNumbers;
  website: string;
  // Not available in ProviderDetails graphql schema
  ddpCode?: string;
  medicalEquipments?: string[];
  facilityProviderSpecialties?: FacilityProviderSpecialities;
  providerGroupsAffiliations: ProviderGroupsAffiliations[];
  leapFrogRatings?: LeapFrogRating;
}

export interface DefaultTranslatedPractitionerLocationProp {
  accessibility?: string[];
  languagesSpokenByOffice?: string[];
  practiceLimitations: PracticeLimitations;
  specialties?: string[];
  virtualCareOffered?: string[];
}

export interface Education {
  schoolName: string;
  degrees: Degrees[];
}

export interface Degrees {
  degreeCode: string;
  isPrimary: boolean;
  description: string;
}

export interface Identifiers {
  id: string;
  value: string;
}

/** /////////////////////////////////////////////////////////////////////////////////////////////// */

/**
 * SHARED INTERFACES
 * Interfaces shared among providerDetails.ProviderLocations[], facilityDetails.FacilityLocationsResponse[], provider_group Details and medical_group Details locations arrays
 * - LeapFrogRatings
 * - Network
 * - Address
 * - PhoneNumbers
 * - FacilityProviderSpecialities
 * - HoursOfOperation
 * - PracticeLimitations
 */

export interface HoursOfOperation {
  day: string;
  openingTime: string;
  closingTime: string;
}

export interface Address {
  line: string[];
  city: string;
  district?: string;
  state: string;
  postalCode: string;
}

export interface PhoneNumbers {
  phone?: string[];
  emergency?: string[];
  appointment?: string[];
  fax?: string[];
}

export interface Network {
  start: string;
  end: string;
  status: string;
}

export interface PracticeLimitations {
  age: string;
  gender: string;
}

export interface FacilityProviderSpecialities {
  hasAnesthesiology: boolean;
  hasEmergencyMedicine: boolean;
  isFreeStandingFacility: boolean;
  hasPathology: boolean;
  hasRadiology: boolean;
  hasJCAHOAccreditation: boolean;
}

export interface LeapFrogRating {
  rawValue: string;
  displayField: string;
  ratingValue: string;
}

export interface ProviderGroupsAffiliations {
  id: string;
  value: string;
}
