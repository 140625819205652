import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/* eslint-disable no-nested-ternary */
import { useDeviceLocation } from '../../../../hooks/useDeviceLocation';
import { useLagoon } from '../../../../hooks/useLagoon';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { getFeatureFlag } from '../../../Utils';
import { getGeoLocationFromStorage } from '../utils';
import { CurrentLocationLink } from './CurrentLocationLink';
import {
  Divider,
  DropdownContentContainer,
  DropdownPositionContainer,
} from './LocationDropdown.styled';
import { LocationDropdownSkeleton } from './LocationDropdownSkeleton';
// import { CurrentLocationLink } from '../ChangeLocation/CurrentLocationLink';
import { LocationSuggestionStack } from './LocationSuggestionStack';

export const LocationDropdown = ({
  onClose,
  activeSuggestion,
  locationSuggestions,
  isLocationLoading,
  setSelectedLocation,
  locationInputVal,
  setLocationInputVal,
}: {
  onClose: () => void;
  activeSuggestion: number;
  locationInputVal: string;
  locationSuggestions?: object[];
  isLocationLoading: boolean;
  setSelectedLocation: (any) => void;
  setLocationInputVal: (val: string) => void;
}) => {
  const { t } = useTranslation();
  const featureFlags: [{ key: string; active: boolean }] = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_FLAGS
  )();
  const decemberFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DECEMBER_RELEASE_ENABLED
  );

  const locationForAnalytics: string = 'change search location';

  const handleLocationItemClick = (rec) => {
    adobeLinkTrackEvent({
      name: 'suggested location',
      location: `body:${locationForAnalytics}`,
      type: 'internal',
    });
    setSelectedLocation(rec);
    onClose();
    setLocationInputVal(rec?.place_name);
  };
  const [, setDeviceLocation] = useState<string>('');
  const { getDeviceLocation } = useDeviceLocation(setDeviceLocation);

  return (
    <DropdownPositionContainer data-testid="location-dropdown-container">
      <DropdownContentContainer>
        <Divider />
        <Layout.Stack
          data-testid="location-suggestions-dropdown-container"
          grow
          space={15}
        >
          <Text color="$gray7">
            {decemberFlag
              ? t('LOCATION_SEARCH.HEADER_DECEMBER_RELEASE')
              : t('LOCATION_SEARCH.HEADER')}
          </Text>
          <Layout.Stack alignItems="left" space={12}>
            {/* {!!warningMessage && (
              <Layout.Group alignItems="top">
                <IconMaterial color="$error1" icon="error" />
                <Text color="$error1">{warningMessage}</Text>
              </Layout.Group>
            )} */}
            {isLocationLoading ? (
              <LocationDropdownSkeleton testId="location-dropdown-container-skeleton" />
            ) : locationInputVal.trim().length > 0 ? (
              <LocationSuggestionStack
                activeSuggestion={activeSuggestion}
                onSuggestionClick={handleLocationItemClick}
                suggestions={locationSuggestions}
              />
            ) : null}
          </Layout.Stack>
          <CurrentLocationLink
            data-testid="changeLocation_currentLocationAction"
            onLocateMeSelect={() => {
              adobeLinkTrackEvent({
                name: Constants.LOCATION_SEARCH.FIND_MY_LOCATION,
                location: `body:${locationForAnalytics}`,
                type: 'internal',
              });
              getDeviceLocation();
            }}
          />
        </Layout.Stack>
      </DropdownContentContainer>
    </DropdownPositionContainer>
  );
};
