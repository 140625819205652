import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { SearchInput } from '@abyss/web/ui/SearchInput';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeSearchFilterEvent } from '../../../AdobeTagging/adobeSearchFilterEvent';
import { Constants } from '../../../Constants';
import { searchInputStyle } from './DirectorySearchInput.styles';

type Props = {
  setProviderNameToSearch: (a: string) => void;
};

export const DirectorySearchInput = ({ setProviderNameToSearch }: Props) => {
  const { t } = useTranslation();
  const form = useForm();

  const findValidationErrorMessage = (input) => {
    if (input.length === 0) {
      return undefined;
    }
    if (!Constants.INPUT_MODELS.NUMBERS_REGEX_PATTERN.test(input)) {
      return t(
        'INPUT_MODELS.PHYSICIAN_DIRECTORY_SEARCH_ERROR_DESCRIPTOR_NO_NUMBERS'
      );
    }
    if (
      !Constants.INPUT_MODELS.PHYSICIAN_DIRECTORY_SEARCH_REGEX_PATTERN.test(
        input
      )
    ) {
      return t('INPUT_MODELS.PHYSICIAN_DIRECTORY_SEARCH_ERROR_DESCRIPTOR');
    }
    if (input.length === 1) {
      return t('Short search');
    }
    return undefined;
  };

  const changeHandler = (inputValue) => {
    form?.setValue('physician-directory-search', inputValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (!findValidationErrorMessage(inputValue)) {
      setProviderNameToSearch(inputValue);
      adobeSearchFilterEvent({
        type: 'provider',
        term: inputValue,
      });
    }
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 600), []);

  return (
    <FormProvider state={form}>
      <SearchInput
        css={{ ...searchInputStyle }}
        data-auto-testid="physician-directory-search"
        data-testid="physician-directory-search"
        enableOutsideScroll
        inputLeftElement={<IconMaterial color="$black" icon="search" />}
        isClearable
        label={t('FACILITY_DETAILS.SEARCH_INPUT_LABEL')}
        model="physician-directory-search"
        onClickSearchButton={() => {}}
        onInputChange={debouncedChangeHandler}
        onSearch={() => {}}
        placeholder={t('FACILITY_DETAILS.SEARCH_INPUT_PLACEHOLDER')}
        rounded
        validators={{
          validate: {
            hasValue: (val) => findValidationErrorMessage(val),
          },
        }}
      />
    </FormProvider>
  );
};
