import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { Survey } from '../../../../models/Healthgrades';
import { Rating } from '../../../SnackCard/Rating';
import { RatingDescriptionWithStarsStyles } from './PatientReviews.styled';
import { RatingDescriptionWithStars } from './RatingDescriptionWithStars';
import { getTextForWaitTime } from './utils';

type Props = {
  review: Survey | null;
};

export const PatientReviewsModal = ({ review }: Props) => {
  const { t } = useTranslation();
  if (!review) return null;
  return (
    <Flex css={{ gap: '$lg' }} direction="column">
      <Rating hideNumber outOf={5} rating={review?.overallScore} size={24} />
      <Text size="$lg">"{review?.comment?.commentContent}"</Text>
      <Flex css={{ gap: '$md' }} direction="row">
        <Flex css={{ gap: '$md' }} direction="column">
          <RatingDescriptionWithStars
            copy="providerTrustRating"
            rating={review?.comment?.answers?.providerTrustRating}
          />
          <RatingDescriptionWithStars
            copy="providerExplanationRating"
            rating={review?.comment?.answers?.providerExplanationRating}
          />
          <RatingDescriptionWithStars
            copy="providerAnswersRating"
            rating={review?.comment?.answers?.providerAnswersRating}
          />
          <RatingDescriptionWithStars
            copy="providerTimeAllocationRating"
            rating={review?.comment?.answers?.providerTimeAllocationRating}
          />
        </Flex>
        <Flex css={{ gap: '$md' }} direction="column">
          <RatingDescriptionWithStars
            copy="officeSchedulingRating"
            rating={review?.comment?.answers?.officeSchedulingRating}
          />
          <RatingDescriptionWithStars
            copy="officeEnvironmentRating"
            rating={review?.comment?.answers?.officeEnvironmentRating}
          />
          <RatingDescriptionWithStars
            copy="providerStaffRating"
            rating={review?.comment?.answers?.providerStaffRating}
          />
          <Flex css={RatingDescriptionWithStarsStyles}>
            <Text size="$sm">{t('waitTime')}</Text>
            <Text size="$sm">
              {t(getTextForWaitTime(review?.comment?.answers?.waitTime))}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
