import { config } from '@abyss/web/tools/config';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Fragment, useEffect } from 'react';

import packageJson from '../../../../../package.json';
/* eslint-disable consistent-return */
import { useLagoon } from '../../../../hooks/useLagoon';
import {
  getCoverageTypes,
  getCurrentMember,
} from '../../../../utils/user.utils';
import { IChildrenProp } from '../../types';
import { getPlanSelectorVariables } from '../PlanSelector/helpers';

const useConfig = (key: string): string => {
  const allConfigs = useLagoon('config');
  const configs = allConfigs(key);
  return configs ? configs.value : '';
};

export const Analytics = ({ children }: IChildrenProp) => {
  const envVar = config('ENV_VAR');
  const apiGateway = config('AWS_API_GATEWAY');
  const applicationId = useConfig('DATADOG_EXT_APPLICATION_ID');
  const clientToken = useConfig('DATADOG_EXT_CLIENT_TOKEN');
  const service = useConfig('DATADOG_EXT_SERVICE');
  const currentMember = getCurrentMember();
  const { accountId } = getPlanSelectorVariables();

  useEffect(() => {
    if (envVar === 'local') return;

    if (!applicationId || !clientToken || !service) {
      // eslint-disable-next-line no-console
      return console.warn(
        'DataDog init failure due to incomplete receive applicationId, clientToken, service!'
      );
    }

    datadogLogs.init({
      clientToken,
      site: 'datadoghq.com',
      service,
      forwardErrorsToLogs: true,
      sampleRate: 100,
      env: envVar,
      version: packageJson.version,
    });

    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service,
      env: envVar,
      version: packageJson.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackFrustrations: true,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      traceSampleRate: 100,
      allowedTracingUrls: [
        `${window.location.protocol}//${window.location.host}`,
        apiGateway,
      ],
    });

    // This is temporary will be removed once we know it works
    // eslint-disable-next-line no-console
    console.log(`DD RUM APM CONNECT TEST: ${apiGateway}`);

    datadogRum.setGlobalContext({
      lineOfBusiness: currentMember?.lineOfBusiness.replace('&', 'n'),
      groupId: currentMember?.policyNumber,
      hsid: accountId ?? '',
      membershipCategory: 'DEFAULT', // from OBAPI's profile response, if empty use DEFAULT (not seeing the field yet in any testing)
      planName:
        currentMember?.planDetail?.eligibility?.benefitSet?.find(
          (benefitSet) => benefitSet.coverageTypeCode.code === 'M'
        )?.planNameType?.brandingLongName || '', // from OBAPI's planDetails response for medical coverage
      coverageType: getCoverageTypes(currentMember)?.includes('M')
        ? 'M'
        : currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType[0]
            .typeCode.coverageTypeCode, // if member has medical coverage, otherwise first coverage returned from obapi's profile
    });
    datadogRum.startSessionReplayRecording();
  }, [applicationId, clientToken, service]);

  return <Fragment>{children}</Fragment>;
};
