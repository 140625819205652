export enum CoverageTypesCodes {
  BEHAVIORAL_HEALTH = 'BEHAVIORAL HEALTH',
  BEHAVIORAL_CARVE_OUT = 'BEHAVIORAL CARVE-OUT',
  MEDICAL = 'MEDICAL',
}

export enum ReverseCoverageTypesCodes {
  'BEHAVIORAL HEALTH' = 'B',
  'BEHAVIORAL CARVE-OUT' = 'OBH',
  'DENTAL' = 'D',
  'MEDICAL' = 'M',
  'VISION' = 'V',
}

enum CareCategories {
  ALL = 'All',
  BEHAVIORAL_HEALTH = 'Behavioral Health',
  MEDICAL_GROUPS = 'Medical Groups',
  MEDICAL_SPECIALISTS = 'Medical Specialists',
  FACILITIES = 'Facilities',
  HEALTH_SUPPLIES = 'Health Supplies',
  PRIMARY_CARE = 'Primary Care',
  DENTAL = 'Dental',
  VISION = 'Vision',
  PROVIDER_GROUP = 'Provider Group',
}

enum ProviderTabs {
  ABOUT = 'About',
  LOCATIONS = 'Locations',
  COST = 'Cost',
  QUALITY = 'Quality',
}

enum FacilityTabs {
  ABOUT = 'About',
  LOCATIONS = 'Locations',
  PHYSICIAN_DIRECTORY = 'Physician Directory',
  QUALITY = 'Quality',
}

export enum PhysicianDirectorySortOrder {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export enum RelationshipTypeCodes {
  DEPENDENT = 'DEPENDENT',
  SUBSCRIBER = 'SUBSCRIBER',
  SPOUSE = 'SPOUSE',
}

export enum ProviderTypes {
  ORGANIZATION = 'organization',
  PRACTITIONER = 'practitioner',
  FACILITY = 'facility',
  MEDICALGROUP = 'Medical Group',
  GROUP = 'provider group',
  MEDICAL_GROUP = 'Medical_Group',
}
export enum ProviderSummaryTypes {
  ORGANIZATION = 'Organization',
  PRACTITIONER = 'Practitioner',
  MEDICAL_GROUP = 'Medical Group',
}

export enum TypeaheadProviderTypes {
  PRACTITIONER = 'practitioner',
  PROVIDER_GROUP = 'Provider Group',
  FACILITY = 'Facility',
  MEDICAL_HEALTH_SUPPLIES = 'Medical/Health Supplies',
  BEHAVIORAL_HEALTH = 'Behavioral Health',
}

export enum TypeaheadBHType {
  GRP = 'GRP',
  FAC = 'FAC',
}

export enum RecentSearchesSearchTypes {
  ORGANIZATION_DETAIL = 'OrganizationDetail',
  PRACTITIONER_DETAIL = 'PractitionerDetail',
  MEDICAL_GROUP_DETAIL = 'MedicalGroupDetail',
  OTHER = 'Other',
}

export enum TypeaheadProviderTypesMapping {
  'Behavioral Health' = CareCategories.BEHAVIORAL_HEALTH,
  'Medical Specialists' = CareCategories.MEDICAL_SPECIALISTS,
  'Primary Care' = CareCategories.PRIMARY_CARE,
  'Facility' = CareCategories.FACILITIES,
  'Medical/Health Supplies' = CareCategories.HEALTH_SUPPLIES,
  'Provider Group' = CareCategories.PROVIDER_GROUP,
  // TODO need remove 'provider group' once typeahead make changes
}

export enum SortOptionValues {
  BEST_MATCH = 'BEST_MATCH',
  HIGHEST_RATING = 'HIGHEST_RATING',
  CLOSEST = 'CLOSEST',
  ALPHA_A_Z = 'ALPHA_A_Z',
  ALPHA_Z_A = 'ALPHA_Z_A',
  TIER_PROV = 'TIER_PROV',
  ACO_PROV = 'ACO_PROV',
  UHPD_PROV = 'UHPD_PROV',
  COST_EFF_RATING = 'COST_EFF_RATING',
}

export const FilterOptionValues = {
  AcceptingNewPatients: {
    Yes: 'Y',
    No: 'N',
  },
};

export enum NetworkStatus {
  INN = 'In-network',
  OON = 'Out-of-network',
}

export enum FilterCollectionModel {
  DISTANCE = 'Distance',
  PATIENT_REVIEWS = 'PatientReviews',
  SPECIALTY_CODE = 'SpecialtyCode',
  AREA_OF_EXPERTISE = 'AreaOfExpertise',
  FACILITY_TYPE = 'FacilityType',
  EVENING_AND_WEEKEND = 'EveningAndWeekend',
  HEALTH_SUPPLIES_OFFERED = 'HealthSuppliesOffered',
  ACCESSIBILITY_CODE = 'AccessibilityCode',
  LANGUAGE_CODE = 'LanguageCode',
  GENDER_CODE = 'GenderCode',
  ETHNICITY_CODE = 'EthnicityCode',
  MEDICAL = 'Medical',
  HOSPITAL_AFFILIATIONS = 'HospitalAffiliations',
  ACCEPTING_NEW_PATIENTS = 'AcceptingNewPatients',
  BEHAVIORAL_PROGRAM_INDICATOR = 'BehavioralProgramIndicator',
  EXPRESS_ACCESS_PROVIDER_INDICATOR = 'ExpressAccessProviderIndicator',
  TIERED_PROVIDER = 'TieredProvider',
  PREMIUM_CARE_PHYSICIAN = 'PremiumCarePhysician',
  PLATINUM_PROVIDER_INDICATOR = 'PlatinumProviderIndicator',
  VIRTUAL_CARE = 'VirtualCare',
  SORT_BY = 'SortBy',
}

export enum FilterKeysAndParamsMapping {
  ExpressAccessProviderIndicator = 'expressAccessProvider',
  TieredProvider = 'tieredProvider',
  PremiumCarePhysician = 'premiumCarePhysician',
  PlatinumProviderIndicator = 'platinumProvider',
  VirtualCare = 'virtualCare',
  SortBy = 'sortBy',
  FacilityType = 'includeOrgTypeCodes',
  SpecialtyCode = 'srcSysSpecialityCodes',
  AreaOfExpertise = 'aoeCodes',
  HealthSuppliesOffered = 'healthSuppliesOffered',
  LanguageCode = 'language',
  AccessibilityCode = 'handicapAccessibleCodes',
  PatientReviews = 'healthGradeRating',
  Medical = 'medicalGroupAffiliationIds',
  HospitalAffiliations = 'hospitalAffiliationProviderIds',
  GenderCode = 'gender',
  Distance = 'searchRadius',
  AcceptingNewPatients = 'acceptingNewPatients',
  PatientSafetyInfoAvailable = 'patientSafetyInfoAvailable',
  EthnicityCode = 'ethnicityCode',
  BehavioralProgramIndicator = 'behavioralProgramId',
}

export const L2_CATEGORIES = {
  '01': CareCategories.PRIMARY_CARE,
  '02': CareCategories.MEDICAL_SPECIALISTS,
  '03': CareCategories.BEHAVIORAL_HEALTH,
  '04': CareCategories.DENTAL,
  '05': CareCategories.VISION,
  '50': CareCategories.FACILITIES,
  '51': CareCategories.HEALTH_SUPPLIES,
  '53': CareCategories.FACILITIES,
};

export const OPTUM_CORRELATION_ID_HEADER = 'optum-cid-ext';

const FACILITY_NAME = 'Facility Name';
const PROVIDER_NAME = 'Provider Name';
const ADDRESS = '123 Main Street';
const PHNO = '(000)-000-0000';
const IN_NETWORK = 'In-network';
const TYPE_OF_PROVIDER = 'Type of Provider';
const TYPE_OF_FACILITY = 'Type of Facility';
const FACILITY = 'Facility';
const PROVIDER = 'Provider';
const MEDICINE = 'Medicine';
const DISTANCE = '3';
const VIEW_ALL = 'View all';
const MILES = 'miles';
const LAST_VISIT = 'Last visit:';
export const PRACTITIONER_NAME_FOR_ANALYTICS = 'provider name';
export const NEW_TAB_CONFIRMATION = 'new tab confirmation';
export const PAGE_SIZE = 10;
export const SUGGESTED_PROVIDER = 'Suggested Provider';
export const RECOMMENDED_PROVIDER = 'Recommended Provider';
export const NULL_RESULTS = 'Null Results';
export const INFORMATION_NOT_AVAILABLE = 'Information not available';
export const DEFAULT_SEARCH_RADIUS = 15;
export const AUTO_INCREMENT_SEARCH_RADIUS = ['25', '50', '75', '100'];
export const Constants = {
  CHANGE: 'Change',
  BROWSE_COMMON_SPECIALTIES: {
    HEADING: 'All Medical Specialists',
    CARD_HEADING: 'Browse common specialties',
    REFERRAL_REQUIRED: 'Referral required',
  },
  URGENT_CARE_ORG_TYPE_CODES: ['030', '057'],
  URGENT_CARE: 'URGENT_CARE',
  BIOGRAPHY_SECTION: {
    HEADING: 'Biography',
    CONTENT:
      'Dr. LaBere enjoys music, spending time with family, playing sports and taking part in physical fitness activities of all types. He has 7 years of experience in the medical field.',
    GENDER: 'Male',
    LANGUAGE: 'English, Spanish',
    ETHNICITY: 'African American',
  },
  BROWSE_FOR_CARE: {
    HEADING: 'Browse for care',
  },
  BROWSE_LABS_AND_SERVICES: {
    HEADING: 'Browse facilities',
    VIEW_ALL,
    FACILITY_NAME,
    PROFESSIONAL_DESIGNATION: 'MD',
    FACILITY_ID: '12345',
    SPECIALTY: 'Dermatologist',
  },
  BROWSE_FACILITIES: {
    HEADING: 'Browse facilities',
    VIEW_ALL,
    FACILITY_NAME,
    PROFESSIONAL_DESIGNATION: 'MD',
    FACILITY_ID: '12345',
    SPECIALTY: 'Dermatologist',
  },
  CONTENT_FILTERS: {
    MAP: 'Map',
    FILTER: 'Filter',
    DISTANCE: 'distance',
  },
  DONT_KNOW_WHERE_TO_START_CARD: {
    HEADING: "Don't know where to start?",
    RECCOMENDATION: 'Get recommendations',
  },
  WHO_CAN_HELP_CARD: {
    HEADING: 'Who can help?',
  },
  FACILITY_DETAILS_ABOUT_SECTION: {
    HEADING: 'Facility details',
    LANGUAGE_SPOKEN: 'Languages spoken by office',
    ACCESSIBILITY: 'Accessibility',
    FACILITY_TYPE: 'Facility type',
    SPECIALTY: 'Specialty',
  },
  FACILITY_DETAILS_LOCATION_SECTION: {
    START_DATE: 'In-network start date',
  },
  LOCATION_SEARCH: {
    FIND_MY_LOCATION: 'find my location',
  },
  LAGOON_TABLE: {
    VISION_AND_DENTAL_REDIRECTION_BASEURL: 'dental-and-vision-redirection',
    COMMONLY_SEARCHED: {
      [CareCategories.ALL]: 'common-searches',
      [CareCategories.BEHAVIORAL_HEALTH]: 'behavioral-health-searches',
      [CareCategories.DENTAL]: 'dental-searches',
      [CareCategories.VISION]: 'vision-searches',
    },
    FEATURE_FLAGS: 'feature-flags',
    FPC_PSX_REDIRECTION: 'fpc-psx-redirection',
    LLM_SEARCH: 'llm-search',
  },
  No_RESULT_FOUND: {
    N0_RESULT_FOUND_CONTENT: 'No Results Found',
  },
  MAP_DATA_CARD: {
    SCHEDULE: 'Schedule',
  },
  MAP_VIEW_NAVIGATION_MODAL: 'Map View Navigation',
  PRIMARY_CARE_PROVIDER: {
    HEADING: 'My primary care provider (PCP)',
    INFO: 'Choose a PCP by 1/1/2023*',
    PCP_NOT_REQUIRED_INFO:
      'We don’t have a Primary Care Provider on file for you.',
    MY_PCP: 'My Primary Care Provider',
    CHOOSE_PCP: 'Choose PCP',
    CHOOSE_A_PCP: 'Choose a PCP',
    CHOOSE_AS_PCP: 'Choose as PCP',
    CHOOSE_A_NEW_PCP: 'Choose a new PCP',
    LAST_VISIT,
    VIEW_ALL,
    DEPENDENTS_PCPS_LABEL: "My dependents' PCPs",
    PCP_ASSIGNED_DATE:
      '*A PCP will be automatically assigned to you on 1/1/2023.',
    OUT_OF_NETWORK: 'This provider is out-of-network as of ',
    YOUR_PCP: 'Your PCP',
  },
  MOCK_YEAR: '2023',
  PROVIDER_CARD: {
    MILES,
  },
  DISPLAY_DIRECTIONS: {
    SEE_DIRECTIONS: 'See directions',
    START_LOCATION: 'Start location',
    DESTINATION: 'Destination',
    ROUTES: 'Routes',
    STEPS: 'Steps',
  },
  PROVIDER_RESULTS: {
    ALL_FILTERS_DRAWER: 'All Filters Drawer',
    FILTER_BY: 'Filter By',
  },
  REFERRAL_CARD: {
    HEADING: 'How to get a referral',
    VIEW_SPECIALIST: 'View specialties',
  },
  SMALL_FACILITY_CARDS: {
    MILES,
  },
  THINGS_TO_KNOW_CARD: {
    HEADING: 'Things to Know',
  },
  TOP_PRIMARY_CARD_PROVIDERS: {
    HEADING: 'Primary care providers',
    VIEW_ALL,
    SEARCH_TERM: CareCategories.PRIMARY_CARE,
  },
  TOP_PRIMARY_CARD_MEDICAL_GROUPS: {
    HEADING: 'Primary care provider groups',
    VIEW_ALL,
    SEARCH_TERM: CareCategories.MEDICAL_GROUPS,
  },
  TOP_PRIMARY_CARD_CARE_CLINIC: {
    HEADING: 'Primary care clinics',
    VIEW_ALL,
  },
  TOP_PRIMARY_CARE_LOCATIONS: {
    VIEW_ALL,
    MILES,
  },
  TOP_PROVIDERS_NEAR_YOU: {
    HEADING: 'Top Providers Near You',
  },
  VIRTUAL_CARE_CARD: {
    HEADING: 'Virtual care',
    VIRTUAL_PRIMARY: 'Virtual primary care',
    ACTIVE_REFRAL: 'Active referrals',
    VIEW_ACTIVE_REFRAL: 'View my active referrals',
    INFO: 'Galileo is your virtual primary care provider.',
    USE_APP: 'Use the Galileo app',
    MY_RECENT_LAB: 'My recent lab visit',
    LAST_VISIT,
    RECENT_VISIT: 'My recent specialist visit',
    SPECIALITY: 'Speciality',
    CONNECT_CARE: 'Connect to Care',
  },
  URGENT_CARE_CARD: {
    HEADING: 'Urgent care',
    INFO: 'Get care for conditions that need immediate attention but aren’t an emergency.',
    GET_CARE_NOW: 'Get care now',
    SEARCH: 'Urgent Care',
  },
  CARE_OPTIONS_CARD: {
    HEADING: 'Quick care',
  },
  PRIMARY_CARE_CARD: {
    HEADING: 'Primary Care Provider',
    INFO: 'Primary care provider is your first contact to get care for your health.',
    GET_CARE_NOW: 'Get care now',
  },
  EAP_CODE_CARD: {
    HEADING: 'EAP code needed',
    LINK_TEXT: 'Get my code',
  },
  SUGGEST_EDIT: {
    HEADING: 'Suggest edit',
    INCORRECT_ITEMS: 'Which items are Incorrect?',
    PROVIDERS_INFO: 'Provider information',
    PROVIDER_NAME: 'Provider’s name',
    SPECIALTY: 'Specialty',
    AFFILIATION: 'Medical group affiliation',
    LOCATION_INFO: 'Location information for:',
    CHOOSE_LOCATION: 'Choose Location',
    ADDRESS: 'Address',
    HOURS: 'Hours',
    PHONE_NO: 'Phone number',
    WEBSITE: 'Website',
    PATIENT_STATUS: 'Accepting patients status',
    OPEN_BUSINESS: 'Open for business',
    OTHER: 'Other',
    SOMETHING_INCORRECT: 'Something else is incorrect',
    NEXT: 'Next',
  },
  RESULT_SECTION: {
    VIEW_ALL,
    PROVIDERS: 'providers',
    FACILITIES: 'facilities',
    PROVIDER_GROUPS: 'Provider group',
    PROVIDER,
    PROVIDER_CAPITAL: PROVIDER,
    FACILITY,
    FACILITY_CAPITAL: FACILITY,
    TOTAL: 'total',
    RESULTS: 'results',
    PRIMARY_CARE_PROVIDERS: CareCategories.PRIMARY_CARE,
    PRACTITIONER: 'Practitioner',
    ORGANIZATION: 'Organization',
    ALL: 'All',
    MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN: 3,
  },
  CHIPS_CATEGORIES: {
    ALL: 'All',
    PRIMARY_CARE: CareCategories.PRIMARY_CARE,
    MEDICAL_SPECIALISTS: CareCategories.MEDICAL_SPECIALISTS,
    BEHAVIORAL_HEALTH: CareCategories.BEHAVIORAL_HEALTH,
    FACILITIES: CareCategories.FACILITIES,
    HEALTH_SUPPLIES: CareCategories.HEALTH_SUPPLIES,
    DENTAL: CareCategories.DENTAL,
    VISION: CareCategories.VISION,
    TYPES_OF_CARE: 'Types of care',
  },
  SEARCH_SECTION: {
    MEDICAL_SPECIALISTS: CareCategories.MEDICAL_SPECIALISTS,
    DENTAL: 'Dental Care',
    BROWSE_HEALTH_SUPPLIES: 'Browse health supply facilities',
  },
  SEARCH_BAR_DRAWER_SECTION_PLACEHOLDER: {
    MEDICAL_SPECIALISTS: 'What are you looking for?',
  },
  SEARCH_TYPES: {
    NAME: 'name',
    LOCATION: 'location',
    SPECIALTY: 'speciality',
    FACILITY,
    PROVIDER,
    PROVIDER_GROUP: 'Provider group',
  },
  SET_LOCATION: {
    LOCATION: 'Set your location',
  },
  // Docs:
  // -> https://csrc.nist.gov/glossary/term/special_character
  SPECIAL_CHARACTERS: [
    '`',
    '~',
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    '_',
    '+',
    '|',
    '}',
    '{{',
    '“',
    ':',
    '?',
    '>',
    '<',
    '[[',
    ']',
    '\\',
    ';',
    '’',
    ',',
    '.',
    '/',
    '-',
    '=',
  ],
  DATA_CARD: {
    MILES: 'miles',
    SCHEDULE: 'Schedule',
  },
  COMMON_SEARCH_SECTION: {
    RESULT_LIMIT: 10,
  },

  RECENT_ACTIVITY: {
    ALL_RECENT_ACTIVITY: 'All Recent Activity',
    PICK_UP_WHERE_YOU_LEFT_OFF: 'Pick up where you left off',
    VIEW_ALL: 'View all',
    HEALTH_SUPPLY_SEARCHES: 'My health supply searches',
    HEALTH_SUPPLY_SEARCHES_DRAWER: 'Recent Health Supply Searches',
    MEDICAL_SPECIALISTS_SEARCHES: 'My specialist searches',
    VISION_SEARCHES: 'My vision searches',
    DENTAL_SEARCHES: 'My dental searches',
  },
  RECENT_ACTIVITY_TRANSLATION_MAPPING: {
    [CareCategories.ALL]: 'PICK_UP_WHERE_YOU_LEFT_OFF',
    [CareCategories.PRIMARY_CARE]: 'PRIMARY_CARE_SEARCHES',
    [CareCategories.MEDICAL_SPECIALISTS]: 'SPECIALIST_SEARCHES',
    [CareCategories.BEHAVIORAL_HEALTH]: 'BEHAVIORAL_HEALTH_SEARCHES',
    [CareCategories.HEALTH_SUPPLIES]: 'HEALTH_SUPPLY_SEARCHES',
    [CareCategories.FACILITIES]: 'FACILITIES_SEARCHES',
    [CareCategories.DENTAL]: 'DENTAL_SEARCHES',
    [CareCategories.VISION]: 'VISION_SEARCHES',
  },
  RECENT_VISIT_TRANSLATION_MAPPING: {
    [CareCategories.PRIMARY_CARE]: 'MY_RECENT_PRIMARY_CARE_VISIT',
    [CareCategories.MEDICAL_SPECIALISTS]: 'MY_RECENT_SPECIALIST_VISIT',
    [CareCategories.BEHAVIORAL_HEALTH]: 'MY_RECENT_BEHAVIORAL_HEALTH_VISIT',
    [CareCategories.HEALTH_SUPPLIES]: 'MY_RECENT_HEALTH_SUPPLY_VISIT',
    [CareCategories.FACILITIES]: 'MY_RECENT_FACILITIES_SEARCHES_VISIT',
    [CareCategories.DENTAL]: 'MY_RECENT_DENTAL_SEARCHES_VISIT',
    [CareCategories.VISION]: 'MY_RECENT_VISION_SEARCHES_VISIT',
  },
  RECENT_VISIT_CLAIMS_DATA_MAPPING: {
    [CareCategories.PRIMARY_CARE]: {
      claimsCategory: 'primaryCare',
    },
    [CareCategories.MEDICAL_SPECIALISTS]: {
      claimsCategory: 'medicalSpecialists',
    },
    [CareCategories.BEHAVIORAL_HEALTH]: {
      claimsCategory: 'behavioralHealth',
    },
    [CareCategories.HEALTH_SUPPLIES]: {
      claimsCategory: 'healthSupplies',
    },
    [CareCategories.FACILITIES]: {
      claimsCategory: 'facilities',
    },
  },

  CARDS: ['card 1', 'card 2', 'card 3', 'card 4', 'card 5'],
  FILTER_BUTTONS: {
    MAP: 'Map',
    FILTER: 'Filter',
    SHARE: 'Share',
    DISTANCE: 'Distance',
    COMPARE: 'Compare',
  },
  SORT_BUTTONS: {
    SORT_RESULT: 'Sort results',
    SORT: 'Sort',
  },
  SORT_OPTIONS: {
    BEST_MATCH: 'Best match',
    HIGHEST_RATING: 'Highest rated (patient reviews)',
    CLOSEST: 'Closest',
    ALPHA_A_Z: 'Alpha (A-Z)',
    ALPHA_Z_A: 'Alpha (Z-A)',
    NEAR_ME: 'Near me',
    LOWEST_TO_HIGHEST_PRICE: 'Lowest to highest price',
    DISTANCE: 'Distance',
    PATIENT_REVIEWS: 'Patient reviews',
  },
  REFINE_RESULTS: 'Refine Results',
  BEST_MATCH: 'Best match',
  MOCK_CARE_CATEGORIES: [
    { label: 'All', value: 'All', id: 'all', coverageType: 'All' },
    {
      label: CareCategories.PRIMARY_CARE,
      value: CareCategories.PRIMARY_CARE,
      id: 'primary-care',
      coverageType: 'MEDICAL',
    },
    {
      label: CareCategories.MEDICAL_SPECIALISTS,
      value: CareCategories.MEDICAL_SPECIALISTS,
      id: 'medical-specialists',
      coverageType: 'MEDICAL',
    },
    {
      label: CareCategories.BEHAVIORAL_HEALTH,
      value: CareCategories.BEHAVIORAL_HEALTH,
      id: 'behavioral-health',
      coverageType: CoverageTypesCodes.BEHAVIORAL_HEALTH,
    },
    {
      label: CareCategories.HEALTH_SUPPLIES,
      value: CareCategories.HEALTH_SUPPLIES,
      id: 'health-supplies',
      coverageType: 'MEDICAL',
    },
    {
      label: CareCategories.FACILITIES,
      value: CareCategories.FACILITIES,
      id: 'labs-and-services',
      coverageType: 'MEDICAL',
    },
    {
      label: CareCategories.DENTAL,
      value: CareCategories.DENTAL,
      id: 'dental',
      coverageType: 'DENTAL',
    },
    {
      label: CareCategories.VISION,
      value: CareCategories.VISION,
      id: 'vision',
      coverageType: 'VISION',
    },
  ],

  COVERAGE_TYPES_CODES: {
    MEDICAL: CoverageTypesCodes.MEDICAL,
    BEHAVIORAL_HEALTH: CoverageTypesCodes.BEHAVIORAL_HEALTH,
  },

  COVERAGE_TYPES: {
    [CareCategories.ALL]: CoverageTypesCodes.MEDICAL,
    [CareCategories.PRIMARY_CARE]: CoverageTypesCodes.MEDICAL,
    [CareCategories.FACILITIES]: CoverageTypesCodes.MEDICAL,
    [CareCategories.MEDICAL_SPECIALISTS]: CoverageTypesCodes.MEDICAL,
    [CareCategories.HEALTH_SUPPLIES]: CoverageTypesCodes.MEDICAL,
    [CareCategories.BEHAVIORAL_HEALTH]: CoverageTypesCodes.BEHAVIORAL_HEALTH,
  },

  RATE_LIMITING: [0, 1, 2, 3, 4],
  MOCK_API_REQUEST: {
    SOME_INVLAID_LOCATION: 'califoasd',
  },
  MOCK_RESULTS: {
    facilities: [
      {
        providerName: FACILITY_NAME,
        practice: TYPE_OF_FACILITY,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: FACILITY,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: FACILITY_NAME,
        practice: TYPE_OF_FACILITY,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: FACILITY,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: FACILITY_NAME,
        practice: TYPE_OF_FACILITY,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: FACILITY,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: FACILITY_NAME,
        practice: TYPE_OF_FACILITY,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: FACILITY,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: FACILITY_NAME,
        practice: TYPE_OF_FACILITY,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: FACILITY,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: FACILITY_NAME,
        practice: TYPE_OF_FACILITY,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: FACILITY,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: FACILITY_NAME,
        practice: TYPE_OF_FACILITY,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: FACILITY,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
    ],
    providers: [
      {
        providerName: PROVIDER_NAME,
        practice: TYPE_OF_PROVIDER,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: PROVIDER,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: PROVIDER_NAME,
        practice: TYPE_OF_PROVIDER,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: PROVIDER,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: PROVIDER_NAME,
        practice: TYPE_OF_PROVIDER,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: PROVIDER,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: PROVIDER_NAME,
        practice: TYPE_OF_PROVIDER,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: PROVIDER,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: PROVIDER_NAME,
        practice: TYPE_OF_PROVIDER,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: PROVIDER,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: PROVIDER_NAME,
        practice: TYPE_OF_PROVIDER,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: PROVIDER,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
      {
        providerName: PROVIDER_NAME,
        practice: TYPE_OF_PROVIDER,
        address: ADDRESS,
        phoneNumber: PHNO,
        type: PROVIDER,
        distance: DISTANCE,
        networkStatus: IN_NETWORK,
        speciality: MEDICINE,
      },
    ],
    snackCardProviders: [
      {
        providerId: '0001',
        providerName: PROVIDER_NAME,
        providerType: PROVIDER,
        primaryDegrees: ['MD'],
        specialities: ['Specialties'],
        distance: DISTANCE,
        locationId: '0002',
        networkStatus: IN_NETWORK,
        npi: 'npi',
        healthGradeRating: '3',
        organizationType: ['org type'],
        speciality: 'specialty',
        imageLocation: 'imageLocation',
      },
    ],
    recentSearches: [
      {
        psxSearchTerm: 'Chiropractor 10',
        pesSearchTerm: 'Chiropractor 10',
        createdDate: '2023-02-20T14:06:07.566+00:00',
        category: 'All',
        searchType: 'Other',
      },
      {
        psxSearchTerm: 'Vaccine',
        pesSearchTerm: 'Chiropractor 10',
        createdDate: '2023-02-20T14:06:07.566+00:00',
        category: 'Health Supplies',
        searchType: 'Other',
      },
    ],
    recentVisit: {
      meta: {
        pagingState: {
          pageNum: 1,
          pageSize: 100,
        },
      },
      claimsData: {
        facilities: [
          {
            lastVisitDate: '2023-06-19',
            providerId: '002682624',
            nuccTaxonomyCode: '177F00000X',
            claimOtherIdentifiers: [
              {
                laboratoryClaimIndicator: 'N',
                dmeClaimIndicator: 'N',
              },
            ],
          },
        ],
        primaryCare: [
          {
            lastVisitDate: '2023-05-22',
            providerId: '003157588',
            nuccTaxonomyCode: '163WG0000X',
            claimOtherIdentifiers: [
              {
                laboratoryClaimIndicator: 'N',
                dmeClaimIndicator: 'N',
              },
            ],
          },
        ],
        medicalSpecialists: [
          {
            lastVisitDate: '2023-06-19',
            providerId: '002682624',
            nuccTaxonomyCode: '101200000X',
            claimOtherIdentifiers: [
              {
                laboratoryClaimIndicator: 'N',
                dmeClaimIndicator: 'N',
              },
            ],
          },
        ],
        healthSupplies: [
          {
            lastVisitDate: '2023-06-19',
            providerId: '002682624',
            nuccTaxonomyCode: '332B00000X',
            claimOtherIdentifiers: [
              {
                laboratoryClaimIndicator: 'N',
                dmeClaimIndicator: 'N',
              },
            ],
          },
        ],
      },
    },
    locationSearchResults: {
      features: [
        {
          id: 'postcode.60714732',
          place_name: 'Washington, District of Columbia, 20001, United States',
          type: 'Feature',
        },
      ],
    },
    userLocationSearchResults: {
      features: [
        {
          place_name: '2070 Ridgeview Drive, Allen, Texas 75013, United States',
        },
        {
          id: 'place.71551212',
          place_name: '2070 Ridgeview Drive, Allen, Texas 75013, United States',
        },
      ],
    },
    attested_details: {
      lastDateText: 'Last date attested',
      date: '11/24/2022',
    },
    filters: {
      GenderCode: {
        options: [
          {
            label: 'Male',
            value: 'Male',
          },
          {
            label: 'Female',
            value: 'Female',
          },
        ],
      },
      PreferredProviders: {
        options: [
          {
            label: 'Yes',
            value: 'Y',
          },
          {
            label: 'No',
            value: 'N',
          },
        ],
      },
      PatientReviews: {
        options: [
          {
            label: '5 Stars',
            value: '5',
          },
          {
            label: '4 Stars and up',
            value: '4',
          },
          {
            label: '3 Stars and up',
            value: '3',
          },
          {
            label: '2 Stars and up',
            value: '2',
          },
          {
            label: '1 Star and up',
            value: '1',
          },
        ],
      },
      AccessibilityCode: {
        options: [
          {
            label: 'Ramp',
            value: 'Ramp',
          },
          {
            label: 'Parking',
            value: 'Parking',
          },
        ],
      },
      LanguageCode: {
        options: [
          {
            label: 'English',
            value: 'en',
          },
          {
            label: 'Spanish',
            value: 'es',
          },
        ],
      },
      AcceptingNewPatients: {
        options: [
          {
            label: 'Yes',
            value: 'Y',
          },
          {
            label: 'No',
            value: 'N',
          },
        ],
      },
      HospitalAffiliations: {
        options: [
          {
            label: 'syracuse area health',
            value: '69119',
          },
          {
            label: 'vista medical center east',
            value: '470520',
          },
        ],
      },
      EveningAvailability: {
        options: [
          {
            label: 'Yes',
            value: 'Y',
          },
          {
            label: 'No',
            value: 'N',
          },
        ],
      },
      VirtualCare: {
        options: [
          {
            label: 'Yes',
            value: 'Y',
          },
          {
            label: 'No',
            value: 'N',
          },
        ],
      },
      Medical: {
        options: [
          {
            label: 'Marengo vision center',
            value: '2667939',
          },
          {
            label: 'Antioch eye associates od',
            value: '3545058',
          },
        ],
      },
      WeekendAvailability: {
        options: [
          {
            label: 'Yes',
            value: 'Y',
          },
          {
            label: 'No',
            value: 'N',
          },
        ],
      },
      Distance: {
        options: [
          {
            label: '5 miles',
            value: '5',
          },
          {
            label: '10 miles',
            value: '10',
          },

          {
            label: '25 miles',
            value: '25',
          },
        ],
      },
      FacilityType: {
        options: [
          {
            label: 'Facility 1',
            value: '1',
          },
          {
            label: 'Facility 2',
            value: '2',
          },
        ],
      },
      SpecialtyCode: {
        options: [
          {
            label: 'CARDIOVASCULAR DISEASE',
            value: '006',
          },
          {
            label: 'DERMATOLOGY',
            value: '007',
          },
          {
            label: 'FAMILY PRACTICE',
            value: '008',
          },
        ],
      },
    },
  },
  VIEW_ALL,
  BROWSE_AZ: [
    { label: 'View all', value: 'View all', id: 'all' },
    { label: 'A', value: 'A', id: 'A' },
    { label: 'B', value: 'B', id: 'B' },
    { label: 'C', value: 'C', id: 'C' },
    { label: 'D', value: 'D', id: 'D' },
    { label: 'E', value: 'E', id: 'E' },
    { label: 'F', value: 'F', id: 'F' },
    { label: 'G', value: 'G', id: 'G' },
    { label: 'H', value: 'H', id: 'H' },
    { label: 'I', value: 'I', id: 'I' },
    { label: 'J', value: 'J', id: 'J' },
    { label: 'K', value: 'K', id: 'K' },
    { label: 'L', value: 'L', id: 'L' },
    { label: 'M', value: 'M', id: 'M' },
    { label: 'N', value: 'N', id: 'N' },
    { label: 'O', value: 'O', id: 'O' },
    { label: 'P', value: 'P', id: 'P' },
    { label: 'Q', value: 'Q', id: 'Q' },
    { label: 'R', value: 'R', id: 'R' },
    { label: 'S', value: 'S', id: 'S' },
    { label: 'T', value: 'T', id: 'T' },
    { label: 'U', value: 'U', id: 'U' },
    { label: 'V', value: 'V', id: 'V' },
    { label: 'W', value: 'W', id: 'W' },
    { label: 'X', value: 'X', id: 'X' },
    { label: 'Y', value: 'Y', id: 'Y' },
    { label: 'Z', value: 'Z', id: 'Z' },
    { label: '#', value: '#', id: '#' },
  ],
  DETAILS_CHIPS: [{ text: 'Directions', icon: 'directions' }],
  DETAILS_CHIPS_NAME: [
    { text: 'Call', icon: 'call_end' },
    // ToDo: for December release
    // { text: 'Appointment', icon: 'perm_contact_calendar' },
    { text: 'Email', icon: 'email' },
    { text: 'Website', icon: 'web' },
  ],
  DETAILS_CHIP_NAMES: {
    Directions: 'Directions',
    Call: 'Call',
    Email: 'Email',
    Website: 'Website',
  },
  NETWORK_COVERAGE_CODE: {
    BEHAVIORAL_OBH: 'bh_100',
    MEDICAL: 'md_NatAncCommercial',
    BEHAVIORAL_B: 'bh_Employer%20Guest',
  },
  NETWORK_COVERAGE_CODE_PES: {
    BEHAVIORAL_OBH: '100',
    BEHAVIORAL_B: 'Employer%20Guest',
  },
  COVERAGE_TYPECODE_TO_CHIP: {
    M: 'MEDICAL',
    MC: 'MEDICAL',
    ME: 'MEDICAL',
    MH: 'MEDICAL',
    MS: 'MEDICAL',
    KM: 'MEDICAL',

    D: 'DENTAL',
    DD: 'DENTAL',
    DE: 'DENTAL',
    DM: 'DENTAL',
    DS: 'DENTAL',

    V: 'VISION',
    VE: 'VISION',
    VS: 'VISION',
    DV: 'VISION',

    B: '',
  },
  PROVIDER_DETAILS: {
    ABOUT: ProviderTabs.ABOUT,
    LOCATIONS: ProviderTabs.LOCATIONS,
    COST: ProviderTabs.COST,
    QUALITY: ProviderTabs.QUALITY,
    VIEWONMAP: 'View on map',
    TABS_LIST: [
      ProviderTabs.ABOUT,
      ProviderTabs.LOCATIONS,
      ProviderTabs.COST,
      ProviderTabs.QUALITY,
    ],
    BANNER: {
      REFERRAL_REQUIRED: 'Referral required',
      CONTACT_MY_PCP: 'Contact my PCP',
    },
    ABOUT_TAB: {
      REVIEW_SECTION: {
        WHAT_PATIENTS_ARE_SAYING: 'What patients are saying',
      },
      DETAILS_SECTION: {
        AREAS_OF_EXPERTISE: 'Areas of expertise',
        HOSPITAL_AFFILIATIONS: 'Hospital affiliations',
        NOT_PROVIDED: 'Not Provided',
        SERVICES_PROVIDED: 'Services provided',
        PROVIDER_SPECIALTIES: 'Provider specialties',
        EDUCATION: 'Education',
        CULTURAL_COMPETENCE: 'Cultural Competence',
        OTHER_PROVIDER_DETAILS: 'Other provider details',
        NOT_AVAILABLE: 'Not Available',
        NA: 'N/A',
      },
    },
    COST_TAB: {
      IN_NETWORK,
      COST_AVERAGES: {
        BELOW_AVERAGE: 'Below average',
      },
      GET_COST_ESTIMATE: 'Get cost estimate',
      COMMON_PROCEDURES_AND_COSTS:
        'Cost estimates for care delivered by this provider',
      AVERAGE_COST_NEAR: 'AVERAGE COST NEAR',
      ESTIMATED_TOTAL_COST: 'ESTIMATED TOTAL COST',
      INSURANCE_PAYS: 'INSURANCE PAYS',
      ESTIMATED_OUT_OF_POCKET_COST: 'ESTIMATED OUT-OF-POCKET COST',
      OFFICE_VISITS: 'Office Visits',
      PROCEDURES: 'Procedures',
    },
    QUALITY_TAB: {
      PREMIUM: 'Premium Care Physician',
      QUALITY: 'Quality care physician',
      BOARD_CERTIFICATIONS_TITLE: 'Board certifications',
      CONTENT:
        'This is placeholder content to illustrate quality tab containing unique information',
      PROVIDER_GROUP: 'Provider group',
      POPOVER: {
        PREMIUM: 'Premium Care Physician',
        CONTENT:
          'This physician meets the UnitedHealth Premium® quality care criteria which includes safe, timely, effective and efficient care.',
        POPOVER_LINK_TEXT: 'Learn more about the Premium Program',
      },
    },
    PHYSICIAN_DIRECTORY_TAB: {
      VIEW_ALL,
    },
    LOCATIONS_TAB: {
      DETAILS_SECTION: {
        PROVIDER_LOCATIONS: 'Selected Location',
        IN_NETWORK: 'In-network start date',
      },
      GROUP_AFFILIATION: 'Provider group',
      SPECIALTY_INNETWORK: 'Specialties with in-network provider',
    },
    BEHAVIORAL_HEALTH_TYPECODES: ['B', 'OBH'],
  },
  DETAILS_PAGE_HEADER: {
    TABS: {
      DIRECTIONS: 'Directions',
      ABOUT: 'About',
      LOCATIONS: 'Locations',
      COST: 'Cost',
      QUALITY: 'Quality',
      PHYSICIAN_DIRECTORY: 'Physician Directory',
    },
  },
  FACILITY_DETAILS: {
    ABOUT: FacilityTabs.ABOUT,
    LOCATIONS: FacilityTabs.LOCATIONS,
    PHYSICIAN_DIRECTORY: FacilityTabs.PHYSICIAN_DIRECTORY,
    QUALITY: FacilityTabs.QUALITY,
    VIEWONMAP: 'View on map',
    SORTBY: 'Sort by',
    PROVIDER_ASCENDING: 'Provider A-Z',
    PROVIDER_DESCENDING: 'Provider Z-A',
    TABS_LIST: [
      // FacilityTabs.ABOUT,  // US6204862: hide the About tab for facility details
      FacilityTabs.LOCATIONS,
      FacilityTabs.PHYSICIAN_DIRECTORY,
      FacilityTabs.QUALITY,
    ],
    ABOUT_TAB: {
      PLACEHOLDER:
        'This is placeholder content to illustrate tabs containing unique information.',
    },
    LOCATIONS_TAB: {
      DETAILS_SECTION: {
        FACILITY_LOCATION: 'Selected Location',
        IN_NETWORK: 'In-network start date',
      },
    },
    PHYSICIAN_DIRECTORY_TAB: {
      PLACEHOLDER:
        'This is placeholder content to illustrate tabs containing unique information.',
    },
    QUALITY_TAB: {
      PLACEHOLDER:
        'This is placeholder content to illustrate tabs containing unique information.',
      PATIENT_SAFETY: 'Patient safety ratings',
      LEAPFROG_RATING_INFORMATION: 'Leapfrog Hospital survey information',
      ACCREDITATION_TITLE: 'Accreditation',
      ACCREDITATION: ['The Joint Commission'],
    },
  },
  PROVIDER_GROUP_DETAILS: {
    ABOUT: FacilityTabs.ABOUT,
    TABS_LIST: [
      FacilityTabs.ABOUT,
      FacilityTabs.LOCATIONS,
      FacilityTabs.PHYSICIAN_DIRECTORY,
    ],
    ABOUT_TAB: {
      DETAILS_SECTION: {
        PROVIDER_GROUP_DETAILS: 'Provider group details',
        SPECIALTIES: 'Specialty',
        CULTURAL_COMPLETENCE: 'Cultural competence',
        NATIONAL_PROVIDER_IDENTIFIER: 'National provider identifier',
        PROVIDER_GROUP_ID: 'Provider group ID',
        NOT_AVAILABLE: 'Not Available',
        NA: 'N/A',
      },
    },
  },
  INPUT_MODELS: {
    SPECIALITY_SEARCH_INPUT: 'specialty-search',
    SPECIALITY_SEARCH_REGEX_PATTERN: /[A-Za-z0-9]/,
    PHYSICIAN_DIRECTORY_SEARCH_REGEX_PATTERN: /^[-a-zA-Z ']+$/,
    NUMBERS_REGEX_PATTERN: /^([^0-9]*)$/,
    SPECIALITY_SEARCH_ERROR_DESCRIPTOR:
      'Please enter a valid search term that includes a letter or number',
  },
  INVALID_DATE: 'Invalid date',
  DEVICE_LOCATION: {
    ADDRESS_CITY_STATE_ZIPCODE: 0,
    CITY_STATE_ZIPCODE: 1,
    ZOOM: 13,
    ZOOM_HOVER: 15,
  },
  STORAGE_KEYS: {
    SESSION: {
      LOCATION: 'location',
      CUSTOM_LOCATION: 'customLocation',
      DEVICE_LOCATION: 'deviceLocation',
      MEMBER_INFO: 'member-info',
      MEMBER_ID: 'memberId',
      MEMBER_LOCATION: 'memberLocation',
      ACTIVE_MEMBER_INDEX: 'active-member-index',
      LOGGED_IN_MEMBER_INDEX: 'logged-in-member-index',
      SELECTED_PROVIDER_ID: 'selectedProviderID',
      SELECTED_FACILITY_LOCATION_ID: 'selectedFacilityLocationID',
      MAP_PIN_HIGHLIGHT_ID: 'map-pin-highlight-id',
      MAP_PIN_SELECTED_ID: 'map-pin-select-id',
      CHOOSE_PCP: 'choosePCP',
      GEO_LOCATION: 'geoLocation',
      TYPE_AHEAD_RESULTS: 'searchResults',
      TYPE_AHEAD_SEARCH_RESULTS: 'searchButtonResults',
      LOGGED_IN_MEMBER_INFO: 'logged-in-member-info',
      BREADCRUMB_URLS: 'breadcrumbUrls',
      DEMO_MEMBER_INFO: 'demo-member-info',
      SUPER_USER: 'superUser',
      SELECTED_SEARCH_FILTERS: 'selectedSearchFilters',
      MAP_PIN_COORDS: 'map-pin-coordinates',
      DIRECTIONS_TRIGGERED_FROM: 'directions-triggered-from',
      TYPE_AHEAD_HEADERS: 'typeaheadHeaders',
    },
    LOCAL: {
      LOCATION: 'location',
      ZIPCODE: 'zipcode',
    },
  },
  TRANSLATION: {
    ENGLISH: 'en',
    SPANISH: 'es',
  },
  ALL_DAYS: [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ],
  FACILITY: 'Facility',
  COOKIES: {
    CID: 'cid',
    DEEPLINK: 'deeplink',
    PLAN_SELECTOR: 'PS_Widget',
  },
  PSLOCALE: 'PS_Locale',
  RECIPROCITY_ID: '52',
  PROVIDER_SEARCH_SORT_BY: 'BEST_MATCH',
  TYPEAHEAD_SEARCH_HEADER: 'Provider, facilities and provider groups',
  TYPEAHEAD_PROVIDERS_LINK_MEDICAL:
    'More providers, facilities and provider groups (Medical)',
  TYPEAHEAD_PROVIDERS_LINK_BEHAVIORAL:
    'More providers, facilities and provider groups (Behavioral)',
  MOCK_PROVIDERS: [
    {
      providerId: '002628046',
      providerName: 'Leung Henry',
      primaryDegrees: ['DO'],
      specialities: ['328', '008'],
      healthGradeRating: '3.8',
      digestId: '3D08C9542438D582322769575C519648',
      tin: '203488317',
      npi: '1881662690',
      networkStartDate: '2022-12-15',
      networkEndDate: '9999-12-31',
      distance: '24.90894747284561',
      providerType: 'Practitioner',
      networkStatus: 'INN',
      pcpId: '00002628046 023',
      address: {
        line: ['24953 Paseo De Valencia Ste 13b'],
        city: 'Laguna Hills',
        district: 'Orange',
        state: 'CA',
        postalCode: '92653-4339',
      },
      phones: {
        phone: ['949-425-0321'],
      },
      latitude: '33.6021',
      longitude: '-117.69914',
      locationId: '002628046-203488317-T-91B3AFD9BC1CC9C4433E06739F4EABA0',
      speciality: 'Family Practice',
      facilityType: null,
      imageLocation:
        'https://findcare-dev.uhc.com/bio-pics/bfaa47ab9c35acc27e6c4bc4b0ebe9b03a9e91fc.JPEG',
      organizationType: null,
      isTieredProvider: false,
    },
    {
      providerId: '006955722',
      providerName: 'Tran Vu Dinh',
      primaryDegrees: ['MD'],
      specialities: ['008'],
      healthGradeRating: '3.7',
      digestId: 'CC745AD2F8426CD3AA2BB55E5FD92606',
      tin: '814338703',
      npi: '1720424286',
      networkStartDate: '2020-09-16',
      networkEndDate: '9999-12-31',
      distance: '5.497956158451204',
      providerType: 'Practitioner',
      networkStatus: 'INN',
      pcpId: '00006955722 019',
      address: {
        line: ['10048 Garden Grove Blvd'],
        city: 'Garden Grove',
        district: 'Orange',
        state: 'CA',
        postalCode: '92844-1621',
      },
      phones: {
        phone: ['714-462-6191'],
      },
      latitude: '33.76773',
      longitude: '-117.97324',
      locationId: '006955722-814338703-T-EA135FE6C20470991BE430BC5CFBF4EB',
      speciality: 'Family Practice',
      facilityType: null,
      imageLocation:
        'https://findcare-dev.uhc.com/bio-pics/1adc03eef95c26c866468eab3fd85381a0893c02.JPEG',
      organizationType: null,
      isTieredProvider: false,
    },
    {
      providerId: '007720375',
      providerName: 'Barroga Hannah Maria Teng',
      primaryDegrees: ['DO'],
      specialities: ['008'],
      healthGradeRating: '5.0',
      digestId: '9F46C9A5B487DCA770D45BF17547FE18',
      tin: '271504911',
      npi: '1184120701',
      networkStartDate: '2021-08-01',
      networkEndDate: '9999-12-31',
      distance: '5.313052352517116',
      providerType: 'Practitioner',
      networkStatus: 'INN',
      pcpId: '00007720375 002',
      address: {
        line: ['2110 N Bellflower Blvd'],
        city: 'Long Beach',
        district: 'Los Angeles',
        state: 'CA',
        postalCode: '90815-3126',
      },
      phones: {
        phone: ['562-346-2222'],
      },
      latitude: '33.79496',
      longitude: '-118.12522',
      locationId: '007720375-271504911-T-49215210583286D52C415D21AE8E279B',
      speciality: 'Family Practice',
      facilityType: null,
      imageLocation:
        'https://findcare-dev.uhc.com/cdn/assets/images/default_Practitioner.jpg',
      organizationType: null,
      isTieredProvider: false,
    },
    {
      providerId: '007186429',
      providerName: 'Lee Cameron M',
      primaryDegrees: ['MD'],
      specialities: ['008'],
      healthGradeRating: '5.0',
      digestId: '572BF543749BF06180ABA48069136AFE',
      tin: '271504911',
      npi: '1528412103',
      networkStartDate: '2019-01-01',
      networkEndDate: '9999-12-31',
      distance: '5.313052352517116',
      providerType: 'Practitioner',
      networkStatus: 'INN',
      pcpId: '00007186429 005',
      address: {
        line: ['2110 N Bellflower Blvd'],
        city: 'Long Beach',
        district: 'Los Angeles',
        state: 'CA',
        postalCode: '90815-3126',
      },
      phones: {
        phone: ['562-346-2222'],
      },
      latitude: '33.79496',
      longitude: '-118.12522',
      locationId: '007186429-271504911-T-49215210583286D52C415D21AE8E279B',
      speciality: 'Family Practice',
      facilityType: null,
      imageLocation:
        'https://findcare-dev.uhc.com/bio-pics/55d5f52f24afb7cfb3a4e216a80b3a7c6e0e163e.JPEG',
      organizationType: null,
      isTieredProvider: false,
    },
    {
      providerId: '001956461',
      providerName: 'Hoang Thao Y',
      primaryDegrees: ['DO'],
      specialities: ['008'],
      healthGradeRating: '5.0',
      digestId: 'DA69DA6EC96DC8C3B32479A041AFE79B',
      tin: '954526112',
      npi: '1750348363',
      networkStartDate: '2016-08-01',
      networkEndDate: '9999-12-31',
      distance: '6.136035073466821',
      providerType: 'Practitioner',
      networkStatus: 'INN',
      pcpId: '00001956461 018',
      address: {
        line: ['5001 Lakewood Blvd'],
        city: 'Lakewood',
        district: 'Los Angeles',
        state: 'CA',
        postalCode: '90712-2412',
      },
      phones: {
        phone: ['562-361-5650'],
      },
      latitude: '33.84974',
      longitude: '-118.14256',
      locationId: '001956461-954526112-T-37A3195763F9953098EA396946687F45',
      speciality: 'Family Practice',
      facilityType: null,
      imageLocation:
        'https://findcare-dev.uhc.com/bio-pics/df9b896353f8754181574183231ee084586743d1.JPEG',
      organizationType: null,
      isTieredProvider: false,
    },
  ],
  ADOBE_TRACKING: {
    VIRTUAL_CARE: 'virtual care',
    NEW_PATIENTS: 'accepting new patients',
    PCP_SERVICES: 'pcp services',
    EVENING_APPOINTMENT: 'evening appointments',
    DETAILS_SITESECTION1: 'result details',
    VIEWALL_SITESECTION1: 'search results',
    VIEWALL_SITESECTION2: 'provider results',
  },
  DRAWER_NAME: {
    BROWSE_ALL_SPECIALTIES: 'all specialties',
    REFERRAL_REQUIRED_SPECIALTIES: 'referral required specialties',
  },
  SPECIALTIES_REQUIRED_REFERRAL: 'specialties requiring referral',
  MOBILE_LIST_VIEW: 'Mobile List View',
  DETAILS_PAGE_HEADER_DIRECTIONS_BUTTON:
    'Details Page Header Directions Button',
};
