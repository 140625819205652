import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { IconBrand } from '@abyss/web/ui/IconBrand';

export const EAPCodeBoxContainer = styled(Box, {
  '&.abyss-box-root': {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    background: '$tint2',
    height: '43px',
    width: '360px',
    padding: '8px',
    '.abyss-popover-trigger ~ div': {
      zIndex: '9 !important',
    },

    '@screen < $sm': {
      height: '81px',
      width: '100%',
    },
    '@screen < $md': {
      height: '100%',
    },
  },
});

export const EAPIcon = styled(IconBrand, {
  '&.abyss-icon-brand': {
    position: 'relative',
    top: '-2px',
    '@screen < $md': { top: '-3px' },
  },
});

export const EAPPopoverStyling = {
  'abyss-popover-trigger-icon': {
    width: '16px',
    height: '16px',
    marginBottom: '2px',
    '@screen < $sm': { marginBottom: '6px' },
  },
};

export const EAPCodeNeeded = styled(Flex, {
  '&.abyss-flex-root': {
    display: 'flex',
    flexWrap: 'nowrap',
    fontWeight: '500',
    fontSize: '18px',
    gap: '4px',
    lineHeight: '24px',
    paddingLeft: '8px',
    color: '$primary1',
    '@screen < $sm': {
      fontSize: '16px',
      paddingLeft: '0',
      lineHeight: '20px',
      paddingRight: '120px',
    },
  },
});

export const EAPGetMyCodeLink = {
  'abyss-link-root': {
    color: '$info1',
    fontWeight: '700',
    cursor: 'pointer',
    display: 'flex',
    lineHeight: '20px',
    marginLeft: '16px',
    marginBottom: '3px',
    '@screen < $sm': {
      paddingTop: '20px',
      marginLeft: '8px',
    },
  },
};

export const EAPCodeText = styled('div', {
  marginLeft: '4px',
});
