import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../../common/Constants';
import {
  hideScrollbar,
  mediumScreenOnly,
} from '../../../../../common/ConstantsStyles';
import { MapDisplay } from '../../../../../common/MapDisplay';
import { getGeoLocationFromStorage } from '../../../../../common/PSXHeader/SearchBar/utils';
import { SelectLocationCard } from '../../../../../common/SelectLocationCard';
import { ProviderLocation } from '../../../../../models/ProviderDetails';
import { Directions } from '../../../../../models/RouteDirections';
import {
  getRoute,
  setResultIcon,
  setZoomLevel,
} from '../../../../../utils/map.utils';
import {
  ChooseLocationCardsContainer,
  ChooseLocationLabel,
  ChooseLocationRow,
  LocationsWrapColumn,
  MapViewWrap,
  MobileMapViewWrap,
  NewPatientDisclaimer,
  SidePaneCloseButton,
  ViewMapButton,
} from './ChoosePCPLocation.styled';

type Props = {
  hasMultipleLocations: boolean;
  isMobileMapView: boolean;
  mobileScreen: boolean;
  providerLocations: ProviderLocation[];
  saveAndCancelButtons: React.ReactNode;
  setIsMobileMapView: (isMobileMapView: boolean) => void;
  useStickyButtonRow: boolean;
  setSelectedAddressIndex: (selectedAddressIndex: number) => void;
};
export const LocationsMapViewWrap = ({
  hasMultipleLocations,
  isMobileMapView,
  mobileScreen,
  providerLocations,
  saveAndCancelButtons,
  setIsMobileMapView,
  setSelectedAddressIndex,
  useStickyButtonRow,
}: Props) => {
  const [closeSidePanel, setCloseSidePanel] = useState<boolean>(false);
  const [routeEndCoords, setRouteEndCoords] = useState<
    [number | null, number | null]
  >([null, null]);
  const [directions, setDirections] = useState<Directions>({
    userPlaceName: '',
    endPlaceName: '',
    routes: [],
  });
  const mediumScreen = useMediaQuery(mediumScreenOnly);
  const map = React.useRef<mapboxgl.Map | null>(null);
  const { t } = useTranslation();
  const { longitude, latitude } = getGeoLocationFromStorage();
  const [highlightId, setHighlightId] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID,
    { providerId: '', from: 'mapView' }
  );
  const [selectedId, setSelectedId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );

  const [mapPinCoords] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_COORDS,
    []
  );

  const highlightRefs = React.useRef<any>({});
  const mobileMapViewClassName = isMobileMapView
    ? 'location-card-map-view'
    : '';

  useEffect(() => {
    setSelectedId(null);
  }, []);

  useEffect(() => {
    highlightRefs.current[highlightId.providerId]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [highlightId]);

  useEffect(() => {
    map.current?.resize();
    if (!closeSidePanel) {
      setZoomLevel(map.current, mapPinCoords);
      // map.current?.flyTo({
      //   center: [+longitude, +latitude],
      // });
    }
  }, [closeSidePanel]);

  const handleMapButton = () => {
    setIsMobileMapView(true);
  };
  const handleClickLocation = async (
    index,
    locationId,
    locationLng,
    locationLat
  ) => {
    if (selectedId === locationId) return;
    const routeDirections = await getRoute(
      map.current,
      longitude,
      latitude,
      +locationLng,
      +locationLat,
      mediumScreen,
      'driving-traffic'
    );
    if (routeDirections) setDirections(routeDirections);
    setRouteEndCoords([locationLng, locationLat]);
    setSelectedId(locationId);
    setSelectedAddressIndex(index);
  };

  const handleLocationCardMouseEnter = async (
    index,
    location,
    flyToPin = false
  ) => {
    await setZoomLevel(map.current, mapPinCoords);
    setResultIcon(
      map.current,
      index,
      true,
      setHighlightId,
      location.locationId
    );
    if (flyToPin) {
      map.current?.flyTo({
        center: [+location.longitude, +location.latitude],
        zoom: Constants.DEVICE_LOCATION.ZOOM,
      });
    }
  };

  const handleLocationCardMouseLeave = (index) => {
    if (!selectedId) {
      setResultIcon(map.current, index, false, setHighlightId, null);
    }
  };

  const chooseALocationHeading =
    hasMultipleLocations && !isMobileMapView ? (
      <ChooseLocationRow
        data-auto-testid="choose-a-location-row"
        data-testid="choose-a-location-row"
      >
        <ChooseLocationLabel
          color="$primary1"
          display="h4"
          fontWeight="$bold"
          offset={1}
        >
          {t('CHOOSE_PCP_FLOW.CHOOSE_A_LOCATION')}
        </ChooseLocationLabel>
        {mobileScreen ? (
          <ViewMapButton
            before={
              <IconMaterial
                color="$interactive1"
                icon="map"
                isScreenReadable
                size="15px"
              />
            }
            data-auto-testid="map-button"
            data-testid="map-button"
            onClick={handleMapButton}
            variant="ghost"
          >
            {t('View Map')}
          </ViewMapButton>
        ) : null}
      </ChooseLocationRow>
    ) : null;

  return (
    <MobileMapViewWrap
      className={isMobileMapView ? 'show-mobile-map-view' : ''}
    >
      <LocationsWrapColumn
        className="locations-wrap-column"
        css={hideScrollbar}
      >
        {(!closeSidePanel || mobileScreen) && (
          <Layout.Stack alignItems="left" grow space={12}>
            {chooseALocationHeading}
            <ChooseLocationCardsContainer
              aria-label="Choose a location"
              className="choose-location-container"
              growrole="group"
            >
              {providerLocations?.map((location, index) => (
                <div
                  key={`pcp-location-${index + 1}`}
                  ref={(el) => {
                    highlightRefs.current[location.locationId] = el;
                  }}
                  className="location-cards-container"
                  data-auto-testid="choose-pcp-location-section"
                  data-testid="choose-pcp-location-section"
                  onBlur={() => handleLocationCardMouseLeave(index)}
                  onFocus={() => handleLocationCardMouseEnter(index, location)}
                  onMouseEnter={() =>
                    handleLocationCardMouseEnter(index, location)
                  }
                  onMouseLeave={() => handleLocationCardMouseLeave(index)}
                  tabIndex={-1}
                >
                  {location?.acceptingNewPatients && (
                    <SelectLocationCard
                      className={`
                      ${mobileMapViewClassName} ${
                        highlightId.providerId === location.locationId
                          ? 'highlight'
                          : ''
                      }
                    `}
                      isMobileMapView={isMobileMapView}
                      location={location}
                      selected={
                        hasMultipleLocations &&
                        selectedId === location.locationId
                      }
                      selectedAddressIndex={index}
                      selectLocation={handleClickLocation}
                      setIsMobileMapView={setIsMobileMapView}
                      showBadgeRow
                      showRadioSelect={hasMultipleLocations}
                    />
                  )}
                </div>
              ))}
            </ChooseLocationCardsContainer>

            {!isMobileMapView ? (
              <NewPatientDisclaimer
                data-auto-testid="patient-disclaimer"
                data-testid="patient-disclaimer"
              >
                <Text fontWeight="$medium" size={12.64}>
                  {t('CHOOSE_PCP_FLOW.NEW_PATIENT_DISCLAIMER')}
                </Text>
              </NewPatientDisclaimer>
            ) : null}
            {!useStickyButtonRow ? saveAndCancelButtons : null}
          </Layout.Stack>
        )}
        <SidePaneCloseButton
          alignItems="center"
          className="side-btn"
          justify="center"
        >
          <IconMaterial
            css={{
              fill: '$black',
            }}
            data-auto-testid="sidepanel-close-icon"
            data-testid="sidepanel-close-icon"
            icon={
              closeSidePanel ? 'keyboard_arrow_right' : 'keyboard_arrow_left'
            }
            onClick={() => {
              adobeLinkTrackEvent({
                name: `sidepanel:${closeSidePanel ? 'open' : 'close'}`,
                location: 'body:map',
              });
              setCloseSidePanel(!closeSidePanel);
            }}
            size="$md"
          />
        </SidePaneCloseButton>
      </LocationsWrapColumn>
      <MapViewWrap cssProps={{ closeSidePanel }}>
        {providerLocations?.length ? (
          <MapDisplay
            coords={{ longitude, latitude }}
            directions={directions}
            locationResults={providerLocations}
            map={map}
            refToBeFocusedOnDirectionsClose={
              Object.values(highlightRefs.current)[0] as HTMLElement
            }
            routeEndCoords={routeEndCoords}
            setRouteEndCoords={setRouteEndCoords}
          />
        ) : null}
      </MapViewWrap>
    </MobileMapViewWrap>
  );
};
