import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SectionContainer,
  TierOneAlertContainer,
  VoiceOnlyText,
} from './ProviderLocationsDetails.style';

type Props = {
  showNonTieredAlert: boolean;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};
export const TierOneAlert = ({
  showNonTieredAlert,
  isVisible,
  setIsVisible,
}: Props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {showNonTieredAlert ? (
        <SectionContainer css={{ marginBottom: '$sm' }}>
          <VoiceOnlyText>Alert</VoiceOnlyText>
          <TierOneAlertContainer
            data-auto-testid="alert-header-container"
            data-testid="alert-header-container"
            isVisible={isVisible}
            onClose={() => {
              setIsVisible(false);
            }}
            title={t('Not a Tier 1 Provider Info')}
            variant="info"
          />
        </SectionContainer>
      ) : null}
    </React.Fragment>
  );
};
