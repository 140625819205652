import { styled } from '@abyss/web/tools/styled';
import { Heading } from '@abyss/web/ui/Heading';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const TitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '32px',
  paddingTop: '8px',
  flexWrap: 'wrap',
  '@screen < $md': {
    padding: '0 $md 12px',
  },
});
type SearchResultsTitlePropType = {
  choosePCP: Boolean;
  offset: Number;
  resultType: String;
  searchTitle: String;
};
export const SearchResultsTitle = ({
  choosePCP,
  offset,
  resultType,
  searchTitle,
}: SearchResultsTitlePropType) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {!choosePCP && searchTitle && (
        <TitleContainer>
          <Heading
            css={{
              'abyss-heading-root': {
                '@screen < $md': {
                  fontSize: '$mediumText !important',
                  lineHeight: '$lg !important',
                },
              },
            }}
            data-auto-testid="results-heading"
            data-testid="results-heading"
            display="h4"
            offset={1}
            textAlign="left"
          >
            {t(`${resultType}-results-for`)} {searchTitle}
          </Heading>
        </TitleContainer>
      )}
      {choosePCP && (
        <TitleContainer>
          <Heading
            css={{
              'abyss-heading-root': {
                '@screen < $sm': {
                  fontSize: '$mediumText !important',
                  lineHeight: '$lg !important',
                },
              },
            }}
            data-testid="provider-results-for-choose-pcp"
            display="h4"
            offset={offset}
            textAlign="left"
          >
            {t('Results for')} {t('primary care doctor')}
          </Heading>
        </TitleContainer>
      )}
    </Fragment>
  );
};
