import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Card } from '@abyss/web/ui/Card';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useLagoon } from '../../hooks/useLagoon';
import { Provider } from '../../models/Provider';
import { ProviderType } from '../../models/ProviderDetails';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { clearRoute, setResultIcon, setZoomLevel } from '../../utils/map.utils';
import {
  getPrimaryPhone,
  getProviderSpecialtyOrOrgTypeCode,
  parseProviderName,
} from '../../utils/providerDetails.utils';
import { adobeSearchResultClickEvent } from '../AdobeTagging/adobeSearchResultClickEvent';
import { Constants } from '../Constants';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { ImageComponent } from '../ImageComponent';
import { TierOne } from '../SnackCard/TierOne';
import { getFeatureFlag } from '../Utils';
import {
  convertProviderTypeToAdobeType,
  formatProviderId,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { Address } from './Address';
import { BestMatchDetails } from './BestMatchDetails';
import {
  AvatarDataCard,
  BottomWrapper,
  BottomWrapperChoosePCP,
  CardContainer,
  ChooseAsPCPButton,
  DataCardAvatarContainer,
  DataContainer,
  HeadingWrapper,
  MilesAndAddressWrapper,
  NetworkWrapper,
  NoRatingText,
  PriceText,
  ProviderCardContent,
  RatingText,
  RatingTextWrapper,
  ResponsiveText,
  VirtualVisitLabel,
  VoiceOnlyText,
  displayVirtualVisitStyle,
  providerNameDataCardTextStyles,
  providerNameToolTipStyles,
  specialityNameToolTipStyles,
} from './DataCard.style';
import { DataCardSkeletonLoader } from './DataCardSkeletonLoader';
import { Direction } from './Direction';
import { Miles } from './Miles';
import { NationalAncillaryFacilityCard } from './NationalAncillaryFacilityCard';
import { Phone } from './Phone';

// import { Schedule } from './Schedule';

type Props = {
  practitioner?: Provider;
  css?: any;
  sectionType?: string;
  updatePin?: (practitioner?: Provider) => void;
  isHorizontalDisplay?: boolean;
  isLoading?: boolean;
  handleDirectionClick?: () => void;
  index?: number;
  scrollIntoView?: (a: number) => void;
  disableCost?: boolean;
  decemberFlag?: boolean;
  locationForAnalytics?: string;
  pageNumber?: number;
  map?: any;
  selectedFilters?: string;
  setRouteEndCoords?(coords: [number | null, number | null]): void;
  usedAt?: string;
  searchTerm?: string;
  navigateToDirections?: boolean;
  setNavigateToDirections?: (a: boolean) => void;
  mobileRouteView?: boolean;
  headers?: ResponseHeaders;
};

export const DataCard = ({
  practitioner = {},
  css = { width: '100%' },
  sectionType,
  updatePin,
  isHorizontalDisplay = false,
  isLoading = false,
  handleDirectionClick,
  index = 0,
  scrollIntoView,
  decemberFlag,
  disableCost,
  locationForAnalytics,
  pageNumber,
  map,
  selectedFilters,
  setRouteEndCoords,
  usedAt = '',
  searchTerm = '',
  navigateToDirections = false,
  setNavigateToDirections,
  mobileRouteView = false,
  headers = { correlationId: '' },
}: Props) => {
  const cardRef = useRef<HTMLElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const {
    providerName,
    primaryDegrees,
    address,
    phones,
    networkStatus,
    distance,
    providerId = '',
    speciality,
    locationId,
    healthGradeRating,
    organizationType = [''],
    providerType,
    isTieredProvider,
    website,
  } = practitioner;

  const { t } = useTranslation();

  const choosePcpTestId: string = 'choose-pcp-button';

  const { searchFilters, setSearchFilters } = useContext(SearchFilterContext);

  const [isOpen, setIsOpen] = useState(false);
  const featureFlags = useLagoon('feature-flags')();
  const showBestMatchDetails: boolean = getFeatureFlag(
    featureFlags,
    'RESULT_PAGE_CUSTOM_SORT'
  );

  const [mapPinCoords] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_COORDS,
    []
  );

  const [selectedId, setSelectedId] = useSessionStorage<string | null>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );

  const [highlightId, setHighlightId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID,
    { providerId: '', from: '' }
  );

  if (
    providerId === highlightId?.providerId &&
    highlightId.from === 'map' &&
    scrollIntoView
  ) {
    scrollIntoView(index);
  }

  const setPinOnMap = () => {
    if (updatePin) {
      updatePin(practitioner);
    }
  };

  const name = parseProviderName(
    providerName,
    providerType,
    primaryDegrees?.[0]
  );

  const hightLightClassName = `${
    providerId === highlightId?.providerId ? 'active' : ''
  }`;

  const inOrOutOfNetwork = networkStatus
    ? t('In-network')
    : t('Not-In-Network');

  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { choosePCP, sectionType: sectionTypeToken } = tokenData;
  const sectionTypeDerived = sectionType || sectionTypeToken;
  const phone = phones?.phone;
  const appointment = phones?.appointment;
  const primaryPhone = getPrimaryPhone(phone, appointment);

  const pageNumTxt = pageNumber ? `:page ${pageNumber}` : '';
  const linkLocationName = `body:${locationForAnalytics} card${pageNumTxt}`;

  const getLinkNameForSearchResultEvent = (
    providerTypeInfo?: string,
    clickSection?: string
  ) => {
    let linkName: string | undefined;

    if (clickSection && clickSection !== '') {
      linkName = clickSection;
    } else {
      linkName = `${convertProviderTypeToAdobeType(providerTypeInfo)} name`;
    }
    return linkName;
  };

  const getLinkPositionIndex = (linkPosition?: number) => {
    if (linkPosition !== undefined) {
      return linkPosition + 1;
    }
    return linkPosition;
  };

  const handleAdobeSearchResultClickEvent = (clickSection?: string) => {
    adobeSearchResultClickEvent({
      filters: selectedFilters,
      linkName: getLinkNameForSearchResultEvent(providerType, clickSection),
      linkPosition: getLinkPositionIndex(index),
      linkLocation: linkLocationName,
      customAttributesBlock: {
        correlationId: headers.correlationId,
        providerId: formatProviderId(practitioner),
        providerType: convertProviderTypeToAdobeType(providerType),
      },
      term: searchTerm,
    });
  };

  const handleDetailsOnClick = ({ showDirection }) => {
    handleAdobeSearchResultClickEvent();

    let detailsRoute;
    switch (sectionTypeDerived) {
      case Constants.RESULT_SECTION.FACILITY_CAPITAL:
        detailsRoute = ConstantsRoutes.FACILITY_DETAILS.path;
        break;
      case Constants.RESULT_SECTION.PROVIDER_GROUPS:
        detailsRoute = ConstantsRoutes.PROVIDER_GROUP_DETAILS.path;
        break;
      default:
        detailsRoute = ConstantsRoutes.PROVIDER_DETAILS.path;
        break;
    }

    const updatedToken = tokenizer.update(token, {
      providerId,
      sectionType: sectionTypeDerived,
      locationId,
      showDirection,
      isTieredDataCard: isTieredProvider,
    });

    setSearchFilters({
      ...searchFilters,
      PageNumber: pageNumber,
    });

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      providerId
    );

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_FACILITY_LOCATION_ID,
      locationId
    );
    navigate(`${detailsRoute}${updatedToken}`);
  };

  const handleChoosePCPClick = (event) => {
    adobeSearchResultClickEvent({
      linkName: choosePcpTestId,
      linkPosition: getLinkPositionIndex(index),
      linkLocation: linkLocationName,
      customAttributesBlock: {
        correlationId: headers.correlationId,
        providerId: formatProviderId(practitioner),
        providerType: convertProviderTypeToAdobeType(providerType),
      },
    });
    const updatedToken = tokenizer.update(token, {
      choosePCPId: event.currentTarget.dataset.providerid,
      selectedProviderType: providerType,
      originLinkNameForAnalytics: choosePcpTestId,
    });

    navigate(`/choose-pcp/locations/${updatedToken}`);
  };

  useEffect(() => {
    if (navigateToDirections && selectedId === locationId) {
      handleDirectionClick?.();
    }
  }, [navigateToDirections]);

  useEffect(() => {
    if (!mobileRouteView && navigateToDirections) {
      setNavigateToDirections?.(false);
      setSelectedId(null);
    }
  }, [mobileRouteView]);

  const showDirection = () => {
    if (handleDirectionClick) {
      if (usedAt === Constants.MOBILE_LIST_VIEW) {
        setNavigateToDirections?.(true);
        setSelectedId(locationId || null);
      } else {
        handleDirectionClick();
      }
    } else {
      handleDetailsOnClick({ showDirection: true });
    }
  };

  const handleHoverOnCard = async () => {
    if (map?.current) {
      await setZoomLevel(map.current, mapPinCoords);
      setResultIcon(map.current, +providerId, true, setHighlightId, providerId);
      setPinOnMap();
    }
    setHighlightId({ providerId, from: usedAt });
  };

  const handleNavigationOnClick = () => {
    if (map?.current && setRouteEndCoords) {
      clearRoute(
        map.current,
        setRouteEndCoords,
        highlightId.providerId,
        setHighlightId
      );
      setSelectedId(null);
    }
    showDirection();
  };

  const onCardClick = () => {
    if (showBestMatchDetails) {
      handleAdobeSearchResultClickEvent('datacard click');
      setIsOpen(true);
    }
  };

  const inOrOutOfNetworkColor = networkStatus ? '$success1' : '';
  const providerSpeciality = getProviderSpecialtyOrOrgTypeCode(
    providerType,
    organizationType,
    speciality
  );

  const imgSrc = practitioner?.imageLocation;

  const displayVirtualVisit =
    decemberFlag &&
    practitioner?.address &&
    // practitioner?.isVirtualCareOffered && // will be enabled once api supports the field in response
    providerType === ProviderType.PRACTITIONER;

  // TODO: ref is undefined , look into it
  if (isLoading)
    return (
      <React.Fragment>
        <DataContainer>
          <CardContainer data-testid="skeleton-loader">
            <DataCardSkeletonLoader />
          </CardContainer>
        </DataContainer>
      </React.Fragment>
    );

  if (providerType === ProviderType.ORGANIZATION && !address?.line[0])
    return (
      <React.Fragment>
        <DataContainer>
          <NationalAncillaryFacilityCard
            cardRef={cardRef}
            css={css}
            facilityName={providerName || ''}
            headers={headers}
            highlightClassName={hightLightClassName}
            index={index}
            linkLocationName={linkLocationName}
            networkStatus
            practitioner={practitioner}
            providerId={providerId}
            providerSpeciality={providerSpeciality}
            selectedFilters={selectedFilters}
            setHighlightId={setHighlightId}
            updatePin={handleHoverOnCard}
            website={website || ''}
          />
        </DataContainer>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <DataContainer>
        <TierOne
          data-auto-testid="tier-one-data-card-tag"
          data-testid="tier-one-data-card-tag"
          isHorizontalDisplay={isHorizontalDisplay}
          isTierOne={isTieredProvider}
        />
        <CardContainer
          ref={cardRef}
          className={hightLightClassName}
          css={css}
          data-auto-testid="generic-data-card"
          data-testid="generic-data-card"
          id={`data-card-for-map-${providerId}`}
          onClick={onCardClick}
          onMouseEnter={handleHoverOnCard}
          style={{ cursor: 'pointer' }}
        >
          <Card.Section
            css={{
              marginTop: '12px',
              padding: '0 16px',
              '@screen < $sm': { padding: '0 12px' },
              height: '100px',
            }}
          >
            <Flex>
              <Flex>
                <DataCardAvatarContainer>
                  <AvatarDataCard>
                    {ImageComponent({ imgRef, providerType, imgSrc })}
                  </AvatarDataCard>
                  {displayVirtualVisit && (
                    <Flex color="$primary1" css={displayVirtualVisitStyle}>
                      <IconMaterial
                        aria-hidden="true"
                        color="white"
                        icon="videocam"
                        size={16}
                        variant="filled"
                      />
                    </Flex>
                  )}
                </DataCardAvatarContainer>
              </Flex>

              <Flex css={{ flex: 1, marginLeft: '8px' }} direction="column">
                <HeadingWrapper
                  alignLayout="left"
                  data-auto-testid={`data-card-heading-${sectionTypeDerived}`}
                  data-testid={`data-card-heading-${sectionTypeDerived}`}
                  onClick={handleDetailsOnClick}
                >
                  <Tooltip
                    asChild={false}
                    content={name}
                    css={providerNameToolTipStyles}
                    position="top"
                    positionOffset={8}
                  >
                    <Text
                      color="$info1"
                      css={providerNameDataCardTextStyles}
                      data-auto-testid={`data-card-detail-button-${sectionTypeDerived}`.replace(
                        / /g,
                        '-'
                      )}
                      data-testid={`data-card-detail-button-${sectionTypeDerived}`.replace(
                        / /g,
                        '-'
                      )}
                      fontWeight="$bold"
                      size="$lg"
                    >
                      {name}
                    </Text>
                  </Tooltip>
                  <IconMaterial
                    className="arrow_forward"
                    css={{
                      width: '24px',
                      height: '12px',
                    }}
                    data-auto-testid={`data-card-arrow-forward-icon-${sectionTypeDerived}-${providerId}`}
                    data-testid={`data-card-arrow-forward-icon-${sectionTypeDerived}-${providerId}`}
                    icon="arrow_forward_ios"
                  />
                </HeadingWrapper>
                <Flex
                  css={{ padding: '2px $md 2px $sm' }}
                  justify="space-between"
                >
                  <Flex direction="column">
                    {providerSpeciality && (
                      <Tooltip
                        content={providerSpeciality}
                        css={specialityNameToolTipStyles}
                        position="top"
                        positionOffset={8}
                      >
                        <ResponsiveText
                          as="div"
                          data-auto-testid={`data-card-specialty-${sectionTypeDerived}`}
                          data-testid={`data-card-specialty-${sectionTypeDerived}`}
                          fontWeight="$medium"
                        >
                          {providerSpeciality}
                        </ResponsiveText>
                      </Tooltip>
                    )}
                    {displayVirtualVisit && (
                      <Layout.Group>
                        <IconMaterial
                          color="$primary1"
                          icon="videocam"
                          size={20}
                        />
                        <VirtualVisitLabel>
                          Offers virtual visits
                        </VirtualVisitLabel>
                      </Layout.Group>
                    )}
                    <Flex alignItems="center">
                      {sectionTypeDerived ===
                        Constants.RESULT_SECTION.PROVIDER && (
                        <React.Fragment>
                          <ProviderCardContent data-testid="provider-ratings">
                            {healthGradeRating && (
                              <RatingTextWrapper
                                data-auto-testid="rating-wrap"
                                data-testid="rating-wrap"
                              >
                                <VoiceOnlyText>
                                  {`${healthGradeRating} stars`}
                                </VoiceOnlyText>
                                {Constants.RATE_LIMITING.map(
                                  (param, ratingIndex) => {
                                    const ratingAsInt = parseInt(
                                      healthGradeRating,
                                      10
                                    );
                                    return (
                                      <span key={param} aria-hidden="true">
                                        <IconMaterial
                                          css={{
                                            'abyss-icon': {
                                              position: 'relative',
                                              top:
                                                param < ratingAsInt
                                                  ? '0px'
                                                  : '1px',
                                              fill:
                                                param < ratingAsInt
                                                  ? '$secondary2'
                                                  : '$black',
                                              stroke:
                                                param < ratingAsInt
                                                  ? '$starBorder'
                                                  : '',
                                            },
                                          }}
                                          data-auto-testid={`data-card-account-star-icon-${sectionTypeDerived}-${
                                            providerId + ratingIndex
                                          }`}
                                          data-testid={`data-card-account-star-icon-${sectionTypeDerived}-${
                                            providerId + ratingIndex
                                          }`}
                                          icon={
                                            param < ratingAsInt
                                              ? 'star_rate'
                                              : 'star_border'
                                          }
                                          size={
                                            param < ratingAsInt
                                              ? '13.33px'
                                              : '14.33px'
                                          }
                                        />
                                      </span>
                                    );
                                  }
                                )}
                                <RatingText
                                  aria-hidden="true"
                                  className="text"
                                  color="$primary1"
                                  data-auto-testid={`data-card-rating-${sectionTypeDerived}`}
                                  data-testid={`data-card-rating-${sectionTypeDerived}`}
                                >
                                  {healthGradeRating}
                                </RatingText>
                              </RatingTextWrapper>
                            )}
                            {!healthGradeRating && (
                              <NoRatingText data-testid="provider-no-rating">
                                {t('Ratings not available')}
                              </NoRatingText>
                            )}
                          </ProviderCardContent>
                        </React.Fragment>
                      )}
                    </Flex>
                  </Flex>
                  <Flex alignItems="end" direction="column">
                    <NetworkWrapper
                      data-testid={`data-card-network-${sectionTypeDerived}-section`}
                    >
                      <IconMaterial
                        color={inOrOutOfNetworkColor}
                        data-auto-testid={`data-card-check-circle-icon-${sectionTypeDerived}-${providerId}`}
                        data-testid={`data-card-check-circle-icon-${sectionTypeDerived}-${providerId}`}
                        icon="check_circle"
                        size={16}
                      />
                      <ResponsiveText
                        data-auto-testid={`data-card-network-${sectionTypeDerived}-${providerId}`}
                        data-testid={`data-card-network-${sectionTypeDerived}-${providerId}`}
                        fontWeight="$medium"
                      >
                        {inOrOutOfNetwork}
                      </ResponsiveText>
                    </NetworkWrapper>
                    {disableCost ? null : (
                      <PriceText
                        color="$interactive2"
                        data-auto-testid={`data-card-account-Dollar-${sectionTypeDerived}-${providerId}`}
                        data-testid={`data-card-account-Dollar-${sectionTypeDerived}-${providerId}`}
                      >
                        $$$
                      </PriceText>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Card.Section>

          <MilesAndAddressWrapper
            data-auto-testid="datacard-address"
            data-testid="datacard-address"
          >
            <IconMaterial
              data-auto-testid={`data-card-location-on-icon-${sectionTypeDerived}-${providerId}`}
              data-testid={`data-card-location-on-icon-${sectionTypeDerived}-${providerId}`}
              icon="location_on"
              size={24}
            />
            <Miles
              distance={distance || ''}
              headers={headers}
              id={providerId}
              indexForAnalytics={getLinkPositionIndex(index)}
              locationForAnalytics={linkLocationName}
              onClosePopup={() => setIsOpen(false)}
              practitioner={practitioner}
              providerType={providerType}
              searchTerm={searchTerm}
              sectionType={sectionTypeDerived}
              selectedFilters={selectedFilters}
            />

            <Address
              address={address}
              id={providerId}
              sectionType={sectionTypeDerived}
            />
          </MilesAndAddressWrapper>

          {choosePCP ? (
            <BottomWrapperChoosePCP alignItems="center" justify="space-between">
              <ChooseAsPCPButton
                data-auto-testid={choosePcpTestId}
                data-providerid={providerId}
                data-testid={choosePcpTestId}
                onClick={handleChoosePCPClick}
                size="$sm"
                variant="outline"
              >
                {t('PRIMARY_CARE_PROVIDER.CHOOSE_PCP')}
              </ChooseAsPCPButton>
              <Phone
                headers={headers}
                id={providerId}
                indexForAnalytics={getLinkPositionIndex(index)}
                locationForAnalytics={linkLocationName}
                phone={primaryPhone}
                practitioner={practitioner}
                providerType={providerType}
                searchTerm={searchTerm}
                sectionType={sectionTypeDerived}
                selectedFilters={selectedFilters}
              />
            </BottomWrapperChoosePCP>
          ) : (
            <BottomWrapper justify="space-evenly">
              <Direction
                handleClick={() => {
                  handleAdobeSearchResultClickEvent('address');
                  handleNavigationOnClick();
                }}
                id={providerId}
                sectionType={sectionTypeDerived}
              />
              <div style={{ height: 'auto' }}>
                <Divider margin={0} orientation="vertical" width={1} />
              </div>
              <Phone
                headers={headers}
                id={providerId}
                indexForAnalytics={getLinkPositionIndex(index)}
                locationForAnalytics={linkLocationName}
                phone={primaryPhone}
                practitioner={practitioner}
                providerType={providerType}
                searchTerm={searchTerm}
                sectionType={sectionTypeDerived}
                selectedFilters={selectedFilters}
              />
              {/* <Schedule id={providerId} sectionType={sectionTypeDerived} /> */}
            </BottomWrapper>
          )}
        </CardContainer>
        {showBestMatchDetails ? (
          <BestMatchDetails
            isOpen={isOpen}
            provider={practitioner}
            setIsOpen={setIsOpen}
          />
        ) : null}
      </DataContainer>
    </React.Fragment>
  );
};
