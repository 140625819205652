type RouteDefinition = {
  [key: string]: {
    key: string;
    name: string;
    pathWithToken?: string;
    path: string;
  };
};

export const ConstantsRoutes: RouteDefinition = {
  PROVIDER_SEARCH_RESULTS: {
    key: 'provider_search_results',
    name: 'ProviderSearchResults',
    pathWithToken: '/results/:token',
    path: '/results',
  },
  NULL_SEARCH_RESULTS: {
    key: 'null_search_results',
    name: 'NullSearchResults',
    pathWithToken: '/results/:token',
    path: '/results',
  },
  NULL_SPECIALTY_RESULTS: {
    key: 'no-specialty-results',
    name: 'NullResultPageForSpecialty',
    pathWithToken: '/no-specialty-results/:token',
    path: '/no-specialty-results',
  },
  PROVIDER_SEARCH_RESULTS_VIEW_ALL: {
    key: 'provider_search_results_view_all',
    name: 'ProviderSearchResultsViewAll',
    path: '/results/viewAll',
  },
  PROVIDER_SEARCH_RESULTS_MAP_VIEW: {
    key: 'provider_search_results_map_view',
    name: 'ProviderSearchResultsMapView',
    path: '/results/mapview',
  },
  ALL_PATIENT_REVIEWS: {
    key: 'all_patient_reviews',
    name: 'AllPatientReviews',
    path: '/provider-details/reviews',
  },
  FACILITY_DETAILS: {
    key: 'facility_details',
    name: 'FacilityDetails',
    pathWithToken: '/facility-details/:token',
    path: '/facility-details/',
  },
  PROVIDER_DETAILS: {
    key: 'provider_details',
    name: 'ProviderDetails',
    pathWithToken: '/provider-details/:token',
    path: '/provider-details/',
  },
  PROVIDER_GROUP_DETAILS: {
    key: 'provider_group_details',
    name: 'ProviderGroupDetails',
    pathWithToken: '/group-details/:token',
    path: '/group-details/',
  },
  VIRTUAL_PROVIDER_GROUP_DETAILS: {
    key: 'virtual_provider_group_details',
    name: 'VirtualProviderGroupDetails',
    pathWithToken: '/virtual-group-details/:token',
    path: '/virtual-group-details/',
  },
  CHOOSE_PCP_LOCATION: {
    key: 'choose_pcp_location',
    name: 'ChoosePCPLocation',
    pathWithToken: '/choose-pcp/locations/:token',
    path: '/choose-pcp/locations/',
  },
  CHOOSE_PCP_SUCCESS: {
    key: 'choose_pcp_success',
    name: 'ChoosePCPSuccess',
    pathWithToken: '/choose-pcp/success/:token',
    path: '/choose-pcp/success/',
  },
};
