import { styled } from '@abyss/web/tools/styled';
import { Alert } from '@abyss/web/ui/Alert';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';

export const StyledPCPWrapper = styled('div', {
  borderBottom: '1px solid $cardBorder',
  marginBottom: '$sm',

  '@screen < $sm': {
    borderTop: '1px solid $cardBorder',
  },
});

export const StyledPCPAlert = styled(Alert, {
  '&.abyss-alert-title': {
    color: '$gray8 !important',
  },
});

export const CollapseContainer = styled(Flex, {
  alignItems: 'start',
  borderWidth: '1px 0px',
  borderStyle: 'solid',
  borderColor: '$cardBorder',
  marginTop: '$sm',
  padding: '$lg calc((100% - 1200px) / 2)',

  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '0px',
    border: '0px',
    marginBottom: '0px',
    marginTop: '0',
  },
  '@screen < $sm': { padding: '0px' },
});
export const CollapseContainerOtherProviders = styled(Flex, {
  alignItems: 'start',
  borderWidth: '1px 0px',
  borderStyle: 'solid',
  borderColor: '$cardBorder',
  marginTop: '8px',
  padding: '$lg calc((100% - 1200px) / 2)',

  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '0px',
    border: '0px',
    marginTop: '0',
  },
  '@screen < $sm': { padding: '0px' },
});

export const CollapseSection = styled(Layout.Group, {
  marginTop: '8px',
  variants: {
    isFullWidth: {
      true: {
        width: '100%',
        '.abyss-layout-group': {
          display: 'inline-block',
        },
      },
      false: {
        width: '50%',
      },
    },
  },
  '@screen < $md': {
    display: 'block !important',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '$md',
    gap: '4px',
    width: '100%',
    height: 'auto',
    background: '$white',
    borderWidth: '1px 0px',
    borderStyle: 'solid',
    borderColor: '#E5E5E6',
    borderRadius: '0px',
    flex: 'none',
    order: '4',
    flexGrow: '0',
  },
  '@screen < $sm': {
    padding: '0 0 4px $md',
  },
});

export const CollapseSubSection = styled(Flex, {
  gap: '12px',
  flexDirection: 'column',
  '.abyss-text-root': {
    lineHeight: '24px',
  },
});

export const OtherDetailsContentTitle = styled('div', {
  color: '#323334',
  fontWeight: '600',
  fontSize: '$md',
  lineHeight: '24px',
  '@screen < $sm': {
    lineHeight: '20px',
  },
});

export const CollapseCardHeaderStyle = {
  backgroundColor: 'white',
  borderRadius: 'unset',
  border: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  boxShadow: 'none',
  color: '$primary1',
  fontSize: '20.25px',
  fontWeight: '$bold',
  lineHeight: '24px',
  minHeight: 'unset',
  padding: '0',
  '.abyss-card-title': {
    margin: '$md 0',
    maxHeight: '24px',
  },
};

export const CollapseCardSectionStyle = {
  color: '#4B4D4F',
  padding: '0',
  borderTop: 'none !important',
};

export const CollapseHospitalAffiliationsStyle = {
  color: '#196ECF',
  padding: '0',
  borderTop: 'none !important',
  fontWeight: '$bold',
};

export const NAText = styled(Text, {
  '&.abyss-text-root': {
    color: '#4B4D4F',
    fontWeight: '$medium',
    fontSize: '18px',
    '@screen < $md': {
      fontSize: '$md',
    },
  },
});

export const CopyIcon = styled(IconMaterial, {
  '&.abyss-icon-material': {
    height: '24px',
    width: '24px',
    cursor: 'pointer',
    '@screen < $md': {
      height: '20px',
      width: '20px',
    },
  },
});

export const HospitalAffiliationsContainerStyle = {
  'abyss-flex-root': {
    alignItems: 'center',
    gap: '4px',
    background: '$primary2',
    border: '0.5px solid #DADADA',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    marginBottom: '$xs',
    marginRight: '$md',
    marginTop: '$xs',
    '@screen >= $md': {
      paddingRight: '16px',
      paddingLeft: '24px',
      height: '42px',
    },
    '@screen < $md': {
      paddingRight: '14px',
      paddingLeft: '4px',
      height: '40px',
    },
  },
};
