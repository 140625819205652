import { styled } from '@abyss/web/tools/styled';

import { SearchInput } from '../../CustomSearchInput';

export const StyledSearchInput = styled(SearchInput, {
  '.abyss-search-input-option-list': {
    filter: 'drop-shadow(0px 8px 4px gray)',
    maxHeight: 1600,
  },
  '.abyss-search-input-icon-container': {
    display: 'none',
  },
  '.abyss-search-input-clear': {
    right: '12px',
  },
  '.abyss-search-input-input': {
    paddingLeft: '40px !important',
    border: 'none',
    boxShadow: 'none',
    fontStyle: 'italic',
    fontSize: '18px !important',
    fontWeight: '500',
    color: '#6E7072',
    '@screen < $md': {
      fontSize: '$md !important',
    },
  },
  '.abyss-search-input-option': {
    color: '$accent1',
  },
  '.abyss-search-input-option-icon': {
    fill: '$gray6',
    marginLeft: '8px',
    marginRight: '2px',
  },
  '&.abyss-search-input-option-list': {
    border: '1px solid $gray4',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
  },
  '&.abyss-search-input-option-list:not(:has(> *))': {
    border: 'none',
    boxShadow: 'none',
    display: 'none',
  },
  '.abyss-search-input-no-options': {
    textAlign: 'left',
    color: '$error1',
    marginLeft: '10px',
  },
  '.abyss-search-input-section': {
    marginLeft: '10px',
    borderTop: '1px solid $gray4',
    marginBottom: '-6px',
    fontSize: '14.22px',
  },
  '.abyss-search-input-left-element': {
    paddingRight: '0px',
  },
  '.abyss-search-input-input-container': {
    paddingRight: '0px',
  },
  '.searchResults': {
    fontWeight: '500',
    color: '$gray7',
    fontSize: '12.64px',
  },
});

export const SearchInputContainer = styled('div', {
  backgroundColor: '#FFFFFF',
  borderRight: 'none',
  borderLeft: 'solid',
  borderTop: 'solid',
  borderBottom: 'solid',
  borderColor: '#CBCCCD',
  borderRadius: '50px 0px 0px 50px',
  height: '71px',
  width: '65%',
  minWidth: '300px',
  paddingLeft: '10px',
  paddingBottom: '8px',
  '@screen < $md': {
    borderRight: 'solid',
    borderColor: '#CBCCCD',
    height: '71px',
    width: '100%',
    borderRadius: '50px',
    padding: '0px 0px 8px 10px',
    marginRight: '16px',
  },
  '.abyss-search-input-root': {
    height: '44px',
    '@screen < $md': {
      height: '42px',
    },
  },
  '.abyss-search-input-input': {
    height: '38px',
    width: '95%',
    '@screen < $md': {
      height: '24px',
    },
  },
});

export const SearchLabel = {
  fontWeight: 700,
  fontSize: '12.64px',
  paddingLeft: '15px',
};

export const NullSuggestion = styled('div', {
  paddingLeft: '30px',
  fontSize: '$sm',
  fontWeight: '$medium',
});

export const Suggestion = styled('p', {
  color: '$gray8',
  fontSize: '$sm',
  fontWeight: '$bold',
});

export const CommonSearchList = {
  fontWeight: '500',
  marginLeft: '6px',
  fontSize: '16px',
};

export const SearchBarContainer = styled('p', {
  paddingRight: '24px',
});

export const ListItemSearch = {
  fontWeight: '700',
  marginLeft: '10px',
  fontSize: '16px',
};

export const DistanceIcon = {
  'abyss-icon-material': {
    width: '16px',
    fill: '$gray7',
    height: '16px',
    marginBottom: '-3px',
  },
};

export const CircleIcon = {
  'abyss-icon-material': {
    width: '4px',
    fill: '$gray4',
    height: '4px',
    marginLeft: '4px',
  },
};
