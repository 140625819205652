import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { PageBody } from '@abyss/web/ui/PageBody';
import { TextInput } from '@abyss/web/ui/TextInput';

export const SearchContainer = styled('div', {
  margin: 15,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '18px',
  textAlign: 'left',
});

export const SearchInput = styled(TextInput, {
  color: 'black',
  '.abyss-text-input-descriptors': {
    marginTop: '4px',
  },
});

export const ProviderSearchContainer = styled(PageBody, {
  height: '100vh',
  textAlign: 'center',
  backgroundColor: '$white',
});

export const FindUserLocationContainer = styled('div', {
  alignSelf: 'flex-start',
});

export const StyledSearchButton = styled(Button, {
  '&.abyss-button-root': {
    padding: '0',
    '&:hover': { backgroundColor: 'transparent' },
  },
});
