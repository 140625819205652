import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DismissableLayer } from '@radix-ui/react-dismissable-layer';
import { RemoveScroll } from 'react-remove-scroll';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { Portal } from '@abyss/web/ui/Portal';
import { ModalContext } from '@abyss/web/ui/Modal/ModalContext';
import { useSearchInput } from './hooks/useSearchInput';
import { OptionList } from './OptionList';
import { SearchInputField } from './SearchInputField';

export const SearchInput = ({ enableOutsideScroll, ...props }) => {
  const { modalRef } = useContext(ModalContext);
  const { optionProps, popperProps, ...selectProps } = useSearchInput({
    ...props,
  });

  const { isOpen } = selectProps.state;
  const { popperRef, popperStyles, placedSide } = popperProps;

  return (
    <SearchInputField {...props} {...selectProps} {...optionProps}>
      {isOpen && (
        <RemoveScroll enabled={!enableOutsideScroll}>
          <Portal containerRef={modalRef}>
            <ThemeProvider>
              <DismissableLayer ref={popperRef} style={popperStyles}>
                <OptionList
                  {...props}
                  {...optionProps}
                  placedSide={placedSide}
                />
              </DismissableLayer>
            </ThemeProvider>
          </Portal>
        </RemoveScroll>
      )}
    </SearchInputField>
  );
};

SearchInput.displayName = '@abyss/web/ui/SearchInput';

SearchInput.propTypes = {
  label: PropTypes.string.isRequired,
  onSearch: PropTypes.func,
  onClickSearchButton: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  debounceTime: PropTypes.number,
  isLoading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  validators: PropTypes.shape({}),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  customRender: PropTypes.func,
  customFilter: PropTypes.func,
  apiFiltering: PropTypes.func,
  resultLimit: PropTypes.number,
  uniqueStorageId: PropTypes.string,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onPaste: PropTypes.func,
  model: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorMessage: PropTypes.string,
  subText: PropTypes.string,
  descriptorsDisplay: PropTypes.oneOf(['row', 'column']),
  hideLabel: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  fuseConfig: PropTypes.shape({}),
  keys: PropTypes.arrayOf(PropTypes.string),
  enableOutsideScroll: PropTypes.bool,
  rounded: PropTypes.bool,
  inputLeftElement: PropTypes.node,
  inputRightElement: PropTypes.node,
  openOnFocus: PropTypes.bool,
  customLoader: PropTypes.node,
  customNoOptionsMessage: PropTypes.string,
};

SearchInput.defaultProps = {
  placeholder: null,
  debounceTime: 0,
  isDisabled: false,
  isClearable: true,
  isLoading: false,
  value: null,
  model: null,
  valueKey: 'value',
  labelKey: 'label',
  onChange: null,
  onInputChange: null,
  customRender: null,
  customFilter: null,
  apiFiltering: null,
  uniqueStorageId: null,
  resultLimit: 6,
  onClear: null,
  onFocus: null,
  onClick: null,
  onKeyDown: null,
  onBlur: null,
  onPaste: null,
  width: '100%',
  height: '38px',
  errorMessage: null,
  hideLabel: false,
  validators: {},
  subText: null,
  descriptorsDisplay: 'row',
  options: [],
  fuseConfig: {},
  keys: ['value', 'label'],
  onClickSearchButton: null,
  onSearch: null,
  enableOutsideScroll: false,
  rounded: false,
  inputLeftElement: null,
  inputRightElement: null,
  openOnFocus: false,
  customLoader: null,
  customNoOptionsMessage: 'No Options',
};
