import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../hooks/useIsOverflow';
import { StyledOverflowAttributeDiv } from '../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../utility/compareDrawerConstants';

type Props = {
  providerEthnicityExpress: string;
};

export const ProviderEthnicityExpressText = ({
  providerEthnicityExpress,
}: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const providerEthnicity = !providerEthnicityExpress
    ? NULL_ATTRIBUTE_STATE.PROVIDER_ETHNICITY
    : providerEthnicityExpress;

  const isAttributeEmpty =
    providerEthnicity === NULL_ATTRIBUTE_STATE.PROVIDER_ETHNICITY;

  const ProviderEthnicityAttribute = (
    <StyledOverflowAttributeDiv
      ref={containerRef}
      cssProps={{ isOverflow, isAttributeEmpty }}
    >
      {t(providerEthnicity)}
    </StyledOverflowAttributeDiv>
  );

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip content={providerEthnicity} position="top" positionOffset={8}>
          {ProviderEthnicityAttribute}
        </Tooltip>
      ) : (
        ProviderEthnicityAttribute
      )}
    </React.Fragment>
  );
};
